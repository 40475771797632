import http from "@/components/services/http-common"


export default class Api {
    getEmployees = () => http.get("employees/simple").then(res =>res.data)
    
    post = (request) => http.post('salaryconfigs', request)

    put = (id, request) => http.put('salaryconfigs/'+id, request)

    get = () => http.get('salaryconfigs').then(r => r.data)

    getItem = (id) => http.get('salaryConfigs/'+ id).then(r => r.data)
}
