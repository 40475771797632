<template>
  <div>
    
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
    <!-- <v-breadcrumbs class="bread-crumbs"
      :items="breadcrumbs" large >
    
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :exact="item.exact"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->

    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <!-- <v-card-title></v-card-title> -->

          <v-card-text>
            <v-form ref="organizationMap_form" id="organizationMap_form" v-model="isValid" @submit.prevent="save">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <span class="font-weight-bold">Tên sơ đồ</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="form.name" :rules="[v=> !!v || 'Hãy nhập dữ liệu']">
                  </v-text-field>
                </v-col>
                
                <v-col cols="12" md="6" class="d-flex align-center">
                  <span class="font-weight-bold">Ngày có hiệu lực</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="form.validFrom" type="date" :rules="[v=> !!v || 'Hãy nhập dữ liệu']">
                  </v-text-field>
                </v-col>
                
                <v-col cols="12" md="6" class="d-flex align-center">
                  <span class="font-weight-bold">Ngày hết hiệu lực</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="form.validTo" type="date" :rules="[v=> !!v || 'Hãy nhập dữ liệu']">
                  </v-text-field>
                </v-col>
                
                <v-col cols="12" md="6" class="d-flex align-center">
                  <span class="font-weight-bold">Hoạt động</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch v-model="form.isActive" :false-value="0" :true-value="1"></v-switch>
                </v-col>
                
                <v-col cols="12" md="6" class="d-flex align-center">
                  <span class="font-weight-bold">File sơ đồ</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="form.fileUpload" prepend-icon="" accept=".pdf" :rules="[v=> (!!v || isEdit) || 'Hãy nhập dữ liệu']"></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn type="submit" color="success" form="organizationMap_form" style="width: 200px;" >Lưu</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>

</template>
<script>
import OrganizationMapIndexApi from './api.js'
import router from "@/router/index";
export default {
  data() {
    return {

      isValid: true,
      form: {
        name: '',
        validFrom: '',
        validTo: '',
        isActive: false,
        fileUpload: null
      }
    }
  },
  methods: {
    save() {
      //**validation**
      this.$refs.organizationMap_form.validate()

      if(this.isValid) {
        let formData = new FormData()
        formData.append('name', this.form.name )
        formData.append('validFrom', this.form.validFrom )
        formData.append('validTo', this.form.validTo )
        formData.append('isActive', this.form.isActive )
        formData.append('fileUpload', this.form.fileUpload )
        var thisRouter = router 
        if(this.isEdit){
          return OrganizationMapIndexApi.put(formData, this.$route.params.id).then(() => {
            thisRouter.push({ name: 'OrganizationMap' })
          })
        }else {
          return OrganizationMapIndexApi.post(formData).then(() => {
            thisRouter.push({ name: 'OrganizationMap' })
          })
        }
      }

      return
    },

    fetchData() {
      return OrganizationMapIndexApi.getItem(this.$route.params.id).then(d => {
        console.log(d)
        this.form.name = d.name,
        this.form.validFrom = d.validFrom
        this.form.validTo = d.validTo,
        this.form.isActive = d.isActive
      })
    }
  },
  computed:{
    isEdit() {

      return !!this.$route.params.id
    },

    breadcrumbs() {
      return [
        {
            text: 'Danh sách sơ đồ hệ thống',
            to: {name: 'OrganizationMap'},
            exact: true
        },
        {
          text: this.isEdit ? 'Chỉnh sửa '+this.$route.params.id : 'Tạo mới',
          to: {name: this.isEdit ? 'OrganizationMapEdit' : 'OrganizationMapCreate'}
        },
      ]
    }
  },
  created() {
    if(this.isEdit) {
      this.fetchData()
    }
  }
}
</script>
