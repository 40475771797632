<template>
    <div class="work-history">
    <v-dialog v-model="dialog" max-width="700px">
        <v-card>
            <v-card-title>
                Danh sách phép
                <v-spacer></v-spacer>
                <v-icon small @click="dialog=false"> mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="mt-3">
                <v-data-table :items="items" :headers="headers">
                    <template v-slot:[`item.amount`]="{item}">
                        <span v-if="item.hasAmount">
                            {{item.amount}}
                        </span>
                        <span v-else>
                            <v-icon small>mdi-infinity</v-icon>
                        </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>
import http from '@/components/services/http-common';
import UploadService from "@/components/services/UploadService";
import moment from 'moment/moment';
export default {
    components:{
    },
    props:["value", "item"],
    watch:{
        dialog: {
            handler(value){
                if(value)
                {
                    this.fetch();
                }
                else{
                    this.close();
                }
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    data() {
        return {
            ROOT_URL: "employees/",
            items:[],
            headers:[
                { text: 'Mã phép', value: 'leavePlanCode', width: 100},
                { text: 'Loại phép', value: 'leavePlanName', width: 150},
                { text: 'Số phép', value: 'amount', width: 100},
                { text: 'Ngày cấp', value: 'date', width: '7rem'},
                { text: 'Ngày hết hạn', value: 'expireDate', width: '7rem'},
                { text: 'Ghi chú', value: 'note', width: 300},
            ]
        }
    },
    methods: {
        fetch(){
            return http.get(this.ROOT_URL+"leaveEnroll-history/"+this.item).then(res => {
                this.items = res.data;
            })
        },
        saveData() {
            
        },
        close(){

        }
    },
    created() {

    }
    
}
</script>
<style scoped>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .7;
        position: absolute;
        width: 100%;
    }
</style>