import ItSection from '@/views/layouts/SubLayouts/ItSection.vue';
//*********** Hiring */
import DepartmentList from '@/views/ItSection/pages/Department/DepartmentList';
import UserList from '@/views/ItSection/pages/User/UserList';
import ItScheduleList from '@/views/ItSection/pages/ItSchedule/ItScheduleList';

export default [
    {
      path: 'it-section',
      name: 'ItSection',
      component: ItSection,
      redirect: { name: 'DepartmentList' },
      children:[
        {
            path: 'department-list',
            name: 'DepartmentList',
            component: DepartmentList,
            meta:{
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Tổng quan',
                disabled: true,
                to: {
                    name: "CandidateOverview",
                    }
                },
              ],
            }
        },
        {
            path: 'user-list',
            name: 'UserList',
            component: UserList,
        },
        {
            path: 'it-schedule',
            name: 'ItSchedule',
            component: ItScheduleList,
        },
        {
            path: 'it-schedule-external',
            name: 'ItScheduleExternal',
            beforeEnter: (to, from, next) => {
              window.location.href = 'http://125.212.254.47:8181/admin/itschedule/';
            },
        },
      ]
    },
    
  ]
