<template>
    <div class="create-leave">
        <v-dialog :value="value.state"  max-width="600px" persistent scrollable>
             <v-card>
                <v-card-title>
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} đơn nghỉ phép 
                    <v-spacer></v-spacer>
                    <v-btn small text @click="close"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                <v-form @submit.prevent="saveData" v-model="valid" id="form" ref="form">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.from" label="Chọn ngày nghỉ" type="date"
                                :rules="[v => !!v  || 'Chọn ngày nghỉ']" @change="form.leavePlanCode=null"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                            v-model="form.leavePlanCode"
                            :items="formData.leavePlans" item-text="leaveName" item-value="leaveCode"
                            @click="fetchLeavePlanType2"
                            label="Kế hoạch nghỉ"
                            >
                                <template v-slot:item="data">
                                    <strong>{{ data.item.leaveName }}: {{ data.item.leaveCode }} </strong> {{`: ${data.item.amount}`}}
                                    <!-- <span v-if="data.item.hasAmount">Còn lại: {{data.item.amount}}</span> 
                                    <span v-else><v-icon>mdi-infinity</v-icon></span>  -->
                                </template>
                            </v-autocomplete>
                        </v-col>
                            <!-- <date-picker v-model="form.from" title="Ngày bắt đầu nghỉ"></date-picker> -->
                        <!-- <v-col cols="6" v-if="form.isMultiple">
                            <date-picker v-model="form.to" title="Ngày bắt đầu đến"></date-picker>
                        </v-col> -->
                        <v-col cols="6">
                            <v-select
                            :disabled="form.isMultiple"
                            v-model="form.leaveAmountType"
                            :items="formData.leaveAmountTypes"
                            label="Loại nghỉ"
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                            outlined
                            v-model="form.note"
                            label="Diễn giải"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>       
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark form="form" color="primary" type="submit" small>
                        Lưu
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
             </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DatePicker from "../../../components/DatePicker";
import http from '../../../components/services/http-common';
import EventBus  from "../../../components/services/EventBus";

export default {
    name: "Create",
    components: {
        "date-picker": DatePicker,
    },
    computed:{
        amount() {
            let amount = 0;
            const day = (1000*60*60*24);
            if(Array.isArray(this.dates))
            {
                if(this.form.isMultiple)
                {
                    if(this.dates.length == 2){
                        let first = new Date(this.dates[0]);
                        let last = new Date(this.dates[1]);
                        amount = (last -first)/day;
                        return Math.abs(amount) +1;
                    }
                }
            }
            return amount;
        }
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            }
        },
        "form.isMultiple": {
            handler(val)
            {
                if(val)
                    this.dates = [];
                else
                    this.dates = "";
            }
        },
    },

    props: ["value"],
    data () {
        return {
            //URL
            ROOT_URL: "leaveRequests",
            LEAVEPLAN_URL: "leavePlans/valid-leave-plan/",
            LEAVEPLAN_URL2: "leavePlans/valid-leave-plan2/",

            //DATA
            valid: false,
            dateMenu: false,
            dates: "",
            ShiftObject: "",
            formData: {
                leaveAmountTypes: [
                    {text: "Nghỉ cả ngày", value: "1"},
                    {text: "Nghỉ 1/2 ngày", value: "0.5", disabled: false},
                ],
                fromMenu: false,
                toMenu: false,
                leavePlans: [],
            },
            form: {
                isMultiple: false,
                from: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
                leaveAmountType: "1",
                leavePlanCode: "",
                remain:0,
                note: "",
                validFrom:null,
                validTo:null
            },
            defaultForm: {
                isMultiple: false,
                from: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
                leaveAmountType: "1",
                leavePlanCode: "",
                remain:"",
                note: "",
                validFrom:null,
                validTo:null
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        errorToast (eventObj)
        {
            EventBus.$emit('hasError', eventObj);
        },

        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        dateApply (){
            if(this.isMultiple)
            {
                let dates = this.dates;
                let dt0 = new Date(dates[0]);
                let dt1 = new Date(dates[1]);
                if(dt0> dt1)
                    this.dates = [dates[1], dates[0]];
            }
            this.$refs.menu.save(this.dates);
        },

        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        
        fetchData () {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                this.form = Object.assign({}, item);
                console.log(this.form);
            })
            .catch(err => {
                console.log(err);
            });
        },
        
        fetchLeavePlanType () {
            return http.get(this.LEAVEPLAN_URL)
            .then(res => {
                // this.formData.leavePlans = res.data;
            }).catch(err => {throw err})
        },

        fetchLeavePlanType2() {
            return http.get(this.LEAVEPLAN_URL2, {
                params: {
                    execDate:this.form.from
                }
            })
            .then(res => {
                this.formData.leavePlans = res.data;
                console.log(this.formData.leavePlans );
            }).catch(err => {throw err})
        },
        
        saveData() {
            this.$refs.form.validate();
            if(this.valid)
            {
                if(!this.value.item)
                {
                    var validLeave = this.formData.leavePlans.find(x=>x.leaveCode == this.form.leavePlanCode);
                    this.form.validFrom = validLeave.validFrom;
                    this.form.validTo = validLeave.validTo;
                    return http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Tạo mới thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        // this.errorToast({message: err.response.data.message, toast: true})
                    });
                } else {
                    return http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Cập nhật thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
                this.close();
            }
        },
    },
    mounted() {
    }
}
</script>