<template>
    <!-- ************** TIMESHEET DETAIL *************** -->
    <v-dialog v-model="dialog" scrollable max-width="650px" @click:outside="closeDetail">
        <v-card>
            <v-card-title>Chi tiết giờ công
                <v-spacer></v-spacer>
                <v-icon @click="fetchDetail">mdi-refresh</v-icon>

            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="">
                    <v-row>
                        <v-col cols="12">
                            <small class="error--text">Các trường mang dấu * không thể thay đổi</small>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-text-field label="Mã *" color="error" v-model="detailItem.code" readonly></v-text-field>
                        </v-col> -->
                        <v-col cols="3">
                            <v-text-field label="Tổng giờ đã làm" type="number" v-model="detailItem.actualWorkTime" min="0" :disabled="false">
                                <template v-slot:[`append`]="">
                                    <v-icon color="blue">mdi-clock</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Cơm *" type="number" readonly v-model="detailItem.lunch" min=0 color="error">
                                <template v-slot:[`append`]="">
                                    <v-icon color="primary">mdi-bowl-mix</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <!-- <v-col cols="6">
                            <v-text-field label="Làm ngoài giờ *" type="number" v-model="detailItem.extendedTime" min=0 readonly color="error"></v-text-field>
                        </v-col> -->
                        <v-col cols="3">
                            <v-text-field label="Giờ làm việc *" type="number" readonly v-model="detailItem.validActualTime" min=0 color="error">
                                <template v-slot:[`append`]="">
                                    <v-icon color="pink">mdi-briefcase</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Nghỉ phép *" type="number" readonly v-model="detailItem.leaveTime" min=0 color="error">
                                <template v-slot:[`append`]="">
                                    <v-icon color="pink">mdi-umbrella-beach</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Tăng ca *" type="number" readonly v-model="detailItem.overtime" min=0 color="error">
                                <template v-slot:[`append`]="">
                                    <v-icon color="amber">mdi-fire</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <!-- <v-col cols="6">
                            <v-text-field label="Tăng ca lễ *" readonly type="number" v-model="detailItem.holidayOvertime" min=0 color="error"></v-text-field>
                        </v-col> -->
                        <v-col cols="6">
                            <v-text-field label="Cơm tăng ca *" type="number" readonly v-model="detailItem.overtimeLunch" min=0 color="error">
                                <template v-slot:[`append`]="">
                                    <v-icon color="amber">mdi-bowl-mix</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea label="Ghi chú" v-model="detailItem.note" outlined ></v-textarea>
                        </v-col>
                        
                        <v-col cols="6">
                            <v-text-field label="Người xác nhận" type="text" readonly v-model="detailItem.acceptedBy" min=0 color="error">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Thời gian xác nhận" type="text" readonly v-model="detailItem.acceptedAt" min=0 color="error">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-switch
                    v-model="detailItem.isConfirmed"
                    label="Xác nhận"
                    ></v-switch>
                    <!-- ENROLLMENT -->
                    <v-subheader class="pl-0">Chấm công:</v-subheader>
                    <v-data-table :items="detailItem.enrollments" dense :headers='[
                                                                    { text: "Giờ checkin", value: "checkinTime", width: "150px"},
                                                                    { text: "Giờ checkout", value: "checkoutTime", width: "150px"},
                                                                    { text: "IP checkin", value: "ipIn", width: "150px"},
                                                                    { text: "IP checkout", value: "ipOut", width: "150px"},
                                                                    { text: "Trễ", value: "late", width: "150px"},
                                                                    { text: "Sớm", value: "early", width: "150px"},]'>
                    </v-data-table>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="value=false" small color="primary">Thoát</v-btn>
                <v-btn @click="saveData" class="mr-2" small color="orange" dark>OK</v-btn>
                
                <v-spacer></v-spacer>
                <v-dialog max-width="600" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="error" class="mx-2" v-bind="attrs" v-on="on" small>
                            Xóa
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar color="primary" dark flat>
                                Xóa giờ công
                            </v-toolbar>
                            <v-card-actions class="justify-end">
                                <v-spacer></v-spacer>
                                <v-btn text @click="deleteItem(), dialog.value = false" >Xóa công</v-btn>
                                <v-btn text @click="dialog.value = false" >Hủy</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import http from '@/components/services/http-common';
import moment from 'moment/moment';
const TIMESHEET_URL = "timesheets";
import EventBus  from "@/components/services/EventBus";
export default {
    props:["value", "item", "employee"],
    watch:{
        value: {
            handler(val){
                this.fetchDetail();
            }
        },
        
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
    },
    data(){
        return {
            
        //DETAIL ITEM
        detailItem: {
            employeeCode:null,
            code: "",
            shiftHour:0,
            actualWorkTime: 0,
            holidayActualTime: 0,
            overtime: 0,
            holidayOvertime: 0,
            leaveTime: 0,
            isConfirmed: false,
            lunch:0,
            overtimeLunch:0,
            late: 0,
            early: 0,
            enrollments: [],
            workDateStr:"",
            validActualTime:0,
            note:"",
            acceptedBy:"",
            acceptedAt:"",
        },
        defaultDetailItem: {
            employeeCode:null,
            code: "",
            actualWorkTime: 0,
            holidayActualTime: 0,
            overtime: 0,
            holidayOvertime: 0,
            leaveTime: 0,
            isConfirmed: false,
            lunch:0,
            overtimeLunch:0,
            late: 0,
            early: 0,
            enrollments: [],
            workDateStr:"",
            validActualTime:0,
            note:"",
            acceptedBy:"",
            acceptedAt:"",
        },
        }
    },
    methods:{
        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        // DETAIL DIALOG
        fetchDetail() {
            let url = `${TIMESHEET_URL}/${this.item}`
            return http.get(url)
            .then((res) => {
                let data= res.data;
                console.log("fetch", data);
                this.detailItem = Object.assign({}, data.item);
                this.detailItem.enrollments = data.enrollments;
            })
            .catch((err) => {
                console.log(err)
            })
        },
        saveData () {
            let isEdit = !!this.detailItem.code;
            if(isEdit)
            {
                delete this.detailItem.enrollments;
                return http.put(TIMESHEET_URL + "/" + this.detailItem.code, this.detailItem)
                .then(res => {
                    let eventObj = {
                        color: "success",
                        message: "Lưu dữ liệu thành công",
                        toast: true,
                    };
                    this.messageToast(eventObj);
                    this.$emit("fetchData");
                })
                .catch(err => { console.log(err)
                });
            }
            else
            {
                let form = {
                    employeeCode: this.detailItem.employeeCode,
                    workDate: moment(this.detailItem.workDateStr, "DDMMYYYY").format("YYYY-MM-DD"),
                    isConfirmed: this.detailItem.isConfirmed,
                    actualWorkTime: this.detailItem.actualWorkTime,
                    overtime: this.detailItem.overtime,
                    leaveTime: this.detailItem.leaveTime,
                    holidayOvertime: this.detailItem.holidayOvertime,
                    holidayActualTime: this.detailItem.holidayActualTime,
                    note: this.detailItem.note
                }
                console.log(this.form.note)
                return http.post(this.TIMESHEET_URL, form).then(res => {
                    this.$emit("fetchData");
                    let eventObj = {
                        color: "success",
                        message: "Lưu dữ liệu thành công",
                        toast: true,
                    };
                    this.messageToast(eventObj);
                });
            }
        },

        closeDetail () {
            this.detailItem = Object.assign({}, this.defaultDetailItem);
            this.detailDialog = false;
        },
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
    }
}
</script>