<template>
    <div class="index">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb>
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <v-card>
            <v-card-title>
                <span class="d-none d-sm-flex">
                    Danh sách kĩ năng
                </span>
                <v-spacer></v-spacer>
                <!-- #FILTER MENU -->
                <!-- <v-btn small class="mx-3" @click="exportExcel" color="teal" dark> <v-icon>mdi-database-export</v-icon> Xuất</v-btn> -->
                <data-filter2 :filterProps="filters2" :defaults="defaults" @fetchData="fetchData" >
                </data-filter2>
                
            </v-card-title>

            <v-data-table
                :mobile-breakpoint="0"
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalItem"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.signDate`]="{ item }" >
                    {{ formatDate(item.signDate) }}
                </template>
                
                <template v-slot:[`item.expireDate`]="{ item }" >
                    {{ formatDate(item.expireDate) }}
                </template>
                
                <template v-slot:[`item.isCurrent`]="{ item }">
                    <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <!-- ********* Edit ****** -->
                    <v-btn dark @click="editItem(item)" color="warning" small class="mx-1">
                        <v-icon >
                            mdi-pencil
                        </v-icon> Sửa
                    </v-btn>

                    <!-- ********* Xoa ****** -->
                    <v-btn dark @click="deleteItem(item)" small class="mx-1" color="error">
                        <v-icon >
                            mdi-delete
                        </v-icon>
                        Xóa
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import Create from './Create.vue';
import DataFilter2 from '@/components/DataFilter2.vue';
import ImportUpload from '@/components/ImportUpload';

export default {
    components: {
        myBreadCrumb,
        "create-dialog": Create,
        'data-filter2': DataFilter2,
        'import-upload': ImportUpload,
    },
    props:["breadcrumbs", "icon"],
    watch: {
        options: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
    },

    data() {
        return {
            ROOT_URL: "Abilities",
            //HEADER DATA

            //TABLE DATA
            filterState:[],
            
            filters2: {
                searchOns: [{name: "Tên", code:"name"}, {name: "Mã", code:"code"}],
            },
            defaults: {
                searchOn: "name"
            },

            headers: [
                {text:"", value: "actions", width: "250px"},
                {text: "Mã", value: "code"},
                {text: "Kĩ năng", value: "skill"},
                {text: "Tên khả năng", value: "name"},
                {text: "Mô tả", value: "description"},
            ],
            items: [],
            options: {},
            totalItem: 0,
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

        }
    },
    methods: {
        //DATA JS
        fetchData (e) {
            this.filterState = e;
            let params =  { ...e, 
                                sortBy: this.options.sortBy, //array
                                sortDesc: this.options.sortDesc, //array
                                page: this.options.page,
                                };
            return http.get(this.ROOT_URL, {
                params: params
            })
            .then(res => {
                console.log(res.data.items)
                this.items = res.data.items;
                this.totalItem = res.data.totalItem
                this.isLoading = false;
            }).catch(err => {})
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {

            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    created() {
        this.fetchData();
    }
}
</script>