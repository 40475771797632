<template>
    <div class="enrollment">
        <v-card class="rounded-lg">
            <v-card-text>
            <v-row style="margin-right: -28px">
                <v-col cols="6" class="pt-5">
                    <month-picker class="my-2" v-model="filters.month" :label="$t('view.pending_employee.pick_month')" width="7rem" dense outlined></month-picker> 
                </v-col>
                <v-col cols="6">
                    <v-tabs class="rounded-l-pill" style=""
                        v-model="tab" background-color="primary" dark right>
                        <v-tab color="success">ỨNG VIÊN
                        </v-tab>
                        <v-tab color="red">ĐÃ NHẬN VIỆC
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-row v-if="tab==0">
                <v-col cols="12" md="3" lg="2">
                    <h5>Mã ứng viên</h5>
                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.code" outlined hide-details></v-text-field>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Tên ứng viên</h5>
                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.name" clearable outlined hide-details></v-text-field>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Công việc ứng tuyển</h5>
                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.jobCode" clearable
                    :items="jobs" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>SĐT</h5>
                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.phone" outlined hide-details></v-text-field>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Email</h5>
                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.mail" outlined hide-details></v-text-field>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Địa chỉ</h5>
                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.cityCode" clearable
                    :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Nơi làm việc mong muốn 1</h5>
                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.city1Code" clearable
                    :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Nơi làm việc mong muốn 2</h5>
                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.city2Code" clearable
                    :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                
                <v-col cols="12" md="3" lg="2">
                    <h5>Cơ sở đào tạo</h5>
                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.unisCode" clearable
                    :items="unis" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Trình độ</h5>
                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.eduLevelCode" clearable
                    :items="eduLvls" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Chuyên ngành</h5>
                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.majorCode" clearable
                    :items="majors" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" lg="2">
                    <h5>Ngày tạo</h5>
                    <date-picker2 v-model="filters.createdAt" :range="false" label="" :dense="true" :hideDetails="true" outlined ></date-picker2>
                </v-col>
            </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-5 rounded-lg">
            <v-tabs-items v-model="tab">
                <v-tab-item >
            
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn class="" icon color="orange"><v-icon @click="fetchData">mdi-refresh</v-icon></v-btn>
                    </v-card-title>
                    <v-data-table
                        :mobile-breakpoint="0"
                        height="600px" fixed-header
                        :headers="headers"
                        :items="item1s"
                        :page.sync="tableParams.page"
                        :sort-by.sync="tableParams.sortBy"
                        :sort-desc.sync="tableParams.sortDesc"
                        :server-items-length="rowCount"
                        :loading="isLoading"
                        :items-per-page.sync="tableParams.pageSize"
                        loading-text="Đang tải dữ liệu..."
                        :footer-props="{
                            itemsPerPageOptions:[
                            15, 50, 100, 200
                            ]
                        }"
                    >
                        <!-- <template v-slot:[`body.prepend`]="" >
                            <tr class="no-hover">
                                <td></td>
                                <td>
                                </td>
                                <td></td>
                                <td>
                                </td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.status" clearable :items="statuses" outlined hide-details></v-autocomplete>
                                </td>
                                <td></td>
                                <td>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                            </tr>
                        </template> -->
                        
                        <template v-slot:[`item.name`]="{ item }" >
                            <span :class="`${item.notAccept ? 'red': 'primary'}--text text--darken-4 font-weight-bold`">{{ item.name }}</span>
                        </template>

                        <template v-slot:[`item.notAccept`]="{ item }" >
                            <v-icon v-if="item.notAccept" color="red">mdi-check</v-icon>
                        </template>
                        <template v-slot:[`item.interviewAt`]="{ item }" >
                            {{ formatDate(item.interviewAt)=="01/01/0001" ? "": formatDate(item.interviewAt) }}
                        </template>
                        <template
                            v-slot:[`item.actions`]="{item}"
                        >
                        
                            <v-tooltip bottom color="primary" v-if="item.status=='PASS' && !item.notAccept">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small @click="acceptDialog=true, targetItem=item" class="ma-1" icon color="primary" >
                                        <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('view.action.approve')}}</span>
                            </v-tooltip>

                            <v-tooltip bottom color="error"  v-if="item.status=='PASS'  && !item.notAccept">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small @click="deny(item)" class="ma-1" icon color="red">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('view.action.deny')}}</span>
                            </v-tooltip>
                            <!-- <v-btn x-small @click="acceptDialog=true, targetItem=item" color="teal" dark v-if="item.status=='PASS' && !item.notAccept" class="my-1">Nhận việc</v-btn>
                            <v-btn x-small @click="deny(item)" color="orange" dark v-if="item.status=='PASS'  && !item.notAccept" class="my-1">Không nh.việc</v-btn> -->
                            <v-btn x-small @click="undo(item)" dark v-if="item.status=='ACCEPT' || item.notAccept" class="my-1">{{$t('view.action.undo')}}</v-btn>
                            <!-- <edit-button @click="edit(item)" label="Chi tiết"></edit-button>
                            <delete-button @click="remove(item)" label="Xóa"></delete-button> -->
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }" >
                            {{ formatDate(item.createdAt) }}
                        </template>
                        <template v-slot:[`item.time`]="{ item }" >
                            {{ formatDate(item.time) }}
                        </template>
                        <template v-slot:[`item.status`]="{ item }" >
                            <v-chip :color="formatStatusColor(item.status)" label small outlined>
                                <strong> {{formatStatus(item.status)}}</strong>
                            </v-chip>
                            <!-- {{ formatStatus(item.status) }} -->
                        </template>
                        
                        <template v-slot:[`item.avatarUrl`]="{ item }">
                            <v-avatar class="my-1" size="54">
                                <v-img
                                    lazy-src="https://banner2.cleanpng.com/20180625/req/kisspng-computer-icons-avatar-business-computer-software-user-avatar-5b3097fcae25c3.3909949015299112927133.jpg"
                                    :src="endpoint + item.avatarUrl.replace('wwwroot/', '')"
                                    alt="Avatar"
                                    v-if="item.avatarUrl" ></v-img>
                            </v-avatar>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item >
                    <v-data-table
                        :mobile-breakpoint="0"
                        height="600px" fixed-header
                        :headers="headers"
                        :items="item2s"
                        :page.sync="tableParam2s.page"
                        :sort-by.sync="tableParam2s.sortBy"
                        :sort-desc.sync="tableParam2s.sortDesc"
                        :server-items-length="rowCount2"
                        :loading="isLoading"
                        :items-per-page.sync="tableParam2s.pageSize"
                        loading-text="Đang tải dữ liệu..."
                        :footer-props="{
                            itemsPerPageOptions:[
                            15, 50, 100, 200
                            ]
                        }"
                    >
                        <template v-slot:[`body.prepend`]="" >
                            <tr class="no-hover">
                                <td></td>
                                <td>
                                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.code" outlined hide-details></v-text-field>
                                </td>
                                <td></td>
                                <td>
                                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.name" clearable outlined hide-details></v-text-field>
                                </td>
                                <td>
                                    <!-- <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filters.status" clearable :items="statuses" outlined hide-details></v-autocomplete> -->
                                </td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.jobCode" clearable
                                    :items="jobs" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.cityCode" clearable
                                    :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.phone" outlined hide-details></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.mail" outlined hide-details></v-text-field>
                                </td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.city1Code" clearable
                                    :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                                </td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.city2Code" clearable
                                    :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                                </td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.unisCode" clearable
                                    :items="unis" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                                </td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.eduLevelCode" clearable
                                    :items="eduLvls" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                                </td>
                                <td>
                                    <v-autocomplete :placeholder="$t('view.filter.type_placeholder')" dense v-model="filter2s.majorCode" clearable
                                    :items="majors" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                                </td>
                                <td>
                                    <date-picker2 v-model="filter2s.createdAt" :range="false" label="" :dense="true" :hideDetails="true" outlined ></date-picker2>
                                </td>
                            </tr>
                        </template>
                        
                        <template v-slot:[`item.notAccept`]="{ item }" >
                            <v-icon v-if="item.notAccept">mdi-check</v-icon>
                        </template>
                        <template v-slot:[`item.interviewAt`]="{ item }" >
                            {{ formatDate(item.interviewAt)=="01/01/0001" ? "": formatDate(item.interviewAt) }}
                        </template>
                        <template
                            v-slot:[`item.actions`]="{item}"
                        >
                        
                            <v-tooltip bottom color="primary" v-if="item.status=='PASS' && !item.notAccept">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small @click="acceptDialog=true, targetItem=item" class="ma-1" icon color="primary" >
                                        <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('view.action.approve')}}</span>
                            </v-tooltip>

                            <v-tooltip bottom color="error"  v-if="item.status=='PASS'  && !item.notAccept">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small @click="deny(item)" class="ma-1" icon color="red">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('view.action.deny')}}</span>
                            </v-tooltip>
                            <!-- <v-btn x-small @click="acceptDialog=true, targetItem=item" color="teal" dark v-if="item.status=='PASS' && !item.notAccept" class="my-1">Nhận việc</v-btn>
                            <v-btn x-small @click="deny(item)" color="orange" dark v-if="item.status=='PASS'  && !item.notAccept" class="my-1">Không nh.việc</v-btn> -->
                            <v-btn x-small @click="undo(item)" dark v-if="item.status=='ACCEPT' || item.notAccept" class="my-1">{{$t('view.action.undo')}}</v-btn>
                            <!-- <edit-button @click="edit(item)" label="Chi tiết"></edit-button>
                            <delete-button @click="remove(item)" label="Xóa"></delete-button> -->
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }" >
                            {{ formatDate(item.createdAt) }}
                        </template>
                        <template v-slot:[`item.time`]="{ item }" >
                            {{ formatDate(item.time) }}
                        </template>
                        <template v-slot:[`item.status`]="{ item }" >
                            <v-chip :color="formatStatusColor(item.status)" label small outlined>
                                <strong> {{formatStatus(item.status)}}</strong>
                            </v-chip>
                            <!-- {{ formatStatus(item.status) }} -->
                        </template>
                        
                        <template v-slot:[`item.avatarUrl`]="{ item }">
                            <v-avatar class="my-1" size="54">
                                <v-img
                                    lazy-src="https://banner2.cleanpng.com/20180625/req/kisspng-computer-icons-avatar-business-computer-software-user-avatar-5b3097fcae25c3.3909949015299112927133.jpg"
                                    :src="endpoint + item.avatarUrl.replace('wwwroot/', '')"
                                    alt="Avatar"
                                    v-if="item.avatarUrl" ></v-img>
                            </v-avatar>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- ************** DIALOG CONFIRMATION *************** -->
        <v-dialog v-model="acceptDialog" max-width="800px" scrollable>
            <v-card>
                <v-card-title class="title teal--text text--darken-4">Quyết định nhận việc nhân viên</v-card-title>
                <v-card-text>
                    <v-subheader class="text-subtitle-1 teal--text text--darken-4">Thông tin ứng viên</v-subheader>
                    <v-row v-if="targetItem">
                        <v-col cols="6" class="text-center">
                            <v-avatar class="my-1" size="128">
                                <v-img
                                    lazy-src="https://banner2.cleanpng.com/20180625/req/kisspng-computer-icons-avatar-business-computer-software-user-avatar-5b3097fcae25c3.3909949015299112927133.jpg"
                                    :src="endpoint + targetItem.avatarUrl.replace('wwwroot/', '')"
                                    alt="Avatar"
                                    v-if="targetItem.avatarUrl" ></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col cols="12" md="6"> 
                            <v-text-field v-model="targetItem.code" label="Mã ứng viên" outlined hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"> 
                            <v-text-field v-model="targetItem.name" label="Tên ứng viên" outlined hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"> 
                            <v-text-field v-model="targetItem.phone" label="SDT" outlined hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"> 
                            <v-text-field v-model="targetItem.idNumber" label="CCCD" outlined hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6"> 
                            <v-text-field v-model="targetItem.job" label="Công việc ứng tuyển" outlined hide-details></v-text-field>
                        </v-col>
                    </v-row>

                    <v-subheader class="text-subtitle-1 teal--text text--darken-4 mt-5">Thông tin nhận việc</v-subheader>
                    <v-row>
                        <v-col cols="12" md="6"> 
                            <v-row>
                                <v-col cols="12">
                                    <date-picker2 :range="false" v-model="form.hireDate" label="Ngày nhận việc" :width="600" :outlined="true" :hide-details="true"></date-picker2>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="form.code" label="Mã nhân viên (tự sinh nếu để trống)" outlined hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="6" >
                            <v-row>
                                <v-col cols="12" v-show="!form.isDepot">
                                    <v-autocomplete v-model="form.temporaryStoreId" label="Chuẩn bị mở mới" item-text="name" item-value="id" :items="temporaryStores"  outlined hide-details></v-autocomplete>
                                </v-col>
                                <v-col cols="12" v-show="!form.isDepot">
                                    <v-autocomplete v-model="form.departmentTypeCode" label="Khối" item-text="name" item-value="code" :items="departmentTypes"  outlined hide-details></v-autocomplete>
                                </v-col>
                                <v-col cols="12" v-show="!form.isDepot">
                                    <v-autocomplete v-model="form.departmentCode" label="Phòng ban" item-text="name" item-value="code" :items="departments"  outlined hide-details></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete v-model="form.positionCode" label="Vị trí" item-text="name" item-value="code" :items="positions" outlined hide-details></v-autocomplete>
                                </v-col>
                                <v-col cols="12" >
                                    <v-autocomplete v-model="form.positionStateCode" label="Chức danh" item-text="name" item-value="code" :items="positionStates"  outlined hide-details></v-autocomplete>
                                </v-col>
                                <v-col cols="12" >
                                    <v-autocomplete v-model="form.shiftCode" label="Ca làm việc" item-text="name" item-value="code" :items="shifts" persistent-hint  outlined hide-details
                                    hint="Lưu ý: Nhân viên hỗ trợ sử dụng CA VĂN PHÒNG, chi nhánh sử dụng ca tương ứng với chức danh tương ứng, riêng TỔNG KHO thuộc khối hỗ trợ sử dụng ca  làm việc như chi nhánh"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-checkbox v-model="form.isDepot" label="Bộ phận kho"></v-checkbox>
                        </v-col> -->
                    </v-row>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" outlined @click="close"><v-icon>mdi-close</v-icon>Thoát </v-btn>
                <v-btn color="primary" dark @click="recruit(targetItem), acceptDialog=false"><v-icon left>mdi-check</v-icon>Duyệt</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import * as moment from "moment/moment";
import DatePicker2 from '../../../components/DatePicker2.vue';

export default {
    name: "CandidateIndex",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        acceptDialog(val) {
            this.fetchCity();
            if(!this.acceptDialog)
            {
                this.item=null;
                this.form = Object.assign({}, this.defaultForm);
            }
            
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        tableParam2s: {
            handler (val) {
                this.fetchData2();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        filter2s: {
            handler (val) {
                this.tableParam2s.page=1;
                this.fetchData2();
            },
            deep: true,
        },
        "form.departmentTypeCode": {
            handler (val) {
                this.fetchDepartments(val);
            },
        },
        "form.departmentCode": {
            handler (val) {
                this.fetchPosition(val);
            },
        },
        "form.positionCode": {
            handler (val) {
                this.fetchPositionState(val);
            },
        },
        "form.positionStateCode": {
            handler (val) {
                if(val == "PST00013")
                    this.form.shiftCode = "CLV00023";
            },
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        param2s: function() {
            return {...this.tableParam2s, ...this.filter2s}
        }
    },
    data () {
        return {
            ROOT_URL: "EmployeeResource",
            POSITION_FETCH: "departments/fetch-position/",
            POSITION_STATE_FETCH: "positions/fetch-position-state",
            endpoint: process.env.VUE_APP_BASE_URL,

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:15
            },
            
            tableParam2s: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:15
            },
            tab: null,
            statuses: [
                {text: "Mới", value: "NEW"},
                {text: "Chờ", value: "PEND"},
                {text: "Đạt", value: "PASS"},
                {text: "Loại", value: "FAIL"},
                {text: "Nhận việc", value: "ACCEPT"},
            ],
            jobs:[],
            cities:[],
            unis:[],
            eduLvls:[],
            majors:[],
            temporaryStores: [],
            filters:{
                code:null,
                createdAt:null,
                status:'PASS',
                name:null,
                jobCode:null,
                cityCode:null,
                city1Code:null,
                city2Code:null,
                unisCode:null,
                eduLevelCode:null,
                majorCode:null,
                phone:null,
                mail:null,
                month: moment().format("YYYY-MM"),
            },
            
            filter2s:{
                code:null,
                createdAt:null,
                status:'ACCEPT',
                name:null,
                jobCode:null,
                cityCode:null,
                city1Code:null,
                city2Code:null,
                unisCode:null,
                eduLevelCode:null,
                majorCode:null,
                phone:null,
                mail:null,
                month: moment().format("YYYY-MM"),
            },
            rowCount: 0,
            rowCount2: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },

            departments:[],
            departmentTypes:[],
            positions:[],
            positionStates:[],
            shifts: [],
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            item1s: [],
            item2s: [],
            search:"",
            options: {},
            isLoading: true,
            quickItem: {name: undefined, status:undefined },

            acceptDialog: false,
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            form: {
                code:"",
                isDepot: false,
                departmentCode: "",
                departmentTypeCode:"",
                hireDate: moment().format("YYYY-MM-DD"),
                positionCode:undefined,
                positionStateCode:undefined,
                shiftCode: undefined,
                temporaryStoreId: null,
            },

            defaultForm: {
                code:"",
                isDepot: false,
                departmentCode: "",
                departmentTypeCode:"",
                hireDate: moment().format("YYYY-MM-DD"),
                positionCode:undefined,
                positionStateCode:undefined,
                shiftCode: undefined,
                temporaryStoreId: null,
            },

            headers: [
                { text: this.$t('view.pending_employee.actions'), value: 'actions',width: 120},
                { text: this.$t('view.pending_employee.code'), align: 'start', value: 'code', width: 100},
                { text: this.$t('view.pending_employee.avatar'), value:"avatarUrl", align: 'center', sortable: false},
                { text: this.$t('view.pending_employee.candidate_name'), align: 'start', value: 'name', width: 200},
                // { text: this.$t('view.pending_employee.status'), align: 'start', value: 'status', width: '9rem'},
                { text: this.$t('view.pending_employee.deny_job'), align: 'start', value: 'notAccept', width: '9rem'},
                // { text: 'Ngày phỏng vấn', align: 'start', value: 'interviewAt', width:'12rem'},
                { text: this.$t('view.pending_employee.job'), align: 'start', value: 'job', width: 200},
                { text: this.$t('view.pending_employee.id_number'), align: 'start', value: 'idNumber', width: 100},
                { text: this.$t('view.pending_employee.id_issue_place'), align: 'start', value: 'idPlace', width: 150},
                { text: this.$t('view.pending_employee.id_issue_date'), align: 'start', value: 'idDate', width: 100},
                { text: 'Thành phố', align: 'start', value: 'city', width: 250},
                { text: 'Quận', align: 'start', value: 'district', width: 250},
                { text: 'Phường', align: 'start', value: 'ward', width: 250},
                { text: 'Địa chỉ', align: 'start', value: 'address', width: 250},
                { text: 'Phone', value: 'phone', width: 200},
                { text: 'Mail', value: 'mail', width: 200 },
                { text: 'Nơi làm việc mong muốn 1', value: 'city1', width: 200 },
                { text: 'Nơi làm việc mong muốn 2', value: 'city2', width: 200 },

                { text: 'Trường', value: 'university', width: 200 },
                { text: 'Học vấn', value: 'educationLevel', width: 200 },
                { text: 'Chuyên ngành', value: 'educationMajor', width: 200 },
                { text: 'Ngày gửi', align: 'start', value: 'createdAt', width:'12rem'},
            ],
        }
    },
    methods: {
        formatStatus(value) {
            switch(value){
                case "NEW":
                    return this.$t('view.pending_employee.status_new')
                case "PEND":
                    return this.$t('view.pending_employee.status_pend')
                case "PASS":
                    return this.$t('view.pending_employee.status_pass')
                case "FAIL":
                    return this.$t('view.pending_employee.status_fail')
                case "ACCEPT":
                    return this.$t('view.pending_employee.status_accept')
                case "DENY":
                    return this.$t('view.pending_employee.status_deny')
            }
        },
        formatStatusColor(value) {
            switch(value){
                case "NEW":
                    return "light"
                case "PEND":
                    return "orange"
                case "PASS":
                    return "success"
                case "FAIL":
                    return "error"
                case "ACCEPT":
                    return "teal"
                case "DENY":
                    return "purple"
            }
        },
        
        close () {
            this.acceptDialog = false;
            this.targetItem=undefined;
            this.form = Object.assign({}, this.defaultForm);
        },
        
        recruit(item)
        {
            return http.post("employeeResource/recruit/"+item.code, this.form).then(res=> {
                item.status = "ACCEPT";
                this.fetchData();
                this.close();
            })
        },
        undo(item)
        {
            return http.get("employeeResource/undo/"+item.code).then(res=> {
                this.fetchData();
            })
        },
        deny(item)
        {
            return http.get("employeeResource/deny/"+item.code).then(res=> {
                this.fetchData();
            })
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        fetchJob() {
            return http.get("jobs").then(res=> {
                this.jobs = res.data;
            })
        },
        fetchCity() {
            return http.get("cities/fetch").then(res=> {
                this.cities = res.data;
            })
        },
        fetchDepartments(deptType){
            if(deptType)
                return http.get("departmentTypes/fetch-department/"+ deptType).then(res => {
                    this.departments = res.data;
                })
        },

        fetchTemporaryStores(){
            return http.get("temporaryStores").then(res => {
                this.temporaryStores = res.data.data;
            })
        },
        fetchDepartmentTypes(){
            return http.get("departmentTypes/fetch").then(res => {
                this.departmentTypes = res.data;
            })
        },
        
        fetchShift(val) {
            return http.get("shifts").then(res => {
                this.shifts = res.data;
            });
        },
        
        fetchPosition(val) {
            if(val)
                return http.get(this.POSITION_FETCH+val).then(res => {
                    // if(!this.fetchData)
                    // {
                    //     this.positions=[];
                    //     if(!res.data.map(x=>x.code).includes(this.form.positionCode))
                    //         this.form.positionCode=null;
                    // }
                    this.positions = res.data;
                    if(this.positions.map(x=>x.code).includes("CV00061"))
                    {
                        this.form.positionCode = "CV00061";
                    }
                    if(this.positions.map(x=>x.code).includes("CV00067"))
                    {
                        this.form.positionCode = "CV00067";
                    }
                    // if(this.positions.map(x=>x.code).includes("CV00061"))
                    //     this.form.positionCode = "CV00067";
                });
        },

        fetchPositionState(val) {
            let url = `${this.POSITION_STATE_FETCH}/${val}`;
            return http.get(url).then(res => {
                // if(!this.fetchData)
                // {
                //     this.positionStates=[];
                //     if(!res.data.map(x=>x.code).includes(this.form.positionStateCode))
                //         this.form.positionStateCode=null;
                // }
                this.positionStates = res.data;
            });
        },

        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.item1s = res.data.results;
                this.rowCount = res.data.rowCount;

            })
            .catch(err => {

            })
        },
        fetchData2 () {
            return http.get(this.ROOT_URL, {
                params: this.param2s
            })
            .then(res => {
                this.isLoading = false;
                this.item2s = res.data.results;
                this.rowCount2 = res.data.rowCount;

            })
            .catch(err => {
                
            })
        },

        fetchUni(){
            return http.get("Universities?dropDown=1").then(res => {
                this.unis = res.data
            })
        },
        fetchMajor(){
            return http.get("educationMajors?dropDown=1").then(res => {
                this.majors = res.data
            })
        },
        fetcheducationLevels(){
            return http.get("educationLevels?dropDown=1").then(res => {
                this.eduLvls = res.data
            })
        },
        edit(event ){
            this.item = event;
            this.dialog = true;
        },

        save(item){
            return http.post("candidates/quick-action/"+item.code, item).then(res=>{
                console.log(res.data);
            })
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete("candidates/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
        timeFormat(val){
            return moment(val).format("YYYY-MM-DD")
        }
    },
    created() {
        this.fetchJob();
        this.fetchCity();
        this.fetchUni();
        this.fetchMajor();
        this.fetcheducationLevels();
        this.fetchData();
        this.fetchData2();
        this.fetchShift();
        this.fetchTemporaryStores();
        this.$emit("setUrl",  this.breadcrumbs);
        this.fetchDepartmentTypes();
    },
    destroyed() {
        this.$emit("setUrl", []);
    }
}
</script>
<style scoped>
.table-cursor tbody tr:hover {
cursor: pointer;    
}
::v-deep .no-hover:hover{
}
::v-deep table > tbody > tr.no-hover:hover
{
    background-color: white !important;
}
</style>