<template>
        <v-dialog v-model="dialog" max-width="600px" scrollable>
            <v-card>
                <v-toolbar color="secondary" flat dark>
                    <v-toolbar-title>{{isEdit ? "Thay đổi" :"Thêm"}} ghi nhận công</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-3" small color="success" type="submit" form="form" :disabled="isEdit"> <v-icon small left>mdi-content-save</v-icon> Lưu</v-btn>
                    <v-icon small @click="dialog=false"> mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="isValid" ref="form" @submit.prevent="saveData" id="form">
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete item-text="name" item-value="code" :items="employees" v-model="form.employeeCode" :search-input.sync="employeeSearch"
                                label="Nhân viên" hide-no-data @change="fetchEmployee" :rules="[v => !!v || 'Chọn một nhân viên']"></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field type="date" label="Ngày điểm danh" v-model="form.date"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete item-text="name" item-value="code" :items="departments" v-model="form.departmentCode" :search-input.sync="departmentSearch"
                                label="Nơi chấm công" hide-no-data @change="fetchDepartment"></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="form.checkIn" label="Giờ checkin" type="time"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="form.checkOut" label="Giờ checkout" type="time"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="form.ipIn" label="IP In"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="form.ipOut" label="IP Out"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item"],
    watch:{
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        dialog: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "enrollments/",
            EMPLOYEE_FETCH: "employees/search",
            DEPARTMENT_FETCH: "departments/fetch",

            isValid: true,
            departmentSearch: null,
            departments: [],
            employeeSearch: null,
            employees: [],
            form: {
                date: moment().format("YYYY-MM-DD"),
                employeeCode:null,
                deparmentCode: null,
                checkIn: moment().format("HH:mm"),
                checkOut: moment().format("HH:mm"),
                ipIn: "",
                ipOut: "",
            },
            defaultForm:{
                date: moment().format("YYYY-MM-DD"),
                employeeCode:null,
                deparmentCode: null,
                checkIn: moment().format("HH:mm"),
                checkOut: moment().format("HH:mm"),
                ipIn: "",
                ipOut: "",
            }
        }
    },
    methods: {
        fetchEmployee(val) {
            return http.get(this.EMPLOYEE_FETCH, {
                params: val
            }).then(res => {
                this.employees = res.data;
            });
        },
        
        fetchDepartment(val) {
            return http.get(this.DEPARTMENT_FETCH, {
                params: val
            }).then(res => {
                this.departments = res.data;
            });
        },
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                this.form = Object.assign({}, res.data.item);
                this.fetchEmployee(this.form.employeeCode);
                this.fetchDepartment(this.form.deparmentCode);
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    return http.post(this.ROOT_URL, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        }
    },
    created() {
    }
    
}
</script>