<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/512/4727/4727424.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Đang làm việc</h4>
                <h2 class="text-h5 font-weight-bold">{{ onlineNumber }}</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/128/912/912267.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Tổng số</h4>
                <h2 class="text-h5 font-weight-bold">{{totalNumber}}</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/512/682/682464.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Nhân viên mới tháng này</h4>
                <h2 class="text-h5 font-weight-bold">{{ newNumber }}</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/512/1886/1886833.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Tài khoản bị khóa</h4>
                <h2 class="text-h5 font-weight-bold">67</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/512/3349/3349548.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Giờ công chưa xác nhận</h4>
                <h2 class="text-h5 font-weight-bold " >{{timesheetNumber}}</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/128/3387/3387188.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Nghỉ phép chưa xác nhận</h4>
                <h2 class="text-h5 font-weight-bold " >{{ unconfirmLeaveNumber }}</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/512/5260/5260075.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Làm thêm chưa xác nhận</h4>
                <h2 class="text-h5 font-weight-bold " >{{ unconfirmOvertimeNumber }}</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card >
          <v-card-text class="text--primary">
            <div class="d-flex align-center">
              <v-img src="https://cdn-icons-png.flaticon.com/512/5218/5218982.png" class="mr-4" max-width="128">
              </v-img>
              <div>
                <h4 class="text-subtitle-1 font-weight-normal">Công tác chưa xác nhận</h4>
                <h2 class="text-h5 font-weight-bold " >{{ unconfirmBusinessNumber }}</h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12" md="6" class="pt-0">
      <v-subheader>
        <h3 class=" text-primary green--text text--darken-4">DANH SÁCH TÀI KHOẢN BỊ KHÓA</h3> 
        
        <vue-excel-xlsx
            :data="checkinBlacklistItems"
            :columns = "convertToExcelLabel(checkinBlacklistHeaders)"
            :file-name="'DU_LIEU_CHO_XAC_NHAN'"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
            >
                <v-btn color="green" small dark text> <v-icon left>mdi-microsoft-excel</v-icon></v-btn>
        </vue-excel-xlsx>
      </v-subheader>
        <v-card>
          <v-data-table :items="checkinBlacklistItems" :headers="checkinBlacklistHeaders" fixed-header height="341px">
            <template v-slot:[`item.date`]="{item}">
              {{ formatDate(item.date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="pt-0">
      <v-subheader>
        <h3></h3>
      </v-subheader>
      
        <v-card min-height="400px" class="d-flex align-item-center">
          <div style="position: relative; height:350px; width:350px; " class="mx-auto my-auto">
            <canvas id="myChart"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>
<script>

import {Chart} from "chart.js";
import api from "./hr_api"
import moment from 'moment/moment';

export default {
  data() {
    return {
      onlineNumber: 0,
      totalNumber: 0,
      newNumber:0,
      
      timesheetNumber:0, 
      unconfirmLeaveNumber:0,
      unconfirmOvertimeNumber:0,
      unconfirmBusinessNumber:0,

      employeeDtRatio: [],

      checkinBlacklistItems: [],
      checkinBlacklistHeaders:[
        {text: "Mã", value: "id", width:"80px"},
        {text: "Mã NS", value: "employeeCode", width:"100px"},
        {text: "Tên NS", value: "employeeName", width: "200px"},
        {text: "Phòng(Chi nhánh)", value: "departmentName", width: "150px"},
        {text: "Khối", value: "departmentTypeName", width: "120px"},
        {text: "Ngày", value: "date"},
        {text: "Mã TK", value: "userCode"},
        {text: "Tên TK", value: "userName"},
        {text: "Mã phòng", value: "departmentCode"},
        {text: "Mã khối", value: "departmentTypeCode"},
      ]
    }
  },
  
  methods: {
    convertToExcelLabel(value) {
        if(Array.isArray(value)) {
            return value.map(x => {
                return {
                    label: x.text,
                    field: x.value
                }
            })
        }
    },
    formatDate(value){
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    
    getOnlineNumber() {
      return this.apiServices.getOnlineEmployee().then(d => {
        this.onlineNumber= d
      })
    },

    getTotalNumber() {
      return this.apiServices.getTotalEmployee().then(d => {
        this.totalNumber = d
      })
    },

    getNewNumber() {
      return this.apiServices.getNewEmployee().then(d => {
        this.newNumber = d
      })
    },
    
    getRequestNumber() {
      return this.apiServices.getUnconfirmeds().then(d => {
        console.log(d)
        this.unconfirmLeaveNumber = d.leaveNumber
        this.unconfirmOvertimeNumber = d.overtimeNumber
        this.unconfirmBusinessNumber = d.businessNumber
      })
    },

    getTimesheetUnconfirmed(){
      return this.apiServices.getTimesheetUnconfirmed().then(d => {
        this.timesheetNumber = d
      })
    },
    
    getEmployeeDtRatio() {
      return this.apiServices.getEmployeeDtRatio().then(d => {
        this.employeeDtRatio = d
        
        //init graph
        const ctx = document.getElementById('myChart');
        const data = {
          labels: [
            'Hỗ trợ',
            'Vận hành'
          ],
          datasets: [{
            label: 'My First Dataset',
            data: this.employeeDtRatio,
            backgroundColor: [
              'rgb(255, 152, 0, 0.8)',
              'rgb(27, 94, 32, 0.8)',
            ],
            hoverOffset: 4
          }]
        };
        const config = {
          type: 'pie',
          data: data,
          options: {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: 'Tỉ lệ vận hành & hỗ trợ',
                    position: 'bottom',
                    font: {
                      size: 18
                    }
                }
            }
          }
        };
        new Chart(ctx, config);
      })
    },
    getCheckinBlackList() {
      return this.apiServices.getCheckinBlackList().then(d => {
        this.checkinBlacklistItems = d
      })
    }
  },
  apiServices: null,
  created() {
    this.apiServices = new api()
  },
  mounted() {
    this.getTimesheetUnconfirmed()
    this.getOnlineNumber()
    this.getTotalNumber()
    this.getNewNumber()
    this.getRequestNumber()
    this.getEmployeeDtRatio()

    this.getCheckinBlackList()


  }
}
</script>