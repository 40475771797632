
import * as moment from "moment/moment";
import http from '@/components/services/http-common';

export default {
    props:["value", "item"],
    computed:{
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }, 
        headers() {
            return [
                // { text: 'Thao tác', value: 'actions', width: "7rem"},
                { text: 'Mã', value: 'code', filter: value=> {return this.filter(value, this.filters.code)}, width: "3rem"},
                { text: 'Mã nhân viên', value: 'employeeCode', filter: value=> {return this.filter(value, this.filters.employeeCode, true)}, width: "3rem"},
                { text: 'Nhân viên', value: 'employee', filter: value=> {return this.filter(value, this.filters.employee, true)}, width: "9rem"},
                { text: 'Ngày nhận việc', value: 'hireDate', width: "7rem"},
                { text: 'Khối', value: 'departmentType', width: "7rem"},
                { text: 'Phòng', value: 'department', width: "7rem"},
                { text: 'Vị trí', value: 'position', width: "7rem"},
                { text: 'Chức danh', value: 'positionState', width: "7rem"},
                { text: 'Ngân hàng', value: 'bank', width: "7rem"},
                { text: 'STK', value: 'bankNumber', width: "7rem"},
                { text: 'Trạng thái', value: 'status', filter: value=> {return this.filter(value, this.filters.status)}, width: "7rem"},
                { text: 'Ngày nghỉ', value: 'quitDate', width: "auto"},
                { text: 'Ngày làm lại', value: 'endDate', width: "auto"},
                { text: 'Trong kì', value: 'inReport', width: "auto", filter: value=> { return this.filters.inReport ==null ? true : this.filters.inReport == value} },
                { text: 'Ghi chú', value: 'note', width: "auto"},
            ]
        },
    },
    
    watch:{
        
        dialog: {
            handler(value){
                if(value)
                {
                    console.log(this.isEdit)
                    if(this.isEdit)
                    {
                        this.fetch();
                    }
                }
                else{
                    this.close();
                }
            }
        }
    },
    data() {
        return {
            BASE_URL: "MONTHLYEMPLOYEES",
            
            isValid: false,
            loading: false,
            form: {
                name: "Chốt kì nhân viên tháng",
                month: moment().startOf("month").format("YYYY-MM-DD"),
                total: 0,
                online: 0,
                suspend: 0,
                pregnant: 0,
                quit: 0,

                monthSuspend: 0,
                monthPregnant: 0,
                monthQuit: 0,
            },
            defaultForm: {
                name: "Chốt kì nhân viên tháng",
                month: moment().startOf("month").format("YYYY-MM-DD"),
                total: 0,
                online: 0,
                suspend: 0,
                pregnant: 0,
                quit: 0,
                
                monthSuspend: 0,
                monthPregnant: 0,
                monthQuit: 0,
            },
            items:[],

            filters:{
                status: null,
                code:"",
                employeeCode: "",
                employee:"",
                inReport: ""
            },
            defaultFilters:{
                status: null,
                code:"",
                employeeCode: "",
                employee:"",
                inReport: ""
            }
        }
    },
    methods: {
        save() {
            this.$refs.monthlyEmployeeForm.validate();
            this.loading = true;
            if(this.isValid){
                return http.post(this.BASE_URL, this.form).then(res => {
                    this.loading = false;
                    this.form = Object.assign({}, res.data.data);
                    this.items = res.data.items;
                }).catch(err => {
                    this.loading = false;
                })
            }
        }, 
        filter(value, filterValue, uppercase = false)
        {
            if(uppercase)
            {
                value = value.toString().toUpperCase();
                filterValue = filterValue.toString().toUpperCase();
            }
            if (!filterValue) return true
  
            return value.includes(filterValue)
        },

        fetch(){
            return http.get(this.BASE_URL+'/'+this.item.code).then(res => {
                this.form = Object.assign({}, res.data.data);
                this.items = res.data.items;
            });
        },
        
        download() {
            return http.get("monthlyEmployees/export/"+this.item.code, {
                // params: this.form,
                responseType: 'arraybuffer',
            }).then((res)  => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'DU_LIEU.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((err)  => {
                console.log(err);
            });
        },
        
        close(){
            this.$refs.monthlyEmployeeForm.resetValidation();
            this.filters = Object.assign({}, this.defaultFilters);
            this.form = Object.assign({}, this.defaultForm);
            this.$emit("fetchData");
            this.items = [];
        }
    },

    created(){
    }
}