<template>
    <v-dialog :value="value" max-width="70%" @click:outside="close" @keydown.esc="close">
      <v-card>
        <v-card-title class="headline">
            Chọn nhân viên tạo tài khoản
            <v-spacer></v-spacer>
            <v-text-field v-model="search" @keydown.enter="fetchData" class="mr-2" append-icon="mdi-magnify"
                label="Tìm kiếm" single-line hide-details>
            </v-text-field>
        </v-card-title>
        <v-data-table  max-width="100%" class="row-pointer"
        :headers="headers"
        :items="employees"
        :options.sync="options"
        :server-items-length="totalItem"
        :loading="isLoading"
        loading-text="Đang tải dữ liệu..."
        dense
        @click:row="handleRow"
        >
            <template
                v-slot:[`item.gender`]="{ item }"
            >
                {{ item.gender ? "Nam" : "Nữ" }}
            </template>

            <template
                v-slot:[`item.birthday`]="{ item }"
            >
                {{ formatDate(item.birthday) }}
            </template>
            
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
      </v-card>
    </v-dialog>
</template>

<script>
import http from '@/components/services/http-common'
import * as moment from "moment/moment"

export default {
    name: "EmployeeDialog",
    components:{

    },
    props: ['value'],
    computed: {
        dialog: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('dialogClose');
            }
        }
    },
    data() {
        return{
            headers: [
                { text: 'Mã nhân viên', value: 'code' },
                {
                    text: 'Tên nhân viên',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Giới tính', value: 'gender' },
                { text: 'Ngày sinh', value: 'birthday' },
                { text: 'Chức vụ', value: 'position' },
                { text: 'Phòng ban', value: 'department' },
                { text: 'Khối', value: 'group' },
            ],
            options: {},
            totalItem: 0,
            employees: [],
            isLoading: true,
            search: '',

            params: {

            }
        }
    },
    watch: {
        options: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        search:{
            handler() {
                this.options.page = 1;
            }
        },
        value: function (oldval) {
            if(oldval)
                this.fetchData();
        }
    },
    methods: {
        close() {
            this.$emit("CloseDialog");
        }, 
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        handleRow (row) {
            this.$emit('rowSelected', row);
            this.close();
        },
        fetchData () {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            if(page != undefined || itemsPerPage != undefined)
                http.get('employees?search='+this.search + '&page='+ page + '&pageSize=' + itemsPerPage ).then(res => {
                    this.isLoading = false;
                    this.employees = res.data.items;
                    this.totalItem = res.data.totalItem;
                }).catch(err => {
                    console.log(err);
                });
        },
    }
}
</script>

<style lang="css" scoped>
    .row-pointer >>> tbody tr :hover {
    cursor: pointer;
    }
</style>