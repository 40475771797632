import http from '@/components/services/http-common'

const baseUrl = `${process.env.VUE_APP_BASE_URL}api/imageReportCategories`

export default class DepartmentService {
  gets = () => http.get(`${baseUrl}/`).then(res => res).then(d => d.data)

  get = (id) => http.get(`${baseUrl}/${id}`).then(res => res).then(d => d.data)

  codeChecking = (input) => http.get(`${baseUrl}/code-checking`, {
    params: {
      code: input
    }
  }).then(d => d.data)

  post = (data) => http.post(`${baseUrl}`, data)

  put = (id, data) => http.put(`${baseUrl}/${id}`, data)

  // resetDevice = (id, note) => http.get(`${baseUrl}/reset-device/${id}`, {
  //   params: {note: note}
  // })
  
  // getDeptIp = (id) => http.get(`${baseUrl}/ip-list/${id}`).then(res => res).then(d => d.data)

  // updateIp = (id, data) => http.put(`${baseUrl}/update-ip/${id}`, data).then(res => res).then(d => d.data)
}