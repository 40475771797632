<template>
  <div>
    <v-btn icon color="info" outlined @click="dialog=true">
      <v-icon class="mx-3" color="info">mdi-help</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog" 
      max-width="600px"
      transition="dialog-transition"
    >
        <v-card>
          <v-card-title>Hướng dẫn</v-card-title>
          <v-card-text class="text--primary body-1">
            <ul>
              <li><b>Danh sách </b>hiển thị các phòng ban trong hệ thống, nhập vào ô <b class="cyan--text">Tìm kiếm</b> để tìm kiếm phòng ban</li>
              <li><b>Cập nhật IP </b>chuột phải vào phòng ban cần cập nhật, nhập IP vào ô dữ liệu và ấn <b class="green--text">Lưu</b></li>
            </ul>
          </v-card-text>
        </v-card>
      
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    }
  }
}
</script>