
import http from '@/components/services/http-common';
import DatePicker from '@/components/DatePicker';
import EventBus  from "@/components/services/EventBus";
import * as moment from "moment/moment";

export default {
    name: "DepartmentCreateDialog",
    components:{
        "date-picker": DatePicker,
    },
    data() {
        return {
            DEPARTMENT_URL: "departments",
            CITY_URL: "cities",
            //*************** Validate **************
            rules: {
                nameRules:[ 
                    v => !!v || 'Tên nhân viên bắt buộc nhập',
                    v => v.length > 5 || 'Phải nhiều hơn 5 kí tự',
                ],
            },
            teamCreateDialog: false,
            teamHeaders: [
                { text: 'Mã', value: 'code' },
                { text: 'Tên Team', value: 'name' },
                { text: 'TeamLead', value: 'managerName' },
                { text: '', value: 'delete' },
            ],
            teams: [],
            //************* Form config */
            rootUrl: "departments",
            activetab: 0,
            valid: false,
            item: undefined,
            isUpdate: false,
            dialog: false,
            IDdateMenu: false,
            HireMenu: false,
            formData: {
                departmentTypes: [],
                positions: [],
                areas: [],
                cities: [],
                employees: [],
                departments:[],
            },

            teamForm: {
                name: '',
                managerCode: ''
            },
            form: {
                name:"",
                alias: "",
                address: "",
                positionCodes: [],
                phone: "",
                departmentTypeCode: "",
                description: "",
                areaCode:"",
                launchDate: "2100-01-01",
                ipList:"",
                cityCode: "",
                isOffice: false,
                managerCode: "",
                asmCode: null,
            },
            defaultForm: {
                name:"",
                alias: "",
                address: "",
                positionCodes: [],
                phone: "",
                departmentTypeCode: "",
                areaCode:"",
                description: "",
                launchDate: "2100-01-01",
                ipList:"",
                cityCode: "",
                isOffice: false,
                managerCode: "",
                asmCode: null,
            },
            
            dateValidRules: [
                v => moment(v, 'DD/MM/YYYY', true).isValid() || 'Định dạng ngày không đúng',
            ],
        };
    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật phòng" : "Tạo mới phòng";
        }
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
    },
    methods: {
        formatDate(value){
            alert(value)
            return moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        },
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        fetchUpdate (code){
            return http.get(this.rootUrl + "/"+code).then(res => {
                this.form = Object.assign({}, res.data);
            }).then(() => {
                this.getTeam(this.form.code)
            }).catch(err => {
                console.log(err);
            });
        },
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        fetchForm() {
            return http.get(this.rootUrl + "/fetch-form").then(res => {
                this.formData.positions = res.data.positions;
                this.formData.areas = res.data.areas;
                this.formData.cities = res.data.cities;
                this.formData.departmentTypes = res.data.departmentTypes;
                this.formData.employees = res.data.employees;
                this.formData.departments = res.data.departments;
            }).catch(err => {})
        },
        createData () {
            this.$refs.form.validate()
            if(!this.isUpdate)
                return http.post(this.rootUrl, this.form).then(res => {
                    this.dialog = false;
                    let eventObj = {
                        color: "success",
                        message: "Tạo mới thành công",
                        toast: true,
                    };
                    this.messageToast(eventObj);
                    this.$parent.fetchData();
                    
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            else{
                this.form.launchDate = this.form.launchDate? this.formatDate(this.form.launchDate) : '1900-01-01'
                return http.put('departments/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    let eventObj = {
                        color: "success",
                        message: "Cập nhật thành công",
                        toast: true,
                    };
                    this.messageToast(eventObj);
                    this.$parent.fetchData();
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        },

        getTeam(id) {
            return http.get('departments/team/'+id).then(r => {
                this.teams = r.data.data
            })
        },
        closeTeam() {
            this.teamForm.name = ''
            this.teamForm.managerCode = ''
            this.teamCreateDialog = false
        },
        createTeam() {
            this.teamForm.parentCode = this.form.code
            var merge = { ...this.form, ...this.teamForm }
            return http.post('departments/team', merge).then(() => {
                this.getTeam(this.form.code)
                this.closeTeam()
            })
        },
        deleteTeam(id) {
            return http.delete('departments/team/'+id)
        }
    },
    mounted() {
        this.fetchForm();
    }
}