<template>
    <v-dialog v-model="dialog" max-width="800px" scrollable>
        <v-card>
            <v-card-title>
                {{isEdit ? "Thay đổi" :"Tạo"}} hợp đồng
                <v-spacer></v-spacer> <v-icon small @click="dialog=false"> mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-form v-model="valid" ref="form" @submit.prevent="saveData" id="contractForm" class="mt-6">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field outlined dense hide-details v-model="form.signDate" type="date" style="width:200px"></v-text-field>
                            <!-- <date-picker2 :range="false" v-model="form.signDate" label="Ngày kí" outlined dense></date-picker2> -->
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field outlined dense hide-details v-model="form.expireDate" type="date" style="width:200px"></v-text-field>
                            <!-- <date-picker2 :range="false" :hideDetails="true" v-model="form.expireDate" label="Ngày hết hạn" :width="500"></date-picker2> -->
                        </v-col>
                        <!-- <v-col cols="12" md="6" offset="0" offset-md="2">
                            <v-text-field v-model="form.contractNumber" v-if="!!form.contractNumber" label="Số hợp đồng" outlined dense></v-text-field>
                            <v-select v-model="form.status" label="Trạng thái" outlined dense :items="[
                                {text: 'Mới', value:'0'},
                                {text: 'Duyệt', value:'1'}
                            ]" hide-details :rules="[v=>(!!v||v==0)||'Nhập dữ liệu']"></v-select>
                        </v-col> -->
                        <v-col cols="12">
                            <v-autocomplete outlined dense hide-details item-text="name" item-value="code" :items="employees" v-model="form.employeeAcode" :search-input.sync="employeeSearch" clearable
                            label="Nhân viên" hide-no-data @change="fetchEmployee" :rules='[v=>!!v||"Chọn dữ liệu"]' :readonly="!!employee || isEdit">
                                <template v-slot:selection="data">
                                    <strong>{{ data.item.code }}</strong> {{`: ${data.item.name} - ${data.item.department}`}}
                                </template>
                                <template v-slot:item="data">
                                    <strong>{{ data.item.code }} </strong> {{`: ${data.item.name} - ${data.item.department}`}}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete dense hide-details outlined :items="contractTypes" item-text="name" item-value="code" v-model="form.contractTypeCode" label="Loại hợp đồng"
                            :rules="[v=>!!v||'Nhập dữ liệu']"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field dense hide-details outlined v-model="form.salary" :rules="[
                                v=>(v!=null || v != undefined) || 'Nhập dữ liệu',
                                v =>  !/\D/.test(v) || 'Dữ liệu không đúng' 
                            ]" label="Lương" :hint="currencyFormat(form.salary)" suffix="vnd"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <!-- <v-select :items="[
                                            {text: '1 Tháng', value:'1months'},
                                            {text: '1 Năm', value:'1years'},
                                            {text: '3 Năm', value:'3years'},
                                            {text: 'Không thời hạn', value:'nolimit'},
                                        ]" v-model="form.lengthType" label="Thời hạn hợp đồng">
                            </v-select> -->
                            <v-select dense hide-details outlined :items="contractCategories"
                                item-text="name" item-value="code"
                             v-model="form.contractCategoryCode" label="Thời hạn hợp đồng">
                            </v-select>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-file-input v-model="file" label="File pdf" accept=".pdf">
                            </v-file-input>
                        </v-col> -->
                        <v-col cols="12" v-if="item && form.attachmentUrl">
                            <!-- <a :href="endpoint + form.attachmentUrl.replace('wwwroot/', '')" target="#">Xem file PDF</a> -->
                            <iframe :src="`https://docs.google.com/viewerng/viewer?url=${form.attachmentUrl}&embedded=true`" frameborder="0" height="800" width="100%">
                            </iframe>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="success" type="submit" form="contractForm"> Lưu</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    this.fetchContractType()
                    this.fetchContractCategory()
                    if(this.employee){
                        this.fetchEmployee(this.employee)
                        this.form.employeeAcode = this.employee
                    }
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        },
        "form.lengthType": function(val){
            if(val!='nolimit')
            {
                var length = val.match(/\d+/g);
                var unit = val.match(/[a-zA-Z]+/g);
                console.log(length.toString());
                var newDate = moment(this.form.signDate, "YYYY-MM-DD").add(length, unit);
                this.form.expireDate = newDate.format("YYYY-MM-DD");
            }else {
                this.form.expireDate = moment("2050-01-01").format("YYYY-MM-DD");
            }
        }

        // FETCHING DATA
        // "form.departmentTypeCode": function (val) {
        //     this.fetchDepartment(this.form.departmentTypeCode);
        // },
        // "form.departmentCode": function(val){
        //     this.fetchPosition(this.form.departmentCode);
        // },
        // "form.positionCode": function(val){
        //     this.fetchPositionState(this.form.positionCode);
        // },
        // "form.positionStateCode": function(val) {
        //     this.fetchShift(this.form.positionStateCode);
        // },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "contracts/",
            EMPLOYEE_FETCH: "employees/search",
            DEPARTMENT_FETCH: "departmentTypes/fetch-department/",
            DEPARTMENT_TYPE_FETCH: "departmentTypes/fetch",
            POSITION_STATE_FETCH: "positions/fetch-position-state",
            SHIFT_FETCH: "positionStates/fetch-shift/",
            POSITION_FETCH: "departments/fetch-position/",
            SCALE_FETCH: "scales/fetch",

            endpoint: process.env.VUE_APP_BASE_URL,
            valid: true,
            departmentSearch: null,
            departmentTypes: [],
            positionStates: [],
            departments: [],
            shifts: [],
            positions: [],
            scales: [],
            employeeSearch: null,
            employees: [],
            contractTypes: [],
            contractCategories: [],
            file: null,
            form: {
                code:null,
                employeeAcode:null,
                contractNumber:null,
                contractTypeCode:null,
                signDate:moment().format("YYYY-MM-DD"),
                expireDate:moment().format("YYYY-MM-DD"),
                createdBy:null,
                salary:null,
                length: 1,
                lengthUnit: "MONTH",
                lengthType:"1years",
                status:0,
                attachmentUrl:"",
            },
            defaultForm:{
                code:null,
                employeeAcode:null,
                contractNumber:null,
                contractTypeCode:null,
                signDate:moment().format("YYYY-MM-DD"),
                expireDate:moment("2100-12-31", "YYYY-MM-DD").format("YYYY-MM-DD"),
                createdBy:null,
                salary:null,
                length: 1,
                lengthUnit: "MONTH",
                lengthType:"1years",
                status:0,
                attachmentUrl:"",
            }
        }
    },
    methods: {
        currencyFormat(x) {
            if(x!=null)
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        fetchEmployee() {
            return http.get(this.EMPLOYEE_FETCH).then(res => {
                this.employees = res.data;
            });
        },
        
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                res.data.signDate = moment(res.data.signDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                res.data.expireDate = moment(res.data.expireDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                this.form = Object.assign({}, res.data);
                this.fetchEmployee();
                console.log(this.form)
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.valid)
            {
                if(!this.item)
                {
                    var formData = new FormData();
                    formData.append("attachmentFile", this.file);
                    formData.append("code", this.form.code);
                    formData.append("employeeAcode", this.form.employeeAcode);
                    formData.append("contractNumber", this.form.contractNumber);
                    formData.append("contractTypeCode", this.form.contractTypeCode);
                    formData.append("signDate", this.form.signDate);
                    formData.append("expireDate", this.form.expireDate);
                    formData.append("createdBy", this.form.createdBy);
                    formData.append("salary", this.form.salary);
                    formData.append("length", this.form.length);
                    formData.append("lengthUnit", this.form.lengthUnit);
                    formData.append("lengthType", this.form.lengthType);
                    formData.append("status", this.form.status);
                    
                    return http.post(this.ROOT_URL, formData)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                }else {
                    return http.put(this.ROOT_URL + this.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {

                    });
                }
            }
        },
        fetchContractType(){
            return http.get("contractTypes/fetch").then(res=>{
                this.contractTypes = res.data;
            })
        },
        fetchContractCategory() {
            return http.get("contractCategories/simple").then(res => {
                this.contractCategories = res.data
            })
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
        }
    },
    created() {
    }
    
}
</script>