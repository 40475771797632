import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'TemporaryStores';
// global api
class TemporaryStoreApi {
  gets() {
    return http.get(`${ROUTE}`).then(r => r.data.data)
  }
  
  getEmployee() {
    return http.get(`${ROUTE}/employee`).then(r => r.data.data)
  }

  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => r.data.data)
  }

  post(data) {
    return http.post(`${ROUTE}`, data)
  }
  put(id, data) {
    return http.put(`${ROUTE}/${id}`, data)
  }
  remove(id){
    return http.delete(`${ROUTE}/${id}`)
  }
  
  getFamilyTemp(id) {
    return http.get(`employeeFamilies/temp/${id}`).then(r => r.data)
  }

}

export default new TemporaryStoreApi();