<template>
    <div class="overtime-index">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog> -->
         
        <v-card class="mb-6 rounded-lg">
            <v-card-title>
                <v-btn icon @click="fetchData">
                    <v-icon color="black">mdi-refresh</v-icon>
                </v-btn>
                <vue-excel-xlsx
                    :data="items"
                    :columns = "convertToExcelLabel(headers)"
                    :file-name="'DU_LIEU_CHO_XAC_NHAN'"
                    :file-type="'xlsx'"
                    :sheet-name="'sheetname'"
                    >
                        <v-btn color="green" small dark> <v-icon left>mdi-microsoft-excel</v-icon>Xuất dữ liệu</v-btn>
                </vue-excel-xlsx>
                
                <v-spacer></v-spacer>
                <create-button @click="dialog=true" ></create-button>
                <v-divider class="mx-2" vertical></v-divider>
                <month-picker width="7rem" dense outlined v-model="month" label="Chọn tháng"></month-picker>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="filters.employeeCode" hide-details outlined dense label="Mã NS" persistent-placeholder></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="filters.employeeName" hide-details outlined dense label="Tên NS" persistent-placeholder></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="filters.nso" hide-details outlined dense label="NSO" persistent-placeholder></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card v-if="true" class="rounded-xl">

            <v-data-table height="600px"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.confirmedDate`]="{ item }">
                    {{ item.confirmedDate == "01/01/0001" ? "" : item.confirmedDate}}
                </template>
                
                <template v-slot:[`item.validFrom`]="{ item }" >
                    {{ $formatter.formatDate(item.validFrom) }}
                </template>
                <template v-slot:[`item.validTo`]="{ item }" >
                    {{ $formatter.formatDate(item.validTo) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    
                    <v-btn x-small color="white" class="mx-2" @click="editItem(item)">
                        <v-icon small>
                            mdi-pencil
                        </v-icon> Chỉnh sửa
                    </v-btn>
                    <!-- ********* Xoa ****** -->
                    <v-btn x-small color="error" @click="deleteItem(item)" v-if="!item.isLock">
                        <v-icon small>
                            mdi-delete
                        </v-icon> Thu hồi
                    </v-btn>
                    
                    <v-icon
                        small color="disable"
                        v-else
                    >
                        mdi-lock
                    </v-icon>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="600" eager>
            <v-card>
                <v-card-title>
                    <div v-if="targetItem">Cập nhật dữ liệu</div>
                    <div v-else>Thêm dữ liệu</div>
                </v-card-title>
                <v-card-text>
                    <main-form @submitted="$root.$emit('resetForm'), dialog=false"></main-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="teal darken-4" dark @click="$root.$emit('submitForm')">Lưu</v-btn>
                    <v-btn color="red" outlined @click="dialog=false, $root.$emit('resetForm')">Thoát</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
// import CreateButton from '@/components/Crud/CreateButton';
// import Create from './Create';
import TemporaryStoreDetailApi from './api'
import MainForm from './MainForm'
import DataFilter from '@/components/DataFilter.vue';
import MonthPicker from '@/components/MonthPicker.vue';

export default {
    name: "TemporaryStoreDetail",
    components : {
        // CreateButton,
        // "create-dialog": Create,
        MainForm,
        "data-filter": DataFilter,
        MonthPicker,
    },
    watch: {
        month: {
            handler(val)
            {
                this.fetchData();
            }
        }
        // options: {
        //     handler (val) {
        //         this.fetchData();
        //     },
        //     deep: true,
        // },
    },
    
    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
    },
    props: [],
    methods: {
        fetchData() {
            this.isLoading = true
            return TemporaryStoreDetailApi.gets().then(d => {
                this.items = d.data
                this.isLoading = false
            })
        },
        deleteItem(item) {
            this.targetItem = item
            this.dialogDelete = true
        },
        editItem(item){
            this.dialog = true
            this.$root.$emit('editForm',  item)
        },

        cfmDeleteItem(){
            TemporaryStoreDetailApi.delete(this.targetItem).then(()=>{
                this.fetchData()
            })
        },
        
        convertToExcelLabel(value) {
            if(Array.isArray(value)) {
                var exceptCols = ['actions', 'images']
                return value.filter(x=>!exceptCols.includes(x.value)).map(x => {
                    return {
                        label: x.text,
                        field: x.value
                    }
                })
            }
        },
    },
    data () {
        return {
            ROOT_URL: "OvertimeRequests",

            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            // filters:{
            //     actions: null,
            //     code: null,
            //     status: null,
            //     date: null,
            //     overtimeTypeCode: null,
            //     timeRange: null,
            //     length: null,
            //     note: null,
            // },
            //TABLE DATA
            // filter: {
            //     code:"",
            //     menu: false,
            //     search: "",
            //     searchAttr: "EmployeeA",
            //     department: "",
            //     from: new Date().toISOString().substr(0, 10),
            //     to: new Date().toISOString().substr(0, 10),

            //     searchAttrs: [
            //         {text: "Tên nhân viên", value: "EmployeeA"},
            //         {text: "Tên người ký", value: "EmployeeB"},
            //     ],
            //     departments: [],
            // },
            month: moment().format("YYYY-MM"),
            overtimeTypes:[],
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttr: [],
            },

            headers: [
                { text: '', value: 'actions', align:"center" },
                { text: 'Mã đơn', value: 'id' },
                { text: 'Mã NS', value: 'employeeCode', 
                    filter: value => {
                    if (!this.filters.employeeCode) return true
                    
                    return value.toLowerCase().includes(this.filters.employeeCode.toLowerCase())
                    },
                },
                { text: 'Nhân viên', value: 'employeeName', 
                    filter: value => {
                    if (!this.filters.employeeName) return true
                    
                    return value.toLowerCase().includes(this.filters.employeeName.toLowerCase())
                    },
                },
                { text: 'Chi nhánh', value: 'temporaryStoreName', 
                    filter: value => {
                    if (!this.filters.nso) return true
                    
                    return value.toLowerCase().includes(this.filters.nso.toLowerCase())
                    },
                },
                { text: 'Từ ngày', value: 'validFrom'},
                { text: 'Đến ngày', value: 'validTo'},
            ],
            filters: {
                employeeCode: '',
                employeeName: '',
                nso: ''
            },

            items: [],
            options: {},
            totalItem: 0,
            isLoading: true,

            //DIALOG
            dialog: false,
            dialogDelete: false,
            targetItem: null,
        }
    },
    created(){
        this.fetchData();
    }

}
</script>
<style scoped>
</style>