<template>
  <v-app id="app">
    <v-fade-transition mode="out-in">
      
      <!--The Main Content component go here-->
      <router-view></router-view>
    </v-fade-transition>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {
    // this.$messaging.getToken({ vapidKey: 'BEE4cvkZvnxAKritStmFd5LRqsAakAveteJE9yODRiHhP0yjzhZiNGvwnI5nm-XYZ5Gon-zh45OFAu6pzQkQ2U0' })
    // .then((currentToken) => {
    //   if (currentToken) {
    //     console.log('client token', currentToken)
    //   } else {
    //     console.log('No registration token available. Request permission to generate one.');
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    // })
  }
};
</script>
