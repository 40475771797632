<template>
    <div class="contract-index">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog=true" ></myBreadCrumb> -->
        <create-dialog v-model="dialog"  :item="item" @fetchData="fetchData"></create-dialog>
        
        <v-row class="my-0">
            <v-col cols="12" md="4" class="pa-0 ml-auto">
                <v-card class="rounded-0 rounded-tl-xl"  style="bottom: -8px; z-index:2">
                    <v-tabs 
                        v-model="tab" dark background-color="primary" right >
                        <v-tab >DANH SÁCH HỢP ĐỒNG
                        </v-tab>
                        <v-tab class="mr-4" >NGÀY CẤP PHÉP NĂM
                        </v-tab>
                        <!-- <v-tab>
                            Grid test
                        </v-tab> -->
                    </v-tabs>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="rounded-lg mb-5">
            <v-card-text class="text--primary">
                <v-row>
                    <v-col cols="12" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Mã</h5>
                        <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Mã NS</h5>
                        <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" outlined hide-details></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Tên NS</h5>
                        <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" outlined hide-details></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Phòng ban/Chi nhánh</h5>
                        <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode"
                        item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                        @update:search-input="fetchDepartment" outlined hide-details></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="12" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Ngày kết thúc HĐ</h5>
                        <date-picker2 placeholder="Nhập" v-model="filters.expire" dense outlined hide-details></date-picker2>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class=" rounded-xl">

            <v-tabs-items v-model="tab">
                <v-tab-item >
                    <v-card flat >
                        <v-card-title>
                            <!-- <span class="d-none d-sm-flex">
                                Danh sách hợp đồng
                            </span> -->
                            <date-picker2 v-model="dateRange" outlined dense :width="250" :hideDetails="true"></date-picker2>
                            <v-chip
                                class="mx-2"
                                close
                                color="red"
                                :outlined="!expireSelected"
                                :dark="expireSelected"
                                @click="expireSelected=true"
                                @click:close="expireSelected=false"
                            >
                                Hết hạn
                            </v-chip>

                            <v-chip
                                class="mx-2"
                                close
                                color="green"
                                :outlined="!monthlySelected"
                                :dark="monthlySelected"
                                @click="monthlySelected=true"
                                @click:close="monthlySelected=false"
                            >
                                Trong tháng
                            </v-chip>
                            <v-spacer></v-spacer>

                            <!-- <import-upload :url="IMPORT_URL" note="Các trường bắt buộc nhập trên TEMPLATE là [mã nhân viên, mã người ký, mã phòng]"></import-upload> -->
                            <!-- #FILTER MENU -->
                            <!-- <v-btn icon color="error" @click="warningDate" large class="rounded">
                                <v-icon>mdi-calendar-alert</v-icon>
                            </v-btn> -->
                            <refresh-button @click="fetchData"></refresh-button>
                            <excel-button @click="exportPrompt=true" :timeLimit="true"/>
                            <export-prompt url="contracts" v-model="exportPrompt"></export-prompt>
                            <import-button @click="importPrompt=true" />
                            <!-- <create-button @click="dialog=true"></create-button> -->
                            <!-- <v-btn small class="mx-3" @click="exportExcel" color="teal" dark> <v-icon>mdi-database-export</v-icon> Xuất</v-btn> -->
                            <!-- <data-filter2 :filterProps="filters2" :defaults="defaults" @fetchData="fetchData" >
                            </data-filter2> -->
                            
                        </v-card-title>
                        <v-data-table
                            :mobile-breakpoint="0"
                            height="50vh" fixed-header
                            :headers="headers"
                            :items="items"
                            :page.sync="tableParams.page"
                            :sort-by.sync="tableParams.sortBy"
                            :sort-desc.sync="tableParams.sortDesc"
                            :server-items-length="rowCount"
                            :loading="isLoading"
                            loading-text="Đang tải dữ liệu..." >
                            <!-- <template v-slot:[`body.prepend`]="" >
                                <tr class="filter">
                                    <td></td>
                                    <td>
                                    </td>
                                    <td></td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                    </td>
                                    <td colspan="100%"></td>
                                </tr>
                            </template> -->
                            
                            <template v-slot:[`header.expireDateStr`]="{header}" >
                                <v-badge color="error" :content="String(warning)">
                                {{header.text}}
                                </v-badge>
                            </template>

                            <template v-slot:[`item.actions`]="{item}" >
                                <edit-button @click="edit(item)"></edit-button>
                                <delete-button @click="deleteItem(item)" ></delete-button>
                                <!-- <v-btn icon class="" color="error">
                                    <v-icon>mdi-delete-circle</v-icon>
                                </v-btn> -->
                            </template>
                            <template v-slot:[`item.isCurrent`]="{item}" >
                                <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                            </template>
                            
                            <template v-slot:[`item.department`]="{ item }">
                                <v-chip class="ma-2 caption font-weight-bold" color="teal darken-4" dark >
                                    <v-icon left>
                                        mdi-store
                                    </v-icon>
                                    {{item.department}}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.signDateStr`]="{ item }" >
                                {{ formatTime(item.signDateStr) }}
                            </template>
                            <template v-slot:[`item.expireDateStr`]="{ item }" >
                                {{ formatTime(item.expireDateStr) }}
                            </template>

                            <template v-slot:[`item.employeeAName`]="{ item }" >
                                <a :href="item.attachmentUrl"><b>{{ item.employeeAName }}</b></a>
                            </template>
                            <template v-slot:[`item.attachmentUrl`]="{ item }">
                                <v-btn v-if="item.attachmentUrl" color="red" icon :href="endpoint + item.attachmentUrl.replace('wwwroot/', '')" target="_blank"> <v-icon>mdi-file</v-icon></v-btn>
                                <!-- <a :href="endpoint + (item.attachmentUrl?item.attachmentUrl.replace('wwwroot/', ''):'')" target="#">Xem file PDF</a> -->
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card>
                        <v-card-title> 
                            <v-spacer></v-spacer>
                            <v-text-field style="max-width:200px" v-model="search2" label="Tìm kiếm" persistent-placeholder outlined dense hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table :items="item2s" :headers="header2s" :search="search2">
                            
                            <template v-slot:[`item.yearlyLeaveAt`]="{item}" >
                                <v-text-field type="date" v-model="item.yearlyLeaveAt" @change="updateItem2(item.code, item.yearlyLeaveAt)" hide-details solo flat></v-text-field>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <ag-grid-vue
                        style="height: 200px"
                        class="ag-theme-quartz"
                        :columnDefs="columnDefs"
                        :rowData="rowData"
                        @cellEditingStarted="cellEditingStartedHandler"
                    >
                    </ag-grid-vue>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <confirm-dialog v-model="dialogDelete" :item="targetItem" @confirm="cfmDeleteItem"></confirm-dialog>

        <v-dialog v-model="importPrompt" max-width="800px" :fullscreen="importFs" scrollable>
            <v-card class="rounded-xl">
                <v-card-title>Import hợp đồng <v-spacer></v-spacer>
                    <v-icon class="mx-2" color="primary" @click="importFs=!importFs">mdi-fullscreen</v-icon>
                    <v-icon color="error" @click="importPrompt=false">mdi-close</v-icon>
                </v-card-title>
                <v-card-subtitle>
                    <a href="http://tshrm.api.trungsonpharma.com/uploads/static/template_import_contract.xlsx"><v-icon small left color="primary">mdi-download</v-icon> Import template </a>
                </v-card-subtitle>
                
                <v-card-text>
                    <v-row>
                        <v-col>
                            <h5 class="text-primary text-subtitle-2">1. File excel import</h5>
                            <v-file-input v-model="template" outlined prepend-icon="" dense> 
                                <template v-slot:[`append-outer`]>
                                    <v-btn class="rounded-xl" @click="importing" color="primary" icon :loading="uploadLoading" style="top: -8px"> <v-icon>mdi-upload</v-icon></v-btn>
                                </template>
                            </v-file-input>
                        </v-col>
                    </v-row>
                    <h5 class="text-primary text-subtitle-2">
                        2. Dữ liệu sẵn sàng import
                    </h5>
                    <v-divider></v-divider>
                    <v-data-table :headers="importHeaders" :items="imports" max-height="600" fixed-header height="auto" :items-per-page="-1">
                        <template v-slot:[`item.signDate`]="{ item }" >
                            {{ $formatter.formatDate(item.signDate) }}
                        </template>
                        <template v-slot:[`item.expireDate`]="{ item }" >
                            {{ $formatter.formatDate(item.expireDate) }}
                        </template>

                        <template v-slot:[`item.contractCode`]="{ item }" >
                            <a :href="item.attachmentURL">{{item.contractCode}}</a>
                        </template>
                        <template v-slot:[`item.salary`]="{ item }" >
                            <v-text-field :hide-details="true" :value="item.salary" outlined dense @change="changeContractImport(item)"></v-text-field>
                        </template>
                        <template v-slot:[`item.yearlyLeave`]="{ item }" >
                            <v-checkbox :true-value="1" :false-value="0" v-model="item.yearlyLeave" @change="changeContractImport(item)"></v-checkbox>
                        </template>
                        <template v-slot:[`item.yearlyLeaveAt`]="{ item }" >
                            <v-text-field type="date" :hide-details="true" :value="$formatter.formatDate(item.yearlyLeaveAt, 'YYYY-MM-DD')" outlined dense @change="changeContractImport(item)"></v-text-field>
                        </template>
                    </v-data-table>
                    <h5 class="text-primary text-subtitle-2">
                        3. Tạo dữ liệu
                    </h5>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="12" class="text-caption">
                            Kiểm tra thông tin nhân viên, xác nhận điều chỉnh ngày cấp phép năm cho nhân viên và ấn <span class="font-weight-bold teal--text text--darken-4">CẬP NHẬT</span> để tạo dữ liệu.
                            <p><b>Các dữ liệu đã tạo ra sẽ không được chép đè. Nếu có thay đổi vui lòng xóa dữ liệu trước khi import dữ liệu mới</b></p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn @click="importExec" dark color="teal darken-4"><v-icon left>mdi-database</v-icon> CẬP NHẬT</v-btn>
                        <!-- <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn> -->
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import http from '@/components/services/http-common';
import ContractCreate from './Create.vue';
import DataFilter from '@/components/DataFilter.vue';
import DataFilter2 from '@/components/DataFilter2.vue';
import ImportUpload from '@/components/ImportUpload';
import ExcelButton from '../../../components/Crud/ExcelButton.vue';
import { AgGridVue } from "ag-grid-vue";
import ConfirmDialog from '@/components/ConfirmDialog'
// import "@ag-grid-community/styles/ag-theme-quartz.css";
// import { AgGridVue } from "ag-grid-vue";

export default {
    name: 'Contract',
    components: {
        AgGridVue,
        ConfirmDialog,
        "create-dialog": ContractCreate,
        'data-filter': DataFilter,
        'data-filter2': DataFilter2,
        'import-upload': ImportUpload,
        ExcelButton,
    },
    watch: {
        // options: {
        //     handler (val) {
        //         this.fetchData();
        //     },
        //     deep: true,
        // },
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        monthlySelected(val) {
            if(val){
                this.dateRange=[moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
            } else {
                this.dateRange=[moment("1900-01-01", "YYYY-MM-DD").format("YYYY-MM-DD"), moment().endOf('month').format('YYYY-MM-DD')]
            }
        },
        expireSelected(val) {
            if(val){
                this.warningDate()
            } else {
                this.filters.expire = null
            }
        },

        importPrompt: {
            handler(val) {
                if(val){
                    this.getImported()
                }
            }
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        dateRange:{
            handler(val){
                if(val.length=2){
                    this.filters.from = val[0];
                    this.filters.to = val[1];
                }
            },
            immediate: true
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },

    data() {
        return {
            CONTRACT_URL: "contracts",
            DEPARTMENT_URL: "departments",
            IMPORT_URL: "contracts/import",
            EXPORT_URL: "contracts/export",
            //HEADER DATA
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Quản lí hợp đồng ',
                disabled: true,
                to: {
                    name: "Contract",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            endpoint: process.env.VUE_APP_BASE_URL,
            //TABLE DATA
            filterState:[],
            tab: null,
            
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            dateRange:[moment("1900-01-01", "YYYY-MM-DD").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                from: moment("1995-01-01").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
                expire: null,
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,
            warning: 0,

            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text: "Số HĐ", value: "code", width: "9rem"},
                {text: "Loại hợp đồng", value: "contractCategoryName", width: "9rem"},
                // {text: "PDF", value: "attachmentUrl", },
                {text: "Mã nhân viên", value: "employeeAcode", width: "9rem"},
                {text: "Tên nhân viên", value: "employeeAName", width: "14rem"},
                {text: "Phòng/Chi nhánh", value: "department", width: "14rem"},
                {text: "Ngày ký", value: "signDateStr", width: "9rem"},
                {text: "Ngày hết hạn", value: "expireDateStr", width: "9rem"},
                {text: "Người ký", value: "createdByName", width: "9rem"},
                // {text: "Loại hạn hợp đồng", value: "lengthType", width: "9rem"},
                // {text: "Hiện tại", value: "isCurrent"},
                {text: "Ghi chú", value: "note", width: "auto"},
            ],
            
            search2: '',
            header2s: [
                {text:"", value: "actions", width:"7rem"},
                {text: "MSNS", value: "code", width: "9rem"},
                {text: "Tên", value: "name", width: "9rem"},
                {text: "Ngày cấp phép năm", value: "yearlyLeaveAt", width: "9rem"},
            ],
            items: [],
            item2s: [],
            options: {},
            totalItem: 0,
            isLoading: true,
            monthlySelected: false,
            expireSelected: false,

            //DIALOG
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: {},

            exportPrompt: false,
            importPrompt:false,
            template: undefined,

            columnDefs: null,
            rowData: null,

            uploadLoading: false,
            importFs: false,
            importHeaders: [
                { text: 'Id', value: 'id' },
                { text: 'Mã HĐ', value: 'contractCode' },
                { text: 'Mã NS', value: 'employeeCode' },
                { text: 'Tên NS', value: 'employeeName' },
                { text: 'Phòng/Chi nhánh', value: 'departmentName' },
                { text: 'Chức vụ', value: 'positionName' },
                { text: 'Chức danh', value: 'positionStateName' },
                { text: 'Tạo bởi', value: 'createdBy' },
                { text: 'Lương CB', value: 'salary' },
                { text: 'Ngày ký', value: 'signDate' },
                { text: 'Ngày kết thúc', value: 'expireDate' },
                { text: 'Cấp phép năm', value: 'yearlyLeave' },
                { text: 'Ngày cấp phép năm', value: 'yearlyLeaveAt' },
                // { text: 'Link file đính kèm', value: 'attachmentURL' },
                // { text: 'Is Imported', value: 'isImported' },
                // { text: 'Imported At', value: 'importedAt' },
            ],
            imports: []
        }
    },
    methods: {
        changeContractImport(value) {
            return http.post(`contracts/import/change/${value.id}`, value).then(() => {
                return this.getImported()
            })
        },
        getImported() {
            return http.get("contracts/imports").then(res => {
                this.imports = res.data.data
            })
        },

        importing() {
            if(!this.template) {
                alert("Chọn file để import")
                return
            }
            var formData = new FormData();
            formData.append("files", this.template);
            this.uploadLoading = true
            return http.post("contracts/import", formData).then(() => { 
                this.getImported().then(() => {
                    this.uploadLoading = false
                })
            });
        },
        importExec() {
            return http.get("contracts/import/exec").then(()=> {
                this.getImported().then(() => {
                    this.importPrompt = false
                })
            })
        },

        formatDate(value, format = "DD/MM/YYYY") {
            return moment(value).format(format);
        },
        
        //DATA JS
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData (e) {
            return http.get(this.CONTRACT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.items.results;
                this.rowCount = res.data.items.rowCount;
                this.warning = res.data.warning;
            }).catch(err => {})
        },

        getItem2s() {
            return http.get("leaveEnrollments/yearly-leave-at").then(res => {
                this.item2s = res.data.data.map(x => {
                    return {
                    code: x.code,
                    name: x.name,
                    yearlyLeaveAt: this.formatDate(x.yearlyLeaveAt, 'YYYY-MM-DD')
                    }
                })
            })
        },

        updateItem2(id, date) {
            return http.put("leaveEnrollments/yearly-leave-at/"+id,{}, {
                params: {
                    date: date
                }
            })
        },

        formatTime(time) {
            return moment(time).format('DD/MM/YYYY')
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {

            }
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem (item) {
            this.targetItem = item.id;
            this.dialogDelete = true;
        },
        cfmDeleteItem() {
            return http.delete(this.CONTRACT_URL + "/" +this.targetItem).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        exportExcel() {
            let params =  { ...this.filterState, 
                                sortBy: this.options.sortBy, //array
                                sortDesc: this.options.sortDesc, //array
                                page: this.options.page,
                                };
            return http.get(this.EXPORT_URL, {
                params: params,
                responseType: 'arraybuffer',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'danh_sach_ho_so.xlsx');
                document.body.appendChild(link);
                link.click();
            })
        },
        warningDate() {
            this.filters.expire=[moment().add(-7,"days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
        },
        cellEditingStartedHandler(event) {
            console.log(event, "event?")
        }

    },
    created() {
        this.fetchDepartment();
        this.fetchData();
        this.getItem2s();
    },
    
    beforeMount() {
        this.columnDefs = [
        { field: "make", flex: 3, editable: true },
        { field: "model" },
        { field: "price" },
        ];

        this.rowData = [
        { make: "Toyota", model: "Celica", price: 35000 },
        { make: "Ford", model: "Mondeo", price: 32000 },
        { make: "Porsche", model: "Boxster", price: 72000 },
        ];
    },
}
</script>

<style scoped>
.ag-theme-quartz {
    /* disable all borders */
    --ag-borders: none;
}
</style>