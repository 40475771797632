<template>
    <v-dialog v-model="dialog" scrollable>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="orange" small dark class="ma-3" v-bind="attrs" v-on="on"> <v-icon left> mdi-database-lock</v-icon> 3. Chốt Công</v-btn>
        </template>
        <v-card >
            <v-card-title>Chốt Công {{month}}
                <v-spacer></v-spacer>
                <v-icon @click="dialog=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
            <v-tabs color="teal">
                <v-tab>Báo Cáo Công</v-tab>
                <v-tab>Báo Cáo Tăng Ca</v-tab>
                <v-btn class="my-auto m3-auto" color="orange" icon  @click="fetchData"><v-icon>mdi-refresh</v-icon></v-btn>
                <!-- <v-tabs-items> -->
                    <v-tab-item>
                        <v-data-table 
                        :mobile-breakpoint="0"
                        height="500px" fixed-header
                        :headers="workTimeHeaders"
                        :items="dItems"
                        :page.sync="tableParams.page"
                        :sort-by.sync="tableParams.sortBy"
                        :sort-desc.sync="tableParams.sortDesc"
                        :server-items-length="rowCount"
                        :loading="isLoading"
                        loading-text="Đang tải dữ liệu..."
                        >
                            <template v-slot:[`body.prepend`]="" >
                                <tr class="filter">
                                    <td>
                                        <v-text-field placeholder="Nhập" dense outlined hide-details v-model="filters.employeeCode"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field placeholder="Nhập" dense outlined hide-details v-model="filters.employeeName"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field placeholder="Nhập" dense outlined hide-details v-model="filters.departmentName"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field placeholder="Nhập" dense outlined hide-details v-model="filters.departmentType"></v-text-field>
                                    </td>
                                    <!-- <td>
                                        <v-select placeholder="Chọn" dense v-model="filters.status"
                                        :items='[{text:"Tất cả", value:-1},{text:"Mở khóa", value:0}, {text:"Khóa", value:1}]'></v-select>
                                    </td> -->
                                    <td colspan="100%"></td>
                                </tr>
                            </template>

                            <template v-slot:[`item.isConfirmed`]="{ item }">
                                <v-chip :color="$helper.formatStatus(item.isConfirmed).color" label small outlined>
                                    <strong> {{$helper.formatStatus(item.isConfirmed).text}}</strong>
                                </v-chip>
                            </template>
                            <template v-slot:[`item.isLock`] = "{item}">
                                <v-icon v-if="item.isLock" color="grey">mdi-lock</v-icon>
                                <v-icon v-else color="green">mdi-lock-open</v-icon>
                            </template>

                            <!-- BAO CAO CONG -->
                            <template v-for="workTimeHeader in workTimeHeaders.slice(4, -(symbols.length + appendHeaders.length))" v-slot:[`item.${workTimeHeader.value}`]="{ item }">
                                <div :key="workTimeHeader.value">
                                    <div v-if="workTimeHeader.isSunday && item.isOffice" >
                                        <span class="orange--text">CN</span>
                                    </div>
                                    <div v-else :key="workTimeHeader.value">
                                        <div v-if="item[workTimeHeader.value]"  class="ma-auto" style="cursor:pointer">
                                            <span v-if="item[workTimeHeader.value].symbol!='x'">{{item[workTimeHeader.value].symbol}}-{{round2Dec(item[workTimeHeader.value].leaveTime )}}</span>
                                            <span v-else> {{round2Dec(item[workTimeHeader.value].actualWorkTime + item[workTimeHeader.value].holidayActualTime + item[workTimeHeader.value].leaveTime)}} </span>
                                        </div>
                                        <div v-else>
                                            <span>KL</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>

                    <v-tab-item>
                        <v-data-table
                        :mobile-breakpoint="0"
                        height="500px" fixed-header
                        :headers="overtimeHeaders"
                        :items="dItems"
                        :page.sync="tableParams.page"
                        :sort-by.sync="tableParams.sortBy"
                        :sort-desc.sync="tableParams.sortDesc"
                        :server-items-length="rowCount"
                        :loading="isLoading"
                        loading-text="Đang tải dữ liệu...">
                            <!-- BAO CAO TANG CA -->
                            <template v-for="overtimeHeader in overtimeHeaders.slice(6, -(appendHeadersOvertime.length))" v-slot:[`item.${overtimeHeader.value}`]="{ item }">
                                <div :key="overtimeHeader.value">
                                    <div v-if="item[overtimeHeader.value]"  class="ma-auto" style="cursor:pointer">
                                        <span v-if="item[overtimeHeader.value].leaveTime>0 || item[overtimeHeader.value].isDayOff"> {{round2Dec(item[overtimeHeader.value].overtime + item[overtimeHeader.value].actualWorkTime)}} </span>
                                        <span v-else> {{round2Dec(item[overtimeHeader.value].overtime)}} </span>
                                    </div>
                                    <div v-else>
                                        0
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                <!-- </v-tabs-items> -->
            </v-tabs>
            </v-card-text>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="green" dark small class="ml-5" @click="excelDialog=true">Xuất Excel</v-btn> -->
                
                <v-btn dark small color="orange" @click="store" class="mx-3" :loading="storeLoading">
                    <v-icon left>mdi-cogs</v-icon> Chốt giờ công
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
import _ from 'lodash';

export default {
    props:["value", "item", "month"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch().then(()=>{
                            this.fetchDepartment(this.form.departmentTypeCode, true);
                            this.fetchPosition(this.form.departmentCode, true);
                            this.fetchShift(this.form.positionStateCode, true);
                        });
                }
                else{
                    this.close();
                }
            }
        },
        
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler: _.debounce(function(){
                    this.tableParams.page=1;
                    this.fetchData();
                }, 500),
            deep: true,
        },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
        
        dItems: function () {
            if(this.items)
            {
                let mapped = this.items.map(x=>{
                    let obj = Object.assign({}, x);
                    delete obj.timesheets;
                    delete obj.symbols;
                    x.timesheets?.forEach(y => {
                        obj[y.date] = y.value;
                    });
                    for(var i=0; i < this.symbols.length; i++){
                        obj[this.symbols[i]]=x.symbolValues==null?0: x.symbolValues[i]
                    }
                    return obj;
                })
                return mapped;
            }
        },
        
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        workTimeHeaders: function () {
            let date = moment(this.month+"-01", "YYYY-MM-DD");
            let end = moment(date.format("YYYY-MM-DD")).endOf("month");
            // date = date.add(1,"days")
            let dates = [];
            let cHeaders = [];
            while(date<=end){
                dates.push({ text: date.format("YYYY-MM-DD"), value: date.format("YYYYMMDD"), width: 50, sortable: false, isSunday: date.day()==0, class: date.isoWeekday()==7?'red-header':''})
                date = date.add(1, "days");
            }
            let symbolHeaders = [];
            if(this.symbols)
                this.symbols.forEach(x=>{
                    symbolHeaders.push({ text: x, value: x, width: 50, sortable: false})
                })
            cHeaders = this.headers.concat(dates).concat(symbolHeaders).concat(this.appendHeaders);
            return cHeaders;   
        },
        
        overtimeHeaders: function () {
            let date = moment(this.month+"-01", "YYYY-MM-DD");
            let end = moment(date.format("YYYY-MM-DD")).endOf("month");
            // date = date.add(1,"days")
            let dates = [];
            let cHeaders = [];
            while(date<=end){
                // if(date.isoWeekday()==1)
                //     this.sundayCodes.push(date.format("YYYYMMDD"));
                dates.push({ text: date.format("YYYY-MM-DD"), value: date.format("YYYYMMDD"), width: 50, sortable: false, isSunday: date.day()==0, class: date.isoWeekday()==7?'red-header':''})
                date = date.add(1, "days");
            }
            let symbolHeaders = [];
            this.symbols.forEach(x=>{
                symbolHeaders.push({ text: x, value: x, width: 50, sortable: false})
            })
            cHeaders = this.headers.concat(dates).concat(this.appendHeadersOvertime);
            return cHeaders;   
        }
        
    },
    data() {
        return {
            ROOT_URL: "monthlyTimeSheetStores/",
            EMPLOYEE_FETCH: "employees/search",
            DEPARTMENT_FETCH: "departmentTypes/fetch-department/",
            DEPARTMENT_TYPE_FETCH: "departmentTypes/fetch",
            POSITION_STATE_FETCH: "positionStates",
            SHIFT_FETCH: "positionStates/fetch-shift/",
            POSITION_FETCH: "departments/fetch-position/",
            SCALE_FETCH: "scales/fetch",
            
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            isLoading: false,
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentName: null,
                workDate: moment().startOf("month").format("YYYY-MM-DD"),
                status: -1,
                isConfirmed: null,
                month: moment().format("MM"),
                year: moment().endOf("month").format("YYYY"),
            },
            rowCount: 0,

            storeLoading: false,
            isValid: true,
            departmentSearch: null,
            departmentTypes: [],
            positionStates: [],
            departments: [],
            shifts: [],
            positions: [],
            scales: [],
            employeeSearch: null,
            employees: [],
            departmentName:"",
            year: null,
            symbols:[],
            
            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            finalizeReport: false,
            
            headers: [
                // { text: 'Thao tác', value: 'actions', width: 150},
                { text: 'Mã NV', align: 'start', value: 'code', width: 100},
                { text: 'Tên nhân viên', align: 'start', value: 'name', width: 200},
                { text: 'Phòng', align: 'start', value: 'department', width: 200},
                { text: 'Khối', align: 'start', value: 'departmentType', width: 200},
                // { text: 'Trạng thái', align: 'start', value: 'isLock', width: 100, sortable:false},
                // { text: 'Tháng', align: 'start', value: 'month', width: 100},
                // { text: 'Năm', align: 'start', value: 'year', width: 100},
            ],
            appendHeaders:[
                { text: 'Ngày công chuẩn', value: 'standardDate', width: 110, sortable: false},
                { text: 'Giờ công chuẩn', value: 'standardHour', width: 110, sortable: false},
                { text: 'Công thực tế', value: 'actualTime', width: 110, sortable: false},
                { text: 'Thiếu giờ', value: 'missingHour', width: 110, sortable: false},
                { text: 'Tăng ca chính thức', value: 'overtimeFinal', width: 110, sortable: false},
                { text: 'Làm thêm chính thức', value: 'extendFinal', width: 110, sortable: false},
                { text: 'Tổng giờ công sau bù', value: 'totalTimeFinal', width: 110, sortable: false}, //giờ công + giờ bù
                { text: 'Tổng giờ công chính thức', value: 'totalWorkTime', width: 110, sortable: false}, //giờ công + giờ bù + tăng ca + làm thêm
                { text: 'Cơm', value: 'lunch', width: 110, sortable: false},
            ],
            appendHeadersOvertime:[
                { text: 'Ngày công chuẩn', value: 'standardDate', width: 110, sortable: false},
                { text: 'Giờ công chuẩn', value: 'standardHour', width: 110, sortable: false},
                { text: 'Công thực tế', value: 'actualTime', width: 110, sortable: false},
                { text: 'Giờ làm thêm', value: 'extend', width: 110, sortable: false},

                { text: 'Giờ làm thiếu', value: 'missingHour', width: 110, sortable: false},
                { text: 'Công bù', value: 'missingCover', width: 110, sortable: false},
                { text: 'Công thiếu còn lại', value: 'missingRemain', width: 110, sortable: false},
                { text: 'Làm thêm còn lại', value: 'extendRemain', width: 110, sortable: false},

                { text: 'Tăng ca tối đa', value: 'overtimeBooking', width: 110, sortable: false},
                { text: 'Tăng ca thường', value: 'normalOvertime', width: 110, sortable: false},
                { text: 'Tăng ca ngày nghỉ', value: 'dayoffOvertime', width: 110, sortable: false},
                { text: 'Tăng ca lễ', value: 'holidayOvertime', width: 110, sortable: false},

                { text: 'Tăng ca chính thức', value: 'overtimeFinal', width: 110, sortable: false},
                { text: 'Làm thêm chính thức', value: 'extendFinal', width: 110, sortable: false},
                { text: 'Tổng giờ công sau bù', value: 'totalTimeFinal', width: 110, sortable: false},//giờ công + giờ bù
                { text: 'Cơm tăng ca', value: 'overtimeLunch', width: 110, sortable: false},
            ],
        }
    },
    methods: {
        
        round2Dec(val){
            return Math.round(val * 100) / 100
        },
        fetchDepartment(val, init=false) {
            if(!init){
                this.departments = [];
                this.departmentCode=null;
                this.positions = [];
                this.positionCode=null;
            }
            return http.get(this.DEPARTMENT_FETCH+val).then(res => {
                this.departments = res.data;
            });
        },
        
        fetchData () {
            this.params.month = this.month.split("-")[1]
            this.params.year = this.month.split("-")[0];
            this.isLoading = true;
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.symbols = res.data.symbols;
                this.rowCount = res.data.rowCount;
                this.sumObj = res.data.sumObj;
            })
            .catch(err => {
            }).finally(()=> {this.isLoading = false})
        },
        
        store()
        {
            this.isLoading = true;
            let month = moment(this.month, "YYYY-MM");
            this.storeLoading = false;
            return http.get("monthlyTimesheets/store", {
                params: {
                    month: month.format("MM"),
                    year: month.format("YYYY"),
                }
            }).then(res=>{
                
                this.isLoading = false;
            }).finally(()=> {
                this.storeLoading = false});
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
        },
    },
    created() {
        this.fetchData();
    }
    
}
</script>