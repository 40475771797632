<template>
   <vue-excel-xlsx
      :data="items"
      :columns = "convertToExcelLabel(headers)"
      :file-name="'DANH_SACH_PHIEU_HT'"
      :file-type="'xlsx'"
      :sheet-name="'sheetname'"
      >
      <v-list-item-title><v-btn color="teal darken-4" class="my-2" small outlined>Xuất excel</v-btn></v-list-item-title>
  </vue-excel-xlsx>
</template>

<script>
export default {
  props:['headers', 'items'],
  methods: {
    
    convertToExcelLabel(value) {
      if(Array.isArray(value)) {
          var exceptCols = []
          return value.filter(x=>!exceptCols.includes(x.value)).map(x => {
              return {
                  label: x.text,
                  field: x.value
              }
          })
      }
    },
  }

}
</script>