<template>
    <div class="create-leave">
        <v-dialog :value="value.state"  max-width="600px" persistent scrollable>
             <v-card>
                <v-card-title>
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} đơn điều chuyển
                    <v-spacer></v-spacer>
                    <v-btn small text @click="close"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text >
                <v-form @submit.prevent="saveData" v-model="valid" id="form" ref="form" class="font-weight-bold" >
                    <v-row class="mt-5">
                        <v-col cols="12">
                            <v-select :items="types" v-model="form.idType" label="Lý do điều chuyển" hide-details="auto" :rules="[v=> !!v ||'Nhập dữ liệu này!']" outlined></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-menu
                            v-model="menuDate1"
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                :value="formatValidFrom"
                                clearable
                                label="Ngày bắt đầu"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="form.validFrom = null"
                                :rules="[v=> !!v ||'Nhập dữ liệu này!']" hide-details="auto" outlined
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                landscape
                                locale="vi"
                                v-model="form.validFrom"
                                @change="menuDate1 = false"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete @change="fetchEmployee" label="Điều chuyển từ" v-model="form.fromDepartmentCode" :items="departments" :rules="[v=> !!v ||'Nhập dữ liệu này!']" hide-details="auto" outlined></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :disabled="!!!form.validFrom" label="Nhân viên điều chuyển" v-model="form.employeeCode" :items="employees" :rules="[v=> !!v ||'Nhập dữ liệu này!']" hide-details="auto" outlined></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete label="Điều chuyển đến" v-model="form.toDepartmentCode" :items="departments" :rules="[v=> !!v ||'Nhập dữ liệu này!']" hide-details="auto" outlined></v-autocomplete>
                        </v-col>
                        
                        <v-col cols="12">
                            <v-textarea v-model="form.note" label="Ghi chú" hide-details="auto" outlined></v-textarea>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-text-field label="Kết thúc" type="date" v-model="form.validTo" :rules="[v=> !!v ||'Nhập dữ liệu này!']"></v-text-field>
                        </v-col> -->
                    </v-row>
                </v-form>       
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark form="form" color="primary" type="submit" small>
                        Lưu
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
             </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DatePicker from "../../../components/DatePicker";
import http from '../../../components/services/http-common';
import EventBus  from "../../../components/services/EventBus";
import moment from 'moment'

export default {
    name: "Create",
    components: {
        "date-picker": DatePicker,
    },
    computed:{
        
        formatValidFrom () {
            return this.form.validFrom ? moment(this.form.validFrom).format('DD/MM/YYYY') : ''
        },
        amount() {
            let amount = 0;
            const day = (1000*60*60*24);
            if(Array.isArray(this.dates))
            {
                if(this.form.isMultiple)
                {
                    if(this.dates.length == 2){
                        let first = new Date(this.dates[0]);
                        let last = new Date(this.dates[1]);
                        amount = (last -first)/day;
                        return Math.abs(amount) +1;
                    }
                }
            }
            return amount;
        }
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            }
        },
        
        "form.validFrom": {
            handler(val)
            {
                this.fetchEmployee()
            }
        },
        "form.isMultiple": {
            handler(val)
            {
                if(val)
                    this.dates = [];
                else
                    this.dates = "";
            }
        },
    },

    props: ["value"],
    data () {
        return {
            //URL
            ROOT_URL: "employeeRotationRequests",
            LEAVEPLAN_URL: "leavePlans/valid-leave-plan/",
            LEAVEPLAN_URL2: "leavePlans/valid-leave-plan2/",

            //DATA
            valid: false,
            dateMenu: false,
            employees:[],
            departments:[],
            dates: "",
            ShiftObject: "",
            formData: {
                leaveAmountTypes: [
                    {text: "Nghỉ cả ngày", value: "1"},
                    {text: "Nghỉ 1/2 ngày", value: "0.5", disabled: false},
                ],
                fromMenu: false,
                toMenu: false,
                leavePlans: [],
            },
            
            menuDate1: false,
            types: [],
            form: {
                employeeCode: "",
                fromDepartmentCode:"",
                toDepartmentCode: "",
                validFrom: "",
                validTo: "2100-01-01",
                idType: null,
                note: ""
            },
            defaultForm: {
                employeeCode: "",
                fromDepartmentCode:"",
                toDepartmentCode: "",
                validFrom: "",
                validTo: "2100-01-01",
                idType: null,
                note: ""
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        errorToast (eventObj)
        {
            EventBus.$emit('hasError', eventObj);
        },

        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        dateApply (){
            if(this.isMultiple)
            {
                let dates = this.dates;
                let dt0 = new Date(dates[0]);
                let dt1 = new Date(dates[1]);
                if(dt0> dt1)
                    this.dates = [dates[1], dates[0]];
            }
            this.$refs.menu.save(this.dates);
        },

        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        
        fetchData () {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                this.form = Object.assign({}, item);
                console.log(this.form);
            })
            .catch(err => {
                console.log(err);
            });
        },

        fetchEmployee() {
            const obj = JSON.parse(localStorage.getItem('user'))
            var currentEmp = obj.employeeCode
            if(this.form.validFrom){
                return http.get("employees/fetch", {
                    params:{
                        asm: false,
                        detail: true,
                        asmArea: "",
                        date: this.form.validFrom,
                        all: 0
                    }
                }).then(res => {
                    var filtered = res.data.filter(x=>x.asm != null && x.departmentCode== this.form.fromDepartmentCode)
                    this.employees = filtered.map(x => {
                        return {
                            text: `${x.name}`,
                            value: x.code
                        }
                    })
                })
            }
        },
        fetchDepartment() {
            return http.get("departments").then(r => {
                this.departments = r.data.map(x => {
                    return {
                        text: x.name,
                        value: x.code
                    }
                })
            })
        },

        fetchType() {
            return http.get("EmployeeRotationRequests/fetch-type").then(res => res.data.data).then(data => {
                this.types = data.map(x => {
                    return {
                        text: `${x.name}`,
                        value: x.id
                    }
                })
            })
        },
        
        saveData() {
            this.$refs.form.validate();
            if(this.valid)
            {
                if(!this.value.item)
                {
                    return http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        // this.errorToast({message: err.response.data.message, toast: true})
                    }).finally(() => {
                        this.close()
                    })
                } else {
                    return http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Cập nhật thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    }).finally(() => {
                        this.close()
                    })
                }
            }
        },
    },
    mounted() {
        this.fetchType()
        this.fetchDepartment()
    }
}
</script>
