<template>
  <v-dialog v-model="dialog" max-width="850" scrollable eager persistent>
    <v-card class="rounded-xl" :loading="loading">
      <v-card-title class="title">
        Chi tiết {{ itemCode }}
        <v-spacer></v-spacer>
        <v-icon color="red" @click="dialog=false, itemCode = null">mdi-close</v-icon>
      </v-card-title>
      <!-- <v-card-subtitle class="red--text">
          Nhân viên kiểm tra, xác nhận và bổ sung các thông tin cần thiết.
      </v-card-subtitle> -->
      <v-card-text class="text--primary">
        <v-form v-model="isValid" @submit.prevent="submitForm" ref="internal_denouce_form" id="internal_denouce_form">
          <v-row  class="my-0">
            <v-col cols="12">
              <div>
                <span class="subheading">Loại phiếu</span>
              </div>
              <v-radio-group v-model="form.typeCode" row hide-details>
                  <v-radio :label="`Khiếu nại`" :value="'KN'" color="primary" ></v-radio>
                  <v-radio :label="`Phản ánh`" :value="'PA'" color="primary" ></v-radio>
                  <v-radio :label="`Góp ý`" :value="'GY'"  color="primary"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <div>
                <span class="subheading">Thông tin cá nhân:</span>
              </div>
              <v-radio-group v-model="form.anonymousType" row hide-details>
                  <v-radio :label="`Ẩn danh`" :value="1" color="primary" ></v-radio>
                  <v-radio :label="`Bình thường`" :value="0" color="primary" ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <span class="subheading">Nhân viên liên quan</span> <v-btn icon @click="employeeDialog = !employeeDialog"><v-icon>mdi-plus</v-icon></v-btn>
              <v-dialog v-model="employeeDialog" max-width="500px">
                <v-card>
                  <v-card-title>Danh sách nhân viên</v-card-title>
                  <v-card-text>
                    <v-text-field v-model="search" label="Tìm kiếm" persistent-placeholder dense style="max-width: 200px;"></v-text-field>
                    <v-data-table :headers="employeeHeaders" :items="employees" :search="search">
                        <template v-slot:[`item.select`]="{ item }">
                            <v-btn @click="selectEmployee(item)" small color="primary" :disabled="form.employeeObjs.map(x=>x.code).includes(item.code)">Chọn</v-btn>
                        </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-chip-group column multiple >
                <v-chip
                  filter
                  outlined
                  v-for="value in form.employeeObjs" :key="value.code" close @click:close="removeEmployee(value)"
                >
                  {{ value.code }}: {{ value.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea outlined hide-details="auto" v-model="form.detail" label="Chi tiết" dense persistent-placeholder></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea outlined hide-details="auto" v-model="form.expectation" label="Kì vọng" dense persistent-placeholder></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-0">
            <v-col cols="12" md="4">
              <v-img :src="form.image1" class="mb-3" v-if="form.image1">
                <v-btn fab x-small dark @click="removeFile(null, 'IMAGE1')"><v-icon>mdi-close</v-icon></v-btn>
              </v-img>
              <v-file-input outlined hide-details="auto" v-model="form.image1File" label="Hình ảnh 1" accept=".jpeg,.png,.jpg"
                            @change="uploadFile(form.image1File, 'IMAGE1')"
                            dense persistent-placeholder></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
              <v-img :src="form.image2" class="mb-3" v-if="form.image2">
                <v-btn fab x-small dark  @click="removeFile(null, 'IMAGE2')"><v-icon>mdi-close</v-icon></v-btn>
              </v-img>
              <v-file-input outlined hide-details="auto" v-model="form.image2File" label="Hình ảnh 2" accept=".jpeg,.png,.jpg"
                            @change="uploadFile(form.image2File, 'IMAGE2')"
                            dense persistent-placeholder></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
              <v-img :src="form.image3" class="mb-3" v-if="form.image3">
                <v-btn fab x-small dark @click="removeFile(null, 'IMAGE3')"><v-icon>mdi-close</v-icon></v-btn>
              </v-img>
              <v-file-input outlined hide-details="auto" v-model="form.image3File"
                            @change="uploadFile(form.image3File, 'IMAGE3')" accept=".jpeg,.png,.jpg"
                            label="Hình ảnh 3" dense persistent-placeholder></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
              <v-img :src="form.image4" class="mb-3" v-if="form.image4">
                <v-btn fab x-small dark @click="removeFile(null, 'IMAGE4')"><v-icon>mdi-close</v-icon></v-btn>
              </v-img>
              <v-file-input outlined hide-details="auto" v-model="form.image4File" label="Hình ảnh 4" accept=".jpeg,.png,.jpg"
                            @change="uploadFile(form.image4File, 'IMAGE4')"
                            dense persistent-placeholder></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
              <div class="video-container" style="position: relative;" v-if="form.video">
                <v-btn dark @click="removeFile(null, 'VIDEO')" fab x-small style="top: 0px; left: 0px; position: absolute; z-index: 99;"> <v-icon>mdi-close</v-icon></v-btn>
                <video :key="form.video" width="320" height="240" controls style="background-color: black;">
                  <source :src="`${form.video}`" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
              <v-file-input outlined hide-details="auto" v-model="form.videoFile" label="Video"  accept=".mov,.mp4"
                            @change="uploadFile(form.videoFile, 'VIDEO')"
                            dense persistent-placeholder></v-file-input>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="dialog=false, itemCode = null">Thoát</v-btn>
          <v-btn color="primary" type="submit" dark form="internal_denouce_form">
              Lưu
          </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiServices from '@/components/services/ApiServices.js'
import InternalDenouceApi from './api.js'
export default {
  mounted() {
    // this.fetchDistrict()
    // this.fetchWard()
    

    //********Global Events Listener */
    this.$root.$on('openDialog', () => {
      this.initForm().then(() => {
        ApiServices.getDepartmentType().then(r => {
          this.departmentTypes = r.data.items
        })
        ApiServices.getEmployee().then(r => {
          this.employees = r.data.data
        })
        this.dialog = true
      })
    });
    
    this.$root.$on('editDialog', (data) => {
      this.itemCode = data.id
      this.dialog = true
      this.loading = true
      ApiServices.getDepartmentType().then(r => {
        this.departmentTypes = r.data.items
      })
      ApiServices.getEmployee().then(r => {
        this.employees = r.data.data
        this.fetchData().finally(() => {
          this.loading = false
        })
      })
    });
    
    

  },
  destroyed() {
    
    // this.dialog = false
    //********OFF Global Events Listener */
    this.$root.$off('openDialog');
    
    this.$root.$off('editDialog');
  },
  computed: {
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội", "Hàn Quốc"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    }
  },
  data() {
    return {
      REQUIRE_RELIGION: ['CG', 'TCG', 'TL'],
      REQUIRE_MARITAL: ['KH'],
      dialog: false,
      idFrontImageFile: null,
      idBackImageFile: null,
      bankImageFile: null,
      maritalStatusImageFile: null,
      religionImageFile: null,
      employee: null,
      isValid: null,
      isValid2: null,
      showTabValid: false,
      noTaxNumber: false,
      noInsuranceNumber: false,
      loading: false,
      employeeLoading: false,
      addFamilyDialog:false,
      employeeDialog: false,

      search: '',
      employeeHeaders: [
        {text: "Mã NS", value: "code"},
        {text: "Tên NS", value: "name"},
        {text: "", value: "select"}
      ],
      

      itemCode: null,
      
      form: {
        id: 0,
        typeCode: 'KN',
        anonymousType: 1,
        employees: [],
        employeeObjs: [],
        detail:'',
        expectation: '',
        image1File: null,
        image2File: null,
        image3File: null,
        image4File: null,
        videoFile: null,
        image1: '',
        image2: '',
        image3: '',
        image4: '',
        video: '',
      },
      
      defaultForm: {
        id: 0,
        typeCode: 'KN',
        anonymousType: 1,
        employees: [],
        employeeObjs: [],
        detail:'',
        expectation: '',
        image1File: null,
        image2File: null,
        image3File: null,
        image4File: null,
        videoFile: null,
        image1: '',
        image2: '',
        image3: '',
        image4: '',
        video: '',
      },
      departments: [],
      departmentTypes: [],
      asms:[],
      employees: [],

      tab: null
    }
  },
  watch: {
    
    "dialog": {
      handler(val) {
        if(val) {
          console.log(val)
          this.fetchDepartment()
        } else {
          //close dialog
          this.close()
        }
      }
    },
  },
  methods: {
    uploadFile(file, type) {
      return InternalDenouceApi.uploadImage(this.itemCode, file, type).then(r => {
        switch(type) {
          case "IMAGE1": {
            this.form.image1 = r.data.data
            break;
          }
          case "IMAGE2": {
            this.form.image2 = r.data.data
            break;
          }
          case "IMAGE3": {
            this.form.image3 = r.data.data
            break;
          }
          case "IMAGE4": {
            this.form.image4 = r.data.data
            break;
          }
          case "VIDEO": {
            this.form.video = r.data.data
            break;
          }
        }
      })
    },
    removeFile(file, type) {
      return InternalDenouceApi.removeImage(this.itemCode, file, type).then(() => {
        
        switch(type) {
          case "IMAGE1": {
            this.form.image1 = ''
            break;
          }
          case "IMAGE2": {
            this.form.image2 = ''
            break;
          }
          case "IMAGE3": {
            this.form.image3 = ''
            break;
          }
          case "IMAGE4": {
            this.form.image4 = ''
            break;
          }
          case "VIDEO": {
            this.form.video = ''
            break;
          }
        }
      })
    },
    selectEmployee(employee) {
      if(!this.form.employeeObjs.includes(employee)) {
        return InternalDenouceApi.addEmployee(this.itemCode, employee.code).then(() => {
          this.form.employeeObjs.push(employee)
        })
      }
    },

    removeEmployee(employee){
      return InternalDenouceApi.removeEmployee(this.itemCode, employee.code).then(() => {
        this.form.employeeObjs = this.form.employeeObjs.filter(x=>x.code != employee.code)
      })
    },

    close() {
      Object.assign(this.form, this.defaultForm)
      this.itemCode = null
      this.$emit('reload')
    },
    initForm() {
      return InternalDenouceApi.post().then(r => {
        this.itemCode = r.data.data.id
        this.form.id = r.data.data.id
      });
    },

    // addEmployee(employeeCode) {
    // },

    submitForm(){
      this.$refs.internal_denouce_form.validate()
      if(this.isValid) {
        this.loading = true
        if(this.itemCode) {
          return InternalDenouceApi.put(this.itemCode, this.form).finally(() => {
            this.loading = false
          })
        }else {
          // return InternalDenouceApi.post(this.form).finally(() => {
          //   this.loading = false
          // })
        }
      }
    },

    fetchData() {
      this.loading = true
      if(this.itemCode){
        return InternalDenouceApi.get(this.itemCode).then(d => {
          Object.assign(this.form, d.form)
          this.form.employees = d.employees
          this.form.employeeObjs = this.employees.filter(x => this.form.employees.includes(x.code))
        }).finally(() => {
          this.loading = false
        })
      }
    },

    
    fetchDepartment() {
      return ApiServices.getDepartment('K00002').then(r => {
          this.departments = r.data
      })
    },

    fetchAsm() {
      return ApiServices.getAsm('K00002').then(r => {
          this.asms = r.data.data
      })
    },
    
    fetchEmployees() {
      return ApiServices.getAsm('K00002').then(r => {
          this.asms = r.data.data
      })
    }
  },
}
</script>