<template>
    <div class="enroll-report">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" ></myBreadCrumb>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="4">
                        <v-row align="center" no-gutters>
                            <label for="" class="mr-3">Thời gian</label>
                            <v-menu
                                ref="menu" v-model="formData.menu"
                                :close-on-content-click="false"
                                :return-value.sync="form.dates"
                                transition="scale-transition" offset-y min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    solo outlined flat dense hide-details=""
                                    v-model="form.dates"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="form.dates" no-title scrollable range>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="formData.menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(form.dates)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="4" v-for="(filter, k) in filters" :key="k">
                        <v-row align="center" no-gutters>
                            <label for="" class="mr-3">{{filter.label}}</label>
                            <v-autocomplete solo flat outlined dense hide-details="" v-model="form[filter.item]" :items="formData[filter.items]" item-text="name" item-value="code"></v-autocomplete>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small class="mx-3" @click="exportExcel" color="teal" dark>Xuất excel</v-btn>
                <v-btn small class="mx-3" @click="preview">Xem trước</v-btn>
            </v-card-actions>

            <!-- CHART -->
            <v-card-text style="position: relative; max-width:600px; min-width:300px;" class="mx-auto">
                <canvas ref="myChart"></canvas>
            </v-card-text>
            <!-- Bang du lieu -->
            <v-data-table :items="previewData" :headers="headers">
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import myBreadCrumb from '../../../components/BreadCrumb';
import http from '../../../components/services/http-common';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

export default {
    name: "EnrollReport",
    components: {
        myBreadCrumb,
    },
    props: ["breadcrumbs", "icon"],
    data () {
        return {
            ROOT_URL: "EnrollReport/",

            //form
            filters: [
                // {label: "Thời gian", items: "department", item: "dates"},
                {label: "Phòng ban", items: "departments", item: "department"},
                {label: "Giới tính", items: "genders", item: "gender"},
            ],
            formData: {
                departments:[],
                genders: [
                    {name: "Tất cả", code: -1},
                    {name: "Nam", code: 1},
                    {name: "Nữ", code: 0},
                ],
                menu: false,
            },
            form: {
                department: "-1",
                gender: -1,
                dates: []
            },
            
            // TABLE DATA
            previewData: [],
            headers: [
                { text: 'Mã', value: 'employeeCode' },
                { text: 'Tên', value: 'employeeName' },
                { text: 'Phòng', value: 'department' },
                { text: 'Ngày công', value: 'workDate' },
                { text: 'Trễ', value: 'late' },
                { text: 'Sớm', value: 'early' },
            ],

            // CHART DATA
            chart: undefined,
            chartData: {
                labels: [],
                datasets: [{
                    label: 'Số lần vi phạm',
                    backgroundColor: 'rgb(255, 127, 80)',
                    borderColor: 'rgb(255, 127, 80)',
                    data: [],
                }]
            },
        }
    },

    methods:{
        fetch(){
            return http.get(this.ROOT_URL+"fetch").then(res => {
                this.formData.departments = [...res.data.departments, {name: "Tất cả", code:"-1"}];
            })
        },
        preview () {
            return http.get(this.ROOT_URL + "preview", {
                params: this.form
            }).then(res => {
                console.log(this.chartData);
                this.previewData = res.data.preview;
                // this.chartData.labels = res.data.chartData.map(a => a.name);

                this.chartData.labels.pop();
                this.chartData.labels = res.data.chartData.map(a => a.name);

                this.chartData.datasets[0].data = res.data.chartData.map(a => a.group);
                this.chart.update();
            });
        },
        
        exportExcel () {
            return http.get(this.ROOT_URL + "export", {
                params: this.form,
                responseType: 'arraybuffer',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'danh_sach_di_tre_ve_som.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },
        initChart() {
            let data =this.chartData;
            const config = {
                type: 'bar',
                data,
                options: {
                    responsive: true,
                    }
            };
            this.chart = new Chart(this.$refs.myChart, config);
        }
    },

    created(){
        this.fetch();
    },
    mounted(){
        this.initChart();
    }
}
</script>