<template>
    <div class="timesheet-2">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog> -->
        <v-card>
            <v-card-title>
            <month-picker v-model="month" label="Chọn tháng" width="9rem" outlined dense></month-picker>
            <v-spacer></v-spacer>
            <refresh-button @click="fetchData"></refresh-button>
            <v-btn dark color="secondary" :to="{name: 'TimeSheet'}" class="mx-1"><v-icon small>mdi-view-grid</v-icon></v-btn>
            <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter> -->
            </v-card-title>
            
            <v-data-table
            :mobile-breakpoint="0"
            height="600px" fixed-header
            :headers="headers"
            :items="items"
            :page.sync="tableParams.page"
            :sort-by.sync="tableParams.sortBy"
            :sort-desc.sync="tableParams.sortDesc"
            :server-items-length="rowCount"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" v-model="filters.code" dense hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <v-select placeholder="Chọn" v-model="filters.isConfirmed" dense hide-details outlined
                            :items='[{text:"Tất cả", value:null},{text:"Chưa XN", value:false}, {text:"Đã XN", value:true}]'></v-select>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" v-model="filters.employeeCode"  dense hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" v-model="filters.employeeName" dense hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" v-model="filters.departmentName" dense hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <date-picker2 v-model="date" dense hide-details outlined ></date-picker2>
                            <!-- <v-text-field placeholder="Nhập" v-model="filters.date" dense hide-details outlined></v-text-field> -->
                        </td>
                        <!-- <td colspan="100%"></td> -->
                    </tr>
                </template>
                <template v-slot:[`item.department`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                    <v-icon left>
                        mdi-store
                    </v-icon>
                    {{item.department}}
                    </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }" >
                    <!-- ********* Undo ****** -->
                    <!-- <v-btn x-small class="mx-1" v-if="item.status != 0" color="secondary" @click="undo(item)">
                        <v-icon small   >
                            mdi-undo
                        </v-icon> Hoàn tác
                    </v-btn> -->
                    <v-btn x-small class="mx-1" color="white" @click="createDialog=true; detailItem=item.code">
                        <v-icon small   >
                            mdi-pencil
                        </v-icon> Chi tiết
                    </v-btn>
                </template>
                <template v-slot:[`item.isConfirmed`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.isConfirmed).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.isConfirmed).text}}</strong>
                    </v-chip>
                </template>
                <template
                    v-slot:[`item.sentDate`]="{ item }"
                >
                    {{ formatDate(item.sentDate) }}
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <create-dialog v-model="createDialog" :item="detailItem" @fetchData="fetchData"></create-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import ApiServices from '@/components/services/ApiServices';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import CreateDialog from './Create.vue';
import * as moment from "moment/moment"

export default {
    name: "Attendance",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        CreateDialog,
        // 'create-dialog': CreateDialog,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        date: {
            handler(val)
            {
                this.filters.from = val[0];
                this.filters.to = val[1];
            }
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "timesheets/view-2",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },

            month: moment().format("YYYY-MM"),
            leavePlans:[],
            date: [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentName: null,
                workDate: moment().startOf("month").format("YYYY-MM-DD"),
                isConfirmed: null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            rowCount: 0,

            departmentSearch:null,
            departments: [],

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', width: '7rem'},
                { text: 'Mã', align: 'start', value: 'code', width: '7rem'},
                { text: 'Xác nhận', align: 'start', value: 'isConfirmed', width: '7rem'},
                { text: 'Mã nhân viên', align: 'start', value: 'employeeCode', width: '7rem'},
                { text: 'Nhân viên', align: 'start', value: 'employee', width: '15rem'},
                { text: 'Phòng', align: 'start', value: 'department', width: '12rem'},
                { text: 'Ngày', align: 'start', value: 'workDate', width: 250},
            ],

            //Create Dialog
            createDialog: false,
            detailItem: null,
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchLeavePlan(){
            ApiServices.fetchLeavePlan().then(res=>{
                this.leavePlans = res.data;
            })
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                console.log(res.data, "xxxxxxxxxx")
                this.isLoading = false;
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
            })
            .catch(err => {

            })
        },
        
        accept(item) {
            http.get(this.ROOT_URL + "accept/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            http.get(this.ROOT_URL + "decline/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            http.get(this.ROOT_URL + "undo/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchData();
        this.fetchLeavePlan();
    }
}
</script>
<style scoped>

::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>