<template>
    <div class="create-holiday">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="70%">
             <v-card>
                <v-card-title>
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} kế hoạch công tác
                </v-card-title>
                <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="form.name" label="Tên kế hoạch"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="form.symbol" label="Ký hiệu chấm công"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="form.scale" label="Tỉ lệ công" :disabled="!form.isOvertime"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-switch v-model="form.isActive" label="Trạng thái kích hoạt"></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch v-model="form.isOvertime" label="Tính tăng ca"></v-switch>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea solo outlined flat v-model="form.note" label="Chú thích"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark
                            color="blue darken-1"
                            type="submit"
                        >
                            Lưu
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
             </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import DatePicker from '@/components/DatePicker'
import EventBus  from "@/components/services/EventBus";

export default {
    name: "CreateHoliday",
    components: {
        "date-picker": DatePicker,
    },
    computed:{

    },
    watch: {
        value: {
            handler(val){
                console.log(val)
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            }
        },
    },

    props: ["value"],
    data () {
        return {
            //URL
            ROOT_URL: "businessTypes/",

            //DÂTA
            valid: false,
            formData: {
                
            },
            form: {
                code:"auto",
                name: "",
                date: new Date().toISOString().substring(0,10),
                isActive: true,
                isOvertime: false,
                scale: 1
            },

            defaultForm: {
                code:"auto",
                name: "",
                date: new Date().toISOString().substring(0,10),
                isActive: true,
                isOvertime: false,
                scale: 1
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },

        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },

        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        
        fetchData () {
            return http.get(this.ROOT_URL + this.value.item.code)
            .then(res => {
                let item = res.data;
                console.log(item);
                this.form = Object.assign({}, item);
                this.form.date = this.form.date.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10);
            })
            .catch(err => {
                console.log(err);
            });
        },
        saveData() {
            let  isValid = this.$refs.form.validate();
            console.log(isValid);
            if(isValid)
            {
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Tạo mới thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                } else {
                    http.put(this.ROOT_URL + this.value.item.code, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Cập nhật thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
                this.close();
            }
        },
        
        //Employee Dialog JS
        rowHandler (e) {
            this.form.EmployeeCode = e.code;
            this.form.EmployeeName = e.name;
        }
    },
    created() {

    }
}
</script>