export const ROUTE = 'ContentTemplates';
import http from '@/components/services/http-common';

class ContentTemplateApi {
  gets() {
    return http.get(`${ROUTE}`).then(r => r.data.data)
  }

  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => r.data.data)
  }

  post(data) {
    return http.post(`${ROUTE}`, data).then(r=> r.data)
  }
  delete(id){
    return http.delete(`${ROUTE}/${id}`)
  }

  fetchEmployees() {
    return http.get('Employees/fetch', {
      params: {
        detail: true
      }
    }).then(r => {
      return r.data
    })
  }

  setEmployees(employees, id) {
    return http.post(`${ROUTE}/set-Employees`, {
      employeeCodes: employees
    }, {
      params: {
        templateId: id
      }
    })
  }

  setImages(images, id) {
    var form = new FormData()
    if (Array.isArray(images)) {
      images.forEach((image, index) => {
        form.append('files', image, image.name); // Assuming `image` is a File object
      });
    } else {
      form.append('files', images, images.name); // In case 'images' is a single file
    }
    return http.post(`${ROUTE}/set-images`, form, {
      params: {
        templateId: id
      }
    })
  }
  
  getImages(id) {
    return http.get(`${ROUTE}/get-employee-images`, {
      params: {
        templateId: id
      }
    }).then(r =>r.data)
  }

  postTemplateDetail(data, templateId){
    return http.post(`${ROUTE}/template-detail`, data, {
      params: {
        templateId: templateId
      }
    })
  }

  getTemplateDetail(templateId) {
    return http.get(`${ROUTE}/template-detail`, {
      params: {
        templateId: templateId
      }
    }).then(r => r.data)
  }

  deleteTemplateDetail(templateDetailId) {
    return http.delete(`${ROUTE}/template-detail`, {
      params: {
        templateDetailId: templateDetailId
      }
    })
  }

  updateFromClipboard(templateDetailId, clipboardString) {
    return http.post(`${ROUTE}/update-from-clipboard`, {
      clipboard: clipboardString
    }, {
      params: {
        templateDetailId: templateDetailId
      }
    }).then(r => r.data)
  }

  getTemplateDetailEmployee(templateDetailId) {
    return http.get(`${ROUTE}/template-detail-employee`, {
      params: {
        templateDetailId: templateDetailId
      }
    }).then(r => r.data)
  }

  generateContent(templateId) {
    return http.get(`${ROUTE}/generate-content/${templateId}`).then(r => r.data)
  }

  hideContent(templateId) {
    return http.get(`${ROUTE}/hide-content/${templateId}`)
  }
  
  showContent(templateId) {
    return http.get(`${ROUTE}/show-content/${templateId}`)
  }

  deleteContent(templateId){
    return http.get(`${ROUTE}/show-content/${templateId}`)
  }
}
export default new ContentTemplateApi();