<template>
    <div class="city">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb>
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <v-card>
            <v-card-title>Danh sách khu vực
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import CityCreateDialog from './Create';
import DataFilter from '@/components/DataFilter';
import ImportUpload from '@/components/ImportUpload';

export default {
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        'create-dialog': CityCreateDialog,
        'import-upload': ImportUpload,
    },
    props:["breadcrumbs", "icon"],
    data () {
        return {
            ROOT_URL:"areas/",
            //filter
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],
            },
            
            //datatable config
            search: '',
            headers: [
                {text: 'Mã vị trí', value: 'code'},
                { text: 'Tên vị trí', value: 'name'},
                { text: 'Alias', value: 'alias' },
                { text: 'Lương tối thiểu', value: 'minimumSalary' },
                { text: 'Lương tối thiểu 7%', value: 'minimumSalary7' },
                { text: 'Áp dụng hiện tại', value: 'currentSalary' },

                { text: 'Thao tác', value: 'actions', sortable: false },
            ],
            items: [],
            
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},
        }
    },
    methods:{
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData () {
            return http.get(this.ROOT_URL).then(res => {
                this.items = res.data.items;
            })
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    mounted () {
        this.fetchData();
    }
}
</script>