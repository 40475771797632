<template>
    <v-dialog v-model="value" max-width="600px" scrollable @click:outside="close">
        <v-card>
            <v-card-title>{{isEdit ? 'Thay đổi': 'Tạo'}} bộ câu hỏi {{isEdit ? item: ''}} </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="form" v-model="valid" @submit.prevent="save">
                    <v-row class="mt-1">
                        <v-col cols="12">
                            <v-text-field label="Tên bộ câu hỏi" v-model="form.name" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="date" dense outlined hide-details="auto" label="Từ" v-model="form.validFrom" clearable></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="date" dense outlined hide-details="auto" label="Đến" v-model="form.validTo" clearable></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea outlined hide-details="auto" label="Mô tả" rows="3" v-model="form.description">
                            </v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-select outlined hide-details="auto"
                                :items="statuses"
                                v-model="form.ApplyToGlobal"
                                label="Trạng thái áp dụng"
                            ></v-select>
                        </v-col>

                        <v-col v-if="form.ApplyToGlobal != 1" cols="12">
                            <v-autocomplete multiple outlined label="Áp dụng cho các phòng ban (chi nhánh):" hide-details="auto" placeholder="Chọn các phòng ban" persistent-placeholder
                                            :items="departments" item-text="name" item-value="code" v-model="form.ApplyToDepartmentCodes" clearable></v-autocomplete>
                        </v-col>
                        <v-col v-if="form.ApplyToGlobal != 1" cols="12">
                            <v-autocomplete multiple outlined label="Và bao gồm các nhân viên:" hide-details="auto" placeholder="Chọn các nhân viên" persistent-placeholder persistent
                                            :items="employees" item-text="name" item-value="code" v-model="form.ApplyToEmployeeCodes" clearable></v-autocomplete>
                            <v-textarea label="Dán mã nhân viên tại đây (phân cách bở dấu ',')" v-model="employeeTextPaste" ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" form="form" @click="save">Lưu </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from './services/api.js'
import moment from 'moment/moment'

export default {
    props:["value", "item"],
    watch:{
        value: {
            handler(val){
                if(val)
                {
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    console.log("check is edit")
                    if(this.isEdit)
                    {
                        this.fetch()
                        this.headerCreated = true
                    }
                }
                else{
                    this.close();
                }
            },
        },

       
        employeeTextPaste(newVal) {
            this.form.ApplyToEmployeeCodes = newVal.length==0? []: newVal.split(',')
        },


    },
    computed: {
        // dialog: {
        //     get: function () {
        //         return this.value;
        //     },
        //     set: function (val){
        //         this.$emit("input", val)
        //     }
        // },
        isEdit() {
            return !!this.item;
        },
        validDetail(){
            console.log(this.rules.required);
        }
    },
    data() {
        return {
            // endpoint: `https://private-label.trungsonpharma.com/`,
            qrSrc: "",
            valid: false,
            statuses: [{text: 'Áp dụng tất cả phòng ban', value: 1}, {text: 'Áp dụng một số', value: 0}],
            form: {
                categoryId: 1,
                name: "",
                validFrom: null,
                validTo: null,
                description:"",
                ApplyToGlobal: 1,
                ApplyToDepartmentCodes: [],
                ApplyToEmployeeCodes: [],
                employeeCodes: []
            },
            defaultForm: {
                categoryId: 1,
                name: "",
                description:"",
                ApplyToGlobal: 1,
                ApplyToDepartmentCodes: [],
                ApplyToEmployeeCodes: [],
                employeeCodes: []
            },
            employeeTextPaste:'',

            /**header */
            departments: [],
            employees:[],
            /** Detail Header*/
            detailHeaders: [
                {text:"Tên dụng cụ", value: "property", width:"7rem"},
                {text:"Số lượng", value: "quantity", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "actualQuantity", width:"7rem", align:"center"},
                {text:"", value: "actions", width:"7rem", align:"center"},
            ],
            properties:[],
            detail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
            },
            detailItems:[],

            propertyValid: false,
            amountValid: false,

            
            rules: {
                required: value => !!value || 'Required.'
            },

            /**Stepper params */
            headerCreated: false,
        }
    }, 
    methods: {
        getUnit(property) {
            var data = this.properties.find(x=>x.code == property);
            if(!data)
                return ""
            else
                return data.propertyUnitName
        },
        getDepartments() {
            this.apiService.getDepartments().then(d => {
                this.departments = d
            })
        },
        getEmployees() {
            this.apiService.getEmployees().then(d => {
                this.employees = d
            })
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.$refs.form.resetValidation();
            this.$emit('close-dialog');
        },

        save() {
            if(this.valid)
            {
                //tao phieu
                if(!this.isEdit) {
                    return this.apiService.postTemplate( this.form).then(res => {
                        this.$emit("fetchData", res.data.code);
                        this.close();
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    });
                }

                //update phieu
                if(this.isEdit) {
                    return this.apiService.putTemplate(this.item,this.form).then(res => {
                        this.$emit("fetchData");
                        this.close();
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    });
                }
            }
        },
        fetch() {
            return this.apiService.getTemplate(this.item).then(d => {
                this.form.categoryId = d.data.categoryId
                this.form.name = d.data.name
                this.form.validFrom = moment(d.data.validFrom).format('YYYY-MM-DD')
                this.form.validTo =moment( d.data.validTo).format('YYYY-MM-DD')
                this.form.description = d.data.description
                this.form.ApplyToGlobal = d.data.applyToGlobal

                //convert to array
                this.form.ApplyToDepartmentCodes = d.data.applyToDepartmentCodes.split(',')
                this.form.ApplyToEmployeeCodes = d.data.applyToEmployeeCodes.split(',')
            })
        },
        // fetchDepartment()
        // {
        //     return http.get("propertyRequests/get-departments").then(res => {
        //         this.departments = res.data;
        //     });
        // },
        // fetchProperty()
        // {
        //     return http.get("properties").then(res => {
        //         this.properties = res.data.items;
        //     });
        // },
        // fetchDetail() {
        //     let url = `propertyRequests/detail/${this.form.code}`
        //     return http.get(url).then(res => {
        //         this.detailItems = res.data;
        //     })
        // },
        

        //SERVICES
    },
    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted() {
        this.getDepartments();
        this.getEmployees()
    }
}
</script>