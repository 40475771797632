<template>
  <v-dialog :value="show" max-width="600" @click:outside="$emit('close')">
    <v-card>
      <v-card-title v-if="!!selectItem">Cập nhật IP {{selectItem.name}}</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitUpdateIpHandler" ref="form_update_ip" id="form_update_ip">
          <v-row>
            <v-col cols="12">
              <div class="text--teal subtitle-1">
                Danh sách IP hiện hành: {{ipList}}
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined dense hide-details label="IP 1:" placeholder="Nhập IP" persistent-placeholder v-model="form.ip1"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined dense hide-details label="IP 2:" placeholder="Nhập IP" persistent-placeholder v-model="form.ip2"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined dense hide-details label="IP 3:" placeholder="Nhập IP" persistent-placeholder v-model="form.ip3"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" type="submit" form="form_update_ip">Cập nhật</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DepartmentService from '../../services/DepartmentService'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectItem:{
      type:Object,
      default: null
    },
    ipList: {
      type: String,
      default:'',
    },
    form: {
      type: Object,
      default() {
        return {
          ip1:'',
          ip2:'',
          ip3:'',
        }
      }
    }
    
  },
  watch: {
    
  },
  data() {
    return {
    }
  },
  computed: {

  },
  departmentService: null,
  created() {
    this.departmentService = new DepartmentService();
  },
  methods: {

    submitUpdateIpHandler() {
      this.ipListArr = [this.form.ip1, this.form.ip2, this.form.ip3];
      let data = {ipList: this.ipListArr.filter(x=>!!x).join(',')}
      this.departmentService.updateIp(this.selectItem.code, data).then(() => {
        this.$emit('close')
      })
    }
  }
}
</script>