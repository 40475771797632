<template>
  <div>
    <iframe referrerpolicy="origin-when-cross-origin" :src="'https://insite.trungsonpharma.com/faq?token='+token" frameborder="0" width="100%" style="height: calc(100vh - 172px);">
      
    </iframe>
  </div>
</template>
<script>
export default {
  computed: {
    token() {
      let token = localStorage.getItem("token")
      return token
    }
  }
}
</script>