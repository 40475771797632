<template>
    <div class="d-flex">
        <v-select flat :items="['00','01','02','03','04','05','06','07']" solo style="max-width:3rem" class="d-flex flex-grow-1" dense append-icon="" hide-details>
        </v-select>
        <div class="font-weight-bold ma-2 d-block">:</div>
        
        <v-select flat :items="['00','01','02','03','04','05','06','07']" solo style="max-width:3rem" class="d-flex flex-grow-1" dense append-icon="" hide-details>
        </v-select>
    </div>
</template>
<script>
export default {
    props:["value", "label", "dense", "outlined", "rules", "clearable"],
    computed:{
        time: {
            get: function () {
            return this.value
            },
            // setter
            set: function (val){
                this.$emit("input", val)
            }
        }
    },
    methods:{
        timeMask(value) {
            const hours = [
                /[0-2]/,
                value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
            ];
            const minutes = [/[0-5]/, /[0-9]/];
            return value.length > 2
                ? [...hours, ':', ...minutes]
                : hours;
        }
    }
}
</script>