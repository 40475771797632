<template>
    <div class="create-leave">
        <v-dialog :value="value.state"  max-width="600px" persistent scrollable>
             <v-card>
                <v-card-title>
                    Import lương nhân viên
                    <v-spacer></v-spacer>
                    <v-btn small text @click="close"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                <v-form @submit.prevent="saveData" v-model="valid" id="form" ref="form">
                    <v-row class="mt-0">
                        <v-col cols="12">
                            <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker>
                        </v-col>
                        
                        <!-- <v-col cols="12">
                            
                            <v-menu
                            v-model="menuDate1"
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                :value="formatValidFrom"
                                clearable
                                label="Bắt đầu"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="validFrom = null"
                                :rules="[v=> !!v ||'Nhập dữ liệu này!']"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                landscape
                                locale="vi"
                                v-model="validFrom"
                                @change="menuDate1 = false"
                            ></v-date-picker>
                            </v-menu>
                        </v-col> -->
                        <v-col cols="12">
                            <v-file-input v-model="file" clearable label="File Import" placeholder="Chọn file import"  accept=".xlsx" :rules='[v=>!!v||"Chọn dữ liệu"]'></v-file-input>
                        </v-col>
                        <v-col>
                            <small class="red--text font-weight-bold">Lưu ý: nếu tồn tại dữ liệu trong tháng, dữ liệu sẽ bị chép đè</small>
                        </v-col>
                    </v-row>
                </v-form>       
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark form="form" color="primary" type="submit" small>
                        Lưu
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
             </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DatePicker from "../../../components/DatePicker";
import http from '../../../components/services/http-common';
import EventBus  from "../../../components/services/EventBus";
import moment from 'moment'

export default {
    name: "Create",
    components: {
        "date-picker": DatePicker,
    },
    computed:{
        
        formatValidFrom () {
            return this.validFrom ? moment(this.validFrom).format('DD/MM/YYYY') : ''
        },
        amount() {
            let amount = 0;
            const day = (1000*60*60*24);
            if(Array.isArray(this.dates))
            {
                if(this.form.isMultiple)
                {
                    if(this.dates.length == 2){
                        let first = new Date(this.dates[0]);
                        let last = new Date(this.dates[1]);
                        amount = (last -first)/day;
                        return Math.abs(amount) +1;
                    }
                }
            }
            return amount;
        }
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            }
        },
    },

    props: ["value"],
    data () {
        return {
            //URL
            ROOT_URL: "employeeSalaryImports/",
            LEAVEPLAN_URL: "leavePlans/valid-leave-plan/",
            LEAVEPLAN_URL2: "leavePlans/valid-leave-plan2/",

            //DATA
            valid: false,
            dateMenu: false,
            employees:[],
            departments:[],
            dates: "",
            ShiftObject: "",
            month: moment().format("YYYY-MM"),
            // validFrom: null,
            file: null,

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        errorToast (eventObj)
        {
            EventBus.$emit('hasError', eventObj);
        },

        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        dateApply (){
            if(this.isMultiple)
            {
                let dates = this.dates;
                let dt0 = new Date(dates[0]);
                let dt1 = new Date(dates[1]);
                if(dt0> dt1)
                    this.dates = [dates[1], dates[0]];
            }
            this.$refs.menu.save(this.dates);
        },

        close () {
            this.$emit("CloseDialog")
            this.$refs.form.resetValidation()
            this.file=null
            this.month = moment().format("YYYY-MM")
        },
        
        fetchData () {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                this.form = Object.assign({}, item);
                console.log(this.form);
            })
            .catch(err => {
                console.log(err);
            });
        },

        fetchEmployee() {
            return http.get("employees/fetch?asm=false&detail=true").then(res => {
                this.employees = res.data.map(x => {
                    return {
                        text: `${x.name}`,
                        value: x.code
                    }
                })
            })
        },

        fetchDepartment() {
            return http.get("departments").then(r => {
                this.departments = r.data.map(x => {
                    return {
                        text: x.name,
                        value: x.code
                    }
                })
            })
        },
        
        saveData() {
            this.$refs.form.validate();
            var JwtString = localStorage.getItem('CaBToken');
            if(this.valid)
            {
                let formData = new FormData();
                formData.append("file", this.file)
                return http.post(this.ROOT_URL+"import", formData, {
                    params: {
                        month: this.month,
                        jwtString: JwtString
                    }
                })
                    .then(res => {
                        console.log(res)
                        this.$emit("fetchData")
                    })
                    .catch(err => {
                        localStorage.removeItem('CaBToken')
                        this.$emit("passwordDialog")
                    }).finally(() => {
                        this.close()
                    })
            }
        },
    },
    mounted() {
        this.fetchEmployee()
        this.fetchDepartment()
    }
}
</script>