var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.isUpdate ? "Cập nhật": "Tạo mới")+" dữ liệu "+_vm._s(_vm.itemId)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){_vm.resetForm(), _vm.$emit('close-dialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form_create_image_report",attrs:{"id":"form_create_image_report"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Mã dữ liệu","rules":[
              v => !!v || 'Nhập dữ liệu này',
              v => (v && v.length <= 10) || 'Mã không dc lớn hơn 10 ký tự'
            ]},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Tên danh mục báo cáo hình ảnh","rules":[
              v => !!v || 'Nhập dữ liệu này',
              v => (v && v.length <= 100) || 'Dữ liệu không dc lớn hơn 100 ký tự'
            ]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Mô tả","rules":[
              v => !!v || 'Nhập dữ liệu này',
              v => (v && v.length <= 255) || 'Dữ liệu không dc lớn hơn 255 ký tự'
            ]},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('v-col',[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"auto","items":[
            {text: 'Hoạt động', value: 'SHOW'},
            {text: 'Ẩn', value: 'HIDE'}
          ]},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","block":"","type":"submit","form":"form_create_image_report"}},[_vm._v("Lưu")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }