<template>
    <v-dialog v-model="dialog" max-width="800px" scrollable fullscreen persistent>
        <v-card>
            <v-card-title class="font-weight-bold">{{isEdit ? 'Thay đổi': 'Tạo'}} hợp đồng{{isEdit ? item.id: ''}}
            <v-spacer></v-spacer>
            <v-btn icon color="red" @click="close(false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-card-text class="text--primary">
                <v-row class="my-0">
                    <v-col cols="12" md="6">
                        <h3 class="green--text text--darken-4 pl-4"><b>THÔNG TIN NHÂN VIÊN KÝ HỢP ĐỒNG</b></h3>
                        <v-divider class="mb-2  green darken-4" style="max-width: 500px;"></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-title class="py-0">
                                        <v-text-field v-model="employeeGlobalSearch" label="Tìm kiếm nhân viên" flat solo prepend-icon="mdi-magnify" hide-details></v-text-field>
                                    </v-card-title>
                                    <v-data-table :search="employeeGlobalSearch" :items="employees" :headers="employeeHeaders" dense fixed-header height="300px" :loading="employeeLoading"
                                                    v-model="employeeSelect" item-key="code" single-select
                                                    :show-select="!isEdit" :item-class="employeeSelectClass" @item-selected="employeeSetInfo">
                                        <template v-slot:[`item.idDate`]="{item}">
                                            {{ formatDate(item.idDate) }}

                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                        <v-subheader><b>THÔNG TIN HỢP ĐỒNG</b></v-subheader>
                        <v-form ref="contract_form" id="contract_form" v-model="valid" @submit.prevent="save" :readonly="isEdit">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-select :disabled="!form.employeeCode" :items="contractCategories" v-model="form.contractCategoryCode" item-value="code" item-text="name" @change="contractCategoryChange"
                                                label="Loại hợp đồng" hide-details="auto" outlined persistent-placeholder placeholder="Hợp đồng không xác định thời hạn, hợp đồng xác định thời hạn, ...."></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Hợp đồng số" v-model="form.contractNumber" hide-details="auto" outlined persistent-placeholder placeholder="……/20……/HĐLĐ"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date" label="Bắt đầu vào" v-model="form.validFrom" hide-details="auto"
                                     outlined persistent-placeholder placeholder="Ngày bắt đầu hợp đồng nếu, không thời hạn sẽ từ 1900-2100"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date" label="Kết thúc vào" v-model="form.validTo" hide-details="auto" outlined persistent-placeholder placeholder="Ngày kết thúc hợp đồng nếu, không thời hạn sẽ từ 1900-2100"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field label="Với mức lương" v-model="form.salary" hide-details="auto" outlined persistent-placeholder placeholder="Mức lương được cài đặt theo chức vụ/ vị trí"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h3 class=""> <a href="#" @click.prevent="printTest" class="green--text text--darken-4" style="text-decoration: none;"><b>TẠO BẢN XEM TRƯỚC</b> </a></h3>
                        <v-divider class="mb-2 green darken-4" style="max-width: 500px;"></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <iframe  id="printf" name="printf" :srcdoc="templateHTMLFilled" style="margin-left: auto; margin-right: auto; width: 100%; height: 100vh; overflow: auto; border-style: solid; padding: 24px;"></iframe>
                                <!-- <div v-html="templateHTMLFilled" style="margin-left: auto; margin-right: auto; max-width: 800px; border-style: solid; padding: 12px;"></div> -->
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mt-7 mb-2"></v-divider>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!isEdit" color="primary" type="submit" form="contract_form" style="width: 200px">Lưu </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from './services/api.js'
import moment from 'moment/moment'
import Editor from '@/components/Editor/Editor.vue';

export default {
    props:["value"],
    components:{
        Editor
    },
    watch:{
        dialog: {
            handler(val){
                if(val)
                {
                    this.getEmployees()
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    console.log("check is edit")
                    if(this.isEdit)
                    {
                        this.fetch()
                        this.headerCreated = true
                    }
                }
                else{
                    this.close();
                }
            },
        },

        imageFiles() {
            this.form.images = ""
        },


    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        isEdit() {
            return !!this.item;
        },
        validDetail(){
            console.log(this.rules.required);
        },
        templateHTMLFilled() {
            var result = this.templateHTML.replace(new RegExp("--EmployeeName--", 'g'), this.form.employeeName)
                             .replace("--BirthDay--", this.formatDate(this.form.birthDay))
                             .replace("--Gender--", this.form.gender?'Nam':'Nữ')
                             .replace("--PhoneNumber--", this.form.phoneNumber)
                             .replace("--Mail--", this.form.mail)
                             .replace("--Address--", this.form.address)
                             .replace("--IdNumber--", this.form.idNumber)
                             .replace("--IdDate--", this.formatDate(this.form.idDate))
                             .replace("--IdPlace--", this.form.idPlace)
                             .replace( new RegExp("--ValidFrom--", 'g'), this.formatDate(this.form.validFrom) )
                             .replace("--ValidTo--", this.formatDate(this.form.validTo))
                             .replace("--Length--", this.form.length)
                             .replace("--LengthType--", this.lengthTypeDictText[this.form.lengthType])
                             .replace("--Position--", this.form.positionName)
                             .replace("--PositionState--", this.form.positionStateName)
                             .replace("--ContractNumber--", this.form.contractNumber)
                             .replace("--Salary--", this.thoundsandSep(this.form.salary))
                             .replace("--GenderCall--", this.form.gender?'Ông':'Bà')
                             .replace(new RegExp("--signDay--", 'g'), this.getDatePart(this.form.validFrom).day)
                             .replace(new RegExp("--signMonth--", 'g'), this.getDatePart(this.form.validFrom).month)
                             .replace(new RegExp("--signYear--", 'g'), this.getDatePart(this.form.validFrom).year)
            return result
        },
        

        employeeReportCategoryDescription() {
            let result = "";
            if(this.categories.length>0){
                if(this.form.employeeReportCategoryCode)
                {
                    let resultObj = this.categories.filter(x=>x.code == this.form.employeeReportCategoryCode)
                    if(resultObj.length>0){
                        result = resultObj[0].description
                    }
                }
            }
            return result
        },
        imageStatus() {
            var result = {
                name: 'Hình ảnh khả dụng',
                color: 'green',
                value: 1
            }
            this.imageFiles.forEach(imageFile => {
                let lastModifiedTimestamp = imageFile.lastModified;
                let createdDate = moment(lastModifiedTimestamp)
                console.log(createdDate)
                if(createdDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                    result = {
                        name: 'Hình ảnh cũ, kiểm tra lại các hình ảnh chụp trong hôm nay',
                        color: 'error',
                        value: 0
                    } 
                }
            });
            return result
        }
    },
    data() {
        return {
            valid: false,
            statuses: [{text: 'Áp dụng tất cả phòng ban', value: 1}, {text: 'Áp dụng một số', value: 0}],

            dialog: false,
            item: null,

            toDepartments:[],
            fromDepartments:[],
            contractCategories:[],
            templateHTML: "",
            length: null,
            lengthType:"",
            lengthTypeDictText: {
                'NAM': 'năm',
                'THANG': 'tháng'
            },
            lengthTypeDictCompute: {
                'NAM': 'years',
                'THANG': 'months'
            },

            form: {
                employeeCode: '',
                employeeName: '',
                gender: '',
                phoneNumber:'',
                mail:'',
                address:'',
                birthDay:'',
                idNumber: '',
                idDate: '',
                idPlace: '',
                positionName: '',
                positionCode: '',
                positionStateName: '',
                positionStateCode: '',
                salary: '',
                validFrom: '',
                validTo: '',
                length:'',
                lengthType:'',
                contractNumber:'',
                contractCategoryCode:''

            },
            defaultForm: {
                employeeCode: '',
                employeeName: '',
                gender: '',
                phoneNumber:'',
                mail:'',
                address:'',
                birthDay:'',
                idNumber: '',
                idDate: '',
                idPlace: '',
                positionName: '',
                positionCode: '',
                positionStateName: '',
                positionStateCode: '',
                salary: '',
                validFrom: '',
                validTo: '',
                length:'',
                lengthType:'',
                contractNumber:'',
                contractCategoryCode:''
            },
            employeeHireDate: null,

            sampleTemplate: "",
            employeeTextPaste:'',

            /**header */
            categories: [],
            departments: [],
            employees:[],
            employeeGlobalSearch:"",
            employeeSelect: [],
            employeeLoading: false,

            /** Detail Header*/
            detailHeaders: [
                {text:"Tên dụng cụ", value: "id", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "name", width:"7rem", align:"center"},
                {text:"Số lượng", value: "toDepartmentCode", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "employeeReportCategoryCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "images", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "toDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "status", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdBy", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedBy", width:"7rem", align:"center"},
                {text:"", value: "actions", width:"7rem", align:"center"},
            ],
            employeeHeaders: [
                {text:"Mã", value: "code"},
                {text:"Tên", value: "name", width: 150 },
                {text:"Địa chỉ", value: "address", width: 200},
                {text:"CCCD", value: "idNumber" },
                {text:"Ngày cấp", value: "idDate" },
                {text:"Nơi cấp", value: "idPlace", width:200 },
                {text:"Chức vụ", value: "positionName", width: 150 },
                {text:"Chức danh", value: "positionStateName" },
                {text:"Phòng ban", value: "departmentName" },
            ],
            properties:[],
            detail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
            },
            detailItems:[],

            propertyValid: false,
            amountValid: false,

            
            rules: {
                required: value => !!value || 'Required.'
            },

            /**Stepper params */
            headerCreated: false,
        }
    }, 
    methods: {
        thoundsandSep(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        printTest() {
            window.frames["printf"].focus();
            window.frames["printf"].print();
        },
        employeeSetInfo(event) {
            if(event.value) {
                this.form.employeeCode = event.item.code
                this.form.employeeName = event.item.name
                this.form.positionCode = event.item.positionCode
                this.form.positionName = event.item.positionName
                this.form.positionStateName = event.item.positionStateName
                this.form.positionStateCode = event.item.positionStateCode
                this.form.departmentCode = event.item.departmentCode
                this.form.departmentName = event.item.departmentName
                this.form.phoneNumber = event.item.phoneNumber
                this.form.mail = event.item.mail
                this.form.address = event.item.address
                this.form.gender = event.item.gender == 'Nam'? true : false
                this.form.birthDay = moment(event.item.birthDay).format("YYYY-MM-DD")
                this.form.idNumber = event.item.idNumber
                this.form.idDate = moment(event.item.idDate).format("YYYY-MM-DD")
                this.form.idPlace = event.item.idPlace
                this.employeeHireDate = moment(event.item.hireDate).format("YYYY-MM-DD")
            } else {
                this.form = Object.assign({}, this.defaultForm);
            }
        },

        employeeSelectClass(item) {
            if(this.employeeSelect.length>0)
            {
                if(this.employeeSelect[0].code == item.code)
                    return 'highlighted-row'
            }
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        getDatePart(value) {
            let date = moment(value);
            let result = {
                day: date.day(),
                month: date.month(),
                year: date.year(),
            }
            console.log(result)
            return result
        },

        getUnit(property) {
            var data = this.properties.find(x=>x.code == property);
            if(!data)
                return ""
            else
                return data.propertyUnitName
        },
        getDepartments() {
            this.apiService.getDepartments().then(d => {
                this.departments = d
            })
        },

        getContractCategory() {
            return this.apiService.getContractCategory().then(d =>  {
                this.contractCategories = d
            })
        },

        getEmployees() {
            this.employeeLoading = true
            return this.apiService.getEmployees().then(d => {
                this.employees = d
            }).finally(() => {
                this.employeeLoading = false
            })
        },
        close( stopClose = false){
            this.item = null
            this.employeeSelect = []
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.$refs.contract_form.resetValidation();
            this.employeeHireDate = '',

            this.uploaded = 0
            this.totalUpload = 0
            this.$emit('fetchData');
            if(!stopClose){
                // this.$emit('close-dialog');
                this.dialog = false
            }


        },

        save() {
            this.$refs.contract_form.validate();
            if(this.valid)
            {
                //tao phieu
                if(!this.isEdit) {
                    this.form.htmlSource = this.templateHTMLFilled
                    console.log(this.form)
                    return this.apiService.post( this.form).then(res => {
                        // this.$emit("fetchData", res.data.code);
                        this.close(true);
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    });
                }

                //update phieu
                // if(this.isEdit) {
                //     return this.apiService.putTemplate(this.item,this.form).then(res => {
                //         // this.$emit("fetchData");
                //         this.close();
                        
                //         // this.fetchDetail();
                //         // this.form = Object.assign({}, res.data.data);
                //     });
                // }
            }
        },
        fetch() {
            return this.apiService.getItem(this.item.id).then(d => {
                // this.employeeSelect = [{code: d.employeeCode}]
                this.employeeGlobalSearch = d.employeeCode
                d.validFrom = moment(d.validFrom).format("YYYY-MM-DD")
                d.validTo = moment(d.validTo).format("YYYY-MM-DD")
                this.form = Object.assign({}, d);
                this.employeeSelect = [{code: d.employeeCode}]
                this.apiService.getContractCategoryTemplate(d.contractCategoryCode).then(d => {this.templateHTML = d})
            })
        },
        getToDepartments() {
            this.apiService.getToDepartments().then(d => {
                console.log(d)
                this.toDepartments = d
            })
        },

        getFromDepartments() {
            this.apiService.getFromDepartments().then(d => {
                this.fromDepartments = d
            })
        },

        getSalary(){
            return this.apiService.getSalary(this.form.employeeCode, this.form.validFrom).then(d => {
                this.form.salary = d
                console.log(this.form.salary)
            })
        },

        contractCategoryChange(value) {
            var contractCategoryObj = this.contractCategories.find(x=>x.code == value)
            if(contractCategoryObj) {
                this.form.length = contractCategoryObj.length
                this.form.lengthType = contractCategoryObj.lengthType
                //gen SOHOPDONG
                let currentYear = moment().year()
                let employeeCodeFormat = this.form.employeeCode.replace('NS', '')
                let contractNumber = `${employeeCodeFormat}/${currentYear}/${contractCategoryObj.prefix}`
                this.form.contractNumber = contractNumber
                if(contractCategoryObj.isStarter == 1) {
                    this.form.validFrom = moment(this.employeeHireDate).format('YYYY-MM-DD')
                } else {
                    this.form.validFrom = moment().startOf('months').format('YYYY-MM-DD')
                }
                this.form.validTo = moment(this.form.validFrom).add(this.form.length, this.lengthTypeDictCompute[this.form.lengthType]).format("YYYY-MM-DD")
                this.getSalary()
            }

            return this.apiService.getContractCategoryTemplate(value).then(d => {
                this.templateHTML = d
            })

        }
        

        //SERVICES
    },
    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted() {
        this.getContractCategory()
        // this.getToDepartments()
        // this.getFromDepartments()
        
        // this.startVideo()
        // this.getDepartments();
        // this.getEmployees()
    }
}
</script>

<style scoped>
.v-data-table__selected {
  background-color: green !important; /* Customize the background color as needed */
  font-weight: 500;
}
.v-data-table__selected td{
    color: white !important;
}
.v-data-table__selected td .v-input--selection-controls__input i {
    color: white;
}
</style>