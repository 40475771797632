<template>
    <div class="business-type-index">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb>
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <v-card>
            <v-card-title>
                Danh sách kế hoạch công tác
                <v-spacer></v-spacer>
                
            </v-card-title>

            <v-data-table
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItem"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template
                    v-slot:[`item.signDate`]="{ item }"
                >
                    {{ formatDate(item.signDate) }}
                </template>
                
                <template
                    v-slot:[`item.expireDate`]="{ item }"
                >
                    {{ formatDate(item.expireDate) }}
                </template>

                <template
                    v-slot:[`item.isActive`]="{ item }"
                >
                    <v-icon v-if="item.isActive">mdi-check</v-icon>
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <!-- ********* Edit ****** -->
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>

                    <!-- ********* Xoa ****** -->
                    <v-icon
                        small color="error"
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import Create from './Create.vue'

export default {
    name: 'Contract',
    components: {
        myBreadCrumb,
        "create-dialog": Create,
    },
    watch: {
        options: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
    },

    data() {
        return {
            CONTRACT_URL: "businessTypes",
            //HEADER DATA
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Quản lí hợp đồng ',
                disabled: true,
                to: {
                    name: "Contract",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //TABLE DATA
            filter: {
                menu: false,
                search: "",
                searchAttr: "EmployeeA",
                department: "",
                from: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),

                searchAttrs: [
                    {text: "Tên nhân viên", value: "EmployeeA"},
                    {text: "Tên người ký", value: "EmployeeB"},
                ],
                departments: [],
            },
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttr: [],
            },

            headers: [
                {text:"", value: "actions"},
                {text: "Mã", value: "code"},
                {text: "Tên kế hoạch", value: "name"},
                {text: "Ký hiệu", value: "symbol"},
                {text: "Kích hoạt", value: "isActive"},
            ],
            items: [],
            options: {},
            totalItem: 0,
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

        }
    },
    methods: {
        // fetchDepartments () {
        //     return http.get(this.DEPARTMENT_URL,{
        //                     params: {
        //                         dropDown: 1,
        //                     }
        //                 })
        //                 .then(res => {
        //                     this.filter.departments = res.data;
        //                 })
        //                 .catch(err => console.log(err))
        // },
        //DATA JS
        fetchData () {
            this.params =  { ...this.params, 
                                sortBy: this.options.sortBy, //array
                                sortDesc: this.options.sortDesc, //array
                                page: this.options.page,
                                itemsPerPage: this.options.itemsPerPage,
                                searchs: [this.filter.search, this.filter.department ?? ""], //string
                                searchAttrs: [this.filter.searchAttr, "Department"], //string
                                };
            return http.get(this.CONTRACT_URL, {
                params: this.params
            })
            .then(res => {
                this.items = res.data.items;
                this.totalItem = res.data.totalItem
                this.isLoading = false;
            })
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {

            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.CONTRACT_URL + "/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    created() {
        this.fetchData();
    }
}
</script>