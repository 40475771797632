<template>
    <div style="z-index: 99999;">
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
    </div>
</template>

<script>
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
// import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';

export default {
    name: 'app',
    props: ["value"],
    computed:{
        editorData: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    data() {
        return {
            editor: ClassicEditor,
            
            editorConfig: {
                removePlugins: [ 'Markdown' ],
                toolbar: {
                    shouldNotGroupWhenFull: true,
                },
                simpleUpload: {
                    // The URL that the images are uploaded to.
                    uploadUrl: process.env.VUE_APP_BASE_URL + 'api/uploads/misc',

                    // Enable the XMLHttpRequest.withCredentials property.
                    // withCredentials: true,

                    // Headers sent along with the XMLHttpRequest to the upload server.
                    headers: {
                        Accept: '*/*'
                        // 'X-CSRF-TOKEN': 'CSRF-Token',
                        // Authorization: 'Bearer <JSON Web Token>'
                    }
                }
            },
            
        };
    },
    created() {
        console.log("exec", this.editor)
    },
    mounted(){
    //   this.$nextTick(() => {
    //   });
    },
    methods: {
        
    }
}
</script>
<style>
.ck-editor__editable_inline {
    min-height: 250px !important;
    max-height: 400px !important;

}

/*Fix ToolbarSticky*/
.v-dialog .ck.ck-editor__top.ck-reset_all {z-index: var(--ck-z-modal)!important;position: sticky!important;top: -1px!important;}
.v-dialog .ck.ck-sticky-panel__content_sticky{position: unset!important;top:40px !important;}
.v-dialog .ck.ck-sticky-panel .ck-sticky-panel__content_sticky { position: unset!important;}
.v-dialog .ck.ck-sticky-panel__placeholder{height:0px!important;}
.v-dialog .ck.ck-sticky-panel{border-bottom:1px #ccced1 solid !important;}
</style>