
import http from '@/components/services/http-common';
import ApiServices from '@/components/services/ApiServices';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
// import CreateDialog from './Create.vue';
// import Excel from './Excel.vue'
// import CreateDialog from './Create.vue';
import * as moment from "moment/moment";
import Chart from '@/components/Charts/Chart.vue';
// import StoreDialog from './Store.vue';
export default {
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        // 'create-dialog': CreateDialog,
        // 'store-dialog': StoreDialog,
        // 'excel-export': Excel,
        "chart": Chart,
        // 'time-selector': Excel,
    },
    props: ["breadcrumbs", "icon","department"],
    watch: {
        department(val) {
            console.log("has department");
        },
        lockWorkTime () {
            this.fetchLockData()
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.department){
                        // this.fetchDepartment(this.department);
                        this.form.departmentCode = this.department;
                    }
                }
            },
            "form.departmentCode": function(val){
                this.fetchPosition(this.form.departmentCode);
            },
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler: _.debounce(function(){
                    this.tableParams.page=1;
                    this.fetchData();
                }, 500),
            deep: true,
        },
        
        month:{
            handler(val){
                this.filters.month=moment(val, "YYYY-MM").format("MM");
                this.filters.year=moment(val, "YYYY-MM").format("YYYY");
            }
        },
        monthForm:{
            handler(val){
                this.form.processMonth=moment(val, "YYYY-MM").format("MM");
                this.form.processYear=moment(val, "YYYY-MM").format("YYYY");
            }
        },
    },
    computed: {
        startMonth: function() {
            return moment(this.month, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
        },
        endMonth: function() {
            return moment(this.month, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        dItems: function () {
            let mapped = this.items.map(x=>{
                let obj = Object.assign({}, x);
                delete obj.timesheets;
                delete obj.symbols;
                x.timesheets?.forEach(y => {
                    obj[y.date] = y.value;
                });
                for(var i=0; i < this.symbols.length; i++){
                    obj[this.symbols[i]]=x.symbolValues==null?0: x.symbolValues[i]
                }
                return obj;
            })
            return mapped;
        },
        workTimeHeaders: function () {
            let date = moment(this.month+"-01", "YYYY-MM-DD");
            let end = moment(date.format("YYYY-MM-DD")).endOf("month");
            // date = date.add(1,"days")
            let dates = [];
            let cHeaders = [];
            while(date<=end){
                dates.push({ text: date.locale('vi').format("ddd DD/MM"), value: date.format("YYYYMMDD"), width: 50, sortable: false, isSunday: date.day()==0, class: date.isoWeekday()==7?'red-header':''})
                date = date.add(1, "days");
            }
            let symbolHeaders = [];
            this.symbols.forEach(x=>{
                symbolHeaders.push({ text: x, value: x, width: 50, sortable: false, class: "symbol-section", cellClass: "symbol-section"})
            })
            cHeaders = this.headers.concat(dates).concat(symbolHeaders).concat(this.appendHeaders);
            return cHeaders;   
        },
        overtimeHeaders: function () {
            let date = moment(this.month+"-01", "YYYY-MM-DD");
            let end = moment(date.format("YYYY-MM-DD")).endOf("month");
            // date = date.add(1,"days")
            let dates = [];
            let cHeaders = [];
            while(date<=end){
                // if(date.isoWeekday()==1)
                //     this.sundayCodes.push(date.format("YYYYMMDD"));
                dates.push({ text: date.locale('vi').format("ddd DD/MM"), value: date.format("YYYYMMDD"), width: 50, sortable: false, isSunday: date.day()==0, class: date.isoWeekday()==7?'red-header':''})
                date = date.add(1, "days");
            }
            let symbolHeaders = [];
            this.symbols.forEach(x=>{
                symbolHeaders.push({ text: x, value: x, width: 50, sortable: false})
            })
            cHeaders = this.headers.concat(dates).concat(this.appendHeadersOvertime);
            return cHeaders;   
        }
    },
    data () {
        return {
            ROOT_URL: "monthlyTimesheets/manager",
            LOCKDATA_URL: "timeRollProcesses",
            DEPARTMENT_FETCH: "departmentTypes/fetch-department/",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize: 50
            },

            storeLoading: false,
            sundayCodes:[],
            month: moment().format("YYYY-MM"),
            monthForm: moment().format("YYYY-MM"),
            form:{
                processMonth: moment().format("MM"),
                processYear:moment().format("YYYY"),
                isAll: false,
            },
            leavePlans:[],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentName: null,
                workDate: moment().startOf("month").format("YYYY-MM-DD"),
                status: -1,
                isConfirmed: null,
                month: moment().format("MM"),
                year: moment().endOf("month").format("YYYY"),
            },
            rowCount: 0,

            departmentSearch:null,
            departments: [],
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            lockWorkTime: false,
            lockDataLoading: false,
            initDialog: false,
            initLoading: false,
            saveDialog:false,
            unSaveDialog:false,
            reloadDialog: false,
            reloadLoading: false,
            createDialog: false,
            finalizeReport: false,
            excelDialog:false,
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,
            sumObj:null,
            
            chartDataOvertime: {
                labels: ["Thường", "Ngày nghỉ", "Lễ"],
                datasets: [
                    {
                        label: "Số giờ",
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 2
                    },
                ],
            },

            headers: [
                // { text: 'Thao tác', value: 'actions', width: 110},
                { text: 'Mã NV', align: 'start', value: 'code', width: 100},
                { text: 'Tên nhân viên', align: 'start', value: 'name', width: 200, divide: true},
                { text: 'Phòng', align: 'start', value: 'department', width: 200},
                // { text: 'Khối', align: 'start', value: 'departmentType', width: 200},
                // { text: 'Trạng thái', align: 'start', value: 'isLock', width: 100, sortable:false},
                // { text: 'Tháng', align: 'start', value: 'month', width: 100},
                // { text: 'Năm', align: 'start', value: 'year', width: 100},
            ],
            appendHeaders:[
                // { text: 'Ngày công chuẩn', value: 'standardDate', width: 110, sortable: false},
                { text: 'Giờ công chuẩn', value: 'standardHour', width: 110, sortable: false},
                { text: 'Công đã làm', value: 'worktime', width: 110, sortable: true},
                { text: 'Thiếu giờ', value: 'missingHour', width: 110, sortable: true},
                { text: 'Giờ làm thêm', value: 'extend', width: 110, sortable: true},
                // { text: 'Tăng ca chính thức', value: 'overtimeFinal', width: 110, sortable: false},
                // { text: 'Làm thêm chính thức', value: 'extendFinal', width: 110, sortable: false},
                // { text: 'Tổng giờ công sau bù', value: 'totalTimeFinal', width: 110, sortable: false}, //giờ công + giờ bù
                // { text: 'Tổng giờ công chính thức', value: 'totalWorkTime', width: 110, sortable: false}, //giờ công + giờ bù + tăng ca + làm thêm
                { text: 'Cơm', value: 'lunch', width: 110, sortable: true},
            ],
            appendHeadersOvertime:[
                // { text: 'Ngày công chuẩn', value: 'standardDate', width: 110, sortable: false},
                { text: 'Giờ công chuẩn', value: 'standardHour', width: 110, sortable: false},
                { text: 'Công thực tế', value: 'actualTime', width: 110, sortable: true},
                { text: 'Giờ làm thêm', value: 'extend', width: 110, sortable: true},

                { text: 'Giờ làm thiếu', value: 'missingHour', width: 110, sortable: true},
                // { text: 'Công bù', value: 'missingCover', width: 110, sortable: false},
                // { text: 'Công thiếu còn lại', value: 'missingRemain', width: 110, sortable: false},
                // { text: 'Làm thêm còn lại', value: 'extendRemain', width: 110, sortable: false},

                // { text: 'Tăng ca tối đa', value: 'overtimeBooking', width: 110, sortable: false},
                // { text: 'Tăng ca thường', value: 'normalOvertime', width: 110, sortable: false},
                // { text: 'Tăng ca ngày nghỉ', value: 'dayoffOvertime', width: 110, sortable: false},
                // { text: 'Tăng ca lễ', value: 'holidayOvertime', width: 110, sortable: false},

                // { text: 'Tăng ca chính thức', value: 'overtimeFinal', width: 110, sortable: false},
                // { text: 'Làm thêm chính thức', value: 'extendFinal', width: 110, sortable: false},
                // { text: 'Tổng giờ công sau bù', value: 'totalTimeFinal', width: 110, sortable: false},//giờ công + giờ bù
                { text: 'Cơm tăng ca', value: 'overtimeLunch', width: 110, sortable: true},
            ],
            symbols:[],
            interval: null,
            processDataLoading: false,
            fullscreen: false,

            //lockdata 
            lockDepartments: [],
        }
    },
    methods: {
        workTimeReport() {
            console.log("Work Time Report");
        },
        overTimeReport() {
           
        },
        round2Dec(val){
            return Math.round(val * 100) / 100
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchLeavePlan(){
            ApiServices.fetchLeavePlan().then(res=>{
                this.leavePlans = res.data;
            })
        },
        fetchDepartment() {
            return http.get("departments/fetch").then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            this.isLoading = true;
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.symbols = res.data.symbols;
                this.rowCount = res.data.rowCount;
                this.sumObj = res.data.sumObj;
                //chart
                // this.chartDataOvertime.datasets[0].data=[];
                // this.chartDataOvertime.datasets[0].backgroundColor=[];
                // this.chartDataOvertime.datasets[0].borderColor=[];

                // for (const property in this.sumObj) {
                //     this.chartDataOvertime.datasets[0].data.push(this.sumObj[property]);
                //     this.chartDataOvertime.datasets[0].backgroundColor.push(this.getRandomColor());
                //     this.chartDataOvertime.datasets[0].borderColor.push('rgb(225, 225, 225)');
                // }

                // this.$refs.chartOvertime.update()
            })
            .catch(err => {
            }).finally(()=> {this.isLoading = false})
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        fetchLockData ()
        {
            return http.get("timeRollProcesses", {
                params: {
                    month: moment(this.month, "YYYY-MM").format("MM"),
                    year: moment(this.month, "YYYY-MM").format("YYYY"),
                }
            }).then(res => {
                this.lockDepartments = res.data.map(x=> {return x.departmentCode});
                })
        },
        lockData () {
            return http.post("TimeRollProcesses/lock", {
                lockDepartments: this.lockDepartments,
                month: moment(this.month, "YYYY-MM").format("MM"),
                year: moment(this.month, "YYYY-MM").format("YYYY"),
            }).then(res=> {
                this.lockWorkTime = false;
            })
        },
        selectAll(value)
        {
            this.lockDepartments = value ? this.departments.map(x=>{return x.code}): [];
        },
        processData ()
        {   
            // this.isLoading = true;
            this.processDataLoading = true;
            return http.get("monthlyTimesheets/process", {
                params: {
                    month: moment(this.month, "YYYY-MM").format("MM"),
                    year: moment(this.month, "YYYY-MM").format("YYYY"),
                }
            }).then(res=>{
                this.interval = setInterval(this.checkBackground, 1000);
            }).catch(e => {
                this.processDataLoading = false;
            });
        },
        store()
        {
            let month = moment(this.month, "YYYY-MM");
            this.storeLoading = false;
            return http.get("monthlyTimesheets/store", {
                params: {
                    month: month.format("MM"),
                    year: month.format("YYYY"),
                }
            }).then(res=>{
            }).finally(()=> {this.storeLoading = true});
        },

        reloadData(item){
            this.reloadDialog = true;
            return http.get("monthlyTimesheets/reload", {
                params:{
                    year: this.form.processYear,
                    month: this.form.processMonth
                }
            }).then(res=>{
            }).finally(()=>{
                this.reloadLoading = false;
            })
        },

        saveAll() {
            return http.post("monthlyTimesheets/save-all", this.form).then(res=>{
                this.saveDialog = false;
            })
        },
        unSaveAll() {
            return http.post("monthlyTimesheets/unsave-all", this.form).then(res=>{
                this.unSaveDialog = false;
            })
        },

        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
        checkBackground(){
            return http("monthlyTimesheets/background-status").then(res => {
                if(res.data==true)
                {
                    this.processDataLoading =true;
                    // this.isLoading = true;
                } else {
                    console.log("clear intervla", this.interval)
                    this.processDataLoading =false;
                    clearInterval(this.interval);
                }
            }).catch(err => {
                clearInterval(this.interval);
            })
        },
        // goFullscreen() 
        // {
        //     var eleFullScreen = this.$refs.fullscreen;
        //     console.log("go fullscreen", eleFullScreen);
        //     if (eleFullScreen.requestFullscreen) {
        //         eleFullScreen.requestFullscreen();
        //     } else if (eleFullScreen.mozRequestFullScreen) { /* Firefox */
        //         eleFullScreen.mozRequestFullScreen();
        //     } else if (eleFullScreen.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        //         eleFullScreen.webkitRequestFullscreen();
        //     } else if (eleFullScreen.msRequestFullscreen) { /* IE/Edge */
        //         eleFullScreen.msRequestFullscreen();
        //     }
        // }
    },
    created () {
        this.fetchData();
        this.fetchDepartment();
        // this.interval = setInterval(this.checkBackground, 5000);
    },
    mounted() {
        var viewFullScreen = document.getElementById("fullscreen-btn");
        if (viewFullScreen) {
            viewFullScreen.addEventListener("click", function() {
                
                if (document.fullscreenElement) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    
                    if (!document.mozFullScreen && !document.webkitFullScreen) {
                        var docElm = document.getElementById("fullscreen");
                        
                        if (docElm.requestFullscreen) {
                            docElm.requestFullscreen();
                            } else if (docElm.msRequestFullscreen) {
                            docElm.msRequestFullscreen();
                            } else if (docElm.mozRequestFullScreen) {
                            docElm.mozRequestFullScreen();
                            } else if (docElm.webkitRequestFullScreen) {
                            docElm.webkitRequestFullScreen();
                            }
                    }
                }
            })
        }
    },
    beforeDestroy(){
        clearInterval(this.interval);
    },
    destroyed()
    {
        this.interval = clearInterval();
    }
}