<template>
    <div class="assignment">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon"></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>
        <v-card class="rounded-lg"> 
            <v-card-title>
                <month-picker v-model="month" label="Chọn tháng" width="7rem" dense outlined ></month-picker>
                
                <date-picker2 type="date"  v-model="filters.execDate"
                outlined hide-details dense :range="false" label="Ngày xét" :width="200"
                inputClass="caption my-2 mx-2"></date-picker2> 
                <v-spacer></v-spacer>

                <v-btn text color="teal darken-4" class="ml-auto caption" small @click="filterExpand=!filterExpand">
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn><div style="float:clear"></div>

            </v-card-title>
            
             <v-expand-transition>
                <div v-show="filterExpand">
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Trạng thái</h5>
                                <v-select placeholder="Chọn" dense v-model="filters.status" hide-details outlined class="caption"
                                :items='[{text:"Tất cả", value:-1}, {text:"Chờ XN", value:0}, {text:"Đã XN", value:1}, {text:"Từ chối", value:2}]'></v-select>
                            </v-col>
                            <v-col cols="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Mã phiếu</h5>
                                <v-text-field class="caption" placeholder="Nhập" dense v-model="filters.code" hide-details outlined></v-text-field>
                            </v-col>
                            <v-col cols="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Loại phép</h5>
                                <v-select placeholder="Chọn" dense v-model="filters.leavePlanCode" :items="leavePlans" item-text="name" item-value="code" clearable
                                hide-details outlined class="caption"></v-select>
                            </v-col>
                            <v-col cols="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Ngày áp dụng</h5>
                                <v-text-field v-model="filters.from" dense hide-details outlined type="date" class="caption"></v-text-field>
                            </v-col>

                            <v-col cols="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Mã NS</h5>
                                <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" hide-details outlined  class="caption"></v-text-field>
                            </v-col>
                            
                            <v-col cols="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Tên NS</h5>
                                <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" hide-details outlined  class="caption"></v-text-field>
                            </v-col>
                            
                            <v-col cols="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Phòng/Chi nhánh</h5>
                                <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode" hide-details outlined
                                item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                                @update:search-input="fetchDepartment"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
        <v-card class="mt-5 rounded-lg">
            <v-card-title>
                Danh sách nghỉ phép
            <v-spacer></v-spacer>
            <v-btn icon class="px-0 mx-1" color="orange" light>
                <v-icon @click="fetchData">mdi-refresh</v-icon>
            </v-btn>
            <create-button @click="dialog.state=true" ></create-button>
            <data-export v-model="exportDialog"></data-export>
            <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter> -->
            </v-card-title>
            <v-data-table
            :mobile-breakpoint="0"
            height="600px" fixed-header
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            :footer-props="{
                itemsPerPageOptions:[
                50, 100, 200
                ]
            }"
            >
                <!-- <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                            <v-select placeholder="Chọn" dense v-model="filters.leaveAmountType" hide-details outlined
                            :items='[{text:"Cả ngày", value:1}, {text:"Nữa ngày", value:0.5}, {text:"Cả hai", value:2}]'></v-select>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode" hide-details outlined
                            item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                            @update:search-input="fetchDepartment"></v-autocomplete>
                        </td>
                        <td>
                            <v-text-field v-model="filters.from" dense hide-details outlined type="date"></v-text-field>
                            <v-text-field v-model="filters.to" dense hide-details outlined type="date"></v-text-field>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template> -->

                <template v-slot:[`item.from`]="{item}">
                    {{formatDate(item.from)}}
                </template>
                
                <template v-slot:[`item.sentDate`]="{item}">
                    {{formatDate(item.sentDate)}}
                </template>

                <template v-slot:[`item.convfirm`]="{item}">
                    {{formatDate(item.sentDate)}}
                </template>

                <template v-slot:[`item.actions`]="{item}" >
                    <!-- ********* Edit ****** -->
                    <v-btn icon v-if="item.status == 0" class="" color="primary" @click="accept(item)">
                        <v-icon >
                            mdi-check-circle
                        </v-icon>
                    </v-btn>

                    <!-- ********* Xoa ****** -->
                    <v-btn icon class="" v-if="item.status == 0" color="error" @click="decline(item)" >
                        <v-icon >
                            mdi-close-circle
                        </v-icon>
                    </v-btn>

                    <!-- ********* Undo ****** -->
                    <v-btn x-small class="mx-1 my-1 elevation-1" dark v-if="item.status != 0" color="blue-grey" @click="undo(item)" >
                        <v-icon small   >
                            mdi-undo
                        </v-icon> Hoàn tác
                    </v-btn>
                </template>
                <template v-slot:[`item.remove`]="{ item }">
                    <v-btn x-small class="mx-1 my-1" color="error" @click="remove(item)" width="100px">
                        <v-icon small >
                            mdi-delete
                        </v-icon> Xóa phiếu
                    </v-btn>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" small >
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
                <template v-slot:[`item.departmentName`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="teal darken-4" label text-color="white" >
                    <v-icon left>
                        mdi-store
                    </v-icon>
                    {{item.departmentName}}
                    </v-chip>
                </template>
                <template v-slot:[`item.confirmDate`]="{ item }" >
                    {{item.confirmDate}}
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import ApiServices from '@/components/services/ApiServices';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import Export from './Export.vue';
import * as moment from "moment/moment";
import Create from './Create';

export default {
    name: "Attendance",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        'data-export': Export,
        "create-dialog": Create,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                console.log(this.tableParams,"////////");
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                // this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                // this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    computed: {
        // params: function() {
        //     return {...this.tableParams, ...this.filters}
        // }
    },
    data () {
        return {
            ROOT_URL: "leaveRequests/",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:50
            },
            month: moment().format("YYYY-MM"),
            leavePlans:[],
            filters:{
                code: null,
                status: -1,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                leavePlanCode:null,
                from: null,
                to: moment().endOf("month").format("YYYY-MM-DD"),
                leaveAmountType: 2,
                execDate: moment().format("YYYY-MM-DD")
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            exportDialog:false,
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: {
                state: false,
                item: undefined,
            },
            item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', width: '7rem'},
                { text: 'Mã', value: 'code', width: '7rem', 
                    filter: value => {
                    if (!this.filters.code) return true

                    return value.includes(this.filters.code)
                    },
                },
                { text: 'Trạng thái', align: 'start', value: 'status', sortable: true, 
                    filter: value => {
                    if (this.filters.status==-1) return true

                    return value == (this.filters.status)
                    },
                },
                { text: 'Nửa ngày', align: 'start', value: 'leaveAmountType', width: '5rem'},
                { text: '', align: ' d-none', value: 'leavePlanCode', width: '9rem', 
                    filter: value => {
                    if (!this.filters.leavePlanCode) return true

                    return value == (this.filters.leavePlanCode)
                    },
                },
                { text: 'Loại phép', align: 'start', value: 'leavePlan', width: '9rem'},
                { text: 'Mã nhân viên', align: 'start', value: 'employeeCode', width: '9rem', 
                    filter: value => {
                    if (!this.filters.employeeCode) return true

                    return value.includes(this.filters.employeeCode)
                    },
                },
                { text: 'Nhân viên', align: 'start', value: 'employeeName', width: '9rem', 
                    filter: value => {
                    if (!this.filters.employeeName) return true

                    return value.includes(this.filters.employeeName)
                    },
                },
                { text: '', align: ' d-none', value: 'departmentCode', width: '14rem', 
                    filter: value => {
                    if (!this.filters.departmentCode) return true

                    return value == (this.filters.departmentCode)
                    },
                },
                { text: 'Phòng', align: 'start', value: 'departmentName', width: '14rem'},
                { text: 'Ngày áp dụng', align: 'start', value: 'from', width: '8rem',  
                    filter: value => {
                    if (!this.filters.from) return true

                    return value == (this.filters.from)
                    }
                },
                { text: 'Ngày gởi', align: 'start', value: 'sentDate', width: '8rem'},
                { text: 'Ghi chú', align: 'start', value: 'note', width: '12rem'},
                { text: 'Người xác nhận', align: 'start', value: 'confirmBy', width: '7rem'},
                { text: 'Ngày xác nhận', align: 'start', value: 'confirmDate'},
                { text: 'Xóa phiếu', value: 'remove', width: 100},
            ],
            filterExpand: true
        }
    },
    methods: {
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchLeavePlan(){
            ApiServices.fetchLeavePlan().then(res=>{
                this.leavePlans = res.data;
            })
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            this.isLoading = true;
            return http.get(this.ROOT_URL+'admin-mobile/', {
                params: {
                    month: this.month,
                    version: "2"
                }
            })
            .then(res => {
                // console.log(res.data)
                console.log(res.data.data)
                this.items = res.data.data;
                // this.rowCount = res.data.rowCount;
            })
            .catch(err => {

            })
            .finally(() => {
                this.isLoading = false;
            })
        },
        
        accept(item) {
            http.get(this.ROOT_URL + "accept/" + item.code).then(res => {
                // let eventObj = {
                //     color: "success",
                //     message: "Cập nhật thành công",
                //     toast: true,
                // };
                // this.messageToast(eventObj);
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            http.get(this.ROOT_URL + "decline/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            http.get(this.ROOT_URL + "undo/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL+"admin/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchData();
        this.fetchLeavePlan();
    }
}
</script>