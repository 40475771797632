import CreateDialog from '../Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
    },
    data () {
        return {
            dialog: false,
            item: null,
            items:[],
            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text:"Mã", value: "code", width:"7rem"},
                {text:"Tên", value: "name", width:"7rem"},
                {text:"Chuyển hướng tới", value: "redirectTo", width:"12rem"},
                {text:"Số lượt click", value: "visit", width:"7rem"},
            ],

            dialogDelete: false,
            targetItem: null,
        }
    },
    methods: {
        fetchData() {
            return http.get("qrLinks").then(res => {
                this.items = res.data.data;
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `qrLinks/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
    },
    mounted () {
        this.fetchData();
    }
}