<template>
    <div class="education">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb> -->
        <create-dialog-university ref="createDialogUniversity" @needFetch="fetchData"></create-dialog-university>
        <create-dialog-education-major ref="EducationMajorCreateDialog" @needFetch="fetchData"></create-dialog-education-major>
        <create-dialog-academic-rank ref="AcademicRankCreateDialog" @needFetch="fetchData"></create-dialog-academic-rank>
        <create-dialog-education-level ref="EducationLevelCreateDialog" @needFetch="fetchData"></create-dialog-education-level>
        <v-card>
            <v-card-title>Học vấn nhân viên
                <v-spacer></v-spacer>
                <create-button @click="openDialog"></create-button>
            </v-card-title>
            <v-tabs v-model="currentTab" background-color="blue-grey lighten-4"
                    center-active grow>

            <v-tabs-slider color="teal"></v-tabs-slider>

            <v-tab v-for="(item, i) in items" :key="i">
                {{ item.title }}
            </v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentTab">
            <v-tab-item
                v-for="(item, i) in items"
                :key="i"
            >
                <v-card flat>
                    <v-card-title>
                        Danh sách {{item.title.toLowerCase()}}
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" @keydown.enter="fetchData" class="mr-2"
                        append-icon="mdi-magnify"
                        label="Tìm kiếm"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :options.sync="optionsArr[i]"
                    :headers="item.headers"
                    :items="data"
                    :search="search">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                            <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card>
            </v-tab-item>
            </v-tabs-items>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import http from '@/components/services/http-common'
import UniversityCreateDialog from './CreateUniversity'
import EducationMajorCreateDialog from './CreateEducationMajor'
import EducationLevelCreateDialog from './CreateEducationLevel'
import AcademicRankCreateDialog from './CreateAcademicRank'
// import CreateEducationLevel from './CreateEducationLevel.vue'
// import CreateAcademicRank from './CreateAcademicRank.vue'

export default {
    name: 'Education',
    components: {
        'create-dialog-university': UniversityCreateDialog,
        'create-dialog-education-major': EducationMajorCreateDialog,
        'create-dialog-education-level': EducationLevelCreateDialog,
        'create-dialog-academic-rank': AcademicRankCreateDialog
    },
    watch: {
        currentTab: {
            handler(newVal, oldVal) {
                // this.optionsArr[this.currentTab].page = 1;
                
                // console.log(this.optionsArr[this.currentTab]);
                // this.optionsArr[oldVal] = this.options;
                // this.data=[];
                // if(this.optionsArr[this.currentTab] == null )
                // {
                //     console.log("1")
                //     // if(oldVal != newVal)
                //     //     this.options = this.defaultOptions;
                //     this.options = this.options;
                // } else {
                //     this.options = this.optionsArr[this.currentTab];
                //     console.log(this.options)
                // }
                this.fetchData();
            },
            // deep:true,
        },
        options: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
    //   search:{
    //         handler() {
    //             this.items[this.currentTab].options.page = 1;
    //       }
    //   }
    },
    computed: {
    },
    data () {
        return {
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //tab config
            currentTab: 0,
            options: {},
            defaultOptions: {},
            optionsArr: [{}, {}, {}, {}],
            tab: null,
            items: [
                {
                    title: 'Trường',
                    options: {},
                    headers: [
                        {
                            text: 'Mã trường',
                            align: 'start',
                            sortable: false,
                            value: 'code',
                        },
                        {
                            text: 'Tên trường',
                            align: 'start',
                            value: 'name',
                        },
                        { text: 'Cấp bậc', value: 'level' },
                        { text: 'Độ ưu tiên', value: 'priority' },
                        { text: 'Thao tác', value: 'actions', sortable: false },
                    ],
                },
                {
                    title: 'Chuyên ngành',
                    options: {},
                    headers: [
                        {
                            text: 'Mã ngành',
                            align: 'start',
                            sortable: false,
                            value: 'code',
                        },
                        {
                            text: 'Tên ngành',
                            align: 'start',
                            value: 'name',
                        },
                        { text: 'Ghi chú', value: 'note' },
                        { text: 'Thao tác', value: 'actions', sortable: false },
                    ],
                },
                {
                    title: 'Học vị',
                    options: {},
                    headers: [
                        {
                            text: 'Mã học vị',
                            align: 'start',
                            sortable: false,
                            value: 'code',
                        },
                        {
                            text: 'Tên học vị',
                            align: 'start',
                            value: 'name',
                        },
                        { text: 'Cấp bậc', value: 'rankRate' },
                        { text: 'Ghi chú', value: 'note' },
                        { text: 'Thao tác', value: 'actions', sortable: false },
                    ],
                },
                {
                    title: 'Trình độ HV',
                    options: {},
                    headers: [
                        {
                            text: 'Mã TDHV',
                            align: 'start',
                            sortable: false,
                            value: 'code',
                        },
                        {
                            text: 'Tên TDHV',
                            align: 'start',
                            value: 'name',
                        },
                        { text: 'Độ ưu tiên', value: 'priority' },
                        { text: 'Thao tác', value: 'actions', sortable: false },
                    ],
                }
            ],

            //datatable config
            // totalItem: 0, //JS main table
            data: [],
            search:"",
            totalItem: 0,
            isLoading: true,
            dialog: false,
            dialogDelete: false,
            targetItem: undefined,
        }
    },
    methods:{
        openDialog(){
            let vm =this;
            switch(this.currentTab) {
                case 0:
                    vm.$refs.createDialogUniversity.dialog = true;
                    break;
                case 1:
                    vm.$refs.EducationMajorCreateDialog.dialog = true;
                    break;
                case 2:
                    vm.$refs.AcademicRankCreateDialog.dialog = true;
                    break;
                case 3:
                    vm.$refs.EducationLevelCreateDialog.dialog = true;
                    break;
                default:
                    console.log("default");
                    break;
            }
        },
        fetchData () {
            var url= "";
            // const { page, itemsPerPage } = this.options;
            switch (this.currentTab) {
                case 0:
                    url = 'universities?search='+ this.search  + '&dropDown=1';
                    break;
                case 1:
                    url = 'educationmajors?search='+ this.search + '&dropDown=1';
                    break;
                case 2:
                    url = 'academicranks?search='+ this.search + '&dropDown=1';
                    break;
                case 3:
                    url = 'educationlevels?search='+ this.search + '&dropDown=1';
                    break;
            }
            http.get(url).then(res => {
                this.isLoading = false;
                this.data = res.data;
                // this.totalItem = res.data.totalItem;
            }).catch(err => {
                console.log(err);
            });
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        editItem(item) {
            let target;
            switch(this.currentTab) {
                case 0:
                    target = this.$refs.createDialogUniversity
                    break;
                case 1:
                    target = this.$refs.EducationMajorCreateDialog
                    break;
                case 2:
                    target = this.$refs.AcademicRankCreateDialog
                    break;
                case 3:
                    target = this.$refs.EducationLevelCreateDialog
                    break;
                default:
                    console.log("default");
                    break;
            }
            target.isUpdate = true;
            target.item = item;
            target.fetchUpdate(item.code);
            target.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = "";
            switch (this.currentTab) {
                case 0:
                    url = 'universities/';
                    break;
                case 1:
                    url = 'educationmajors/';
                    break;
                case 2:
                    url = 'academicranks/';
                    break;
                case 3:
                    url = 'educationlevels/';
                    break;
            }
            http.delete(url+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    mounted () {
        this.defaultOptions = this.options;
        this.fetchData();
    },
}
</script>

<style lang="stylus" scoped>

</style>