
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter';
import CreateDialog from '../Create.vue';
import * as moment from "moment/moment";
import apiService from '@/components/services/ApiServices'

export default {
    name: "Attendance",
    components: {
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,
    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        
        filters: {
            handler: _.debounce(function(){
                    this.tableParams.page=1;
                    this.fetchData();
                }, 500),
            deep: true,
        },
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "benefitmanagers/",
            EMPLOYEE_FETCH: "employees/search",
            exportPrompt: false,

            //filter
            employees:[],
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            filters:{
                code: null,
                employeeCode: null,
                departmentCode: null
            },
            departmentSearch:null,
            departments: [],
            shifts:[],
            rowCount: 0,
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            types:[
                {text: "Con nhỏ", value: "CON"},
                {text: "Hiếu hỉ", value: "HH"},
                {text: "Tang chế", value: "TC"},
                {text: "Phép tháng (ngoại lệ)", value: "PT"},
                {text: "Phép OFF có lương", value: "OFF"},
            ],

            headers: [
                { text: '', align: 'start', value: 'actions', width: '5rem'},
                { text: 'Mã', align: 'start', value: 'code', width: '5rem'},
                { text: 'Loại', value: 'type', width: '4rem' },
                { text: 'MS nhân viên', align: 'start', value: 'employeeCode', width: '7rem'},
                { text: 'Nhân viên', align: 'start', value: 'name', width: '9rem'},
                { text: 'Thời gian áp dụng', align: 'start', value: 'dates', width: 250},
                // { text: 'Từ', align: 'start', value: 'from', width: 200},
                { text: 'Tạo bởi', align: 'start', value: 'createdBy', width: 250},
                { text: 'Tạo từ', align: 'start', value: 'createdAt', width: 100},
                { text: 'Hình ảnh', align: 'start', value: 'images', width: 200},
                { text: 'Note', value: 'note'},
            ],

            viewImg: null,
            dialogViewImg1: null,
            dialogViewImg2: null,
        }
    },
    methods: {
        convertToExcelLabel(value) {
            if(Array.isArray(value)) {
                return value.map(x => {
                    return {
                        label: x.text,
                        field: x.value
                    }
                })
            }
        },
        
        exportTemplate(){
            try {
                this.loadingDialog = true
                return http.get("benefitmanagers/export", {
                    responseType: 'blob',
                }).then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `DANH_SACH_CHE_DO${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }).finally(()=> {
                    this.loadingDialog=false
                });
            } catch(e){
                alert(e)
            }
        },
        //Filter
        customFilter (item, queryText, itemText) {
            const textOne = item.code.toLowerCase()
            const textTwo = item.name.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
            textTwo.indexOf(searchText) > -1
        },

        // format
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        //fetching
        fetchEmployee(val) {
            return http.get(this.EMPLOYEE_FETCH).then(res => {
                this.employees = res.data;
            });
        },
        fetchDepartment() {
            apiService.fetchDepartment(this.departmentSearch)
            .then(res => {
                this.departments = res.data;
            })
            .catch(err => console.log(err))
        },
        fetchShift() {
            return http.get("shifts").then(res => {
                this.shifts = res.data;
            })
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.items.results;
                this.rowCount = res.data.items.rowCount;
            })
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchEmployee();
        this.fetchData();
        this.fetchShift();
        this.fetchDepartment();
    },
    mounted () {
        this.$nextTick(function () {
        })
    }
}