import moment from 'moment';
import http from '../../../components/services/http-common';
export default {
    methods: {
        statusText(val) {
            let str = "aa";
            switch (val) {
                case 0:
                    str = "Chờ duyệt"
                    break;
                case 1:
                    str = "Xác nhận"
                    break;
                case 2:
                    str = "Từ chối"
                    break;
                default:
                    break;
            }
            console.log(val);
            return str;
        },
        initDate() {
            const zeroPad = (num, places) => String(num).padStart(places, '0');
            var curr = new Date(); // get current date
            var first = new Date(curr.getFullYear(), curr.getMonth(), 1);
            var last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
            var firstdayStr = `${first.getFullYear()}-${zeroPad(first.getMonth()+1, 2)}-${zeroPad(first.getDate(), 2)}`;
            var lastdayStr = `${last.getFullYear()}-${zeroPad(last.getMonth()+1, 2)}-${zeroPad(last.getDate(), 2)}`;
            this.filter.from = firstdayStr;
            this.filter.to = lastdayStr;
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        //DATA JS
        fetchOvertimeType() {
            return http.get("overtimeTypes/fetch").then(res => {
                this.overtimeTypes = res.data.items;
                console.log(res.data);
            })
        },
        fetchData () {
            // this.params =  { ...this.params, 
            //                     sortBy: this.options.sortBy, //array
            //                     sortDesc: this.options.sortDesc, //array
            //                     page: this.options.page,
            //                     itemsPerPage: this.options.itemsPerPage,
            //                     searchs: [this.filter.search, this.filter.department ?? ""], //string --COMBINE SEARCH AND FILTER
            //                     searchAttrs: [this.filter.searchAttr, "Department"], //string --COMBINE SEARCH AND FILTER
            //                     };
            return http.get(this.ROOT_URL + "/self", {
                params:{
                    month: moment(this.month, "YYYY-MM").format("YYYY-MM-DD")
                }
            })
            .then(res => {
                this.items = res.data;
                // this.totalItem = res.data.totalItem;
                this.isLoading = false;
            })
        },
    
        //DIALOG METHOD
        openDialog() {
            this.params = {
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        
        detailItem (item) {
            this.dialog.state = true;
            this.dialog.item = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/self/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
    }
}