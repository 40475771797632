<template>
    <div class="leave-type">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog=true"></myBreadCrumb> -->
        <create-dialog ref="createDialog" @needFetch="fetchData" :item="item" v-model="dialog"></create-dialog>
        <v-card>
            <v-card-title>
                Danh sách loại phép
            <v-spacer></v-spacer>
            <create-button @click="dialog=true"></create-button>
            <v-divider vertical class="mx-2"></v-divider>
            <v-text-field v-model="search" @keydown.enter="fetchData" class="mr-2" 
                append-icon="mdi-magnify" style="max-width: 15rem"
                label="Tìm kiếm" dense
                single-line outlined
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-data-table height="500px" group-by="type"
            :headers="headers"
            :items="items"
            :search="search" :items-per-page="-1"
            
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.useLeave`]="{ item }">
                    <v-icon v-if="item.useLeave">mdi-check</v-icon>
                </template>
                <template v-slot:[`item.isActive`]="{ item }">
                    <v-icon v-if="item.isActive">mdi-check</v-icon>
                </template>
                <template v-slot:[`item.payMethodCode`]="{ item }">
                    <v-icon v-if="item.hasSalary">mdi-check</v-icon>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn x-small @click="editItem(item)" class="mx-1">
                        <v-icon small >
                            mdi-pencil
                        </v-icon>Sửa
                    </v-btn>
                    
                    <v-btn x-small @click="deleteItem(item)" color="error" class="mx-1">
                        <v-icon small  >
                            mdi-delete
                        </v-icon>Xóa
                    </v-btn>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
// import LeaveTypeCreateDialog from './LeaveTypeCreate';
import * as moment from "moment/moment";
import Create from "./Create.vue";

export default {
    name: "LeaveType",
    props:["breadcrumbs", "icon"],
    components: {
        myBreadCrumb,
        "create-dialog": Create
    },
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
    },
    data () {
        return {
            ROOT_URL: "leaveplans",

            // ********* datatable config **********
            
            items: [],
            search:"",
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,
            
            headers: [
                { text: 'Thao tác', value: 'actions', sortable: false, width: '12rem'},
                { text: 'Mã', value: 'code', },
                { text: 'Loại', value: 'type', groupable: true },
                { text: 'Tên hoạch kế hoạch nghỉ', value: 'name', },
                { text: 'Số ngày nghỉ', value: 'amount' },
                { text: 'Kích hoạt', value: 'isActive' },
                { text: 'Có lương', value: 'payMethodCode' },
                { text: 'Ghi chú', value: 'description' },
            ],
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData () {
            this.isLoading =true;
            return http.get(this.ROOT_URL).then(res => {
                this.items = res.data;
                this.isLoading =false;
            }).catch(err => {
                console.log(err);
            });
        },
        editItem(item) {
            this.item =item,
            this.dialog =true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err);
            })
        },
    },
    mounted() {
        this.fetchData();
    }
}
</script>
<style scoped>

</style>