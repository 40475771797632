<template>
  <div id="navless">
    <v-snackbar :timeout="3000"
      v-model="showAlert" absolute
      right top color="error" >
        <v-icon dark>
          mdi-alert
        </v-icon>
      {{errorMessage}}
      <h6 v-for="(item, index) in errorMessages" :key="index">
        {{item}}
      </h6>
    </v-snackbar>
    <v-app-bar color="white" elevate-on-scroll app light class="px-0" height="92px">
      <v-container class="d-flex py-0 flex-grow-1" style="height:100%">
      <v-app-bar-title v-if="$vuetify.breakpoint.smAndUp">
        <router-link :to="{name: 'login'}" class="d-block my-auto"> 
          <img  v-show="scrollTop!=0 || currentRouteName != 'login'" src="/static/logo2.png" alt="logo" height="68px" class="my-2">
          <!-- <v-img  contain v-show="scrollTop!=0 || currentRouteName != 'login'"
            width="256"
            src="/static/logo2.png"
          ></v-img> -->
        </router-link>
      </v-app-bar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <div class="d-flex align-center" v-if="$vuetify.breakpoint.smAndDown">
          
          <!-- <v-btn class="my-auto mx-2" text :to="{name: 'login'}">TRANG CHỦ</v-btn> -->
          <v-btn outlined x-large color="#187733" style="border: 3px; border-style: solid; border-color: #187733;" class="font-weight-bold" rounded :to="{name: 'RecruitNavless'}" type="button" >TUYỂN DỤNG</v-btn>
          <v-menu bottom left >
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
            </template>

            <v-list>
              <v-list-item link :to="{name: 'login'}">
                <v-list-item-title>TRANG CHỦ</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title>GIỚI THIỆU</v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>TIN TỨC</v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>LIÊN HỆ</v-list-item-title>
              </v-list-item>

              <v-list-item link :to="{name: 'RecruitNavless'}">
                <v-list-item-title>TUYỂN DỤNG</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex align-center" v-else>
          <v-btn color="green darken-4" class="my-auto" plain :to="{name: 'login'}">TRANG CHỦ</v-btn>
          <!-- <v-btn color="green darken-4" class="my-auto" plain>GIỚI THIỆU</v-btn> -->
          <v-btn color="green darken-4" class="my-auto" plain>TIN TỨC</v-btn>
          <!-- <v-btn color="green darken-4" class="my-auto" plain>LIÊN HỆ</v-btn> -->
          <v-btn outlined rounded x-large color="#187733" class="ml-4 font-weight-bold" :to="{name: 'RecruitNavless'}" type="button" style="border: 3px; border-style: solid; border-color: #187733;">TUYỂN DỤNG</v-btn>

        </div>
        <!-- <div class="d-flex align-center">
        </div> -->
      
      </v-toolbar-items>
      </v-container>
      <!-- <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to bottom right, rgba(230,230,230,.5), rgba(70,128,30,.8)"
        ></v-img>
      </template> -->
      
        <!-- <v-avatar class="hidden-md-and-up" tile><img src="/trungson.jpg"></v-avatar> -->
      <!-- <v-app-bar-title > -->
        <!-- <v-avatar width="512" class="hidden-sm-and-down" tile><img  src="/static/logo2.png"></v-avatar> -->
      <!-- </v-app-bar-title> -->

      <!-- <v-app-bar-title class="mx-3 font-weight-regular title">TRUNGSON PHARMA</v-app-bar-title> -->
      
      <!-- <template v-slot:extension>
      </template> -->
    </v-app-bar>
    
      <v-main>
        <v-container :fluid="true" transition="fade" class="pa-0"
        >
          <router-view></router-view>
          <!-- <div class="zalo-chat-widget" data-oaid="2756912669568493531" data-welcome-message="Rất vui khi được hỗ trợ bạn!" data-autopopup="0" data-width="350" data-height="420"></div> -->
        </v-container>
      </v-main>
      <v-footer dark padless style="">
        <v-card flat tile class="teal darken-4 lighten-1 white--text text-center" color="transparent" width="100%">
          <v-card-text>
            <v-btn class="mx-4 white--text" icon href="https://www.facebook.com/nhathuoctrungson/">
              <v-icon size="24px" >
                mdi-facebook
              </v-icon>
            </v-btn>
            <v-btn class="mx-4 white--text" icon >
              <v-icon size="24px">
                mdi-instagram
              </v-icon>
            </v-btn>
            <v-btn class="mx-4 white--text" icon >
              <v-icon size="24px">
                mdi-link
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0">
            Được thành lập từ năm 1997, Hệ thống nhà thuốc Trung Sơn là chuỗi nhà thuốc được tin dùng và yêu thích nhất khu vực Mekong và TP. Hồ Chí Minh
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — <strong>TRUNGSON PHARMA</strong>
          </v-card-text>
        </v-card>
      </v-footer>
  </div>
</template>

<script>
import EventBus from '../../components/services/EventBus'
export default {
  name: "NavlessLayout",
  data() {
    return {
      showAlert: false,
      errorMessage: null,
      errorMessages: [],
      vertical: true,

      //data
      scrollTop: 0,
    }
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    errorsHandler (e){
      //**SWEET ALERT
      console.log("errors")
    },
    errorHandler (e) {
      this.$Swal.fire({
        title: "Thông báo",
        text: e.message,
        icon: 'error',
        toast: e.toast ?? false,
        timer: e.timer != undefined ? (e.timer ?? 3000) : undefined,
        position: e.toast ? 'top-end' : 'center',
        showConfirmButton: e.showConfirmButton? e.showConfirmButton : false,
      });
    },
    
    messageHandler (e){
      //**SWEET ALERT
      this.$Swal.fire(
      {
        title: "Thông báo",
        text: e.message,
        icon: 'success',
        toast: e.toast ?? false,
        timer: 3000,
        position: e.toast ? 'top-end' : 'center',
        showConfirmButton: false,
      });
    },
    messagesHandler (e) {

    }
    
  },
  created() {
    window.onscroll = () => {
      this.scrollTop = document.documentElement.scrollTop;
    };
    // Listening the event hello
    EventBus.$on('hasError', this.errorHandler);
    EventBus.$on('hasErrors', this.errorsHandler);
    EventBus.$on('hasMessage', this.messageHandler);
    EventBus.$on('hasMessages', this.messagesHandler);
    
  },
  destroyed() {
    // Stop listening the event hello with handler
    EventBus.$off('hasError', this.errorHandler);
    EventBus.$off('hasErrors', this.errorsHandler);
    EventBus.$off('hasMessage', this.messageHandler);
    EventBus.$off('hasMessages', this.messagesHandler);
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style scoped>

</style>