import LeaveEnroll from '@/views/HR/LeaveEnroll/Index.vue';
import LeaveEnrollCreate from '@/views/HR/LeaveEnroll/Create.vue';


export default [
    {
      path: 'benefits/leave-enroll',
      name: 'LeaveEnroll',
      component: LeaveEnroll,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Phân bổ phép',
            disabled: true,
            to: {
                name: "Assignment",
                }
            }
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
    
    {
      path: 'leave-enroll/create',
      name: 'LeaveEnrollCreate',
      component: LeaveEnrollCreate,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Phân bổ phép',
            disabled: false,
            to: {
                name: "LeaveEnroll",
                }
            },
            {
            text: 'Tạo phân bổ phép',
            disabled: true,
            to: {
                name: "LeaveEnrollCreate",
                }
            }
        ],
        icon:{
            text:"Lưu",
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
    
    {
      path: 'leave-enroll/edit/:id/:id2',
      name: 'LeaveEnrollEdit',
      component: LeaveEnrollCreate,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Danh sách phân bổ phép',
            disabled: false,
            to: {
                name: "LeaveEnroll",
                }
            },
            {
            text: 'Chỉnh sửa phân bổ phép',
            disabled: true,
            to: {
                name: "LeaveEnrollEdit",
                }
            }
        ],
        icon:{
            text:"Lưu",
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
  ]
