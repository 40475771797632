<template>
    <div>
      <v-dialog v-model="createDialog" width="600px" >
        <create-form :itemId="selectItem" @close-dialog="closeDialogHandler" ref="create_form"></create-form>
      </v-dialog>
        <v-card>
            <v-toolbar color="white" flat>
                <v-toolbar-title class="mx-4">Danh sách phiếu</v-toolbar-title>
                <v-spacer></v-spacer>
                
                <v-btn color="primary" text @click="fetchData"><v-icon left >mdi-refresh</v-icon> Làm mới</v-btn>
                <!-- //actions -->
                <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success" class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="createDialog=true">
                            <v-list-item-title>Thêm phiếu <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            
            <v-card-title>
                <v-text-field hide-details v-model="search" placeholder="Tìm kiếm" label="Tìm kiếm" persistent-placeholder outlined dense></v-text-field>
            </v-card-title>
            <v-data-table :items="items" :headers="headers"  height="calc(100vh - 300px)" :search="search">
                <template v-slot:[`item.actions`]="{item}" >
                    <!-- <edit-button @click="edit(item)"></edit-button>
                    <delete-button @click="deleteItem(item)"></delete-button> -->
                    <!-- <v-btn x-small color="error"  @click="deleteItem(item)"><v-icon left>mdi-delete</v-icon> Xóa</v-btn> -->
                    
                    <v-menu dense left bottom offset-y >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" x-small
                                v-bind="attrs"
                                v-on="on"
                            >
                                Thao tác<v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            
                            <v-list-item @click="deleteItem(item)" dense>
                                <v-list-item-icon>
                                    <v-icon color="error">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Xóa </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="edit(item)" dense>
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-pen</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Chi tiết </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                
                <template v-slot:[`item.description`]="{item}" >
                    <span v-html="item.description"></span>
                </template>

            </v-data-table>

        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="error" @click="cfmDeleteItem">OK</v-btn>
                        <v-btn color="primary" @click="dialogDelete=false" outlined>Thoát</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import CreateDialog from './Create.vue';
import moment from 'moment/moment';
import Api from './api'
import CreateForm from './CreateForm.vue'

export default {
    components:{
      CreateForm
    },
    watch:{
        createDialog(val) {
            if(val){
              console.log('open dialog')
            }
            else {
              this.selectItem=null
              this.$refs.create_form.resetForm()
            }
        },
    },
    data () {
        return {
            month: moment().startOf("months").format("yyyy-MM"),
            item: null,
            items:[],
            search:"",

            filterCode:"", 
            filterStatus:"", 
            filterDepartment:"", 
            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text:"Mã", value: "code"},
                {text:"Tên", value: "name"},
                {text:"Mô tả", value: "description"},
                // {text:"Loại thời gian gởi", value: "repeatType"},
                // {text:"Từ ngày", value: "from"},
                // {text:"Đến ngày", value: "to"},
                {text:"Hoạt động", value: "isActive"},
                {text:"Ẩn", value: "isHide"},
            ],

            dialogDelete: false,
            dialog: false,

            createDialog: false,
            selectItem: null,
        }
    },
    methods: {
        fetchData() {
            this.apiService.gets().then(d => {
              this.items = d
            })
        },
        closeDialogHandler() {
            this.selectItem = null;
            this.createDialog = false;
            this.fetchData()
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.selectItem = item.code;
            this.createDialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return this.apiService.delete(this.targetItem.code).then(() => {
                this.fetchData()
                this.dialogDelete= false
                this.targetItem = null
            })
        },
    },
    apiService: null,
    created() {
      this.apiService = new Api();
    },
    mounted () {
        this.fetchData();
    }
}
</script>