<template>
    <div class="job-create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="800px" scrollable>
                <v-card>
                    <v-card-title> {{titleText}} công việc ứng tuyển </v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="saveData" id="form_job_create" v-model="valid" ref="form">
                            <v-row class="mx-auto">
                                <v-col>
                                    <label for="name">Tên công việc</label>
                                    <v-text-field id="name" v-model="form.name" solo outlined flat dense>
                                    </v-text-field>
                                    
                                    <v-radio-group v-model="form.requiredCv" row>
                                        <v-radio label="Yêu cầu CV" :value="1"></v-radio>
                                        <v-radio label="Không yêu cầu CV" :value="0"></v-radio>
                                    </v-radio-group>
                                    
                                    <label for="note">Ghi chú</label>
                                    <v-textarea id="note" v-model="form.note" solo outlined flat dense rows="2">
                                    </v-textarea>
                                    
                                    <v-checkbox v-model="form.isActive" label="Kích hoạt"></v-checkbox>
                                </v-col>
                                <div style="width: 100%; display: block;" class="ml-4">
                                    Mô tả công việc 
                                </div>
                                <v-col cols="12">
                                    <editor v-model="form.description"/>
                                    <small>Độ dài: {{ descriptionLength }}</small>
                                </v-col>

                                <v-col cols="6">
                                    <v-select label="Thành phố khả dụng" :items="[
                                        {
                                            text: 'Tất cả các thành phố', value: 1
                                        },
                                        {
                                            text: 'Giới hạn thành phố', value: 0
                                        },
                                    ]" v-model="form.isAvailableAllCities">
                                    </v-select>
                                </v-col>

                                <v-col cols="6"> 
                                    <v-autocomplete multiple label="Giới hạn thành phố" 
                                    :search-input.sync="citySearchInput" :items="cities"
                                    @change="citySearchInput = ''"
                                     v-model="availableCityCodesArr" clearable item-value="code" item-text="name">
                                    </v-autocomplete>
                                </v-col>

                                
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  color="primary" form="form_job_create" type="submit" dark small>Lưu dữ liện</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import Editor from '@/components/Editor/Editor.vue';
export default {
    name: "Create",
    props: ["value"],
    computed:{
        titleText () {
            return this.value.item ? "Cập nhật" : "Tạp mới";
        },
        descriptionLength() {
            if(!this.form.description) {
                return 0
            } else {
                return this.form.description.length
            }
        }
    },
    components:{
        Editor
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            },
            deep: true,
        },
    },
    data () {
        return {
            ROOT_URL: "Jobs",

            //FORMDATA
            valid: false,
            formData: { },
            cities: [],
            citySearchInput: '',

            availableCityCodesArr: [],
            form: {
                // code:"",
                name: "",
                note: "",
                description: "",
                isActive: true,
                isAvailableAllCities: 1,
                availableCityCodes: '',
                requiredCv: 0
            },

            defaultForm: {
                // code:"",
                name: "",
                note: "",
                description: "",
                isActive: true,
                isAvailableAllCities: 1,
                availableCityCodes: '',
                requiredCv: 0
            },
        }
    },
    created() {
        this.fetchCities()
    },
    methods: {
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },

        fetchCities() {
            return http.get("cities/fetch").then(res => {
                this.cities = res.data.data
            })
        },

        fetchData () {
            this.availableCityCodesArr  = []
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                let item = res.data;
                console.log(item);
                this.form = Object.assign({}, item);
                this.availableCityCodesArr  = this.form.availableCityCodes.split(",")
            })
            .catch(err => {
                console.log(err);
            });
        },
        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                if(!this.value.item)
                {
                    this.form.availableCityCodes = this.availableCityCodesArr.join(",")

                    return http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                        this.close();
                    })
                    .catch(err => {
                    });
                } else {
                    this.form.availableCityCodes = this.availableCityCodesArr.join(",")
                    return http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                        this.close();
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
            }
        },
    }
    
}
</script>