<template>
    <div class="timesheet-2">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon"></myBreadCrumb>
        <!-- <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog> -->
        <v-card>
            <v-card-title>
                <v-btn small color="indigo" @click="createDialog=true" class="mx-1" dark>Khóa công tất cả</v-btn>
                <v-btn small color="orange"  @click="unLockDialog=true" class="mx-1" dark>Mở công tất cả</v-btn>
                <v-spacer></v-spacer>
                <month-picker v-model="month" label="Chọn tháng"></month-picker>
                
                <v-btn small text class="elevation-2"><v-icon @click="fetchData">mdi-refresh</v-icon></v-btn>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
                </data-filter> -->
            </v-card-title>
            
            <v-data-table
            :mobile-breakpoint="0"
            height="600px" fixed-header
            :headers="headers"
            :items="items"
            :page.sync="tableParams.page"
            :sort-by.sync="tableParams.sortBy"
            :sort-desc.sync="tableParams.sortDesc"
            :server-items-length="rowCount"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-select placeholder="Chọn" dense v-model="filters.status" outlined hide-details
                            :items='[{text:"Tất cả", value:-1},{text:"Mở khóa", value:0}, {text:"Khóa", value:1}]'></v-select>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.departmentName"  outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.month" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.year" outlined hide-details></v-text-field>
                        </td>
                    </tr>
                </template>
                <template v-slot:[`item.actions`]="{item}" >
                    <v-btn x-small @click="edit(item)" class="mx-1 elevation-2" text>
                        <v-icon small>mdi-pencil</v-icon> Xem
                    </v-btn>
                </template>
                <template v-slot:[`item.isConfirmed`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.isConfirmed).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.isConfirmed).text}}</strong>
                    </v-chip>
                </template>
                <template v-slot:[`item.status`] = "{item}">
                    <v-icon v-if="item.status==1" color="grey">mdi-lock</v-icon>
                    <v-icon v-else color="green">mdi-lock-open</v-icon>
                </template>
                <template
                    v-slot:[`item.sentDate`]="{ item }"
                >
                    {{ formatDate(item.sentDate) }}
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="createDialog" width="500" >
            <v-card>
                <v-card-title class="secondary white--text">Khóa công</v-card-title>
                <v-form @submit.prevent="createData">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <month-picker v-model="monthForm" label="Chọn tháng khóa công"></month-picker>
                                <v-checkbox v-model="form.isAll" label="Áp dụng cho tất cả phòng ban"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="primary" type="submit">Khóa công</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="unLockDialog" width="500" >
            <v-card>
                <v-card-title class="secondary white--text">Mở công</v-card-title>
                <v-form @submit.prevent="unLockData">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <month-picker v-model="monthForm" label="Chọn tháng mở công"></month-picker>
                                <v-checkbox v-model="form.isAll" label="Áp dụng cho tất cả phòng ban"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="primary" type="submit">Mở công</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="calDialog" width="500" >
            <v-card>
                <v-card-title class="secondary white--text">Chốt công</v-card-title>
                <v-form @submit.prevent="calData">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <month-picker v-model="monthForm" label="Chọn tháng chốt công"></month-picker>
                                <v-checkbox v-model="form.isAll" label="Áp dụng cho tất cả phòng ban"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="primary" type="submit">Chốt công</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import ApiServices from '@/components/services/ApiServices';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import CreateDialog from './Create.vue'
// import CreateDialog from './Create.vue';
import * as moment from "moment/moment";
import ExcelDialog from './Excel';

export default {
    name: "Attendance",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").format("MM");
                this.filters.to=moment(val, "YYYY-MM").format("YYYY");
            }
        },
        monthForm:{
            handler(val){
                console.log(val)
                this.form.processMonth=moment(val, "YYYY-MM").format("MM");
                this.form.processYear=moment(val, "YYYY-MM").format("YYYY");
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "timeRollProcesses",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },

            month: moment().format("YYYY-MM"),
            monthForm: moment().format("YYYY-MM"),
            form:{
                processMonth: moment().format("MM"),
                processYear:moment().format("YYYY"),
                isAll: true,
            },
            leavePlans:[],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentName: null,
                workDate: moment().startOf("month").format("YYYY-MM-DD"),
                status: -1,
                isConfirmed: null,
                month: moment().format("MM"),
                year: moment().endOf("month").format("YYYY"),
            },
            rowCount: 0,

            departmentSearch:null,
            departments: [],

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            calDialog:false,
            unLockDialog: false,
            createDialog: false,
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', width: 110},
                { text: 'Mã', align: 'start', value: 'code', width: 100},
                { text: 'Trạng thái', align: 'start', value: 'status', width: 100},
                { text: 'Phòng', align: 'start', value: 'department.name', width: 200},
                { text: 'Tháng', align: 'start', value: 'month', width: 200},
                { text: 'Năm', align: 'start', value: 'year', width: 200},
                // { text: 'Ngày áp dụng', align: 'start', value: 'date', width: 250},
                // { text: 'Số giờ', align: 'start', value: 'amount', width: 100},
                // { text: 'Người xác nhận', align: 'start', value: 'confirmedBy.name', width: 250},
                // { text: 'Ngày xác nhận', align: 'start', value: 'confirmedDate', width: 250},
            ],
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchLeavePlan(){
            ApiServices.fetchLeavePlan().then(res=>{
                this.leavePlans = res.data;
            })
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            this.isLoading = true;
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                console.log(res.data)
                this.isLoading = false;
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
            })
            .catch(err => {
            }).finally(()=> {this.isLoading = false});
        },
        createData(){
            this.isLoading = true;
            return http.post("timeRollProcesses", this.form).then(res => {
                this.fetchData();
            }).catch(e=>{});
        },
        unLockData(){
            this.isLoading = true;
            return http.post("timeRollProcesses/unlock-all", this.form).then(res => {
                this.fetchData();
            }).catch(e=>{});
        },
        
        calData(){
            this.isLoading = true;
            return http.post("timeRollProcesses/calculate", this.form).then(res => {
                this.fetchData();
            }).catch(e=>{});
        },

        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchData();
        this.fetchLeavePlan();
    }
}
</script>