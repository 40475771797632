
import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        },

    },
    computed: {
        dialog: {
            get: function () {
                this.fetchTodos();
                this.fetchToday();
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
        today: function() {
            return moment().format("DD/MM/YYYY")
        }
    },
    data() {
        return {
            ROOT_URL: "EmployeeDailyTodos/",

            isValid: true,
            fetchData: false,

            todos: [],
            form: {
                code: null,
                employeeCode:"",
                departmentCode: "",
                note:"",
                todos:[],
                createdAt: "",
                updatedAt: "",
            },
            defaultForm:{
                code: null,
                employeeCode:"",
                departmentCode: "",
                note:"",
                todos:[],
                createdAt: "",
                updatedAt: "",
            },
            quitReasons: [
                "Khác",
            ]
        }
    },
    methods: {
        // fetch(employee = false){
        //     this.fetchData =true;
        //     return http.get(this.ROOT_URL+this.item.code).then(res => {
        //         this.form = Object.assign({}, res.data, ...this.form);
        //         this.fetchData =false;
        //     })
        // },
        fetchTodos() {
            return http.get(this.ROOT_URL+"fetch/todos").then(res => {
                this.todos = res.data;
            });
        },
        fetchToday() {
            return http.get(this.ROOT_URL+"employee/today").then(res => {
                if(res.data){
                    this.form = Object.assign({}, res.data.data);
                }
            });
        },

        saveData() {
            let form = this.form;
            this.$refs.form.validate();
            return http.post(this.ROOT_URL+"employee", {
                code : this.form.code,
                todos: this.form.todos,
                note: this.form.note,
            } 
            ).then(res =>{
                this.$emit("fetch");
                this.dialog = false;
            });
        },

        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fetchData=false;
        }
    },
    created() {
    }
}