<template>
    <v-menu
        ref="menu"
        v-model="formData.menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        :return-value.sync="value"
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :dense="dense"
            v-model="value"
            :label="title"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="rules"
        ></v-text-field>
        </template>
        <v-date-picker
        color="success"
        show-current="false"
        locale="vi"
        v-model="date"
        @change="changeHandle"
        @input="updateVal"
        type="date"
        no-title
        scrollable>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: "DatePicker",
    // props: ['value', 'title'],
    props: {
        value: {
            default: new Date().toISOString().substr(0, 10),
            type: String
        },
        title: {
            default: "",
            type: String
        },
        dense: {
            default: false,
            type: Boolean
        },
        rules: {
            default() {
                return []
            },
            type: Array
        }
    },
    data () {
        return{
            formData: {
                menu: false,
                dataDate: this.value,
            },
            dateStr: ""
        }
    },
    watch: {
        formData: {
            handler(newVal, oldVal){
                if(newVal.dataDate != oldVal.dataDate)
                    this.formData.dataDate = this.value;
            },
            deep: true
        },

    },
    computed: {
        date: {
            // getter
            get: function () {
                this.dateStr = this.value;
                return this.value;
            },
            // setter
            set: function (value) {
                this.dateStr = value;
            }
        },
    },
    methods: {
        changeHandle (){
            this.$refs.menu.save(this.dateStr);

        },
        updateVal () {
            var val = this.formData.dataDate;
            this.$emit('input', this.dateStr);
        }
    },
    mounted() {
        console.log(this.value);
    }
    
}
</script>