<template>
  <div>
    <my-breadcrumb :data="breadcrumb"></my-breadcrumb>
    <v-card class="mb-4">
      <v-tabs>
        <v-tab>Phép năm</v-tab>
        <v-tab>Phân bổ theo vị trí</v-tab>
        <v-tab>Phân bổ theo cá nhân</v-tab>

        <v-tab-item>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn small class="mx-2" color="teal darken-4" dark outlined><v-icon>mdi-excel</v-icon> Xuất excel</v-btn>
            <v-btn small class="ml-2" color="teal darken-4" dark><v-icon>mdi-plus</v-icon> Thêm</v-btn>
          </v-card-title>

          <v-card-text>
            <v-data-table :items="items1" :headers="headers1">
              <template v-slot:[`item.isActive`]="{ item }">
                <v-icon color="green" v-if="item.isActive">mdi-check</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn small class="mx-2" color="teal darken-4" dark outlined><v-icon>mdi-excel</v-icon> Xuất excel</v-btn>
            <v-btn small class="ml-2" color="teal darken-4" dark @click="dialog2=true"><v-icon>mdi-plus</v-icon> Thêm</v-btn>
          </v-card-title>

          <v-card-text>
            <v-data-table :items="items2" :headers="headers2">
              <template v-slot:[`item.isActive`]="{ item }">
                <v-icon color="green" v-if="item.isActive">mdi-check</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn small class="mx-2" color="teal darken-4" dark outlined><v-icon>mdi-excel</v-icon> Xuất excel</v-btn>
            <v-btn small class="ml-2" color="teal darken-4" dark @click="dialog3=true"><v-icon>mdi-plus</v-icon> Thêm</v-btn>
          </v-card-title>

          <v-card-text>
            <v-data-table :items="items3" :headers="headers3">
              <template v-slot:[`item.isActive`]="{ item }">
                <v-icon color="green" v-if="item.isActive">mdi-check</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-dialog
      v-model="dialog2"
      persistent 
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
        Tạo dữ liệu mới
        </v-card-title>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12">
              <v-select label="Chức danh" outlined hide-details="auto" dense
              item-text="name" item-value="code"
               v-model="data2.positionStateCode" :items="positionStates"></v-select>
            </v-col>
            <v-col cols="12">
              <v-select label="Loại phép" outlined hide-details="auto" dense :items="leavePlans"
              item-text="name" item-value="code" v-model="data2.leavePlanCode"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="teal darken-4" @click="saveData2">Lưu</v-btn>
          <v-btn dark color="error" outlined @click="dialog2=false">Thoát</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog3"
      persistent 
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
        Tạo dữ liệu mới
        </v-card-title>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12">
              <v-autocomplete label="Nhân viên" outlined hide-details="auto" dense
              item-text="name" item-value="code" clearable 
               v-model="data3.employeeCode" :items="employees"></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-select label="Loại phép" outlined hide-details="auto" dense :items="leavePlans"
              item-text="name" item-value="code" v-model="data3.leavePlanCode"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="teal darken-4" @click="saveData3">Lưu</v-btn>
          <v-btn dark color="error" outlined @click="dialog3=false">Thoát</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyBreadcrumb from '@/components/MyBreadcrumb'
import http from '@/components/services/http-common'

export default {
  components: {
    MyBreadcrumb
  },

  watch: {
    dialog2(newVal, oldVal) {
      console.log(oldVal, newVal)
      if(newVal== false){
        this.data2 = {}
      }
    },
    
    dialog3(newVal, oldVal) {
      console.log(oldVal, newVal)
      if(newVal== false){
        this.data3 = {}
      }
    }
  },

  data() {
    return {
      breadcrumb: [
          {text: "Trang chủ ", to: {name: 'Home'}},
          {text: "Cài đặt phép định kì ", to: {name: 'LeaveSchedule'}}
      ],

      positionStates: [],
      leavePlans: [],
      employees: [],

      items1: [],
      headers1: [
        {text: "Mã hợp đồng", value: "contractTypeCode"},
        {text: "Mã phép", value: "leavePlanCode"},
        {text: "Tên phép", value: "leavePlanName"},
        {text: "Trạng thái", value: "isActive"},
      ],
      
      dialog2: false,
      data2: {},
      items2: [],
      headers2: [
        {text: "Chức danh", value: "positionStateName"},
        {text: "Mã phép", value: "leavePlanCode"},
        {text: "Tên phép", value: "leavePlanName"},
        {text: "Trạng thái", value: "isActive"},
      ],
      
      dialog3: false,
      data3: {},
      items3: [],
      headers3: [
        {text: "Mã NS", value: "employeeCode"},
        {text: "Tên nhân viên", value: "employeeName"},
        {text: "Mã phép", value: "leavePlanCode"},
        {text: "Tên phép", value: "leavePlanName"},
        {text: "Trạng thái", value: "isActive"},
      ]
    }
  },
  methods: {
    
    fetchEmployees() {
      return http.get('employees/simple').then(res => {
        this.employees = res.data.map(x=>{
          return {
            code: x.code,
            name: x.code+':'+x.name
          }
        })
      })
    },

    fetchPositionState() {
      return http.get('positionStates').then(res => {
        this.positionStates = res.data
      })
    },

    fetchLeavePlan() {
      return http.get('leavePlans?dropdown=1').then(res => {
        console.log(res)
        this.leavePlans = res.data.filter(x=> x.isActive == 1 && (x.type == "PT" || x.type == "PN"))
      })
    },
    getData1() {
      return http.get("benefits/type/CONTRACT").then(r => {
        this.items1 = r.data.data
      })
    },
    getData2() {
      return http.get("benefits/type/DEFAULT").then(r => {
        this.items2 = r.data.data
      })
    },
    getData3() {
      return http.get("benefits/type/EMPLOYEE").then(r => {
        this.items3 = r.data.data
      })
    },

    saveData2() {
      return http.post("benefits/type/DEFAULT", this.data2)
    },

    saveData3() {
      return http.post("benefits/type/EMPLOYEE", this.data3)
    }
  },
  created() {
    this.fetchPositionState()
    this.fetchLeavePlan()
    this.fetchEmployees()
    this.getData1()
    this.getData2()
    this.getData3()
  }
  
}
</script>