<template>
    <div class="holiday">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb> -->
        <create-holiday v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-holiday>
        <v-card>
            <v-card-title>
                Danh sách ngày lễ 
                <v-spacer></v-spacer>
                <create-button @click="dialog.state=true"></create-button>
                <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
                </data-filter>
            </v-card-title>

            <v-text-field outlined v-model="search" hide-details label="Tìm kiếm" class="ml-auto mr-3" dense style="width:300px"></v-text-field>
            <v-data-table
            height="700px" :search="search"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            
            :footer-props="{
                itemsPerPage: '',
            }"
            >
                <template v-slot:[`item.date`]="{ item }">
                    {{ formatDate(item.date) }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <!-- ********* Edit ****** -->
                    <v-icon
                        small
                        class="mx-1"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>

                    <!-- ********* Xoa ****** -->
                    <v-icon small color="error" class="mx-1" @click="deleteItem(item)" >
                        mdi-delete
                    </v-icon>
                    <v-btn x-small @click="openShiftDialog(item)" color="teal" dark class="mx-1">
                        <v-icon small>mdi-cog</v-icon> cài đặt
                    </v-btn>
                </template>
                <template v-slot:[`item.schedule`]="{ item }">
                    <v-btn x-small dark @click="schedule(item)" color="primary"><v-icon small>mdi-calendar-clock</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShift" max-width="800px" scrollable>
            <v-card>
                <v-card-title class="headline">Áp dụng cho ca làm việc</v-card-title>
                <v-card-text>
                    <v-row class="my-0">
                        <v-col cols="12">
                            <v-select :items="departmentTypes" v-model="shiftDepartmentType" item-value="code" item-text="name" label="Khối phòng ban"
                            @change="fetchSetup" outlined hide-details clearable></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table v-model="shiftSelects"
                                            :headers="shiftHeaders"
                                            :items="shiftDatas"
                                            :items-per-page="20"
                                            :single-select="false"
                                            item-key="code"
                                            item="code"
                                            show-select max-width="400"
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="teal darken-1" text @click="setShift">Lưu</v-btn>
                    <!-- <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn> -->
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import DatePicker from "@/components/DatePicker";
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter';
import EventBus  from "@/components/services/EventBus";
// import myBreadCrumb from '@/components/BreadCrumb';
import * as moment from "moment/moment";
import CreateHoliday from './Create'
export default {
    name: "Holiday",
    components: {
        // myBreadCrumb,
        'data-filter': DataFilter,
        "create-holiday": CreateHoliday
    },
    data () {
        return {
            ROOT_URL: "holidays",
            search: "",
            //filter
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],
            },

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:[],
            },

            //TABLE DATA
            headers: [
                {text:"", value: "actions", width: 200},
                // {text: "Đặt lịch", value: "schedule",width: 100},
                {text: "Mã", value: "code", width: 150},
                {text: "Tên ngày lễ", value: "name", width: 250},
                {text: "Ngày nghỉ", value: "date"},
            ],

            items: [],
            options: {},
            totalItem: 0,
            isLoading: true,


            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            dialogShift: false,
            targetItem: {},

            //shift panel
            shiftDatas:[],
            shiftSelects:[],
            shiftHeaders: [
                {text:  "", value: "actions"},
                {text: "Mã", value: "code"},
                {text: "Tên", value: "name"},
                {text: "Ca hành chánh", value: "isOffice"},
                {text: "Loại", value: "workType"},
                // {text: "Hoạt động", value: "isActive"},
            ],

            shiftDepartmentType: "",
            departmentTypeSelects:[],
            departmentTypes: [],
            departmentTypeHeaders: [
                {text:  "", value: "actions"},
                {text: "Mã", value: "code"},
                {text: "Tên", value: "name"},

            ],

            //DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            // //DIALOG
            // dialog: {
            //     state: false,
            //     item: undefined,
            // },
            // dialogDelete: false,
            // targetItem: {},
        }
    },
    watch: {
      dialogShift: {
        handler (val) {
            if(val) {
                this.dialogShiftHandler();
            }else{
                this.shiftSelects=[];
                this.targetItem = null;
                this.shiftDepartmentType=null;
            }
        },
        deep: true,
      }
    },
    methods : {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        fetchData () {
            http.get(this.ROOT_URL).then(res => {
                this.isLoading = false;
                this.items = res.data.items;
            }).catch(err => {
                console.log(err);
            });
        },
        manualApply(item)
        {
            return http.get(this.ROOT_URL+"/apply/"+item.code).then(res=>{

            })
        },
        schedule (item){
            return http.get("Holidays/schedule/"+ item.code).then(res => {

            })
        },
        openShiftDialog(item) {
            this.dialogShift=true;
            this.targetItem = item;
        },

        dialogShiftHandler() {
            return http.get("shifts").then(res => {
                this.shiftDatas = res.data;
            }).then(() => {
                return http.get("departmentTypes/fetch").then(res => {
                    this.departmentTypes = res.data;
                })
            })
        },
        fetchSetup() {
            let id = this.targetItem.code;
            return http.get("holidays/get-setup/"+id, {
                params: {
                    departmentTypeCode: this.shiftDepartmentType
                }
            }).then(r => {
                this.shiftSelects = this.shiftDatas.filter(x=> r.data.shifts.indexOf(x.code)!=-1)
            })
        },

        setShift() {
            let id = this.targetItem.code;
            return http.post("holidays/setup/"+id, {
                shiftCodes: this.shiftSelects.map(x=>x.code),
                departmentTypeCode: this.shiftDepartmentType
            }).then(() => {
                this.dialogShift=false;
            })
        },

        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    created () {
        this.fetchData();
    }
}
</script>