import http from "./http-common";

export const BANK_GETS = 'banks';
export const MONTHLY_EMPLOYEE_GETS = "monthlyemployees";
export const CURRENT_EMPLOYEE = "CurrentEmployees";
export const ETHNICITY_GETS = "ethnicities";
export const ORIGIN_GETS = "origins";
export const RELIGION_GETS = "religions";
export const CITY_GETS = "cities/fetch";

// global api
class ApiServices {
    fetchDepartment(value) {
        return http.get("departments/fetch", {
            params: {
                search: value
            }
        })
    }
    getDepartment(departmentTypeCode = "") {
        return http.get("departments", {
            params: {
                departmentTypeCode: departmentTypeCode
            }
        })
    }
    getDepartmentType() {
        return http.get("departmentTypes")
    }
    
    getAsm() {
        return http.get("employees/fetch/v2?loadBy=ASM_ONLY")
    }
    getEmployee() {
        return http.get("employees/fetch/v2")
    }
    fetchPosition(value="") {
        return http.get("positions/fetch", {
            params: {
                search: value
            }
        })
    }

    getPosition(){
        return http.get("positions/fetch").then(r =>r.data)
    }
    
    getPositionState(id){
        return http.get("positions/fetch-position-state/"+id).then(r =>r.data)
    }
    
    fetchProperty() {
        return http.get("properties")
    }
    
    fetchEmployeeInDepartment(value) {
        const url = `departments/${value}/fetch-employee`
        return http.get(url, {
            params: {
                search: value
            }
        })
    }
    fetchEmployee(value){
        return http.get("employees/search", {
            params: {
                search: value
            }
        });
    }

    fetchGenericEmployee() {
        return http.get("employees/fetch/v2", {
            params: {
                onlineOnly: false
            }
        })
    }

    fetchEmployeeSimple(dateString){
        if(dateString){
            return http.get("employees/simple", {
                params: {
                    date: dateString
                }
            });
        }else
            return http.get("employees/simple");
    }
    fetchLeavePlan(){
        return http.get("leaveplans");
    }
    
    fetchEducationLevel(){
        return http.get('educationLevels/fetch')
    }
    fetchUniversity(){
        return http.get('universities/fetch')
    }
    fetchEducationMajor(){
        return http.get('educationMajors/fetch')
    }
    fetchBank(){
        return http.get(BANK_GETS)
    }
    
    fetchEthnicity(){
        return http.get(ETHNICITY_GETS)
    }
    fetchOrigin(){
        return http.get(ORIGIN_GETS)
    }
    fetchReligion(){
        return http.get(RELIGION_GETS)
    }

    fetchCity(){
        return http.get(CITY_GETS)
    }

    fetchDistrictFromCity(id) {
        if(id)
            return http.get(`cities/fetch-district/${id}`)
    }

    fetchWardFromDistrict(id) {
        if(id)
            return http.get(`districts/fetch-ward/${id}`)
    }

    fetchMonthlyEmployee(month) {
        return http.get(MONTHLY_EMPLOYEE_GETS, {
            params: {
                month:month
            }
        });
    }
    
    fetchCurrentEmployee(filter) {
        return http.get(CURRENT_EMPLOYEE, {
            params: filter
        });
    }
    
    getExport(baseUrl, filterForm, fileName) {
        let url = `${baseUrl}/export`
        return http.get(url, {
            params: filterForm,
            responseType: 'arraybuffer',
        }).then((res)  => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName??'file'}.xlsx` ); //or any other extension
            document.body.appendChild(link);
            link.click();
            return 
        }).catch((err)  => {
            console.log(err);
            return
        });
    }
}

export default new ApiServices();