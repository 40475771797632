<template>
  <v-dialog v-model="dialog" max-width="850" scrollable eager persistent>
    <v-card class="rounded-xl" :loading="loading">
      <v-card-title class="title">
        Chi tiết
        <v-spacer></v-spacer>
        <v-icon color="red" @click="dialog=false, itemCode = null">mdi-close</v-icon>
      </v-card-title>
      <!-- <v-card-subtitle class="red--text">
          Nhân viên kiểm tra, xác nhận và bổ sung các thông tin cần thiết.
      </v-card-subtitle> -->
      <v-card-text class="text--primary">
        <v-form v-model="isValid" @submit.prevent="submitForm" ref="temporary_store_form" id="temporary_store_form">
          <v-row  class="my-0">
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.code" label="Mã" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.alias" label="Alias" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.name" label="Tên" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.phone" label="Sdt" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.launchDate" label="Ngày khai trương" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete outlined hide-details="auto" :items="departmentTypes" item-value="code" item-text="name"
                v-model="form.departmentTypeCode" label="Phân loại" dense persistent-placeholder></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined hide-details="auto" v-model="form.description" label="Mô tả" dense persistent-placeholder></v-text-field>
            </v-col>
          </v-row>

            <v-divider></v-divider>
          <v-row class="my-0">
            <v-col cols="12" md="6">
              <v-autocomplete outlined hide-details="auto" :items="employees" item-text="name" item-value="code" v-model="form.managerCode" label="Quản lý" dense persistent-placeholder></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete outlined hide-details="auto" :items="asms" item-text="name" item-value="code" v-model="form.asmCode" label="Asm" dense persistent-placeholder></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.areaCode" label="Vùng" dense persistent-placeholder></v-text-field>
            </v-col>
          </v-row>
            <v-divider></v-divider>

          <v-row class="my-0">
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.cityCode" label="Thành phố" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.address" label="Địa chỉ" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined hide-details="auto" v-model="form.note" label="Ghi chú" dense persistent-placeholder></v-text-field>
            </v-col>
            
            <v-col cols="12" md="6">
              <v-checkbox outlined hide-details="auto" v-model="form.isOffice" label="Thuộc văn phòng" dense persistent-placeholder></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="dialog=false, itemCode = null">Thoát</v-btn>
          <v-btn color="primary" type="submit" dark form="temporary_store_form">
              Lưu
          </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiServices from '@/components/services/ApiServices.js'
import DepartmentApi from './api.js'
export default {
  mounted() {
    // this.fetchDistrict()
    // this.fetchWard()
    ApiServices.getDepartmentType().then(r => {
      this.departmentTypes = r.data.items
    })
    ApiServices.getEmployee().then(r => {
      this.employees = r.data.data
    })
    this.fetchAsm()

    //********Global Events Listener */
    this.$root.$on('openDialog', () => {
      this.dialog = true
    });
    
    this.$root.$on('editDialog', (data) => {
      this.dialog = true
      this.itemCode = data.code
      this.fetchData()
    });
    

  },
  destroyed() {
    
    // this.dialog = false
    //********OFF Global Events Listener */
    this.$root.$off('openDialog');
    
    this.$root.$off('editDialog');
  },
  computed: {
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội", "Hàn Quốc"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    }
  },
  data() {
    return {
      REQUIRE_RELIGION: ['CG', 'TCG', 'TL'],
      REQUIRE_MARITAL: ['KH'],
      dialog: false,
      idFrontImageFile: null,
      idBackImageFile: null,
      bankImageFile: null,
      maritalStatusImageFile: null,
      religionImageFile: null,
      employee: null,
      isValid: null,
      isValid2: null,
      showTabValid: false,
      noTaxNumber: false,
      noInsuranceNumber: false,
      loading: false,
      addFamilyDialog:false,
      

      itemCode: null,
      initForm: false,
      
      form: {
        code: '',
        alias: '',
        name: '',
        phone: '',
        launchDate: '1900-01-01',
        description: '',
        departmentTypeCode: '',
        managerCode: '',
        asmCode: '',
        areaCode: '',
        cityCode: '',
        address: '',
        note: '',
        isOffice: false,
      },
      
      defaultForm: {
        code: '',
        alias: '',
        name: '',
        phone: '',
        launchDate: '1900-01-01',
        description: '',
        departmentTypeCode: '',
        managerCode: '',
        asmCode: '',
        areaCode: '',
        cityCode: '',
        address: '',
        note: '',
        isOffice: false,
      },
      departments: [],
      departmentTypes: [],
      asms:[],
      employees: [],

      tab: null
    }
  },
  watch: {
    
    "dialog": {
      handler(val) {
        if(val) {
          console.log(val)
          this.fetchDepartment()
        } else {
          //close dialog
          this.close()
        }
      }
    },
  },
  methods: {
    close() {
      Object.assign(this.form, this.defaultForm)
      this.noInsuranceNumber = false
      this.noTaxNumber = false
      this.itemCode = null
    },
    submitForm(){
      this.$refs.temporary_store_form.validate()
      if(this.isValid) {
        this.loading = true
        if(this.itemCode) {
          return DepartmentApi.put(this.itemCode, this.form).finally(() => {
            this.loading = false
          })
        }else {
          return DepartmentApi.post(this.form).finally(() => {
            this.loading = false
          })
        }
      }
    },

    fetchData() {
      this.initForm = true
      return DepartmentApi.get(this.itemCode).then(d => {
        Object.assign(this.form, d)
        this.form.validFrom = this.$formatter.formatDate(this.form.validFrom, 'YYYY-MM-DD')
        this.form.validTo = this.$formatter.formatDate(this.form.validTo, 'YYYY-MM-DD')
      }).finally(() => {
        
      })
    },

    
    fetchDepartment() {
      return ApiServices.getDepartment('K00002').then(r => {
          this.departments = r.data
      })
    },

    fetchAsm() {
      return ApiServices.getAsm('K00002').then(r => {
          this.asms = r.data.data
      })
    },
    
    fetchEmployees() {
      return ApiServices.getAsm('K00002').then(r => {
          this.asms = r.data.data
      })
    }
  },
}
</script>