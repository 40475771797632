<template>
  <div>
    <v-dialog v-model="value" width="400px" @input="updateValue" >
      <v-card>
        <v-card-title>
          Bạn có muốn tiếp tục ? {{item}}
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" outlined @click="$emit('input', false)">Thoát</v-btn>
          <v-btn color="teal darken-4" dark @click="$emit('confirm', item)">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['value', 'item'],
  data() {
    return {
      inputValue: this.value,
    }
  },
  watch: {
    value(val) {
      this.$emit('input', val)
      // this.updateValue()
    }
  },
  methods: {
    updateValue(val) {
      // console.log(val, 'xxxxxxxx')
      this.$emit('input', val);
    },
  }
}
</script>

