<template>
  <v-card-title>
      <v-row justify="start" style="width: 100%;">
          <v-col cols="12" md="2">
              <v-text-field @input="emitFilterEmployeeCode" hide-details outlined dense :value="filterEmployeeCode" label="Mã số nhân viên" persistent-placeholder></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
              <v-text-field @input="emitFilterName" hide-details outlined dense :value="filterName" label="Tên nhân viên" persistent-placeholder></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
              <v-text-field @input="emitFilterIdNumber" hide-details outlined dense :value="filterIdNumber" label="CMND/CCCD" persistent-placeholder></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
              <!-- chinhanh -->
              <v-text-field @input="emitFilterDepartment" hide-details outlined dense :value="filterDepartment" label="Phòng ban" persistent-placeholder></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
              <!-- chinhanh -->
              <v-text-field @input="emitFilterPositionState" hide-details outlined dense :value="filterPositionState" label="Chức danh" persistent-placeholder></v-text-field>
          </v-col>
      </v-row>
  </v-card-title>
</template>

<script>
export default {
  name: 'FilterSection',
  props:{
    filterEmployeeCode:{
      type: String,
      default: "",
    },
    filterName:{
      type: String,
      default: "",
    },
    filterIdNumber:{
      type: String,
      default: "",
    },
    filterDepartment:{
      type: String,
      default: "",
    },
    filterPositionState:{
      type: String,
      default: "",
    },
  },
  methods:{
    emitFilter(){
      this.$emit("filterData")
    },
    emitFilterEmployeeCode(value) {
      this.$emit('update:filterEmployeeCode', value)
    },

    emitFilterName(value) {
      this.$emit('update:filterName', value)
    },
    
    emitFilterIdNumber(value) {
      this.$emit('update:filterIdNumber', value)
    },

    emitFilterDepartment(value) {
      this.$emit('update:filterDepartment', value)
    },
    
    emitFilterPositionState(value) {
      this.$emit('update:filterPositionState', value)
    },
  }
}
</script>