<template>
    <div class="clock">
        {{currentTime}}
    </div>
</template>

<script>
export default {
    name:"Clock",
    components : {

    },
    watch: {

    },
    computed: {

    },
    data() {
        return {
            state: true,
            currentTime: "",
            interval: null,
        }
    },
    methods: {
        updateCurrentTime() {
            if (this.state == true) {
                var today = new Date();
                const zeroPad = (num, places) => String(num).padStart(places, '0')
                var h = zeroPad(today.getHours(), 2);
                var m = zeroPad(today.getMinutes(), 2);
                var s = zeroPad(today.getSeconds(), 2);
                this.currentTime = h + ":" + m + ":" + s;
            }
        },
    },
    mounted() {
        this.updateCurrentTime();
        this.interval = setInterval(this.updateCurrentTime, 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    }
}
</script>