<template>
  <v-dialog v-model="dialog" max-width="850" scrollable eager persistent>
    <v-card class="rounded-xl" :loading="loading">
      <v-card-title class="title">
        Thông tin nhân viên
      </v-card-title>
      <!-- <v-card-subtitle class="red--text">
          Nhân viên kiểm tra, xác nhận và bổ sung các thông tin cần thiết.
      </v-card-subtitle> -->
      <v-tabs v-model="tab" centered background-color="primary" dark slider-color="orange" >
          <!-- <v-tab style="background-color: #00334D" class="rounded-tl-xl">Xác nhận thông tin</v-tab> -->
          <v-tab >CÁ NHÂN <v-icon small color="white" dark v-show="isValid==false && showTabValid==true">mdi-alert-circle</v-icon></v-tab>
          <v-tab >NGƯỜI THÂN</v-tab>
      </v-tabs>
      <v-card-text class="text--primary">
          <v-tabs-items v-model="tab">
              <v-tab-item eager>
                  <v-form v-model="isValid" @submit.prevent="submitForm" ref="update_info_form" id="update_info_form">
                    <v-subheader class="text-subtitle-1 font-weight-bold">THÔNG TIN CƠ BẢN</v-subheader>
                    <v-card outlined rounded="xl">
                      <v-card-text class="">
                        <v-row class="mb-5 px-2">
                          <v-col cols="12" md="6">
                              <label>Mã NS:</label>
                              <span class="font-weight-bold d-block ml-2 text-primary">{{form.employeeCode}}</span>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>Họ & tên:</label>
                              <v-text-field v-model="form.employeeName" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                              <label>Giới tính:</label>
                              <v-select :items="[
                                {text: 'Nam', value: true},
                                {text: 'Nữ', value: false},
                              ]" v-model="form.gender" dense outlined hide-details :rules="[v => v!=null|| 'Hãy nhập dữ liệu này']"></v-select>
                          </v-col>
                          <v-col cols="12" md="3">
                              <label>Ngày sinh:</label>
                              <v-text-field type="date" v-model="form.birthday" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>SĐT:</label>
                              <v-text-field v-model="form.phoneNumber" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                          </v-col>
                          
                        </v-row>

                        <h5 class="text-subtitle-2">CĂN CƯỚC CÔNG DÂN</h5>
                        <v-divider ></v-divider>
                        <v-row class="mt-0 mb-5 px-2">
                          <v-col cols="12" md="6">
                              <v-img contain class="rounded-lg mx-auto mb-2" :src="!form.idFrontImageBase64.base64 ? '/static/placeholder/image_holder.png' :'data:image/jpeg;charset=utf-8;base64,'+form.idFrontImageBase64.base64" v-if="!form.idFrontImageUrl" max-height="128" aspect-ratio="1.6"></v-img>
                              <v-img contain class="rounded-lg mx-auto mb-2" v-else :src="form.idFrontImageUrl"  aspect-ratio="1.6"></v-img>
                              <label for="" style="width: 100%">Hình ảnh CCCD mặt trước</label>
                              <v-file-input v-show="!itemCode" prepend-icon="" v-model="idFrontImageFile" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-file-input>
                          
                          </v-col>
                          <v-col cols="12" md="6">
                              <v-img contain class="rounded-lg mx-auto mb-2" :src="!form.idBackImageBase64.base64 ? '/static/placeholder/image_holder.png' :'data:image/jpeg;charset=utf-8;base64,'+form.idBackImageBase64.base64" v-if="!form.idBackImageUrl" max-height="128" aspect-ratio="1.6"></v-img>
                              <v-img contain class="rounded-lg mx-auto mb-2" v-else :src="form.idBackImageUrl"  aspect-ratio="1.6"></v-img>
                              <label for="" style="width: 100%">Hình ảnh CCCD mặt sau</label>
                              <v-file-input v-show="!itemCode" prepend-icon="" v-model="idBackImageFile" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-file-input>
                          
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>CCCD:</label>
                              <v-text-field v-model="form.idNumber" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>Ngày cấp:</label>
                              <v-text-field type="date" v-model="form.idDate" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>Nơi cấp:</label>
                              <v-autocomplete v-model="form.idPlace" :items="idPlaces" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                          </v-col>
                        </v-row>
                        
                        <h5 class="text-subtitle-2">ĐỊA CHỈ THƯỜNG TRÚ</h5>
                        <v-divider></v-divider>
                        <v-row class="mt-0 mb-5 px-2">
                          <v-col cols="12" md="6">
                              <label>Thành phố:</label>
                              <v-autocomplete v-model="form.city1Code" :items="cities" item-text="name" item-value="code" @change="fetchDistrict"
                               dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                          </v-col>
                          
                          <v-col cols="12" md="6">
                              <label>Quận:</label>
                              <v-autocomplete v-model="form.district1Code" :items="districts" item-text="name" item-value="code" @change="fetchWard"
                               dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                          </v-col>
                          
                          <v-col cols="12" md="6">
                              <label>Phường:</label>
                              <v-autocomplete v-model="form.ward1Code" :items="wards" item-text="name" item-value="code"
                               dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                          </v-col>
                          
                          <v-col cols="12" md="6">
                              <label>Địa chỉ:</label>
                              <v-text-field v-model="form.address1" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                          </v-col>
                        </v-row>

                        <h5 class="text-subtitle-2">HỌC VẤN</h5>
                        <v-divider></v-divider>
                        <v-row class="mt-0 px-2">
                          <v-col cols="12" md="6">
                              <label>Trình độ:</label>
                              <v-autocomplete v-model="form.educationLevelCode" :items="educationLevels" item-text="name" item-value="code" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>Chuyên ngành:</label>
                              <v-autocomplete v-model="form.educationMajorCode" :items="educationMajors" item-text="name" item-value="code" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>Cơ sở đào tạo:</label>
                              <v-autocomplete v-model="form.universityCode" :items="universities" item-text="name" item-value="code" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>Tốt nghiệp:</label>
                              <v-select v-model="form.graduateStatus" :items="[
                                { text: 'Đã tốt nghiệp', value: 1},
                                { text: 'Chưa tốt nghiệp', value: 0},
                              ]" dense outlined hide-details="auto" :rules="[v => v!=null || 'Hãy nhập dữ liệu này']"></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                              <label>Chứng chỉ dược:</label>
                              <v-select v-model="form.hasPharmaCert" :items="[
                                { text: 'Đại học', value: 'DH'},
                                { text: 'Cao đẳng', value: 'CD'},
                                { text: 'Không', value: 'KHONG'},
                              ]" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-select>
                          </v-col>
                          <!-- <v-col cols="12">
                              <v-checkbox hide-details v-model="form.basicInfoConfirmed" label="Xác nhận thông tin đúng"></v-checkbox>
                          </v-col>
                          <v-col cols="12" v-show="!form.basicInfoConfirmed">
                              <label for="">Ghi chú thông tin cần cập nhật</label>
                              <v-textarea outlined ></v-textarea>
                          </v-col> -->
                          
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-subheader class="text-subtitle-1 font-weight-bold">THÔNG TIN CHI TIẾT</v-subheader>
                    <v-card outlined rounded="xl">
                      <v-card-text>
                        <v-row class="mt-0 mb-5 px-2">
                            <v-col cols="12" md="6">
                                <label for="">SĐT Khẩn cấp</label>
                                <v-text-field v-model="form.emergencyPhone" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <label for="">Tình trạng hôn nhân</label>
                                <v-select v-model="form.maritalStatus"
                                :items="marriages"
                                dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-select>
                            </v-col>
                            
                            <v-col cols="12" md="3">
                                <label for="">Quốc tịch</label>
                                <v-select v-model="form.originCode" :items="origins" item-value="code" item-text="name"
                                dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Tôn giáo</label>
                                <v-autocomplete v-model="form.religionCode" :items="religions" item-text="name" item-value="code"
                                dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Dân tộc</label>
                                <v-autocomplete v-model="form.ethnicityCode" :items="ethnicities" item-text="name" item-value="code"
                                dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" md="6" v-show="REQUIRE_RELIGION.includes(form.religionCode)">
                                <v-img contain class="rounded-lg mx-auto mb-2" src="/static/placeholder/image_holder.png" v-if="!form.religionImageUrl" max-width="128px"></v-img>
                                <v-img contain class="rounded-lg" v-else :src="form.religionImageUrl"  aspect-ratio="1.6"></v-img>
                                <label for="">Hình ảnh tôn giáo</label>
                                <v-file-input v-show="!itemCode" prepend-icon="" v-model="religionImageFile" dense outlined hide-details="auto" ></v-file-input>
                            </v-col>
                            <v-col cols="12" md="6" v-show="REQUIRE_MARITAL.includes(form.maritalStatus)">
                                <v-img contain class="rounded-lg mx-auto mb-2" src="/static/placeholder/image_holder.png" v-if="!form.maritalImageUrl" max-width="128px"></v-img>
                                <v-img contain class="rounded-lg" v-else :src="form.maritalImageUrl"  aspect-ratio="1.6"></v-img>
                                <label for="">Hình ảnh tình trạng hôn nhân</label>
                                <v-file-input v-show="!itemCode" prepend-icon="" v-model="maritalStatusImageFile" dense outlined hide-details="auto" ></v-file-input>
                            </v-col>
                          </v-row>
                            <h5 class="text-subtitle-2">ĐỊA CHỈ TẠM TRÚ</h5>
                            <v-divider></v-divider>
                          <v-row class="mt-0 mb-5 px-2">
                            <v-col cols="12" md="6">
                                <label for="">Thành phố</label>
                                <v-autocomplete v-model="form.city2Code" :items="cities2" item-text="name" item-value="code" @change="fetchDistrict2"
                                dense outlined hide-details ="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Quận/huyện</label>
                                <v-autocomplete v-model="form.district2Code" :items="districts2" item-text="name" item-value="code" @input="fetchWard2"
                                dense outlined hide-details ="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Phường/xã</label>
                                <v-autocomplete v-model="form.ward2Code" :items="wards2" item-text="name" item-value="code"
                                dense outlined hide-details ="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Địa chỉ</label>
                                <v-text-field v-model="form.address2" dense outlined hide-details ="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                            </v-col>
                          </v-row>

                          <h5 class="text-subtitle-2">TÀI KHOẢN NGÂN HÀNG</h5>
                          <v-divider></v-divider>
                          <v-row class="mt-0 px-2">
                            <v-col cols="12">
                                <v-img contain max-height="200px" class="rounded-lg mx-auto my-2" src="/static/placeholder/image_holder.png" v-if="!form.bankImageUrl"  max-width="128px"></v-img>
                                <v-img contain max-height="200px" class="rounded-lg" v-else :src="form.bankImageUrl"  aspect-ratio="1.6"></v-img>
                                <label for="">Hình ảnh tài khoản ngân hàng</label>
                                <v-file-input v-show="!itemCode" v-model="bankImageFile" dense outlined hide-details="auto" ></v-file-input>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Ngân hàng</label>
                                <v-autocomplete v-model="form.bankCode" :items="banks" item-text="name" item-value="code"
                                dense outlined hide-details ="auto" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Số tài khoản </label>
                                <v-text-field v-model="form.bankNumber" dense outlined hide-details="auto" ></v-text-field>
                            </v-col>
                            
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Mã số thuế </label>
                                <small class="red--text">
                                  <a href="https://canhan.gdt.gov.vn/ICanhan/Request?&dse_sessionId=32BsIb-n0FAxIZTJDtYQ8m3&dse_applicationId=-1&dse_pageId=117&dse_operationName=retailIndexProc&dse_errorPage=error_page.jsp&dse_processorState=initial&dse_nextEventName=start"
                                    class="red--text" target="_blank"
                                  >Tra cứu mã số thuế</a>
                                </small>
                                <v-text-field v-model="form.taxNumber" dense outlined hide-details="auto" v-show="!noTaxNumber"></v-text-field>
                                <v-checkbox label="Không có" v-model="noTaxNumber"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="">Mã BHXH </label>
                                <v-text-field v-model="form.insuranceNumber" dense outlined hide-details="auto" v-show="!noInsuranceNumber"></v-text-field>
                                <v-checkbox label="Không có" v-model="noInsuranceNumber"></v-checkbox>
                            </v-col>
                            
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
              </v-tab-item>
              <v-tab-item eager>
                <!-- <v-subheader class="text-subtitle-1 font-weight-bold" v-if="!form.updatingFamily">DỮ LIỆU HIỆN TẠI</v-subheader> -->
                <!-- <v-card outlined rounded="lg" v-if="!form.updatingFamily">
                  <v-data-table></v-data-table>
                </v-card> -->
                <v-subheader class="text-subtitle-1 font-weight-bold">CẬP NHẬT THÔNG TIN</v-subheader>
                <v-card class="rounded-lg" outlined>
                  <v-card-title class="text-subtitle-1">
                    <v-switch label="Điều chỉnh thông tin" v-model="form.updatingFamily" :true-value="1" :false-value="0"></v-switch>
                    <v-spacer></v-spacer>
                    <v-btn v-if="form.updatingFamily" @click="addFamilyDialog=true" small rounded dark color="teal darken-4" type="button"><v-icon left>mdi-plus</v-icon> Thêm</v-btn>
                  </v-card-title>
                  <v-divider class=""></v-divider>
                  <v-card-text class="pt-0 text--primary">
                    <div v-if="form.updatingFamily">
                      <v-data-table :items="form.familyMembers" :headers="familyMemberHeaders">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="error" icon dark @click="deleteFamily(item)"><v-icon>mdi-close-circle</v-icon></v-btn>
                        </template>
                        <template v-slot:[`item.doB`]="{ item }">
                            {{ $formatter.formatDate() }}
                        </template>
                      </v-data-table>
                      <v-dialog max-width="500" v-model="addFamilyDialog">
                        <v-card>
                          <v-card-title>
                            <v-spacer></v-spacer>
                            <v-icon color="error" @click="false">mdi-close</v-icon>
                          </v-card-title>
                          <v-card-text>
                            <v-form v-model="isValid2" @submit.prevent="addFamily" class="my-0" ref="update_info_form_family" id="update_info_form_family">
                                <v-row class="my-0">
                                    <v-col cols="6">
                                        <label for="">Mối quan hệ</label>
                                        <v-select  dense outlined
                                          :items="[
                                            {text: 'Cha', value:'CHA'},
                                            {text: 'Mẹ', value:'ME'},
                                            {text: 'Con', value:'CON'},
                                            {text: 'Chồng', value:'CHONG'},
                                            {text: 'Vợ', value:'VO'},
                                            {text: 'Ông ngoại', value:'ONGNG'},
                                            {text: 'Ông nội', value:'ONGNO'},
                                            {text: 'Bà ngoại', value:'BANG'},
                                            {text: 'Bà nội', value:'BANO'},
                                            {text: 'Anh', value:'ANH'},
                                            {text: 'Chị', value:'CHI'},
                                            {text: 'Em', value:'EM'},
                                          ]"
                                          v-model="family.relationType" hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <label for="">Họ & tên</label>
                                        <v-text-field v-model="family.memberName" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <label for="">Ngày sinh</label>
                                        <v-text-field type="date" v-model="family.doB" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <label for="">SĐT</label>
                                        <v-text-field v-model="family.phoneNumber" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <label for="">CCCD</label>
                                        <v-text-field v-model="family.idNumber" dense outlined hide-details="auto" :rules="[v => !!v || 'Hãy nhập dữ liệu này']"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                          </v-card-text>
                          <v-card-actions class="justify-center">
                            <v-btn small rounded dark color="teal darken-4" form="update_info_form_family" type="submit"> Lưu</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
          </v-tabs-items>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="dialog=false, itemCode = null">Thoát</v-btn>
          <v-btn color="primary" type="submit" dark form="update_info_form" v-show="!itemCode">
              Tạo phiếu
          </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiServices from '@/components/services/ApiServices.js'
import InfoUpdateApi from './api.js'
export default {
  mounted() {
    // this.fetchDistrict()
    // this.fetchWard()

    //********Global Events Listener */
    this.$root.$on('openDialog', () => {
      this.dialog = true
      this.getEmployee()
    });
    
    this.$root.$on('editDialog', (data) => {
      this.dialog = true
      this.itemCode = data.id
      this.fetchData()
    });
    

  },
  beforeDestroy() {
    // this.dialog = false
    //********OFF Global Events Listener */
    this.$root.$off('openDialog');
    
    this.$root.$off('editDialog');
  },
  computed: {
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội", "Hàn Quốc"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    }
  },
  data() {
    return {
      REQUIRE_RELIGION: ['CG', 'TCG', 'TL'],
      REQUIRE_MARITAL: ['KH'],
      dialog: false,
      idFrontImageFile: null,
      idBackImageFile: null,
      bankImageFile: null,
      maritalStatusImageFile: null,
      religionImageFile: null,
      employee: null,
      isValid: null,
      isValid2: null,
      showTabValid: false,
      noTaxNumber: false,
      noInsuranceNumber: false,
      loading: false,
      addFamilyDialog:false,
      

      itemCode: null,
      initForm: false,
      
      form: {
        updatingFamily: 0,
        // ****employee attribute*****name
        employeeCode:'',
        employeeName: '',
        phoneNumber: '',
        gender: '',
        birthday: '',
        idFrontImageUrl: '',
        idBackImageUrl: '',
        bankImageUrl: '',
        maritalImageUrl: '',
        religionImageUrl: '',
        idNumber: '',
        idDate: '',
        idPlace: '',
        city1Name: '',
        district1Name: '',
        ward1Name: '',

        city1Code: '',
        district1Code: '',
        ward1Code: '',
        address1: '',

        // ****base attribute*****
        originCode: '',
        religionCode: '',
        ethnicityCode: '',
        city2Code: '',
        district2Code: '',
        ward2Code: '',
        address2: '',

        educationLevelCode: '',
        educationMajorCode: '',
        universityCode: '',
        graduateStatus: null,

        emergencyPhone: '',
        maritalStatus: '',
        bankCode: '',
        bankNumber: '',
        status: '',
        basicInfoConfirmed: true,
        idFrontImage: null,
        idBackImage: null,
        bankImage: null,
        insuranceNumber:'',
        taxNumber:'',
        hasPharmaCert: 'KHONG',
        idFrontImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        idBackImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        bankImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        MaritalImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        religionImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        familyMembers:[]
          
      },
      
      defaultForm: {
        updatingFamily: 0,
        // ****employee attribute*****name
        employeeCode:'',
        employeeName: '',
        phoneNumber: '',
        gender: '',
        birthday: '',
        idFrontImageUrl: '',
        idBackImageUrl: '',
        bankImageUrl: '',
        maritalImageUrl: '',
        religionImageUrl: '',
        idNumber: '',
        idDate: '',
        idPlace: '',
        city1Name: '',
        district1Name: '',
        ward1Name: '',

        city1Code: '',
        district1Code: '',
        ward1Code: '',
        address1: '',

        // ****base attribute*****
        originCode: '',
        religionCode: '',
        ethnicityCode: '',
        city2Code: '',
        district2Code: '',
        ward2Code: '',
        address2: '',

        educationLevelCode: '',
        educationMajorCode: '',
        universityCode: '',
        graduateStatus: null,

        emergencyPhone: '',
        maritalStatus: '',
        bankCode: '',
        bankNumber: '',
        status: '',
        basicInfoConfirmed: true,
        idFrontImage: null,
        idBackImage: null,
        bankImage: null,
        insuranceNumber:'',
        taxNumber:'',
        hasPharmaCert: 'KHONG',
        idFrontImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        idBackImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        bankImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        MaritalImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        religionImageBase64: {
            base64:'',
            fileName: '',
            type: ''
        },
        familyMembers:[]
          
      },
      origins: [],
      religions: [],
      ethnicities: [],
      cities: [],
      cities2: [], 
      districts: [],
      districts2: [], 
      wards: [],
      wards2:[],
      banks:[],
      educationLevels: [],
      universities: [],
      educationMajors: [],
      marriages:[
          {text: "Kết hôn", value: "KH"},
          {text: "Độc thân", value: "DT"},
          {text: "Chưa có thông tin", value: ""},
      ],

      family:{
        relationType: null,
        memberName: '',
        phoneNumber: '',
        doB: '',
        idNumber: '',
      },
      
      familyDefault:{
        relationType: null,
        memberName: '',
        phoneNumber: '',
        doB: '',
        idNumber: '',
      },
      headers: [
        {text: '', value: 'actions'},
        {text: 'Mối quan hệ', value: 'relationType'},
        {text: 'Họ & tên', value: 'memberName'},
        {text: 'SDT', value: 'phoneNumber'},
        {text: 'Ngày sinh', value: 'doB'},
        {text: 'CCCD', value: 'idNumber'}
      ],
      familyMemberHeaders: [
        {text: '', value: 'actions'},
        {text: 'Mối quan hệ', value: 'relationType'},
        {text: 'Tên', value: 'memberName'},
        {text: 'CCCD', value: 'idNumber'},
        {text: 'SDT', value: 'phoneNumber'},
      ],
      tab: null
    }
  },
  watch: {
    noTaxNumber: {
      handler(val) {
        if(!val) {
          this.form.taxNumber=''
        }else {
          this.form.taxNumber='NO_NUMBER'
        }
      }
    },
    noInsuranceNumber: {
      handler(val) {
        if(!val) {
          this.form.insuranceNumber=''
        }else {
          this.form.insuranceNumber='NO_NUMBER'
        }
      }
    },

    idFrontImageFile: {
      handler(val) {
          this.$formatter.fileToBase64(val).then(r => {
              this.form.idFrontImageBase64.base64 = r.base64
              this.form.idFrontImageBase64.fileName = r.fileName
              this.form.idFrontImageBase64.type = r.type
              return r
          })
      }
    },
    
    idBackImageFile: {
      handler(val) {
          this.$formatter.fileToBase64(val).then(r => {
              this.form.idBackImageBase64.base64 = r.base64
              this.form.idBackImageBase64.fileName = r.fileName
              this.form.idBackImageBase64.type = r.type
              return r
          })
      }
    },
    bankImageFile: {
      handler(val) {
          this.$formatter.fileToBase64(val).then(r => {
              this.form.bankImageBase64.base64 = r.base64
              this.form.bankImageBase64.fileName = r.fileName
              this.form.bankImageBase64.type = r.type
              return r
          })
      }
    },
    
    maritalStatusImageFile: {
      handler(val) {
          this.$formatter.fileToBase64(val).then(r => {
            this.form.MaritalImageBase64.base64 = r.base64
            this.form.MaritalImageBase64.fileName = r.fileName
            this.form.MaritalImageBase64.type = r.type
            return r
          })
      }
    },
    religionImageFile: {
      handler(val) {
          this.$formatter.fileToBase64(val).then(r => {
            this.form.religionImageBase64.base64 = r.base64
            this.form.religionImageBase64.fileName = r.fileName
            this.form.religionImageBase64.type = r.type
            return r
          })
      }
    },
    // "form.city1Code": {
    //   handler(val) {
    //     if(!this.initForm) {
    //       this.form.district1Code = null
    //       this.form.ward1Code = null
    //     }
    //     this.fetchDistrict(val)
    //   }
    // },
    
    // "form.district2Code": {
    //   handler(val) {
    //     if(!this.initForm) {
    //       this.form.ward2Code = null
    //     }
    //     this.fetchWard(val)
    //   }
    // },
    
    "dialog": {
      handler(val) {
        if(val) {
          this.tab = null
          this.fetchCity()
          this.fetchOrigin()
          this.fetchBank()
          //fetch edulvl, major,  uni
          
          ApiServices.fetchEducationLevel().then(r => {
            this.educationLevels = r.data
          })
          ApiServices.fetchUniversity().then(r => {
            this.universities = r.data
          })
          ApiServices.fetchEducationMajor().then(r => {
            this.educationMajors = r.data
          })
          ApiServices.fetchReligion().then(r => {
              this.religions = r.data
          })
          ApiServices.fetchEthnicity().then(r => {
              this.ethnicities = r.data
          })
        } else {
          //close dialog
          this.close()
        }
      }
    },
  },
  methods: {
    close() {
      Object.assign(this.form, this.defaultForm)
      this.noInsuranceNumber = false
      this.noTaxNumber = false
      this.itemCode = null
    },
    submitForm(){
      this.showTabValid = true
      this.$refs.update_info_form.validate()
      if(this.form.religionCode == 'TL' || this.form.religionCode == 'TCG' || this.form.religionCode == 'CG') {
        if(!this.religionImageFile) {
          alert("Hãy chụp giấy tờ tín ngưỡng bổ sung")
          return 0
        }
      }
      if(this.form.maritalStatus == 'KH') {
        if(!this.maritalStatusImageFile) {
          alert("Hãy chụp giấy chứng nhận kết hôn")
          return 0
        }
      }
      
      if(this.form.bankNumber) {
        if(!this.bankImageFile) {
          alert("Hãy chụp ảnh chứng mình ngân hàng")
          return 0
        }
      }
      if(this.isValid) {
        this.loading = true
        return InfoUpdateApi.post(this.form).finally(() => {
          this.loading = false
        })
      }
    },

    fetchData() {
      this.initForm = true
      return InfoUpdateApi.get(this.itemCode).then(d => {
        
        this.form.employeeCode = d.infoUpdateRequest.employeeCode
        this.form.employeeName = d.infoUpdateRequest.employeeName
        this.form.phoneNumber = d.infoUpdateRequest.phoneNumber
        this.form.maritalStatus = d.infoUpdateRequest.maritalStatus
        this.form.birthday = this.$formatter.formatDate(d.infoUpdateRequest.birthday, 'YYYY-MM-DD')
        this.form.idNumber = d.infoUpdateRequest.idNumber
        this.form.idDate = this.$formatter.formatDate(d.infoUpdateRequest.idDate, 'YYYY-MM-DD')
        this.form.idPlace = d.infoUpdateRequest.idPlace
        this.form.gender = d.infoUpdateRequest.gender
        this.form.originCode = d.infoUpdateRequest.originCode
        this.form.religionCode = d.infoUpdateRequest.religionCode
        this.form.ethnicityCode = d.infoUpdateRequest.ethnicityCode
        this.form.educationLevelCode = d.infoUpdateRequest.educationLevelCode
        this.form.educationMajorCode = d.infoUpdateRequest.educationMajorCode
        this.form.universityCode = d.infoUpdateRequest.universityCode
        this.form.graduateStatus = d.infoUpdateRequest.graduateStatus
        this.form.updatingFamily = d.infoUpdateRequest.updatingFamily
        this.form.hasPharmaCert = d.infoUpdateRequest.hasPharmaCert
        
        //address
        this.form.city2Code = d.infoUpdateRequest.city2Code
        this.form.district2Code = d.infoUpdateRequest.district2Code
        this.form.ward2Code = d.infoUpdateRequest.ward2Code
        this.form.address2 = d.infoUpdateRequest.address2
        
        this.form.city1Code = d.infoUpdateRequest.city1Code
        this.form.district1Code = d.infoUpdateRequest.district1Code
        this.form.ward1Code = d.infoUpdateRequest.ward1Code
        this.form.address1 = d.infoUpdateRequest.address1

        this.form.emergencyPhone = d.infoUpdateRequest.emergencyPhone
        this.form.bankCode = d.infoUpdateRequest.bankCode
        this.form.bankNumber = d.infoUpdateRequest.bankNumber
        this.form.status = d.infoUpdateRequest.status
        this.form.taxNumber = d.infoUpdateRequest.taxNumber
        this.form.insuranceNumber = d.infoUpdateRequest.insuranceNumber
        this.form.basicInfoConfirmed = d.infoUpdateRequest.basicInfoConfirmed
        this.form.idFrontImageUrl =  d.infoUpdateRequest.idFrontImageUrl
        this.form.idBackImageUrl =  d.infoUpdateRequest.idBackImageUrl
        this.form.bankImageUrl =  d.infoUpdateRequest.bankImageUrl
        this.form.maritalImageUrl =  d.infoUpdateRequest.maritalImageUrl
        this.form.religionImageUrl =  d.infoUpdateRequest.religionImageUrl

        if(this.form.taxNumber == 'NO_NUMBER'){
          this.noTaxNumber = true
        }
        if(this.form.insuranceNumber == 'NO_NUMBER'){
          this.noInsuranceNumber = true
        }
        this.form.familyMembers = d.employeeFamilyTemps
        
        ApiServices.fetchDistrictFromCity(this.form.city1Code).then(r => {
            this.districts = r.data
        })
        ApiServices.fetchWardFromDistrict(this.form.district1Code).then(r => {
            this.wards = r.data
        })

        
        ApiServices.fetchDistrictFromCity(this.form.city2Code).then(r => {
            this.districts2 = r.data
        })
        ApiServices.fetchWardFromDistrict(this.form.district2Code).then(r => {
            this.wards2 = r.data
        })

        // InfoUpdateApi.getFamilyTemp(this.itemCode).then(d => {
        //   this.form.familyMembers = d.data
        // })

      }).finally(() => {
        this.initForm = false
      })
    },

    getEmployee() {
      return InfoUpdateApi.getEmployee().then(d => {
        this.employee = d
        this.employee.birthday = this.$formatter.formatDate(this.employee.birthday, 'YYYY-MM-DD')
        this.employee.idDate = this.$formatter.formatDate(this.employee.idDate, 'YYYY-MM-DD')

        this.form.employeeCode = this.employee.code
        this.form.employeeName = this.employee.name
        this.form.phoneNumber = this.employee.phoneNumber
        this.form.maritalStatus = this.employee.maritalStatus
        this.form.gender = this.employee.gender
        this.form.birthday = this.employee.birthday
        this.form.idNumber = this.employee.idNumber
        this.form.idDate = this.employee.idDate
        this.form.idPlace = this.employee.idPlace
        this.form.educationLevelCode = this.employee.educationLevelCode
        this.form.educationMajorCode = this.employee.educationMajorCode
        this.form.universityCode = this.employee.universityCode
        this.form.graduateStatus = this.employee.graduateStatus
        
        this.form.city1Code = this.employee.city1Code
        this.form.district1Code = this.employee.district1Code
        this.form.ward1Code = this.employee.ward1Code

        this.form.city1Name = this.employee.city1Name
        this.form.district1Name = this.employee.district1Name
        this.form.ward1Name = this.employee.ward1Name
        this.form.address1 = this.employee.address1

        ApiServices.fetchDistrictFromCity(this.form.city1Code).then(r => {
            this.districts = r.data
        })

        ApiServices.fetchWardFromDistrict(this.form.district1Code).then(r => {
            this.wards = r.data
        })
      })
    },
    
    fetchOrigin() {
      return ApiServices.fetchOrigin().then(r => {
          this.origins = r.data
      })
    },
    fetchCity() {
        return ApiServices.fetchCity().then(r=> {
            this.cities = r.data.data
            this.cities2 = r.data.data
        })
    },
    fetchDistrict(id) {
      this.wards =[]
      this.form.district1Code = null
      this.form.ward1Code = null
      ApiServices.fetchDistrictFromCity(id).then(r => {
          this.districts = r.data
      })
    },
    
    fetchDistrict2(id) {
      this.wards =[]
      this.form.district2Code = null
      this.form.ward2Code = null
      ApiServices.fetchDistrictFromCity(id).then(r => {
          this.districts2 = r.data
      })
    },

    // fetchDistrict(id) {
    //     ApiServices.fetchDistrictFromCity(id).then(r => {
    //         this.districts = r.data
    //     })
    // },

    fetchWard(id) {
      this.form.ward1Code = null
      ApiServices.fetchWardFromDistrict(id).then(r => {
          this.wards = r.data
      })
    },
    
    fetchWard2(id) {
      this.form.ward2Code = null
      ApiServices.fetchWardFromDistrict(id).then(r => {
          this.wards2 = r.data
      })
    },
    
    fetchBank() {
        return ApiServices.fetchBank().then(r => {
            this.banks = r.data
        })
    },
    addFamily() {
      this.form.familyMembers.push({...this.family})
      Object.assign(this.family, this.familyDefault)
      this.$refs.update_info_form_family.resetValidation()
      this.addFamilyDialog = false
    },
    deleteFamily(item) {
      this.form.familyMembers = this.form.familyMembers.filter(x=>x.memberName != item.memberName && x.idNumber != item.idNumber)
    }
  },
}
</script>