import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'employeeFamilies';
// global api
class EmployeeFamilyIndexApi {
  gets() {
    return http.get(`${ROUTE}`).then(r => r.data.data)
  }
  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => {

      r.data.data.doB = moment(r.data.data.doB).format("YYYY-MM-DD")
      return r.data
    })
  }

  post(payload) {
    return http.post(`${ROUTE}`, payload)
  }
  
  put(id, payload) {
    return http.put(`${ROUTE}/${id}`, payload)
  }

  getDropDown() {
    return http.get(`${ROUTE}/get-dropdown`).then(r => r.data)
  }

  getJob() {
    return http.get(`Jobs`).then(r => r.data.map(x => {
        return {
          text: x.name,
          value: x.code
        }
      }
    ))
  }

  getRelationType() {
    return http.get(`${ROUTE}/get-relationTypes`).then(r => r.data)
  }

  getJobDescription(id) {
    return http.get(`Jobs/get-description/${id}`).then(r => r.data)
  }

  deleteItem(id) {
    return http.delete(`${ROUTE}/${id}`).then(r => r.data)
  }

  fetchEmployee() {
    return http.get('employees/search').then(r => r.data)
  }

}

export default new EmployeeFamilyIndexApi();