
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import apiService from '@/components/services/ApiServices';

export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        },

    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
        today: function() {
            return moment().format("DD/MM/YYYY")
        },
        youtubeEmbed: function() {
            let result = "";
            let EMBED_TEMPLATE = '<iframe width="560" height="315" src="https://www.youtube.com/embed/YOUTUBE_ID" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            if(this.form.youtubeUrl)
            {
                let youtubeId = this.youtube_parser(this.form.youtubeUrl)
                this.form.youtubeCode = youtubeId;
                result = EMBED_TEMPLATE.replace('YOUTUBE_ID', youtubeId)
            }
            return result;
        },
        yearArrays: function() {
            return this.generate_array_of_years()
        },
        parsedMonth: function() {
            if(this.month && this.year)
            {
                let result = moment(`${this.year}-${this.month}-1`,"YYYY-MM-DD").format("YYYY-MM-DD");
                this.form.month = result;
                return result;
            }
            return false;
        },
        helpImage: function() {
            return process.env.VUE_APP_BASE_URL + 'uploads/statics/videoStoreSurveyHelp.gif'
        }
    },
    data() {
        return {
            ROOT_URL: "YoutubeManager/",

            isValid: true,
            fetchData: false,

            todos: [],
            departments: [],
            form: {
                code: null,
                title:"",
                description: "",
                departmentCode:"",
                youtubeCode:"",
                youtubeUrl:"",
                googleUrl:"",
            },
            defaultForm:{
                code: null,
                title:"",
                description: "",
                departmentCode:"",
                youtubeCode:"",
                youtubeUrl:"",
                googleUrl:"",
            },
            
            months: [
                {text: "Tháng 1", value: 1},
                {text: "Tháng 2", value: 2},
                {text: "Tháng 3", value: 3},
                {text: "Tháng 4", value: 4},
                {text: "Tháng 5", value: 5},
                {text: "Tháng 6", value: 6},
                {text: "Tháng 7", value: 7},
                {text: "Tháng 8", value: 8},
                {text: "Tháng 9", value: 9},
                {text: "Tháng 10", value: 10},
                {text: "Tháng 11", value: 11},
                {text: "Tháng 12", value: 12},

            ],
            month: undefined,
            year: undefined,

            //file upload rules
            videoUploading: false,
            googleUrl: null,
            fileUpload: null,
            fileRules: [
                value => !value || value.size < 200000000 || 'Kích thước file không dc quá 200Mb',
            ],
        }
    },
    methods: {
        fetch(){
            this.fetchData =true;
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                var data = res.data.data
                this.form = Object.assign({}, res.data.data);
                var date = moment(this.form.monthFormated, 'DD/MM/YYYY');

                console.log(this.month)
                this.month = Number(date.format('M'));
                this.year = Number(date.format('Y'));
                // console.log(this.ROOT_URL+this.item.code, res.data.data);
                // this.form = Object.assign({}, res.data.data, ...this.form);
                this.fetchData =false;
            })
        },
        fetchTodos() {
            return http.get(this.ROOT_URL+"fetch/todos").then(res => {
                this.todos = res.data;
            });
        },
        
        fetchDepartment() {
            return http.get("departments?fetch=1")
            .then(res => {
                this.departments = res.data;
            })
            .catch(err => console.log(err))
        },

        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit)
                {
                    return http.post(this.ROOT_URL, this.form 
                        ).then(res =>{
                            this.$emit("fetch");
                            this.dialog = false;
                        });
                }else {
                    return http.put(this.ROOT_URL+this.item.code, this.form 
                        ).then(res =>{
                            this.$emit("fetch");
                            this.dialog = false;
                        });
                }
            }
        },

        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.month = undefined;
            this.year = undefined;
            this.$refs.form.resetValidation();
            this.fetchData=false;
        },

        youtube_parser(url){
            if(url){
                if(url.includes("shorts/")){
                    url = url.replace("https://www.youtube.com/shorts/", "");
                    url = url.replace("https://youtube.com/shorts/", "");
                    var hasParam = url.split("?");
                    console.log(url.replace("https://www.youtube.com/shorts/", ""), hasParam);
                    if(hasParam.length>1)
                    {
                        url = hasParam[0]
                    }
                    return url;
                }else {
                    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                    var match = url.match(regExp);
                    return (match&&match[7].length==11)? match[7] : false;
                }
            }
            else return "";
        },

        generate_array_of_years() {
            var max = new Date().getFullYear()
            var min = max - 9
            var years = []
          
            for (var i = max; i >= min; i--) {
                years.push(i)
            }
            return years
        },

        uploadGoogleVideo() {
            this.videoUploading = true
            let fileName = this.fileUpload.name
            let fileExt = fileName.split('.').pop()
            console.log(this.fileUpload, fileName, fileExt)
            let formData = new FormData();
            formData.append("file", this.fileUpload)
            return http.post("youtubeManager/upload-google", formData).then(r => r.data).then(d=> {
                this.form.googleUrl = d
                this.videoUploading = false
            } )
        }
    },
    created() {
        this.fetchDepartment();
    }
}