<template>
  <v-banner v-model="v0" three-line transition="slide-y-transition" v-if="!isHttps">
      Hiện tại bạn đang truy cập web với giao thức cũ (http) vui lòng chuyển qua giao thức kết nối mới (https). Xin cám ơn
      <template v-slot:actions="{ dismiss }">
        <v-btn text color="error" @click="dismiss" >
          Tắt
        </v-btn>
        <v-btn text color="success" @click="redirectHttps" >
          Chuyển
        </v-btn>
      </template>
    </v-banner>
</template>

<script>
export default {
  created() {

  },
  computed: {
    isHttps() {
      if (location.protocol !== 'https:') {
        return false
        // location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
      return true
    }
  },
  methods:{
    redirectHttps() {
      window.location.href = 'https://tshrm.trungsonpharma.com/admin/base/home'
    }
  },

  data() {
    return {
      v0: true,
    }
  }
}
</script>