import http from '@/components/services/http-common'
import DatePicker from "@/components/DatePicker";
import UploadService from "@/components/services/UploadService"
import * as moment from "moment/moment";
import apiCaller from "./api.js"

const dateRegex = new RegExp('[0-9]{1,2}(/|-)[0-9]{1,2}(/|-)[0-9]{4}');

export default {
    name: "EditProfile",
    components: {
        "date-picker": DatePicker
    },
    props:["value", "employee"],
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật nhân viên" : "Tạo mới nhân viên";
        },
        
        avatar(){
            if(this.form.avatar)
                return process.env.VUE_APP_BASE_URL + this.form.avatar.replace('wwwroot/', '');
            else
                return '/sample.png';
        },
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        
        idPlaces() {
            let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội"];
            let cityData = this.cities.map(x=>{return x.name});
            return fixedData.concat(cityData);
        }
    },
    watch: {
        value (val) {
            this.fetchCity();
            this.fetchEducationLevel();
            this.fetchUniversity();
            this.fetchAcademicRank();
            this.fetchEducationMajor();
            this.fetchEthinicity();
            this.fetchReligion();
            this.fetchOrigins();
            this.fetchArea();
            if(val)
                this.fetch(this.employee).then(()=>{
                    this.fetchDistrict(this.form.cityCode, true);
                    this.fetchWard(this.form.districtCode, true);
                    this.fetchDDistrict(this.form.domicileCityCode, true);
                    this.fetchDWard(this.form.domicileDistrictCode, true);
                })
            else
                this.close();
        },
    },
    data() {
        return {
            //URL
            PROFILE_URL: "account/",
            //*************** Validate **************
            rules: {
                nameRules:[ 
                    v => !!v || 'Tên nhân viên bắt buộc nhập',
                    v => v.length > 5 || 'Phải nhiều hơn 5 kí tự',
                ],
                emailRules: [
                    v => !!v || 'Email nhân viên là bắt buộc',
                ],
                genderRules:[ 
                    v => !(v === "") || 'Giới tính là bắt buộc',
                ],
                marriageRules:[ 
                    v => !!v || 'TT hôn nhân là bắt buộc',
                ],
                phoneRules: [
                    v => !!v || 'SDT là bắt buộc',
                    v => v.length > 9 || 'Phải nhiều hơn 9 kí tự',
                    v => v.length < 20 || 'Phải ít hơn 20 kí tự',
                ],
                idNumRules: [
                    v => !!v || 'Số CMND là bắt buộc',
                ],
                idDateRules: [
                    v => !!v || 'Ngày cấp CMND là bắt buộc',
                ],
                idPlaceRules: [
                    v => !!v || 'Nơi cấp CMND là bắt buộc',
                ],

                educationLevelRules: [
                    v => !!v || 'Trình độ học vấn là bắt buộc',
                ],

                cityRules: [
                    v => !!v || 'Thành phố thường trú là bắt buộc',
                ],
                districtRules: [
                    v => !!v || 'Quận thường trú là bắt buộc',
                ],
                wardRules: [
                    v => !!v || 'Phường thường trú là bắt buộc',
                ],
                addressRules: [
                    v => !!v || 'Địa chỉ thường trú là bắt buộc',
                ],
                
                required: v=>(!!v || v==false) || 'Nhập dữ liệu',
                validDate: v=> (dateRegex.test(v) || v==null) || 'Dữ liệu không đúng, định dạng ngày dd/mm/yyyy'
            },
            
            //************* Form config */
            activetab: 0,
            valid: false,
            item: undefined,
            isUpdate: false,
            idDateMenu: false,
            HireMenu: false,

            panels:[0, 4],
            isValid: true,

            //new
            departmentSearch: null,
            departments: [],
            employeeSearch: null,
            employees: [],
            marriages:[
                {text: "Kết hôn", value: "KH"},
                {text: "Độc thân", value: "DT"},
                {text: "Chưa có thông tin", value: ""},
            ],
            dCities:[],
            dDistricts:[],
            dWards:[],
            cities:[],
            districts:[],
            wards:[],

            educationLevels:[],
            universities:[],
            academicRanks:[],
            educationMajors:[],
            educationStatues: [
                {text: "Tốt nghiệp", value: true},
                {text: "Chưa tốt nghiệp", value: false},
            ],
            ethnicities:[],
            religions:[],
            origins:[],
            
            areas:[],
            
            
            genders:[
                {text: "Nam", value: true},
                {text: "Nữ", value: false},
            ],

            form: {
                avatar: '',
                //tab1
                name: '',
                gender: '',
                birthDay: moment().format("YYYY-MM-DD"),
                phoneNumber: '',
                emergencyPhoneNumber: '',
                mail: '',
                hireDate: moment().format("YYYY-MM-DD"),
                isVerified:false,
                // tab2
                idNumber: '',
                idDate: moment().format("YYYY-MM-DD"),
                idPlace: '',
                marriage: '',
                ethnicityCode: null,
                religionCode: null,
                originCode: null,
                domicileCityCode: null,
                domicileDistrictCode: null,
                cityCode:null,
                districtCode: null,
                wardCode:null,
                address:"",

                degreeFrom:null,
                degreeTo:null,

                // tab3
                educationLevelCode: null,
                universityCode: null,
                academicRankCode: null,
                educationMajorCode: null,
                educationStatus: true,
            },
            defaultForm: {
                avatar: '',
                //tab1
                name: '',
                gender: '',
                birthDay: moment().format("YYYY-MM-DD"),
                phoneNumber: '',
                mail: '',
                hireDate: moment().format("YYYY-MM-DD"),
                isVerified:false,
                // tab2
                idNumber: '',
                idDate: moment().format("YYYY-MM-DD"),
                idPlace: '',
                marriage: '',
                bankCode:'',
                bankNumber: '',
                ethnicityCode: null,
                religionCode: null,
                originCode: null,

                domicileCityCode: null,
                domicileDistrictCode: null,
                domicileWardCode:null,
                domicileAddress:null,

                cityCode:null,
                districtCode: null,
                wardCode:null,
                address:"",

                degreeFrom:null,
                degreeTo:null,

                // tab3
                educationLevelCode: null,
                universityCode: null,
                academicRankCode: null,
                educationMajorCode: null,
                educationStatus: true,
            },
            formData: {
                Banks: [],
                Cities: [],
                Districts: [],
                Wards: [],
                Postions: [],
                Departments: [],
                DepartmentTypes: [],
                Shifts: [],
                Positions: [],
                Cities1: [],
                Districts1: [],
                Wards1: [],
                Cities2: [],
                Districts2: [],
                Wards2: [],
                EducationLevels: [],
                Universities: [],
                AcademicRanks: [],
                EducationMajors: [],
            },
            departmenttypes: [],
            
            // Upload files
            currentFiles: undefined,
            progress: 0,
            message: "",
            fileInfos: [],
            endpoint: process.env.VUE_APP_BASE_URL,

        };
    },
    methods:{
        chooseAvatar(){
    	    this.$refs.avatarInput.$refs.input.click()
        },
        uploadAvatar(files) {
            let progress = 0;
            UploadService.upload(files, (event) => {
                progress = Math.round((100 * event.loaded) / event.total);
            }, "employees/avatar")
            .then((response) => {
                this.form.avatar = response.data.path;
            })
        },
        fetchEthinicity(val) {
            return http.get("ethnicities").then(res => {
                this.ethnicities = res.data;
            });
        },
        
        fetchReligion(val) {
            return http.get("religions").then(res => {
                this.religions = res.data;
            });
        },
        fetchOrigins(val) {
            const t = apiCaller.fetchOrigins().then(res => {
                this.origins = res.data
            });
        },
        fetchCity(){
            return http.get('cities/fetch').then(res => {
                this.cities = res.data;
                this.dCities = res.data;
            });
        },
        fetchDDistrict(val, init =false){
            if(!init){
                this.dDistricts=[];
                this.form.domicileDistrictCode=null;
                this.dWards=[];
                this.form.domicileWardCode=null;
            }
            return http.get('cities/fetch-district/'+val).then(res => {
                this.dDistricts = res.data;
            });
        },
        fetchDistrict(val, init =false){
            if(!init){
                this.districts=[];
                this.form.districtCode=null;
                this.wards=[];
                this.form.wardCode=null;
            }
            return http.get('cities/fetch-district/'+val).then(res => {
                this.districts = res.data;
            });
        },
        fetchDWard(val, init =false){
            if(!init){
                this.dWards=[];
                this.form.domicileWardCode=null;
            }
            return http.get('districts/fetch-ward/'+val).then(res => {
                this.dWards = res.data;
            });
        },
        fetchWard(val, init =false){
            if(!init){
                this.wards=[];
                this.form.wardCode=null;
            }
            return http.get('districts/fetch-ward/'+val).then(res => {
                this.wards = res.data;
            });
        },
        fetchEducationLevel(){
            return http.get('educationLevels/fetch').then(res=>{
                this.educationLevels = res.data;
            })
        },
        fetchUniversity(){
            return http.get('universities/fetch').then(res=>{
                this.universities = res.data;
            })
        },
        fetchAcademicRank(){
            return http.get('academicRanks/fetch').then(res=>{
                this.academicRanks = res.data;
            })
        },
        fetchEducationMajor(){
            return http.get('educationMajors/fetch').then(res=>{
                this.educationMajors = res.data;
            })
        },
        fetchArea() {
            return http.get("areas/fetch").then(res => {
                this.areas = res.data;
            })
        },
        
        fetch(){
            return http.get(this.PROFILE_URL).then(res => {
                this.form = Object.assign({}, res.data.employee);
                this.form.idDate = moment(this.form.idDate).format("YYYY-MM-DD");
                this.form.hireDate = moment(this.form.hireDate).format("YYYY-MM-DD");
                console.log(res.data)
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                    return http.put("account/update-profile/", this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
            }
        },
        close(){
            this.panels=[0, 4],
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        }
    },
    
    created() {
        
    },
    mounted() {
    }
}