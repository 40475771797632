<template>
  <v-menu
    :value="showMenu"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    @input="menuHandler"
  >
    <v-list>
      <v-list-item @click="$emit('open-ip-dialog')">
        <v-list-item-icon>
          <v-icon color="green">mdi-web</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Cập nhật IP</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    showMenu: {
      type:Boolean,
      default: false,
    },
    x: {
      type:Number,
      default:0,
    },
    y: {
      type:Number,
      default:0,
    }
  },
  watch:{
  },
  methods: {
    menuHandler(value) {
      console.log(value)
      if(value === true) {
        this.$emit('open-rclick-menu')
      }
      if(value === false) {
        this.$emit('close-rclick-menu')
      }
    }
  }
}
</script>
