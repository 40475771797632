<template>
    <div class="employee-report">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" ></myBreadCrumb>
        <!-- <create-dialog ref="createDialog" @needFetch="fetchData"></create-dialog> -->
        <v-row>
            <v-col cols="6" md="3" v-for="(item, k ) in items" :key="k">
                <v-card @click="itemClick(item.linkName)">
                    <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="item.image"
                    >
                    <v-card-title>{{item.title}}</v-card-title>
                    </v-img>

                    <v-card-subtitle class="pb-0">
                        {{item.subtitle}}
                    </v-card-subtitle>

                    <v-card-text class="text--primary">
                    <div v-for="(info, i) in item.infos" :key="i">{{info}}</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import myBreadCrumb from '../../components/BreadCrumb';
import http from '../../components/services/http-common';
import router from '../../router/index'
export default {
    name: "EmployeeReport",
    components: {
        myBreadCrumb,
    },
    computed: {

    },
    watch: {

    },
    data() {
        return {
            //BREADCRUMBS
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Báo cáo nhân sự',
                disabled: true,
                to: {
                    name: "EmployeeReport",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //DATA
            items: [
                {image:"https://cdn.vuetifyjs.com/images/cards/docks.jpg", linkName: "BirthdayReport", icon:"", title: "Sinh nhật", text:"", icon:"", subtitle: "Thông tin", infos: [
                                                                                                            "Sinh nhật nhân viên",
                                                                                                            "Số tài khoản",
                                                                                                            "Ngân hàng"]},
                {image:"https://miro.medium.com/max/4800/1*-Az7OcxNpaO8S4RtM-yozg.jpeg",linkName: "EnrollReport", icon:"", title: "Chấm công", text:"", icon:"", subtitle: "Thông tin", infos: [
                                                                                                            "Thời gian chấm công",
                                                                                                            "Thông tin chấm công",
                                                                                                            "Đi trễ về sớm"]},
                {image:"https://wallpaperaccess.com/full/42562.jpg",linkName: "", icon:"", title: "Ngày phép", text:"", icon:"", subtitle: "Thông tin", infos: [
                                                                                                            "Thống kê phép sử dụng",
                                                                                                            "Lịch sử dụng phép",
                                                                                                            "Phép được cấp"]},
            ],

        }
    },
    methods: {
        itemClick(linkName) {
            router.push({ name: linkName})
        }
    }
}
</script>