<template>
  <v-dialog
    :value="dialog"
    max-width="500px" @click:outside="$emit('close-employee-type-export-dialog')"
    transition="dialog-transition"
  >
    <v-card :loading="loading">
      <v-card-title primary-title>
        Xuất dữ liệu exel phân loại nhân viên
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm" v-model="formValid" id="employee_type_detail_export" ref="employee_type_detail_export">
          <v-row>
            <v-col cols="12">
              <v-text-field type="date" label="Từ ngày" hide-details="auto" dense outlined v-model="form.fromDate" :rules="[v => !!v || 'Nhập dữ liệu này']" ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field type="date" label="Từ ngày" hide-details="auto" dense outlined
                v-model="form.toDate" :rules="[v => !!v || 'Nhập dữ liệu này']" ></v-text-field>
            </v-col> 
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" type="submit" form="employee_type_detail_export">Xuất dữ liệu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import http from '@/components/services/http-common'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formValid: true,
      form: {
        fromDate: moment().startOf('months').format('YYYY-MM-DD'),
        toDate: moment().endOf('months').format('YYYY-MM-DD'),
      },
      loading: false,
    }
  },
  methods: {
    submitForm() {
      this.loading=true
      this.$refs.employee_type_detail_export.validate()
      if(this.formValid)
      {
        return http.get("employeeTypeDetails/export", {
            params: this.form,
            responseType: 'blob',
        }).then(res => {
            this.loading =false
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `DANH SACH_CHI_TIET_PHAN_LOAI.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
      }
    }
  }
}
</script>