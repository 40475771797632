import SystemConfig from '@/views/layouts/SubLayouts/SystemConfig.vue';
//*********** Setting */
import Setting from '@/views/Setting/Index.vue'
import DepartmentType from '@/views/Setting/DepartmentType/Index.vue';
import Department from '@/views/Setting/Department/Index.vue';
import Shift from '@/views/Setting/Shift/Index.vue';
import Position from '@/views/Setting/Position//Index.vue';
import LeavePlan from '@/views/Setting/LeavePlan/Index.vue';
import OvertimePlan from '@/views/Setting/OvertimePlan/Index.vue';
import OvertimeScaleShift from '@/views/Setting/OvertimeScale/Index.vue';
import DefaultBookingShift from '@/views/Setting/OvertimeDefaultBooking/Index.vue';
import BusinessPlan from '@/views/Setting/BusinessPlan/Index.vue';
import Holiday from '@/views/Setting/Holiday/Index';
import Education from '@/views/Setting/Education/Index.vue';
import PositionState from '@/views/Setting/PositionState/Index.vue';
import LongLeave from '@/views/Setting/LongLeave/Index.vue';
import Skill from '@/views/Setting/Skill/Index';
import Ability from '@/views/Setting/Ability/Index';
import Property from '@/views/Setting/Property/Index';

//*********** GEO */
import Geo from '@/views/Geo/Index.vue';
import City from '@/views/Geo/City/Index.vue'
import District from '@/views/Geo/District/Index.vue'
import Ward from '@/views/Geo/Ward/Index.vue'
import Area from '@/views/Geo/Area/Index.vue'

//REPORTs
import Report from '@/views/Report/Index.vue';
import EmployeeReport from '@/views/Report/Employee.vue';
import BirthdayReport from '@/views/Report/Reports/BirthdayReport.vue';
import EnrollReport from '@/views/Report/Reports/EnrollReport.vue';
import TimeSheetReport from '@/views/Report/Reports/TimesheetReport/Index';

import ContractType from '@/views/Setting/ContractType/Index.vue';
export default [
    {
      path: 'systemConfig',
      name: 'SystemConfig',
      component: SystemConfig,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      },
      children:[
        
      //********Setting */
      {
        path: 'setting',
        component: Setting,
        children:[
            {
              path: 'department-type',
              name: 'DepartmentType',
              component: DepartmentType
            },
            {
              path: 'departments',
              name: 'Department',
              component: Department,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Dashboard',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí phòng ban ',
                  disabled: true,
                  to: {
                      name: "department",
                      }
                  },
                ],
              }
            },
            {
              path: 'positions',
              name: 'Position',
              component: Position,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí vị trí',
                  disabled: true,
                  to: {
                      name: "position",
                      }
                  },
                ],
              }
            },
            {
              path: 'shifts',
              name: 'Shift',
              component: Shift,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Dashboard',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí ca làm việc ',
                  disabled: true,
                  to: {
                      name: "shift",
                      }
                  },
                ],
              }
            },
            {
              path: 'leave-plans',
              name: 'LeavePlan',
              component: LeavePlan,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí loại phép ',
                  disabled: true,
                  to: {
                      name: "LeaveType",
                      }
                  },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'long-leaves',
              name: 'LongLeave',
              component: LongLeave,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Cài đặt nghỉ dài hạn',
                    disabled: true,
                    to: {
                        name: "LongLeave",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'contracttypes',
              name: 'ContractType',
              component: ContractType
            },
            {
              path: 'overtime-plan',
              name: 'OvertimePlan',
              component: OvertimePlan
            },
            {
              path: 'overtime-scale-shift',
              name: 'OvertimeScaleShift',
              component: OvertimeScaleShift
            },
            {
              path: 'overtime-booking-shift',
              name: 'DefaultBookingShift',
              component: DefaultBookingShift
            },
            {
              path: 'business-plan',
              name: 'BusinessPlan',
              component: BusinessPlan
            },
            {
              path: 'holidays',
              name: 'Holiday',
              component: Holiday,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí ngày lễ',
                  disabled: true,
                  to: {
                      name: "Holiday",
                      }
                  },
                ],
              }
            },
            {
              path: 'educations',
              name: 'Education',
              component: Education,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Dashboard',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí học vấn ',
                  disabled: true,
                  to: {
                      name: "Education",
                      }
                  },
              ],
              }
            },
            {
              path: 'position-state',
              name: 'PositionState',
              component: PositionState,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí trạng thái công việc',
                  disabled: true,
                  to: {
                      name: "positionStates",
                      }
                  },
                ],
              }
            },
            {
              path: 'skills',
              name: 'Skill',
              component: Skill,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Thiết lập kĩ năng nhân viên',
                    disabled: true,
                    to: {
                        name: "Skill",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
                }
            },
            {
              path: 'abilities',
              name: 'Ability',
              component: Ability,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Khả năng nhân viên',
                    disabled: true,
                    to: {
                        name: "Ability",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'properties',
              name: 'Property',
              component: Property,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Danh sách tài sản',
                    disabled: true,
                    to: {
                        name: "Property",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            // {
            //   path: 'worktypes',
            //   name: 'WorkType',
            //   component: WorkType
            // },
          ]
        },
        {
          path: 'geo',
          component: Geo,
          children:[
            {
              path: 'areas',
              name: 'Area',
              component: Area,
              meta:{ 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Danh sách khu vực',
                    disabled: true,
                    to: {
                        name: "Area",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'cities',
              name: 'City',
              component: City,
              meta:{
                  breadcrumbs: [
                    {
                    text: 'Dashboard',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Quản lí thành phố',
                    disabled: true,
                    to: {
                        name: "City",
                        }
                    },
                ],
              }
            },
            {
              path: 'districts',
              name: 'District',
              component: District,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Dashboard',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí quận huyện',
                  disabled: true,
                  to: {
                      name: "District",
                      }
                  },
                ],
              }
            },
            {
              path: 'wards',
              name: 'Ward',
              component: Ward,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Dashboard',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí phường xã',
                  disabled: true,
                  to: {
                      name: "Ward",
                      }
                  },
                ],
              }
            },
          ]
        },
        {
          path: 'report',
          component: Report,
          children:[
            // ...SalaryReport,
            {
              path: 'timesheet',
              name: 'TimeSheetReport',
              component: TimeSheetReport,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Báo cáo giờ công',
                    disabled: true,
                    to: {
                        name: "TimeSheetReport",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
          ]

        },

        {
          path: 'report/employee',
          name: 'EmployeeReport',
          component: EmployeeReport
        },
        {
          path: 'report/birthday',
          name: 'BirthdayReport',
          component: BirthdayReport,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Báo cáo nhân sự',
                disabled: false,
                to: {
                    name: "EmployeeReport",
                    }
                },
                {
                text: 'Sinh nhật',
                disabled: true,
                to: {
                    name: "EmployeeReport",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'report/enroll',
          name: 'EnrollReport',
          component: EnrollReport,
          props: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Báo cáo nhân sự',
                disabled: false,
                to: {
                    name: "EmployeeReport",
                    }
                },
                {
                text: 'Đi trễ/sớm',
                disabled: true,
                to: {
                    name: "EnrollReport",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
      ]
    },
    
  ]
