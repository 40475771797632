import http from '@/components/services/http-common.js'

const baseUrl = `${process.env.VUE_APP_BASE_URL}api`

export default class EmployeeTypeDetailService {
  getEmployeeTypeDetails = id => http.get(`${baseUrl}/EmployeeTypeDetails/${id}`).then(res => res).then(d => d.data)

  getEmployeeTypeDetail = id => http.get(`${baseUrl}/EmployeeTypeDetails/item/${id}`).then(res => res).then(d => d.data)

  createEmployeeTypeDetail = (form) => http.post(`${baseUrl}/EmployeeTypeDetails`, form)

  updateEmployeeTypeDetail = (id, form) => http.put(`${baseUrl}/EmployeeTypeDetails/${id}`, form)

  deleteEmployeeTypeDetail = (id) => http.delete(`${baseUrl}/EmployeeTypeDetails/${id}`)

  fetchEmployeeType = () => http.get(`${baseUrl}/EmployeeTypeDetails/fetch-employee-type`).then(res => res).then(d => d.data)

  fetchEmployeeTypeReason = () => http.get(`${baseUrl}/EmployeeTypeDetails/fetch-employee-type-reason`).then(res => res).then(d => d.data)
}
