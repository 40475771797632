<template>
    <div class="confirm-overtime">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon"></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="dialog = {state: false, item: undefined}" :item="item" @fetch="fetchData"></create-dialog>
        <v-card class="mb-5 rounded-lg">
            <v-card-title>
                <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker>
                <v-text-field type="date" label="Ngày xét" v-model="filters.execDate" hide-details style="max-width:200px" outlined dense class="mx-2"></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Trạng thái</h5>
                        <v-select placeholder="Chọn" dense v-model="filters.status" outlined hide-details
                        :items='[{text:"Tất cả", value:-1}, {text:"Chờ XN", value:0}, {text:"Đã XN", value:1}, {text:"Từ chối", value:2}]'></v-select>
                    </v-col>
                    <v-col cols="6" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Mã phiếu</h5>
                        <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Mã NS</h5>
                        <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" outlined hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <h5 class="pa-0 ma-0 subtitle-2">Tên NS</h5>
                        <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" outlined hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <h5 class="pa-0 ma-0 subtitle-2">Phòng ban</h5>
                        <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode" outlined hide-details
                        item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                        @update:search-input="fetchDepartment"></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="2">
                        <h5 class="pa-0 ma-0 subtitle-2">Ngày áp dụng</h5>
                        <date-picker2 :range="false" placeholder="Nhập" type="date" dense v-model="filters.date" outlined hide-details></date-picker2>
                    </v-col>
                    
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="rounded-lg">
            <v-card-title>
                Phiếu làm thêm
            <v-spacer></v-spacer>
            <v-btn icon color="orange" class="mx-1" @click="fetchData">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <export-dialog v-model="exportDialog"></export-dialog>
            <create-button @click="dialog.state=true"></create-button>
            <!-- <v-text-field type="date" label="Ngày xét" v-model="filters.execDate" hide-details style="max-width:200px" outlined dense class="mx-2"></v-text-field>
            <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker> -->
            <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter> -->
            </v-card-title>
            
            <v-data-table
                :mobile-breakpoint="0"
                height="550px" fixed-header
                :headers="headers"
                :items="items"
                :items-per-page.sync="tableParams.pageSize"
                :page.sync="tableParams.page"
                :sort-by.sync="tableParams.sortBy"
                :sort-desc.sync="tableParams.sortDesc"
                :server-items-length="rowCount"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{
                    itemsPerPage: '',
                    itemsPerPageOptions:[
                        50, 100, 200
                    ]
                }"
            >
                <!-- <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td></td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td></td>
                        <td>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template> -->
                
                <template v-slot:[`item.actions`]="{item}" >
                    <!-- ********* Edit ****** -->
                    <v-btn icon v-if="item.status == 0" color="primary" @click="accept(item)">
                        <v-icon >
                            mdi-check-circle
                        </v-icon>
                    </v-btn>

                    <!-- ********* Xoa ****** -->
                    <v-btn icon v-if="item.status == 0" color="error" @click="decline(item)" >
                        <v-icon >
                            mdi-close-circle
                        </v-icon>
                    </v-btn>

                    <!-- ********* Undo ****** -->
                    <undo-button @click="undo(item)" v-if="item.status != 0"></undo-button>
                </template>
                <template v-slot:[`item.remove`]="{ item }">
                    <v-btn x-small class="mx-1 my-1" color="error" @click="remove(item)" width="100px">
                        <v-icon small >
                            mdi-delete
                        </v-icon> Hoàn tác
                    </v-btn>
                </template>
                <template v-slot:[`item.departmentCodeNavigation.name`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="teal darken-4" label text-color="white" >
                    <v-icon left>
                        mdi-store
                    </v-icon>
                    {{item.departmentCodeNavigation.name}}
                    </v-chip>
                </template>
                
                <template v-slot:[`item.employee.name`]="{ item }">
                    <span class="font-weight-bold">{{item.employee.name}}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" small>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
                <template
                    v-slot:[`item.sentDate`]="{ item }"
                >
                    {{ formatDate(item.sentDate) }}
                </template>
                <template
                    v-slot:[`item.date`]="{ item }"
                >
                    {{ formatDate(item.date) }}
                </template>
                <template v-slot:[`item.deleteCell`]="{ item }" >
                    <v-btn small color="error" @click="dialogDelete=true, targetItem=item">Xóa</v-btn>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
// import CreateDialog from './Create.vue';
import * as moment from "moment/moment";
import Export from './Export.vue';
import Create from './Create';

export default {
    name: "Attendance",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        'export-dialog': Export,
        "create-dialog": Create,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "overtimeRequests/",
            
            exportDialog: false,
            //filter
            // dialog:false,
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize: 50
            },
            month: moment().format("YYYY-MM"),
            filters:{
                execDate: moment().format("YYYY-MM-DD"),
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
                status: -1,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                date: null,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: false,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', width: '7rem'},
                { text: 'Mã', align: 'start', value: 'code', width: '7rem'},
                { text: 'Trạng thái', align: 'start', value: 'status', width: '9rem'},
                { text: 'Loại', align: 'start', value: 'overtimeType.name', width: '10rem'},
                { text: 'Mã NS', align: 'start', value: 'employee.code', width: '7rem'},
                { text: 'Tên NS', align: 'start', value: 'employee.name', width: '12rem'},
                { text: 'Phòng', align: 'start', value: 'departmentCodeNavigation.name', width: '9rem'},
                { text: 'Giờ tăng ca', align: 'start', value: 'length', width: '7rem'},
                { text: 'Ngày áp dụng', align: 'start', value: 'date', width: '12rem'},
                { text: 'Ngày gởi', align: 'start', value: 'sentDate'},
                { text: 'Ghi chú', align: 'start', value: 'note', width: '12rem'},
                { text: 'Thời gian', align: 'start', value: 'overtimeTimeStr'},
                { text: 'Người xác nhận', align: 'start', value: 'confirmByCodeNavigation.name'},
                { text: 'Ngày xác nhận', align: 'start', value: 'confirmDate'},
                { text: 'Xóa', align: 'start', value: 'deleteCell'},
            ],
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            if(this.isLoading == false) {
                this.isLoading = true;
                return http.get(this.ROOT_URL+'admin/', {
                    params: this.params
                })
                .then(res => {
                    console.log(res.data, "fetch")
                    this.items = res.data.results;
                    this.rowCount = res.data.rowCount;
                })
                .catch(err => {

                })
                .finally(() => {
                    this.isLoading = false;
                })
            }
        },
        accept(item) {
            return http.get(this.ROOT_URL + "accept/" + item.code).then(res => {
                // let eventObj = {
                //     color: "success",
                //     message: "Cập nhật thành công",
                //     toast: true,
                // };
                // this.messageToast(eventObj);
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            return http.get(this.ROOT_URL + "decline/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            http.get(this.ROOT_URL + "undo/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            console.log("remove");
            return http.delete(this.ROOT_URL+'admin/'+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchData();
    }
}
</script>