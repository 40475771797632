<template>
    <v-menu
        ref="menu"
        v-model="formData.menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="value"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="value"
            :label="title"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
        </template>
        <v-time-picker
            scrollable
            v-if="formData.menu"
            v-model="formData.dataTime"
            full-width
            @click:minute="$refs.menu.save(value)"
            @input="updateVal"
            format="24hr"
        ></v-time-picker>
    </v-menu>
</template>

<script>
export default {
    name: "TimePicker",
    props: ['value', 'title'],
    watch: {
        formData: {
            handler(val){
                this.formData.dataTime = this.value;
            },
            deep: true
        }
    },
    data () {
        return{
            formData: {
                menu: false,
                dataTime: this.value,
            }
        }
    },
    methods: {
        updateVal () {
            var val = this.formData.dataTime;
            this.$emit('input', val)
        }
    },
    mounted() {
        // console.log(this.value);
    }
    
}
</script>