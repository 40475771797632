<template>
    <div>
        <create-dialog :value="dialog"
        @close-dialog="dialog=false, item=null"
        :item="item" @fetchData="fetchData" @setSelect="setSelect"></create-dialog>
        <v-card>
            <v-toolbar color="white" flat>
                <v-toolbar-title class="mx-4">Danh sách phiếu</v-toolbar-title>
                <v-spacer></v-spacer>
                
                <!-- //actions -->
                <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success" class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                    <v-list-item @click="dialog=true">
                        <v-list-item-title>Thêm phiếu <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            
            <v-card-title>
                <v-row justify="start">
                    <v-col cols="12" md="2">
                        <v-dialog
                            ref="monthDialog"
                            :return-value.sync="month"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense 
                                v-model="month"
                                label="Chọn tháng *"
                                prepend-icon="mdi-calendar"
                                readonly hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker dense locale="vi"
                            v-model="month"
                            type="month"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.monthDialog.save(month)"
                            >
                                Chọn
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field v-model="filterCode" hide-details outlined dense
                             placeholder="Tìm kiếm" persistent-placeholder label="Tìm mã phiếu" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select v-model="filterStatus" hide-details outlined dense :items="['Mới', 'Đã nhận', 'Hoàn thành', 'Chối từ']" clearable
                             placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm mã phiếu" ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" md="2">
                        <v-text-field v-model="search" hide-details outlined dense
                             placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm chi nhánh" ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" md="2">
                        <v-text-field v-model="filterDepartment" hide-details outlined dense
                             placeholder="Tìm kiếm chi nhánh" persistent-placeholder label="Tìm kiếm chi nhánh" ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table :items="items" :headers="headers"  height="calc(100vh - 300px)" :search="search">
                <template v-slot:[`item.actions`]="{item}" >
                    <edit-button @click="edit(item)"></edit-button>
                    <delete-button @click="deleteItem(item)"></delete-button>
                </template>
            </v-data-table>

        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CreateDialog from './Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        }
    },
    data () {
        return {
            month: moment().startOf("months").format("yyyy-MM"),
            item: null,
            items:[],
            search:"",

            filterCode:"", 
            filterStatus:"", 
            filterDepartment:"", 
            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text:"Mã", value: "code", width:"7rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Phòng ban", value: "departmentName", width:"7rem", 
                    filter: value => {
                        if (!this.filterDepartment) return true
            
                        return value.toUpperCase().includes(this.filterDepartment.toUpperCase())
                    },
                },
                {text:"Trạng thái", value: "status", width:"12rem", 
                    filter: value => {
                        if (!this.filterStatus) return true
            
                        return value.toUpperCase().includes(this.filterStatus.toUpperCase())
                    },
                },
                {text:"Tạo bởi", value: "createdByName", width:"7rem"},
                {text:"Tạo vào", value: "createdAt", width:"7rem"},
                {text:"Tiếp nhận bởi", value: "receivedByName", width:"7rem"},
                {text:"Tiếp nhận vào", value: "receivedAt", width:"7rem"},
            ],

            dialogDelete: false,
            targetItem: null,
            dialog: false,
        }
    },
    methods: {
        fetchData(id) {
            return http.get("propertyRequests", {
                params: {
                    month: this.month
                }
            }).then(res => {
                this.items = res.data.data;
                if(id){
                    let findData = this.data.items.find(x=>x.code==id)
                    console.log(findData)
                    this.item =findData
                    this.dialog = true;
                    // this.edit(findData)
                }
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `propertyRequests/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
    },
    mounted () {
        this.fetchData();
    }
}
</script>