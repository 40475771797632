import http from "@/components/services/http-common"


export default class Api {
    

    post = (request) => http.post('contractCategories', request)

    put = (id, request) => http.put('contractCategories/'+id, request)

    getItem = (id) => http.get(`contractCategories/${id}`).then(res=>res.data)
    
    get = () => http.get('contractCategories').then(res => res.data)

    delete = (id) =>  http.delete(`contractCategories/${id}`)
}
