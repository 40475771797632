<template>
    <v-dialog v-model="dialog" hide-overlay transition="dialog-bottom-transition" max-width="600px">
        <v-card>
            <v-card-title class=""> Xuất excel
                <v-spacer></v-spacer>
                <v-icon color="white" @click="dialog=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <date-picker2
                        v-model="form.fromTo" label="Khoảng thời gian" :width="800"
                        multiple clearable
                        ></date-picker2>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                        :items="formData.departments"
                        v-model="form.DepartmentCodes" label="Phòng"
                        multiple clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete multiple item-text="name" item-value="code" :items="formData.employees" v-model="form.employeeCodes"
                        label="Nhân viên" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]' clearable>
                            <template v-slot:selection="data">
                                <strong>{{ data.item.code }}</strong> {{`: ${data.item.name} - ${data.item.department!=null? data.item.department: 'Chưa phân công'}`}}; 
                            </template>
                            <template v-slot:item="data">
                                <strong>{{ data.item.code }} </strong> {{`: ${data.item.name} - ${data.item.department!=null? data.item.department: 'Chưa phân công'}`}}
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6" v-if="timeLimit">
                        <date-picker2 v-model="form.from" :width="500"></date-picker2>
                    </v-col>
                    <v-col cols="6" v-if="timeLimit">
                        <date-picker2 v-model="form.to" :width="500"></date-picker2>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete multiple label="Trạng thái" :items="[
                            {text: 'Xác nhận', value:1},
                            {text: 'Từ chối', value:2},
                            {text: 'Chờ', value:0},
                        ]" v-model="form.statuses" clearable>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="customBtn">
                </slot>
                <v-btn depressed dark color="teal darken-4" @click="getExport">
                    <v-icon small left>mdi-download</v-icon>
                    Tải về file
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as moment from "moment/moment"
import http from '@/components/services/http-common';
import UploadService from "@/components/services/UploadService";
import EventBus  from "@/components/services/EventBus";
import DatePicker from "@/components/DatePicker";

export default {
    name: "Excel",
    props: ['value', 'url', 'fileName', 'timeLimit'],
    components: {
        "date-picker": DatePicker
    },
    computed:{
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    watch: {
      options: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
    },
    data () {
        return {
            progress: 0,
            currentFiles: undefined,
            message: "",
            fileInfos: [],

            //**************** TABLE CONFIG */
            employees: [],
            options: {},
            totalItem: 0,
            isLoading: false,

            formData: {
                employees:[],
                departments: [],
            },
            form: {
                fromTo:[],
                employeeCodes: [],
                departmentCodes: [],
                statuses: [],
                from: moment().startOf("months").format("YYYY-MM-DD"),
                to: moment().endOf("months").format("YYYY-MM-DD"),
            },

            //************* data table config ********
            headers: [
                { text: 'Mã nhân viên', value: 'code' },
                {
                    text: 'Tên nhân viên',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Hợp đồng', value: 'contract', sortable: false },
                // { text: 'Phúc lợi', value: 'benefit', sortable: false },
                { text: 'Giới tính', value: 'gender' },
                { text: 'Ngày sinh', value: 'birthday' },
                { text: 'Chức vụ', value: 'position' },
                { text: 'Phòng ban', value: 'department' },
                { text: 'Khối', value: 'group' },
            ],
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        
        fetchEmployee(val) {
            return http.get("employees/search").then(res => {
                this.formData.employees = res.data;
            });
        },
        fetchData () {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            if(page != undefined || itemsPerPage != undefined)
                return http.get('employees/preview-export?page='+ page + '&pageSize=' + itemsPerPage, {
                    params: this.form
                }).then(res => {
                    this.isLoading = false;
                    this.employees = res.data.items;
                    this.totalItem = res.data.totalItem;
                }).catch(err => {
                    console.log(err);
                });
        },

        fetchDepartmentType() {
            http.get("departmenttypes?dropDown=1").then(res => {
                this.formData.departmentTypes = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
            })
            .catch(err => {
                console.log("err");
            });
        },
        fetchDepartment() {
            this.form.DepartmentCode = '';
            this.formData.departments = [];
            if(this.form.DepartmentTypeCode != '')
                http.get("departments?dropDown=1").then(res => {
                    this.formData.departments = res.data.map(function(obj) {
                    return {
                        text: obj.name,
                        value: obj.code,
                        }
                    });
                }).catch(err => {
                    console.log(err);
                });
        },
        
        fetchPosition() {
            http.get("positions?dropDown=1").then(res => {
                this.formData.positions = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        },

        fetchShift() {
            if(this.form.DepartmentTypeCode != '')
                http.get("departmenttypes/fetch-shift/" + this.form.DepartmentTypeCode).then(res => {
                    this.formData.shifts = res.data.map(function(obj) {
                    return {
                        text: obj.name,
                        value: obj.code,
                        }
                    });
                    console.log(res)
                }).catch(err => {
                    console.log(err);
                });
        },
        selectFile(files) {
            this.progress = 0;
            this.currentFiles = files;
        },
        getExport() {
            let url = `${this.url}/export`
            http.get(url, {
                params: this.form,
                responseType: 'arraybuffer',
            }).then((res)  => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.fileName??'file'}.xlsx` ); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((err)  => {
                console.log(err);
            });
        },
    },
    created () {
        this.fetchEmployee();
        this.fetchDepartment();
    }
}
</script>