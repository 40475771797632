<template>
    <v-dialog v-model="value" max-width="600px" scrollable @click:outside="close">
        <v-card>
            <v-card-title>Tạo phiếu yêu cầu CC/DC</v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="form" v-model="valid" :readonly="headerCreated">
                    <v-row class="mt-1">
                        <v-col cols="12">
                            <v-autocomplete outlined :items="departments" item-text="name" item-value="code" 
                            v-model="form.departmentCode" label="Phòng ban" :rules="[v=>!!v|| 'Dữ liệu bắt buộc']"></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
                <v-data-table v-if="headerCreated" :headers="detailHeaders" :items-per-page="-1" :items="detailItems">
                    <template v-slot:[`body.prepend`]="" >
                        <tr>
                            <td><v-autocomplete :items="properties" v-model="detail.propertyCode" item-text="name" item-value="code" :rules="[rules.required]" 
                                clearable
                                ></v-autocomplete></td>
                            <td>
                                <v-text-field type="number" v-model="detail.quantity" min="0" :suffix="getUnit(detail.propertyCode)" :rules="[v=>!!v ||'Nhập dữ liệu']">
                                </v-text-field> 
                            </td>
                            <td>
                                <v-text-field type="number" v-model="detail.actualQuantity" min="0" :suffix="getUnit(detail.propertyCode)" :rules="[v=>!!v ||'Nhập dữ liệu']">
                                </v-text-field> 
                            </td>
                            <td class="text-center">
                                <v-btn small color="primary" class="mx-auto" @click="createDetail" v-show="detail.propertyCode != null && detail.quantity != null">Tạo mới</v-btn>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:[`item.actions`]="{item}" >
                        <v-btn small class="error" @click="deleteDetail(item)"><v-icon small left>mdi-delete</v-icon> Xóa</v-btn>
                    </template>
                    
                    <template v-slot:[`item.quantity`]="{item}" >
                        {{`${item.quantity} ${getUnit(item.propertyCode)}`}}
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions v-if="!headerCreated">
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="save" v-if="valid">Lưu </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const googleQRURL = "https://chart.googleapis.com/chart?";
import http from '@/components/services/http-common';
import moment from 'moment/moment';
const FETCH_DEPARTMENT_URL = ""
export default {
    props:["value", "item"],
    watch:{
        value: {
            handler(val){
                if(val)
                {
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    console.log("check is edit")
                    if(this.isEdit)
                    {
                        this.fetch().then(()=> {
                            this.fetchDetail();
                        });
                        this.headerCreated = true;
                    }
                }
                else{
                    this.close();
                }
            }
        },

        "form.name":{
            handler(val){
                this.form.params = this.convertToSlug(val);
            }
        }
    },
    computed: {
        // dialog: {
        //     get: function () {
        //         return this.value;
        //     },
        //     set: function (val){
        //         this.$emit("input", val)
        //     }
        // },
        isEdit() {
            return !!this.item;
        },
        validDetail(){
            console.log(this.rules.required);
        }
    },
    data() {
        return {
            endpoint: `https://private-label.trungsonpharma.com/`,
            qrSrc: "",
            valid: false,
            form: {
                code: "",
                departmentCode: "",
            },
            defaultForm: {
                code: "",
                departmentCode: "",
            },

            /**header */
            departments: [],
            /** Detail Header*/
            detailHeaders: [
                {text:"Tên dụng cụ", value: "property", width:"7rem"},
                {text:"Số lượng", value: "quantity", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "actualQuantity", width:"7rem", align:"center"},
                {text:"", value: "actions", width:"7rem", align:"center"},
            ],
            properties:[],
            detail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
            },
            detailItems:[],

            propertyValid: false,
            amountValid: false,

            
            rules: {
                required: value => !!value || 'Required.'
            },

            /**Stepper params */
            headerCreated: false,
        }
    }, 
    methods: {
        getUnit(property) {
            var data = this.properties.find(x=>x.code == property);
            if(!data)
                return ""
            else
                return data.propertyUnitName
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.$refs.form.resetValidation();
            this.$emit('close-dialog');
        },

        save() {
            if(this.valid)
            {
                return http.post("propertyRequests", this.form).then(res => {
                    this.headerCreated = true;
                    this.$emit("fetchData", res.data.data.code);
                    this.close();
                    
                    // this.fetchDetail();
                    // this.form = Object.assign({}, res.data.data);
                });
            }
        },
        fetch() {
            let url = `propertyRequests/${this.item.code}`;
            return http.get(url).then(res => {
                this.form = Object.assign({}, res.data.data);
            })
        },
        fetchDepartment()
        {
            return http.get("propertyRequests/get-departments").then(res => {
                this.departments = res.data;
            });
        },
        fetchProperty()
        {
            return http.get("properties").then(res => {
                this.properties = res.data.items;
            });
        },
        fetchDetail() {
            let url = `propertyRequests/detail/${this.form.code}`
            return http.get(url).then(res => {
                this.detailItems = res.data;
            })
        },
        createDetail() {
            if(this.detail.propertyCode !=0 && this.detail.quantity !=0)
            {
                let url = `propertyRequests/detail/${this.form.code}`
                return http.post(url, {
                    propertyCode: this.detail.propertyCode,
                    quantity: this.detail.quantity,
                    actualQuantity: this.detail.actualQuantity,
                }).then(res => {
                    var item = {
                        propertyCode: res.data.propertyCode,
                        property: res.data.property,
                        quantity: res.data.quantity,
                        actualQuantity: res.data.actualQuantity
                    };
                    this.detail=Object({}, this.defaultDetail);
                    this.detailItems.push(item);
                });
            }
        },
        
        deleteDetail(item) {
            let url = `propertyRequests/detail/${this.form.code}`;
            return http.delete(url, {
                data:{
                    propertyCode: item.propertyCode,
                }
            }).then(res => {
                this.detailItems = this.detailItems.filter(x=>x.propertyCode != res.data.data.propertyCode)
            })
        }

        //SERVICES
    },
    mounted() {
        this.fetchDepartment();
        this.fetchProperty();
    }
}
</script>