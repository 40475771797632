import CreateDialog from '../Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        }
    },
    data () {
        return {
            month: moment().startOf("months").format("yyyy-MM"),
            dialog: false,
            item: null,
            items:[],
            search:"",
            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text:"Mã ASM", value: "asmCode", width:"10rem"},
                {text:"Tên ASM", value: "asmName", width:"10rem"},
                {text:"Giá trị(cv)", value: "value", width:"9rem"},
                {text:"Năm", value: "year", width:"10rem"},
            ],

            statusColor : {
                "Tạo mới":"green",
                "Tiếp nhận":"orange",
                "Từ chối":"red",
                "Hoàn thành":"cyan",
            },
            // *** Filter ***
            filterStatus:"",
            filterCode:"",
            filterSender:"",
            filterReceiver:"",
            filterCompleter:"",
            filterSenderDept:"",
            filterReceiveDept:"",

            dialogDelete: false,
            targetItem: null,
        }
    },
    methods: {
        blockClick(event)
        {
            event.stopPropagation()
        },
        
        fetchData() {
            return http.get("candidatePlans").then(res => {
                this.items = res.data;
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let code = this.targetItem.asmCode + "|" + this.targetItem.year;
            let url = `candidatePlans/${code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
    },
    mounted () {
        this.fetchData();
    }
}