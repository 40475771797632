<template>
  <v-card>
    <v-card-title>
      {{isUpdate ? "Cập nhật": "Tạo mới"}} dữ liệu {{itemId}}
      <v-spacer></v-spacer>
      <v-btn icon color="error" @click="resetForm(), $emit('close-dialog')"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <v-form v-model="formValid" @submit.prevent="formSubmit" ref="form_create_image_report" id="form_create_image_report">
        <v-row class="mt-0">
          <v-col cols="12">
            <v-text-field outlined dense hide-details="auto" label="Mã dữ liệu" v-model="form.code" persistent-placeholder
              :rules="[
                v => !!v || 'Nhập dữ liệu này',
                v => (v && v.length <= 10) || 'Mã không dc lớn hơn 10 ký tự'
              ]"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined dense hide-details="auto" label="Tên danh mục báo cáo hình ảnh" v-model="form.name" persistent-placeholder
              :rules="[
                v => !!v || 'Nhập dữ liệu này',
                v => (v && v.length <= 100) || 'Dữ liệu không dc lớn hơn 100 ký tự'
              ]"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-subheader style="height:auto; padding-left:0">Mô tả</v-subheader>
            <editor v-model="form.description" label="Mô tả" >
            </editor>
            <!-- <v-textarea outlined dense hide-details="auto" label="Mô tả" v-model="form.description" 
              :rules="[
                v => !!v || 'Nhập dữ liệu này',
                v => (v && v.length <= 255) || 'Dữ liệu không dc lớn hơn 255 ký tự'
              ]"
            ></v-textarea> -->
          </v-col>

          <v-col cols="6">
            <v-select v-model="form.isHide" :items="[
              {text: 'Hiển thị', value: 0},
              {text: 'Ẩn', value: 1}
                              ]" persistent-placeholder label="Hiển thị" hide-details="auto" outlined>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select v-model="form.isActive" :items="[
              {text: 'Hoạt động', value: 1},
              {text: 'Tạm dừng', value: 0}
                              ]" persistent-placeholder label="Trạng thái hoạt động" hide-details="auto" outlined>
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" block type="submit" form="form_create_image_report">Lưu</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Api from './api'
import Editor from '@/components/Editor/Editor'

export default {
  components:{
    "editor": Editor
  },
  props: {
    itemId: {
      type: String,
      default: null
    },
    bus: {
      type: Object
    }
  },
  computed: {
    isUpdate() {
      if(this.itemId){
        this.formFillData()
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {

      // validation
      formValid: false,
      codeValid: true,

    // form data
      form: {
        code: "",
        name: "",
        description: "",
        isActive: 1,
        isHide: 0
        // status: "SHOW"
      },
      defaultForm: {
        code: "",
        name: "",
        description: "",
        isActive: 1,
        isHide: 0
        // status: "SHOW"
      }
    }
  },
  methods: {
    resetForm(){
      this.form = Object.assign({}, this.defaultForm)
      this.$refs.form_create_image_report.resetValidation();
    },

    //validation methods
    // codeChecking() {
    //   this.imageReportCategoryService.codeChecking(this.form.code).then(d => {
    //     console.log(d)
    //     this.codeValid = d
    //   })
    // },

    // form methods
    formFillData() {
      if(this.itemId){
        this.apiService.get(this.itemId).then(d => {
          Object.assign(this.form, d)
        })
      }
    },
    formSubmit() {
      this.$refs.form_create_image_report.validate();

      
      if(this.formValid) {
        console.log(this.formValid)
        // tao moi
        if(!this.isUpdate) {
          this.apiService.post(this.form)
        }

        //cap nhat
        if(this.isUpdate) {
          this.apiService.put(this.itemId, this.form).then(() => {
            this.$emit("close-dialog")
          })
        }
      }

    }
  },
  apiService: null,

  created() {
    this.apiService = new Api()
  }

}
</script>