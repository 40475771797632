<template>
    <div class="info-update">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb> -->
        <!-- <create-dialog ref="createDialog" @CloseDialog="closeHandler" @fetchData="fetchData" v-model="dialog"></create-dialog> -->
        <v-card class="rounded-lg">
            <v-card-title>
                <v-text-field v-model="search" @keydown.enter="fetchData" class="mx-2" style="max-width:18rem" outlined dense
                    append-icon="mdi-magnify"
                    label="Tìm kiếm"
                    single-line
                    hide-details
                ></v-text-field>
                
                <v-spacer></v-spacer>
                <v-btn small @click="fetchData" icon><v-icon>mdi-refresh</v-icon></v-btn>
                <create-button @click="openDialog"></create-button>
            </v-card-title>
        </v-card>
        <v-card class="mt-5 rounded-lg">
            <v-card-title>

                <!-- <v-menu bottom left offset-y class="d-md-none">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="d-md-none">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list dense>
                    <v-list-item  @click="dialog.state = true">
                        <v-list-item-icon><v-icon color="teal">mdi-plus</v-icon></v-list-item-icon>
                        <v-list-item-title>Thêm công việc</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu> -->
                Danh sách phiếu cập nhật thông tin
            </v-card-title>
            <v-data-table
            :mobile-breakpoint="0"
            height="600px"
            :headers="headers"
            :items="items"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.isActive`]="{ item }">
                    <v-radio-group v-model="item.isActive" row dense @change="changeVisible(item)">
                        <v-radio color="green" label="Hiện" :value="true" ></v-radio>
                        <v-radio color="red" label="Ẩn" :value="false" ></v-radio>
                    </v-radio-group>
                    <!-- <v-icon v-if="item.isActive">
                        mdi-check
                    </v-icon> -->
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <!-- <v-btn x-small @click="editItem(item)" class="mx-1">
                        <v-icon small>mdi-pencil</v-icon> Xem
                    </v-btn> -->
                    <v-btn @click="editItem(item)" icon class="mx-1" color="teal darken-4" dark>
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                    <v-btn @click="deleteItem(item)" icon class="mx-1" color="error">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                    {{$formatter.formatDate(item.createdAt, 'DD/MM/YYYY HH:mm:ss')}}
                </template>
                
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip color="success" v-if="item.status==1">Đã duyệt</v-chip>
                    <v-chip color="primary" v-if="item.status==0">Chờ duyệt</v-chip>
                    <v-chip color="error" v-if="item.status==2">Từ chối</v-chip>
                </template>

            </v-data-table>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="error" @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <create-dialog ></create-dialog>
    </div>
</template>

<script>
import CreateButton from '@/components/Crud/CreateButton'
import http from '@/components/services/http-common'
import InfoUpdateApi from './api.js'
import * as moment from "moment/moment";
import ApiServices from '@/components/services/ApiServices.js';
import Create from './Create'

export default {
    name: 'InfoUpdateIndex',
    components: {
        CreateButton,
        'create-dialog': Create
    },
    props:["breadcrumbs", "icon"],
    data () {
        return {
            // URLS
            ROOT_URL: "jobs",

            rootUrl: "positions",

            //dialog
            // dialog: {state: false, item: undefined},
            
            //datatable config
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,
            dialogDelete: false,
            targetItem: undefined,
            employee: null,
            dialog:false,
            isValid: false,
            base64: '',

            headers: [
                
              { text: 'Thao tác', align: 'start', sortable: false, value: 'actions' },
              { text: 'Id', align: 'start', sortable: true, value: 'id' },
              { text: 'Employee Code', value: 'employeeCode' },
              { text: 'Tạo bởi', value: 'createdBy' },
              { text: 'Tạo vào', value: 'createdAt' },
            //   { text: 'Origin Code', value: 'originCode' },
            //   { text: 'Religion Code', value: 'religionCode' },
            //   { text: 'Ethnicity Code', value: 'ethnicityCode' },
            //   { text: 'City Code', value: 'city2Code' },
            //   { text: 'District Code', value: 'district2Code' },
            //   { text: 'Ward Code', value: 'ward2Code' },
            //   { text: 'Address', value: 'address2' },
            //   { text: 'Emergency Phone', value: 'emergencyPhone' },
            //   { text: 'Bank Code', value: 'bankCode' },
            //   { text: 'Bank Account', value: 'bankAccount' },
              { text: 'Trạng thái', value: 'status' },
            ],
        }
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
    //   dialog:{
    //     handler(val) {
    //       if(val) {
    //       this.openDialog()
    //       } else {
    //         this.closeHandler()
    //       }
    //     }
    //   },
      search:{
          handler() {
            this.options.page = 1;
          }
      },
    },
    methods:{
        openDialog(){
            this.$root.$emit('openDialog')
            // this.getEmployee()
        },
        fetchData () {
            return InfoUpdateApi.gets().then(d => {
                this.items = d
            }).catch(err => {
                console.log(err);
            });
        },
        editItem(item) {
            this.$root.$emit('editDialog', item)
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return  InfoUpdateApi.remove(this.targetItem.id).then(() => {
                this.dialogDelete = false;
                this.fetchData()
            })
        },

        changeVisible(item){
            return http.get('jobs/visibility/'+item.code, {
                params: {
                    status: item.isActive
                }
            }).then(() => {
                return this.fetchData()
            })
        },
        closeHandler() {
            // this.dialog = {state: false, item: undefined}
        },

        
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },

    },


    mounted () {
        this.fetchData()
        // this.fetchOrigin()
        // this.fetchCity()
        // this.fetchBank()
        // ApiServices.fetchReligion().then(r => {
        //     this.religions = r.data
        // })
        // ApiServices.fetchEthnicity().then(r => {
        //     this.ethnicities = r.data
        // })
    },
    created()
    {
        this.$emit("setUrl", this.breadcrumbs);
    }, 
    destroyed() {
        this.$emit("setUrl", []);
    }
}
</script>

<style lang="stylus" scoped>

</style>