<template>
  
  <div class="recruitment-request">
    <!-- <v-breadcrumbs class="bread-crumbs"
      :items="breadcrumbs" large >
    
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :exact="item.exact"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
      <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb> -->
      

      <!-- <create-dialog-health-insurance ref="createHealthInsurance" @needFetch="fetchData"></create-dialog-health-insurance> -->
      <v-card class="mb-4">
        <v-card-title>Kì điều chỉnh thông tin
          <v-btn :disabled="!(allowSelect.length==1 && allowSelect[0]?.isApplied !=1 && allowSelect[0]?.isRollback !=1)" class="ml-auto" @click="applyAllowHandler" color="blue darken-4" dark outlined>Cập nhật hồ sơ</v-btn>
          <v-btn :disabled="!(allowSelect.length==1 && (allowSelect[0]?.isRollback ==1))" class="mx-2" @click="undoAllowHandler" color="red darken-4" dark outlined>Hoàn tác</v-btn>
          <v-btn class="mx-2"  color="success" outlined @click="exportHandler">Xuất excel</v-btn>
          <v-btn class="mx-2" @click="dialogAllow=true" color="teal darken-4" dark>Tạo mới</v-btn>
        </v-card-title>

        <v-card-text>
          <v-data-table dense :headers="allowHeaders" :items-per-page="5" :items="allows" v-model="allowSelect" single-select @input="allowSelectHandler" show-select>
            <template v-slot:[`item.actions`]="{ item }">
                <v-switch x-small v-model="item.status" text @change="statusAllow($event, item)" :true-value="1" :false-value="0"></v-switch>
            </template>
            <template v-slot:[`item.remove`]="{ item }">
                <v-btn x-small color="error" text @click="allowDialogDelete = true,  allowTargetItem = item" v-if="item.isRollback!=1"> Xóa</v-btn>
            </template>
            <template v-slot:[`item.validFrom`]="{ item }">
                {{ formatDate(item.validFrom, false) }}
            </template>
            <template v-slot:[`item.validTo`]="{ item }">
                {{ formatDate(item.validTo, false) }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatDate(item.createdAt, true) }}
            </template>
            <template v-slot:[`item.isApplied`]="{ item }">
                <v-chip small v-if="item.isApplied==1" color="success">Cập nhật</v-chip>
            </template>
            
            <template v-slot:[`item.count`]="{ item }">
              {{ item.totalUpdate }}/{{item.totalEmployee}}
            </template>
            <template v-slot:[`item.isRollback`]="{ item }">
              <v-chip v-if="item.isRollback == 1" color="error" small>Lưu trữ: {{ item.idRollback }}</v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-card>
          <v-card-title>
              Danh sách phiếu
              <v-spacer></v-spacer>
              <v-text-field hide-details v-model="search" outlined style="max-width: 250px;" dense placeholder="Nhập dữ liệu bạn muốn tìm" label="Tìm kiếm" persistent-placeholder></v-text-field>
              <!-- <v-btn class="" color="teal darken-4" dark :to="{ name: 'EmployeeInfoUpdateCreate', query: {
                returnPage: page
              } }"> Tạo mới</v-btn> -->
              <!-- <create-button @click="dialog={state: true, item:null}"></create-button> -->
          </v-card-title>
          <v-data-table height="600px"
          :headers="headers"
          :page="page"
          :items="data"
          :search="search"
          :loading="isLoading"
          no-data-text="Chọn một kì điều chỉnh thông tin"
          >
              <!-- BHYT -->
              <template v-slot:[`item.actions`]="{ item }">
                  <v-btn x-small color="white" @click="editItem(item)"><v-icon small>mdi-pencil</v-icon> Chi tiết</v-btn>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                  <v-btn small color="error" style="width: 50px; height: 50px; " class="ma-2" @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <template v-slot:[`item.relationType`]="{ item }">
                <v-chip color="pink lighten-4">
                  <b>{{relationTypes[item.relationType]}}</b>
                </v-chip>
              </template>
              <template v-slot:[`item.isDependant`]="{ item }">
                  <v-icon color="green" v-if="item.isDependant == 1">mdi-check</v-icon>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                  {{ formatDate(item.createdAt, true) }}
              </template>
              <template v-slot:[`item.remove`]="{ item }">
                <v-btn text x-small color="error" @click="dialogDelete=true, targetItem = item">Thu hồi</v-btn>
              </template>
              <template v-slot:[`item.isLatest`]="{ item }">
                <v-icon color="green" v-if="item.isLatest">mdi-check</v-icon>
              </template>
          </v-data-table>
          
      </v-card>

      <!-- ************** DELETE CONFIRMATION *************** -->
      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
              <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="allowDialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="allowDialogDelete=false">Thoát</v-btn>
              <v-btn color="blue darken-1" text @click="cfmAllowDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>
      
      <v-dialog v-model="dialogAllow" max-width="500px">
          <v-card>
              <v-card-title class="headline">Tạo dữ liệu kì bổ sung dữ liệu</v-card-title>
              <v-card-text>
                <v-form id="info_update_allow_form" ref="info_update_allow_form" v-model="allowValid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field  label="Mã" v-model="allowForm.code" placeholder="DCTT-T1123"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field type="date" v-model="allowForm.validFrom"  label="Từ ngày"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field type="date" v-model="allowForm.validTo"  label="Đến ngày"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea outlined v-model="allowForm.description" label="Mô tả"></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="saveAllow" form="info_update_allow_form">Lưu</v-btn>
              <v-btn color="error" outlined text >Thoát</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="dialogExport" scrollable>
        <v-card>
          <v-card-title>
            Xuất excel 
            <v-spacer></v-spacer>
            <export-excel :items="exportData" :headers="exportHeader"></export-excel>
            <!-- <v-btn small text color="teal darken-4">Tải xuống</v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-data-table :items="exportData" :headers="exportHeader"> 
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import EmployeeFamilyIndexApi from './api.js'
import MyBreadcrumb from '../../../components/MyBreadcrumb.vue';
import ExportExcel from '@/components/ExportExcel.vue';
// import ExportExcel from '../../../components/ExportExcel.vue';

export default {
  components: { MyBreadcrumb, ExportExcel },
  data() {
    return {
      //DATATABLE
      data: [],
      relationTypes: [],
      page: 0,
      totalItem: 0,
      search:"",
      options: {},
      isLoading: true,
      targetItem: undefined,
      headers: [
          { text: 'Trạng thái', value: 'actions', sortable: false, width: "12rem", filterable: false},
          { text: 'Mã nhân sự', align: 'start', sortable: false, value: 'employeeCode', },
          { text: 'Tạo bởi', align: 'start', value: 'createdBy', width:'15rem' },
          { text: 'Tạo vào', align: 'start', value: 'createdAt', },
          { text: 'Trạng thái', value: 'status' },
          { text: 'Mới nhất', value: 'isLatest' },
          { text: '', value: 'remove' },
      ],
      
      allows:[],
      allowSelect:[],
      allowHeaders: [
          { text: 'Hoạt động', value: 'actions', sortable: false, width: "7rem", filterable: false},
          { text: 'Lưu trữ', align: 'start', sortable: false, value: 'isRollback', },
          { text: 'Mã', align: 'start', sortable: false, value: 'id', },
          { text: 'Từ', align: 'start', value: 'validFrom' },
          { text: 'Tới', align: 'start', value: 'validTo', },
          { text: 'Tạo vào', value: 'createdAt' },
          { text: 'Tạo bởi', value: 'createdBy' },
          { text: 'Số lượng', value: 'count' },
          { text: 'Cập nhật hồ sơ', value: 'isApplied' },
          { text: 'Cập nhật vào', value: 'appliedAt' },
          { text: 'Cập nhật bởi', value: 'appliedBy' },
          { text: '', value: 'remove' },
      ],
      allowForm: {

      },
      dialogAllow: false,
      allowValid: true,
      
      allowTargetItem: null,
      allowDialogDelete: null,

      dialogDelete: false,
      
      exportData: [],
      exportHeader: [
        { text: 'Mã', value:'code' },
        { text: 'Giới tính', value:'gender' },
        { text: 'Ngày sinh', value:'birthday' },
        { text: 'Số CCCD', value:'idNumber' },
        { text: 'Nơi cấp', value:'idPlace' },
        { text: 'Ngày cấp', value:'idDate' },
        { text: 'Trạng thái hôn nhân', value:'maritalStatus' },
        { text: 'Quốc tịch', value:'originName' },
        { text: 'Tôn giáo', value:'religionName' },
        { text: 'Dân tộc', value:'ethnicityName' },
        { text: 'Thành phố 1', value:'city1Name' },
        { text: 'Quận, huyện 1', value:'district1Name' },
        { text: 'Phường, xã 1', value:'ward1Name' },
        { text: 'Địa chỉ 1', value:'address1' },
        { text: 'Thành phố 2', value:'city2Name' },
        { text: 'Quận, huyện 2', value:'district2Name' },
        { text: 'Phường, xã 2', value:'ward2Name' },
        { text: 'Địa chỉ 2', value:'address2' },
        { text: 'Trình độ học vấn 1', value:'educationLevel1Name' },
        { text: 'Chuyên ngành 1', value:'educationMajor1Code' },
        { text: 'Cơ sở đào tạo 1', value:'university1Name' },
        { text: 'Tốt nghiệp 1', value:'graduateStatus1' },
        { text: 'Trình độ học vấn 2', value:'educationLevel2Name' },
        { text: 'Chuyên ngành 2', value:'educationMajor2Code' },
        { text: 'Cơ sở đào tạo 2', value:'university2Name' },
        { text: 'Tốt nghiệp 2', value:'graduateStatus2' },
        { text: 'Chứng chỉ hành nghề dược', value:'pharmaCert' },
      ],

      dialogExport: false,

      breadcrumbs: [
        {
          text: 'Danh sách yêu cầu điều chỉnh thông tin',
          disabled: false,
          to: {name: 'HrEmployeeInfoUpdate'}
        },
      ]
    }
  },
  methods: {
    
    formatDate(value, time = false) {
      if(time==true) {
        return moment(value).format("DD/MM/YYYY hh:mm:ss");
      }
      return moment(value).format("DD/MM/YYYY");
    },
    
    editItem (item) {
      this.$router.push({
        name: 'HrEmployeeInfoUpdateEdit',
        params: { id: item.id },
        query: { returnPage: this.$route?.returnPage??0
        }
      })
    },

    //fetching
    gets() {
      this.isLoading = true
      return EmployeeFamilyIndexApi.getAllow().then(d => {
        console.log(d)
        this.allows = d.data
      }).finally(()=> {
        this.isLoading = false
      })
    },

    allowSelectHandler() {
      if(this.allowSelect.length == 0) {
        // alert("Chọn kì cập nhật")
        this.data = []
        return
      }
      return EmployeeFamilyIndexApi.gets(this.allowSelect[0].id).then(d => {
      this.data = d
      })
    },
    
    deleteItem (item) {
          this.dialogDelete = true;
          this.targetItem = item;
      },
    cfmDeleteItem() {
      alert("Not supported")
      return
      return EmployeeFamilyIndexApi.deleteItem(this.targetItem.id).then(() => {
        this.targetItem = null
        this.dialogDelete = false
      }).finally(() => {
        return this.gets()
      })
    },

    //** Allow */
    saveAllow() {
      return EmployeeFamilyIndexApi.postAllow(this.allowForm).then(() => {
      });
    },

    cfmAllowDeleteItem() {
      return EmployeeFamilyIndexApi.deleteAllow(this.allowTargetItem.id).then(() => {
        this.allowTargetItem = null
        this.dialogDelete = false
      }).finally(() => {
        return this.gets()
      })
    },

    statusAllow($event, item) {
      if(item.isApplied==1) {
        alert("Phiếu đã cập nhật vào hồ sơ, Không thể chỉnh sửa")
        return this.gets()
      }
      EmployeeFamilyIndexApi.statusAllow(item.id, $event ? 1: 0)
    },

    applyAllowHandler() {
      if(this.allowSelect[0].length == 0 ){
        alert("Hãy chọn một nhân viên")
        return
      }
      this.isLoading = true
      return EmployeeFamilyIndexApi.applyAllow(this.allowSelect[0].id).finally(() => {
        this.isLoading = false
        this.allowSelect = []
        return this.gets()
      })
    },
    
    undoAllowHandler() {
      if(this.allowSelect[0].length == 0 ){
        alert("Hãy chọn một nhân viên")
        return
      }

      this.isLoading = true
      return EmployeeFamilyIndexApi.undoAllow(this.allowSelect[0].id).finally(() => {
        this.isLoading = false
        this.allowSelect = []
        return this.gets()
      })
    },
    exportHandler() {
      this.dialogExport = true
      if(this.allowSelect[0].length == 0 ){
        alert("Hãy chọn một nhân viên")
        return
      }
      return EmployeeFamilyIndexApi.exportAllowDetail(this.allowSelect[0].id).then(d => {
        console.log(d)
        this.exportData = d.data
        console.log("export", this.exportData)
      })
    }
  },

  created() {
    this.gets()
    
    EmployeeFamilyIndexApi.getRelationType().then(r => {
      this.relationTypes = r.dict
    })
  }
}
</script>