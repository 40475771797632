<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn outlined color="red darken-4" class="mx-2" @click="cfmDialog=cfmDialog=true" >Xóa</v-btn>
      <v-icon color="red" @click="closeDialog">mdi-close</v-icon>
    </v-card-title>
  <v-stepper
    v-model="form.step"
    vertical
  >
    <v-stepper-step
        :complete="currentCompleteStep(1)"
        step="1"
      >
        Thông tin cơ bản {{id}}
        <small>Nhập thông tin cơ bản của template</small>
      </v-stepper-step>
      
    <v-stepper-content step="1">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Tên" v-model="form.name" persistent-placeholder outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Loại" v-model="form.type" persistent-placeholder outlined hide-details readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea  label="Ghi chú" v-model="form.note" persistent-placeholder outlined hide-details></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-file-input label="Ảnh đại diện" v-model="avatar" persistent-placeholder outlined hide-details></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-file-input label="File đính kèm" v-model="attachment" persistent-placeholder outlined hide-details></v-file-input>
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="error" @click="closeDialog"> Đóng</v-btn>
          <v-btn dark color="teal darken-4" @click="submitData"> Lưu</v-btn>
        </v-card-actions>
      </v-card>
    </v-stepper-content>

    <!-- ******************************* STEP2 -->
    <v-stepper-step
        :complete="currentCompleteStep(2)"
        step="2"
      >
        Danh sách nhân viên được áp dụng
        <small>Tạo danh sách nhân viên</small>
      </v-stepper-step>
      
    <v-stepper-content step="2">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea label="Mã nhân viên" hint="Dán danh sách nhân viên được phân cách bởi dấu ;" persistent-hint outlined v-model="selectEmployeesText">
              </v-textarea>
            </v-col>
          </v-row>
          <v-data-table :items="filteredEmployees" :headers="employeeHeaders" 
            v-model="selectEmployees" ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" outlined @click="form.step=1">Quay lại</v-btn> 
          <v-btn color="teal darken-4" dark @click="setEmployees">Tiếp tục</v-btn> 
        </v-card-actions>
      </v-card>
    </v-stepper-content>

    <!-- ******************************* STEP3 -->
    <v-stepper-step
        :complete="currentCompleteStep(3)"
        step="3"
      >
        Dữ liệu hình ảnh 
        <small>Hình ảnh tương ứng với từng nhân viên, mỗi nhân viên một ảnh</small>
      </v-stepper-step>
      
    <v-stepper-content step="3">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-file-input multiple outlined label="Dữ liệu hình ảnh" v-model="images" hint="Yêu cầu tên file cần giống với mã số nhân viên"></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-btn x-small @click="getImages" color="orange" dark class="ml-auto">refresh</v-btn>
              <v-data-table :items="imagePreviews" :headers="imageHeaders" fixed-header height="500px">
                <template v-slot:[`item.imageUrl`]="{ item }">
                  <img :src="item.imageUrl" alt="" srcset="" width="200px">
                </template>
              </v-data-table>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-btn color="red" outlined @click="form.step=2">Quay lại</v-btn> 
          <v-btn color="teal darken-4" dark @click="setImages">Tiếp tục</v-btn> 
        </v-card-actions>
      </v-card>
    </v-stepper-content>
    <!-- ******************************* STEP4 -->
    <v-stepper-step
        :complete="currentCompleteStep(4)"
        step="4"
      >
        Nội dung template
        <small>Cấu  hình template</small>
      </v-stepper-step>
      
    <v-stepper-content step="4">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Tên đoạn nội dung" v-model="detail.name"  hide-details outlined persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field label="Thứ tự" v-model="detail.order" hide-details outlined persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select label="Loại" :items="types" v-model="detail.type" hide-details outlined persistent-placeholder></v-select>
            </v-col>
            <v-col cols="6">
              <v-textarea label="Nội dung phía trước" v-model="detail.beforeContent" hide-details outlined persistent-placeholder></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea label="Nội dung phía sau" v-model="detail.afterContent" hide-details outlined persistent-placeholder></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-btn class="mx-auto" color="teal darken-4" @click="postDetail" dark >Tạo mới</v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
              <v-btn @click="getDetail"><v-icon>mdi-refresh</v-icon></v-btn>
              <v-data-table :items="templateDetails" :headers="templateDetailHeaders"  show-select v-model="selectDetail" single-select>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn @click="deleteDetail(item.id)" small outlined> Xóa</v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <h5 class="black--text">Nội dung theo từng nhân viên</h5>
            </v-col>
            <v-col cols="12">
              <v-textarea label="Dán dữ liệu từ excel" hint="hệ thống convert về với kí tự ; phân cách dòng và | phân cách cột" outlined v-model="insertFromClipboard">
              </v-textarea>
               <v-btn class="float-right" small color="primary" outlined @click="updateFromClipboard">Dán vào excel</v-btn>
               <!-- <v-file-input label="File import" v-model="importFile" ></v-file-input>
               <v-btn small color="teal teal-darken-4">Nhập từ excel</v-btn> -->
            </v-col>
            <v-col cols="12">
              <v-data-table :items="templateDetailEmployees" :headers="templateDetailEmployeeHeaders" >
              </v-data-table>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <!-- <v-btn color="red" outlined @click="form.step=2">Quay lại</v-btn>  -->
          <v-spacer></v-spacer>
          <v-btn color="teal darken-4" dark @click="generateContent">Tạo tin</v-btn> 
          <v-btn color="blue darken-4" dark @click="hideContent">Ẩn tin đã tạo</v-btn> 
          <v-btn color="blue darken-4" outlined dark @click="showContent">Hiển thị tin đã tạo</v-btn> 
          <v-btn color="red darken-4" dark @click="deleteContent">Xóa tin đã tạo</v-btn> 
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-stepper-content>
  </v-stepper>
  <confirm-dialog v-model="cfmDialog" :item="id" @confirm="deleteItem"></confirm-dialog>
  </v-card>
</template>

<script>
import ContentTemplateApi from './api'
import ConfirmDialog from '@/components/ConfirmDialog'
export default {
  components:{
    ConfirmDialog
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectEmployeesText: '',
      selectEmployeesArr: '',
      selectEmployees: [],
      employees: [],
      images: [],
      imagePreviews: [],
      imageHeaders: [
        { text: 'ID', value: 'id'},
        { text: 'Mã nhân sự', value: 'employeeCode'},
        { text: 'Hình ảnh', value: 'imageUrl'},
      ],
      
      employeeHeaders:[
        { text: 'Thao tác', value: 'actions'},
        { text: 'Mã nhân sự', value: 'code'},
        { text: 'Tên', value: 'name'},
      ],
      avatar: null,
      attachment: null,
      form: {
        name:'',
        type:'PERSONAL',
        avatar:'',
        note:'',
        step: 1
      },

      templateDetails: [],
      templateDetailHeaders: [
        { text: '', value: 'actions'},
        { text: 'Id Template', value: 'templateId'},
        { text: 'Tên đoạn nội dung', value: 'name'},
        { text: 'Nội dung phía trước', value: 'beforeContent'},
        { text: 'Nội dung phía sau', value: 'afterContent'},
        { text: 'Thứ tự', value: 'order', sortable: false},
        { text: 'Loại nội dung', value: 'type', sortable: false},

      ],

      templateDetailEmployees: [],
      
      templateDetailEmployeeHeaders: [
        { text: 'Id', value: 'id'},
        { text: 'Mã nhân sự', value: 'employeeCode'},
        { text: 'Nội dung', value: 'value'},
      ],

      detail: {
        name: '',
        order: 0,
        type: 'TEXT',
        beforeContent: '',
        afterContent: '',
      },
      types: [
        {text: "Văn bản", value: "TEXT"},
        {text: "Link hình ảnh", value: "IMAGE_LINK"},
      ],
      insertFromClipboard: '',
      selectDetail:[],
      cfmDialog: false
    }
  },
  computed:{
    filteredEmployees () {
      return this.employees.filter(x => this.selectEmployeesArr.includes(x.code))
    }
  },
  watch: {
    selectDetail() {
      console.log(this.selectDetail)
      if(this.selectDetail){
        if(this.selectDetail.length>0) {
          let item = this.selectDetail[0]
          this.getDetailEmployee(item.id)
        }
      }
    },
    'insertFromClipboard': {
      
        handler(oldVal, newVal) {
          this.insertFromClipboard = this.insertFromClipboard.replace(/\t/g, '|')
          
          this.insertFromClipboard = this.insertFromClipboard.replace(/\n/g, ';')
        }
    },

    selectEmployeesText() {
      this.selectEmployeesText = this.selectEmployeesText.replace(/\n/g, ';')
      this.selectEmployeesArr = this.selectEmployeesText.split(';')
      console.log(this.selectEmployeesText)
    },
    
    id: {
        handler(oldVal, newVal) {
        console.log(oldVal, newVal)
        if(this.id){
          return ContentTemplateApi.get(this.id).then(d => {
            this.form.name = d.name,
            this.form.type = d.type,
            this.form.avatar = d.avatar,
            this.form.note = d.note,
            this.form.step = d.step
          })
        }
      },
      immediate: true
    }
  },
  methods:{
    currentCompleteStep(step) {
      return this.form.step <step
    },

    getEmployees() {
      return ContentTemplateApi.fetchEmployees().then(d => {
        this.employees = d
      })
    },

    resetForm() {
      this.form.name = ''
      this.form.type = ''
      this.form.avatar = ''
      this.form.attachment = ''
      this.form.note = ''
      this.form.step = 1
      
    },
    closeDialog() {
      this.$emit("close")
      this.resetForm()
    },
    
    submitData() {
      var form = new FormData()
      form.append('name', this.form.name)
      form.append('type', this.form.type)
      form.append('avatarFormFile', this.avatar)
      form.append('attachmentFormFile', this.attachment)
      form.append('note', this.form.note)
      form.append('step', this.form.step)
      return ContentTemplateApi.post(form).then((d) => {
        console.log('new id',d, d.data.id)
        this.$emit("set-id", d.data.id)
      }).then(() => {
        this.form.step = 2
      })
    },
    deleteItem() {
      return ContentTemplateApi.delete(this.id).then(()=> {
        this.closeDialog()
      })
    },
    setEmployees() {
      return ContentTemplateApi.setEmployees(this.selectEmployeesArr, this.id).then(() => {
        this.form.step = 3
      })
    },
    setImages() {
      return ContentTemplateApi.setImages(this.images, this.id).then(()=> {
        this.form.step = 4
      })
    },
    getImages() {
      return ContentTemplateApi.getImages(this.id).then(d => {
        this.imagePreviews = d.data
      })
    },

    postDetail() {
      return ContentTemplateApi.postTemplateDetail(this.detail, this.id)
    },

    getDetail() {
      return ContentTemplateApi.getTemplateDetail(this.id).then(d => { 
        this.templateDetails = d.data
      })
    },
    updateFromClipboard() {
      if(this.selectDetail){
        if(this.selectDetail.length>0) {
          let item = this.selectDetail[0]
          return ContentTemplateApi.updateFromClipboard(item.id, this.insertFromClipboard).then(() => { 
            this.getDetailEmployee(item.id)
          })
        }
      }
    },

    deleteDetail(id) {
      return ContentTemplateApi.deleteTemplateDetail(id).then(() => { 
        this.getDetail()
      })
    },

    getDetailEmployee(id) {
      
      return ContentTemplateApi.getTemplateDetailEmployee(id).then(d => { 
        this.templateDetailEmployees = d.data
      })
    },

    generateContent() {
      return ContentTemplateApi.generateContent(this.id).then(d => {
        console.log(d)
      })
    },
    hideContent() {
      return ContentTemplateApi.hideContent(this.id).then(d => {
        console.log(d)
      })
    },
    
    showContent() {
      return ContentTemplateApi.showContent(this.id).then(d => {
        console.log(d)
      })
    },

    deleteContent() {
      return ContentTemplateApi.deleteContent(this.id).then(d => {
        console.log(d)
      })
    }
  },

  mounted() {
    this.getEmployees()
    if(this.id >0 ) {
      this.getDetail()
    }
  }
}
</script>