import http from "@/components/services/http-common"

const TOKEN_URL = "kiemke/checkrolekiemke"
const GET_PHIEU_URL = "dutruhang/danhsachdutruhang"


const SET_TINHTRANG_URL = "dutruhang/capnhattinhtrangdathang"

const GET_HANG_HOA_URL = "kiemke/danhsachhanghoa"
const GET_HANG_HOA_UNIT_URL = "dutruhang/getdonvitinhhanghoa"

const POST_DATHANG_URL = "dutruhang/themdutruhanghoa"
const DELETE_PHIEU_URL = "dutruhang/1"
const ASM_CONFIRM_URL = "dutruhang/capnhattinhtrangdathang"

var TOKEN = ""

export default class Api {

    getToDepartments = () => http.get("employeeReports/fetch-to-department").then(res => res.data)

    getFromDepartments = () => http.get("departments/v2/fetch?type=CN").then(res => res.data)
    
    getCategories = (departmentCode) => http.get("employeeReports/fetch-category", {
        params: {
            departmentCode: departmentCode
        }
    }).then(res => res.data)

    uploadFile = (files) => {
        var formData = new FormData();

        files.forEach((value, index) => {
            formData.append(`data`, value);
        });
        console.log(formData, files)
        return http.post("employeeReports/upload/files", formData).then(r => r.data)
    }

    post = (request) => http.post('employeeReports',request)

    getItem = (id) => http.get(`employeeReports/${id}`).then(res=>res.data)

    getItemDetail = (id) => http.get(`employeeReports/detail/${id}`).then(res=>res.data)
    
    get = (month) => http.get('employeeReports/local', {
        params: {
            month: month
        }
    }).then(res => res.data.data)

    delete = (id) =>  http.delete(`employeeReports/${id}`)
}
