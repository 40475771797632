<template>
  <div>
    <div style="min-height: calc(100vh - 300px); widows: 100%;" class="d-flex align-center mb-6">
        <v-card width="400px" class="mx-auto elevation-3 rounded-xl" :loading="loading">
          <router-link class="text-primary text-right px-4 py-4 d-block" text :to="{name: 'login'}" style="width: 100%;">
              Quay về trang chủ</router-link>
          <!-- <a href="" style="width: 100%;" class="text-right px-4 py-4 d-block">Quay về trang chủ</a> -->
          <img src="/static/icons/forget_pw.png" class="mx-auto d-block mt-2" width="128">
          <v-card-title primary-title class="text-center">
            Quên Mật khẩu
          </v-card-title>
          <v-card-subtitle>
            Nhập thông tin sau để lấy lại mật khẩu qua email.
          </v-card-subtitle>
          <v-card-text>
            <v-form v-model="formValid" id="forgetPwForm" ref="forgetPwForm" @submit.prevent="getPasswordByMail">
              <v-row>
                <v-col>
                  <v-text-field outlined hint="Nhập SĐT hoặc tên tài khoản" :rules="[v=>!!v||'Hãy nhập sdt, username của bạn']"
                   label="Nhập tên người dùng" hide-details="auto" persistent-placeholder v-model="username" persistent-hint></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions  class="justify-center">
            <v-btn color="primary" type="submit" form="forgetPwForm">Lấy mật khẩu mới</v-btn>
          </v-card-actions>
        </v-card>
    </div>
  </div>
</template>

<script>
import Api from './api.js'

export default {
  apiService: null,
  created() {
    this.apiService = new Api();
  },
  data() {
    return {
      username: "",
      loading: false,
      formValid: true,
    }
  },

  methods: {
    getPasswordByMail() {
      this.$refs.forgetPwForm.validate()
      if(this.formValid) {
        this.loading = true
        return this.apiService.forgetPassword(this.username).then(d => {
            console.log(d)
          }).finally(()=>{
            this.loading = false
          })
      }
    }
  }
}
</script>