import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr'
 
export default {
    // install (Vue) {
    //   const connection = new HubConnectionBuilder()
    //     .withUrl(process.env.VUE_APP_BASE_URL + 'notification-hub', {
    //         skipNegotiation: true,
    //         transport: HttpTransportType.WebSockets
    //     })
    //     .configureLogging(LogLevel.Information)
    //     .build()
   
    //     let startedPromise = null
    //     function start () {
    //     startedPromise = connection.start().catch(err => {
    //         console.error('Failed to connect with hub', err)
    //         return new Promise((resolve, reject) => 
    //         setTimeout(() => start().then(resolve).catch(reject), 5000))
    //     })
    //     return startedPromise
    //     }
    //     connection.onclose(() => start())
        
    //     start();

        
    //     // use new Vue instance as an event bus
    //     const notificationHub = new Vue() 
    //     // every component will use this.$questionHub to access the event bus
    //     Vue.prototype.$notificationHub = notificationHub
    //     // Forward server side SignalR events through $questionHub, where components will listen to them
    //     connection.on('HasNotification', (questionId, score) => {
    //         notificationHub.$emit('hasNotification', { questionId, score });
    //     })

    //     notificationHub.notificationSubscribe = (questionId) => {
    //         return startedPromise
    //           .then(() => connection.invoke('JoinNotificationGroup', questionId))
    //           .catch(console.error)
    //     }
        
    //     notificationHub.notificationUnSubscribe = (questionId) => {
    //         console.log(questionId);
    //         return startedPromise
    //           .then(() => connection.invoke('LeaveNotificationGroup', questionId))
    //           .catch(console.error)
    //     }
    // }
}