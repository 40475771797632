import CreateDialog from '../Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        }
    },
    computed: {
        types() {
            let typeItems = this.items.map(x=>x.typeSupportName)
            let types = [...new Set(typeItems)]
            return types
        }
    },
    data () {
        return {
            month: moment().startOf("months").format("yyyy-MM"),
            dialog: false,
            item: null,
            items:[],
            search:"",
            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text:"Mã", value: "code", width:"10rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Loại", value: "typeSupportName", width:"9rem",
                    filter: value => {
                        if (!this.filterTypeName) return true
            
                        return value.toUpperCase().includes(this.filterTypeName.toUpperCase())
                    },
                },
                {text:"Lý do", value: "titleSender", width:"9rem"},
                {text:"Mã NS người gởi", value: "senderCode", width:"9rem",
                    filter: value => {
                        if (!this.filterSenderCode) return true
            
                        return value.toUpperCase().includes(this.filterSenderCode.toUpperCase())
                    },
                },
                {text:"Người gởi", value: "senderName", width:"10rem",
                    filter: value => {
                        if (!this.filterSender) return true
            
                        return value.toUpperCase().includes(this.filterSender.toUpperCase())
                    },
                },
                {text:"Người nhận", value: "receiveName", width:"10rem", 
                    filter: value => {
                        if (!this.filterReceiver) return true
            
                        return value.toUpperCase().includes(this.filterReceiver.toUpperCase())
                    },
                },
                {text:"Người hoàn thành", value: "completeName", width:"10rem", 
                    filter: value => {
                        if (!this.filterCompleter) return true
            
                        return value.toUpperCase().includes(this.filterCompleter.toUpperCase())
                    },
                },
                {text:"Phòng ban gởi", value: "departmentSenderName", width:"10rem",
                    filter: value => {
                        if (!this.filterSenderDept) return true
            
                        return value.toUpperCase().includes(this.filterSenderDept.toUpperCase())
                    },
                },
                {text:"Phòng ban nhận", value: "departmentReceiveName", width:"10rem",
                    filter: value => {
                        if (!this.filterReceiveDept) return true
            
                        return value.toUpperCase().includes(this.filterReceiveDept.toUpperCase())
                    },
                },
                {text:"Ngày gởi", value: "sendDateStr",width:"5rem"},
                {text:"Đánh giá", value: "evaluate", width:"5rem"},
                {text:"Trạng thái", value: "supportStatusName", 
                    filter: value => {
                        if (!this.filterStatus) return true
            
                        return value.includes(this.filterStatus)
                    },
                },
            ],

            statusColor : {
                "Tạo mới":"green",
                "Tiếp nhận":"orange",
                "Từ chối":"red",
                "Hoàn thành":"cyan",
            },
            // *** Filter ***
            filterTypeName:"",
            filterStatus:"",
            filterCode:"",
            filterSenderCode:"",
            filterSender:"",
            filterReceiver:"",
            filterCompleter:"",
            filterSenderDept:"",
            filterReceiveDept:"",

            dialogDelete: false,
            targetItem: null,
        }
    },
    methods: {
        blockClick(event)
        {
            event.stopPropagation()
        },
        
        fetchData() {
            return http.get("salarySupports", {
                params: {
                    month: this.month
                }
            }).then(res => {
                this.items = res.data;
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `supports/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
        
        exportExcel(){
            return http.get("salarySupports/export", {
                params: {
                    month: moment(this.month).format('YYYY-MM-DD')
                },
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH SACH_PHIEU_HT_${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },

    },
    mounted () {
        this.fetchData();
    }
}