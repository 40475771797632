<template>
  <v-dialog
    :value="dialog"
    max-width="600px"
    transition="dialog-transition"
    @click:outside="$emit('close-cfm-dialog')"
  >
    <v-card>
      <v-card-title>
        Bạn có muốn tiếp tục ?
      </v-card-title>
      <v-card-subtitle v-if="!!item"><b>Nhân viên:</b> {{item.employeeName}} - <i>{{item.userCode}}</i></v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select label="Lý Do" :items="[
              {text: 'Đổi thiết bị', value: 'DOI_THIET_BI'},
              {text: 'Khác', value: 'KHAC'}
            ]" v-model="reason"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="$emit('close-cfm-dialog')">Thoát</v-btn>
        <v-btn color="success" @click="resetDevice">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UserService from '../../services/UserService'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // dialog: true
      reason: 'DOI_THIET_BI'
    }
  },
  methods: {
    resetDevice() {
      this.userService.resetDevice(this.item.userCode, this.reason).then(() => {
        this.reason = 'DOI_THIET_BI'
        this.$emit('close-cfm-dialog')
      })
    }
  },
  userService: null,
  created() {
    this.userService = new UserService()
  }
}
</script>