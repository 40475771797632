const googleQRURL = "https://chart.googleapis.com/chart?";
const quickchartQRURL = "https://quickchart.io/qr?";
import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item"],
    watch:{
        value: {
            handler(val){
                if(val)
                {
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        },
        "form.name":{
            handler(val){
                this.form.params = this.convertToSlug(val);
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            endpoint: `https://private-label.trungsonpharma.com/`,
            qrSrc: "",
            valid: false,
            form: {
                code: "",
                name: "",
                qr : "",
                redirectTo: "",
                visit: 0,
                params: "",
            },
            defaultForm: {
                code: "",
                name: "",
                qr : "",
                redirectTo: "",
                visit: 0,
                params: "",
            },
        }
    }, 
    methods: {
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
        },

        save() {
            if(this.valid)
            {
                if(this.isEdit)
                {
                    var url = `qrLinks/${this.item.code}`;
                    return http.put(url, this.form).then(res => {
                        this.$emit("fetchData");
                    });
                }else {
                    return http.post("qrLinks", this.form).then(res => {
                        this.$emit("fetchData");
                        this.form = Object.assign({}, res.data.data);
                        this.$emit("setSelect", this.form.code);
                    });
                }
            }
        },
        fetch() {
            let url = `qrLinks/${this.item.code}`;
            return http.get(url).then(res => {
                this.form = Object.assign({}, res.data.data);
            })
        },

        //SERVICES
        isUrlValid(userInput) {
            var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if(res == null)
                return false;
            else
                return true;
        },
        convertToSlug(title) { 
            //Đổi chữ hoa thành chữ thường
            let slug = title.toLowerCase();
        
            //Đổi ký tự có dấu thành không dấu
            slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
            slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
            slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
            slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
            slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
            slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
            slug = slug.replace(/đ/gi, 'd');
            //Xóa các ký tự đặt biệt
            slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
            //Đổi khoảng trắng thành ký tự gạch ngang
            slug = slug.replace(/ /gi, "-");
            //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
            //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
            slug = slug.replace(/\-\-\-\-\-/gi, '-');
            slug = slug.replace(/\-\-\-\-/gi, '-');
            slug = slug.replace(/\-\-\-/gi, '-');
            slug = slug.replace(/\-\-/gi, '-');
            //Xóa các ký tự gạch ngang ở đầu và cuối
            slug = '@' + slug + '@';
            slug = slug.replace(/\@\-|\-\@|\@/gi, '');
            return slug;
        },
        googleQr (body)
        {
            let paramObj = {
                chs: "150x150",
                cht: "qr",
                chl: body,
                choe: "UTF-8"
            }
            var params = new URLSearchParams(paramObj).toString();
            return `${googleQRURL}${params}`
        },
        quickchartQr(body) {
            let paramObj = {
                text: body
            }
            var params = new URLSearchParams(paramObj).toString();
            return `${quickchartQRURL}${params}`
        },
        getBase64Image(url, callback) {
            var result = "";
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const dataURL = canvas.toDataURL("image/png");
              callback(dataURL);
            }
            img.setAttribute('crossOrigin', 'anonymous'); 
            img.src = url;
        },
        generateQr(){
            let directUrl = `${this.endpoint}redirect/${this.form.code}`
            let qrLink = this.quickchartQr(directUrl);
            var form = this.form;
            this.getBase64Image(qrLink, function(base64image){
                console.log(base64image);
                form.qr = base64image;
           });
        }
    }
}