const googleQRURL = "https://chart.googleapis.com/chart?";
import http from '@/components/services/http-common';
import moment from 'moment/moment';
const FETCH_DEPARTMENT_URL = ""
export default {
    props:["value", "item"],
    watch:{
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                    {
                        this.fetch().then(()=> {
                            this.fetchDetail();
                        });
                        this.headerCreated = true;
                    }
                }
                else{
                    this.close();
                }
            }
        },
        "form.name":{
            handler(val){
                this.form.params = this.convertToSlug(val);
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
    },
    data() {
        return {
            endpoint: `https://private-label.trungsonpharma.com/`,
            qrSrc: "",
            valid: false,
            form: {
                code: "",
                departmentCode: "",
            },
            defaultForm: {
                code: "",
                departmentCode: "",
            },

            /**header */
            departments: [],
            /** Detail Header*/
            detailHeaders: [
                {text:"Tên dụng cụ", value: "property", width:"7rem"},
                {text:"Giá cả", value: "price", width:"7rem"},
                {text:"Số lượng", value: "quantity", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "actualQuantity", width:"7rem"},
                {text:"Tông giá", value: "itemTotal", width:"7rem"},
            ],
            validStatus: false,
            status: -1,
            properties:[],
            detail:{
                propertyCode:undefined,
                actualQuantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                actualQuantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            detailItems:[],

            /**Stepper params */
            headerCreated: false,
        }
    }, 
    methods: {
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.$refs.form.resetValidation();
        },

        updateStatus() {
            if(this.valid)
            {
                if(this.isEdit)
                {
                    var url = `propertyRequests/update-status/${this.item.code}`;
                    var obj = {
                        status: this.status
                    };
                    return http.put(url, obj).then(res => {
                        this.$emit("fetchData");
                        this.form = Object.assign({}, res.data.data);
                    });
                }else {
                    return http.post("propertyRequests", this.form).then(res => {
                        this.$emit("fetchData");
                        this.headerCreated = true;
                        this.form = Object.assign({}, res.data.data);
                    });
                }
            }
        },
        fetch() {
            let url = `propertyRequests/${this.item.code}`;
            return http.get(url).then(res => {
                this.form = Object.assign({}, res.data.data);
                this.status =this.form.status.toString();
            })
        },
        fetchDepartment()
        {
            return http.get("propertyRequests/get-departments").then(res => {
                this.departments = res.data;
            });
        },
        fetchProperty()
        {
            return http.get("properties").then(res => {
                this.properties = res.data.items;
            });
        },
        fetchDetail() {
            let url = `propertyRequests/detail/${this.form.code}`
            return http.get(url).then(res => {
                this.detailItems = res.data;
            })
        },
        createDetail() {
            let url = `propertyRequests/detail/${this.form.code}`
            return http.post(url, {
                propertyCode: this.detail.propertyCode,
                quantity: this.detail.quantity,
            }).then(res => {
                var item = {
                    propertyCode: res.data.propertyCode,
                    property: res.data.property,
                    quantity: res.data.quantity
                };
                this.detail=Object({}, this.defaultDetail);
                this.detailItems.push(item);
            });
        },
        deleteDetail(item) {
            let url = `propertyRequests/detail/${this.form.code}`;
            return http.delete(url, {
                data:{
                    propertyCode: item.propertyCode,
                }
            }).then(res => {
                this.detailItems = this.detailItems.filter(x=>x.propertyCode != res.data.data.propertyCode)
            })
        },
        
        exportExcel() {
            let url = `propertyRequests/excel/${this.form.code}`;
            return http.get(url, {
                params:this.filters,
                responseType: 'arraybuffer',
            }).then(res=>{
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'excel.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },

        //SERVICES
    },
    mounted() {
        this.fetchDepartment();
        this.fetchProperty();
    }
}