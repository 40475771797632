<template>
  <div>
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
    
    <v-row>
        <v-col cols="12" md="9">
          <v-card :loading="isLoading">
              <v-card-title>Nhập thông tin phiếu yêu cầu tuyển dụng</v-card-title>

              <v-card-text class="text--primary">
                    <v-form ref="recruitment_request_form" id="recruitment_request_form" v-model="isValid" @submit.prevent="save">
                      
                      <span class="green--text text--darken-4">THÔNG TIN ỨNG TUYỂN</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Công việc ứng tuyển
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined :loading="jobLoading" hide-details="auto" :items="jobs" v-model="form.jobCode"
                           :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"
                           @change="getJobDescription"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Số lượng ứng tuyển
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" type="number" v-model="form.amount" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Ngày nhận việc dự kiến
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.expectCompleteDate" type="date" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                      </v-row>
                      
                      <div class="d-flex" style="width:100%">
                        <span class="green--text text--darken-4">MÔ TẢ CÔNG VIỆC</span> <v-btn small class="ml-auto" color="teal darken-4" @click="jobDescriptionShow = !jobDescriptionShow" plain> Ẩn/Hiện </v-btn>
                      </div>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center" >
                          <span v-show="jobDescriptionShow==true">Nội dung</span>
                        </v-col>
                        <v-col cols="12" md="6"  v-show="jobDescriptionShow==true">
                          <span v-html="jobDescription"  v-if="!!jobDescription"></span>
                          <span class="error--text" v-else> Chưa có mô tả công việc, hãy cập nhật mô tả công việc cho dữ liệu này </span>
                        </v-col>
                        <!-- <v-col cols="12" md="6" v-else >
                          <span class="error--text"> Chưa có mô tả công việc, hãy cập nhật mô tả công việc cho dữ liệu này </span>
                        </v-col> -->
                      </v-row>
                      
                      <span class="green--text text--darken-4">LÝ DO TUYỂN DỤNG</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Tuyển thay thế 
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" :items="recruitTypes" v-model="form.typeReplacement" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Tuyển thêm
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" :items="recruitTypes" v-model="form.typeAddition" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Tuyển vị trí mới
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" :items="recruitTypes" v-model="form.typeNew" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Diễn giải
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea outlined hide-details="auto" v-model="form.description" rows="3"></v-textarea>
                        </v-col>
                      </v-row>
                      
                      <span class="green--text text--darken-4">YÊU CẦU ỨNG VIÊN</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Giới tính
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined multiple hide-details="auto" :items="genders" v-model="form.gender" :rules="[v=> v!=null || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Độ tuổi
                        </v-col>
                        <v-col cols="12" md="3">
                          Từ 
                          <v-text-field outlined hide-details="auto" type="number" v-model="form.ageFrom" min="18"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          Đến
                          <v-text-field outlined hide-details="auto" type="number" v-model="form.ageTo"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Kinh nghiệp làm việc
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined multiple chips deletable-chips hide-details="auto" :items="workExps" v-model="form.workExp" 
                          item-text="value" item-value="key"
                          :rules="[v=> v!=null || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Ngoại hình
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined multiple hide-details="auto" 
                          :items="appearances" item-text="value" item-value="key" v-model="form.appearance" 
                          :rules="[v=> v!=null || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Trình độ học vấn
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined multiple hide-details="auto" 
                          :items="educationLevels" item-value="code" item-text="name" 
                          v-model="form.educationLevel" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Chuyên ngành
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete multiple outlined hide-details="auto" chips deletable-chips
                           :items="educationMajors" :search-input.sync="educationMajorSearch" item-value="code" item-text="name"
                           v-model="form.educationMajor" :rules="[
                            v=> !!v || 'Bạn hãy nhập dữ liệu này',
                            v=> v.length > 0 || 'Chọn ít nhất 1 chuyên ngành',
                            v=> v.length<=3 || 'Chọn 3 chuyên ngành'
                          ]"
                          @change="educationMajorSearch=''"></v-autocomplete >
                        </v-col>
                                                
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Trình độ ngoại ngữ
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" :items="recruitTypes" 
                          v-model="form.languageSkill"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Trình độ tin học
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" :items="recruitTypes" v-model="form.computerSkill"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Kĩ năng
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" :items="recruitTypes" v-model="form.otherSkill"></v-text-field>
                        </v-col>
                        
                      </v-row>
                      
                      <span class="green--text text--darken-4">LƯƠNG VÀ HỢP ĐỒNG</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Mức lương đề nghị
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.suggestSalary"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Loại hợp đồng
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" 
                            :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"
                            :items="contractTypes" item-text="value" item-value="key" v-model="form.contractType" ></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Quyền lợi khác
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea rows="3" outlined hide-details="auto" v-model="form.otherBenefit"></v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
              </v-card-actions>
              
              <v-fab-transition v-if="!isEdit" >
                <v-btn style="bottom: 20px;"
                  color="success"
                  fab
                  dark
                  large
                  fixed
                  bottom
                  right offset
                  type="submit" form="recruitment_request_form"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-fab-transition>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn block type="submit" color="success" form="recruitment_request_form" style="width: 200px;" v-if="!isEdit" class="my-2">Tạo phiếu</v-btn>
          <v-btn block outlined type="button" color="error" style="width: 200px;" class="my-2" @click="$router.push({name: 'RecruitmentRequest', query: {
            returnPage: $route.query.returnPage
          }})">Quay lại danh sách </v-btn>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import RecruitmentRequestIndexApi from './api.js'

import router from "@/router/index";
export default {
  computed: {
    isEdit() {

      return !!this.$route.params.id
    },

    currentId() {
      if(this.$route.params.id){
        return this.$route.params.id
      } else 
        return null
    },

    breadcrumbs() {
      return [
        {
            text: 'Phiếu yêu cầu tuyển dụng',
            to: {name: 'RecruitmentRequest'},
            exact: true
        },
        {
          text: this.isEdit ? 'Chỉnh sửa '+this.$route.params.id : 'Tạo mới',
          to: {name: this.isEdit ? 'RecruitmentRequestEdit' : 'RecruitmentRequestCreate'}
        },
      ]
    }
  },

  data() {
    return {
      isLoading: false,

      jobs: [],
      contractTypes: [],
      recruitTypes: [
        {text: 'Trong & ngoài kế hoạch', value: 'BOTH'},
        {text: 'Trong kế hoạch', value: 'INPLAN'},
        {text: 'Ngoài kế hoạch', value: 'OFFPLAN'},
        {text: 'Không có', value: 'NONE'},
      ],
      genders: [
        {text: "Nam", value: 1},
        {text: "Nữ", value: 0},
      ],
      workExps: [],
      appearances: [],
      educationLevels: [],

      educationMajorSearch: '',
      educationMajors: [],

      isValid: true,
      jobDescription: null,
      jobDescriptionShow: true,
      jobLoading:  false,
      form: {
        jobCode: '',
        amount: null,
        expectCompleteDate: null,
        typeReplacement: 'NONE',
        typeAddition: 'NONE',
        typeNew: 'NONE',
        description: '',
        
        suggestSalary: '',
        contractType: '',
        otherBenefit: '',

        //yeu cau ứng viên
        gender: '',
        ageFrom: 18,
        ageTo: 18,
        workExp: [],
        appearance: [],
        educationLevel: [],
        educationMajor: [],
        languageSkill: '',
        computerSkill: '',
        otherSkill: ''
      }
    }
  },
  methods: {
    save() {
      let thisRouter = router
      this.$refs.recruitment_request_form.validate()
      if(this.isValid) {
        if(!this.isEdit){
          return RecruitmentRequestIndexApi.post(this.form).then(() => {
            thisRouter.push({ name: 'RecruitmentRequest' })
          }).catch(e => {
            alert("error")
          })
        }
      }
    },

    getData() {
      this.isLoading = true

      return RecruitmentRequestIndexApi.get(this.currentId).then(d => {
        console.log(d.data)
        this.form = Object.assign({}, d.data)
        Object.assign(this.form, d.requirement)
        return this.getJobDescription(this.form.jobCode)
      }).finally(() => {
        this.isLoading = false
      })
    },

    getJobDescription(val) {
      this.jobLoading = true
      RecruitmentRequestIndexApi.getJobDescription(val).then(x=> {
        this.jobDescription = x.description
      }).finally(()=> {
        this.jobLoading = false
      })
    }
  },
  created() {
    RecruitmentRequestIndexApi.getDropDown().then(d => {
      this.contractTypes = d.contractTypes
      this.workExps = d.workExps
      this.appearances = d.appearances
      this.educationLevels = d.educationLevels
      this.educationMajors = d.educationMajors
    })
    
    RecruitmentRequestIndexApi.getJob().then(r => {
      this.jobs = r
    })
  },

  mounted() {
    if(this.isEdit) {
      this.getData()
    }
  }
}
</script>