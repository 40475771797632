<template>
    <div class="leave-index" >
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog> -->

        <v-card v-if="true" class="mb-5">
            <v-card-title>
                {{template== null ? '' : template.name}}
                <v-spacer></v-spacer>
                <!-- <v-btn small color="teal darken-4" dark @click="dialog.state=true">
                    <v-icon small left>mdi-upload</v-icon> Upload excel
                </v-btn> -->
                <!-- <create-button @click="dialog.state=true" ></create-button> -->
                <v-divider vertical class="mx-2"></v-divider>
                <!-- <v-btn outlined color="success" class="mx-2" @click="postAnswers">Lưu câu trả lời</v-btn> -->
            </v-card-title>
            <v-card-text>
              <v-list subheader tile flat dense>
                <v-list-item>
                  <v-list-item-content>
                    <b>Nội dung:</b> Khảo sát về đánh giá các cầu hỏi abc, sdhsdsmc,
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <b>Tạo vào:</b> 05/06/2023
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
        </v-card>
        
        <v-card>
          <v-card-text class="body-2">
            <v-row>
              <v-col cols="12" class="" v-for="questionObj in questions" :key="questionObj.id">
                <v-card flat class="mx-auto">
                  <v-card-title style="word-break: break-word;">
                    {{questionObj.question.name}}
                  </v-card-title>
                  <v-card-text class="text--primary">
                    <v-radio-group v-if="questionObj.question.type === 'OPTION'" v-model="answerValues[questionObj.question.id]" hide-details class="mt-0">
                      <v-radio color="orange" v-for="answer in questionObj.answers" :key="answer.id" :label="answer.name" :value="answer.id"></v-radio>
                    </v-radio-group>
                    
                    <!-- v-model="answerValues[questionObj.question.id]" -->
                    <div v-if="questionObj.question.type === 'MULTI_OPTION'" hide-details class="mt-0">
                      <v-checkbox multiple color="orange" v-for="answer in questionObj.answers" :key="answer.id" :label="answer.name" :value="answer.id.toString()" v-model="answerValues[questionObj.question.id]"></v-checkbox>
                    </div>
                    
                    <v-textarea hide-details outlined v-if="questionObj.question.type === 'TEXT'" placeholder="Nhập câu trả lời" persistent-placeholder
                    v-model="answerValues[questionObj.question.id]"
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="success" @click="postAnswers">Lưu câu trả lời</v-btn>
          </v-card-actions>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import api from './api.js';
// import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Leave',
    components: {
        CreateButton,
        // "create-dialog": Create,
        "data-filter": DataFilter
    },
    computed: {
      templateId() {
        return this.$route.params.id;
      }
    },
    data() {
      return {
        globalSearch:'',
        template: null,
        questions: [],
        answerValues: {},

        dialogDelete: false,
        cfmDeleteItem: undefined
      }
    },
    methods: {

      getTemplateDetail() {
        return this.apiServices.getTemplate(this.templateId).then(d => {
          this.template = d.data
          return this.apiServices.getQuestions(this.templateId).then(d => {
            this.questions = d.data
          }).then(() => {
            this.apiServices.getTemplateAnswers(this.templateId).then(d => {
              let data = d.data
              let empAnswers = data.surveyEmployeeAnswers;
              this.answerValues = empAnswers.reduce((obj, item) => {
                switch(item.type){
                  case "TEXT": {
                    obj[item.surveyQuestionId] = item.textAnswer
                    break;
                  }
                  case "OPTION": {
                    obj[item.surveyQuestionId] = item.surveyAnswerId
                    break;
                  }
                  default: {
                    if(!Array.isArray(obj[item.surveyQuestionId]))
                      obj[item.surveyQuestionId] = []
                    obj[item.surveyQuestionId].push(item.surveyAnswerId.toString())
                    break;
                  }
                }
                // obj[item.surveyQuestionId] = item.type == "TEXT" ? item.textAnswer : (item.type == "OPTION" ? item.surveyAnswerId : item.multiSurveyAnswerIds.split(","))
                console.log(obj)
                return obj
              }, {})
              console.log(this.answerValues)
            })
          })
        })
      },

      initAnswers() {
        return this.apiServices.initAnswers(this.templateId)
      },
      postAnswers(){
        //convert to string
        for (const key in this.answerValues) {
          let value = this.answerValues[key]
          if(Array.isArray(value)){
            this.answerValues[key] = value.join(",")
          }
        }
        return this.apiServices.postAnswers(this.templateId, {
          QuestionAnswerDict: this.answerValues
        }).then(() => this.getTemplateDetail())
      }
    },
    apiServices: null,
    created() {
      this.apiServices = new api();
        // this.fetchData();
    },
    mounted() {
      this.getTemplateDetail()
      this.initAnswers()
    }
}
</script>