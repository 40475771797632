import { render, staticRenderFns } from "./Contractv2Tab.vue?vue&type=template&id=0674dd00&scoped=true&"
import script from "./Contractv2Tab.vue?vue&type=script&lang=js&"
export * from "./Contractv2Tab.vue?vue&type=script&lang=js&"
import style0 from "./Contractv2Tab.vue?vue&type=style&index=0&id=0674dd00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0674dd00",
  null
  
)

export default component.exports