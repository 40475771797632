import http from "./http-common";

class UploadFilesService {
  upload(files, onUploadProgress, url = "users/facedata") {
    let formData = new FormData();
    console.log("files", files);
    files.forEach(file => {
      formData.append("Files", file);
    });

    return http.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  }

  getFiles() {
    return http.get("users/face-images");
  }
  
  uploadFace(files, onUploadProgress, mode = 0) {
    let formData = new FormData();
    console.log("files", files);
    files.forEach(file => {
      formData.append("Files", file);
    });
    if(mode!=0)
      formData.append("mode", 1);

    return http.post("users/facedata", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  }
}

export default new UploadFilesService();