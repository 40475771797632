<template>
    <div>
        <v-card class="rounded-lg mb-5">
            <v-card-title>
                <v-icon>mdi-refresh</v-icon>
                <v-spacer></v-spacer>
                <v-chip class="mx-2" color="teal darken-4" dark>Hôm nay</v-chip>
                <v-chip class="mx-2" color="teal darken-4" dark>Đầu tháng</v-chip>
                <v-chip class="mx-2" color="teal darken-4" dark>Cuối tháng</v-chip>
                <v-text-field type="date" style="max-width: 200px" outlined hide-details dense ></v-text-field>
            </v-card-title>
        </v-card>
        
        <v-row class="mb-6">
            <v-col cols="12" md="6" >
                <v-card class="rounded-xl" >
                    <v-card-title>Số lượng nhân viên theo chức danh</v-card-title>
                    <v-card-text class="text-center" style="height: 480px;">
                        <div style="" class="mx-auto my-auto">
                            <chart ref="chart" :chartData="employeeSummaryData" chartType="bar" legendPos="bottom" :showLabel="true" >
                            </chart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" md="3" class=" d-flex flex-column lighten-5 " style="margin-top: -6px; margin-bottom: -6px; ">
                <v-card color="blue" class="flex-grow-1 my-2 rounded-xl" outlined flat style="border-color: green">
                    <v-card-title primary-title class="white--text">
                        TỔNG SỐ 
                        <v-spacer></v-spacer>
                        <v-avatar size="64" color="blue lighten-2" >
                            <v-icon color="white">
                                mdi-account-tie
                            </v-icon>
                        </v-avatar>
                    </v-card-title>
                    
                    <div class="pa-4 ">
                        <h5 class="white--text font-weight-bold display-2 text-center">{{totalEmp}}</h5>
                    </div>
                    <v-card-subtitle class="white--text">
                        Tất cả các nhân viên đang hoạt động
                    </v-card-subtitle>
                </v-card>
                <v-card style="" color="" class="flex-grow-1 my-2 rounded-xl">
                    <v-card-title primary-title class="">
                        NHÂN VIÊN MỚI
                        <v-spacer></v-spacer>
                        <v-avatar size="64" color="green lighten-4" >
                            <v-icon color="green">
                                mdi-chart-bar
                            </v-icon>
                        </v-avatar>
                    </v-card-title>
                    
                    <div class="pa-4 ">
                        <h5 class=" font-weight-bold display-2 text-center">{{totalNewEmp}}</h5>
                    </div>
                    <v-card-subtitle>
                        Nhân viên mới nhân việc trong tháng
                    </v-card-subtitle>
                </v-card>
            </v-col>
            
            <v-col cols="6" md="3" class=" d-flex flex-column lighten-5" style="margin-top: -6px; margin-bottom: -6px">
                <v-card style="" color="" class="flex-grow-1 my-2 rounded-xl">
                    <v-card-title primary-title class="">
                        SỐ STORE
                        <v-spacer></v-spacer>
                        <v-avatar size="64" color="yellow lighten-4" >
                            <v-icon color="yellow darken-2">
                                mdi-storefront
                            </v-icon>
                        </v-avatar>
                    </v-card-title>
                    
                    <div class="pa-4 ">
                        <h5 class=" font-weight-bold display-2 text-center">{{storeNo}}</h5>
                    </div>
                    <v-card-subtitle>
                       Số lượng các store trên hệ thống
                    </v-card-subtitle>
                </v-card>
                <v-card style="" color="" class="flex-grow-1 my-2 rounded-xl">
                    <v-card-title primary-title class="">
                        SỐ NSO
                        <v-spacer></v-spacer>
                        <v-avatar size="64" color="red lighten-4" >
                            <v-icon color="red">
                                mdi-chart-bar
                            </v-icon>
                        </v-avatar>
                    </v-card-title>
                    
                    <div class="pa-4 ">
                        <h5 class=" font-weight-bold display-2 text-center">{{nsoStoreNo}}</h5>
                    </div>
                    <v-card-subtitle>
                       Số lượng store chuẩn bị mở 
                    </v-card-subtitle>
                </v-card>
            </v-col>

        </v-row>

        <!-- section pos by dept -->
        <v-card class="rounded-xl" :style="`zoom: ${zoom}%`">
            <v-card-title>Số lượng nhân viên theo chức danh và phòng ban 
                <v-spacer></v-spacer>
                <vue-excel-xlsx
                    :data="items"
                    :columns = "convertToExcelLabel(headers)"
                    :file-name="'DU_LIEU_CHO_XAC_NHAN'"
                    :file-type="'xlsx'"
                    :sheet-name="'sheetname'"
                    >
                        <v-btn color="green" small dark> <v-icon left>mdi-microsoft-excel</v-icon>Xuất dữ liệu</v-btn>
                </vue-excel-xlsx>
                <v-subheader>Zoom: {{zoom}}</v-subheader>
                <v-btn icon color="primary" @click="zoom-=10">
                    <v-icon>mdi-magnify-minus-outline</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="zoom+=10">
                    <v-icon>mdi-magnify-plus-outline</v-icon>
                </v-btn>
                <v-text-field v-model="search" hide-details rounded outlined style="max-width: 200px" placeholder="Tìm kiếm" dense></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-data-table item-key="name" hide-default-header
                :headers="headers" :items="items" :loading="tableLoading" :search="search" height="600px" fixed-header :items-per-page="-1">
                    
                    <template v-slot:[`item.total`]="{ item }">
                        {{item.qlch+item.tca+item.cot1+item.cot2+item.cot3+item.nvm+item.bve}}
                    </template>
                    
                    <template v-slot:[`item.differNvbh`]="{ item }">
                        <span :class="`${item.differNvbh == 0? 'green': (item.differNvbh > 0 ?'blue': 'red') }--text`">
                            {{ item.differNvbh}}
                        </span>
                    </template>
                    <template v-slot:[`item.differSl`]="{ item }">
                        <span :class="`${item.differSl == 0? 'green': (item.differSl > 0 ?'blue': 'red') }--text`">
                            {{ item.differSl}}
                        </span>
                    </template>
                    <template v-slot:[`item.differSm`]="{ item }">
                        <span :class="`${item.differSm == 0? 'green': (item.differSm > 0 ?'blue': 'red') }--text`">
                            {{ item.differSm}}
                        </span>
                    </template>
                    
                    <template v-slot:[`header`]="{ props }">
                        <thead class="v-data-table-header" >
                            <tr>
                                <th rowspan="2" style="color:black; background-color:#FFF8E1" >{{props.headers[0].text}}</th>
                                <th rowspan="2" style="color:black; background-color:#FFF8E1; width: 150px" >{{props.headers[1].text}}</th>
                                <th rowspan="2" style="color:black; background-color:#FFF8E1; width: 150px" >{{props.headers[2].text}}</th>
                                <th rowspan="2" style="color:black; background-color:#FFF8E1" >{{props.headers[3].text}}</th>
                                <th colspan="4" style="color:black; background-color:#C8E6C9; ">Định biên</th>
                                <th colspan="4" style="color:black; background-color:#B3E5FC">Thống kê</th>
                                <th rowspan="2" style="color:black; background-color:#DCE775">{{props.headers[12].text}}
                                    <p><small>(Hiện hữu) - (Định biên)</small></p>
                                </th>
                                <th rowspan="2" style="color:black; background-color:#DCE775">{{props.headers[13].text}}
                                    <p><small>(Hiện hữu) - (Định biên)</small></p>
                                </th>
                                <th rowspan="2" style="color:black; background-color:#DCE775">{{props.headers[14].text}}
                                    <p><small>(Hiện hữu) - (Định biên)</small></p>
                                </th>
                            </tr>
                            <tr>
                                <th style="color:black;">{{props.headers[4].text}}</th>
                                <th style="color:black;">{{props.headers[5].text}}</th>
                                <th style="color:black;">{{props.headers[6].text}}</th>
                                <th style="color:black; background-color: #E0E0E0">{{props.headers[7].text}}</th>
                                <th style="color:black">{{props.headers[8].text}}</th>
                                <th style="color:black">{{props.headers[9].text}}</th>
                                <th style="color:black">{{props.headers[10].text}}</th>
                                <th style="color:black; background-color: #E0E0E0">{{props.headers[11].text}}</th>
                            </tr>
                        </thead>
                    </template>
                </v-data-table>
            </v-card-text>
            
        </v-card>

    </div>
</template>

<script>
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import Chart from '@/components/Charts/Chart.vue';
import EmployeeSummaryApi from './EmployeeSummaryApi'

export default {
    components:{
        Chart
    },
    computed: {
        isAsm() {
            var result = false
            if(this.GROUP_MA) {
                if(this.GROUP_MA.length>0){
                    result = this.GROUP_MA.includes('TS_ASM')
                }
            }
            return result
        },
        selectedTemplate() {
            let rslt = this.item1Select.length>0 ? ` ${this.item1Select[0].id }`: ""

            return rslt
        },
        chinhanhs(){
            if(!this.Sources) return [] 
            var result =this.Sources.split("|") 
            if(result.length > 0){
                this.dathangForm.chinhanh_dutru = result[0]
            }
            return result
        },
    },
    watch:{
        dialogCarousel(val) {
            if(!val)
                this.targetItem=null;
        },
        dialog(val) {
            console.log(val)
            if(!this.dialog)
                this.item=null;
        },
        filterMonth(val)
        {
            console.log(val)
            this.fetchData();
        },
        item1Select() {
            if(this.selectedTemplate != ""){
                this.getQuestions()
            } else{
                this.questions=[]
            }
        },
        filterFromDate() {
            this.getPhieu()
        },
        filterToDate() {
            this.getPhieu()
        },
        hanghoaFilterType() {
            this.getHangHoa()
        },
        hanghoaFilter() {
            this.getHangHoa()
        },
        
        'dathangForm.dvt': function(newVal, oldVal) {
            console.log(oldVal)
            if(this.hanghoaUnits.length >0){
                let selected = this.hanghoaUnits.filter(x=>x.Code == newVal)[0]
                let tonkho = selected['End Quantity'] ? selected['End Quantity'] : 0
                this.dathangForm.tonkho_hethong = tonkho
            }
        },
        hanghoaOutter() {
            this.dathangForm.mahanghoa=""
            this.dathangForm.soluong_dutru=""
            this.dathangForm.dvt=""
            this.dathangForm.tenhang=""
            // this.dathangForm.chinhanh_dutru=""
            this.dathangForm.tonkho_hethong=""
            // this.dathangForm.userid_tao=""

            this.dathangForm.base64_image=[]
            this.dathangForm.loai_hang= ''
            this.dathangForm.trungbinh_ban= ''
            this.dathangForm.loai_yeucau= ''
            this.dathangForm.ngay_giaokhach= null
        }
    },
    data () {
        return {
            zoom: 100,
            employeeSummaryData: {
                label:"Biểu đồ thể hiện số nhân viên của từng chức danh trong toàn hệ thống",
                labels: [],
                
                datasets: [
                    {
                        label: "Nhân viên",
                        data: [],
                        backgroundColor: ['#EF9A9A', '#FFCC80', '#81D4FA', '#80CBC4', '#A5D6A7', '#E6EE9C', '#BCAAA4'],
                        borderWidth: 2
                    },
                ],
            },
            storeNo: 0,
            nsoStoreNo: 0,
            totalEmp: 0,
            totalNewEmp: 0,
            headers: [
                { text: 'Mã', value: 'storeCode', filterable: true },
                { text: 'Tên cửa hàng', value: 'storeName', filterable: true },
                { text: 'Tỉnh thành', value: 'cityName', filterable: true },
                { text: 'Mô hình', value: 'model', filterable: true },

                // { text: 'Bảo vệ', value: 'expectBve', filterable: false },
                { text: 'NVBH', value: 'expectNVBH', filterable: false },
                { text: 'SL', value: 'expectSL', filterable: false },
                { text: 'SM', value: 'expectSM', filterable: false },
                { text: 'Tổng', value: 'expectSum', cellClass:'cell-grey', filterable: false },
                
                // { text: 'Bảo vệ', value: 'actualBve', filterable: false },
                { text: 'NVBH', value: 'actualNVBH', filterable: false },
                { text: 'SL', value: 'actualSL', filterable: false },
                { text: 'SM', value: 'actualSM', filterable: false },
                { text: 'Tổng', value: 'actualSum', cellClass:'cell-grey', filterable: false },
                { text: 'C/L NVBH', value: 'differNvbh', cellClass:"cell-light-lime", align: 'center', filterable: false },
                { text: 'C/L SL', value: 'differSl', cellClass:"cell-light-lime", align: 'center', filterable: false },
                { text: 'C/L SM', value: 'differSm', cellClass:"cell-light-lime", align: 'center', filterable: false },
                // { text: 'Chênh lệch', value: 'differ', cellClass:"cell-light-lime", align: 'center', filterable: false },
            ],
            items: [],
            tableLoading: false,
            search: "",

        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY")
        },
        getGraphData() {
            EmployeeSummaryApi.getGraphData().then(d => {
                d.graphData.forEach(p => {
                    this.employeeSummaryData.labels.push(p.positionStateName)
                    this.employeeSummaryData.datasets[0].data.push(p.countNo)
                });
                
            }).then(()=>{
                console.log(this.employeeSummaryData)
                this.$refs.chart.update();
            })
        },
        getStatisticData() {
            EmployeeSummaryApi.getStatisticData().then(d => {
                this.storeNo = d.storeNo
                this.nsoStoreNo = d.nsoStoreNo
                this.totalEmp = d.totalEmp
                this.totalNewEmp = d.totalNewEmp
            })
        },

        getTableData() {
            this.tableLoading = true
            EmployeeSummaryApi.getTableData().then(d => {
                this.items = d.data;
                this.tableLoading = false
                console.log(d.groupData)
            })
        },
        
        convertToExcelLabel(value) {
            if(Array.isArray(value)) {
                var exceptCols = ['actions', 'images']
                return value.filter(x=>!exceptCols.includes(x.value)).map(x => {
                    return {
                        label: x.text,
                        field: x.value
                    }
                })
            }
        },
    },

    apiService: null,
    created() {
    },
    mounted () {
      this.getGraphData()
      this.getStatisticData()
      this.getTableData()
    }
}
</script>
<style scoped>
    ::v-deep .cell-grey{
        background-color: #E0E0E0 !important;
    }
    ::v-deep .cell-light-lime{
        background-color: #F0F4C3 !important;
    }
</style>