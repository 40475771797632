import CreateDialog from '../Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        },
        from(val)
        {
            this.fetchData();
        },
        
        to(val)
        {
            this.fetchData();
        }
    },
    data () {
        return {
            search:"",
            month: moment().startOf("months").format("yyyy-MM"),
            from: moment().startOf("months").format("YYYY-MM-DD"),
            to: moment().endOf("months").format("YYYY-MM-DD"),
            dialog: false,
            item: null,
            items:[],
            headers: [
                {text:"", value: "actions", width:"3rem", align:"center"},
                {text:"Mã", value: "code", width:"10px"},
                {text:"Phòng ban", value: "departmentName",},
                {text:"Trạng thái", value: "status", width:"9rem"},
                {text:"Tạo bởi", value: "createdByName", width:"15rem"},
                {text:"Tạo vào", value: "createdAt", width:"10rem"},
                {text:"Tiếp nhận bởi", value: "receivedByName", width:"15rem"},
                {text:"Tiếp nhận vào", value: "receivedAt", width:"10rem"},
            ],
            dialogExcel: false,
            excel:{
                from: moment().startOf("months").format("YYYY-MM-DD"),
                to: moment().endOf("months").format("YYYY-MM-DD"),
                status:1,
            },
            dialogDelete: false,
            targetItem: null,
            
            receiveDialog: false,
            receive:{
                from: null,
                to: null,
            }
        }
    },
    methods: {
        fetchData() {
            return http.get("propertyRequests", {
                params: {
                    month: this.month,
                    from: this.from,
                    to: this.to
                }
            }).then(res => {
                this.items = res.data.data;
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `propertyRequests/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
        exportExcel() {
            let url = `PropertyRequests/month-excel`;
            return http.get(url, {
                params:this.excel,
                responseType: 'arraybuffer',
            }).then(res=>{
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'excel.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },

        receiveMultiple() {
            if(this.receive.from == null || this.receive.to == null) {
                alert("Từ ngày đến ngày không được trống")
                return
            }
            return http.get('propertyRequests/receive/multiple', {
                params: {
                    from: moment(this.receive.from).format('YYYY-MM-DD'),
                    to: moment(this.receive.to).format('YYYY-MM-DD')
                }
            }).then(() => {
                this.receive.from = null
                this.receive.to = null
                this.fetchData()
            })
        }
    },
    mounted () {
        this.fetchData();
    }
}