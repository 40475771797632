<template>
    <div class="createEducation">
        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-form @submit.prevent="createData" ref="form">
                    <v-card-title>
                    <span class="headline">Tạo chuyên ngành</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="form.Code" label="Mã" ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="form.Name" label="Tên" ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="form.Note" label="Ghi chú" ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    >
                        Thoát
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        type="submit"
                    >
                        Lưu
                    </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common'
export default {
    name: "EducationMajorCreateDialog",
    components:{

    },
    data() {
        return {
            //*************** Validate **************
            rules: {
                nameRules:[ 
                    v => !!v || 'Tên nhân viên bắt buộc nhập',
                    v => v.length > 5 || 'Phải nhiều hơn 5 kí tự',
                ],
            },
            
            //************* Form config */
            activetab: 0,
            valid: false,
            item: undefined,
            isUpdate: false,
            dialog: false,
            IDdateMenu: false,
            HireMenu: false,
            formData: {
                departmentTypes: []
            },
            form: {
                Code: "",
                Name:"",
                Level: "",
                Priority: "",
            },
            defaultForm: {
                Code: "",
                Name:"",
                Level: "",
                Priority: "",
            },
        };
    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật phòng" : "Tạo mới phòng";
        }
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
    },
    methods: {
        fetchUpdate (code){
            http.get("educationmajors/"+code).then(res => {
                let data = res.data;
                this.form.Code = data.code;
                this.form.Name = data.name;
                this.form.Note = data.note;
                console.log(data)
            }).catch((err) => {
                console.log(err)
            });
        },
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        createData () {
            this.$refs.form.validate()
            if(!this.isUpdate)
                http.post('educationmajors', this.form).then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                }).catch( (err) => {
                    if(err.response.status){
                        let eventObj = {
                            color: "error",
                            messages: "Đã có lỗi xảy ra, vui lòng kiểm tra lại dữ liệu !!!"
                        }
                        EventBus.$emit('hasMessage', eventObj);
                    }
                })
            else{
                http.put('educationmajors/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        }
    },
    mounted() {
        // this.fetchDepartmentType();
    }
}
</script>
