import http from '@/components/services/http-common';
export default {
    methods: {
        fetchUpdate (){
            return http.get(this.rootUrl + "/"+ this.item.code).then(res => {
                let data = res.data;
                this.form = Object.assign({}, res.data);
                this.form.leavePlans = data.benefits.map(x=>{return x.leavePlanCode});
            }).catch(err => {
                console.log(err);
            });
        },
        fetchLeavePlans () {
            return http.get("leavePlans/fetch").then(res => {
                this.leavePlans = res.data.items;
            })
        },
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        createData () {
            this.$refs.form.validate()
            if(!this.isUpdate)
                http.post(this.rootUrl + "/", this.form).then(res => {
                    this.dialog = false;
                    this.$emit("needFetch");
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            else{
                http.put(this.rootUrl + '/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    this.$emit("needFetch");
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        }
    },
}