<template>
 
 <v-dialog v-model="value" max-width="600" persistent  scrollable >
    <v-card :loading="isLoading" >
        <v-card-title>Thông tin thành viên 
          <v-btn class="ml-auto" icon color="red" @click="$emit('input', false)"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        

        <v-card-text class="text--primary">
              <v-form ref="admin_employee_family_form" id="admin_employee_family_form" v-model="isValid" @submit.prevent="save">
                <span class="green--text text--darken-4">NHÂN VIÊN</span>
                <v-autocomplete v-model="form.employeeCode" :items="employees" placeholder="Chọn nhân viên" solo :readonly="isEdit"></v-autocomplete>

                <span class="green--text text--darken-4">THÔNG TIN CƠ BẢN</span>
                <v-divider style="width:100%"></v-divider>
                <v-row class="ma-0">
                  <v-col cols="12" md="6" class="d-flex align-center">
                    Họ tên NPT
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined hide-details="auto" v-model="form.memberName" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" md="6" class="d-flex align-center">
                    CCCD
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined hide-details="auto" v-model="form.memberId" ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" md="6" class="d-flex align-center">
                    Mối quan hệ
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select outlined hide-details="auto" 
                    :items="relationTypes" item-text="value" item-value="key" v-model="form.relationType" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                  </v-col>
                  
                  <v-col cols="12" md="6" class="d-flex align-center">
                    Ngày sinh NPT
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined type="date" hide-details="auto" v-model="form.doB" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" md="6" class="d-flex align-center">
                    Số điện thoại
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined hide-details="auto" v-model="form.phoneNumber" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="d-flex align-center">
                    Thời hạn từ
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field type="date" outlined hide-details="auto" v-model="form.validFrom" ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" md="6" class="d-flex align-center">
                    Thời hạn đến
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field type="date" outlined hide-details="auto" v-model="form.validTo" ></v-text-field>
                  </v-col>
                  
                  <!-- <v-col cols="12" md="6" class="d-flex align-center">
                    Người phụ thuộc
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox hide-details="auto" v-model="form.isDependant" :false-value="0" :true-value="1"></v-checkbox>
                  </v-col> -->
                </v-row>
              </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="error" outlined class="mx-2">Thoát </v-btn>
          <v-btn color="teal darken-4" dark  class="mx-2" form="admin_employee_family_form" type="submit">Lưu</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EmployeeFamilyIndexApi from './api.js'
import moment from 'moment/moment';

import router from "@/router/index";
export default {
  props:['value', 'item'],
  computed: {
    isEdit() {

      return !!this.item
    },

    currentId() {
      if(this.$route.params.id){
        return this.$route.params.id
      } else 
        return null
    },

    breadcrumbs() {
      return [
        {
            text: 'Phiếu yêu cầu tuyển dụng',
            to: {name: 'EmployeeFamily'},
            exact: true
        },
        {
          text: this.isEdit ? 'Chỉnh sửa '+this.$route.params.id : 'Tạo mới',
          to: {name: this.isEdit ? 'EmployeeFamilyEdit' : 'EmployeeFamilyCreate'}
        },
      ]
    }
  },
  watch: {
    value(val) {
      this.$emit('input', val)
      // this.updateValue()
      if(val) {
        this.getEmployees()
        if(this.isEdit) {
          this.getData()
        }
      }
    },
  },

  data() {
    return {
      isLoading: false,

      jobs: [],
      employees: [],
      contractTypes: [],
      recruitTypes: [
        {text: 'Trong & ngoài kế hoạch', value: 'BOTH'},
        {text: 'Trong kế hoạch', value: 'INPLAN'},
        {text: 'Ngoài kế hoạch', value: 'OFFPLAN'},
        {text: 'Không có', value: 'NONE'},
      ],
      genders: [
        {text: "Nam", value: 1},
        {text: "Nữ", value: 0},
      ],
      relationTypes: [],
      workExps: [],
      appearances: [],
      educationLevels: [],

      educationMajorSearch: '',
      educationMajors: [],

      isValid: true,
      jobDescription: null,
      jobDescriptionShow: true,
      jobLoading:  false,
      form: {
        employeeCode: '',
        memberName: '',
        relationType: '',
        doB: null,
        phoneNumber: '',
        isDependant: 1,
        idMember: '',
        validFrom: moment().format("YYYY-MM-DD"),
        validTo: "2100-01-01"
      }
    }
  },
  methods: {
    save() {
      this.$refs.admin_employee_family_form.validate()
      if(this.isValid) {
        if(!this.isEdit){
          return EmployeeFamilyIndexApi.post(this.form).then(() => {
          }).catch(() => {
            alert("error")
          })
        } else {
          return EmployeeFamilyIndexApi.put(this.currentId, this.form).then(() => {
          }).catch(() => {
            alert("error")
          })
        }
      }
    },

    getData() {
      this.isLoading = true

      return EmployeeFamilyIndexApi.get(this.item.id).then(d => {
        this.form = Object.assign({}, d.data)
        this.form.validFrom = moment(this.form.validFrom).format("YYYY-MM-DD")
        this.form.validTo = moment(this.form.validTo).format("YYYY-MM-DD")
      }).finally(() => {
        this.isLoading = false
      })
    },

    getEmployees() {
      this.employeeLoading = true
      return EmployeeFamilyIndexApi.getEmployees().then(d => {
        this.employees = d.map(x => {
          return {
            text: `${x.code}: ${x.name} (${x.departmentName})`,
            value: x.code
          }
        })
      }).finally(() =>  this.employeeLoading = false)
    },
    
    getJobDescription(val) {
      this.jobLoading = true
      EmployeeFamilyIndexApi.getJobDescription(val).then(x=> {
        this.jobDescription = x.description
      }).finally(()=> {
        this.jobLoading = false
      })
    },

  },
  created() {
    EmployeeFamilyIndexApi.getDropDown().then(d => {
      this.contractTypes = d.contractTypes
      this.workExps = d.workExps
      this.appearances = d.appearances
      this.educationLevels = d.educationLevels
      this.educationMajors = d.educationMajors
    })
    
    EmployeeFamilyIndexApi.getRelationType().then(r => {
      this.relationTypes = r.data
    })
  },

  mounted() {
    if(this.isEdit) {
      this.getData()
    }
  }
}
</script>