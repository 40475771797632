<template>
    <v-btn @click="$emit('click')" class="rounded mx-1" small dark color="green darken-4"><v-icon>mdi-import</v-icon>Import</v-btn>
</template>
<script>
export default {
    props:['on', 'value'],
    mounted(){
    }
}
</script>
