<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" style="">
        <v-img src="/static/forgot-password.jpg" contain max-height="80vh"></v-img>
      </v-col>
      <v-col cols="12" md="4" class="">
        <v-card flat class="mx-auto my-6 rounded" min-height="500" width="400px">
          <v-card-title class="font-weight-bold mb-6">
            <v-icon color="orange darken-3" large class="mr-4" left>mdi-lock-reset</v-icon> THAY ĐỔI MẬT KHẨU
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="changePassword" v-model="valid" id="change_password_form" ref="change_password_form">
              <v-row>
                <v-subheader class="font-weight-bold">Tài khoản đăng nhập</v-subheader>
                <v-col cols="12" class="pt-0">
                  <v-text-field solo rounded hide-details="auto" placeholder="SDT đăng nhập" :rules="[v=> !!v ||'Nhập dữ liệu này!']" v-model="form.username"></v-text-field>
                </v-col>
                
                <v-subheader class="font-weight-bold">Mật khẩu cũ của bạn</v-subheader>
                <v-col cols="12" class="pt-0">
                  <v-text-field solo rounded hide-details="auto" type="password" placeholder="Nhập mật khẩu hiện tại" :rules="[v=> !!v ||'Nhập dữ liệu này!']" v-model="form.oldPassword"></v-text-field>
                </v-col>
                
                <v-subheader class="font-weight-bold">Mật khẩu mới</v-subheader>
                <v-col cols="12" class="pt-0">
                  <v-text-field solo rounded hide-details="auto" type="password" placeholder="Thay đổi mật khẩu" :rules="[v=> !!v ||'Nhập dữ liệu này!']" 
                    hint="Mật khẩu mới bao gồm kí tự VIẾT HOA, VIẾT THƯỜNG và CHỮ SỐ với độ dài tối thiểu là 6 kí tự"
                    persistent-hint
                    counter
                    v-model="form.password"></v-text-field>
                </v-col>
                
                <v-subheader class="font-weight-bold">Nhập lại mật khẩu mới</v-subheader>
                <v-col cols="12" class="pt-0">
                  <v-text-field solo rounded hide-details="auto" type="password" placeholder="Xác nhận mật khẩu mới" :rules="[v=> !!v ||'Nhập dữ liệu này!']" v-model="form.confirmPassword"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn type="submit" form="change_password_form" color="teal darken-4" dark rounded large>Thay đổi <v-icon right color="white">mdi-cursor-default-click</v-icon></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import http from '@/components/services/http-common';

export default {

  name: "ChangePasswordForce",
  components: {
  },
  data() {
    return {
      valid: true,
      form: {
        username:"",
        oldPassword:"",
        password:"",
        confirmPassword:"",
      }
    }
  },
  methods: {
    changePassword() {
      this.$refs.change_password_form.validate()
      if(this.valid){
        return http.post("account/change-password/force", this.form).then(()=>{
          this.$refs.change_password_form.reset()
        })
      }
    }
  }
}
</script>