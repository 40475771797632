
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import Compressor from 'compressorjs';

export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch().then(()=>{
                        }).catch(err=>{
                        }).finally(() => {
                            this.fetchData = false;
                        });
                }
                else{
                    this.close();
                }
            }
        },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
        
        formatDates: {
            get: function () {
                var results = [];
                this.form.dates.forEach(x => {
                    results.push(moment(x).format("DD/MM/YYYY"));
                });
                return results;
            }
        },
        
        rules: {
            
            get: function () {
                var result = {
                    requiredDate: v=>(!!v || this.form.dates.length>0)|| 'Nhập dữ liệu',
                }
                return result;
            }

        }
    },
    data() {
        return {
            ROOT_URL: "benefitManagers/",
            EMPLOYEE_FETCH: "employees/search",

            employeeSearch: null,

            employees: [],

            types:[
                {text: "Con nhỏ", value: "CON"},
                {text: "Hỷ sự", value: "HH"},
                {text: "Tang chế", value: "TC"},
                {text: "Phép tháng (ngoại lệ)", value: "PT"},
                {text: "Phép OFF có lương", value: "OFF"},
                {text: "Phép khám thai", value: "KT"},
            ],
            
            isValid: true,
            fetchData: false,

            image1:undefined,
            image2:undefined,
            form: {
                amount:0,
                code: undefined,
                employeeCode: null,
                from: null,
                to: null,
                dates: [],
                note: null,
                type: "CON",
                image1:undefined,
                image2:undefined,
            },

            defaultForm:{
                amount:0,
                code: undefined,
                employeeCode: null,
                from: null,
                to: null,
                dates: [],
                note: null,
                type: "CON",
                image1:undefined,
                image2:undefined,
            },

            helpDialog: false,
            helpSlider: 0,
        }
    },
    methods: {
        customFilter (item, queryText, itemText) {
            const textOne = item.code.toLowerCase()
            const textTwo = item.name.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
            textTwo.indexOf(searchText) > -1
        },

        fetchEmployee(val) {
            return http.get(this.EMPLOYEE_FETCH).then(res => {
                this.employees = res.data;
            });
        },
        compressed1(){
            var that = this;
            new Compressor(this.image1, {
                quality: 0.5,
                success(result) {
                    that.form.image1 = result;
                },
                    error(err) {
                    console.log(err.message);
                },
            });
        },
        compressed2(){
            var that = this;
            new Compressor(this.image2, {
                quality: 0.5,
                success(result) {
                    that.form.image2 = result;
                },
                    error(err) {
                    console.log(err.message);
                },
            });
        },
        
        //fetch current item
        fetch(employee = false){
            if(employee)
            {
                return http.get(this.ROOT_URL+this.employee, {
                    params:{
                        employee: true
                    }
                }).then(res => {
                    this.form = Object.assign({}, res.data);
                    this.form.assignDate = moment().format("YYYY-MM-DD");
                    this.fetchEmployee(this.form.employeeCode);
                    this.fetchData=false;
                })
            }else{
                return http.get(this.ROOT_URL+this.item.code).then(res => {
                    this.form = Object.assign({}, res.data);
                    this.fetchEmployee(this.form.employeeCode);
                    this.fetchData=false;
                })
            }
        },
        
        saveData() {
            let that =this;
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    var formData = new FormData();
                    formData.append('code', this.form.code);
                    formData.append('employeeCode', this.form.employeeCode);
                    formData.append('from', this.form.from);
                    formData.append('to', this.form.to);
                    formData.append('type', this.form.type);
                    formData.append('note', this.form.note);
                    formData.append('amount', this.form.amount);
                    
                    this.form.dates.forEach(date => {
                        formData.append('dates[]', date);
                    });
                    
                    if(this.form.image1)
                    {
                        formData.append('image1', this.form.image1, this.form.image1.name);
                    }
                    
                    if(this.form.image2)
                    {
                        formData.append('image2', this.form.image2, this.form.image2.name);
                    }

                    return http.post(this.ROOT_URL, formData).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    });

                } else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    });
                }
            }
        },
        
        compressed() {
            if(this.form.images.length>0)
            {
                let commpressedImage = [];
                this.form.images.forEach(image => {
                    new Compressor(image, {
                        quality: 0.5,
                    
                        // The compression process is asynchronous,
                        success(result) {
                            commpressedImage.push(result)
                        },
                        error(err) {
                            console.log(err.message);
                        },
                    });
                });
                console.log("compress images", commpressedImage);
            }
        },

        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fetchData=false;
        }
    },
    created() {
        this.fetchEmployee();
    }
}
