import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'securitySalaries';
// global api
class SecuritySalaryApi {
  gets(month, filter) {
    return http.get(`${ROUTE}`).then(r => r.data)
  }

  updateCell(data){
    console.log(data)
    return http.put(`${ROUTE}/${data.employeeCode}`, data)
  }

  
  importing(month, file) {
    var formData = new FormData();
    formData.append("files", file);
    return http.post(`${ROUTE}/Import-cell/${month}`, formData);
  }

}

export default new SecuritySalaryApi();