<template>
    <div class="create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="80%">
            <v-card>
                <v-card-title class="mb-5">
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} khu vực
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                clearable
                                v-model="form.name"
                                label="Tên"
                                ></v-text-field>

                                <v-text-field
                                clearable
                                v-model="form.alias"
                                label="Alias"
                                ></v-text-field>

                                <v-text-field
                                v-model="form.minimumSalary"
                                label="Lương tối thiểu vùng"
                                ></v-text-field>

                                <v-text-field
                                v-model="form.minimumSalary7"
                                label="Lương tối thiểu vùng 7%"
                                ></v-text-field>

                                <v-text-field
                                v-model="form.currentSalary"
                                label="Lương áp dụng hiện tại"
                                ></v-text-field>

                                <v-textarea
                                clearable
                                outlined
                                v-model="form.note"
                                label="Ghi chú"
                                ></v-textarea>

                                <v-switch
                                v-model="form.isActive"
                                label="Trạng thái"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark
                                color="blue darken-1"
                                type="submit"
                            >
                                Lưu
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import http from '@/components/services/http-common';
import * as moment from "moment/moment";

export default {
    components: {
    },
    props: ["value"],
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
            }
        },
        'form.minimumSalary': {
            handler(val)
            {
                this.form.minimumSalary7 = val* 107 /100;
            },
            deep:true,
        }
    },
    data() {
        return {

            // URL
            ROOT_URL: "areas/",
            
            //RULE
            rules: {
            },

            // DATA
            valid: false,
            formData: {
                cities: []
            },
            form: {
                name: "",
                alias: "",
                minimumSalary: "",
                minimumSalary7: "",
                currentSalary: "",
                note: "",
                isActive: "",
            },

            defaultForm: {
                name: "",
                alias: "",
                minimumSalary: "",
                minimumSalary7: "",
                currentSalary: "",
                note: "",
                isActive: "",
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },

        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                }else {
                    http.put(this.ROOT_URL+ this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {

                    });
                }
            }
        },

        fetchData() {
            return http.get(this.ROOT_URL + this.value.item.code)
            .then(res => {
                this.form = Object.assign({}, res.data.item)
            })
            .catch(err => {
                console.log(err);
            });
        },

    },
    created(){
    }
    
}
</script>