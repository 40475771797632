import http from '@/components/services/http-common';
// import * as moment from "moment/moment"

export const ROUTE = 'salaryAdjustments';
export const ROUTE_MS = 'monthlySalaries'
// global api
class SalaryAdjustmentApi {
  gets(month, filter) {
    console.log('xxx',filter)
    return http.get(`${ROUTE}`, {
      params: {
        month: month,
        employeeCode: filter.employeeCode,
        employeeName: filter.employeeName,
        departmentCode: filter.departmentCode,
      }
    }).then(r => r.data)
  }

  updateCell(data){
    return http.put(`${ROUTE}/cell/${data.code}`, data)
  }
  post(data, month) { 
    return http.post(`${ROUTE}`, data, {
      params: {
        month: month
      }
    })
  }
  remove(id) {
    return http.delete(`${ROUTE}/${id}`)
  }

  apply(month) {
    return http.get(`${ROUTE}/apply`, {
      params: {
        month: month
      }
    })
  }
  
  // initData(month) {
  //   return http.post(`${ROUTE}/init`,null, {
  //     params: {
  //       month: month
  //     }
  //   })
  // }
  
  importing(month, file) {
    var formData = new FormData();
    formData.append("files", file);
    return http.post(`${ROUTE}/Import-cell/${month}`, formData);
  }

  //get employees
  getEmployees() {
    return http.get('employees/simple').then(r => r.data)
  }

  getDepartments() {
    return http.get('departments').then(r => r.data)
  }

  getAreas() {
    return http.get('areas').then(r => r.data)
  }

  getPositionStates() {
    return http.get('positionStates').then(r => r.data)
  }

  getEmployeeInfo(employeeCode, month) {
    return http.get(`${ROUTE}/get-employee-info/${employeeCode}`, {
      params: {
        month: month
      }
    }).then(r => r.data)
  }

  getEmployeeCurrentSalary(employeeCode){
    return http.get(`${ROUTE}/get-employee-current-salary/${employeeCode}`).then(r => r.data.salary)
  }


}

export default new SalaryAdjustmentApi();