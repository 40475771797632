<template>
    <div class="contract-type">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb>
        <create-dialog ref="createDialog" @needFetch="fetchData"></create-dialog>
        <v-card>
            <v-card-title>
                Danh sách loại hợp đồng
            <v-spacer></v-spacer>
            <v-text-field v-model="search" @keydown.enter="fetchData" class="mr-2" style="max-width:300px" solo
                append-icon="mdi-magnify"
                label="Tìm kiếm"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-data-table height="600px"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItem"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import myBreadCrumb from '@/components/BreadCrumb'
import http from '@/components/services/http-common'
import ContractTypeCreateDialog from './Create'

export default {
    name: 'ContractType',
    components: {
        myBreadCrumb,
        'create-dialog': ContractTypeCreateDialog,
    },
    data () {
        return {
            breadcrumbs: [
                {
                text: 'Dashboard',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Quản lí loại hợp đồng',
                disabled: true,
                to: {
                    name: "ContractType",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            rootUrl: "contracttypes",
            
            //datatable config
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,
            dialog: false,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                {
                    text: 'Mã',
                    align: 'start',
                    sortable: false,
                    value: 'code',
                },
                {
                    text: 'Tên',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Mô tả', value: 'description' },
                { text: 'Thao tác', value: 'actions', sortable: false },
            ],
        }
    },
    methods:{
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData () {
            const { page, itemsPerPage } = this.options;
            if(page != undefined || itemsPerPage != undefined)
                http.get(this.rootUrl + '?search='+this.search + '&page='+ page + '&pageSize=' + itemsPerPage).then(res => {
                    this.isLoading = false;
                    this.items = res.data.items;
                    this.totalItem = res.data.totalItem;
                    console.log(res.data);
                }).catch(err => {
                    console.log(err);
                });
        },
        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate();
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.rootUrl + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    watch: {
      options: {
        handler (newVal, oldVal) {
            console.log("change")
            // this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    mounted () {
        this.fetchData();
    }
}
</script>

<style lang="stylus" scoped>

</style>