import http from "@/components/services/http-common"
export default class Api {
  gets = () => http.get('employeeReportCategories').then(res =>res.data)

  get = (id) => http.get('employeeReportCategories/'+id).then(res =>res.data)

  post = (data) => http.post('employeeReportCategories', data)
  put = (id, data) => http.put('employeeReportCategories/'+id, data)

  delete = (id) => http.delete('employeeReportCategories/'+id)
}