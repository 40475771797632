<template>
    <div class="createPosition">
        <v-dialog v-model="dialog" max-width="600px" scrollable>
            <v-card>
                <v-card-title>
                    <span class="headline">Tạo chức vụ</span>
                </v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="createData" ref="form" id="form">
                        <v-row>
                            <v-col cols="12">
                                <v-select label="Khối làm việc" v-model="form.departmentTypeCode" :items="formData.departmentTypes"
                                    item-text="name" item-value="code" :rules="[v=>!!v||'Dữ liệu bắt buộc nhập']">
                                </v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                :rules="rules.nameRules"
                                v-model="form.name"
                                label="Tên"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                v-model="form.description"
                                filled label="Mô tả"
                                auto-grow placeholder="Mô tả "
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"
                        text @click="dialog = false" >
                        Thoát
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text type="submit" form="form"
                    >
                        Lưu
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common'
export default {
    name: "PositionCreateDialog",
    components:{

    },
    data() {
        return {
            //*************** Validate **************
            rules: {
                nameRules:[ 
                    v => !!v || 'Tên bắt buộc nhập',
                    v => v.length > 5 || 'Phải nhiều hơn 5 kí tự',
                ],
                state:[ 
                    v => !!v || 'Hình thức bắt buộc nhập',
                ],
            },
            
            // URL
            STATE_URL: "PositionStates",

            //************* Form config */
            rootUrl: "positions",
            activetab: 0,
            valid: false,
            item: undefined,
            isUpdate: false,
            dialog: false,
            IDdateMenu: false,
            HireMenu: false,
            State: false,
            formData: {
                parents: [],
                states: [],
                departmentTypes: [],
            },
            
            // LOADING 
            loadingState: false,

            // FORMData 
            form: {
                name:"",
                parentCode: null,
                description: "",
                positionStateCode:"",
                departmentTypeCode: "",
            },
            defaultForm: {
                name:"",
                parentCode: null,
                description: "",
                positionStateCode:"",
                departmentTypeCode: "",
            },
        };
    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật phòng" : "Tạo mới phòng";
        }
    },
    watch: {
        dialog (val) {
            if (val)
            {
                this.fetch();
                this.fetchParent();
                this.fetchState();
                return val;
            }
            else
                this.close();
        },
    },
    methods: {
        fetchUpdate (){
            return http.get(this.rootUrl + "/"+ this.item.code).then(res => {
                let data = res.data;
                this.form = Object.assign({}, data);
                // this.form.Name = data.name;
                // this.form.ParentCode = data.parentCode;
                // this.form.PositionStateCode = data.positionStateCode;
                // this.form.Description = data.description;
            }).catch(err => {
                console.log(err);
            });
        },
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        fetch () {
            http.get("departmentTypes?dropDown=1").then(res => {
                // this.formData.states = res.data.states;
                this.formData.departmentTypes = res.data;
            })
        },
        fetchParent() {
            return http.get("positions?dropDown=1").then(res => {
                this.formData.parents = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
                console.log(res.data);
            }).catch(err =>{
                console.log("error connection");
            });
        },

        fetchState () {
            return http.get(this.STATE_URL + "?dropDown=1").then(res => {
                this.formData.states = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
                console.log(this.formData.states)
            }).catch(err =>{
                console.log("error connection");
            });
        },

        createData () {
            this.$refs.form.validate()
            if(!this.isUpdate)
                return http.post(this.rootUrl + "/", this.form).then(res => {
                    this.dialog = false;
                    this.$emit("needFetch");
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            else{
                return http.put(this.rootUrl + '/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    this.$emit("needFetch");
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        }
    },
    mounted() {
        // this.fetchParent();
        this.fetch();
    }
}
</script>
