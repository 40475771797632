<template>
    <v-dialog v-model="dialog" width="500px">
        <v-card :loading="loading">
            <v-card-title>
                Xuất excel
                <v-spacer></v-spacer> 
                <v-icon small @click="dialog=false"> mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete :search-input.sync="employeeSearch" v-model="form.employeeCodes" hide-no-data label="Chọn nhân viên" clearable multiple
                        :items="employees" item-value="code" item-text="name" @update:search-input="fetchEmployee"></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete :search-input.sync="departmentSearch" v-model="form.departmentCodes" hide-no-data label="Chọn phòng ban" multiple
                        :items="departments" item-value="code" item-text="name" @update:search-input="fetchDepartment"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <!-- <v-spacer></v-spacer> -->
                <!-- <v-btn @click="exportExcel" small color="success">Xuất excel</v-btn> -->
                <v-btn @click="exportExcel2" small color="warning" block>Xuất excel</v-btn>
                <!-- <v-spacer></v-spacer> -->
            </v-card-actions>
            <small class="ml-4">Thực thi lại sau khi ấn tính công</small>
            <v-card-actions>
                <v-btn @click="exportExcelReport" small color="green" class="white--text" block>Xuất excel theo phòng ban</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment';
import http from '@/components/services/http-common';
import ApiService from "@/components/services/ApiServices";
export default {
    props: ["value", "month", "year"],
    computed:{
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    watch:{
        // employeeSearch:{
        //     handler(val){
        //         console.log(val)
        //         this.fetchEmployee();
        //     }
        // }
    },
    data() {
        return {
            ROOT_URL: "Timesheetreports",
            
            employeeSearch: null,
            employees: [],
            departmentSearch: null,
            departments: [],
            form:{
                employeeCodes: [],
                departmentCodes: [],
                year: moment().format("YYYY"),
                month: moment().format("MM"),
            },
            loading: false,
        }
    },
    methods:{
        fetchEmployee(){
            return ApiService.fetchEmployee(this.employeeSearch).then(res=>{
                this.employees = res.data;
            })
        },
        fetchDepartment(){
            return ApiService.fetchDepartment(this.departmentSearch).then(res=>{
                this.departments = res.data;
            })
        },
        exportExcel () {
            this.form.year = this.year;
            this.form.month = this.month;
            this.loading = true;
            return http.get("monthlyTimesheets" + "/export", {
                params: this.form,
                responseType: 'blob',
            }).then(res =>{
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'GIO_CONG_THANG.xlsx');
                document.body.appendChild(link);
                link.click();
            }).catch (err => {
                throw err;
            }).finally(()=> this.loading=false);
        },

        exportExcelReport() {
            this.loading = true;
            return http.get("monthlytimesheets/export-rotation", {
                params: {
                    year: this.year,
                    month: this.month
                }
            }).then(res => {
                return http.get(res.data, {
                    responseType: "arraybuffer"
                }).then(res2 => {
                    let blob = new Blob([res2.data]), url = window.URL.createObjectURL(blob)
                    
                    let filename = res.data.split("/").pop();
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    
                    this.loading = false;
                })
            })
        },
        
        exportExcel2 () {
            this.form.year = this.year;
            this.form.month = this.month;
            this.loading = true;
            return http.get("monthlyTimesheets" + "/export2", {
                params: this.form,
                responseType: 'blob',
            }).then(res =>{
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `GIO_CONG_THANG_${this.form.month}_${this.form.year}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).catch (err => {
                throw err;
            }).finally(()=> this.loading=false);
        },
    }
}
</script>