<template>
    <div class="overtime-create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="600px" class="mb-3">
             <v-card>
                 <v-toolbar flat>
                     <v-toolbar-title>{{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} đơn làm thêm</v-toolbar-title>
                     <v-spacer></v-spacer>
                     <v-icon small @click="value.state=false">mdi-close</v-icon>
                 </v-toolbar>
                <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete v-model="form.employeeCode" :search-input="employeeSearch" @update:search-input="fetchEmployee" label="Nhân viên *" 
                                    :filter="customFilter"
                                    hide-no-data :items="employees" item-text="name" item-value="code">
                                    <template v-slot:item="data">
                                        <strong>{{ data.item.code }}: </strong> {{` ${data.item.name} - ${data.item.department}`}}
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete label="Loại tăng ca *" :rules="rules.OvertimeTypeRules" item-text="name" item-value="code"
                                :items="formData.overtimeTypes" v-model="form.overtimeTypeCode" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete label="Loại tăng ca *" :rules="rules.OvertimeTypeRules" item-text="name" item-value="code"
                                :items="formData.overtimeTypes" v-model="form.overtimeTypeCode" ></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <date-picker2 v-model="form.date" title="Ngày tăng ca *" :range="false" :width="400"> </date-picker2>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="form.length" label="Số giờ tăng ca *" :rules='[v=>!!v ||"Nhập số giờ tăng ca"]' type="number">
                                    
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field type="time" v-model="form.startOvertime" :rules="[v=>!!v|| 'Nhập dữ liệu']" label="Giờ bắt đầu"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field type="time" v-model="form.endOvertime" :rules="[v=>!!v|| 'Nhập dữ liệu']" label="Giờ kết thúc"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea flat outlined v-model="form.note" hide-details solo label="Chú thích"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark color="blue darken-1" type="submit"  v-if="!value.item || (value.timesheet && value.item && !value.item.code)" >
                            Gửi
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
             </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import http from '@/components/services/http-common';
import EventBus  from "@/components/services/EventBus";
import TimePicker from "@/components/TimePicker";
import * as moment from "moment/moment";
import ApiServices from '@/components/services/ApiServices';

const checkMaxURL = "OvertimeRequests/valid-max-hour";

export default {
    name: "Create",
    components: {
        "date-picker": DatePicker,
        "time-picker": TimePicker,
    },
    computed:{

    },
    
    watch: {
        value: {
            handler(val){
                this.checkMaxHour();
                if(val.timesheet)
                {
                    this.form.adminEmployeeCode=val.item.adminEmployeeCode;
                    this.form.date=val.item.date;
                    this.form.accept = true;
                    if(!!val.item.code)
                    {
                        this.fetchData();
                    }
                }else{
                    if(val.item != undefined)
                        this.fetchData();
                }
                if(val.state)
                    return val || this.close();
            },
            deep: true,
        },

        'form.endOvertime': {
            handler(val){
                let timeStart = Date.parse(`01 Jan 2012 ${this.form.startOvertime}:00 GMT` );
                let timeEnd = Date.parse(`01 Jan 2012 ${this.form.endOvertime}:00 GMT` );
                // this.form.length = (timeEnd - timeStart)/(1000*60*60);
                // this.form.length = (Math.floor(this.form.length * 100) / 100) > 0 ? (Math.floor(this.form.length * 100) / 100) : 0;
                return val;
            }
        },
    },

    props: ["value"],
    data () {
        return {
            //URL
            ROOT_URL: "OvertimeRequests/",
            OVERTIME_TYPE_URL: "OvertimeTypes/fetch",

            //DATA
            valid: false,
            ShiftObject: "",
            formData: {
                overtimeTypes: [],
            },
            employeeSearch:"",
            form: {
                code:"",
                adminEmployeeCode:"",
                date: new Date().toISOString().substr(0, 10),
                overtimeTypeCode: "",
                length: 0,
                note: "",
                isBefore: true,
                hasTime: false,
                startOvertime: "00:00",
                endOvertime: "00:00",
            },

            defaultForm: {
                code:"",
                adminEmployeeCode:"",
                date: new Date().toISOString().substr(0, 10),
                overtimeTypeCode: "",
                length: 0,
                note: "",
                isBefore: true,
                hasTime: false,
                startOvertime: "00:00",
                endOvertime: "00:00",
            },

            //EMPLOYEE DIALOG
            employees:[],
            employeeDialog: false,
            selectedField: "",
            
            //RULES Validation
            rules: {
                OvertimeTypeRules:[ 
                    v => !!v || 'Loại tăng ca bắt buộc nhập',
                ],
                LengthRules:[ 
                    v => v > 0 || 'Thời gian tăng ca phải lớn hơn 0',
                    // v => v < 7 || 'Thời gian tăng ca phải nhỏ hơn 5',
                ],
            },
        }
    },
    
    methods: {
        customFilter(item, queryText, itemText) {
            return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        },
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },

        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        fetchEmployee(val){
            ApiServices.fetchEmployee(val).then(res=>{
                this.employees = res.data;
                console.log(res.data);
            })
        },

        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
            this.value.item.code="";
        },
        
        fetchData () {
            return http.get(this.ROOT_URL+"admin"+ this.value.item.code)
            .then(res => {
                let item = res.data.item;
                this.form = Object.assign({}, item);

                //format data
                this.form.startOvertime = this.form.startOvertime.slice(-8);
                this.form.endOvertime = this.form.endOvertime.slice(-8);
            })
            .catch(err => {
                console.log(err);
            });
        },
        
        checkMaxHour(){
            return http.get(checkMaxURL, {
                params: {
                    date: this.form.date
                }
            }).then(res => {
                console.log(res.data)
            })
        },

        fetchOvertimeType () {
            http.get(this.OVERTIME_TYPE_URL).then(res => {
                this.formData.overtimeTypes = res.data.items
            }).catch(err => { throw err });
        },

        saveData() {
            let  isValid = this.$refs.form.validate();
            this.form.startOvertime = this.form.startOvertime ?? undefined;
            this.form.endOvertime = this.form.endOvertime ?? undefined;
            if(isValid)
            {
                if(!this.value.item || this.value.timesheet)
                {
                    return http.post(this.ROOT_URL+'admin', this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Tạo mới thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                } else {
                    return http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Cập nhật thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
                this.close();
            }
        },
    },
    
    mounted() {
        this.fetchOvertimeType();
    }
}
</script>