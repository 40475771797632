<template>
    <div class="index" >
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <v-card  v-if="webAllowed">
            <v-card-title>
                <v-spacer></v-spacer>
                <refresh-button @click="fetchData"></refresh-button>
                <create-button @click="dialog.state=true"></create-button>
                <v-divider vertical class="mx-2"></v-divider>
                <span class="d-none d-sm-flex">
                    <month-picker v-model="month" outlined dense width="7rem"></month-picker>
                </span>
                

                <!-- #FILTER MENU -->
                <!-- <data-filter2 :filterProps="filters2" :defaults="defaults" @fetchData="fetchData" >
                </data-filter2> -->
                
            </v-card-title>

            <v-data-table
                height="600px"
                :mobile-breakpoint="0"
                :headers="headers"
                :items="items"
                :page.sync="tableParams.page"
                :sort-by.sync="tableParams.sortBy"
                :sort-desc.sync="tableParams.sortDesc"
                :server-items-length="rowCount"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr class="filter">
                        <td></td>
                        <td>
                            <v-select v-model="filters.status" :items="[{text:'Tất cả', value: -1},
                            {text:'Chờ', value: 0}, {text:'Xác nhận', value: 1}, {text:'Từ chối', value: 2}]" placeholder="Chọn" dense outlined hide-details></v-select>
                        </td>
                        <td>
                            <v-text-field v-model="filters.code" placeholder="Chọn" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete v-model="filters.LongLeaveCode" :items="longLeaves" item-text="name" item-value="code" 
                            placeholder="Chọn" clearable  dense outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-text-field v-model="filters.employeeCode" placeholder="Chọn" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field v-model="filters.employeeName" placeholder="Chọn" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field v-model="filters.departmentName" placeholder="Chọn" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <date-picker2 v-model="filters.requestDate" placeholder="Chọn" dense outlined hide-details>
                            </date-picker2>
                        </td>
                        <td>
                            <v-text-field v-model="filters.leaveDays" placeholder="Chọn" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <date-picker2 v-model="filters.from" placeholder="Chọn" dense outlined hide-details>
                            </date-picker2>
                        </td>
                        <td>
                            <date-picker2 v-model="filters.to" placeholder="Chọn" dense outlined hide-details>
                            </date-picker2>
                        </td>
                        <td>
                            <!-- <v-text-field v-model="filters.approveBy" placeholder="Chọn" dense outlined hide-details></v-text-field> -->
                        </td>
                        <td>
                            <!-- <v-text-field v-model="filters.acceptDate" placeholder="Chọn" dense outlined hide-details></v-text-field> -->
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
                <template v-slot:[`item.to`]="{ item }" >
                    {{ $helper.formatDate(item.to) }}
                </template>
                
                <template v-slot:[`item.from`]="{ item }" >
                    {{ $helper.formatDate(item.from) }}
                </template>

                <template v-slot:[`item.requestDate`]="{ item }" >
                    {{ $helper.formatDate(item.requestDate) }}
                </template>
                <template v-slot:[`item.acceptDate`]="{ item }" >
                    {{ $helper.formatDate(item.acceptDate) }}
                </template>
                
                <template v-slot:[`item.confirmBy`]="{ item }" >
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                    <v-icon left>
                        mdi-store
                    </v-icon>
                    {{ item.confirmBy }}
                    </v-chip>
                </template>
                
                <template v-slot:[`item.isCurrent`]="{ item }">
                    <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <!-- ********* Edit ****** -->
                    <v-btn x-small @click="editItem(item)" class="mx-1 elevation-2" text raise >
                        <v-icon small>
                            mdi-pencil
                        </v-icon> Chi tiết
                    </v-btn>

                    <!-- ********* Xoa ****** -->
                    <v-btn x-small @click="deleteItem(item)" class="mx-1" l color="error">
                        <v-icon small >
                            mdi-delete
                        </v-icon>  Xóa
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CreateButton from '@/components/Crud/CreateButton';
import http from '@/components/services/http-common';
import Create from './Create.vue';
import DataFilter2 from '@/components/DataFilter2.vue';
import moment from 'moment';
import MonthPicker from '@/components/MonthPicker.vue';

export default {
    components: {
        CreateButton,
        'create-dialog': Create,
        'month-picker': MonthPicker,
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
    },
    watch: {
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    
    data() {
        return {
            ROOT_URL: "longleaverequests",
            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //TABLE DATA
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            month: moment().format("YYYY-MM"),
            longLeaves:[],
            filters:{
                status: -1,
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,
            items: [],

            headers: [
                {text:"", value: "actions", align:"center", width: "200px", sortable:false},
                {text: "Trạng thái", value: "status", width: "120px"},
                {text: "Số phiếu", value: "code", width: "150px"},
                {text: "Loại", value: "longLeaveType", width: "150px"},
                {text: "Mã nhân viên", value: "employeeCode", width: "150px"},
                {text: "Tên nhân viên", value: "employeeName", width: "200px"},
                {text: "Phòng", value: "departmentName", width: "200px"},
                {text: "Ngày yêu cầu", value: "requestDate", width: "200px"},
                {text: "Số ngày nghỉ", value: "leaveDays", width: "200px"},
                {text: "Từ", value: "from", width: "200px"},
                {text: "Đến", value: "to", width: "200px"},
                {text: "Người xác nhận", value: "confirmBy", width: "200px"},
                {text: "Ngày xác nhận", value: "confirmAt", width: "200px"},
            ],
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

        }
    },
    methods: {
        formatDate(val){
            return moment(val).format("YYYY-MM-DD")
        },
        //DATA JS
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.items = res.data.results;
                this.rowCount = res.data.rowCount
                this.isLoading = false;
            }).catch(err => {})
        },
        fetchLongLeave() {
            return http.get("longleaves/fetch").then(res=> {
                this.longLeaves = res.data.items
            });
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {

            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    created() {
        this.fetchData();
        this.fetchLongLeave();
    }
}
</script>

<style scoped>
::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>