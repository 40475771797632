<template>
    <div class="job">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb> -->
        <create-dialog ref="createDialog" @CloseDialog="closeHandler" @fetchData="fetchData" v-model="dialog"></create-dialog>
        <v-card>
            <v-card-title>
            <v-spacer></v-spacer>
            <v-checkbox v-model="showHide" label="Hiển thị ẩn" @change="fetchData"></v-checkbox>

            <v-text-field v-model="search" @keydown.enter="fetchData" class="mx-2" style="max-width:18rem" outlined dense
                append-icon="mdi-magnify"
                label="Tìm kiếm"
                single-line
                hide-details
            ></v-text-field>
            
            <create-button @click="dialog.state=true" class="d-none d-md-flex"></create-button>

            <!-- MENU MOBILE -->
            <v-menu bottom left offset-y class="d-md-none">
                <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="d-md-none">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>
                <v-list dense>
                <v-list-item  @click="dialog.state = true">
                    <v-list-item-icon><v-icon color="teal">mdi-plus</v-icon></v-list-item-icon>
                    <v-list-item-title>Thêm công việc</v-list-item-title>
                </v-list-item>
                </v-list>
          </v-menu>
            </v-card-title>
            <v-data-table
            :mobile-breakpoint="0"
            height="600px"
            :headers="headers"
            :items="items"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.isActive`]="{ item }">
                    <v-radio-group v-model="item.isActive" row dense @change="changeVisible(item)">
                        <v-radio color="green" label="Hiện" :value="true" ></v-radio>
                        <v-radio color="red" label="Ẩn" :value="false" ></v-radio>
                    </v-radio-group>
                    <!-- <v-icon v-if="item.isActive">
                        mdi-check
                    </v-icon> -->
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn x-small @click="editItem(item)" class="mx-1">
                        <v-icon small>mdi-pencil</v-icon> Xem
                    </v-btn>
                    <v-btn x-small @click="deleteItem(item)" class="mx-1" color="error">
                        <v-icon small>mdi-trash-can</v-icon> Xóa
                    </v-btn>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import CreateButton from '@/components/Crud/CreateButton'
import http from '@/components/services/http-common'
import Create from './Create'

export default {
    name: 'Job',
    components: {
        CreateButton,
        'create-dialog': Create
    },
    props:["breadcrumbs", "icon"],
    data () {
        return {
            // URLS
            ROOT_URL: "jobs",

            rootUrl: "positions",

            //dialog
            dialog: {state: false, item: undefined},
            
            //datatable config
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,
            dialogDelete: false,
            targetItem: undefined,
            showHide: false,

            headers: [
                { text: 'Thao tác', value: 'actions', sortable: false, width:'12rem'},
                {
                    text: 'Mã công việc',
                    align: 'start',
                    sortable: false,
                    value: 'code', width: '7rem'
                },
                {
                    text: 'Tên công việc',
                    align: 'start',
                    value: 'name', width: '10rem'
                },
                { text: 'Ghi chú', value: 'note', width: '20rem'},
                { text: 'Hiển thị', value: 'isActive'},
            ],
        }
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    methods:{
        openDialog(){
            this.dialog.state = true;
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: {
                    isAdmin: 1
                }
            }).then(res => {
                console.log(res.data)
                this.items = res.data;
                if(this.showHide == false) {
                    this.items = this.items.filter(x=>x.isActive == 1)
                }
            }).catch(err => {
                console.log(err);
            });
        },
        editItem(item) {
            this.dialog.state = true;
            this.dialog.item = item;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },

        changeVisible(item){
            return http.get('jobs/visibility/'+item.code, {
                params: {
                    status: item.isActive
                }
            }).then(() => {
                return this.fetchData()
            })
        },
        closeHandler() {
            this.dialog = {state: false, item: undefined}
        }
    },
    mounted () {
        this.fetchData();
    },
    created()
    {
        this.$emit("setUrl", this.breadcrumbs);
    }, 
    destroyed() {
        this.$emit("setUrl", []);
    }
}
</script>

<style lang="stylus" scoped>

</style>