<template>
  <div>
    <v-card >
      <v-card-title>Danh sách phụ cấp
        <v-spacer></v-spacer>
        <v-btn @click="importDialog = true" text color="teal darken-4">Nhập dữ liệu</v-btn>
        <v-btn @click="createDialog = true" icon color="teal darken-4"> <v-icon>mdi-plus</v-icon></v-btn>
        <v-btn class="mx-2" @click="getData" small icon color="teal darken-4"><v-icon >mdi-refresh</v-icon></v-btn>
        <v-text-field label="Tháng" v-model="month" style="max-width: 200px;" type="date" hint="Chọn ngày đầu tháng vd 01/09/2023"></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row  class="">
          <v-col cols="6" md="2">
            <v-text-field hide-details v-model="filter.employeeCode" outlined dense label="MSNS" @change="getData"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field hide-details v-model="filter.employeeName" outlined dense label="Họ và tên" @change="getData"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-autocomplete clearable :items="departments" hide-details v-model="filter.departmentCode" outlined dense label="Chi nhánh" @change="getData"></v-autocomplete items="departments">
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-data-table :headers="headers" :items="supplements" :loading="isLoading" fixed-header height="65vh">
          <template v-slot:[`item.pC_Khu_Vuc`]="{ item }" >
            <v-text-field flat style="max-width: 50px;" dense hide-details solo v-model="item.pC_Khu_Vuc" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_BH`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.pC_BH" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.he_So`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.he_So" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.luong_Hieu_Qua_2`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.luong_Hieu_Qua_2" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_Dien_Thoai`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.pC_Dien_Thoai" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_Cong_Tac`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.pC_Cong_Tac" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_Dao_Tao`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.pC_Dao_Tao" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.truy_Lanh`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.truy_Lanh" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.truy_Thu`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.truy_Thu" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.hoan_Ung`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.hoan_Ung" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.ghi_Chu`]="{ item }" >
            <v-text-field flat dense hide-details solo v-model="item.ghi_Chu" type="text" @change="updateCell(item)"></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- create diaglog -->
    <v-dialog v-model="createDialog" width="600px" scrollable>
      <v-card>
        <v-card-title>
          Chi tiết dữ liệu
        </v-card-title>
        <v-card-text>
          <v-form id="supplement_create_form" ref="supplement_create_form" @submit.prevent="save" v-model="isValid">
            <v-row class="mt-0">
              <v-col cols="12">
                <v-autocomplete label="Nhân viên" v-model="form.msnv" :items="employees" outlined hide-details :rules="[v=>!!v|| 'Bạn hãy nhập dữ liệu này']"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-select label="Đánh giá" v-model="form.danh_Gia" :items="[
                  {text: 'Đạt', value: 'DAT'},
                  {text: 'Không đạt', value: 'KDAT'}
                ]"></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field label="PC Khu vực" v-model="form.pC_Khu_Vuc" hide-details="auto" hint="nếu để rỗng để hệ thống tự tính" outlined persistent-hint></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field label="Lương hiệu quả 2" v-model="form.luong_Hieu_Qua_2" outlined hide-details="auto"></v-text-field>
              </v-col>
              
              <v-col cols="6">
                <v-text-field label="PC Điện thoại" v-model="form.pC_Dien_Thoai" outlined hide-details="auto"></v-text-field>
              </v-col>
              
              <v-col cols="6">
                <v-text-field label="PC Công tác" v-model="form.pC_Cong_Tac" outlined hide-details="auto"></v-text-field>
              </v-col>
              
              <v-col cols="6">
                <v-text-field label="PC đào tạo" v-model="form.pC_Dao_Tao" outlined hide-details="auto"></v-text-field>
              </v-col>
              
              <v-col cols="6">
                <v-text-field label="PC truy lãnh" v-model="form.truy_Lanh" outlined hide-details="auto"></v-text-field>
              </v-col>
              
              <v-col cols="6">
                <v-text-field label="PC truy thu" v-model="form.truy_Thu" outlined hide-details="auto"></v-text-field>
              </v-col>
              
              <v-col cols="6">
                <v-text-field label="Hoàn ứng" v-model="form.hoan_Ung" outlined hide-details="auto"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn @click="createDialog = false" color="error" outlined class="mx-2">Đóng</v-btn>
          <v-btn type="submit" color="teal darken-4" form="supplement_create_form" dark class="mx-2">Lưu</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="importDialog" width="600px"> 
      <v-card :loading="importLoading">
        <v-card-title>Import dữ liệu</v-card-title>
        <v-card-subtitle>
          <small class="info--text mr-6">Template excel: </small>
          <export-excel :items="supplements" :headers="headers" class="mx-6"></export-excel>
        </v-card-subtitle>
        <v-card-text>
          <v-form v-model="validImport" id="import_supplement" ref="import_supplement" @submit.prevent="importHandler">
            <v-row>
              <v-col>
                <v-file-input v-model="importFile" label="File import" persistent-placeholder :rules="[v => !!v || 'Chọn file import']"></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="teal darken-4" form="import_supplement" type="submit" small dark>
            <span v-if="importLoading"> Dữ liệu đang import. vui lòng không chuyển trang</span>
            <span v-else>Nhập dữ liệu</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import moment from 'moment';
import SupplementApi from './api'
import ApiServices from '@/components/services/ApiServices'
import ExportExcel from '@/components/ExportExcel'

export default {
  data() {
    return {
      columns: [{ prop: "name" }, { prop: "details" }],
      rows: [{
        name: "1",
        details: "Item 1",
      }],
      isLoading: false,
      month: `${moment().year()}-${moment().month()+1}-01`,
      filter: {
        employeeCode: '',
        employeeName: '',
        departmentCode: '',
      },
      departments:[],
      employees: [],

      isValid: true,
      createDialog: false,
      importFile: null,
      importDialog: false,
      importLoading: false,
      validImport: true,
      headers: [
          // { text: 'Mã', value: 'ma' },
          { text: 'MSNV', value: 'msnv' },
          { text: 'Họ Tên', value: 'ho_Ten', width: 150 },
          { text: 'Chi Nhánh', value: 'chi_Nhanh' },
          { text: 'Lương', value: 'luong', width: 120 },
          { text: 'Hệ Số', value: 'he_So' , width: 120},
          { text: 'Đánh Giá', value: 'danh_Gia', width: 120 },
          { text: 'PC Khu Vực', value: 'pC_Khu_Vuc', width: 120 },
          { text: 'PC Bảo Hiểm', value: 'pC_BH', width: 120 },
          { text: 'Lương Hiệu Quả 2', value: 'luong_Hieu_Qua_2', width: 120 },
          { text: 'PC Điện Thoại', value: 'pC_Dien_Thoai', width: 120 },
          { text: 'PC Công Tác', value: 'pC_Cong_Tac', width: 120 },
          { text: 'PC Đào Tạo', value: 'pC_Dao_Tao', width: 120 },
          { text: 'Truy Lành', value: 'truy_Lanh', width: 120 },
          { text: 'Truy Thu', value: 'truy_Thu', width: 120 },
          { text: 'Hoàn Ứng', value: 'hoan_Ung', width: 120 },
          { text: 'Ghi Chú', value: 'ghi_Chu', width: 200 },
          { text: 'Người Tạo', value: 'createdBy' },
          { text: 'Tạo vào', value: 'createdAt' },
          { text: 'Họ Tên', value: 'ho_Ten' },
          { text: 'MSNV', value: 'msnv' },
        ],

        supplements: [],

        form: {
          msnv: null,
          pC_Khu_Vuc: 0,
          luong_Hieu_Qua_2: 0,
          pC_Dien_Thoai: 0,
          pC_Cong_Tac: 0,
          pC_Dao_Tao: 0,
          truy_Lanh: 0,
          truy_Thu: 0,
          hoan_Ung: 0,
        }
    };
  },
  methods: {
    getData() {
      this.isLoading = true
      return SupplementApi.gets(this.month, this.filter).then(d => d.data).then(data => {
        this.supplements = data
        this.isLoading = false
      })
    },
    getDepartment() {
      return ApiServices.fetchDepartment().then(r =>{
        this.departments = r.data.map(x => {
          return {
            text: x.name,
            value: x.code
          }
        })
      });
    },
    
    getEmployee() {
      return SupplementApi.getEmployees().then(d => {
        this.employees = d.map(x => {
          return {
            text: `${x.code}: ${x.name} (${x.departmentName})`,
            value: x.code
          }
        })
      })
    },

    updateCell(item){
      console.log(item)
      return SupplementApi.updateCell(item).then(() => this.getData())
    },

    importHandler() {
      // alert("import")
      this.importLoading = true
      return SupplementApi.importing(this.month, this.importFile).finally(() => {
        this.importLoading = false
      })
    },

    save() {
      this.$refs.supplement_create_form.validate()
      if(this.isValid) {
        return SupplementApi.post(this.form, this.month)
      } else {
        alert("falied")
      }
    }
  },
  components: {
    ExportExcel
  },
  created() {
    this.getDepartment()
    this.getEmployee()
    this.getData()
  }
};
</script>