<template>
  <div>
    <my-breadcrumb :data="breadcrumb"></my-breadcrumb>
    <v-card>
      
      <v-tabs v-model="tabs">
        <v-tab>Thông tin thưởng</v-tab>
        <v-tab>Danh sách nhân viên</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card-text style="min-height: 400px">
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="6" md="6" class="d-flex align-center">
                  Tháng chi thưởng
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field style="max-width: 200px;"
                  outlined hide-details v-model="month" @change="getData" type="date" dense placeholder="Chọn tháng" persistent-placeholder></v-text-field>
                </v-col>
                
                <!-- <v-col cols="6" md="6" class="d-flex align-center">
                  Duyệt chi thưởng
                </v-col>
                <v-col cols="6" md="6">
                  <v-checkbox ></v-checkbox>
                </v-col>
                
                <v-col cols="6" md="6" class="d-flex align-center">
                  Duyệt chi thưởng
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field hide-details ></v-text-field>
                </v-col> -->

                <v-col cols="12">
                  <b>Lịch sử thao tác</b>
                  <v-card outlined class="mt-2">
                    <v-data-table :items="histories" :headers="historyHeaders" :items-per-page="-1">
                      <template v-slot:[`item.updatedAt`]="{ item }">
                        {{ formatTime(item.updatedAt) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-title>
            <v-text-field style="max-width:200px" dense v-model="search" outlined placeholder="Tìm kiếm" hide-details persistent-placeholder></v-text-field>
            <v-spacer></v-spacer>
            <v-btn icon color="teal" @click="getData" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
            <export-excel :items="items" :headers="headers"> </export-excel>
            <v-btn small color="teal darken-4" class="mx-2" outlined @click="importDialog=true">Import excel</v-btn>
            <v-btn small color="teal darken-4" class="" dark
            @click="openDialog">Tạo mới</v-btn>
          </v-card-title>
          <v-card-text>
            <!-- <v-row justify="end">
              <v-col cols="12" xl="2" md="3">
              </v-col>
            </v-row> -->
            <v-data-table :items="items" :headers="headers" :search="search" :loading="isLoading" dense height="600px">
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="error" x-small @click="targetItem = item, dialogDelete=true">xóa</v-btn>
                <!-- <v-btn color="teal darken-4" small @click="targetItem = item, dialogCreate=true" dark>sửa</v-btn> -->
              </template>
              <template v-slot:[`item.month`]="{ item }">
                {{ formatDate(item.month, "MM/YYYY") }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount.toLocaleString() }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- import du lieu nhan vien -->
    <v-dialog v-model="importDialog" max-width="600px">
      <v-card>
        <v-card-title >
          Import dữ liệu 
        </v-card-title>
        <v-card-subtitle>
          Tháng: {{ month }}
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="text-body-1">
          <v-row class="mt-0">
            <v-col cols="6" class="d-flex align-center">
              Loại thưởng
            </v-col>
            <v-col cols="6">
              <v-autocomplete clearable :items="bonusTypes" item-text="name" item-value="code" v-model="importExcel.bonusTypeCode"></v-autocomplete>
            </v-col>
            
            <v-col cols="6" class="d-flex align-center">
              File excel
            </v-col>
            <v-col cols="6">
              <v-file-input v-model="importExcel.file"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn small @click="importFile" dark color="teal darken-4">Import</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-dialog @fetchData="getData" :item.sync="targetItem" v-model="dialogCreate"></create-dialog>
    <confirm-dialog v-model="dialogDelete" :item="targetItem" @confirm="deleteItem" ></confirm-dialog>
  </div>
</template>

<script>

import BonusApi from './api.js'
import moment from 'moment';
import ExportExcel from '@/components/ExportExcel'
import MyBreadcrumb from '@/components/MyBreadcrumb'
import CreateDialog from './Create.vue'
import ConfirmDialog from '@/components/ConfirmDialog'


export default {
  data() {
    return {
      breadcrumb: [
        {text: "Danh mục thưởng", to: {name: 'BonusType'}}
      ],

      tabs: null,
      importExcel: {
        bonusTypeCode: '',
        file: null
      },
      bonusTypes: [],
      items: [],
      importDialog: false,
      histories: [],
      historyHeaders: [
        { text: '', value: 'actions', width: 50},
        { text: 'Mã NS', value: 'updatedBy'},
        { text: 'Vào lúc', value: 'updatedAt'},
        { text: 'Hành động', value: 'action'},
        { text: 'Mã dữ liệu', value: 'code'}
      ],
      targetItem: null,
      search: '',
      isLoading: false,
      headers: [
        { text: '', value: 'actions', cellClass: 'basic-yellow', class: 'yellow-header fixed-header', width: 50},
        { text: 'Mã', value: 'code', cellClass: 'basic-yellow', class: 'yellow-header fixed-header', width: 100},
        { text: 'MSNV', value: 'employeeCode', cellClass: 'basic-yellow', class: 'yellow-header fixed-header', width: 150 },
        { text: 'Tên NV', value: 'employeeName', cellClass: 'basic-yellow', class: 'yellow-header fixed-header', width: 150 },
        { text: 'Loại thưởng', value: 'bonusTypeName', cellClass: 'basic-yellow', class: 'yellow-header fixed-header' },
        { text: 'Tháng áp dụng', value: 'month', cellClass: 'basic-yellow', class: 'yellow-header fixed-header' },
        { text: 'Số tiền', value: 'amount', cellClass: 'basic-yellow', class: 'yellow-header fixed-header' },
      ],
      dialog: false,
      dialogDelete:false,
      dialogCreate: false,

      month: moment().startOf('month').format('YYYY-MM-DD'),
      employees: [],
      employeeLoading: false,
    }
  },
  methods: {
    formatDate(value, format = "DD/MM/YYYY") {
        return moment(value).format(format);
    },
    formatTime(value) {
        return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
    addThousandSeparator(number) {
      // Convert the number to a string
      let numberString = number.toString();

      // Split the string into integer and decimal parts
      let parts = numberString.split('.');

      // Add thousand separators to the integer part
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Join the integer and decimal parts back together
      let result = parts.join('.');

      return result;
    },

    getBonusType() {
      return BonusApi.getBonusTypes().then(d => {
        this.bonusTypes = d.data
      })
    },

    deleteItem(item) {
      return BonusApi.deleteItem(item.code).then(() => {
        this.items = this.items.filter(x=>x.code != item.code)
        this.dialogDelete = false
        this.targetItem = null
      })
    },

    openDialog(event, id) {
        if(id){
            alert(event, id)
        }else {
          this.dialogCreate = true
        }
    },
    getData() {
      return BonusApi.gets(this.month).then(d=> {
        console.log(d)
        this.items = d.data
      })
    },

    importFile() {
      console.log(this.importExcel)
      return BonusApi.importFile(this.month, this.importExcel.bonusTypeCode, this.importExcel.file)
    },

    getHistories () {
      return BonusApi.getHistories().then(d => {
        this.histories = d.data
      })
    }
  },
  components: {
    ExportExcel,
    MyBreadcrumb,
    CreateDialog,
    ConfirmDialog

  },
  created()  {
    this.getBonusType()
    this.getData()
    this.getHistories()
  }
}
</script>

<style scoped lang="scss">
// header color
// ::v-deep .fixed-header {
//   position: sticky;
//   top: 0;
//   z-index: 1;
// }
// ::v-deep .white-header{
//   color: black !important;
//   background: white;
// }
// ::v-deep .yellow-header{
//   color: black !important;
//   background: #FFF59D;
// }
// ::v-deep .blue-header{
//   color: black !important;
//   background: #90CAF9;
// }
// ::v-deep .coral-header{
//   color: black !important;
//   background: #FFAB91;
// }
// ::v-deep .green-header{
//   color: black !important;
//   background: #A5D6A7;
// }

// collumn colors
// ::v-deep tr:has(.salary-blue):hover{
//   background: rgba(0,0,0, .2);
// }

// ::v-deep .basic-yellow {
//   background: rgba(255, 253, 231, .5);
// }
// ::v-deep .salary-blue {
//   background: rgba(227, 242, 253, .5);
// }
// ::v-deep .timesheet-coral {
//   background: rgba(255, 235, 238, .5);
// }
// ::v-deep .actual-green {
//   background: rgba(232, 245, 233, .5);
// }


// ::v-deep table > tbody > tr > td:nth-child(1) {
//     position: sticky !important;
//     position: -webkit-sticky !important;
//     left: 0;
//     z-index: 0;
//     background: rgba(255, 253, 231, 1);
//   }
// ::v-deep table > thead > tr > th:nth-child(1) {
//     z-index: 1;
//     position: sticky !important;
//     position: -webkit-sticky !important;
//     left: 0;
//   }
  
// ::v-deep table > tbody > tr > td:nth-child(2)
//  {
//     position: sticky !important;
//     position: -webkit-sticky !important;
//     left: 50px;
//     z-index: 0;
//     background: rgba(255, 253, 231, 1);
//   }
// ::v-deep table > thead > tr > th:nth-child(2) {
//     z-index: 0;
//     position: sticky !important;
//     position: -webkit-sticky !important;
//     left: 50px;
//     background: red;
//   }
</style>