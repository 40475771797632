<template>
    <div class="index">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog=true" ></myBreadCrumb> -->
        <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog>
        <v-row>
            <v-col cols="6">
                <v-card>
                    <v-card-title>
                        <span class="d-none d-sm-flex">
                            Ký hiệu gốc
                        </span>
                        <v-spacer></v-spacer>
                        <!-- #FILTER MENU -->
                        <!-- <v-btn small class="mx-3" @click="exportExcel" color="teal" dark> <v-icon>mdi-database-export</v-icon> Xuất</v-btn> -->
                        
                    </v-card-title>

                    <v-data-table height="600"
                        :mobile-breakpoint="0"
                        :headers="headers"
                        :items="items"
                        :loading="isLoading"
                        loading-text="Đang tải dữ liệu..."
                    >
                        <template v-slot:[`item.signDate`]="{ item }" >
                            {{ formatDate(item.signDate) }}
                        </template>
                        
                        <template v-slot:[`item.expireDate`]="{ item }" >
                            {{ formatDate(item.expireDate) }}
                        </template>
                        
                        <template v-slot:[`item.isCurrent`]="{ item }">
                            <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">

                            <!-- ********* Edit ****** -->
                            <!-- <v-btn @click="editItem(item)" text class="mx-1 elevation-2" x-small>
                                <v-icon small>
                                    mdi-pencil
                                </v-icon> Sửa
                            </v-btn> -->

                            <!-- ********* Xoa ****** -->
                            <!-- <v-btn dark @click="deleteItem(item)" class="mx-1" color="error" x-small>
                                <v-icon small>
                                    mdi-delete
                                </v-icon>
                                Xóa
                            </v-btn> -->
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card>
                    <v-card-title>
                        <span class="d-none d-sm-flex">
                            Mapping ký hiệu
                        </span>
                        <v-spacer></v-spacer>
                        <!-- #FILTER MENU -->
                        <!-- <v-btn small class="mx-3" @click="exportExcel" color="teal" dark> <v-icon>mdi-database-export</v-icon> Xuất</v-btn> -->
                        
                    </v-card-title>

                    <v-data-table height="600"
                        :mobile-breakpoint="0"
                        :headers="mappingHeaders"
                        :items="mappings"
                        :loading="isLoading"
                        loading-text="Đang tải dữ liệu..."
                    >
                        <template v-slot:[`item.signDate`]="{ item }" >
                            {{ formatDate(item.signDate) }}
                        </template>
                        
                        <template v-slot:[`item.expireDate`]="{ item }" >
                            {{ formatDate(item.expireDate) }}
                        </template>
                        
                        <template v-slot:[`item.isCurrent`]="{ item }">
                            <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import Create from './Create.vue';

export default {
    components: {
        myBreadCrumb,
        "create-dialog": Create,
    },
    props:["breadcrumbs", "icon"],
    watch: {
        options: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
    },

    data() {
        return {
            ROOT_URL: "OvertimeScales/symbol-scale",
            //HEADER DATA

            //TABLE DATA
            filterState:[],
            
            filters2: {
                searchOns: [{name: "Tên", code:"name"}, {name: "Mã", code:"code"}],
            },
            defaults: {
                searchOn: "name"
            },

            headers: [
                {text:"", value: "actions", width: "200px"},
                {text:"Mã", value: "symbol"},
                {text:"Tên", value: "scale"},
                {text:"Tăng ca", value: "description"},
            ],
            items: [],

            
            mappingHeaders: [
                {text:"", value: "actions", width: "200px"},
                {text:"Ký hiệu", value: "symbol"},
                {text:"Ký hiệu cha", value: "parent"},
                {text:"Mô tả", value: "description"},
            ],
            mappings: [],

            options: {},
            totalItem: 0,
            isLoading: true,

            //DIALOG
            item:null,
            dialog: false,
            dialogDelete: false,
            targetItem: {},

        }
    },
    methods: {
        //DATA JS
        fetchData (e) {
            this.isLoading = true;
            return http.get(this.ROOT_URL)
            .then(res => {
                var data =res.data;
                this.items = data.parentSymbols;
                this.mappings = data.symbolMaps;
                this.isLoading = false;
            }).catch(err => {})
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {

            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem(item){
            this.item = item;
            this.dialog = true;
        },
        
        deleteItem(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },

    },
    created() {
        this.fetchData();
    }
}
</script>