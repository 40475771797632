<template>
<div>
    <!-- <side-bar ref="sideBar" :items="items"></side-bar> -->
    <!-- <v-main> -->
      <v-container style="min-height:calc(75vh - 124px)" class="mt-3">
        <v-fade-transition mode="out-in">
          <!--The Main Content component go here-->
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    <!-- </v-main> -->
</div>
</template>
<script>
import http from '@/components/services/http-common';
import SideBar from "@/components/SideBar.vue";
export default {
    components:{
        SideBar
    },
    
    data(){
      return {
        dbMenus: [],
        items: [
          { title: "Xác nhận công", subheader:true },
          { title: "Giờ công", icon: "mdi-electron-framework", route:{ name: 'TimeSheet'}, auth: "module:salary"},
          { title: "Chấm công", icon: "mdi-clock-time-one", route:{ name: 'Attendance'}, auth: "module:salary"},
          { title: "Đơn từ", subheader:true },
          { title: "Làm thêm", icon: "mdi-note-text-outline", route:{name:"SalaryConfirmOvertime"}, auth: ["module:salary"]},
          { title: "Công tác", icon:"mdi-note-text-outline", route:{name:"SalaryConfirmBusiness"}, auth: ["module:salary"]},
          { title: "Đơn nghỉ phép", icon: "mdi-note-text-outline", route:{name: 'SalaryConfirmLeave'}, auth: "module:salary"},
          // { title: "Nghỉ dài hạn", icon: "mdi-car-estate", route:{name:"SalaryConfirmLongLeaveRequest"}, auth: ["module:salary"]},
          // { title: "Tăng ca tối đa", icon: "mdi-image-filter-hdr", route:{name:"SalaryConfirmOvertimeBooking"}, auth: ["module:salary"]},

          { title: "Tổng hợp giờ công", subheader:true },
          { title: "Chốt giờ công", icon: "mdi-checkbox-marked", route:{name: 'MonthlyTimeSheet'}, auth: "module:salary"},
          { title: "Thông tin lương", icon: "mdi-upload", route:{name:"SalaryImport"}, auth: ["sal:imp"]},
          { title: "Phản hồi lương", icon: "mdi-headset", route:{name:"SalarySupport"}, auth: ["sal:imp"]},
          { title: "Phản hồi x/n công", icon: "mdi-headset", route:{name:"TimesheetSupport"}, auth: ["sal:imp"]},

          { title: "Lương (in progress)", subheader:true },
          { title: "Lương nhân viên", icon: "mdi-currency-usd", route:{ name: 'Salary'}, auth: "module:salary"},
          { title: "Điều chỉnh lương", icon: "mdi-currency-usd", route:{ name: 'SalaryAdjustment'}, auth: "module:salary"},
          { title: "Bảng lương", icon: "mdi-currency-usd", route:{ name: 'EmployeePayroll'}, auth: "module:salary"},

          { title: "Thưởng & phụ cấp", subheader:true },
          { title: "Danh mục thưởng", icon: "mdi-gift-outline", route:{ name: 'BonusType'}, auth: "module:salary"},
          { title: "Chi tiết thưởng", icon: "mdi-gift-outline", route:{ name: 'Bonus'}, auth: "module:salary"},
          { title: "Phụ cấp", icon: "mdi-handshake", route:{ name: 'Supplement'}, auth: "module:salary"},
          { title: "Người phụ thuộc", icon: "mdi-human-female-boy", route:{ name: 'SalaryEmployeeFamily'}, auth: "module:salary"},

          { title: "Cài đặt", subheader:true },
          { title: "Hệ thống", icon: "mdi-cog-outline", route:{ name: 'SalarySetting'}, auth: "module:salary"},
          { title: "Cài đặt lương bảo vệ", icon: "mdi-counter", route:{ name: 'SecuritySalary'}, auth: "module:salary"},
          { title: "Lịch sử thay đổi lương", icon: "mdi-counter", route:{ name: 'MonthlySalary'}, auth: "module:salary"},
          { title: "Lương theo chức vụ", icon: "mdi-cog-outline", route:{ name: 'SalaryConfig'}, auth: "module:salary"},
          { title: "Hệ số tăng ca", icon: "mdi-counter", route:{ name: 'OvertimeScale'}, auth: "module:salary"},
        ],
      }
    },
    methods: {
      
      getMenu() {
        return http.get("menus/sections/SALARY").then(r => {
          this.dbMenus = r.data.data.map(x => {
            return {
              title: x.title,
              icon: x.icon,
              route: {name: x.routeName},
              auth: x==null ? '' :x.claims.split(','),
              subheader: x.type == 'SUBHEADER',
              group: x.type == 'GROUP' ? x.href : undefined,
              subs: x.subMenus ==null? undefined: x.subMenus.map(s => {
                return {
                  title: s.title,
                  icon: s.icon,
                  route: {name: s.routeName},
                  auth: x==null ? '' :s.claims.split(','),
                  subheader: s.type == 'SUBHEADER',
                }
              })
            }
          })

          // console.log(this.dbMenus)
        })
      },

    },
    beforeMount() {
    },

    created(){
      this.getMenu().then(() => {
        this.$emit('updateSidebar', this.dbMenus);
      })
    },
}
</script>