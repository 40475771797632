import http from '@/components/services/http-common';
import ApiService from '@/components/services/ApiServices';
export default {
    
    methods: {
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        selectAllPosition() {
            this.form.positionCodes=this.positions;
        },
        fetchPositions() {
            return ApiService.fetchPosition().then(res => {
                this.positions= res.data;
            })
        },
        
        fetchLeavePlans () {
            return http.get("leavePlans").then(res => {
                this.leavePlans = res.data;
            })
        },
        fetchData () {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                let item = res.data;
                this.form = Object.assign({}, item);
                this.form.positionCodes = item.positionPositionStates.map(x=> {return x.positionCode})
                this.form.leavePlans = item.benefits.map(x=> {return x.leavePlanCode})
            })
            .catch(err => {
                console.log(err);
            });
        },
        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                if(!this.value.item)
                {
                    return http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                } else {
                    return http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
                this.close();
            }
        },
    },
}