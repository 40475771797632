<template>
    <v-dialog v-model="dialog" max-width="800px" scrollable @click:outside="close" :retain-focus="false">
        <v-card>
            <v-card-title>{{isEdit ? 'Thay đổi': 'Tạo'}} báo cáo {{isEdit ? item.id: ''}} 
            <v-spacer></v-spacer>
            <v-btn icon color="red" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-divider class="my-4"></v-divider>
            <v-card-text class="text--primary px-0">
                <v-stepper v-model="step" class="elevation-0" flat non-linear>
                    <v-stepper-header class="elevation-0">
                        <v-stepper-step editable step="1" >
                        Chọn loại báo cáo
                        </v-stepper-step>
                        <v-divider></v-divider>
                        
                        <v-stepper-step step="2" >
                        Thông tin báo cáo
                        </v-stepper-step>
                    </v-stepper-header>
                    
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card class="mb-12" flat :loading="fetchLoading">
                                <v-row>
                                    <v-col cols="12" md="6" v-for="(category, key) in categories" :key="key">
                                        <div class="column-content">
                                            <v-card dark style="background-color: rgba(0,0,0,.75); background-blend-mode: multiply; background-image: url('https://img.freepik.com/free-photo/back-view-man-taking-pictures-outdoors-with-smartphone_23-2148682664.jpg?w=826&t=st=1691728448~exp=1691729048~hmac=ce42404a78dca86e7105fdf61039818f5855a2e86eadfd140947e988e20d64a9'); background-size: 100%;">
                                                <!-- <v-img :src="''"></v-img> -->
                                                <v-card-title style="word-break: break-word; ">
                                                    {{ category.name }}
                                                </v-card-title>
                                                <v-card-subtitle>
                                                    <b class="red--text">Phòng ban nhận báo cáo: </b> 
                                                    {{ category.department.name }}
                                                </v-card-subtitle>
                                                <v-card-text class="d-none d-md-block">
                                                    <b class="red--text">Mô tả: </b> <br>
                                                    <span v-html="category.description"></span>
                                                </v-card-text>
                                                <v-card-actions class="mt-3">
                                                    <v-btn v-if="!fetchLoading" block dark color="red" @click="step++, form.employeeReportCategoryCode = category.code, form.fromDepartmentCode = category.departmentCode">
                                                        Chọn danh mục
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-card class="mb-12" flat>
                                <v-row>
                                    <v-col cols="12">
                                        <v-form ref="form" v-model="valid" @submit.prevent="save">
                                            
                                            <v-row class="my-0">
                                                <v-col cols="12" md="6">
                                                    <span class="text-subtitle-2">Thông tin báo cáo</span>
                                                    <p class="text-subtitle-2 red--text"><small>Các dữ liệu có dấu (*) là bắt buộc</small></p>
                                                    <p class="text-caption text--secondary"> Kiểm tra lại loại báo cáo và nơi gởi báo cáo</p>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-select readonly :items="toDepartments" item-text="name" item-value="code" v-model="form.toDepartmentCode" 
                                                            persistent-placeholder hide-details="auto"
                                                            :rules='[v=>!!v||"Chọn dữ liệu"]'
                                                            label="Phòng ban nhận báo cáo" outlined ></v-select>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-select readonly :items="categories" item-text="name" item-value="code" v-model="form.employeeReportCategoryCode" label="Danh mục báo cáo" placeholder="Chọn một"
                                                            :rules='[v=>!!v||"Chọn dữ liệu"]'
                                                            outlined hide-details="auto" persistent-placeholder></v-select>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-divider></v-divider>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <span class="text-subtitle-2">Nội dung về báo cáo</span>
                                                    <p class="text-caption text--secondary"> Nhập dữ liệu báo cáo</p>
                                                </v-col>
                                                
                                                <v-col cols="12" md="6">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-autocomplete :items="fromDepartments" v-model="form.fromDepartmentCode" item-text="name" item-value="code" 
                                                            persistent-placeholder hide-details="auto"
                                                            :rules='[v=>!!v||"Chọn dữ liệu"]'
                                                            label="Phòng ban gởi báo cáo *" outlined ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-text-field label="Tiêu đề báo cáo *" v-model="form.name" hide-details="auto"
                                                            :rules='[v=>!!v||"Chọn dữ liệu"]'
                                                            persistent-placeholder outlined ></v-text-field>
                                                        </v-col>
                                                        <v-subheader class="mb-0">Thông tin báo cáo</v-subheader>
                                                        <v-col cols="12" class="pt-0">
                                                            <editor v-model="form.fromDepartmentNote">
                                                            </editor>
                                                        </v-col>
                                                        <v-col>
                                                            <!-- <v-text-field v-model="form.images" v-show="false"></v-text-field> -->
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                
                                                <v-col cols="12">
                                                    <v-divider></v-divider>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <span class="text-subtitle-2">Dữ liệu hình ảnh</span>
                                                    <p class="text-caption text--secondary">Upload hình ảnh theo mô tả đưa ra</p>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-row>
                                                        <v-col cols="12" v-for="(imageSlot, key) in imageSlots" :key="key">
                                                            <v-tooltip top color="rbga(0,0,0,1)" open-delay="300">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-img v-if="!form.imageDatas[imageSlot.code]" :src="imageSlot.background" style="opacity: .5;" v-bind="attrs" v-on="on"></v-img>
                                                                    <v-img  v-else :src="form.imageDatas[imageSlot.code]" style="opacity: .5;" v-bind="attrs" v-on="on"></v-img>
                                                                </template>
                                                                <v-img v-if="!form.imageDatas[imageSlot.code]" width="600px" :src="imageSlot.background" style=""></v-img>
                                                                <v-img v-else width="600px" :src="form.imageDatas[imageSlot.code]" style=""></v-img>
                                                            </v-tooltip>
                                                            <v-file-input class="my-2" :rules='[v=>!!v||"Chọn dữ liệu"]'
                                                            hide-details="auto" outlined :label="imageSlot.name"  :loading="uploading"
                                                            v-model="imageFileDicts[imageSlot.code]" :multiple="false" @change="uploadImageFileDict(imageSlot.code, $event)">
                                                            </v-file-input>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                            </v-row>
                                            <v-row class="mt-1">
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                </v-row>
                        </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="uploading || step !=2" color="primary" form="form" @click="save">Lưu </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from './services/api.js'
import moment from 'moment/moment'
import Editor from '@/components/Editor/Editor';

export default {
    props:["value"],
    components: {
        "editor": Editor
    },
    watch:{
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    console.log("check is edit")
                    if(this.isEdit)
                    {
                        this.fetch()
                        this.headerCreated = true
                    }
                }
                else{
                    this.close();
                }
            },
        },

        // imageFiles() {
        //     this.form.images = ""
        // },
        
        employeeTextPaste(newVal) {
            this.form.ApplyToEmployeeCodes = newVal.length==0? []: newVal.split(',')
        },
        step(value) {
            if(value == 1) {
                this.form = Object.assign({}, this.defaultForm);
                this.form.toDepartmentCode = 'P00135'
                this.headerCreated = false;
                this.$refs.form.resetValidation();
            }
        }

    },
    computed: {
        // dialog: {
        //     get: function () {
        //         return this.value;
        //     },
        //     set: function (val){
        //         this.$emit("input", val)
        //     }
        // },
        fetchLoading() {
            return !(this.fetchReady['category']== true && this.fetchReady['fromDepartment']== true &&this.fetchReady['toDepartment']== true)
        },
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        isEdit() {
            return !!this.item;
        },
        validDetail(){
            console.log(this.rules.required);
        },
        

        employeeReportCategoryDescription() {
            let result = "";
            if(this.categories.length>0){
                if(this.form.employeeReportCategoryCode)
                {
                    let resultObj = this.categories.filter(x=>x.code == this.form.employeeReportCategoryCode)
                    if(resultObj.length>0){
                        result = resultObj[0].description
                    }
                }
            }
            return result
        },
        
        imageSlots() {
            let result = "";
            if(this.categories.length>0){
                if(this.form.employeeReportCategoryCode)
                {
                    let resultObj = this.categories.filter(x=>x.code == this.form.employeeReportCategoryCode)
                    if(resultObj.length>0){
                        result = resultObj[0].employeeReportCategoryImages
                        console.log(result)
                        result.forEach(imgslot => {
                            this.imageFileDictLoadings[imgslot.code] = false
                            console.log(imgslot)
                        })
                    }
                }
            }
            return result
        },
        imageStatus() {
            var result = {
                name: 'Hình ảnh khả dụng',
                color: 'green',
                value: 1
            }
            this.imageFiles.forEach(imageFile => {
                let lastModifiedTimestamp = imageFile.lastModified;
                let createdDate = moment(lastModifiedTimestamp)
                console.log(createdDate)
                if(createdDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                    result = {
                        name: 'Hình ảnh cũ, kiểm tra lại các hình ảnh chụp trong hôm nay',
                        color: 'error',
                        value: 0
                    } 
                }
            });
            return result
        }
    },
    data() {
        return {
            cameraOrUpload: 0,
            fetchReady: {
                'category': false,
                'toDepartment': false,
                'fromDepartment': false,
            },
            imageFiles: [],
            
            img: null,
            camera: null,
            deviceId: null,
            devices: [],
            imageUploading: false,
            uploaded: 0,
            totalUpload: 0,
            
            qrSrc: "",
            valid: false,
            statuses: [{text: 'Áp dụng tất cả phòng ban', value: 1}, {text: 'Áp dụng một số', value: 0}],

            dialog: false,
            item: null,

            toDepartments:[],
            fromDepartments:[],

            //steppers
            step: 1,

            form: {
                name: "",
                toDepartmentCode: "",
                fromDepartmentCode: "",
                employeeReportCategoryCode: "",
                images: "",
                imageDatas: {},
                toDepartmentNote: "",
                fromDepartmentNote: "",
                status: 0

            },
            defaultForm: {
                name: "",
                toDepartmentCode: "",
                fromDepartmentCode: "",
                employeeReportCategoryCode: "",
                images: "",
                imageDatas: {},
                toDepartmentNote: "",
                fromDepartmentNote: "",
                status: 0
            },
            employeeTextPaste:'',
            imageFileDicts:{},
            imageFileDictLoadings:{},

            /**header */
            categories: [],
            departments: [],
            employees:[],
            /** Detail Header*/
            uploading: false,
            detailHeaders: [
                {text:"Tên dụng cụ", value: "id", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "name", width:"7rem", align:"center"},
                {text:"Số lượng", value: "toDepartmentCode", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "employeeReportCategoryCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "images", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "toDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "status", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdBy", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedBy", width:"7rem", align:"center"},
                {text:"", value: "actions", width:"7rem", align:"center"},
            ],
            properties:[],
            detail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
            },
            detailItems:[],

            propertyValid: false,
            amountValid: false,

            
            rules: {
                required: value => !!value || 'Required.'
            },

            /**Stepper params */
            headerCreated: false,
        }
    }, 
    methods: {
        
        onCapture() {
            this.img = this.$refs.webcam.capture();
            // convert to blob then upload
            const contentType = 'image/jpeg';
            const filename = 'example.jpg';
            
            const file = this.base64ToFile(this.img.split(',')[1], filename);

            console.log(file)
            this.imageUploading = true
            this.totalUpload +=1
            this.apiService.uploadFile([file]).then(d => {
                var joinString = [this.form.images, d]
                this.form.images = joinString.filter(x=>!!x).join(';')
            }).finally(() => {
                this.uploaded +=1
                if(this.totalUpload == this.uploaded){
                    this.imageUploading = false
                }
            })
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },

        uploadFiles() {
            this.imageUploading = true
            this.totalUpload = this.imageFiles.length

            return this.apiService.uploadFile(this.imageFiles).then(d => {
                var joinString = [this.form.images, d]
                this.form.images = joinString.filter(x=>!!x).join(';')
            }).finally(() => {
                this.uploaded = this.totalUpload
                if(this.totalUpload == this.uploaded){
                    this.imageUploading = false
                }
            })
        },

        base64ToFile(base64String, filename, mimeType = ''){
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });

            return new File([blob], filename);
        },

        getUnit(property) {
            var data = this.properties.find(x=>x.code == property);
            if(!data)
                return ""
            else
                return data.propertyUnitName
        },
        getDepartments() {
            this.apiService.getDepartments().then(d => {
                this.departments = d
            })
        },

        getCategories() {
            return this.apiService.getCategories(this.form.toDepartmentCode).then(d => {
                this.categories = d
                this.fetchReady['category'] = true

            })
        },

        getEmployees() {
            this.apiService.getEmployees().then(d => {
                this.employees = d
            })
        },
        close(){
            this.dialog = false
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.$refs.form.resetValidation();
            this.form.toDepartmentCode = 'P00135'

            this.uploaded = 0
            this.totalUpload = 0
            this.step=1

            this.$emit('fetchData');
            this.$emit('close-dialog');


        },

        save() {
            this.$refs.form.validate();
            if(this.valid)
            {
                //tao phieu
                if(!this.isEdit) {
                    return this.apiService.post( this.form).then(res => {
                        // this.$emit("fetchData", res.data.code);
                        this.close();
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    });
                }

                //update phieu
                if(this.isEdit) {
                    return this.apiService.putTemplate(this.item,this.form).then(res => {
                        // this.$emit("fetchData");
                        this.close();
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    });
                }
            }
        },
        uploadImageFileDict(code, value){
            this.imageFileDictLoadings[code] = true
            this.uploading = true
            console.log(this.imageFileDictLoadings)
            return this.apiService.uploadFile([value]).then(d => {
                this.uploading = false
                this.form.imageDatas[code] = d
            })
        },
        // fetch() {
        //     this.apiService.get('2023-07-01').then(d => {
        //         this.items
        //     })
        // },
        getToDepartments() {
            this.apiService.getToDepartments().then(d => {
                console.log(d)
                this.toDepartments = d
                this.fetchReady['toDepartment'] = true
            })
        },

        getFromDepartments() {
            this.apiService.getFromDepartments().then(d => {
                this.fromDepartments = d
                this.fetchReady['fromDepartment'] = true
            })
        }
        // fetchDepartment()
        // {
        //     return http.get("propertyRequests/get-departments").then(res => {
        //         this.departments = res.data;
        //     });
        // },
        // fetchProperty()
        // {
        //     return http.get("properties").then(res => {
        //         this.properties = res.data.items;
        //     });
        // },
        // fetchDetail() {
        //     let url = `propertyRequests/detail/${this.form.code}`
        //     return http.get(url).then(res => {
        //         this.detailItems = res.data;
        //     })
        // },
        

        //SERVICES
    },
    apiService: null,
    created() {
        
        this.apiService = new api()

        this.form.toDepartmentCode = 'P00135'
        this.getCategories()
        this.getToDepartments()
        this.getFromDepartments()
    },
    mounted() {
        
        // this.startVideo()
        // this.getDepartments();
        // this.getEmployees()
    }
}
</script>
<style scoped>
    .column-content {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .v-tooltip__content {
        opacity: 1 !important;
        padding: 0px;
        border-width: 2px;
        border-style: solid;
        border-color: black;
    }
</style>