<template>
    <v-tooltip bottom color="info">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" @click="$emit('click')" class="ma-1" color="info" icon>
            <v-icon small>{{customIcon?icon:'mdi-magnify'}}</v-icon>
        </v-btn>
      </template>
      <span> Chi tiết</span>
    </v-tooltip>
</template>
<script>
export default {
    props:["icon"],
    computed:{
        customIcon() {
            return !!this.icon
        }
    }
}
</script>