import http2 from "@/components/services/http-common2"

const TOKEN_URL = "kiemke/checkrolekiemke"
const GET_PHIEU_URL = "dutruhang/danhsachdutruhang"


const SET_TINHTRANG_URL = "dutruhang/capnhattinhtrangdathang"

const GET_HANG_HOA_URL = "https://apikiemke.trungsonpharma.com"
const GET_HANG_HOA_UNIT_URL = "dutruhang/getdonvitinhhanghoa"
const SETT_KHONG_HOA_DON_URL = "dutruhang/capnhattinhtrangdathang"
var TOKEN = ""

export default class Api {

    
    getToken = (user_id) => http2.get(`${TOKEN_URL}`, {
        params: {
            user_id: user_id
        }
    }).then(r => {
        TOKEN = r.data.token
        return r.data
    })

    getPhieu = (token, user_id, fromDate = '2023-06-01', toDate='2100-01-01') => {
        const token2 = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsInByaUtleSI6ImFjZjAxMzQxMDE5MjE1OGJhODBlZGY0MmEyMjIxZTRmIn0.eyJ1c2VyX2lkIjoiTlMyOTc3IiwiZXhwaXJlcyI6MTY4NzA3ODIwMC44Njc5NzU3fQ.VI-8IKg6Cj1BTC_TS2Bv8wFd84ob6s3POQDP0v90Ftw"
        if(!user_id) return false
        return http2.get(`${GET_PHIEU_URL}`, {
            params: {
                user_id: user_id,
                tungay: fromDate,
                denngay: toDate,
            },
            
            headers: {
                'Authorization': `Bearer ${TOKEN}`
            }
        }).then(r => r.data)
    }

    setTinhTrangNote = (ID, user_capnhat, note, mode) => {
        if(mode == "ACCEPT")
            return http2.post(SET_TINHTRANG_URL, {
                "ID": ID,
                "dat_duoc": note,
                "user_capnhat": user_capnhat
            }, {
                headers: {
                    'Authorization': `Bearer ${TOKEN}`
                }
            })
        if(mode == "DENY") 
            return http2.post(SET_TINHTRANG_URL, {
                "ID": ID,
                "khong_datduoc": note,
                "user_capnhat": user_capnhat
            }, {
                headers: {
                    'Authorization': `Bearer ${TOKEN}`
                }
            })
    }

    setTinhTrang = (ID, user_xacnhan, tinh_trang) => {
        return http2.post(SET_TINHTRANG_URL, {
            ID: ID,
            user_xacnhan: user_xacnhan,
            tinh_trang: tinh_trang
        }, {
            headers: {
                'Authorization': `Bearer ${TOKEN}`
            }
        })
    }

    getHangHoa = (danhmuc="No_", giatri="") => {
        return http2.get(GET_HANG_HOA_URL, {
            params: {
                danhmuc: danhmuc,
                giatri:giatri
            }
        }, {
            headers: {
                'Authorization': `Bearer ${TOKEN}`
            }
        })
    }
    
    getHangHoaUnit = (mahanghoa, user_id) => {
        return http2.get(GET_HANG_HOA_UNIT_URL, {
            params: {
                mahanghoa: mahanghoa,
                user_id:user_id
            }
        }, {
            headers: {
                'Authorization': `Bearer ${TOKEN}`
            }
        })
    }

    setKhongHoaDon = (data) => http2.post(SETT_KHONG_HOA_DON_URL,{
        "ID": data.ID,
        "user_capnhat": data.user_capnhat,
        "khong_hoadon": data.khong_hoadon
    }, {
        headers: {
            'Authorization': `Bearer ${TOKEN}`
        }
    }).then(r=> r.data)
}
