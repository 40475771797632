
import myBreadCrumb from '@/components/BreadCrumb';
import Chart from '@/components/Charts/Chart.vue';
import http from '@/components/services/http-common';
import * as moment from "moment/moment";
export default {
    components: {
        myBreadCrumb,
        "chart": Chart
    },
    props: ["breadcrumbs", "icon"],
    computed: {
        years() {
            let result = [];
            let startYear = 2000;
            result.push(startYear);
            for(var i=0; i<100; i++){
                var newYear= startYear+i;
                result.push(newYear);
            }
            return result;
        }
    },
    watch: {
        "ratioFilter":{
            handler(value){
                this.fetchTotalGraph();
            },
            deep: true
        },
        
        eduGraphYear(){
            this.fetchEduGraph();
        },
        asmBaseMonth(){
            this.fetchAsmBaseGraph();
        },
        
        selectedUniversities: {
            handler: function (val, oldVal) {
                this.fetchEduGraph();
            },
            deep: true
        }
    },
    data(){
        return {
            params:{
                time: "MONTH"
            },
            chartType: "doughnut",
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "Công việc",
                        data: [],
                        backgroundColor: ["#DCEDC8"],
                        // borderColor: ["#33691E"],
                        borderWidth: 2
                    },
                ],
            },
            chartAreaData:{
                labels: [],
                datasets: [
                    {
                        label: "Khu vực",
                        data: [],
                        backgroundColor: ["#DCEDC8"],
                        // borderColor: ["#33691E"],
                        borderWidth: 2
                    },
                ],
            },
            ratioFilter:{
                month: moment().startOf("months").format("yyyy-MM"),
                departmentType:"K00002"
            },
            chartTotalData: {
                label:"",
                labels: [],
                datasets: [
                    {
                        label: "Tên dataset1",
                        data: [],
                        backgroundColor: ["#F9FBE7"],
                        borderColor: ["white"],
                        borderWidth: 2
                    },
                ],
            },
            chartTotalScale: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true
                }
            },

            eduGraphYear: moment().format('YYYY-MM'),
            chartEduData: {
                label:"",
                labels: [],
                
                datasets: [
                    {
                        label: "Số lượng",
                        data: [],
                        backgroundColor: []
                    },
                ],
            },

            universities: [],
            selectedUniversities: [ "DHCT", "DHTD", "YCT"],

            asmBaseMonth: moment().startOf("months").format("yyyy-MM"),
            chartAsmBaseData: {
                label:"",
                labels: [],
                datasets: [
                    {
                        label: "Nhận việc",
                        data: [],
                        backgroundColor: ["blue"],
                        borderColor: ["white"],
                        borderWidth: 2
                    },
                ],
            },

            employeeFluctuateMonth: moment().startOf("months").format("yyyy-MM"),
            employeeFluctuateData: {
                label:"",
                labels: [],
                datasets: [
                    {
                        label: "Tên dataset1",
                        data: [],
                        backgroundColor: ["green"],
                        borderColor: ["white"],
                        borderWidth: 2
                    },
                ],
            },

            monthlyCandidateMonth: moment().startOf("months").format("yyyy-MM"),
            monthlyCandidateData: {
                label:"",
                labels: [],
                datasets: [
                    {
                        label: "Tên dataset1",
                        data: [],
                        backgroundColor: ["green"],
                        borderColor: ["white"],
                        borderWidth: 2
                    },
                ],
            },
        }
    },
    methods:{
        fetch(){
            return http.get("candidates/overview", {
                params: this.params
            }).then(res => {
                let jobOverviewResults = res.data.jobResult;
                jobOverviewResults.forEach(x => {
                    this.chartData.labels.push(x.job.name);
                    this.chartData.datasets[0].data.push(x.value);
                    this.chartData.datasets[0].backgroundColor.push(this.getRandomColor());
                });
                this.$refs.chart.update();
                
                let areaData = res.data.areaData;
                areaData.forEach(x => {
                    this.chartAreaData.labels.push(x.city);
                    this.chartAreaData.datasets[0].data.push(x.count);
                });
                this.$refs.chartArea.update();
                
            }).then(()=> {

            })
        },

        fetchTotalGraph () {
            // FETCH DATA TOTAL GRAPH
            return http.get("CandidateReport", {
                params: this.ratioFilter
            }).then(res => {
                // this.chartTotalData = Object.assign({}, res.data.totalGraph);
                let data = res.data.totalGraph;
                this.chartTotalData.labels = data.labels;
                console.log(this.chartTotalData.backgroundColor );

                this.chartTotalData.datasets[0].label = data.datasets[0].label;
                this.chartTotalData.datasets[0].data = data.datasets[0].data;
                this.chartTotalData.datasets[0].backgroundColor = data.colors;
                this.$refs.chartTotal.update();
            })
        },

        // FETCH DATA TOTAL GRAPH
        fetchAsmBaseGraph () {
            return http.get("CandidateReport/total-asm-base", {
                params: {
                    month: this.asmBaseMonth
                }
            }).then(res => {
                // this.chartTotalData = Object.assign({}, res.data.totalGraph);
                let data = res.data.graph;  
                this.chartAsmBaseData.labels = data.labels;

                this.chartAsmBaseData.datasets[0].label = data.datasets[0].label;
                this.chartAsmBaseData.datasets[0].data = data.datasets[0].data;
                
                this.chartAsmBaseData.datasets = [];
                data.datasets.forEach(dataset => {
                    var obj = {};
                    obj["label"] = dataset.label;
                    obj["data"] = dataset.dataObj.map(i=>{
                        return  {
                            x : i.y,
                            y : i.x
                        }
                    });
                    // obj["type"] = dataset.label == "Kế hoạch"? "line" : "line"
                    obj["backgroundColor"] = dataset.label == "Kế hoạch"? ["orange"] : ["gray"] ;
                    this.chartAsmBaseData.datasets.push(obj)
                });
                this.$refs.chartAsmBase.update();
            })
        },
        
        fetchMonthlyCandidateGraph () {
            // FETCH DATA TOTAL GRAPH
            return http.get("CandidateReport/monthly-candidate", {
                params: {
                    month: this.monthlyCandidateMonth
                }
            }).then(res => {
                // this.chartTotalData = Object.assign({}, res.data.totalGraph);
                let data = res.data.graph;
                this.monthlyCandidateData.labels = data.labels;

                this.monthlyCandidateData.datasets[0].label = data.datasets[0].label;
                this.monthlyCandidateData.datasets[0].data = data.datasets[0].data;
                
                this.monthlyCandidateData.datasets = [];
                data.datasets.forEach(dataset => {
                    var obj = {};
                    obj["label"] = dataset.label;
                    obj["data"] = dataset.dataObj;
                    obj["backgroundColor"] = dataset.label == "Kế hoạch"? ["orange"] : ["green"] ;
                    this.monthlyCandidateData.datasets.push(obj)
                });
                this.$refs.chartMonthlyCandidate.update();
            })
        },

        fetchEmployeeFluctuateGraph () {
            // FETCH DATA TOTAL GRAPH
            return http.get("CandidateReport/employee-fluctuate", {
                params: {
                    month: this.employeeFluctuateMonth
                }
            }).then(res => {
                // this.chartTotalData = Object.assign({}, res.data.totalGraph);
                let data = res.data.graph;
                this.employeeFluctuateData.labels = data.labels;

                this.employeeFluctuateData.datasets[0].label = data.datasets[0].label;
                this.employeeFluctuateData.datasets[0].data = data.datasets[0].data;
                
                this.employeeFluctuateData.datasets = [];
                var color = 0;
                data.datasets.forEach(dataset => {
                    var obj = {};
                    obj["label"] = dataset.label;
                    obj["data"] = dataset.dataObj;
                    obj["backgroundColor"] = [color%2==0?"#54DE9C":"#EF7843"];
                    this.employeeFluctuateData.datasets.push(obj)
                    color++;
                });
                this.$refs.chartEmployeeFluctuate.update();
            })
        },

        fetchEduGraph(){
            return http.get("CandidateReport/edu", {
                params: {
                    year: moment(this.eduGraphYear, 'YYYY-MM').year(),
                    month: moment(this.eduGraphYear, 'YYYY-MM').month()+1,
                    selectedUniversities: this.selectedUniversities
                    }
            }).then(res => {
                var eduData = res.data.eduGraph;
                this.chartEduData.labels = [];
                this.chartEduData.datasets[0].data = [];
                eduData.datasets.forEach(dataset => {
                    
                    this.chartEduData.labels.push(dataset.label);
                    this.chartEduData.datasets[0].data.push(dataset.dataObj[0].y);
                    this.chartEduData.datasets[0].backgroundColor.push(this.getRandomColor());
                    console.log(dataset.dataObj);
                    // var obj = {};
                    // obj["label"] = dataset.label;
                    // obj["data"] = dataset.dataObj;
                    // obj["backgroundColor"] = [this.getRandomColor()];
                    // this.chartEduData.datasets.push(obj)
                });

                this.$refs.chartEdu.update();
            })
        },

        fetchUni(){
            return http.get("universities/fetch").then(res => {
                this.universities = res.data;
            });
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }
    },
    created(){
        this.fetch();
        this.fetchTotalGraph();
        this.fetchEduGraph();
        this.fetchUni();
        this.fetchAsmBaseGraph();
        this.fetchEmployeeFluctuateGraph();
        this.fetchMonthlyCandidateGraph();
    }
    
}