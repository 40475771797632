<template>
    <div class="assignment">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog> -->
        
        <create-dialog ></create-dialog>
        <v-tabs
            v-model="tab"
        >
            <v-tab>
                Chờ xác nhận
            </v-tab>
            <v-tab>
                Sẵn sàng nhập dữ liệu
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item eager>
                <v-card>
                    <v-card-title>
                        Danh sách phiếu
                    <v-spacer></v-spacer>
                    <v-btn icon class="px-0 mx-1" color="orange" light>
                        <v-icon @click="fetchData">mdi-refresh</v-icon>
                    </v-btn>
                    <create-button @click="dialog.state=true" ></create-button>
                    <!-- <data-export v-model="exportDialog"></data-export> -->
                    <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
                    </data-filter> -->
                    </v-card-title>
                    
                    <v-data-table
                    :mobile-breakpoint="0"
                    height="600px" fixed-header
                    :headers="headers"
                    :items="items"
                    :loading="isLoading"
                    loading-text="Đang tải dữ liệu..."
                    :footer-props="{
                        itemsPerPageOptions:[
                        50, 100, 200
                        ]
                    }"
                    >
                        <!-- <template v-slot:[`body.prepend`]="" >
                            <tr>
                                <td></td>
                                <td>
                                    <v-select placeholder="Chọn" dense v-model="filters.status" hide-details outlined
                                    :items='[{text:"Tất cả", value:-1}, {text:"Chờ XN", value:0}, {text:"Đã XN", value:1}, {text:"Từ chối", value:2}]'></v-select>
                                </td>
                                <td>
                                    <v-text-field placeholder="Nhập" dense v-model="filters.code" hide-details outlined></v-text-field>
                                </td>
                                <td>
                                    <v-select placeholder="Chọn" dense v-model="filters.leaveAmountType" hide-details outlined
                                    :items='[{text:"Cả ngày", value:1}, {text:"Nữa ngày", value:0.5}, {text:"Cả hai", value:2}]'></v-select>
                                </td>
                                <td>
                                    <v-select placeholder="Chọn" dense v-model="filters.leavePlanCode" :items="leavePlans" item-text="name" item-value="code"
                                    hide-details outlined></v-select>
                                </td>
                                <td>
                                    <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" hide-details outlined></v-text-field>
                                </td>
                                <td>
                                    <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" hide-details outlined></v-text-field>
                                </td>
                                <td>
                                    <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode" hide-details outlined
                                    item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                                    @update:search-input="fetchDepartment"></v-autocomplete>
                                </td>
                                <td>
                                    <v-text-field v-model="filters.from" dense hide-details outlined type="date"></v-text-field>
                                    <v-text-field v-model="filters.to" dense hide-details outlined type="date"></v-text-field>
                                </td>
                                <td colspan="100%"></td>
                            </tr>
                        </template> -->

                        <template v-slot:[`item.view`]="{item}">
                            <v-icon color="primary" @click="openViewDialog(item)">mdi-eye</v-icon>
                        </template>
                        <template v-slot:[`item.from`]="{item}">
                            {{formatDate(item.from)}}
                        </template>
                        
                        <template v-slot:[`item.sentDate`]="{item}">
                            {{formatDate(item.sentDate)}}
                        </template>

                        <template v-slot:[`item.createdAt`]="{item}">
                            {{formatDate(item.createdAt, "DD/MM/YYYY HH:mm:ss")}}
                        </template>

                        <template v-slot:[`item.actions`]="{item}" >
                            <!-- ********* Edit ****** -->
                            <v-btn v-if="item.status == 0" class="" color="primary" @click="accept(item)" dark icon>
                                <v-icon >
                                    mdi-check-circle
                                </v-icon>
                            </v-btn>

                            <!-- ********* Xoa ****** -->
                            <v-btn class=" " v-if="item.status == 0" color="error" @click="decline(item)" dark icon>
                                <v-icon >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>

                            <!-- ********* Undo ****** -->
                            <v-btn class="" dark v-if="item.status != 0" color="blue-grey" @click="undo(item)" icon>
                                <v-icon>
                                    mdi-undo
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:[`item.remove`]="{ item }">
                            <v-btn x-small class="mx-1 my-1" color="error" @click="remove(item)" width="100px">
                                <v-icon small >
                                    mdi-delete
                                </v-icon> Xóa phiếu
                            </v-btn>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip :color="$helper.formatStatus(item.status).color" dark>
                                <strong> {{$helper.formatStatus(item.status).text}}</strong>
                            </v-chip>
                        </template>
                        <template v-slot:[`item.departmentName`]="{ item }">
                            <v-chip class="ma-2 caption font-weight-bold" color="orange" text-color="white" >
                            <v-icon left>
                                mdi-store
                            </v-icon>
                            {{item.departmentName}}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.confirmDate`]="{ item }" >
                            {{item.confirmDate}}
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card>
                    <v-card-title>
                        Danh sách phiếu
                    <v-spacer></v-spacer>
                    <v-btn icon class="px-0 mx-1" color="orange" light>
                        <v-icon @click="fetchData(1)">mdi-refresh</v-icon>
                    </v-btn>
                    
                    <v-btn @click="updateConfirmDialog=true" dark color="teal darken-4">Cập nhật dữ liệu</v-btn>
                    <v-dialog max-width="600" v-model="updateConfirmDialog">
                        <v-card>
                            <v-card-title>
                                Cập nhật dữ liệu vào thông tin nhân viên
                            </v-card-title>
                            <v-card-subtitle class="error--text">
                                Thao tác này không thể quay lại. Vui lòng kiểm tra kĩ thông tin
                            </v-card-subtitle>
                            <v-card-text>
                                Bạn có chắc chắn tiếp tục ?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn outlined color="error" @click="updateConfirmDialog=false">Hủy</v-btn>
                                <v-btn dark color="primary" @click="updateToEmployee">Tiếp tục</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- <data-export v-model="exportDialog"></data-export> -->
                    <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
                    </data-filter> -->
                    </v-card-title>
                    
                    <v-data-table
                    :mobile-breakpoint="0"
                    height="600px" fixed-header
                    :headers="headers"
                    :items="items1"
                    :loading="isLoading"
                    loading-text="Đang tải dữ liệu..."
                    :footer-props="{
                        itemsPerPageOptions:[
                        50, 100, 200
                        ]
                    }"
                    >
                        <!-- <template v-slot:[`body.prepend`]="" >
                            <tr>
                                <td></td>
                                <td>
                                    <v-select placeholder="Chọn" dense v-model="filters.status" hide-details outlined
                                    :items='[{text:"Tất cả", value:-1}, {text:"Chờ XN", value:0}, {text:"Đã XN", value:1}, {text:"Từ chối", value:2}]'></v-select>
                                </td>
                                <td>
                                    <v-text-field placeholder="Nhập" dense v-model="filters.code" hide-details outlined></v-text-field>
                                </td>
                                <td>
                                    <v-select placeholder="Chọn" dense v-model="filters.leaveAmountType" hide-details outlined
                                    :items='[{text:"Cả ngày", value:1}, {text:"Nữa ngày", value:0.5}, {text:"Cả hai", value:2}]'></v-select>
                                </td>
                                <td>
                                    <v-select placeholder="Chọn" dense v-model="filters.leavePlanCode" :items="leavePlans" item-text="name" item-value="code"
                                    hide-details outlined></v-select>
                                </td>
                                <td>
                                    <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" hide-details outlined></v-text-field>
                                </td>
                                <td>
                                    <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" hide-details outlined></v-text-field>
                                </td>
                                <td>
                                    <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode" hide-details outlined
                                    item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                                    @update:search-input="fetchDepartment"></v-autocomplete>
                                </td>
                                <td>
                                    <v-text-field v-model="filters.from" dense hide-details outlined type="date"></v-text-field>
                                    <v-text-field v-model="filters.to" dense hide-details outlined type="date"></v-text-field>
                                </td>
                                <td colspan="100%"></td>
                            </tr>
                        </template> -->

                        <template v-slot:[`item.view`]="{item}">
                            <v-icon color="primary" @click="openViewDialog(item)">mdi-eye</v-icon>
                        </template>
                        <template v-slot:[`item.from`]="{item}">
                            {{formatDate(item.from)}}
                        </template>
                        
                        <template v-slot:[`item.sentDate`]="{item}">
                            {{formatDate(item.sentDate)}}
                        </template>

                        <template v-slot:[`item.createdAt`]="{item}">
                            {{formatDate(item.createdAt, "DD/MM/YYYY HH:mm:ss")}}
                        </template>

                        <template v-slot:[`item.actions`]="{item}" >
                            <!-- ********* Edit ****** -->
                            <div v-if="!item.applyToEmployee"> 
                                <v-btn x-small v-if="item.status == 0" class="mx-1 elevation-1 " color="success" @click="accept(item)" dark>
                                    <v-icon small >
                                        mdi-check
                                    </v-icon> Đồng ý
                                </v-btn>

                                <!-- ********* Xoa ****** -->
                                <v-btn x-small class="mx-1 my-1 elevation-1 " v-if="item.status == 0" color="error" @click="decline(item)" dark >
                                    <v-icon small >
                                        mdi-close
                                    </v-icon>Từ chối
                                </v-btn>

                                <!-- ********* Undo ****** -->
                                <v-btn x-small class="mx-1 my-1 elevation-1" dark v-if="item.status != 0" color="blue-grey" @click="undo(item)" >
                                    <v-icon small   >
                                        mdi-undo
                                    </v-icon> Hoàn tác
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:[`item.remove`]="{ item }">
                            <v-btn v-if="!item.applyToEmployee" x-small class="mx-1 my-1" color="error" @click="remove(item)" width="100px">
                                <v-icon small >
                                    mdi-delete
                                </v-icon> Xóa phiếu
                            </v-btn>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip :color="$helper.formatStatus(item.status).color" label small outlined v-if="!item.applyToEmployee">
                                <strong> {{$helper.formatStatus(item.status).text}}</strong>
                            </v-chip>
                            <v-chip color="grey" label small outlined v-else>
                                <strong> Đã cập nhật </strong>
                            </v-chip>
                        </template>
                        <template v-slot:[`item.departmentName`]="{ item }">
                            <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                            <v-icon left>
                                mdi-store
                            </v-icon>
                            {{item.departmentName}}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.confirmDate`]="{ item }" >
                            {{item.confirmDate}}
                        </template>
                    </v-data-table>

                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <!-- <v-card class="rounded-lg"> 
            <v-card-title>
                <month-picker v-model="month" label="Chọn tháng" width="7rem" dense outlined ></month-picker> 
                <v-spacer></v-spacer>

                <v-btn text color="teal darken-4" class="ml-auto caption" small @click="filterExpand=!filterExpand">
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn><div style="float:clear"></div>

            </v-card-title>
            
             <v-expand-transition>
                <div v-show="filterExpand">
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" sm="4" md="3">
                                <h5 class="pa-0 ma-0 subtitle-2">Ngày duyệt</h5>
                                <date-picker2 type="date"  v-model="filters.execDate"
                                outlined hide-details dense :range="false"
                                inputClass="caption my-2" :width="600"></date-picker2>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card> -->
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="error" @click="cfmDeleteItem">Xóa</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="viewDialog" max-width="600">
            <v-card>
                <v-card-title>
                    Chi tiết thông tin cập nhật
                </v-card-title>
                <v-card-text class="text--primary" >
                    <v-row class="my-0" v-if="viewItem">
                        <v-col cols="12" md="6">
                            <label>Mã nhân sự:</label>
                            <h5 class="ml-2">{{viewItem.employeeCode}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Tên nhân sự:</label>
                            <h5 class="ml-2">{{viewItem.employeeName}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Quốc tịch:</label>
                            <h5 class="ml-2">{{viewItem.originName}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Tôn giáo:</label>
                            <h5 class="ml-2">{{viewItem.religionName}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Dân tộc:</label>
                            <h5 class="ml-2">{{viewItem.ethnicityName}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Số điện thoại khần cấp:</label>
                            <h5 class="ml-2">{{viewItem.emergencyPhone}}</h5>
                        </v-col>
                        <v-col cols="12">
                            <label>Địa chỉ tạm trú:</label>
                            <h5 class="ml-2">{{viewItem.city2Name}}, {{viewItem.district2Name}}, {{viewItem.ward2Name}}, {{viewItem.address2}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Ngân hàng:</label>
                            <h5 class="ml-2">{{viewItem.bankName}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Số tài khoản:</label>
                            <h5 class="ml-2">{{viewItem.bankAccount}}</h5>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>CCCD mặt trước:</label>
                            <a :href="viewItem.idFrontImageUrl" target="_blank">
                                <img :src="viewItem.idFrontImageUrl" alt="" srcset="" width="300px">
                            </a>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>CCCD mặt sau:</label>
                            <a :href="viewItem.idBackImageUrl" target="_blank">
                                <img :src="viewItem.idBackImageUrl" alt="" srcset="" width="300px">
                            </a>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>Ảnh giấy tờ ngân hàng:</label>
                            <a :href="viewItem.bankImageUrl" target="_blank">
                                <img :src="viewItem.bankImageUrl" alt="" srcset="" width="300px">
                            </a>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        Không tìm thấy thông tin nhân sự
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import ApiServices from './api';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import * as moment from "moment/moment";
import Create from '@/views/Base/InfoUpdate/Create';

export default {
    name: "InfoUpdateRequest",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        "create-dialog": Create,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        
        tab(val) {
            if(val  == 0){
                this.fetchData(0)
            }
            if(val == 1) {
                this.fetchData(1)
            }
        },
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                console.log(this.tableParams,"////////");
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "infoUpdateRequests/",
            tab: 0,
            updateConfirmDialog: false,
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:50
            },
            month: moment().format("YYYY-MM"),
            leavePlans:[],
            filters:{
                code: null,
                status: -1,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                leavePlanCode:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
                leaveAmountType: 2,
                execDate: moment().format("YYYY-MM-DD")
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            exportDialog:false,
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            items1: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: {
                state: false,
                item: undefined,
            },
            item: null,
            dialogDelete: false,
            targetItem: undefined,
            viewItem: null,
            viewDialog: false,

            headers: [
                { text: 'Thao tác', align:"center", value: 'actions', width: '7rem'},
                { text: 'Xem phiếu', value: 'view', width: '7rem', align: 'center', sortable: false},
                { text: 'Trạng thái', align: 'start', value: 'status', width: '7rem'},
                { text: 'Mã', align: 'start', value: 'id', width: '7rem'},
                { text: 'Mã NS', align: 'start', value: 'employeeCode', width: '7rem'},
                { text: 'Tên NS', align: 'start', value: 'employeeName', width: '7rem'},
                { text: 'Ngày tạo', align: 'start', value: 'createdAt'},
                { text: 'Người tạo', align: 'start', value: 'createdBy'},
                
                { text: 'Xóa phiếu', value: 'remove', width: 100},
            ],
            filterExpand: false
        }
    },
    methods: {
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        formatDate(value, format = "DD/MM/YYYY") {
            return moment(value).format(format);
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        // fetchLeavePlan(){
        //     ApiServices.fetchLeavePlan().then(res=>{
        //         this.leavePlans = res.data;
        //     })
        // },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData (tab = 0) {
            this.isLoading = true;
            if(tab==0) {
                
                return ApiServices.gets()
                .then(d => {
                    console.log(d)
                    this.items = d
                })
                .catch(err => {
                console.log(err)
                })
                .finally(() => {
                    this.isLoading = false;
                })
            }
            if(tab == 1){
                return ApiServices.readyGets()
                .then(d => {
                    this.items1 = d
                })
                .catch(err => {
                console.log(err)
                })
                .finally(() => {
                    this.isLoading = false;
                })
            }
        },
        
        accept(item) {
            return ApiServices.changeStatus(item.id, 1).then(res => {
                // let eventObj = {
                //     color: "success",
                //     message: "Cập nhật thành công",
                //     toast: true,
                // };
                // this.messageToast(eventObj);
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            return ApiServices.changeStatus(item.id, 2).then(res => {
                // let eventObj = {
                //     color: "success",
                //     message: "Cập nhật thành công",
                //     toast: true,
                // };
                // this.messageToast(eventObj);
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            return ApiServices.changeStatus(item.id, 0).then(res => {
                this.fetchData();
                this.fetchData(1);
            }).catch(err => {
                console.log(err)
            });
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL+"admin/"+this.targetItem.id).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },

        openViewDialog(item){
            this.$root.$emit('editDialog', item)
            // this.viewDialog = true
            // this.viewItem = item
        },
        updateToEmployee() {
            return ApiServices.updateToEmployee()
        }
    },
    created () {
        this.fetchData();
        this.fetchLeavePlan();
    }
}
</script>
<style scoped>
</style>