<template>
    <div class="property-issue">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" ></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchEmployee"></create-dialog>

        <v-card>
            <v-row>
                <v-col cols="12" md="4">
                    <v-data-table
                    :headers="headers1"
                    :items="employees"
                    :search="employeeSearch"
                    item-key="code"
                    v-model="employee"
                    single-select
                    @item-selected="employeeSelect"
                    show-select
                    > 
                        <template  v-slot:[`item.hasReturnProperty`]="{ item }">
                            <v-switch v-model="item.hasReturnProperty" :label="`${item.hasReturnProperty?'Hoàn trả': 'Chưa hoàn trả'}`" @change="updateReturnStatus(item)"></v-switch>
                        </template>
                        
                        <template v-slot:top>
                            <v-toolbar flat >
                                <v-toolbar-title>Danh sách nhân viên</v-toolbar-title>
                                <v-divider class="mx-4" inset  vertical ></v-divider>
                                <v-spacer></v-spacer>
                                <v-autocomplete v-model="departmentCode" label="Chọn phòng ban" :search-input.sync="departmentSearch" @update:search-input="fetchDepartment" @change="fetchEmployee"
                                item-value="code" item-text="name" :items="departments" hide-no-data hide-details outlined dense></v-autocomplete>
                                <!-- <v-text-field label="Tìm kiếm" hide-details="" outlined dense v-model="employeeSearch"></v-text-field> -->
                            </v-toolbar>       
                        </template>
                    </v-data-table>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="12" md="8">
                    <!-- ********** TABLE ORDERS ********** -->
                    <v-data-table height="600px"
                    single-select
                    :headers="issueHeaders"
                    :items="employeeProperties"
                    >
                        <template v-slot:top>
                            <v-toolbar flat >
                                <v-toolbar-title>Danh sách phiếu cấp</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical ></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field label="Tìm kiếm" hide-details outlined dense v-model="employeeSearch" style="max-width: 15rem">
                                </v-text-field>
                                
                                <v-dialog v-model="propertyDialog" transition="dialog-bottom-transition" max-width="600" scrollable>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn dark color="teal" v-show="!!selectedEmployee" small class="mx-3" v-bind="attrs" v-on="on" @click="propertyDialog=true, propertyDialogEdit=false">
                                            <v-icon small left>mdi-plus-thick</v-icon> Cấp tài sản
                                        </v-btn>
                                    </template>
                                    <template v-slot:default="dialog">
                                        <!-- dialog create -->
                                        <v-card>
                                            <v-toolbar color="secondary" dark flat >Tài sản được cấp
                                                <v-spacer></v-spacer>
                                                <v-icon @click="dialog.value=false">mdi-close</v-icon>
                                            </v-toolbar>
                                            <v-card-text class="pa-3">
                                                <v-form ref="form" v-model="valid">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-autocomplete v-model="issue.propertyCode" label="Tài sản*" placeholder="Chọn tài sản"
                                                            :items="properties" item-text="name" item-value="code" :rules="[v=>!!v||'Dữ liệu bắt buộc']"></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field v-model="issue.quantity" label="Số lượng cấp*" type="number" :rules="[v=>v>0||'Dữ liệu bắt buộc']"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field v-model="issue.returnQuantity" label="Đã thu hồi*" type="number"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <date-picker2 v-model="issue.issueDate" :range="false" :disabled="propertyDialogEdit" label="Ngày cấp*" :rules="[v=>!!v||'Dữ liệu bắt buộc']" :width="300"></date-picker2>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <date-picker2 v-model="issue.returnDate" :disabled="issue.status!=2" :range="false" label="Ngày trả" :rules="[v=>!!v||'Dữ liệu bắt buộc*']" :width="300"></date-picker2>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-select v-model="issue.status" :items="statuses" label="Trạng thái" :rules="[v=>v!=undefined||'Dữ liệu bắt buộc*']"></v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-textarea outlined v-model="issue.note" label="Ghi chú">
                                                            </v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-card-text>
                                            <v-card-actions class="justify-end">
                                                
                                            <v-btn dark small color="green" class="mx-1" @click="saveEmployeeProperty()">Cấp tài sản</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </template>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.issueDate`]="{ item }">
                            <!-- ********* Edit ****** -->
                            {{$helper.formatDate(item.issueDate)}}
                        </template>
                        <template v-slot:[`item.returnDate`]="{ item }">
                            <span v-if="$helper.formatDate(item.returnDate)!='9999-12-31'">{{$helper.formatDate(item.returnDate)}}</span>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <!-- ********* Edit ****** -->
                            <v-btn @click="editItem(item)" color="white" x-small class="mx-1">
                                <v-icon small left>
                                    mdi-pencil
                                </v-icon> Chi tiết
                            </v-btn>
                        </template>
                        <template v-slot:[`item.remove`]="{ item }">
                            <!-- ********* Xoa ****** -->
                            <v-btn dark @click="deleteItem(item)" x-small class="mx-1" color="error">
                                <v-icon small>
                                    mdi-delete
                                </v-icon>
                                Xóa
                            </v-btn>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <!-- ********* Xoa ******sss -->
                            <span v-if="item.status==1">Cấp đủ</span>
                            <span v-if="item.status==2">Trả đủ</span>
                            <span v-if="item.status==0">Khác</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <!-- ************** DELETE CONFIRMATION *************** -->
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import Create from './Create';
import ApiServices from '../../../components/services/ApiServices';
import moment from 'moment/moment'

export default {
    components: {
        myBreadCrumb,
        'create-dialog': Create,
    },
    props:["breadcrumbs", "icon"],
    watch: {
        options: {
            handler (val) {
                this.fetchEmployee();
            },
            deep: true,
        },
        propertyDialog:{
            handler(val) {
                if(val)
                {

                }else{
                    this.$refs.form.resetValidation();
                    this.issue = Object.assign({}, this.issueDefault)
                }
            }
        },
        employee: {
            handler(value) {
                if(value[0]){
                    this.fetchOrder();
                }else {
                    this.orders = [];
                }
            }
        }
    },
    data () {
        return {
            ROOT_URL:"PropertyEmployees/",
            //filter
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],
            },
            
            //datatable config
            search: '',
            headers1: [
                {text: 'Mã', value: 'code'},
                { text: 'Tên nhân viên', value: 'employeeName'},
                { text: 'Hoàn trả tất cả', value: 'hasReturnProperty'},
            ],
            employees: [],

            headers2: [
                {text: 'Mã', value: 'code'},
                { text: 'Ngày cấp', value: 'issueDate'},
                { text: 'Nhân viên cấp', value: 'receiverCode'},
                { text: 'Nhân viên được cấp', value: 'giverCode'},
                { text: 'Tổng số', value: 'total'},
                { text: '', value: 'actions'},
            ],
            orders: [],
            headers3: [
                {text: 'Mã', value: 'propertyCode'},
                {text: 'Tên thiết bị', value: 'name'},
                {text: 'Số lượng', value: 'quantity'},
                {text: '', value: 'actions'},
            ],
            employeeProperties:[],
            issueHeaders:[
                {text:"Chi tiết", value: "edit"},
                {text:"Mã tài sản", value: "propertyCode", width:'10rem'},
                {text:"Tài sản", value: "propertyName", width:'8rem'},
                {text:"Ngày cấp", value: "issueDate", width:'8rem'},
                {text:"Ngày trả", value: "returnDate", width:'8rem'},
                {text:"Số lượng cấp", value: "quantity"},
                {text:"Số lượng trả", value: "returnQuantity"},
                {text:"Trạng thái", value: "status"},
                {text:"Ghi chú", value: "note"},
                {text:"Xóa", value: "remove"},
            ],
            selectedEmployee:null,
            employee: [],
            employeeSearch:"",
            departmentSearch: "",
            departmentCode:"",
            departments:[],

            propertyDialogEdit:false,
            propertyDialog:false,
            properties:[],
            selectedProperty:null,
            valid:true,
            issue:{
                issueNo:0,
                propertyCode:"",
                issueDate: moment().format("yyyy-MM-DD"),
                returnDate:moment("9999-12-31").format("yyyy-MM-DD"),
                quantity:1,
                returnQuantity:0,
                status:1,
                note:"",
            },
            issueDefault:{
                issueNo:0,
                propertyCode:"",
                issueDate: moment().format("yyyy-MM-DD"),
                returnDate:moment("9999-12-31").format("yyyy-MM-DD"),
                quantity:1,
                returnQuantity:0,
                status:1,
                note:"",
            },

            order: {},
            orderDetails: [],
            statuses: [
                {text: "Khác", value: 0},
                {text: "Cấp đủ ", value: 1},
                {text: "Trả đủ", value: 2},
            ],

            detail:{
                propertyOrderCode: "",
                propertyCode: "",
                quantity:""
            },

            //dialog
            dialog: {
                state: false,
                item: undefined,
            },
            employeeProperty: null,
            dialogDetail: false,
            dialogDelete: false,
            targetItem: {},
        }
    },
    methods:{
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchDepartment(value){
            this.$apiService.fetchDepartment(this.departmentSearch).then(res=> {
                this.departments = res.data;
            })
        },
        fetchEmployee (value='') {
            if(this.departmentCode)
                this.$apiService.fetchEmployeeInDepartment(this.departmentCode).then(res => {
                    this.employees = res.data;
                })
        },
        fetchEmployeeProperty(value){
            const url = `propertyEmployees/get-properties/${value}`
            if(value)
                return http.get(url).then(res => {
                    this.employeeProperties = res.data;
                })
        },
        fetchProperty () {
            this.$apiService.fetchProperty().then(res => {
                this.properties = res.data.items;
            })
        },
        updateReturnStatus(item){
            var url = `employees/update-return-status/${item.code}`
            return http.get(url, {
                params:{
                    value: item.hasReturnProperty
                }
            }).then(res => {
                console.log("ok");
            })
        },
        employeeSelect(data){
            // neu untick or tick
            if(data.value)
            {
                this.selectedEmployee = data.item.code;
                this.fetchEmployeeProperty(this.selectedEmployee);
            }
            else {
                this.selectedEmployee = null;
                this.employeeProperties=[];
            }
        },
        saveEmployeeProperty(value){
            this.$refs.form.validate();
            if(this.valid){
                if(value)
                {
                    const url = `PropertyEmployees/${this.selectedEmployee}-${this.issue.propertyCode}-${issue.no}`
                    return http.put(url, this.issue).then(res => {
                        this.fetchEmployeeProperty(this.selectedEmployee);
                        this.propertyDialog=false;
                        this.fetchEmployee();
                    })
                }
                else {
                    const url = `PropertyEmployees/${this.selectedEmployee}-${this.issue.propertyCode}`
                    console.log(url)
                    return http.post(url, this.issue).then(res => {
                        this.fetchEmployeeProperty(this.selectedEmployee);
                        this.propertyDialog=false;
                    })
                }
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.propertyDialogEdit =true;
            this.propertyDialog = true;
            const url = `PropertyEmployees/${this.selectedEmployee}-${item.propertyCode}-${item.issueNo}`;
            return http.get(url).then(res => {
                this.issue = Object.assign({}, res.data);
            })
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            console.log(this.targetItem)
            const url = `PropertyEmployees/${this.selectedEmployee}-${this.targetItem.propertyCode}-${this.targetItem.issueNo}`;
            return http.delete(url).then(res => {
                this.dialogDelete = false;
                this.fetchEmployeeProperty(this.selectedEmployee);
            }).catch(err => {
                console.log(err)
            })
        },

        fetchOrder() {
            if(this.employee[0]){
                http.get(this.ROOT_URL + "get-properties/" + this.employee[0].code).then(res => {
                    this.orders = res.data.items;
                })
            }else{
                this.orders = [];
            }
        },
        
        getOrder(code) {
            return http.get(this.ROOT_URL+code).then(res => {
                this.order = res.data.item;
                this.orderDetails = res.data.itemDetails;
            });
        },

        createOrder () {
            if(this.employee[0])
                return http.post(this.ROOT_URL, {
                    receiverCode: this.employee[0].code,
                }).then(res => {
                    this.order = res.data.item;
                    this.fetchOrder();
                })
        },
        saveOrder () {
            return http.put(this.ROOT_URL + this.order.code, this.order).then(res => {
                this.fetchOrder();
            })
        },
        createOrderDetail () {
            if(this.order)
                this.detail.propertyOrderCode = this.order.code;
                return http.post(this.ROOT_URL+"detail", this.detail).then(res=>{
                    let item = res.data.item;
                    this.getOrder(this.order.code);
                })
        },

    },
    mounted () {
        this.fetchEmployee();
        this.fetchProperty();
    }
}
</script>