// import CreateDialog from '../Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';

export default {
    components:{
        // "create-dialog": CreateDialog,
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
    },
    data () {
        return {
            dialog: false,
            item: null,
            items:[],
            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text:"Mã", value: "rowId", width:"7rem"},
                {text:"Từ ngày", value: "fromDate", width:"7rem"},
                {text:"Đến ngày", value: "toDate", width:"12rem"},
                {text:"Loại tăng ca", value: "shiftCode", width:"7rem"},
                {text:"Tên ca", value: "shiftName", width:"7rem"},
                {text:"Hệ số", value: "hour", width:"7rem"},
            ],

            dialogDelete: false,
            targetItem: null,
        }
    },
    methods: {
        fetchData() {
            return http.get("DefaultOvertimeBookingByShifts").then(res => {
                this.items = res.data;
                console.log(this.items);
            })
        },
        formatDate (date) {
            return moment(date).format("DD/MM/YYYY")
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `qrLinks/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
    },
    mounted () {
        this.fetchData();
    }
}