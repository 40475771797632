<template>
    <div id="IosCamera">
        <!-- <h4>{{ accuracy}}</h4> -->
        <v-card class="result" flat :loading="process" width="100%" style="display:block">
            <img ref="photo" :src="previewImage" alt="" style="max-width: 100%">
            <canvas ref="canvas" class="canvas"></canvas>
            <v-card-text class="pa-0">
            </v-card-text>
            <v-overlay  v-model="process" absolute z-index="5">
                <v-progress-circular
                    :size="50" :width="3" color="cyan accent-2"
                    indeterminate >
                    </v-progress-circular>
            </v-overlay>
        </v-card>
        <p class="teal--text">{{`${result} - ${accuracy}`}} </p>
        <v-file-input
        prepend-icon="mdi-face-recognition"
        capture="user"
        accept="image/*"
        multiple
        show-size
        label="Hình ảnh chấm công"
        @change="pickFile"
        ></v-file-input>
        <!-- <input ref="fileInput" type="file" @input="pickFile"> -->
    </div>
</template>
<script>

// import * as faceapi from 'face-api.js';
import http from "./services/http-common";
import EventBus  from "../components/services/EventBus";
// import { resizeResults } from 'face-api.js';

export default {
    name: "IosCamera",
    components:{
        EventBus
    },
    data () {
        return {
            previewImage: null,
            option: undefined,
            init_option: undefined,
            faceMatcher: null,
            accuracy:'',
            result: "",
            process: false
        }
    },
    methods: {
        async getDescriptors () {
            let JSON_face_descriptor = (await http.get("users/get-face-descriptor")).data.face_descriptor;
            if(JSON_face_descriptor)
                this.faceMatcher = await Object.setPrototypeOf(JSON.parse(JSON_face_descriptor), faceapi.FaceMatcher.prototype);
            else
            {
                let eventObj = {
                    color: "error",
                    message: "Vui lòng cập nhật dữ liệu gương mặt",
                    toast: false,
                    showConfirmButton: true,
                    timer: undefined,
                };
                EventBus.$emit('hasError', eventObj);
                this.faceMatcher = null;
            }
        },
        async pickFile(file) {
            var that =this;
            if (file) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                
                reader.onloadend = async function() {
                    await that.recognize();
                }
                
                reader.readAsDataURL(file[0])
                this.$emit('input', this.$refs.photo);
            }
        },
        prepareFaceDetector() {
            let base_image = new Image();
            base_image.src = "/init3.jpg";
            base_image.onload = function() {
                const useTinyModel = true;
                const fullFaceDescription = faceapi
                .detectSingleFace(base_image, this.option)
                .withFaceLandmarks(useTinyModel)
                .withFaceDescriptor()
                .run()
                .then(res => {
                    console.log("--------> " + JSON.stringify(res));
                });
            };
        },
        async recognize() {
            let cam = this.$refs.photo;
            let canvas = this.$refs.canvas;
            this.process = true;            
            const displaySize = { width: cam.clientWidth, height: cam.clientHeight }
            canvas.width = cam.clientWidth;
            canvas.height = cam.clientHeight;
            canvas.style.top = -cam.clientHeight-+"px";
            let result = await faceapi.detectSingleFace(cam, this.option)
                                        .withFaceLandmarks(true)
                                        .withFaceDescriptor();
                            
            const resizedDetection = faceapi.resizeResults(result, displaySize);
            if(result == undefined)
            {
                this.result = "Không phát hiện gương mặt nào";
                this.process = false;
                this.processing = false;
                return
            } else
            {
                faceapi.draw.drawDetections(canvas, resizedDetection);
            }
            const bestMatch = await this.faceMatcher.findBestMatch(result.descriptor);
            this.accuracy = bestMatch.toString();
            if(bestMatch.toString(false) == "unknown")
            {
                this.result = "Không phải là bạn, xin vui lòng thử lại";
                this.process = false;
                return false;
            }else
            {
                this.result = "Đã xác thực bạn có thể tiến hành chấm công";
                this.process = false;
                this.$emit("allow");
                this.detected = true;
                return true;
            }
            // return
            // this.processing = false;
        }
    },
}
</script>
<style scoped>
.result{ 
    display:block;
    position:relative;
    }
.result img{
    position:absolute;
    z-index:1;
}
.result canvas {
    width: 100%;
}
.canvas{ 
    position:relative;
    z-index:2;
    }
</style>