import moment from 'moment/moment';

class Formatter {
  formatDate(value, format="DD/MM/YYYY"){
    return moment(value).format(format);
  }
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        return reject('No file selected');
      }
  
      const fileName = file.name;
      const type = file.type;
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const base64String = event.target.result.split(',')[1]; // Remove the data URL part
        resolve({ base64: base64String, fileName: fileName, type:type  });
      };
  
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  }
}

export default new Formatter();