<template>
    <div>
        <!-- <create-dialog :value="dialog"
        @close-dialog="dialog=false, item=null"
        :item="item" @fetchData="fetchData" @setSelect="setSelect"></create-dialog> -->
        <v-card>
            <v-toolbar color="white" flat>
                <v-toolbar-title class="mx-4">Danh sách phiếu {{this.USER_ID}}</v-toolbar-title>
                <v-spacer></v-spacer>
                
                <!-- //actions -->
                <!-- <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success" class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                    <v-list-item @click="dialog=true">
                        <v-list-item-title>Thêm phiếu <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu> -->
            </v-toolbar>
            
            <v-card-title>
                <v-row justify="start">
                    <!-- <v-col cols="12" md="3">
                        <v-text-field type="date" hide-details outlined dense v-model="filterFromDate" label="Từ ngày" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field type="date" hide-details outlined dense v-model="filterToDate" label="Từ ngày" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select clearable :items="statuses" outlined hide-details v-model="filterStatus" dense label="Trạng thái">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select clearable :items="chinhanhs" outlined hide-details v-model="filterChiNhanh" dense label="Chi nhánh"></v-select>
                    </v-col> -->

                    <v-col cols="12" md="12">
                        <v-text-field v-model="globalSearch" hide-details outlined dense full-width
                             placeholder="Tìm kiếm" persistent-placeholder label="Tìm mã phiếu" ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="2">
                        <v-select v-model="filterStatus" hide-details outlined dense :items="['Mới', 'Đã nhận', 'Hoàn thành', 'Chối từ']" clearable
                             placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm mã phiếu" ></v-select>
                    </v-col> -->
                    <!-- <v-col cols="12" md="2">
                        <v-text-field v-model="search" hide-details outlined dense
                             placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm chi nhánh" ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" md="2">
                        <v-text-field v-model="filterDepartment" hide-details outlined dense
                             placeholder="Tìm kiếm chi nhánh" persistent-placeholder label="Tìm kiếm chi nhánh" ></v-text-field>
                    </v-col> -->
                </v-row>
            </v-card-title>
        </v-card>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card>
                    <!-- <v-card-title>Danh sách bộ câu hỏi</v-card-title> -->
                    <v-data-table :items="items1" :headers="headers1"  height="calc(100vh - 300px)" 
                    :search="globalSearch" >
                        <template v-slot:[`item.actions`]="{item}" >
                            <v-btn v-if="item.type =='FAIL'" color="pink lighten-2" x-small @click="reSync(item)" ><b>Re-Sync</b></v-btn>
                            <!-- <edit-button @click="edit(item)"></edit-button>
                            <delete-button @click="deleteItem(item)"></delete-button> -->
                        </template>

                        <template v-slot:[`item.TinhTrang`]="{item}" >
                            
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small
                                :color="statusColors[item.TinhTrang]"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >
                                {{item.TinhTrang}}
                                <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="openBottom(item, status)"
                                v-for="(status, index) in statuses.filter(x=>x != item.TinhTrang)"
                                :key="index"
                                >
                                    <v-list-item-title :class="`${statusColors[status]}--text font-weight-medium`">{{ status }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </template>

                        <template v-slot:[`item.note`]="{item}" >
                            <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                            <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                        </template>
                        
                        <template v-slot:[`item.LinkAnh`]="{item}" >
                            {{convertImage(item.LinkAnh)}}
                        </template>

                        <template v-slot:[`item.TenHang`]="{item}" >
                            <a v-if="item.LinkAnh" :href="convertImage(item.LinkAnh)" target="_blank">
                                <img  :src="convertImage(item.LinkAnh)" alt="" srcset="" width="100px">
                            </a>
                            <h5>{{item.TenHang}}</h5>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
            
        </v-row>
        
        <!-- ************** BOTTOM SHEET  *************** -->
        <v-bottom-sheet v-model="bottomSheet" persistent max-width="600px" hide-overlay>
            <v-card
                v-if="item1SelectObj"
                class="" 
                height="300px"
            >
                <v-card-title v-if="bottomSheetType!='RETURN'" :class="`font-weight-bold ${bottomSheetType == 'ACCEPT'? 'green' : ( bottomSheetType == 'DENY'? 'red': '')}--text text--darken-2`"><v-spacer></v-spacer>{{bottomSheetType == 'ACCEPT'? 'Xác nhận' : ( bottomSheetType == 'DENY'? 'Từ chối': '')}} phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-title v-if="bottomSheetType=='RETURN'" class="font-weight-bold primary--text"> <v-spacer></v-spacer> Trả về phiếu phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-text v-if="bottomSheetType!='RETURN'">
                    <v-row>
                        <v-col cols="12" class="">
                            <label for="bottom_note" class="font-weight-bold text-left">Ghi chú</label>
                            <v-textarea id="bottom_note" rows="4" solo hide-details placeholder="Gõ enter để LƯU" v-model="note">

                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-3" outlined color="success" @click="setStatusNote" >
                    Lưu
                    </v-btn>

                    <v-btn class="mt-3" color="error" text @click="closeBottom" >
                    Đóng
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này? ID: {{targetItem}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteTemplate">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loadingDialog" width="300px" persistent>
            <v-card>
                <v-card-title class="text-center">
                    <v-spacer></v-spacer>
                    Đang xuất file
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="text-center">
                    <v-progress-circular
                    size="70"
                    indeterminate
                    color="orange"
                    ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import CreateDialog from './Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import api from './api.js'

export default {
    components:{
        // "create-dialog": CreateDialog,
    },
    computed: {
        selectedTemplate() {
            let rslt = this.item1Select.length>0 ? ` ${this.item1Select[0].id }`: ""

            return rslt
        },
        chinhanhs(){
            return [...new Set(this.items1.map(obj => obj.ChiNhanhDuTru))]
        }
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        },
        item1Select() {
            if(this.selectedTemplate != ""){
                this.getQuestions()
            } else{
                this.questions=[]
            }
        },
        filterFromDate() {
            this.getPhieu()
        },
        filterToDate() {
            this.getPhieu()
        }
    },
    data () {
        return {
            TOKEN: null,
            USER_ID: null,
            month: moment().startOf("months").format("yyyy-MM"),
            statuses: ['Chờ xác nhận','Đã xác nhận','Không đặt được'],
            statusColors: {
                'Chờ xác nhận': 'blue',
                'Đã xác nhận': 'green',
                'Không đặt được': 'red',
            },
            bottomSheet: false,
            bottomSheetType: "",
            note: "",

            item: null,

            item1SelectObj: null,
            item1Select: [],
            items1:[],
            items2:[],
            search:"",

            filterCode:"", 
            filterDepartment:"", 
            
            filterFromDate:moment().startOf('months').format('YYYY-MM-DD'),
            filterToDate: moment().endOf('months').format('YYYY-MM-DD'),
            filterStatus:"",
            filterChiNhanh:"",

            currentItem: "",

            loadingDialog: false,
            globalSearch:"",
            headers1: [
                // {text:"Thao tác", value: "actions", width:"100px"},
                // {text: "Tình trạng", value: "TinhTrang", width:"220px",
                //     filter: value => {
                //         if (!this.filterStatus) return true
            
                //         return value.toUpperCase().includes(this.filterStatus.toUpperCase())
                //     },
                // },
                {text: "Thao tác", value: "actions", width:"100px", sortable: false, filterable:false},
                {text: "Thời gian", value: "time", width:"100px"},
                {text: "Mã NS", value: "employeeCode", width:"100px"},
                {text: "Kết quả đồng bộ", value: "type", width:"150px"},
                {text: "Hành động", value: "action", width:"200px"},
                {text: "Giá trị", value: "value"},
                {text: "Đợt đồng bộ", value: "progressTime"},
            ],
            
            headers2: [
                {text:"Thao tác", value: "actions", width:"7rem"},
                {text:"Id", value: "id", width:"7rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Tên khảo sát", value: "name", width:"7rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
            ],

            questionTypes: [
                {text: "Tự luận", value: "TEXT"},
                {text: "Lựa chọn", value: "OPTION"},
                {text: "Nhiều lựa chọn", value: "MULTI_OPTION", disabled: true},
            ],
            question:{
                type: "OPTION",
                name:"",
            },
            questions:[],

            answerHeader:[
                {text:"Câu trả lời", value:"name"},
                {text:"Xóa", value:"actions", width: 150}

            ],
            answers: [],
            answer:"",

            dialogDelete: false,
            targetItem: null,
            dialog: false,
            questionDialog:false,
        }
    },
    methods: {
        convertImage(data) {
            const imgPrefix = 'https://storage.googleapis.com/tshrm-bucket/Uploads/DatHang/'
            if(data)
            {
                let arr = JSON.parse(data)
                if(arr.length>0){
                    let imgObj = arr[0]
                    return imgPrefix+imgObj['0']
                }
                return ''
            }
            return ''
        },
        fetchData() {
            return this.apiService.fetchData().then(d => {
                this.items1 = d.logs
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        publish(item) {
            this.apiService.publishTemplate(item).then(() => this.fetchData())
        },

        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `propertyRequests/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        //template
        openBottom(item, status, event) {
            this.item1SelectObj = item
            // if(status == 'Đã xác nhận' || status == 'Không đặt được')
            this.bottomSheet = true;
            if(status == "Đã xác nhận")
                this.bottomSheetType = "ACCEPT"
            if(status == "Không đặt được")
                this.bottomSheetType = "DENY"
            if(status == "Chờ xác nhận"){
                this.bottomSheetType = "RETURN"
            }
            console.log(item, event)
        },
        closeBottom() {
            this.bottomSheet = false
            this.bottomSheetType = ''
            this.note = ''
        },

        deleteTemplate() {
            return this.apiService.deleteTemplate(this.targetItem).then(()=> {
                this.targetItem=undefined
                this.dialogDelete = false
                this.fetchData()
            })
        },
        reSync(item){
            if(item.action == 'CREATE'){
                this.apiService.syncCreate(item.employeeCode).then(() => this.fetchData())
            }
        },
        
        // exportTemplate(item){
        //     try {
        //         this.loadingDialog = true
        //         return http.get("surveys/"+item.id+"/export", {
        //             params: {
        //                 month: moment(this.month).format('YYYY-MM-DD')
        //             },
        //             responseType: 'blob',
        //         }).then(res => {
        //             const url = window.URL.createObjectURL(new Blob([res.data]));
        //             const link = document.createElement('a');
        //             link.href = url;
        //             link.setAttribute('download', `EXPORT_KHAO_SAT${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
        //             document.body.appendChild(link);
        //             link.click();
        //         }).finally(()=> {
        //             this.loadingDialog=false
        //         });
        //     } catch(e){
        //         alert(e)
        //     }
        // },

        ///QUESTION
        closeQuestion() {
            this.questionDialog = false
            this.answers = []
            this.answer = ""
            this.question.name = ""
            this.question.type = "OPTION"
        },
        getQuestions() {
            try{
                return this.apiService.getQuestions(this.selectedTemplate).then(d => {
                    this.questions = d.data
                })
            }catch(e)
            {
                alert(e)
            }
        },
        saveQuestion(){
            let mappedAnswer = this.answers.map(x=>x.name)
            let postData = {
                name: this.question.name,
                type: this.question.type,
                answers: mappedAnswer
            }
            return this.apiService.postQuestion(this.selectedTemplate, postData).then(() => {
                this.closeQuestion()
                return this.getQuestions()
            })
        },
        deleteQuestion(id) {
            return this.apiService.deleteQuestion(this.selectedTemplate, id).then(() => {
                return this.getQuestions()
            })
        },
        changeQuestionOrder(id, mode) {
            return this.apiService.changeQuestionOrder(this.selectedTemplate, id, mode).then(() => {
                return this.getQuestions()
            })
        },

        /// ANSWER
        addAnswer() {
            this.answers.push({name: this.answer});
            this.answer="";
        },
        deleteAnswer(index) {
            // let index = this.answers.findIndex(obj => obj.name === name);
            if (index !== -1) {
            let removedObject = this.answers.splice(index, 1); // Remove the object from the array
            } else {
                alert('Object not found in the array.');
            }
        },
        
        getToken() {
            let userJson = localStorage.getItem("user")
            let user = JSON.parse(userJson)
            if(user){
                let employeeCode = 'NS2977'
                if(user.employeeCode != 'NV05039')
                    employeeCode = user.employeeCode

                return this.apiService.getToken(employeeCode).then(d => {
                    this.TOKEN = d.token
                    this.USER_ID = d.UserID
                    this.Sources = d.sourceCode
                    // this.dathangForm.chinhanh_dutru = d.sourceCode
                })
            }
        },
        
        setStatusNote() {
            if(this.item1SelectObj){
                if(this.bottomSheetType == "ACCEPT")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'ACCEPT').then(() => {
                            return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Đã xác nhận").then(() =>
                            this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                                this.closeBottom()
                                return this.getPhieu()
                            })
                        )
                    })
                if(this.bottomSheetType == "DENY")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'DENY').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Không đặt được").then(() => 
                        this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(()=>{
                            this.closeBottom()
                            return this.getPhieu()
                            })
                        )
                    })
                if(this.bottomSheetType == "RETURN"){
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Chờ xác nhận").then(() =>
                            this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                                this.closeBottom()
                                this.getPhieu()
                            })
                        )
                    })
                }
            }
            return true
        },

    },

    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted () {
      this.fetchData()
    //   this.getToken().then(()=> this.getPhieu())
    }
}
</script>