import LaDSection from '@/views/layouts/SubLayouts/LaDSection.vue';
//*********** Hiring */
import SyncLog from '@/views/LaDSection/SyncLog/Index';
// import ItScheduleList from '@/views/ItSection/pages/ItSchedule/ItScheduleList';

export default [
    {
      path: 'lad-section',
      name: 'LaDSection',
      component: LaDSection,
      children:[
        {
            path: 'sync-log',
            name: 'SyncLog',
            component: SyncLog,
        },
        
      ]
    },
    
  ]
