
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter';
import CreateDialog from '../Create.vue';
import * as moment from "moment/moment"

export default {
    name: "Attendance",
    components: {
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "quitRequests/",
            exportPrompt: false,
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
                shiftCode:null,
            },
            departmentSearch:null,
            departments: [],
            shifts:[],
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                // { text: 'Thao tác', value: 'actions', width: 200},
                { text: 'Mã', align: 'start', value: 'code', width: '7rem'},
                // { text: 'Mã NS', align: 'start', value: 'quitEmployeeCode', width: '7rem'},
                // { text: 'Nhân viên', align: 'start', value: 'quitEmployee', width: '9rem'},
                { text: 'Lý do', align: 'start', value: 'quitReason', width: 200},
                { text: 'TCN duyệt', align: 'start', value: 'managerAccept', width: 250},
                { text: 'ASM duyệt', align: 'start', value: 'asmAccept', width: 250},
                { text: 'HR duyệt', align: 'start', value: 'hrAccept', width: 250},
            ],
        }
    },
    methods: {
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchData () {
            return http.get(this.ROOT_URL + "personal")
            .then(res => {
                this.items = res.data.datas;
                this.isLoading = false;
            });
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            // http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
            //     this.fetchData();
            //     this.dialogDelete = false;
            // })
        },
    },
    created () {
        this.fetchData();
    },
    
}