<template>
    <div class="work-history">
    <v-dialog v-model="dialog" max-width="850px">
        <v-card>
            <v-card-title>
                Lịch sử công việc
                <v-spacer></v-spacer>
                <v-icon small @click="dialog=false"> mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="mt-3">
                <v-checkbox label="Nâng cột" v-model="promoted"></v-checkbox>
                <v-data-table :items="filteredItem" :headers="headers">
                    <template v-slot:[`item.deleteCell`]="{ item }" >
                        <v-btn v-if="!item.isCurrent" :id="item.code" color="error" small @click="deleteConfirm = !deleteConfirm, targetItem = item.code"> Xóa</v-btn>
                    </template>
                    <template v-slot:[`item.code`]="{ item }" >
                        <v-icon left color="red" v-if="item.isCurrent">mdi-flag</v-icon> {{item.code}} 
                    </template>
                    <template v-slot:[`item.promotedDate`]="{ item }" >
                        {{item.promotedDate}} 
                        <!-- <v-icon left color="green" v-if="item.isPromoted==1">mdi-arrow-up</v-icon> {{item.promotedDate}} 
                        <v-icon left color="red" v-if="item.isPromoted==2">mdi-arrow-down</v-icon> {{item.promotedDate}}  -->
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirm" width="400">
        <v-card>
            <v-card-title>Bạn có muốn xóa dữ liệu {{targetItem}}</v-card-title>
            <v-card-actions>
                <v-btn small color="orange" dark class="mx-1" @click="deleteQuickAction"> Ok</v-btn>
                
                <v-btn small outlined class="mx-1" @click="deleteConfirm = false, targetItem=undefined"> Hủy</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>

import http from '@/components/services/http-common';
import UploadService from "@/components/services/UploadService";
import moment from 'moment/moment';
export default {
    components:{
    },
    props:["value", "item"],
    watch:{
        dialog: {
            handler(value){
                if(value)
                {
                    this.fetch();
                }
                else{
                    this.close();
                }
            }
        },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        filteredItem: {
            get: function () {
                var items= this.items;
                if(this.promoted)
                    return items.filter(x=>x.isPromoted);
                else {
                    return items;
                }
            },
        }
    },
    data() {
        return {
            ROOT_URL: "employees/",
            promoted: false,

            deleteConfirm: false,
            targetItem: undefined,

            items:[],
            headers:[
                // { text: 'Mã nhân viên', value: 'employeeCode', width: '9rem'},
                { text: 'Xóa', value: 'deleteCell', width: '5rem'},
                { text: 'Mã', value: 'code', width: '9rem'},
                { text: 'Ngày nâng cột', value: 'promotedDate', width: '9rem'},
                { text: 'Ngày', value: 'assignDateStr', width: '7rem'},
                // { text: 'Khối', value: 'departmentTypeName', width: '7rem'},
                { text: 'Phòng', value: 'departmentName', width: '7rem'},
                { text: 'Cột', value: 'positionStateName', width: '7rem'},
                { text: 'Vị trí', value: 'positionName', width: '7rem'},
                { text: 'Ca', value: 'shiftName', width: '7rem'},
                { text: 'Tạo vào', value: 'createdAt', width: '7rem'},
                { text: 'Tạo bởi', value: 'updatedBy', width: '7rem'},
                
            ]
        }
    },
    methods: {
        fetch(){
            return http.get(this.ROOT_URL+"work-history/"+this.item).then(res => {
                this.items = res.data;
            })
        },
        deleteQuickAction () {
            let that = this;
            return http.delete("assignments/quick-action/"+this.targetItem).then(res => {
                that.deleteConfirm = false;
                that.fetch();
            })
        },
        saveData() {
        },
        close(){
        }
    },
    created() {
    }
    
}
</script>
<style scoped>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .7;
        position: absolute;
        width: 100%;
    }
</style>