import http from '@/components/services/http-common';
// import * as moment from "moment/moment"

export const ROUTE = 'salaries';

class SalaryApi {
  gets () {
    return http.get(`${ROUTE}`, {
      params: {
      }
    }).then(r => r.data)
  }

}

export default new SalaryApi ();