<template>
  <v-tabs class="flex-grow-1 flex-shrink-0 " background-color="transparent" slider-color="green " color="green darken-4" right :slider-size="5">
      <v-tab :to="{name: 'Contractv2'}"> <b>1. TẠO MỚI</b></v-tab> 
      <v-tab :to="{name: 'Contractv2Pending'}"><b>2. GỞI NHÂN VIÊN</b></v-tab>  
      <v-tab :to="{name: 'Contractv2Return'}"><b>3. NHÂN VIÊN TRẢ VỀ</b></v-tab>  
      <v-tab :to="{name: 'Contractv2Ready'}"><b>4. SẴN SÀNG</b></v-tab>  
      <v-tab :to="{name: 'Contractv2Complete'}"><b>5. HOÀN THÀNH</b></v-tab>  
      <!-- <v-tab :to="{name: 'Contractv2Resign'}"><b>(*). CẦN TÁI KÝ</b></v-tab>  <v-divider vertical color=""></v-divider> -->
  </v-tabs>
</template>

<script>
export default {
  name: 'Contractv2Tab',
}
</script>

<style scoped>
.v-tab:not(.v-tab--active) {
  /* color: rgba(255, 255, 255, .8) !important; */
}
</style>