import http from '@/components/services/http-common';
// import * as moment from "moment/moment"

export const ROUTE = 'supplements';
// global api
class SupplementApi {
  gets(month, filter) {
    console.log('xxx',filter)
    return http.get(`${ROUTE}`, {
      params: {
        month: month,
        employeeCode: filter.employeeCode,
        employeeName: filter.employeeName,
        departmentCode: filter.departmentCode,
      }
    }).then(r => r.data)
  }

  updateCell(data){
    return http.put(`${ROUTE}/cell/${data.ma}`, data)
  }
  
  importing(month, file) {
    var formData = new FormData();
    formData.append("files", file);
    return http.post(`${ROUTE}/Import-cell/${month}`, formData);
  }
  
  getEmployees() {
    return http.get('employees/simple').then(r => r.data)
  }

  post(data, month) {
    return http.post(`${ROUTE}`, data, {
      params: {
        month: month
      }
    })
  }

}

export default new SupplementApi();