import http from '@/components/services/http-common'

const baseUrl = `${process.env.VUE_APP_BASE_URL}api/it/itSchedules`

export default class DepartmentService {
  gets = () => http.get(`${baseUrl}/`).then(res => res).then(d => d.data)

  get = id => http.get(`${baseUrl}/${id}`).then(res => res).then(d => d.data)

  setStep = (data) => http.get(`${baseUrl}/set-step`, {
    params: data
  }).then(res => res).then(d => d.data)

  postOrPut = (data) => http.post(`${baseUrl}/`,data).then(d=>d.data)

  import = (data, id) => http.post(`${baseUrl}/import?Id=${id}`,data).then(d => d.data)
  
  generateOr = (id) => http.get(`${baseUrl}/generate-otrq`,{
    params: {Id: id}
  })
}