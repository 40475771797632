<template>
    <v-dialog v-model="dialog" max-width="800px" scrollable @click:outside="close" eager>
        <v-card>
            <v-card-title v-if="item!=null">Chi tiết báo cáo {{ item.name }}
            <v-spacer></v-spacer>
            <v-btn icon color="red" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-card-subtitle class="py-0">
                <v-row class="my-0">
                    <v-col cols="12" md="6">
                        <b>Ngày tạo: </b> {{ form.createdAt }}
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="d-block">
                            <b>Chi nhánh gởi: </b> {{ form.fromDepartment }}
                        </div>
                        <div class="d-block">
                            <b>Phòng ban nhận: </b> {{ form.toDepartment }}
                        </div>
                    
                    </v-col>
                </v-row>
            </v-card-subtitle>
            
          <v-card-text class="text--primary my-0">
            <v-row class="my-0">
                <v-col cols="12" md="4">
                    <span class="text-subtitle-2">Thông tin báo cáo</span>
                    <p class="text-caption text--secondary"> Kiểm tra lại loại báo cáo và nơi gởi báo cáo</p>
                </v-col>
                <v-col cols="12" md="8">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Danh mục" :value="form.employeeReportCategory" hide-details outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Tên báo cáo" :value="form.name" hide-details outlined></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>

                <v-col cols="12" md="4">
                    <span class="text-subtitle-2">Nội dung về báo cáo</span>
                    <p class="text-caption text--secondary"> Nhập dữ liệu báo cáo</p>
                </v-col>
                
                <v-col cols="12" md="8">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete :items="fromDepartments" v-model="form.fromDepartmentCode" item-text="name" item-value="code" 
                            persistent-placeholder hide-details="auto"
                            :rules='[v=>!!v||"Chọn dữ liệu"]'
                            label="Phòng ban gởi báo cáo" outlined ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Tiêu đề báo cáo" v-model="form.name" hide-details="auto"
                            :rules='[v=>!!v||"Chọn dữ liệu"]'
                            persistent-placeholder outlined ></v-text-field>
                        </v-col>
                        <v-subheader class="mb-0">Chi tiết nội dung báo cáo</v-subheader>
                        <v-col cols="12" class="pt-0">
                            <p style="height:150px; overflow-y: auto; border-style:solid; border-width:2px; border-color:#004D40; padding: 12px; border-radius: 9px">
                                <span v-html="form.fromDepartmentNote"></span>
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
                
                <v-col cols="12" md="4">
                    <span class="text-subtitle-2">Dữ liệu hình ảnh</span>
                    <p class="text-caption text--secondary">Nhập dữ liệu hình ảnh theo mô tả đưa ra</p>
                </v-col>
                <v-col cols="12" md="8">
                    <v-row>
                        <v-col cols="12" v-for="(imageSlot, key) in form.employeeReportImages" :key="key">
                            <v-tooltip top color="rbga(0,0,0,1)" open-delay="300">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-img :src="imageSlot.link" v-bind="attrs" v-on="on"></v-img>
                                </template>
                                <v-img width="720px" :src="imageSlot.link" style=""></v-img>
                            </v-tooltip>
                            <h5 class="text-caption text-center">{{ imageSlot.employeeReportCategoryImage.name }}</h5>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!-- <div id="TSComment_Content"></div> -->
                <iframe v-if="openIframe" ref="commentIframe" width="100%" height="600px" :src="iFrameUrl" frameborder="0"></iframe>
              </v-col>

            </v-row>
          </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="statusColors[form.status]" rounded dark
                     @click="changeStatus(item.id)">
                    <v-icon left>{{statusIcons[form.status]}}</v-icon>
                    {{ statusesDict[form.status] }}
                </v-btn>
                <!-- <v-btn small color="primary" form="form" @click="save">Lưu </v-btn> -->
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from './services/api.js'
import moment from 'moment/moment'

export default {
    props:["value"],
    watch:{
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
        dialog: function (value) {
            if(value){
                this.fetch()
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    console.log("check is edit")
                    if(this.isEdit)
                    {
                        this.fetch()
                        this.headerCreated = true
                    }
                }
                else{
                    this.close();
                }
            },
        },

        employeeTextPaste(newVal) {
            this.form.ApplyToEmployeeCodes = newVal.length==0? []: newVal.split(',')
        },


    },
    computed: {
        // dialog: {
        //     get: function () {
        //         return this.value;
        //     },
        //     set: function (val){
        //         this.$emit("input", val)
        //     }
        // },
        user() {
            let userObjJSON = localStorage.getItem("user")
            if(userObjJSON){
                let userObj = JSON.parse(userObjJSON)
                return userObj
            }else {
                return null
            }
        },
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        isEdit() {
            return !!this.item;
        },
        validDetail(){
            console.log(this.rules.required);
        },
        iFrameUrl() {
            return `/comment.html?id=${this.form.id}&user=${this.user.employeeName}`
        }
    },
    data() {
        return {
            
            img: null,
            camera: null,
            deviceId: null,
            devices: [],
            imageUploading: false,
            uploaded: 0,
            totalUpload: 0,
            
            openIframe:false,
            
            qrSrc: "",
            valid: false,
            statuses: [
                {text: "Tạo mới", value: 0},
                {text: "Đã xem", value: 1}
            ],
            statusesDict: {
                0: "Tạo mới",
                1: "Đã xem"
            },
            statusColors: {
                0: 'blue',
                1: 'green'
            },
            statusIcons: {
                0: 'mdi-new-box',
                1: 'mdi-checkbox-marked-circle'
            },

            dialog: false,
            item: null,

            toDepartments:[],
            fromDepartments:[],

            form: {
                id: "",
                name: "",
                toDepartment: "",
                toDepartmentCode: "",
                fromDepartmentCode: "",
                fromDepartment: "",
                employeeReportCategoryCode: "",
                employeeReportCategory: "",
                images: "",
                toDepartmentNote: "",
                fromDepartmentNote: "",
                status: 0,
                createdAt: "",
                createdBy: "",
                createdByName: "",
                updatedAt: "",
                updatedBy: "",
                updatedByName: "",
            },
            defaultForm: {
                id: "",
                name: "",
                toDepartment: "",
                toDepartmentCode: "",
                fromDepartmentCode: "",
                fromDepartment: "",
                employeeReportCategoryCode: "",
                employeeReportCategory: "",
                images: "",
                toDepartmentNote: "",
                fromDepartmentNote: "",
                status: 0,
                createdAt: "",
                createdBy: "",
                createdByName: "",
                updatedAt: "",
                updatedBy: "",
                updatedByName: "",
            },
            employeeTextPaste:'',

            /**header */
            categories: [],
            departments: [],
            employees:[],
            /** Detail Header*/
            detailHeaders: [
                {text:"Tên dụng cụ", value: "id", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "name", width:"7rem", align:"center"},
                {text:"Số lượng", value: "toDepartmentCode", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "employeeReportCategoryCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "images", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "toDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "status", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdBy", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedBy", width:"7rem", align:"center"},
                {text:"", value: "actions", width:"7rem", align:"center"},
            ],
            properties:[],
            detail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
            },
            detailItems:[],

            propertyValid: false,
            amountValid: false,

            
            rules: {
                required: value => !!value || 'Required.'
            },

            /**Stepper params */
            headerCreated: false,
        }
    }, 
    methods: {
        
        onCapture() {
            this.img = this.$refs.webcam.capture();
            // convert to blob then upload
            const contentType = 'image/jpeg';
            const filename = 'example.jpg';
            
            const file = this.base64ToFile(this.img.split(',')[1], filename);

            console.log(file)
            this.imageUploading = true
            this.totalUpload +=1
            this.apiService.uploadFile([file]).then(d => {
                var joinString = [this.form.images, d]
                this.form.images = joinString.filter(x=>!!x).join(';')
            }).finally(() => {
                this.uploaded +=1
                if(this.totalUpload == this.uploaded){
                    this.imageUploading = false
                }
            })
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        base64ToFile(base64String, filename, mimeType = ''){
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });

            return new File([blob], filename);
        },

        getUnit(property) {
            var data = this.properties.find(x=>x.code == property);
            if(!data)
                return ""
            else
                return data.propertyUnitName
        },
        getDepartments() {
            this.apiService.getDepartments().then(d => {
                this.departments = d
            })
        },

        getCategories() {
            this.apiService.getCategories(this.form.ToDepartmentCode).then(d => {
                this.categories = d
            })
        },

        getEmployees() {
            this.apiService.getEmployees().then(d => {
                this.employees = d
            })
        },
        close(){
            this.dialog = false
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.openIframe=false
            // this.$refs.form.resetValidation();

            this.uploaded = 0
            this.totalUpload = 0

            this.$emit('close-dialog');


        },
        
        changeStatus(id) {
            return this.apiService.changeStatus(id).then(() => {
                this.fetch()
            })
        },

        save() {
            this.$refs.form.validate();
            if(this.valid)
            {
                //tao phieu
                if(!this.isEdit) {
                    return this.apiService.post( this.form).then(res => {
                        // this.$emit("fetchData", res.data.code);
                        this.close();
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    });
                }

                //update phieu
                if(this.isEdit) {
                    return this.apiService.putTemplate(this.item,this.form).then(res => {
                        // this.$emit("fetchData");
                        this.close();
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    });
                }
            }
        },
        fetch() {
            return this.apiService.getItemDetail(this.item.id).then(d => {
                this.openIframe=true
                this.form = Object.assign({}, d);
            })
        },
        getToDepartments() {
            this.apiService.getToDepartments().then(d => {
                console.log(d)
                this.toDepartments = d
            })
        },

        getFromDepartments() {
            this.apiService.getFromDepartments().then(d => {
                this.fromDepartments = d
            })
        }
        // fetchDepartment()
        // {
        //     return http.get("propertyRequests/get-departments").then(res => {
        //         this.departments = res.data;
        //     });
        // },
        // fetchProperty()
        // {
        //     return http.get("properties").then(res => {
        //         this.properties = res.data.items;
        //     });
        // },
        // fetchDetail() {
        //     let url = `propertyRequests/detail/${this.form.code}`
        //     return http.get(url).then(res => {
        //         this.detailItems = res.data;
        //     })
        // },
        

        //SERVICES
    },
    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted() {
        this.getToDepartments()
        this.getFromDepartments()
        
        // this.startVideo()
        // this.getDepartments();
        // this.getEmployees()
    }
}
</script>