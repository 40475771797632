<template>
  <div>
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
    <v-card >
      <v-card-title>
        <v-text-field label="Tháng" v-model="month" style="max-width: 200px;" class="mx-2" type="date" hint="Chọn ngày đầu tháng vd 01/09/2023"></v-text-field>

        <v-spacer></v-spacer>
        <v-btn color="error" small class="mx-2" @click="applyDialog=true">Chốt điều chỉnh</v-btn>
        <v-btn @click="importDialog = true" dark color="teal darken-4" icon><v-icon>mdi-database-import</v-icon></v-btn>
        <v-btn class="mx-2" @click="createDialog = true" icon color="teal darken-4"><v-icon>mdi-plus</v-icon></v-btn>
        <v-btn class="mx-2" @click="getData" icon color="teal darken-4"><v-icon >mdi-refresh</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row  class="">
          <v-col cols="6" md="2">
            <v-text-field hide-details v-model="filter.employeeCode" outlined dense label="MSNS" @change="getData"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field hide-details v-model="filter.employeeName" outlined dense label="Họ và tên" @change="getData"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-autocomplete clearable :items="filterDepartments" hide-details v-model="filter.departmentCode" outlined dense label="Chi nhánh" @change="getData"></v-autocomplete>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-data-table :headers="headers" :items="items" :loading="isLoading" fixed-header height="65vh">
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn x-small @click="removeRow(item)" v-if="item.isLock==0" color="error">Xóa</v-btn>
          </template>
          <template v-slot:[`item.isUpSalary`]="{ item }" >
            <v-checkbox dense hide-details solo v-model="item.isUpSalary" @change="updateCell(item)" :true-value="1" :false-value="0"></v-checkbox>
          </template>
          <template v-slot:[`item.isUpPositionState`]="{ item }" >
            <v-checkbox dense hide-details solo v-model="item.isUpPositionState" @change="updateCell(item)" :true-value="1" :false-value="0"></v-checkbox>
          </template>
          <template v-slot:[`item.isDownShift`]="{ item }" >
            <v-checkbox dense hide-details solo v-model="item.isDownShift" @change="updateCell(item)" :true-value="1" :false-value="0"></v-checkbox>
          </template>
          <template v-slot:[`item.isNewEmployee`]="{ item }" >
            <v-checkbox dense hide-details solo v-model="item.isNewEmployee" @change="updateCell(item)" :true-value="1" :false-value="0"></v-checkbox>
          </template>
          <template v-slot:[`item.isRotate`]="{ item }" >
            <v-checkbox dense hide-details solo v-model="item.isRotate" @change="updateCell(item)" :true-value="1" :false-value="0"></v-checkbox>
          </template>
          <template v-slot:[`item.oldSalary`]="{ item }" >
            {{ item.oldSalary }}
            <!-- <v-text-field dense hide-details solo v-model="item.oldSalary" @change="updateCell(item)"></v-text-field> -->
          </template>
          <template v-slot:[`item.newSalary`]="{ item }" >
            {{ item.newSalary }}
            <!-- <v-text-field dense hide-details solo v-model="item.newSalary" @change="updateCell(item)"></v-text-field> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Import Dialog******** -->
    <v-dialog v-model="importDialog" width="600px"> 
      <v-card :loading="importLoading">
        <v-card-title>Import dữ liệu</v-card-title>
        <v-card-subtitle>
          <small class="info--text mr-6">Template excel: </small>
          <export-excel :items="items" :headers="headers" class="mx-6"></export-excel>
        </v-card-subtitle>
        <v-card-text>
          <v-form v-model="validImport" id="import_supplement" ref="import_supplement" @submit.prevent="importHandler">
            <v-row>
              <v-col>
                <v-file-input v-model="importFile" label="File import" persistent-placeholder :rules="[v => !!v || 'Chọn file import']"></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="teal darken-4" form="import_supplement" type="submit" small dark>
            <span v-if="importLoading"> Dữ liệu đang import. vui lòng không chuyển trang</span>
            <span v-else>Nhập dữ liệu</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CreateDialog *********** -->
    <v-dialog v-model="createDialog" width="600px" scrollable>
      <v-card :loading="createDialogLoading">
        <v-card-title>Tạo dữ liệu mới</v-card-title>
        <v-card-text>
          <v-form v-model="isValid" ref="salary_adjustment_form" id="salary_adjustment_form" @submit.prevent="save">
            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="form.employeeCode" placeholder="Chọn nhân viên" label="Nhân viên" :items="employees" @change="employeeCodeChangeHandler"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select readonly v-model="form.departmentCode" label="Phòng ban" :items="departments"></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select readonly v-model="form.areaCode" label="Vùng" :items="areas"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="form.oldPositionStateCode" label="Chức danh cũ" :items="positionStates"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="form.newPositionStateCode" label="Chức danh mới" :items="positionStates"></v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field v-model="form.oldSalary" label="Lương cũ" outlined hide-details="auto" placeholder="Nhập số liệu" persistent-placeholder></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="form.newSalary" label="Lương mới" outlined hide-details="auto" placeholder="Nhập số liệu" persistent-placeholder></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox v-model="form.isUpSalary" label="Tăng lương" :true-value="1" :false-value="0"></v-checkbox>
              </v-col>
              
              <v-col cols="12" md="6">
                <v-checkbox v-model="form.IsUpPositionState" label="Nâng cột" :true-value="1" :false-value="0"></v-checkbox>
              </v-col>
              
              <v-col cols="12" md="6">
                <v-checkbox v-model="form.isDownShift" label="Xuống ca" :true-value="1" :false-value="0"></v-checkbox>
              </v-col>
              
              <v-col cols="12" md="6">
                <v-checkbox v-model="form.isNewEmployee" label="Nhân viên mới" :true-value="1" :false-value="0"></v-checkbox>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox v-model="form.isRotate" label="Điều chuyển" outlined></v-checkbox>
              </v-col>

              <v-col cols="12">
                <v-textarea label="Ghi chú" v-model="form.note">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn outlined color="error" class="mx-2">Hủy</v-btn>
          <v-btn dark color="teal darken-4" class="mx-2" type="submit" form="salary_adjustment_form">Lưu</v-btn>
        </v-card-actions>        
      </v-card>
    </v-dialog>

    <v-dialog v-model="applyDialog" width="600px">
      <v-card>
        <v-card-title>
          Chốt điều chỉnh lương tháng
        </v-card-title>
        <v-card-subtitle class="warning--text">
          <b>Lưu ý:</b> Dữ liệu sau khi chốt không thể thu hồi
        </v-card-subtitle>

        <v-card-actions class="justify-center">
          <v-btn class="mx-2" color="error" outlined @click="applyDialog=false">Thoát</v-btn>
          <v-btn class="mx-2" color="teal darken-4" dark @click="applyHandler">Đồng ý</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import moment from 'moment';
import SalaryAdjustmentApi from './api'
import ApiServices from '@/components/services/ApiServices'
import ExportExcel from '@/components/ExportExcel'
import MyBreadcrumb from '@/components/MyBreadcrumb'

export default {

  watch: {
    createDialog(value) {
      if(value){
        this.getDropDownItems()
      } else {
        this.closeDialogHandler()
      }
    }
  },

  data() {
    return {
      breadcrumbs: [
        {text: 'Danh sách điều chỉnh lương', to: {name:'SalaryAdjustment'}}
      ],

      columns: [{ prop: "name" }, { prop: "details" }],
      rows: [{
        name: "1",
        details: "Item 1",
      }],
      isLoading: false,
      month:moment().startOf('months').format("YYYY-MM-DD"),
      filter: {
        employeeCode: '',
        employeeName: '',
        departmentCode: '',
      },
      filterDepartments:[],

      importFile: null,
      importDialog: false,
      importLoading: false,
      validImport: true,
      headers: [
            
        { text: '', value: 'actions' },
        { text: 'Mã', value: 'code' },
        { text: 'MSNV', value: 'employeeCode' },
        { text: 'MSNV', value: 'employeeName' },
        { text: 'Mã phòng ban', value: 'departmentName', width: 150 },
        { text: 'Chức danh cũ', value: 'oldPositionStateName', width: 150 },
        { text: 'Chức danh mới', value: 'newPositionStateName' , width: 150},
        { text: 'Lương tháng trước', value: 'oldSalary', width: 150 },
        { text: 'Lương tháng này', value: 'newSalary', width: 150 },
        { text: 'Tăng lương', value: 'isUpSalary', width: 150 },
        { text: 'Nâng cột', value: 'isUpPositionState', width: 150 },
        { text: 'Xuống ca', value: 'isDownShift', width: 150 },
        { text: 'Nhân viên mới', value: 'isNewEmployee', width: 150 },
        { text: 'Điều chuyển', value: 'isRotate', width: 150 },
        { text: 'Ghi chú', value: 'note', width: 150 },
        // { text: 'Tháng', value: 'month', width: 150 }
        ],

        items: [],

        createDialogLoading: false,
        createDialog: false,
        form: {
          departmentCode: '',
          areaCode: '',
          newPositionStateCode: '',
          oldPositionStateCode: '',
          employeeCode: '',
          newSalary: 0,
          oldSalary: 0,

          isUpSalary: 0,
          isUpPositionState: 0,
          isDownShift: 0,
          isNewEmployee: 0,
          IsRotate: 0,
          note: '',

        },
        isValid: false,

        employees: [],
        departments: [],
        areas: [],
        positionStates: [],

        applyDialog: false,
    };
  },
  methods: {
    getData() {
      this.isLoading = true
      return SalaryAdjustmentApi.gets(this.month, this.filter).then(d => d.data).then(data => {
        this.items = data
        console.log(this.items)
        this.isLoading = false
      })
    },
    removeRow(item){
      return SalaryAdjustmentApi.remove(item.code).then(() => {
        this.getData()
      })
    },

    getDepartment() {
      return ApiServices.fetchDepartment().then(r =>{
        this.filterDepartments = r.data.map(x => {
          return {
            text: x.name,
            value: x.code
          }
        })
      });
    },

    updateCell(item){
      console.log(item)
      return SalaryAdjustmentApi.updateCell(item).then(() => this.getData())
    },

    importHandler() {
      // alert("import")
      this.importLoading = true
      return SalaryAdjustmentApi.importing(this.month, this.importFile).finally(() => {
        this.importLoading = false
      })
    },


    //create handler
    save() {
      this.createDialogLoading = true
      return SalaryAdjustmentApi.post(this.form, this.month).then(() => {
        this.createDialogLoading = false
      })
    },

    applyHandler() { 
      this.isLoading = true
      return SalaryAdjustmentApi.apply(this.month).then(() => {
      }).finally(() => {
        this.isLoading = false
      })
    },

    closeDialogHandler() {
      this.form.employeeCode = ''
      this.form.departmentCode = ''
      this.form.areaCode = ''
      this.form.newPositionStateCode = ''
      this.form.oldPositionStateCode = ''
      this.newSalary = 0
      this.oldSalary = 0
      
      this.form.isUpSalary = 0
      this.form.isUpPositionState = 0
      this.form.isDownShift = 0
      this.form.isNewEmployee = 0
      this.form.IsRotate = 0
      this.form.note = ''
    },
    

    getDropDownItems() {
      SalaryAdjustmentApi.getDepartments().then(d => {
        this.departments = d.map(x => {
          return {
            text: `${x.name}`,
            value: x.code
          }
        })
      })

      SalaryAdjustmentApi.getAreas().then(d => {
        this.areas = d.items.map(x => {
          return {
            text: `${x.name}`,
            value: x.code
          }
        })
      })

      SalaryAdjustmentApi.getPositionStates().then(d => {
        this.positionStates = d.map(x => {
          return {
            text: `${x.name}`,
            value: x.code
          }
        })
      })

      SalaryAdjustmentApi.getEmployees().then(d => {
        this.employees = d.map(x => {
          return {
            text: `${x.code}: ${x.name} (${x.departmentName})`,
            value: x.code
          }
        })
      })
    },

    // getCurrentSalary(employeeCOde) {
    // },

    employeeCodeChangeHandler(value) {
      return SalaryAdjustmentApi.getEmployeeInfo(value, this.month).then(d => {
        console.log(d)
        this.form.departmentCode = d.departmentCode
        this.form.areaCode = d.areaCode
        this.form.newPositionStateCode = d.newPositionStateCode
        this.form.oldPositionStateCode = d.oldPositionStateCode
      }).then(() => { 
        return SalaryAdjustmentApi.getEmployeeCurrentSalary(value).then(s => {
          this.form.oldSalary = s
        })
      })
    },

  },
  components: {
    ExportExcel,
    MyBreadcrumb
  },
  created() {
    this.getDepartment()
    this.getData()
  },
};
</script>