import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'infoUpdateRequests';
// global api
class InfoUpdateApi {
  gets() {
    return http.get(`${ROUTE}/admin`).then(r => r.data.data)
  }
  
  readyGets() {
    return http.get(`${ROUTE}/admin/ready`).then(r => r.data.data)
  }
  
  getEmployee() {
    return http.get(`${ROUTE}/employee`).then(r => r.data.data)
  }

  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => r.data.data)
  }

  post(data) {
    return http.post(`${ROUTE}`, data)
  }
  remove(id){
    return http.delete(`${ROUTE}/${id}`)
  }
  changeStatus(id, status){
    return http.get(`${ROUTE}/admin/change-status/${id}`, {
      params: {
        status: status
      }
    })
  }
  updateToEmployee() {
    return http.get(`${ROUTE}/admin/update-employee`)
  }

}

export default new InfoUpdateApi();