<template>
    <div class="leave-index" >
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog> -->

        <v-card v-if="true" class="mb-5">
            <v-card-title>
                Danh sách khảo sát
                <v-spacer></v-spacer>
                <!-- <v-btn small color="teal darken-4" dark @click="dialog.state=true">
                    <v-icon small left>mdi-upload</v-icon> Upload excel
                </v-btn> -->
                <!-- <create-button @click="dialog.state=true" ></create-button> -->
                <v-divider vertical class="mx-2"></v-divider>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field outlined hide-details label="Tìm kiếm" placeholder="" persistent-placeholder v-model="globalSearch"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>

        <v-row>
          
          <v-col cols="9">
              <v-data-iterator
                :items="templates"
                :search="globalSearch"
              >
                  <template v-slot:default="props">
                    <v-row class="fill-height">
                      <v-col cols="12" md="6" v-for="template in props.items" :key="template.id">
                        <v-card>
                          <v-img height="300px" src="https://www.mycustomer.com/sites/default/files/styles/inline_banner/public/istock_blossomstar_survey.jpg?itok=O99NnX3-">

                          </v-img>
                          <v-card-title class="teal--text text--darken-4">{{template.name}}</v-card-title>
                          <v-card-text style="height: 120px; overflow-y: auto;r">
                            <p><small><b>Đã khảo sát: </b> 398</small></p>
                            <p><small><b class="error--text">Hết hạn vào: </b> 30/07/2023</small></p>
                            <div  class="text--primary body-2">
                            {{template.description}}
                            </div>
                          </v-card-text>
                          <v-card-actions >
                            <v-spacer></v-spacer>
                            <v-btn dark depressed color="orange darken-2" :to="{ name: 'SurveyEmployeeDetail', params: { id: template.id } }" >Tham gia khảo sát</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
              </v-data-iterator>
          </v-col>
          
          <v-col cols="3">
            <v-row>
              <v-col>
                <v-card min-height="500px" class="">
                  <v-card-title primary-title>
                    Khảo sát đã tham gia
                  </v-card-title>
                  <v-list color="transparent">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold green--text text--darken-4">
                          xxxxxx
                        </v-list-item-title>
                        <v-list-item-subtitle 
                          class="text--primary mt-1">
                          <v-icon left>mdi-clock</v-icon>
                          xxxxxx
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold green--text text--darken-4">
                          Bài khảo sát lấy ý kiến số 1
                        </v-list-item-title>
                        <v-list-item-subtitle 
                          class="text--primary mt-1">
                          <v-icon left>mdi-clock</v-icon>
                          xxxxxx
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold green--text text--darken-4">
                          Bài khảo sát lấy ý kiến số 1
                        </v-list-item-title>
                        <v-list-item-subtitle 
                          class="text--primary mt-1">
                          <v-icon left>mdi-clock</v-icon>
                          xxxxxx
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold green--text text--darken-4">
                          Bài khảo sát lấy ý kiến số 1
                        </v-list-item-title>
                        <v-list-item-subtitle 
                          class="text--primary mt-1">
                          <v-icon left>mdi-clock</v-icon>
                          xxxxxx
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-card-text></v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import api from './api.js';
// import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Leave',
    components: {
        CreateButton,
        // "create-dialog": Create,
        "data-filter": DataFilter
    },
    data() {
      return {
        globalSearch:'',
        templates: [],

        dialogDelete: false,
        cfmDeleteItem: undefined
      }
    },
    methods: {
      getValidTemplates() {
        return this.apiServices.getValid().then(d => {
          this.templates = d.data
        });
      }
    },
    apiServices: null,
    created() {
      this.apiServices = new api();
        // this.fetchData();
    },
    mounted() {
      this.getValidTemplates()
    }
}
</script>