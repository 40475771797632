<template>
    <div class="departmentType">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb>
        <create-dialog ref="createDialog" @needFetch="fetchData"></create-dialog>
        <!-- DATA-TABLE -->
        <v-card>
            <v-card-title>
                Danh sách khối
            <v-spacer></v-spacer>
            <v-text-field v-model="search" @keydown.enter="fetchData" class="mr-2" 
                append-icon="mdi-magnify"
                label="Tìm kiếm"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="departmentTypes"
            :options.sync="options"
            :server-items-length="totalItem"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    {{item.status == "DEACTIVATE" ? "Vô hiệu hóa" : "Bình thường"}}
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import myBreadCrumb from '@/components/BreadCrumb'
import http from '@/components/services/http-common'
import DepartmentTypeCreateDialog from './Create'

export default {
    name: 'DepartmentType-index',
    components: {
        myBreadCrumb,
        'create-dialog': DepartmentTypeCreateDialog
    },
    data () {
        return {
            breadcrumbs: [
                {
                text: 'Dashboard',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Quản lí khối ',
                disabled: false,
                to: {
                    name: "departmentType",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: "departmenttype.create"
            },

            //datatable config
            totalItem: 0,
            departmentTypes: [],
            search:"",
            options: {},
            isLoading: true,
            dialog: false,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                {
                    text: 'Mã khối',
                    align: 'start',
                    sortable: false,
                    value: 'code',
                },
                {
                    text: 'Tên khối',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Mô tả', value: 'description' },
                // { text: 'Trạng thái', value: 'status' },
                { text: 'Thao tác', value: 'actions', sortable: false },
            ],

        }
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    methods:{
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData () {
            const { page, itemsPerPage } = this.options;
            if(page != undefined || itemsPerPage != undefined)
                http.get('departmenttypes?search='+this.search + '&page='+ page + '&pageSize=' + itemsPerPage).then(res => {
                    this.isLoading = false;
                    this.departmentTypes = res.data.items;
                    this.totalItem = res.data.totalItem;
                }).catch(err => {
                    console.log(err);
                });
        },
        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate(item.code);
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete("departmenttypes/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    mounted () {
        this.fetchData();
    }
}
</script>

<style lang="stylus" scoped>

</style>