<template>
    <div>
      <v-dialog v-model="createDialog" width="400px" >
        <create-form :itemId="selectItem" @close-dialog="createDialog=false" ref="create_form"></create-form>
      </v-dialog>
        <v-card>
            <v-toolbar color="white" flat>
                <v-toolbar-title class="mx-4">Danh sách phiếu</v-toolbar-title>
                <v-spacer></v-spacer>
                
                <v-btn color="primary" text @click="fetchData"><v-icon left >mdi-refresh</v-icon> Làm mới</v-btn>
                <!-- //actions -->
                <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success" class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                    <v-list-item @click="createDialog=true">
                        <v-list-item-title>Thêm phiếu <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            
            <v-card-title>
                <v-text-field hide-details v-model="search" placeholder="Tìm kiếm" label="Tìm kiếm" persistent-placeholder outlined dense></v-text-field>
            </v-card-title>
            <v-data-table :items="items" :headers="headers"  height="calc(100vh - 300px)" :search="search">
                <template v-slot:[`item.actions`]="{item}" >
                    <edit-button @click="edit(item)"></edit-button>
                    <!-- <delete-button @click="deleteItem(item)"></delete-button> -->
                </template>
            </v-data-table>

        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <!-- <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn> -->
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import CreateDialog from './Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import ImageReportCategoryService from '../../services/ImageReportCategoryService'
import CreateForm from '../ImageReportCategory/CreateForm.vue'
import Vue from 'vue'

export default {
    components:{
      CreateForm
    },
    watch:{
        createDialog(val) {
            if(val){
              console.log('open dialog')
            }
            else {
              this.selectItem=null
              this.$refs.create_form.resetForm()
            }
        },
    },
    data () {
        return {
            month: moment().startOf("months").format("yyyy-MM"),
            item: null,
            items:[],
            search:"",

            filterCode:"", 
            filterStatus:"", 
            filterDepartment:"", 
            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text:"ID", value: "id"},
                {text:"Mã", value: "code"},
                {text:"Tên", value: "name"},
                {text:"Mô tả", value: "description"},
                {text:"Trạng thái", value: "status"},
            ],

            dialogDelete: false,
            dialog: false,

            createDialog: false,
            selectItem: null,
        }
    },
    methods: {
        fetchData() {
            this.imageReportCategoryService.gets().then(d => {
              this.items = d
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.selectItem = item.code;
            this.createDialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `propertyRequests/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
    },
    imageReportCategoryService: null,
    created() {
      this.imageReportCategoryService = new ImageReportCategoryService();
    },
    mounted () {
        this.fetchData();
    }
}
</script>