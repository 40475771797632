<template>
    <v-sheet class="chart-container" :style="`position: relative; height: 100%`">
        <canvas ref="chart"></canvas>
    </v-sheet>   
</template>
<script>
import {Chart} from "chart.js";
export default {
    props:{
        chartData:Object,
        chartOptions:Object,
        width: String,
        height: String,
        maxWidth: String,
        label: String
    },
    data(){
        return {
            chart:undefined
        }
    },
    methods:{
        chartConstructor( chartData) {
            const chartElement = this.$refs.chart;
            this.chart = new Chart(chartElement, {
                type: "bar",
                data: chartData,
                options: {
                    indexAxis: this.$vuetify.breakpoint.name=='xs'?'y':'x',
                    aspectRatio: this.$vuetify.breakpoint.name=='xs' ?0.8:2,
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: this.label,
                            position: "bottom",
                            center:"center",
                            fullSize: true
                        },
                        legend: {
                            position: "top",
                        }
                    }
                },
            });
        },
        update(){
            this.chart.update();
        }
    },
    mounted(){
        let {chartData} = this;
        this.chartConstructor(chartData);
    }
}
</script>