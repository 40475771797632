import http from '@/components/services/http-common'

const baseUrl = `${process.env.VUE_APP_BASE_URL}api/it/users`

export default class UserService {
  gets = () => http.get(`${baseUrl}/`).then(res => res).then(d => d.data)

  resetDevice = (id, note) => http.get(`${baseUrl}/reset-device/${id}`, {
    params: {note: note}
  })
  
  addWeb = (id) => http.get(`${baseUrl}/add-web/${id}`)

  changePassword = (id,data) => http.put(`${baseUrl}/change-password/${id}`, data)
  
  // getDeptIp = (id) => http.get(`${baseUrl}/ip-list/${id}`).then(res => res).then(d => d.data)

  // updateIp = (id, data) => http.put(`${baseUrl}/update-ip/${id}`, data).then(res => res).then(d => d.data)
}