<template>
    <div class="enrollment">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog=true"></myBreadCrumb> -->
        <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog>
        <v-card>
            <v-card-title>
                <!-- Danh sách chấm công -->
            <v-spacer></v-spacer>
            <refresh-button @click="fetchData"></refresh-button>
            <excel-button @click="exportPrompt=true"></excel-button>
            <export-prompt v-model="exportPrompt" url="enrollments"></export-prompt>
            <create-button @click="dialog=true"></create-button>
            <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter> -->
            </v-card-title>
            
            <v-data-table
            :mobile-breakpoint="0"
            height="600px" fixed-header
            :headers="headers"
            :items="items"
            
            :page.sync="tableParams.page"
            :sort-by.sync="tableParams.sortBy"
            :sort-desc.sync="tableParams.sortDesc"
            :server-items-length="rowCount"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode"
                            :items="departments" item-value="code" item-text="name" search-input.sync="departmentSearch"
                            @update:search-input="fetchDepartment" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.date" type="date" clearable outlined hide-details></v-text-field>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>
                <template
                    v-slot:[`item.actions`]="{item}"
                >
                    <edit-button @click="edit(item)"></edit-button>
                    <delete-button @click="remove(item)"></delete-button>
                    <!-- <v-btn x-small  class="mx-1">
                        <v-icon small>mdi-pencil</v-icon> Chi tiết
                    </v-btn>
                    <v-btn x-small @click="remove(item)" class="mx-1" color="error">
                        <v-icon small>mdi-trash-can</v-icon> Xóa
                    </v-btn> -->
                </template>
                <template
                    v-slot:[`item.time`]="{ item }"
                >
                    {{ formatTime(item.time) }}
                </template>
            </v-data-table>
        </v-card>
        
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import CreateDialog from './Create.vue';
import * as moment from "moment/moment"
import ApiService from "@/components/services/ApiServices"

export default {
    name: "Attendance",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "enrollments",
            exportPrompt: false,
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            departments: [],
            departmentSearch:null,
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentCode:null,
                date: null,
            },
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', width: '12rem'},
                { text: 'Mã', align: 'start', value: 'code', width: '7rem'},
                { text: 'Mã nhân viên', align: 'start', value: 'employeeCode', width: '9rem'},
                { text: 'Nhân viên', align: 'start', value: 'name', width: '9rem'},
                { text: 'Phòng ban', align: 'start', value: 'employeeDepartment', width: '9rem'},
                { text: 'Ngày', value: 'date', width: '7rem'},
                { text: 'Vào', value: 'checkin', width: '7rem' },
                { text: 'Ra', value: 'checkout', width: '7rem'},
                { text: 'Nơi chấm công', align: 'start', value: 'departmentCodeNavigation.name', width: 200},
                { text: 'IP checkin', align: 'start', value: 'ipIn', width: 250},
                { text: 'IP checkout', align: 'start', value: 'ipOut', width: 250},
                // { text: 'IPv4 checkin', align: 'start', value: 'ip4In', width: 250},
                // { text: 'IPv4 checkout', align: 'start', value: 'ip4Out', width: 250},
                // { text: 'IPv6 checkin', align: 'start', value: 'ip6In', width: 250},
                // { text: 'IPv6 checkout', align: 'start', value: 'ip6Out', width: 250},
                { text: 'Định danh checkin', align: 'start', value: 'fingerprintIn', width: 250},
                { text: 'Định danh checkout', align: 'start', value: 'fingerprintOut', width: 250},
            ],
        }
    },
    methods: {
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchDepartment () {
            ApiService.fetchDepartment(this.departmentSearch).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
            })
            .catch(err => {

            })
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL+"/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchData();
    }
}
</script>
<style scoped>

::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>