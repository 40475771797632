import http from "@/components/services/http-common"
export default class Api {
  verifyUser = (username) => http.get("users/verify", {
    params: {
      username: username
    }
  }).then(res => res.data)

  pendingUser = () => http("account/profile-pending").then(r => r.data)

  pendingUserStatus = (id, status, note="") => http(`contractsv2/change-status/${id}`, {
    params: {
      status: status,
      note: note
    }
  })

  forgetPassword = (userName) => http.get(`forgetPassword/send-email`, {
    params: {
      userName: userName
    }
  }).then(r =>r.data)
}