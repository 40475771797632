<template>
  <div>
      <create-dialog  @fetchData="fetchData" ref="create_employee_report_dialog"></create-dialog>
      <view-dialog  @fetchData="fetchData" ref="view_employee_report_dialog"></view-dialog>
      
      <v-row>
          <v-col cols="12" >
              <v-card>
                  <v-toolbar color="white" flat>
                      <v-toolbar-title class="mx-4 d-none d-md-block">Danh sách phiếu
                      <v-btn icon @click="fetchData" color="orange"><v-icon>mdi-refresh</v-icon></v-btn>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      
                      <v-dialog
                          ref="monthDialog"
                          :return-value.sync="filterMonth"
                          persistent
                          width="290px"
                      >
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field outlined dense class="mx-2" style="max-width:150px"
                              v-model="filterMonth"
                              label="Chọn tháng *"
                              prepend-icon="mdi-calendar"
                              readonly hide-details
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                          </template>
                          <v-date-picker dense locale="vi"
                          v-model="filterMonth"
                          type="month"
                          scrollable
                          >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.monthDialog.save(filterMonth)"
                          >
                              Chọn
                          </v-btn>
                          </v-date-picker>
                      </v-dialog>
                      <v-btn color="teal" outlined @click="openDialog" >
                        Tạo hợp đồng
                      </v-btn>
                      
                      <!-- //actions -->
                      <!-- <v-menu
                          left
                          bottom offset-y
                      >
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn outlined color="success" class="ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                  Hành động<v-icon>mdi-chevron-down</v-icon>
                              </v-btn>
                          </template>

                          <v-list>
                          <v-list-item @click="dialog=true">
                              <v-list-item-title>Thêm phiếu <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                          </v-list-item>
                          </v-list>
                      </v-menu> -->
                  </v-toolbar>
                  
                  
                    <filter-section 
                        :filterEmployeeCode.sync="filterEmployeeCode"
                        :filterName.sync = "filterName"
                        :filterIdNumber.sync ="filterIdNumber"
                        :filterDepartment.sync = "filterDepartment"
                        :filterPositionState.sync = "filterPositionState"
                    ></filter-section>
              </v-card>
              <v-row class="mt-5">
                  <v-col cols="12">
                        <contract-v2-tab></contract-v2-tab>
                      <v-card>
                          <!-- <v-card-title>Danh sách bộ câu hỏi</v-card-title> -->
                          <v-data-table :items="items1" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                          :search="globalSearch" >
                          
                              <template v-slot:[`item.actions`]="{item}" > 
                                  <v-menu dense
                                          left
                                          bottom offset-y
                                      >
                                          <template v-slot:activator="{ on, attrs }">
                                              <v-btn color="primary" x-small
                                                  v-bind="attrs"
                                                  v-on="on"
                                              >
                                                  Thao tác<v-icon>mdi-chevron-down</v-icon>
                                              </v-btn>
                                          </template>

                                          <v-list>
                                              <v-list-item @click="edit(item)" dense>
                                                  <v-list-item-icon>
                                                      <v-icon color="primary">mdi-pen</v-icon>
                                                  </v-list-item-icon>
                                                  <v-list-item-title>Chi tiết </v-list-item-title>
                                              </v-list-item>
                                          </v-list>
                                      </v-menu>
                              </template>
                              <template v-slot:[`item.createdAt`]="{item}" > 
                                  <span >{{ formatDate(item.createdAt) }}</span>
                              </template>
                              <template v-slot:[`item.fromDepartmentNote`]="{item}" > 
                                  <span v-html="item.fromDepartmentNote"></span>
                              </template>
                              
                              <template v-slot:[`item.uploadConfirm`]="{item}" > 
                                
                                <v-dialog width="500" v-model="contractDialog">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" class="rounded-xl" small v-bind="attrs" v-on="on" @click="contractUpload=null">
                                            <v-icon>mdi-upload</v-icon> Tải lên file đã duyệt
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title>File hợp đồng số: {{ item.contractNumber }}</v-card-title>
                                        <v-card-text>
                                            <v-file-input v-model="contractUpload" outlined prepend-icon="" hide-details="" clearable label="File Upload"></v-file-input>
                                        </v-card-text>
                                        <v-card-actions class="justify-center">
                                            <v-btn class="rounded-l" color="green" dark small @click="uploadConfirm(item.id)">Tải lên</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                              </template>

                              <template v-slot:[`item.status`]="{item}" >
                                  <v-chip
                                  :color="statusColors[item.status]"
                                  text-color="white"
                                  >
                                  <v-avatar left>
                                      <v-icon>{{statusIcons[item.status]}}</v-icon>
                                  </v-avatar>
                                  {{ statusesDict[item.status] }}
                                  </v-chip>
                              </template>
                              

                              <template v-slot:[`item.employeeStatus`]="{item}">
                                <v-checkbox color="green" v-model="item.employeeStatus" :true-value="2" :false-value="1" @click="verify(item, item.employeeStatus, 'EMPLOYEE')"></v-checkbox>
                              </template>
                              <template v-slot:[`item.hrManagerStatus`]="{item}">
                                    <v-checkbox color="green" class="d-inline-block" v-model="item.hrManagerStatus" :true-value="2" :false-value="1" @click="verify(item, item.hrManagerStatus, 'HR')"></v-checkbox>
                            </template>

                            <template v-slot:[`item.attachment`]="{item}">
                                <a :href="item.fileUrl+'?tiket='+ generateRandom10DigitNumber()" class="red--text" v-if="item.fileUrl">Tải về pdf </a> 
                            </template>

                              <template v-slot:[`item.employeeName`]="{item}">
                                  <b class="">{{item.employeeName}}</b>
                              </template>
                              <template v-slot:[`item.gender`]="{item}">
                                  <b :class="(item.gender ? 'blue': 'pink') + '--text'">{{item.gender ? 'Nam': 'Nữ'}}</b>
                              </template>
                              
                              <template v-slot:[`item.idDate`]="{item}">
                                  {{ formatDate(item.idDate) }}
                              </template>
                              <template v-slot:[`item.birthDay`]="{item}">
                                  {{ formatDate(item.birthDay) }}
                              </template>
                              <template v-slot:[`item.validFrom`]="{item}">
                                  {{ formatDate(item.validFrom) }}
                              </template>
                              <template v-slot:[`item.validTo`]="{item}">
                                  {{ formatDate(item.validTo) }}
                              </template>

                              <template v-slot:[`item.deletePhieu`]="{item}" >
                                  <v-btn outlined small color="error" @click="deletePhieu(item.ID)"><v-icon small left>mdi-delete</v-icon> Xóa</v-btn>
                              </template>

                          </v-data-table>
                      </v-card>
                  </v-col>
                  
              </v-row>
          </v-col>

      </v-row>
      <!-- ************** BOTTOM SHEET  *************** -->
      <v-bottom-sheet v-model="bottomSheet" persistent max-width="600px" hide-overlay>
          <v-card
              v-if="item1SelectObj"
              class="" 
              height="300px"
          >
              <v-card-title v-if="bottomSheetType!='RETURN'" :class="`font-weight-bold ${bottomSheetType == 'ACCEPT'? 'green' : ( bottomSheetType == 'DENY'? 'red': '')}--text text--darken-2`"><v-spacer></v-spacer>{{bottomSheetType == 'ACCEPT'? 'Xác nhận' : ( bottomSheetType == 'DENY'? 'Từ chối': '')}} phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
              <v-card-title v-if="bottomSheetType=='RETURN'" class="font-weight-bold primary--text"> <v-spacer></v-spacer> Trả về phiếu phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
              <v-card-text v-if="bottomSheetType!='RETURN'">
                  <v-row>
                      <v-col cols="12" class="">
                          <label for="bottom_note" class="font-weight-bold text-left">Ghi chú</label>
                          <v-textarea id="bottom_note" rows="4" solo hide-details placeholder="Gõ enter để LƯU" v-model="note">

                          </v-textarea>
                      </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="mt-3" outlined color="success" @click="setStatusNote" >
                  Lưu
                  </v-btn>

                  <v-btn class="mt-3" color="error" text @click="closeBottom" >
                  Đóng
                  </v-btn>
                  <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-bottom-sheet>
      <!-- ************** DELETE CONFIRMATION *************** -->
      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này? ID: {{!!targetItem? targetItem.id: ''}}</v-card-title>
              <v-card-actions>
                  <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                      <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                  <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="loadingDialog" width="300px" persistent>
          <v-card>
              <v-card-title class="text-center">
                  <v-spacer></v-spacer>
                  Đang xuất file
                  <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="text-center">
                  <v-progress-circular
                  size="70"
                  indeterminate
                  color="orange"
                  ></v-progress-circular>
              </v-card-text>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import CreateDialog from './Create.vue';
import ViewDialog from './View.vue';
import Contractv2Tab from './components/Contractv2Tab.vue'
import http from '@/components/services/http-common';
import FilterSection from './components/Filter.vue'
import moment from 'moment/moment';
import api from './services/api.js'

export default {
  components:{
    "create-dialog": CreateDialog,
    "view-dialog": ViewDialog,
    "contract-v2-tab": Contractv2Tab,
    "filter-section": FilterSection
  },
  computed: {
      isAsm() {
          var result = false
          if(this.GROUP_MA) {
              if(this.GROUP_MA.length>0){
                  result = this.GROUP_MA.includes('TS_ASM')
              }
          }
          return result
      },
      selectedTemplate() {
          let rslt = this.item1Select.length>0 ? ` ${this.item1Select[0].id }`: ""

          return rslt
      },
      chinhanhs(){
          if(!this.Sources) return [] 
          var result =this.Sources.split("|") 
          if(result.length > 0){
              this.dathangForm.chinhanh_dutru = result[0]
          }
          return result
      },
  },
  watch:{
      dialogCarousel(val) {
          if(!val)
              this.targetItem=null;
      },
      dialog(val) {
          console.log(val)
          if(!this.dialog)
              this.item=null;
      },
      filterMonth(val)
      {
          console.log(val)
          this.fetchData();
      },
      item1Select() {
          if(this.selectedTemplate != ""){
              this.getQuestions()
          } else{
              this.questions=[]
          }
      },
      filterFromDate() {
          this.getPhieu()
      },
      filterToDate() {
          this.getPhieu()
      },
      hanghoaFilterType() {
          this.getHangHoa()
      },
      hanghoaFilter() {
          this.getHangHoa()
      },
      
      'dathangForm.dvt': function(newVal, oldVal) {
          console.log(oldVal)
          if(this.hanghoaUnits.length >0){
              let selected = this.hanghoaUnits.filter(x=>x.Code == newVal)[0]
              let tonkho = selected['End Quantity'] ? selected['End Quantity'] : 0
              this.dathangForm.tonkho_hethong = tonkho
          }
      },
      hanghoaOutter() {
          this.dathangForm.mahanghoa=""
          this.dathangForm.soluong_dutru=""
          this.dathangForm.dvt=""
          this.dathangForm.tenhang=""
          // this.dathangForm.chinhanh_dutru=""
          this.dathangForm.tonkho_hethong=""
          // this.dathangForm.userid_tao=""

          this.dathangForm.base64_image=[]
          this.dathangForm.loai_hang= ''
          this.dathangForm.trungbinh_ban= ''
          this.dathangForm.loai_yeucau= ''
          this.dathangForm.ngay_giaokhach= null
      }
  },
  data () {
      return {
          TOKEN: null,
          USER_ID: null,
          GROUP_MA: null,
          Sources: null,
          month: moment().startOf("months").format("yyyy-MM"),
          statuses: [
              {text: "Tạo mới", value: 0},
              {text: "Đã xem", value: 1},
              {text: "Sẵn sàng", value: 3},
          ],
          statusesDict: {
              0: "Tạo mới",
              1: "Đã xem",
              3: "Sẵn sàng",
          },
          statusColors: {
              0: 'blue',
              1: 'green',
              3: 'light-green',
          },
          statusIcons: {
              0: 'mdi-new-box',
              1: 'mdi-checkbox-marked-circle',
              3: 'mdi-clipboard-check-outline'
          },
          dialogCarousel: false,
          dialogCarouselFs: false,
          dialogCarouselWidth: 600,
          bottomSheet: false,
          createPanel: true,
          bottomSheetType: "",
          note: "",

          item: null,

          item1SelectObj: null,
          item1Select: [],
          item2Select: [],
          items1:[],
          items2:[],
          search:"",

          filterMonth: moment().startOf('months').format('YYYY-MM-DD'),
          filterCode:"", 
          
          filterFromDate:moment().startOf('months').format('YYYY-MM-DD'),
          filterEmployeeCode: "",
            filterName: "",
            filterIdNumber: "",
            filterDepartment: "",
            filterPositionState: "",

          currentItem: "",

          loadingDialog: false,
          globalSearch:"",
          headers1: [
                { text: "", value: "actions" },
                { text: "Trạng thái", value: "status" },
                // { text: "NV đã duyệt", value: "employeeStatus" },
                // { text: "HR đã duyệt", value: "hrManagerStatus", width:200 },
                { text: "Tải lên HĐ duyệt", value: "uploadConfirm", width:200 },
                { text: "File Hợp đồng", value: "attachment", width:200 },
            //   { text: "Mã số", value: "id" },
                { text: "Số hợp đồng", value: "contractNumber" },
                { text: "Hiệu lực từ", value: "validFrom" },
                { text: "Hiệu lực đến", value: "validTo" },
                { text: "Mã loại hợp đồng", value: "contractCategoryCode" },
                { text: "Lương", value: "salary" },
                { text: "Thời hạn", value: "length" },
                { text: "Đơn vị thời hạn", value: "lengthType" },
                { text: "Mã nhân viên", value: "employeeCode", 
                    filter: value => {
                        if (!this.filterEmployeeCode) return true
            
                        return value.toUpperCase().includes(this.filterEmployeeCode.toUpperCase())
                    }
                },
                
                { text: "Tên nhân viên", value: "employeeName", width: 200 , 
                    filter: value => {
                        if (!this.filterName) return true
            
                        return value.toUpperCase().includes(this.filterName.toUpperCase())
                    }
                },
                { text: "Giới tính", value: "gender" },
                { text: "Số điện thoại", value: "phoneNumber", width: 200 },
                { text: "Email", value: "mail", width: 200 },
                { text: "Số chứng minh nhân dân", value: "idNumber" },
                { text: "Ngày cấp CMND", value: "idDate" , 
                    filter: value => {
                        if (!this.filterIdNumber) return true
            
                        return value.toUpperCase().includes(this.filterIdNumber.toUpperCase())
                    }
                },
                { text: "Nơi cấp CMND", value: "idPlace", width: 200 },
                { text: "Phòng ban", value: "departmentName", width: 200 , 
                    filter: value => {
                        if (!this.filterDepartment) return true
            
                        return value.toUpperCase().includes(this.filterDepartment.toUpperCase())
                    }
                },
                { text: "Tên vị trí", value: "positionName", width: 200 , 
                    filter: value => {
                        if (!this.filterPositionState) return true
            
                        return value.toUpperCase().includes(this.filterPositionState.toUpperCase())
                    }
                },
                { text: "Tên trạng thái vị trí", value: "positionStateName", width: 200 },
                { text: "Ngày sinh", value: "birthDay" },
          ],
          contractUpload: null,
          
          hanghoaDialog: false,
          hanghoaFilterType: "Name",
          hanghoaFilter: "",
          hanghoaUnits:[],
          hanghoaOutter:false,
          //dathang form
          dathangFormValid:false,
          dathangForm: {
              mahanghoa:"",
              soluong_dutru:"",
              dvt:"",
              tenhang:"",
              chinhanh_dutru:"",
              tonkho_hethong:"",
              userid_tao:"",
              base64_image:[],
              ghichu_dathang:"",
              loai_hang: '',
              trungbinh_ban: '',
              loai_yeucau: '',
              ngay_giaokhach: null,
          },
          image: null,
          imageData: {
              data_image: "",
              name:"",
              extension: ""
          },

          headers2: [
              {text:"", value: "actions", width:"5rem"},
              {text:"Mã", value: "No_", width:"7rem", 
                  filter: value => {
                      if (!this.filterCode) return true
          
                      return value.toUpperCase().includes(this.filterCode.toUpperCase())
                  },
              },
              {text:"Tên", value: "Name", width:"7rem", 
                  filter: value => {
                      if (!this.filterCode) return true
          
                      return value.toUpperCase().includes(this.filterCode.toUpperCase())
                  },
              },
              {text:"Mô tả", value: "Description 2", width:"7rem"}
          ],

          questionTypes: [
              {text: "Tự luận", value: "TEXT"},
              {text: "Lựa chọn", value: "OPTION"},
              {text: "Nhiều lựa chọn", value: "MULTI_OPTION", disabled: true},
          ],
          question:{
              type: "OPTION",
              name:"",
          },
          questions:[],

          answerHeader:[
              {text:"Câu trả lời", value:"name"},
              {text:"Xóa", value:"actions", width: 150}

          ],
          answers: [],
          answer:"",

          dialogDelete: false,
          targetItem: null,
          dialog: false,
          questionDialog:false,

          contractDialog: false,
      }
  },
  methods: {
    generateRandom10DigitNumber() {
        const min = 1000000000; // Smallest 10-digit number (10 zeros)
        const max = 9999999999; // Largest 10-digit number (nine nines)

        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    formatDate(value) {
        return moment(value).format("DD/MM/YYYY")
    },
    openDialog(event, id) {
        if(id){
            alert(event, id)
        }else {
            this.$refs.create_employee_report_dialog.dialog = !this.$refs.create_employee_report_dialog.dialog
        }
    },
    openDialogCarousel() {
        console.log(this.targetItem)
    },
      // handleFileChange(file) {
      //     //reset
      //     const filename = file.name;
      //     const extension = filename.split('.').pop();

      //     this.imageData.name="xxxx";
      //     this.imageData.extension = extension

      //     //read base64
      //     const reader = new FileReader();
          
      //     reader.onload = (e) => {
      //         this.imageData.data_image = e.target.result.split(',')[1];
      //         console.log(e.target.result)
      //     };
      //     reader.readAsDataURL(file);
      // },

      fetchData() {
          return this.apiService.getReady().then(d => {
              this.items1 = d
          })
      },
      setSelect(e)
      {
          this.item= {code : e};
      },
    verify(item, status, type = 'HR') {
        console.log(item, parseInt(status), type)
        return this.apiService.verify(item.id, parseInt(status), type).finally(() => {
            this.fetchData();
        })
    },
      
      edit(item){
          this.$refs.view_employee_report_dialog.dialog = true
          this.$refs.view_employee_report_dialog.item = item

      },
      publish(item) {
          this.apiService.publishTemplate(item).then(() => this.fetchData())
      },

      // deleteItem(item) {
      //     this.dialogDelete = true;
      //     this.targetItem = item;
      // },
      cfmDeleteItem() {
          return this.apiService.delete(this.targetItem.id).then(() => {
              this.fetchData()
          }).then(() => {
              this.dialogDelete = false
          })
          
      },

      //template
      openBottom(item, status, event) {
          this.item1SelectObj = item
          // if(status == 'Đã xác nhận' || status == 'Không đặt được')
          this.bottomSheet = true;
          if(status == "Đã xác nhận")
              this.bottomSheetType = "ACCEPT"
          if(status == "Không đặt được")
              this.bottomSheetType = "DENY"
          if(status == "Chờ xác nhận"){
              this.bottomSheetType = "RETURN"
          }
          console.log(item, event)
      },
      closeBottom() {
          this.bottomSheet = false
          this.bottomSheetType = ''
          this.note = ''
      },

      deleteItem() {
          return this.apiService.deleteItem(this.targetItem).then(()=> {
              this.targetItem=undefined
              this.dialogDelete = false
              this.fetchData()
          })
      },
      
      exportTemplate(item){
          try {
              this.loadingDialog = true
              return http.get("surveys/"+item.id+"/export", {
                  params: {
                      month: moment(this.month).format('YYYY-MM-DD')
                  },
                  responseType: 'blob',
              }).then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `EXPORT_KHAO_SAT${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                  document.body.appendChild(link);
                  link.click();
              }).finally(()=> {
                  this.loadingDialog=false
              });
          } catch(e){
              alert(e)
          }
      },

      ///QUESTION
      closeQuestion() {
          this.questionDialog = false
          this.answers = []
          this.answer = ""
          this.question.name = ""
          this.question.type = "OPTION"
      },
      getQuestions() {
          try{
              return this.apiService.getQuestions(this.selectedTemplate).then(d => {
                  this.questions = d.data
              })
          }catch(e)
          {
              alert(e)
          }
      },
      saveQuestion(){
          let mappedAnswer = this.answers.map(x=>x.name)
          let postData = {
              name: this.question.name,
              type: this.question.type,
              answers: mappedAnswer
          }
          return this.apiService.postQuestion(this.selectedTemplate, postData).then(() => {
              this.closeQuestion()
              return this.getQuestions()
          })
      },
      deleteQuestion(id) {
          return this.apiService.deleteQuestion(this.selectedTemplate, id).then(() => {
              return this.getQuestions()
          })
      },
      changeQuestionOrder(id, mode) {
          return this.apiService.changeQuestionOrder(this.selectedTemplate, id, mode).then(() => {
              return this.getQuestions()
          })
      },

      uploadConfirm(id) {
        return this.apiService.uploadConfirm(id, this.contractUpload).then(()=> {
            this.contractDialog = false
            this.fetchData()
        })
      },



  },

  apiService: null,
  created() {
      this.apiService = new api()
  },
  mounted () {
    this.fetchData()
  //   this.getToken().then(()=> {
  //     this.getHangHoa()
  //     this.getPhieu()
  //     })
    
  }
}
</script>