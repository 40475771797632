<template>
    <v-dialog v-model="dialog" max-width="600px">
        <v-card>
            <v-form v-model="isValid" ref="form" @submit.prevent="saveData" id="form">
                <v-toolbar color="secondary" flat dark>
                    <v-toolbar-title>{{isEdit ? "Thay đổi" :"Thêm"}} ghi nhận công</v-toolbar-title>
                    <v-spacer></v-spacer> <v-icon small @click="dialog=false"> mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <month-picker v-model="month" label="Tháng dự trù" :readonly="isEdit"></month-picker>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete item-text="name" item-value="code" :items="employees" v-model="form.employeeCode" :search-input="employeeSearch" clearable
                            label="Nhân viên" hide-no-data @update:search-input="fetchEmployee" :readonly="isEdit"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field  v-model="form.hour" label="Số giờ tăng ca"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field :readonly="!isEdit" v-model="form.using" label="Số giờ đã tăng ca"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="form.note" label="Ghi chú" outlined>
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="success" type="submit"> Lưu</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item"],
    watch:{
        month(val) {
            this.form.month = moment(val,"YYYY-MM").format("MM");
            this.form.year = moment(val,"YYYY-MM").format("YYYY");
        },
        employeeSearch:{
            handler(val){
                this.fetchEmployee(this.employeeSearch);
            }
        },
        // departmentSearch:{
        //     handler(val){
        //         this.fetchDepartment(val);
        //     }
        // },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "overtimeBookings/",
            EMPLOYEE_FETCH: "employees/search",

            isValid: true,
            departmentSearch: null,
            departmentTypes: [],
            positionStates: [],
            departments: [],
            shifts: [],
            positions: [],
            scales: [],
            employeeSearch: null,
            employees: [],

            month:moment().format("YYYY-MM"),
            form: {
                date: moment().format("YYYY-MM-DD"),
                employeeCode:null,
                month:  moment().format("MM"),
                year: moment().format("YYYY"),
                hour: 0,
                using: 0,
                note: "",
            },
            defaultForm:{
                date: moment().format("YYYY-MM-DD"),
                employeeCode:null,
                month:  moment().format("MM"),
                year: moment().format("YYYY"),
                hour: 0,
                using: 0,
                note: "",
            }
        }
    },
    methods: {
        fetchEmployee(val) {
            return http.get(this.EMPLOYEE_FETCH, {
                params: {
                    search: this.val
                }
            }).then(res => {
                this.employees = res.data;
            });
        },
        
        fetch(){
            console.log(this.item)
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                this.form = Object.assign({}, res.data);
                console.log(this.form)
                this.fetchEmployee(this.form.employeeCode);
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    return http.post(this.ROOT_URL, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
        }
    },
    created() {
    }
    
}
</script>