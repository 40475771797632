<template>
    <div class="create-insurance">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="600px">
             <v-card>
                <v-card-title>
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} đơn đi công tác
                </v-card-title>
                <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12"  md="6">
                                <v-autocomplete hide-details="auto" filled rounded v-model="form.businessTypeCode" :items="formData.businessTypes" item-text="name" item-value="code" label="Lý do công tác">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select filled rounded hide-details="auto" v-model="form.amountType" :items="[
                                    { text: 'Công tác nguyên ngày', value: 1.0 },
                                    { text: 'Công tác nữa ngày', value: 0.5 }
                                ]" label="Giờ công tác" type="number" :rules="[x=> !!x || 'Nhập dữ liệu']"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <date-picker  title="Ngày công tác" v-model="form.date" ></date-picker>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="form.note" solo flat outlined label="Diễn giải"></v-textarea>
                            </v-col>
                            <!-- <v-col cols="12" md="6">
                                <v-text-field step='0.5' v-model="form.amount" label="Giờ công tác" type="number" :rules="[x=>x%0.5==0||'Không hợp lệ']"></v-text-field>
                            </v-col> -->
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark color="primary" type="submit" small>
                            Lưu
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
             </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import http from '@/components/services/http-common';
import EventBus  from "@/components/services/EventBus";

export default {
    name: "Create",
    components: {
        "date-picker": DatePicker,
    },
    computed:{

    },
    watch: {
        value: {
            handler(val){
                console.log(val)
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            }
        },
    },

    props: ["value"],
    data () {
        return {
            //URL
            ROOT_URL: "businessRequests",
            BUSINESSTYPE_URL: "businesstypes/",

            //DÂTA
            valid: false,
            ShiftObject: "",
            formData: {
                businessTypes: [],
            },
            form: {
                businessTypeCode: "",
                date: new Date().toISOString().substr(0,10),
                note: "",
                amountOvertime: 0,
                symbol: "",
            },

            defaultForm: {
                businessTypeCode: "",
                date: new Date().toISOString().substr(0,10),
                note: "",
                amountOvertime: 0,
                symbol: "",
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },

        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },

        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        
        fetchData () {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                this.form = Object.assign({}, item);
                this.form.date = this.form.date.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10);
            })
            .catch(err => {
                console.log(err);
            });
        },
        
        fetchBusinessType () {
            return http.get(this.BUSINESSTYPE_URL)
            .then(res => {
                this.formData.businessTypes = res.data.items;
            }).catch(err => {throw err})

        },
        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Tạo mới thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                } else {
                    http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Cập nhật thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
                this.close();
            }
        },
    },
    mounted() {
        this.fetchBusinessType();
    }
}
</script>