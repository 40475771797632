<template>
    <div class="leave-index" >
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData" @passwordDialog="passwordDialog=true"></create-dialog>

        <v-card v-if="true">
            <v-card-title>
                <v-spacer></v-spacer>
                <!-- <v-btn small color="warning" dark @click="changePasswordDialog=true" class="mx-2">
                    <v-icon small left>mdi-key</v-icon> Thay đổi mk
                </v-btn>
                <v-btn small color="teal darken-4" dark @click="dialog.state=true">
                    <v-icon small left>mdi-upload</v-icon> Upload excel
                </v-btn> -->
                <!-- <create-button @click="dialog.state=true" ></create-button> -->
                <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker>
                <v-divider vertical class="mx-2"></v-divider>
                
                <!-- menu -->
                <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="dialog.state=true">
                            <v-list-item-icon>
                                <v-icon color="warning">mdi-upload</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Import excel 
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changePasswordDialog=true">
                            <v-list-item-icon>
                                <v-icon color="warning">mdi-key</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Thay đổi mật khẩu </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showData">
                            <v-list-item-icon>
                                <v-icon color="warning">mdi-cellphone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Hiển thị dữ liệu </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="hideData">
                            <v-list-item-icon>
                                <v-icon color="warning">mdi-cellphone-off</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Ẩn dữ liệu </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-data-table height="600px"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
            
                <template v-slot:[`top`]>
                    <v-row class="mx-1 mb-4">
                        <v-col cols="6" md="4">
                            <v-text-field v-model="filterEmployeeCode" outlined dense label="Tìm kiếu mã NS" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" md="4">
                            <v-text-field v-model="filterEmployeeName" outlined dense label="Tìm kiếu tên NS" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" md="4">
                            <v-text-field v-model="filterDepartmentName" outlined dense label="Tìm kiếu tên phòng/chi nhánh" hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:[`item.maNS`]="{ item }">
                    <div :class="item.isShow==1?'success--text font-weight-bold':''">{{item.maNS}}</div>
                </template>
                <template v-slot:[`item.ten`]="{ item }">
                    <div :class="item.isShow==1?'success--text font-weight-bold':''">{{item.ten}}</div>
                </template>
                <template v-slot:[`item.chiNhanh`]="{ item }">
                    <div :class="item.isShow==1?'success--text font-weight-bold':''">{{item.chiNhanh}}</div>
                </template>
                <template v-slot:[`item.boPhan`]="{ item }">
                    <div :class="item.isShow==1?'success--text font-weight-bold':''">{{item.boPhan}}</div>
                </template>
                <template v-slot:[`item.chucVu`]="{ item }">
                    <div :class="item.isShow==1?'success--text font-weight-bold':''">{{item.chucVu}}</div>
                </template>
                <!-- <template v-slot:[`item.stk`]="{ item }">
                    <div :class="item.isShow==1?'success--text font-weight-bold':''">{{item.stk}}</div>
                </template>
                <template v-slot:[`item.nganHang`]="{ item }">
                    <div :class="item.isShow==1?'success--text font-weight-bold':''">{{item.nganHang}}</div>
                </template> -->

                <template v-slot:[`item.gcCoBan`]="{ item }">
                    {{formatNumber(item.gcCoBan)}}
                </template>
                <template v-slot:[`item.gcThucTe`]="{ item }">
                    {{formatNumber(item.gcThucTe)}}
                </template>
                <template v-slot:[`item.gcLamThem`]="{ item }">
                    {{formatNumber(item.gcLamThem)}}
                </template>
                <template v-slot:[`item.gcTangCa`]="{ item }">
                    {{formatNumber(item.gcTangCa)}}
                </template>
                <template v-slot:[`item.gcLeTet`]="{ item }">
                    {{formatNumber(item.gcLeTet)}}
                </template>
                <template v-slot:[`item.ktTamUng`]="{ item }">
                    {{formatNumber(item.ktTamUng)}}
                </template>
                <template v-slot:[`item.ktBHXH`]="{ item }">
                    {{formatNumber(item.ktBHXH)}}
                </template>
                <template v-slot:[`item.ktTNCN`]="{ item }">
                    {{formatNumber(item.ktTNCN)}}
                </template>
                <template v-slot:[`item.ktTruyThu`]="{ item }">
                    {{formatNumber(item.ktTruyThu)}}
                </template>
                <template v-slot:[`item.lCoBan`]="{ item }">
                    {{formatNumber(item.lCoBan)}}
                </template>
                <template v-slot:[`item.lThucTe`]="{ item }">
                    {{formatNumber(item.lThucTe)}}
                </template>
                <template v-slot:[`item.lLamThem`]="{ item }">
                    {{formatNumber(item.lLamThem)}}
                </template>
                <template v-slot:[`item.lTangCa`]="{ item }">
                    {{formatNumber(item.lTangCa)}}
                </template>
                <template v-slot:[`item.lLeTet`]="{ item }">
                    {{formatNumber(item.lLeTet)}}
                </template>
                <template v-slot:[`item.pcKhuVuc`]="{ item }">
                    {{formatNumber(item.pcKhuVuc)}}
                </template>
                <template v-slot:[`item.pcDaoTao`]="{ item }">
                    {{formatNumber(item.pcDaoTao)}}
                </template>
                <template v-slot:[`item.pcCongTac`]="{ item }">
                    {{formatNumber(item.pcCongTac)}}
                </template>
                <template v-slot:[`item.thuongQuy`]="{ item }">
                    {{formatNumber(item.thuongQuy)}}
                </template>
                <template v-slot:[`item.thuongTienAn`]="{ item }">
                    {{formatNumber(item.thuongTienAn)}}
                </template>
                <template v-slot:[`item.thuongTruyLanh`]="{ item }">
                    {{formatNumber(item.thuongTruyLanh)}}
                </template>

                <template v-slot:[`item.thucNhan`]="{ item }">
                    {{formatNumber(item.thucNhan)}}
                </template>

                <template v-slot:[`item.tongLuong`]="{ item }">
                    {{formatNumber(item.tongLuong)}}
                </template>

                <template v-slot:[`item.tongKhauTru`]="{ item }">
                    {{formatNumber(item.tongKhauTru)}}
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="passwordDialog" width="300px" persistent>
            <v-card>
                <v-card-title>Mật khẩu CaB
                    <v-spacer></v-spacer>
                    <v-icon @click="passwordDialog=false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="privatePassword" hide-details="auto" outlined dense label="Mật khẩu" type="password" @keydown.enter.prevent="privateAuth(), privatePassword=''"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="privateAuth(), privatePassword=''">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- change pass dialog -->
        <v-dialog v-model="changePasswordDialog" width="350px" persistent>
            <v-card>
                <v-card-title>Thay đổi mật khẩu CaB
                    <v-spacer></v-spacer>
                    <v-icon @click="changePasswordDialog=false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="privatePasswordChange.password" hide-details="auto" outlined dense label="Mật khẩu" type="password"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="privatePasswordChange.passwordConfirmation" hide-details="auto" outlined dense label="Xác nhận mật khẩu" type="password"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="changePassword(), privatePasswordChange.password='', privatePasswordChange.passwordConfirmation=''">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import http from '@/components/services/http-common';
import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Leave',
    components: {
        CreateButton,
        "create-dialog": Create,
        "data-filter": DataFilter
    },
    watch: {
        tableParams: {
            handler (val) {
                this.fetchData();
                this.fetchCity();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month: {
            handler(val)
            {
                this.filters.from = moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to = moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },

    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
    },
    data() {
        return {
            ROOT_URL: "EmployeeSalaryImports/",

            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //filters
            month: moment().format("YYYY-MM"),
            filters:{
                code:null,
                employeeName:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            rowCount: 0,

            //TABLE
            filterEmployeeCode:"",
            filterEmployeeName:"",
            filterDepartmentName:"",
            headers: [
                {text: "Mã NS", value: "maNS", 
                    filter: value => {
                        if (!this.filterEmployeeCode) return true
            
                        return value.toUpperCase().includes(this.filterEmployeeCode.toUpperCase())
                    },
                },
                {text: "Tên NS", value: "ten", 
                    filter: value => {
                        if (!this.filterEmployeeName) return true
            
                        return value.toUpperCase().includes(this.filterEmployeeName.toUpperCase())
                    },
                },
                {text: "Chức vụ", value: "chucVu"},
                {text: "Chi nhánh/Phòng ban", value: "chiNhanh",
                    filter: value => {
                        if (!this.filterDepartmentName) return true
            
                        return value.toUpperCase().includes(this.filterDepartmentName.toUpperCase())
                    },
                },
                {text: "Bộ phận", value: "boPhan"},
                {text: "STK", value: "stk"},
                {text: "Ngân Hàng", value: "nganHang"},
                {text: "Giờ công cơ bản", value: "gcCoBan"},
                {text: "Giờ công thực tế", value: "gcThucTe"},
                {text: "Giờ công làm thêm", value: "gcLamThem"},
                {text: "Giờ công tăng ca", value: "gcTangCa"},
                {text: "Giờ công lễ tết", value: "gcLeTet"},
                {text: "Khấu trừ tạm ứng", value: "ktTamUng"},
                {text: "Khấu trừ BHXH", value: "ktBHXH"},
                {text: "Khấu trừ TNCN", value: "ktTNCN"},
                {text: "Khấu trừ truy thu", value: "ktTruyThu"},
                {text: "Lương cơ bản", value: "lCoBan"},
                {text: "Lương thực tế", value: "lThucTe"},
                {text: "Lương làm thêm", value: "lLamThem"},
                {text: "Lương tăng ca", value: "lTangCa"},
                {text: "Lương lễ tết", value: "lLeTet"},
                {text: "Phụ cấp khu vực", value: "pcKhuVuc"},
                {text: "Phụ cấp đào tạo", value: "pcDaoTao"},
                {text: "Phụ cấp công tác", value: "pcCongTac"},
                {text: "Phụ cấp chuyên môn", value: "pcChuyenMon"},
                {text: "Thưởng quý", value: "thuongQuy"},
                {text: "Phụ cấp cơm trưa", value: "thuongTienAn"},
                {text: "Thưởng truy lãnh", value: "thuongTruyLanh"},
                {text: "Truy thu thuế 2023", value: "thue2023"},
                {text: "Hoàn thuế 2023", value: "hThue2023"},
                {text: "Tổng lương", value: "tongLuong"},
                {text: "Tổng khấu trừ", value: "tongKhauTru"},
                {text: "Thực nhận", value: "thucNhan"},
                {text: "Bằng chữ", value: "bangChu", width:"350px"},
            ],

            items: [],
            options: {},
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

            //private auth
            privatePassword: "",
            passwordDialog: false,
            changePasswordDialog:false,

            privatePasswordChange:{
                password:"",
                passwordConfirmation:""
            }
        }
    },

    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatNumber(value){
            let rValue = this.roundUp(value)
            return rValue.toLocaleString('en-US', { style: 'decimal' });
        },
        roundUp(value){
            return Math.ceil(value * 100) / 100;
        },
        
        status(value) {
            let result = {};
            switch(value) {
                case 0: {
                    result.text = "Chờ duyệt";
                    result.color = "warning"
                    break;
                }
                case 1: {
                    result.text = "Đã duyệt"
                    result.color = "success"
                    break;
                }
                case 2: {
                    result.text = "Từ chối"
                    result.color = "error"
                    break;
                }
                default:{
                    result.text = "unknown"
                    break;
                }
            }
            return result;
        },

        //DATA JS
        fetchData () {
            var JwtString = localStorage.getItem('CaBToken');

            // Check if the data exists
            if (JwtString) {
                return http.get(this.ROOT_URL + "admin/show", {
                    params: {
                        month: this.month,
                        JwtString: JwtString
                    }
                })
                .then(res => {
                    this.items = res.data.data
                    this.isLoading = false;
                }).catch(e => {
                    localStorage.removeItem('CaBToken')
                    this.isLoading = false;
                    this.passwordDialog = true
                })
            } else {
                this.passwordDialog = true
            }
        },

        showData() {
            var JwtString = localStorage.getItem('CaBToken');
            try {
                return http.get(this.ROOT_URL + "publish", {
                    params: {
                        month: this.month,
                        JwtString: JwtString
                    }
                }).then(()=> this.fetchData())
            } catch(e) {
                alert(e)
            }
        },
        hideData() {
            var JwtString = localStorage.getItem('CaBToken');
            try {
                return http.get(this.ROOT_URL + "unpublish", {
                    params: {
                        month: this.month,
                        JwtString: JwtString
                    }
                }).then(()=> this.fetchData())
            } catch(e) {
                alert(e)
            }
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL + "undo/" +this.targetItem.id).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        // cab auth
        privateAuth() {
            return http.post("CaBAuthentication/login", {
                password: this.privatePassword
            }).then(res => res.data).then(data => {
                this.passwordDialog = false
                localStorage.setItem('CaBToken', data.data)
                this.fetchData()
            }).catch(e => {
                console.log(e)
            })
        },
        changePassword(){
            var JwtString = localStorage.getItem('CaBToken');
            return http.post("CaBAuthentication/change-password", this.privatePasswordChange, {
                params: {
                    JwtString: JwtString
                }
            }).then(res => res.data).then(data => {
                this.changePasswordDialog = false
                localStorage.removeItem('CaBToken')
                this.fetchData()
                // this.items = []
            }).catch(e => {
                console.log(e)
            })
        }

    },
    
    created() {
        this.fetchData()
    }
}
</script>