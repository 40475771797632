<template>
    <div class="index">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)"></myBreadCrumb> -->
        <v-card>
            <v-card-title> <span class="d-none d-md-block">Danh sách yêu cầu</span>
                <v-spacer></v-spacer>
                <refresh-button @click="fetchData"></refresh-button>
                <excel-button @click="exportPromt=true"></excel-button>
                <export-prompt v-model="exportPromt" url="longLeaveRequests" timeLimit></export-prompt>
                <v-divider vertical class="mx-2"></v-divider>
                <month-picker v-model="month" dense outlined width="7rem"></month-picker>
                <filter-menu>
                    <v-row class="my-2">
                        <v-col cols="6">
                            <v-select dense outlined hide-details v-model="filters.status" :items="[{text:'Tất cả', value: -1},
                            {text:'Chờ', value: 0}, {text:'Xác nhận', value: 1}, {text:'Từ chối', value: 2}]" placeholder="Trạng thái"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="filters.code" placeholder="Mã phiếu"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete dense outlined hide-details v-model="filters.LongLeaveCode" :items="longLeaves" 
                            item-text="name" item-value="code" placeholder="Loại nghỉ"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="filters.employeeCode" placeholder="Mã nhân viên"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="filters.employeeName" placeholder="Nhân viên"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="filters.departmentName" placeholder="Phòng ban"></v-text-field>
                        </v-col>
                    </v-row>
                </filter-menu>
            </v-card-title>
            <v-data-table height="600px" fixed-header
            :headers="headers"
            :items="items"
            :page.sync="tableParams.page"
            :sort-by.sync="tableParams.sortBy"
            :sort-desc.sync="tableParams.sortDesc"
            :server-items-length="rowCount"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu...">
            
                <template v-slot:[`body.prepend`]="" >
                    <tr class="filter d-none d-md-table-row">
                        <td></td>
                        <td>
                            <v-select dense outlined hide-details v-model="filters.status" :items="[{text:'Tất cả', value: -1},
                            {text:'Chờ', value: 0}, {text:'Xác nhận', value: 1}, {text:'Từ chối', value: 2}]" placeholder="Chọn"></v-select>
                        </td>
                        <td>
                            <v-text-field dense outlined hide-details v-model="filters.code" placeholder="Chọn"></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete dense outlined hide-details v-model="filters.LongLeaveCode" :items="longLeaves" item-text="name" item-value="code" placeholder="Chọn"></v-autocomplete>
                        </td>
                        <td>
                            <v-text-field dense outlined hide-details v-model="filters.employeeCode" placeholder="Chọn"></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense outlined hide-details v-model="filters.employeeName" placeholder="Chọn"></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense outlined hide-details v-model="filters.departmentName" placeholder="Chọn"></v-text-field>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn x-small color="success" @click="accept(item)" width="100px" class="mx-1" v-if="item.status == 0">
                        <v-icon small>mdi-check</v-icon>Chấp nhận
                    </v-btn>
                    <v-btn x-small color="error" @click="decline(item)" width="100px" class="mx-1" v-if="item.status == 0">
                        <v-icon small>mdi-close</v-icon>Từ chối
                    </v-btn>

                    <!-- ********* Undo ****** -->
                    <undo-button v-if="item.status != 0" @click="undo(item)"></undo-button>
                </template>

                <template v-slot:[`item.status`]="{ item }" >
                    <v-chip :color="status(item.status).color" label small outlined>
                        <strong>{{ status(item.status).text }}</strong>
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>

import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import * as moment from "moment/moment";
import DataFilter from "@/components/DataFilter"

export default {
    components: {
        myBreadCrumb,
        "data-filter": DataFilter,
    },
    props: ["breadcrumbs", "icon"],
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    watch: {
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    data () {
        return {
            ROOT_URL: "longleaveRequests",
            exportPromt: false,
            //tab
            limitList: [
                {text: "1 Tháng", value: 1},
                {text: "3 Tháng", value: 3},
                {text: "5 Tháng", value: 5},
                {text: "Tất cả", value: 0},
            ],
            limit: 1,
            isLoading: true,
            //TABLE DATA
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            month: moment().format("YYYY-MM"),
            longLeaves:[],
            filters:{
                status:-1,
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            rowCount: 0,
            items: [],
            headers: [
                {text:"", value: "actions", align:"center", width: "110px"},
                {text: "Trạng thái", value: "status", width: "100px"},
                {text: "Số phiếu", value: "code", width: "150px"},
                {text: "Loại", value: "longLeaveType", width: "150px"},
                {text: "Mã nhân viên", value: "employeeCode", width: "150px"},
                {text: "Tên nhân viên", value: "employeeName", width: "200px"},
                {text: "Phòng", value: "departmentName", width: "200px"},
                {text: "Ngày gởi", value: "requestDate", width: "200px"},
                {text: "Số ngày nghỉ", value: "leaveDays", width: "200px"},
                {text: "Từ", value: "from", width: "200px"},
                {text: "Đến", value: "to", width: "200px"},
                {text: "Người xác nhận", value: "confirmBy", width: "200px"},
                {text: "Ngày xác nhận", value: "confirmAt", width: "200px"},
            ],
            form: {
                search:"",
                searchArr: [],
            },
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        preventHeaderHandler(e)
        {
            e.stopPropagation();
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        status(value) {
            let result = {};
            switch(value) {
                case 0: {
                    result.text = "Chờ duyệt";
                    result.color = "warning"
                    break;
                }
                case 1: {
                    result.text = "Đã duyệt"
                    result.color = "success"
                    break;
                }
                case 2: {
                    result.text = "Từ chối"
                    result.color = "error"
                    break;
                }
                default:{
                    result.text = "unknown"
                    break;
                }
            }
            return result;
        },
        fetchLongLeave () {
            return http.get("longleaves").then(res=>{
                this.longLeaves = res.data.items;
            })
        },
        fetchData() {
            return http.get(this.ROOT_URL, {
                            params: this.params
                        }).then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
                console.log(res.data, "m8")
            }).catch(err => {
            });
        },
        accept(item) {
            this.isLoading = true;
            return http.get(this.ROOT_URL + "/accept/" + item.code).then(res => {
                this.fetchData();
                this.isLoading=false;
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            return http.get(this.ROOT_URL+ "/deny/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            return http.get(this.ROOT_URL + "/undo/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        }
    },
    mounted () {
        this.fetchData();
        this.fetchLongLeave();
    }
}
</script>

<style scoped>
.v-text-field{
      max-width: 250px;
}
</style>