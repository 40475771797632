<template>
  <v-dialog v-model="dialog" max-width="800" scrollable eager persistent>
    <v-card class="rounded-xl" :loading="loading">
      <v-card-title class="title">
        Chi tiết
      </v-card-title>
      <!-- <v-card-subtitle class="red--text">
          Nhân viên kiểm tra, xác nhận và bổ sung các thông tin cần thiết.
      </v-card-subtitle> -->
      <v-card-text class="text--primary">
        <v-form v-model="isValid" @submit.prevent="submitForm" ref="temporary_store_form" id="temporary_store_form">
          <v-row class="mt-0">
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.code" readonly label="Mã" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete outlined hide-details="auto" v-model="form.employeeCode" 
              :items="employees" item-value="code" item-text="name" label="MSNS" :filter="(item,queryText) => (item.code.includes(queryText) || item.name.includes(queryText))"
              dense persistent-placeholder>
                <template v-slot:item="data">
                  {{ data.item.code }}: {{ data.item.name }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.insuranceNumber" label="Số BHXH" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.healthInsuranceNumber" label="Số BHYT" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" type="date" v-model="form.insuranceDate" label="Ngày cấp BHXH" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" type="date" v-model="form.healthInsuranceDate" label="Ngày cấp BHYT" dense persistent-placeholder></v-text-field>
            </v-col>
            <v-col cols="12" >
              <v-text-field outlined hide-details="auto" v-model="form.description" label="Mô tả" dense persistent-placeholder></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="dialog=false, itemCode = null">Thoát</v-btn>
          <v-btn color="primary" type="submit" dark form="temporary_store_form">
              Lưu
          </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiServices from '@/components/services/ApiServices.js'
import InsuranceApi from './api.js'
// import TemporaryStoreApi from './api.js'
export default {
  mounted() {
    this.fetchEmployee()

    //********Global Events Listener */
    this.$root.$on('openDialog', () => {
      this.dialog = true
    });
    
    this.$root.$on('editDialog', (data) => {
      this.dialog = true
      this.itemCode = data.code
      this.fetchData()
    });
    

  },
  destroyed() {
    
    // this.dialog = false
    //********OFF Global Events Listener */
    this.$root.$off('openDialog');
    
    this.$root.$off('editDialog');
  },
  computed: {
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội", "Hàn Quốc"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      itemCode: null,
      initForm: false,
      
      isValid: true,
      form: {
        code: '',
        insuranceNumber: '',
        healthInsuranceNumber: '',
        employeeCode: '',
        employeeName: '',
        description: '',
        healthInsuranceDate: '',
        insuranceDatensuranceDate: '',
        createdAt: '',
      },
      
      defaultForm: {
        code: '',
        insuranceNumber: '',
        healthInsuranceNumber: '',
        employeeCode: '',
        employeeName: '',
        description: '',
        healthInsuranceDate: '',
        insuranceDatensuranceDate: '',
        createdAt: '',

      },
      departments: [],
      employees: [],

      family:{
        relationType: null,
        memberName: '',
        phoneNumber: '',
        doB: '',
        idNumber: '',
      },
      
      familyDefault:{
        relationType: null,
        memberName: '',
        phoneNumber: '',
        doB: '',
        idNumber: '',
      },
      headers: [
        {text: '', value: 'actions'},
        {text: 'Mối quan hệ', value: 'relationType'},
        {text: 'Họ & tên', value: 'memberName'},
        {text: 'SDT', value: 'phoneNumber'},
        {text: 'Ngày sinh', value: 'doB'},
        {text: 'CCCD', value: 'idNumber'}
      ],
      familyMemberHeaders: [
        {text: '', value: 'actions'},
        {text: 'Mối quan hệ', value: 'relationType'},
        {text: 'Tên', value: 'memberName'},
        {text: 'CCCD', value: 'idNumber'},
        {text: 'SDT', value: 'phoneNumber'},
      ],
      tab: null
    }
  },
  watch: {
    
    "dialog": {
      handler(val) {
        if(val) {
          console.log(val)
          this.fetchDepartment()
        } else {
          //close dialog
          this.close()
        }
      }
    },
  },
  methods: {
    close() {
      Object.assign(this.form, this.defaultForm)
    },

    fetchEmployee() {
      return ApiServices.fetchGenericEmployee().then(r => {
        this.employees = r.data.data
      })
    },
    submitForm(){
      // this.$refs.temporary_store_form.validate()
      // if(this.isValid) {
      //   this.loading = true
      //   if(this.itemCode) {
      //     return TemporaryStoreApi.put(this.itemCode, this.form).finally(() => {
      //       this.loading = false
      //     })
      //   }else {
      //     return TemporaryStoreApi.post(this.form).finally(() => {
      //       this.loading = false
      //     })
      //   }
      // }
    },

    fetchData() {
      this.initForm = true
      return InsuranceApi.get(this.itemCode).then(d => {
        d.insuranceDate = this.$formatter.formatDate(d.data.insuranceDate, 'YYYY-MM-DD')
        Object.assign(this.form, d.data)
        console.log(this.form.employeeCode)
        // this.form.validFrom = this.$formatter.formatDate(this.form.validFrom, 'YYYY-MM-DD')
        // this.form.validTo = this.$formatter.formatDate(this.form.validTo, 'YYYY-MM-DD')
      }).finally(() => {
        
      })
    },

    
    fetchDepartment() {
      return ApiServices.getDepartment('K00002').then(r => {
          this.departments = r.data
      })
    },
  },
}
</script>