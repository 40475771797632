<template>
    <div class="position-state">
        <create-dialog ref="createDialog" @CloseDialog="closeHandler" @fetchData="fetchData" v-model="dialog"></create-dialog>
        <v-card>
            <v-card-title>
                Chức danh
            <v-spacer></v-spacer>
            <v-text-field v-model="search" @keydown.enter="fetchData" style="max-width:300px" class="mr-5"
                append-icon="mdi-magnify" label="Tìm kiếm" single-line hide-details outlined dense
            ></v-text-field>
            <create-button @click="openDialog"></create-button>
            </v-card-title>
            <v-data-table height="600px"
            :headers="headers"
            :items="items"
            loading-text="Đang tải dữ liệu..."
            :search="search"
            >
                <template v-slot:[`item.isActive`]="{ item }">
                    <v-icon v-if="item.isActive">
                        mdi-check
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <edit-button @click="editItem(item)"></edit-button>
                    <delete-button @click="deleteItem(item)"></delete-button>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import http from '@/components/services/http-common'
import Create from './Create'

export default {
    name: 'PositionState',
    components: {
        'create-dialog': Create
    },
    data () {
        return {
            // URLS
            ROOT_URL: "positionStates",

            //Breakcrumbs
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //dialog
            dialog: {state: false, item: undefined},
            
            //datatable config
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', sortable: false, width:'12rem' },
                {
                    text: 'Mã công việc',
                    align: 'start',
                    sortable: false,
                    value: 'code', width:'12rem'
                },
                {
                    text: 'Tên công việc',
                    align: 'start',
                    value: 'name', width:'12rem'
                },
                { text: 'Ghi chú', value: 'note', sortable: false },
            ],
        }
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    methods:{
        openDialog(){
            this.dialog.state = true;
        },
        fetchData () {
            return http.get(this.ROOT_URL).then(res => {
                console.log("fetchdata");
                this.items = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        editItem(item) {
            this.dialog.state = true;
            this.dialog.item = item;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
        closeHandler() {
            this.dialog = {state: false, item: undefined}
        }
    },
    mounted () {
        this.fetchData();
    }
}
</script>

<style lang="stylus" scoped>

</style>