<template>
  <div>
    <my-breadcrumb :data ="breadcrumb"></my-breadcrumb>
    <v-card>
      <v-card-title>
        <!-- Dữ liệu lương cơ bản của nhân viên  -->
        <v-text-field style="max-width: 200px;" class="mx-2" outlined hide-details v-model="month" type="date" dense placeholder="Chọn tháng" persistent-placeholder></v-text-field>

        <v-spacer></v-spacer>
        <v-btn icon color="teal" @click="getData" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
        <export-excel :items="items" :headers="headers"> </export-excel>
        <!-- <v-btn class="ml-2">Tạo bảng lương</v-btn > -->
      </v-card-title>

      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" xl="2" md="3">
            <v-text-field dense v-model="search" outlined placeholder="Tìm kiếm" hide-details persistent-placeholder></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :loading="isLoading" :items="items" :headers="headers" :search="search" :items-per-page="100" fixed-header height="600px">
          
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import SalaryApi from './api.js'
import moment from 'moment';
import ExportExcel from '@/components/ExportExcel'
import MyBreadcrumb from '@/components/MyBreadcrumb'

export default {
  data() {
    return {
      breadcrumb: [
        {text: "Dữ liệu lương cơ bản của nhân viên", to: {name: 'Salary'}}
      ],

      items: [],
      search: '',
      isLoading: false,
      headers: [
        // { text: 'Mã', value: 'code' },
        { text: 'Mã NS', value: 'employeeCode' },
        { text: 'Tên NS', value: 'employeeName' },
        // { text: 'Tháng', value: 'month' },
        { text: 'Lương', value: 'basicSalary' },
        { text: 'Tiền đóng BHXH', value: 'insurancePaid' },
        { text: 'Kích hoạt', value: 'isActive' },
        { text: 'Tạo vào', value: 'createdAt' },
        { text: 'Cập nhật vào', value: 'updatedAt' },
        // { text: 'Lương tháng trước', value: 'isActive' },
        
      ],

      month: moment().startOf('month').format('YYYY-MM-DD')
    }
  },
  methods: {
    formatDate(value) {
        return moment(value).format("DD/MM/YYYY");
    },

    getData() {
      this.isLoading = true
      return SalaryApi.gets(this.month).then(d=> {
        console.log(d)
        this.items = d.data
        this.isLoading = false
      })
    },
    
  },
  components: {
    ExportExcel,
    MyBreadcrumb
  },
  created()  {
    this.getData()
  }
}
</script>