import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'EmployeeSummaries';
// global api
class EmployeeSummaryApi {
  getGraphData(){
    return http.get(`${ROUTE}/graph`).then(r=>r.data)
  }
  getStatisticData(){
    return http.get(`${ROUTE}/statistic`).then(r=>r.data)
  }
  getTableData(){
    return http.get(`${ROUTE}/table`).then(r=>r.data)
  }
}

export default new EmployeeSummaryApi();