<template>
    <div class="role-user">
        <v-dialog v-model="value" @click:outside="close" @keydown.esc="close" max-width="90vw">
            <v-card>
                <v-card-title>
                    Phân quyền nhóm {{item ? item.roleName: ""}}
                </v-card-title>
                <v-expansion-panels tile flat>
                    <v-expansion-panel>
                    <v-expansion-panel-header>
                    Bộ lọc
                    </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row justify="end">
                                <v-col cols="4">
                                    <v-autocomplete dense label="Phòng ban/Chi nhánh" multiple small-chips deletable-chips :items="formData.departmentTypes" v-model="filterVals[0]">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-autocomplete dense label="Phòng ban/Chi nhánh" multiple small-chips deletable-chips :items="formData.departments" v-model="filterVals[1]">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="4" >
                                    <v-autocomplete dense label="Vị trí" multiple small-chips deletable-chips :items="formData.positions" v-model="filterVals[2]">
                                    </v-autocomplete>
                                </v-col>
                                <v-card-actions class="py-0">
                                    <v-btn small color="teal" dark @click="fetchUser(); fetchUserRole();"> Lọc
                                    <v-icon right dark small>
                                        mdi-filter
                                    </v-icon></v-btn>
                                </v-card-actions>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card-title>
                                Tài khoản trong nhóm
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search1"
                                append-icon="mdi-magnify"
                                label="Tìm kiếm"
                                single-line
                                hide-details
                                @keydown.enter="fetchUserRole"
                            ></v-text-field>
                            </v-card-title>
                            <v-data-table
                            :mobile-breakpoint="0"
                            :headers="headers1"
                            :options.sync="options1"
                            :items="roleUsers"
                            @keydown.enter="fetchData"
                            :server-items-length="totalItem1"
                            >
                                <template v-slot:[`item.isActive`]="{ item }">
                                    <v-icon v-if="item.isActive">mdi-check</v-icon>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="UnassignHandler(item)"
                                    >
                                        mdi-account-arrow-right
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-title>
                                D.s tài khoản
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search2"
                                append-icon="mdi-magnify"
                                label="Tìm kiếm"
                                single-line
                                hide-details
                                @keydown.enter="fetchUser"
                            ></v-text-field>
                            </v-card-title>
                            <v-data-table
                            :mobile-breakpoint="0"
                            :headers="headers2"
                            :options.sync="options2"
                            :items="users"
                            @keydown.enter="fetchData"
                            :server-items-length="totalItem2"
                            >
                                <template v-slot:[`item.isActive`]="{ item }">
                                    <v-icon v-if="item.isActive">mdi-check</v-icon>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        @click="AssignHandler(item)"
                                    >
                                        mdi-account-arrow-left
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    >
                        Thoát
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        type="submit"
                    >
                        Lưu
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import http from '@/components/services/http-common'

export default {
    name: "RoleUser",
    components: {

    },
    watch: {
        value (val){
            if(val)
            {
                this.fetchUser();
                this.fetchUserRole();
            }
            return val;
        }
    },
    computed: {
    },
    props: ["value", "item"],
    data () {
        return {
            headers1: [
                { text: 'Thao tác', value: 'actions', sortable: false, width: "100px", align:"center"},
                { text: 'Mã tài khoản', value: 'code', width: "150px" },
                {
                    text: 'Tên tài khoản',
                    align: 'start',
                    sortable: false,
                    value: 'username', width: "150px"
                },
                { text: 'Tên nhân viên', value: 'employeeName', width: "150px"},
                { text: 'Kích hoạt', value: 'isActive', width: "150px"},
                ],
            headers2: [
                { text: 'Thao tác', value: 'actions', sortable: false, width: "100px" },
                { text: 'Mã tài khoản', value: 'code', width: "150px" },
                {
                    text: 'Tên tài khoản',
                    align: 'start',
                    sortable: false,
                    value: 'username', width: "150px"
                },
                { text: 'Tên nhân viên', value: 'employeeName', width: "150px" },
                { text: 'Kích hoạt', value: 'isActive', width: "150px" },
                ],
            roleUsers: [],
            users: [],
            options1: {page: 1, itemsPerPage:10},
            options2: {page: 1, itemsPerPage:10},
            totalItem1: 0,
            totalItem2: 0,
            search1: "",
            search2: "",

            filterAttrs: ["DepartmentType", "Department", "Position"],
            filterVals: [[], [], []],
            formData: {
                departmentTypes: [],
                departments: [],
                positions: [],
            }

        }
    },
    methods: {
        close () {
            this.$emit("closeDialog");
        },

        UnassignHandler(item) {
            http.get('roles/unassign/' +this.item.code+ '?userCode='+ item.code).then(res => {
                this.fetchUserRole();
                this.fetchUser();
            }).catch(err => {
                console.log(err);
            })
        },
        
        AssignHandler(item) {
            http.get('roles/assign/' +this.item.code+ '?userCode='+ item.code).then(res => {
                this.fetchUserRole();
                this.fetchUser();
            }).catch(err => {
                console.log(err);
            })
        },

        fetchUserRole() {
            const { page, itemsPerPage } = this.options1;
            if(page != undefined || itemsPerPage != undefined)
            {
                http.get('roles/fetch-users/' +this.item.code+ '?search='+this.search1 + '&inRole=1' + '&page='+ page + '&pageSize=' + itemsPerPage, {
                    params: {
                        searchs: this.filterVals,
                        searchAttrs: this.filterAttrs
                    }
                }).then(res => {
                    this.roleUsers = res.data.items;
                    this.totalItem1 = res.data.totalItem1;
                }).catch(err => {
                    console.log(err);
                })
            }
        },
        fetchUser() {
            const { page, itemsPerPage } = this.options2;
            if(page != undefined || itemsPerPage != undefined)
            {
                http.get('roles/fetch-users/' +this.item.code+ '?search='+this.search2 + '&inRole=0' + '&page='+ page + '&pageSize=' + itemsPerPage, {
                        params: {
                        searchs: this.filterVals,
                        searchAttrs: this.filterAttrs
                    }
                }).then(res => {
                    this.users = res.data.items;
                    this.totalItem2 = res.data.totalItem2;
                }).catch(err => {
                    console.log(err);
                })
            }
        },
        
        fetchDepartmentType() {
            http.get("departmenttypes").then(res => {
                this.formData.departmentTypes = res.data.items.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        },
        fetchDepartment () {
            http.get("departments").then(res => {
                this.formData.departments = res.data.items.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        },
        fetchPosition () {
            http.get("positions").then(res => {
                this.formData.positions = res.data.items.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        }
    },
    created() {
        this.fetchDepartmentType();
        this.fetchDepartment();
        this.fetchPosition();
    }
}
</script>