<template>
    <div class="feedback-index">
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <refresh-button @click="fetchData"></refresh-button>
                <create-button @click="dialog=true" class="mx-1"></create-button>
                <month-picker v-model="month" outlined dense :range="false" width="9rem"></month-picker>
            </v-card-title>

            <v-data-table
                :mobile-breakpoint="0"
                height="600px" fixed-header
                :headers="headers"
                :items="items"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..." >
                <template v-slot:[`body.prepend`]="" >
                    <tr class="filter">
                        <td colspan="100%"></td>
                    </tr>
                </template>
                <template v-slot:[`item.actions`]="{item}" >
                    <edit-button @click="edit(item)"></edit-button>
                    <!-- <v-btn x-small @click="remove(item)" class="mx-1" color="error">
                        <v-icon small>mdi-trash-can</v-icon> Xóa
                    </v-btn> -->
                </template>
                <template v-slot:[`item.isCurrent`]="{item}" >
                    <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                </template>
                
                <template v-slot:[`item.department`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                        <v-icon left>
                            mdi-store
                        </v-icon>
                        {{item.department}}
                    </v-chip>
                </template>
                
                <template
                    v-slot:[`item.time`]="{ item }"
                >
                    {{ formatTime(item.time) }}
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter.vue';
import ImportUpload from '@/components/ImportUpload';
import ExcelButton from '@/components/Crud/ExcelButton.vue';
import MonthPicker from '../../../components/MonthPicker.vue';

export default {
    name: 'Contract',
    components: {
        'data-filter': DataFilter,
        'import-upload': ImportUpload,
        ExcelButton,
    },
    watch: {
        // options: {
        //     handler (val) {
        //         this.fetchData();
        //     },
        //     deep: true,
        // },
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        dateRange:{
            handler(val){
                if(val.length=2){
                    this.filters.from = val[0];
                    this.filters.to = val[1];
                }
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },

    data() {
        return {
            FEEDBACK_URL: "feedbacks",
            //HEADER DATA
            month: moment().format("YYYY-MM"),
            
            //filter
            departmentSearch:null,
            departments: [],
            rowCount: 0,
            isLoading:true,

            headers: [
                {text: "Thao tác", value: "actions", width:"5rem"},
                {text: "Mã", value: "code", width:"3rem"},
                {text: "Mã nhân viên", value: "employeeCode", width: "5rem"},
                {text: "Tên nhân viên", value: "employeeName", width: "7rem"},
                {text: "Phòng ban", value: "departmentName", width: "7rem"},
                {text: "Nội dung", value: "body", width: "9rem"},
                {text: "Ngày gởi", value: "createdAt", width: "9rem"},
            ],
            items: [],
            
            //DIALOG
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: {},

            exportPrompt: false,

        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        //DATA JS
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData (e) {
            return http.get(this.FEEDBACK_URL, {
                params:{
                    month: this.month
                }
            })
            .then(res => {
                this.isLoading = false;
                this.items=res.data;
            }).catch(err => {})
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {

            }
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem (item) {
            this.targetItem = item;
            this.dialogDelete = true;
        },
        cfmDeleteItem() {
            http.delete(this.CONTRACT_URL + "/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        // exportExcel() {
        //     let params =  { ...this.filterState, 
        //                         sortBy: this.options.sortBy, //array
        //                         sortDesc: this.options.sortDesc, //array
        //                         page: this.options.page,
        //                         };
        //     return http.get(this.EXPORT_URL, {
        //         params: params,
        //         responseType: 'arraybuffer',
        //     }).then(res => {
        //         const url = window.URL.createObjectURL(new Blob([res.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'danh_sach_ho_so.xlsx');
        //         document.body.appendChild(link);
        //         link.click();
        //     })
        // }

    },
    created() {
        this.fetchDepartment();
        this.fetchData();
    }
}
</script>