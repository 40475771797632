<template>
    <div class="assignment">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog=true"></myBreadCrumb> -->
        <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog>
        <v-card>
            <v-card-title>
            <v-spacer></v-spacer>
            <v-divider vertical class="mx-2"></v-divider>
            <create-button @click="dialog=true"></create-button>
            <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter> -->
            </v-card-title>
            <v-card-text>
                <v-text-field label="Tìm kiếm" v-model="search"  style="max-width:300px"></v-text-field>
                <v-data-table
                :mobile-breakpoint="0"
                height="600px" fixed-header
                :headers="headers"
                :items="items"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..."
                :search="search"
                >
                    
                <template v-slot:[`item.avatar`]="{item}" >
                        <div v-if="!item.avatar">
                            <v-icon>mdi-image</v-icon>
                        </div>
                        <div v-else>
                            <v-img width="96px" v-if="!item.avatar.includes('.pdf')" :src="item.avatar"></v-img>
                        </div>
                    </template>
                    <template v-slot:[`item.isFeature`]="{item}" >
                        <v-icon v-if="item.isFeature" color="success">mdi-check</v-icon>
                    </template>
                    <template v-slot:[`item.isVisible`]="{item}" >
                        <v-icon v-if="item.isVisible" color="success">mdi-check</v-icon>
                    </template>
                    <template v-slot:[`item.actions`]="{item}" >
                        <edit-button @click="edit(item)"></edit-button>
                        <delete-button @click="remove(item)"></delete-button>
                    </template>
                    <template v-slot:[`item.isCurrent`]="{item}" >
                        <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                    </template>
                    <template
                        v-slot:[`item.time`]="{ item }"
                    >
                        {{ formatTime(item.time) }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter';
import CreateDialog from './Create.vue';
import * as moment from "moment/moment"

export default {
    name: "Attendance",
    components: {
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "contents/",

            search:"",
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', width: 200},
                { text: 'Mã', align: 'start', value: 'code', width: 200},
                { text: 'Ảnh đại diện', align: 'start', value: 'avatar', width: 200},
                { text: 'Danh mục', align: 'start', value: 'contentCategoryCodeNavigation.title', width: 200},
                { text: 'Tiêu đề', align: 'start', value: 'title', width: 200},
                { text: 'Hiển thị popup', align: 'start', value: 'isFeature', width: 200},
                { text: 'Hiển thị', align: 'start', value: 'isVisible', width: 200},
                { text: 'Thứ tự', align: 'start', value: 'order', width: 250},
            ],
        }
    },
    methods: {
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data;
                // this.rowCount = res.data.totalItem;
            })
            .catch(err => {

            })
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchData();
    },
    
}
</script>