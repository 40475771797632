<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      :items-per-page="5"
    >
    
      <template v-slot:[`header.actions`]="{  }" >
        <v-btn small @click="openCreateDialogHandler" color="primary">Thêm</v-btn>
      </template>
      
      <template v-slot:[`item.actions`]="{ item }" >
        <v-btn icon small color="secondary" @click="openUpdateDialogHandler(item)"> <v-icon small>mdi-pencil</v-icon></v-btn>
        <v-btn icon small color="error" @click="deleteItemHandler(item)"> <v-icon small>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>

    <!-- dialog  -->
    <v-dialog v-model="createDialog" max-width="600px">
      <v-card>
        <v-card-title>{{`${updateDialog?'Cập nhật':'Thêm'}`}} dữ liệu {{employeeCode}}</v-card-title>
        <v-row no-gutters v-show="updateDialog" v-model="formValid">
          <v-col cols="12" md="6">
            <v-card-subtitle>
              Người tạo: {{form.createdBy}}
            </v-card-subtitle>
          </v-col>
          <v-col cols="12" md="6">
            <v-card-subtitle>
              Người vào: {{form.createdAt}}
            </v-card-subtitle>
          </v-col>
          <v-col cols="12" md="6">
            <v-card-subtitle>
              Sửa bởi: {{form.updatedBy}}
            </v-card-subtitle>
          </v-col>
          <v-col cols="12" md="6">
            <v-card-subtitle>
              Sửa vào: {{form.updatedAt}}
            </v-card-subtitle>
          </v-col>
        </v-row>
        <v-card-text>
          <v-form id="etd_form" ref="etd_form" @submit.prevent="submitFormHandler" v-model="formValid">
            <v-row  class="mt-0">
              <v-col cols="12" md="6">
                <v-select 
                  hide-details="auto" outlined dense label="Phân loại nhân viên"
                  item-text="name" item-value="code" :rules="[x=>!!x || 'Thiếu dữ liệu này']"
                  v-model="form.employeeTypeCode" :items="dropdownData.employeeTypes"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select hide-details="auto" outlined dense label="Lý do phân loại"
                  item-text="name" item-value="code" :rules="[x=>!!x || 'Thiếu dữ liệu này']"
                  v-model="form.employeeTypeReasonCode" :items="dropdownData.employeeTypeReasons"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field hide-details="auto" outlined dense  label="Từ ngày" placeholder="dd/mm/yyyy" type="date"
                  v-model="form.fromDate" :rules="dateValidRules"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field hide-details="auto" outlined dense  label="Đến ngày" placeholder="dd/mm/yyyy" type="date"
                v-model="form.toDate" :rules="dateValidRules"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea hide-details="auto" outlined dense v-model="form.description" label="Diễn giải"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
        <v-btn type="submit" form="etd_form" color="success" small block>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cfmDialog" width="400">
      <v-card>
        <v-card-title>Bạn có muốn xóa dữ liệu này?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" outlined @click="cfmDialog=false, cfmItem=null">Thoát</v-btn>
          <v-btn class="mx-2" color="error" @click="cfmDeleteItemHandler">Xóa</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmployeeTypeDetailService from './js/EmployeeTypeDetailService.js'
import moment from 'moment/moment'

export default {
  props: {
    employeeCode: {
      type: String,
      default: '',
    },
    fetchDialog: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    fetchDialog(newVal, oldVal) {
      console.log(newVal, oldVal)
      if(newVal!=false){
        this.getTableDataHandler()
      }
    },

    createDialog(value){
      if(value) {
        console.log(value)
      } else {
        this.closeHandler()
      }
    }
  },
  data() {
    return {
      headers: [
        { text: '', value: 'actions', sortable: false, width: "5rem"},
        { text: 'Mã NS', value: 'employeeCode'},
        { text: 'Tên NS', value: 'employeeName'},
        { text: 'Loại nhân viên', value: 'employeeTypeName'},
        { text: 'Lý do phân loại', value: 'employeeTypeReasonName'},
        { text: 'Mô tả', value: 'description'},
        { text: 'Từ ngày', value: 'fromDate'},
        { text: 'Đến ngày', value: 'toDate'},
        { text: 'Tạo vào', value: 'createdAt'},
        { text: 'Tạo bởi', value: 'createdBy'},
        { text: 'Sửa vào', value: 'updatedAt'},
        { text: 'Sửa bởi', value: 'updatedBy'},

      ],
      datas: [],

      formValid: true,
      form: {
        employeeCode:'',
        employeeTypeCode: '',
        employeeTypeReasonCode: '',
        fromDate: '',
        toDate: '',
        description: '',

        createdBy: '',
        createdAt: '1900-01-01',
        updatedBy: '',
        updatedAt: '1900-01-01',
      },
      defaultForm: {
        employeeCode:'',
        employeeTypeCode: '',
        employeeTypeReasonCode: '',
        fromDate: '',
        toDate: '',
        description: '',

        createdBy: '',
        createdAt: '1900-01-01',
        updatedBy: '',
        updatedAt: '1900-01-01',
      },
      dropdownData: {
        employeeTypes: [],
        employeeTypeReasons: [],
      },

      selectItem: null,
      // Create Dialog
      createDialog: false,
      updateDialog: false,

      // cfmDialog
      cfmDialog: false,

      //rules
      dateValidRules: [
          v => !!v || 'Thiếu dữ liệu này',
          v => moment(v, 'YYYY-MM-DD', true).isValid() || 'Định dạng ngày không đúng',
      ],
    }
  },
  methods: {
    getTableDataHandler() {
      this.employeeTypeDetailService.getEmployeeTypeDetails(this.employeeCode).then(d => {
        console.log(d, '***** data *****', this.employeeCode)
        this.datas =d
      } )
    },

    openCreateDialogHandler() {
      this.createDialog = true
    },

    openUpdateDialogHandler(item) {
      this.createDialog = true
      this.updateDialog = true
      this.selectItem = item
      //get detail
      this.employeeTypeDetailService.getEmployeeTypeDetail(item.id).then(d=> {
        console.log("*** detail item ***", d)
        Object.assign(this.form, d)
      })
    },

    submitFormHandler() {
      this.form.employeeCode = this.employeeCode
      this.$refs.etd_form.validate()
      console.log(this.form)
      if(this.formValid){
        if(this.updateDialog) {
          this.employeeTypeDetailService.updateEmployeeTypeDetail(this.selectItem.id, this.form).then(() => this.getTableDataHandler()).then(() => this.createDialog=false)
        }
        if(!this.updateDialog) {
          this.employeeTypeDetailService.createEmployeeTypeDetail(this.form).then(() => this.getTableDataHandler()).then(() => this.createDialog=false)
        }
      }
    },

    deleteItemHandler(item) {
      this.cfmDialog = true;
      this.selectItem = item;
    },
    cfmDeleteItemHandler() {
      this.employeeTypeDetailService.deleteEmployeeTypeDetail(this.selectItem.id).then(() => this.getTableDataHandler()).then(()=> this.selectItem=null)
    },

    closeHandler() {
      // this.$refs.etd_form.reset()
      this.updateDialog = false
      this.selectItem=null
      this.$refs.etd_form.resetValidation()
      Object.assign(this.form, this.defaultForm)
    }
  },
  employeeTypeDetailService: null,
  created() {
    this.employeeTypeDetailService = new EmployeeTypeDetailService()
  },
  mounted() {
    this.getTableDataHandler()
    this.employeeTypeDetailService.fetchEmployeeType().then(d => this.dropdownData.employeeTypes = d)
    this.employeeTypeDetailService.fetchEmployeeTypeReason().then(d => this.dropdownData.employeeTypeReasons = d)
  }
  
}
</script>