<template>
    <v-dialog v-model="dialog" max-width="600px" scrollable>
        <v-card>
            <v-card-title>Chi tiết dữ liệu</v-card-title>
            <v-card-text>
                <v-form v-model="isValid" ref="form" @submit.prevent="saveData" id="form">
                    <v-row v-if="item==null">
                        <v-col> Không tìm thấy dữ liệu </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12">
                            <v-text-field readonly v-model="form.employee" label="Tên"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly v-model="form.department" label="Phòng ban"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field readonly v-model="form.month" label="Tháng"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field readonly v-model="form.year" label="Năm"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.standardDate" readonly label="Ngày công chuẩn"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.standardHour" readonly label="Giờ công chuẩn"></v-text-field>
                        </v-col>
                        <v-col  cols="12">
                            <v-text-field v-model="form.actualTime" readonly label="Công thực tế"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly v-model="form.extend" label="Giờ làm thêm"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.missingHour" readonly label="Giờ làm thiếu"></v-text-field>
                        </v-col>
                        <v-col  cols="12">
                            <v-text-field v-model="form.missingCover" readonly label="Công bù"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.missingRemain" readonly label="Công thiếu còn lại"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.extendRemain" readonly label="Làm thêm còn lại"></v-text-field>
                        </v-col>
                        <v-col  cols="12">
                            <v-text-field v-model="form.overtimeBooking" readonly label="Tăng ca tối đa"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.normalOvertime" readonly label="Tăng ca thường"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.dayOffOvertime" readonly label="Tăng ca ngày nghỉ"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.holidayOvertime" readonly label="Tăng ca lễ"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.overtimeFinal" readonly label="Tăng ca chính thức"></v-text-field>
                        </v-col>
                        <v-col  cols="12">
                            <v-text-field v-model="form.extendFinal" readonly label="Làm thêm chính thức"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.totalTimeFinal" readonly label="Tổng giờ công sau bù"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.totalWorkTime" readonly label="Tổng giờ công chính thức"></v-text-field>
                        </v-col>
                        <v-col  cols="6">
                            <v-text-field v-model="form.lunch" readonly label="Cơm"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="form.overtimeLunch" readonly label="Cơm tăng ca"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="form.Note" outlined label="Ghi chú"></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" class="mx-3" type="submit" form=form> <v-icon left>mdi-content-save</v-icon> Lưu</v-btn>
                <v-btn small dark color="orange" class="mx-3" @click="processData"> <v-icon left>mdi-cogs</v-icon> Xử lí lại</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch().then(()=>{
                            // this.fetchDepartment(this.form.departmentTypeCode, true);
                            // this.fetchPosition(this.form.departmentCode, true);
                            // this.fetchShift(this.form.positionStateCode, true);
                        });
                }
                else{
                    this.close();
                }
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "monthlyTimesheets/",
            EMPLOYEE_FETCH: "employees/search",
            DEPARTMENT_FETCH: "departmentTypes/fetch-department/",
            DEPARTMENT_TYPE_FETCH: "departmentTypes/fetch",
            POSITION_STATE_FETCH: "positionStates",
            SHIFT_FETCH: "positionStates/fetch-shift/",
            POSITION_FETCH: "departments/fetch-position/",
            SCALE_FETCH: "scales/fetch",

            isValid: true,
            departmentSearch: null,
            departmentTypes: [],
            positionStates: [],
            departments: [],
            shifts: [],
            positions: [],
            scales: [],
            employeeSearch: null,
            employees: [],
            departmentName:"",
            month: null,
            year: null,
            form: {
                name: null,
                department: null,
                month: null,
                year: null,

                standardDate: null,
                standardHour: null,
                actualTime: null,
                extend: null,
                missingHour: null,
                missingCover: null,
                missingRemain: null,
                extendRemain: null,
                overtimeBooking: null,
                normalOvertime: null,
                dayOffOvertime: null,
                holidayOvertime: null,
                overtimeFinal: null,
                extendFinal: null,
                totalTimeFinal: null,
                totalWorkTime: null,
                lunch: null,
                overtimeLunch:  null, 
            },
            defaultForm:{
                name: null,
                department: null,
                month: null,
                year: null,

                standardDate: null,
                standardHour: null,
                actualTime: null,
                extend: null,
                missingHour: null,
                missingCover: null,
                missingRemain: null,
                extendRemain: null,
                overtimeBooking: null,
                normalOvertime: null,
                dayOffOvertime: null,
                holidayOvertime: null,
                overtimeFinal: null,
                extendFinal: null,
                totalTimeFinal: null,
                totalWorkTime: null,
                lunch: null,
                overtimeLunch:  null,  
            }
        }
    },
    methods: {
        fetchDepartment(val, init=false) {
            if(!init){
                this.departments = [];
                this.departmentCode=null;
                this.positions = [];
                this.positionCode=null;
            }
            return http.get(this.DEPARTMENT_FETCH+val).then(res => {
                this.departments = res.data;
            });
        },
        
        fetch(){
            return http.get(this.ROOT_URL+this.item.monthlyCode).then(res => {
                this.form = Object.assign({}, res.data);
                console.log(this.form)
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    // return http.post(this.ROOT_URL, this.form).then(res =>{
                    //     this.$emit("fetch");
                    //     this.dialog = false;
                    // })
                }else {
                    const { name, department, employee, month, year, ...data } = this.form
                    return http.put(this.ROOT_URL+ this.item.monthlyCode, data).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
        },
        
        processData ()
        {   
            // this.isLoading = true;
            this.processDataLoading = true;
            const url = `monthlyTimesheets/process/${this.item.code}`
            return http.get(url, {
                params: {
                    month: this.form.month,
                    year:  this.form.year,
                }
            });
        },
    },
    created() {
    }
    
}
</script>