<template>
    <v-toolbar class=" mb-2" rounded flat color="transparent" dense>
        <v-breadcrumbs :items="parsedUrls" divider="/" large class="py-0" >
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                :to="item.to"
                :disabled="item.disabled"
                exact
                >
                <span v-bind:class="{' orange--text': !item.disabled }"> {{ item.text.toUpperCase() }} </span>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-btn  color="light-blue darken-3" @click="openStatisticPanel" class="mr-3" v-if="icon2" small text>
            <v-icon left>
                {{ icon2 ? icon2.icon : "" }}
            </v-icon> Thông số
        </v-btn>
        <v-btn color="green lighten-4 green--text text-center" @click="openCreateForm" small :fab="false" v-if="icon">
            <v-icon small>
                {{ icon ? icon.icon : "mdi-pencil-plus" }}
            </v-icon>
            <span class="d-none d-sm-inline">{{icon.text? icon.text: "Thêm"}}</span>
        </v-btn>
    </v-toolbar>
    <!-- <v-row no-gutters justify="space-between" class="" >
        <v-col cols="10" md="6">
        </v-col>
        <v-col align-self="center" class="d-flex justify-end align-center">
            <v-btn  color="light-blue darken-3" @click="openStatisticPanel" class="mr-3" v-if="icon2" small text>
                <v-icon left>
                    {{ icon2 ? icon2.icon : "" }}
                </v-icon> Thông số
            </v-btn>
            <v-btn color="green lighten-4 green--text text-center" elevation="1" @click="openCreateForm" small :fab="false" v-if="icon">
                <v-icon small>
                    {{ icon ? icon.icon : "mdi-pencil-plus" }}
                </v-icon>
                <span class="d-none d-sm-inline">{{icon.text? icon.text: "Thêm"}}</span>
            </v-btn>
        </v-col>
    </v-row> -->
</template>

<script>
export default {
    name: "BreadCrumb",
    components: {

    },
    computed: {
        parsedUrls: function () {
            return JSON.parse(this.urls);
        }
    },
    methods: {
        openCreateForm() {
            this.$emit("createForm");
        },
        openStatisticPanel() {
            this.$emit("statisticPanel");
        }
    },
    props: ['urls', 'icon', 'icon2'],
    mounted() {
    }

}
</script>
<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 5em;
  z-index: 2;
}
.custom-shadow {
    box-shadow: 0 3px 11px 0 #e8eafc,0 3px 3px -2px hsla(0,0%,69.8%,.1),0 1px 8px 0 hsla(0,0%,60.4%,.1)!important;
}
</style>
