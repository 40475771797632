<template>
    <div class="overtime-index">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true"></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <v-card v-if="true">
            <!-- <time-picker2></time-picker2> -->
            <v-card-title>
                <v-spacer></v-spacer>
                <create-button @click="dialog.state=true" ></create-button>
                <v-divider class="mx-2" vertical></v-divider>
                <month-picker width="7rem" dense outlined v-model="month" label="Chọn tháng"></month-picker>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>

            <v-data-table height="600px"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.confirmedDate`]="{ item }">
                    {{ item.confirmedDate == "01/01/0001" ? "" : item.confirmedDate}}
                </template>
                
                <template v-slot:[`item.expireDate`]="{ item }" >
                    {{ formatDate(item.confirmDate) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <!-- ********* Xoa ****** -->
                    <v-btn x-small color="error" @click="deleteItem(item)" v-if="!item.isLock">
                        <v-icon small>
                            mdi-delete
                        </v-icon> Thu hồi
                    </v-btn>
                    
                    <v-icon
                        small color="disable"
                        v-else
                    >
                        mdi-lock
                    </v-icon>
                </template>

                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field dense v-model="filters.code" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.status" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <date-picker2 dense v-model="filter.dateRange" outlined hide-details>
                            </date-picker2>
                        </td>
                        <td>
                            <v-autocomplete dense :items="overtimeTypes" v-model="filters.overtimeTypeCode" item-value="code" item-text="name" placeholder="Chọn" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                                <v-text-field dense v-model="filters.timeRange" placeholder="Chọn" outlined hide-details></v-text-field>
                            
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.length" placeholder="Nhập" outlined hide-details></v-text-field>
                        </td>
                        <td>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';
import methods from './index_methods';
import MonthPicker from '@/components/MonthPicker.vue';

export default {
    name: "OvertimeIndex",
    components : {
        CreateButton,
        "create-dialog": Create,
        "data-filter": DataFilter,
        MonthPicker,
    },
    watch: {
        month: {
            handler(val)
            {
                this.fetchData();
            }
        }
        // options: {
        //     handler (val) {
        //         this.fetchData();
        //     },
        //     deep: true,
        // },
    },
    
    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
    },
    props: [],
    ...methods,
    data () {
        return {
            ROOT_URL: "OvertimeRequests",

            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            filters:{
                actions: null,
                code: null,
                status: null,
                date: null,
                overtimeTypeCode: null,
                timeRange: null,
                length: null,
                note: null,
            },
            //TABLE DATA
            filter: {
                code:"",
                menu: false,
                search: "",
                searchAttr: "EmployeeA",
                department: "",
                from: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),

                searchAttrs: [
                    {text: "Tên nhân viên", value: "EmployeeA"},
                    {text: "Tên người ký", value: "EmployeeB"},
                ],
                departments: [],
            },
            month: moment().format("YYYY-MM"),
            overtimeTypes:[],
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttr: [],
            },

            headers: [
                { text: '', value: 'actions', align:"center", width: "200px" },
                { text: 'Mã đơn', value: 'code', width: "120px" },
                { text: 'Trạng thái', align:"center", value: 'status', width: "120px"},
                { text: 'Ngày áp dụng', align: 'start', value: 'date', width: "120px"},
                { text: 'Loại yêu cầu', align: 'start', value: 'overtimeTypeCode', width: "200px"},
                { text: 'Khung giờ tăng ca', align: 'start', value: 'timeRange', width: "200px"},
                { text: 'Thời gian tăng ca', align: 'start', value: 'length', width: "100px"},
                { text: 'Ngày gởi', align: 'center', value: 'sentDate', width: "300px"},
                { text: 'Lý do', align: 'center', value: 'overtimeReason', width: "300px"},
                { text: 'Ghi chú', align: 'center', value: 'note', width: "300px"},
            ],

            items: [],
            options: {},
            totalItem: 0,
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},
        }
    },
    created(){
        this.fetchData();
        this.fetchOvertimeType();
    }

}
</script>
<style scoped>
::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>