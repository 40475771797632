<template>
    <div class="overtime-booking">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog=true"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog> -->
        <v-card>
            <v-card-title>
                <v-btn v-if="selected.length>0" x-small color="orange" dark class="mx-1" @click="accept"> Xác nhận</v-btn>
                <v-btn v-if="selected.length>0" x-small color="red" dark class="mx-1" @click="deny"> Từ chối</v-btn>

                <v-spacer class="d-none d-md-flex"></v-spacer>
                <v-autocomplete dense outlined class="my-2 mr-2" style="max-width:300px" v-model="filters.departmentCode" :items="departments" 
                label="Phòng ban" hide-details item-text="name" item-value="code"></v-autocomplete>
                <month-picker v-model="month" label="Tháng dự trù" width="7rem" :dense="true" outlined></month-picker>
            </v-card-title>
            <v-data-table v-model="selected" show-select @toggle-select-all="selectAll"
            item-key="code" :items-per-page="10"
            :mobile-breakpoint="0"
            height="600px" fixed-header
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <!-- <template v-slot:[`header.data-table-select`]="{ props, on }">
                    <v-simple-checkbox :ripple="false" class="d-inline-flex mb-3"
                        v-bind="props" v-on="on"
                    ></v-simple-checkbox>
                </template> -->
                <template v-slot:[`item.booking`]="{ item }">
                    <v-edit-dialog
                    :return-value.sync="item.booking"
                    @save="save(item)"
                    >
                    {{ item.booking }}
                    <template v-slot:input>
                        <v-text-field min=0 step=1 type="number"
                        v-model="item.booking"
                        :rules="[v=>v>=0||'Giá trị không dc âm']"
                        label="Edit"
                        single-line
                        counter
                        ></v-text-field>
                    </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field dense v-model="filters.code" placeholder="Chọn" hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.name" placeholder="Chọn" hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.department" placeholder="Chọn" hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.booking" placeholder="Chọn" hide-details outlined></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.status" placeholder="Chọn" hide-details outlined style="max-width: 7rem"></v-text-field>
                        </td>
                        <td></td>
                    </tr>
                </template>
                <!-- <template
                    v-slot:[`item.actions`]="{item}"
                >
                    <v-btn x-small @click="edit(item)" class="mx-1">
                        <v-icon small>mdi-pencil</v-icon> Sửa
                    </v-btn>
                    <v-btn x-small @click="remove(item)" class="mx-1" color="error">
                        <v-icon small>mdi-trash-can</v-icon> Xóa
                    </v-btn>
                </template> -->
                <template v-slot:[`item.isOffice`]="{item}" >
                    <v-icon v-if="item.isOffice">mdi-check</v-icon>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
                <template
                    v-slot:[`item.time`]="{ item }"
                >
                    {{ formatTime(item.time) }}
                </template>
                <template v-slot:[`item.department`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                    <v-icon left>
                        mdi-store
                    </v-icon>
                    {{item.department}}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import ApiService from '@/components/services/ApiServices';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
// import CreateDialog from './Create.vue';
import * as moment from "moment/moment"

export default {
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        // 'create-dialog': CreateDialog,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        departmentSearch:{
            handler(val) {
                this.fetchDepartment
            }
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month(val) {
            this.filters.month = val.split("-")[1];
            this.filters.year = val.split("-")[0];
        }

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "overtimeBookings/",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },

            month: moment().format("YYYY-MM"),
            departments:[],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                month: moment().format("MM"),
                year: moment().format("YYYY"),
            },
            departmentSearch:null,
            rowCount: 0,
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            selected: [],
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                // { value:"data-table-select", width: 120, align:"end"},
                // { text: 'Thao tác', value: 'actions', width: 200},
                { text: 'Mã nhân viên', align: 'start', value: 'code', width: '7rem'},
                { text: 'Nhân viên', align: 'start', value: 'name', width: '18rem'},
                { text: 'Phòng', align: 'start', value: 'department', width: '14rem'},
                { text: 'Số giờ tăng ca', align: 'start', value: 'booking', width: '8rem'},
                { text: 'Trạng thái', align: 'start', value: 'status'},
                { text: 'Ghi chú', align: 'start', value: 'note'},
            ],
        }
    },
    methods: {
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchDepartment() {
            ApiService.fetchDepartment(this.departmentSearch).then(res=>{
                this.departments = res.data;
            })
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.items;
                console.log(this.items)
                // this.rowCount = res.data.rowCount;
            })
            .catch(err => {

            })
        },
        save(item){
            if(item.booking >0 ){
                return http.post("overtimebookings/set",{
                    employeeCode: item.code,
                    month:this.filters.month,
                    year:this.filters.year,
                    hour: item.booking
                }).then(res => {
                    
                });
            }
        },
        selectAll(status) {
            if(status.value)
                this.selected = this.items;
            else
                this.selected = [];
        },
        accept(){
            return http.post("overtimebookings/accept",{
                employees: this.selected.map(x=>x.code),
                month: this.filters.month,
                year: this.filters.year,
            }).then(res=>{
                this.fetchData()
            })
        },
        deny(){
            return http.post("overtimebookings/deny",{
                employees: this.selected.map(x=>x.code),
                month: this.filters.month,
                year: this.filters.year,
            }).then(res=>{
                this.fetchData()
            })
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchDepartment();
        this.fetchData();
    }
}
</script>

<style scoped>
::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>