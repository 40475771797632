<template>
  <div>
    <v-card outlined flat style="border-width: 2px; border-color: teal;">
      <v-card-title class="text-h5">
        <v-spacer></v-spacer>
        {{name}}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-subtitle>
        <h5><b>Id:</b> {{ $route.params.id }}</h5>
        <h5><b>Bắt đầu khảo sát:</b> {{ formatDate(validFrom) }}</h5>
        <h5><b>Kết thúc khảo sát:</b>  {{ formatDate(validTo) }}</h5>
      </v-card-subtitle>
      <v-card-text class="text--primary">
        {{description}}
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col cols="12">
            <v-card class="mt-4" min-height="6rem">
              <v-card-text class="subtitle-1 text--primary">
                <b>Phạm vi tham gia khảo sát: </b> <span class="body">{{ rangeString }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card >
              <v-card-text class="red--text text--darken-4">
                <div class="d-flex align-center">
                  <v-img src="https://cdn-icons-png.flaticon.com/128/912/912267.png" class="mr-4" max-width="128">
                  </v-img>
                  <div>
                    <h4 class="text-subtitle-1 font-weight-normal">Tổng số</h4>
                    <h2 class="text-h5 font-weight-bold">{{total}}</h2>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          
          <v-col cols="12" md="6">
            <v-card >
              <v-card-text class="text--primary">
                <div class="d-flex align-center">
                  <v-img src="https://cdn-icons-png.flaticon.com/512/825/825590.png" class="mr-4" max-width="128">
                  </v-img>
                  <div>
                    <h4 class="text-subtitle-1 font-weight-normal">Đã tham gia</h4>
                    <h2 class="text-h5 font-weight-bold">{{attended}}</h2>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="6">
        <v-card min-height="400px" class="d-flex align-item-center mt-4">
          <div style="position: relative; height:350px; width:350px; " class="mx-auto my-auto">
            <canvas id="myChart"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-subheader class="text-h5 text--primary mt-4"><v-icon left color="blue darken-3" large>mdi-chart-bar-stacked</v-icon> Chi tiết khảo sát</v-subheader>
    <v-row>
      <v-col cols="12" v-for="detailSummary in detailSummaries" :key="detailSummary.name">
        <v-card>
          <v-card-title class="title">Câu hỏi: {{ detailSummary.name }}</v-card-title>
          <div style="position: relative; height:300px; width:60vw; margin-left: 200px;" class="mx-auto my-auto">
            <canvas :id="detailSummary.name"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {Chart} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReportApi from './services/report_api'
import moment from 'moment/moment'
export default {
  computed: {
    currentId() {
      return this.$route.params.id
    }
  },
  data() {
    return {
      name: "",
      validFrom:null,
      validTo:null,
      description: "",
      rangeString:"",
      total: 0,
      attended: 0,
      detailSummaries: [],
      colors: [
        'rgba(244, 67, 54, 0.8)',
        'rgba(233, 30, 99, 0.8)',
        'rgba(156, 39, 176, 0.8)',
        'rgba(103, 58, 183, 0.8)',
        'rgba(63, 81, 181, 0.8)',
        'rgba(33, 150, 243, 0.8)',
        'rgba(3, 169, 244, 0.8)',
        'rgba(0, 188, 212, 0.8)',
        'rgba(0, 150, 136, 0.8)',
        'rgba(76, 175, 80, 0.8)',
        'rgba(139, 195, 74, 0.8)',
        'rgba(205, 220, 57, 0.8)',
        'rgba(255, 235, 59, 0.8)',
        'rgba(255, 193, 7, 0.8)',
        'rgba(255, 152, 0, 0.8)',
        'rgba(255, 87, 34, 0.8)'
      ]
    }
  },
  methods: {
    formatDate(value){
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    getBaseInfo(){
      return this.ReportApiSerivces.getBaseInfo(this.currentId).then(d =>{
        ({ name: this.name, validFrom: this.validFrom, validTo: this.validTo, description: this.description } = d.data)
      })
    },
    
    getRangeInfo(){
      return this.ReportApiSerivces.getRangeInfo(this.currentId).then(d =>{
        // ({ name: this.name, validFrom: this.validFrom, validTo: this.validTo, description: this.description } = d.data)
        ({ 
          rangeString: this.rangeString,
           total: this.total,
            attended: this.attended,
            detailSummaries: this.detailSummaries
          } = d)
        console.log(d)
      })
    },
    drawTotalChartDetail(detail) {
      //init graph
      console.log(detail.stats.map(x=>x.answer))
      const ctx = document.getElementById(detail.name);
      const data = {
        labels: detail.stats.map(x=>x.answer),
        datasets: [{
          label: 'Số người tham gia',
          data: detail.stats.map(x=>x.count),
          backgroundColor: this.colors,
          // hoverOffset: 24
        }]
      }
      
      const config = {
          type: 'bar',
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y',
            plugins: {
                datalabels: {
                  color: 'white',
                  font: {
                    weight: 'bold'
                  }
                },
                legend: {
                  position: 'right',
                },
                
                labels: {
                  render: 'value',
                  fontColor: ['green', 'white', 'red']
                },
            },
            
            scales: {
              x: {
                // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
                suggestedMin: 0,

                // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
                // suggestedMax: 1500,
              }
            }
          }
        };
        new Chart(ctx, config);
    },
    drawTotalChart() {
      
        //init graph
        const ctx = document.getElementById('myChart');
        const data = {
          labels: [
            'Đã tham gia',
            'Chưa tham gia'
          ],
          datasets: [{
            label: 'Số người tham gia',
            data: [this.attended, this.total-this.attended],
            backgroundColor: [
              'rgb(255, 152, 0, 0.8)',
              'rgb(27, 94, 32, 0.8)',
            ],
            hoverOffset: 4
          }]
        };
        const config = {
          type: 'pie',
          data: data,
          options: {
            responsive: true,
            datalabels: {
              color: 'red',
              weight: 'bold'
            },
            plugins: {
              datalabels: {
                color: 'white',
              },
                title: {
                    display: true,
                    text: 'Số người tham gia',
                    position: 'bottom',
                    font: {
                      size: 18
                    }
                }
            }
          }
        };
        new Chart(ctx, config);
    }
  },
  ReportApiSerivces:null,
  created(){
    this.ReportApiSerivces = new ReportApi()
  },
  mounted() {
    Chart.register(ChartDataLabels);
    this.getBaseInfo()
    this.getRangeInfo().then(() => {
      this.drawTotalChart()
      //draw question chart
      this.detailSummaries.forEach(detail => {
        this.drawTotalChartDetail(detail)
      });

    })
  }
}
</script>