<template>
    <div class="createDepartmentType">
        <v-dialog v-model="dialog" max-width="80vw">
            <v-card>
                <v-form @submit.prevent="createData" v-model="valid" ref="form">
                    <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                v-model="form.name"
                                label="Tên"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                v-model="form.parent"
                                :items="formData.parents"
                                item-text="name"
                                item-value="code"
                                label="Nút cha"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                v-model="form.order"
                                label="Thứ tự hiển thị"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-switch v-model="form.isOffice" label="Văn phòng">
                                </v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch v-model="form.isRoot" label="Nút gốc">
                                </v-switch>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                v-model="form.description"
                                filled
                                label="Mô tả"
                                auto-grow
                                placeholder="Mô tả chức năng khối"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    >
                        Thoát
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        type="submit"
                    >
                        Lưu
                    </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common'

export default {
    name: "DepartmentTypeCreateDialog",
    components:{

    },
    data() {
        return {
            
            //*************** Validate **************
            rules: {
                nameRules:[ 
                    v => !!v || 'Tên nhân viên bắt buộc nhập',
                    v => v.length > 5 || 'Phải nhiều hơn 5 kí tự',
                ],
            },
            
            //************* Form config */
            activetab: 0,
            valid: false,
            item: undefined,
            isUpdate: false,
            dialog: false,
            IDdateMenu: false,
            HireMenu: false,
            form: {
                name:"",
                isOffice: false,
                order: 0,
                isRoot: false,
                description: ""
            },
            defaultForm: {
                name:"",
                isOffice: false,
                order: 0,
                isRoot: false,
                description: ""
            },
            formData: {
                parents: [],
            }
        };
    },
    methods: {
        fetchUpdate (code){
            http.get("departmenttypes/"+code).then(res => {
                let data = res.data;
                this.form = Object.assign({}, data.item)
                this.formData.parents = data.parents
                // this.form.name = data.name;
                // this.form.description = data.description;
                // this.form.description = data.description;
            }).catch(err => {
                console.log(err);
            });
        },
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        createData () {
            this.$refs.form.validate();
            if(!this.isUpdate)
                http.post('departmenttypes', this.form).then(res => {
                    this.dialog = false;
                    this.$emit("needFetch");
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            else{
                http.put('departmenttypes/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        },
    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật khối" : "Tạo mới khối";
        }
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
    },
}
</script>
