<template>
    <div class="business-index">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <v-card v-if="true">
            <v-card-title>
                <v-spacer></v-spacer>
                <create-button @click="dialog.state=true"></create-button>
                <v-divider vertical class="mx-2"></v-divider>
                <month-picker width="7rem"  v-model="month" label="Chọn tháng" outlined dense></month-picker>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>

            <v-data-table height="600px"
                :mobile-breakpoint="0"
                :headers="headers"
                :items="items"
                :page.sync="tableParams.page"
                :sort-by.sync="tableParams.sortBy"
                :sort-desc.sync="tableParams.sortDesc"
                :server-items-length="rowCount"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.confirmedDate`]="{ item }">
                    {{ item.confirmedDate == "01/01/0001" ? "" : item.confirmedDate}}
                </template>
                
                <template v-slot:[`item.expireDate`]="{ item }" >
                    {{ formatDate(item.confirmDate) }}
                </template>

                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field dense v-model="filters.code" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-select dense v-model="filters.status" :items="[
                                {text: 'Chờ duyệt', value:0},
                                {text: 'Đã duyệt', value:1},
                                {text: 'Từ chối', value:2},
                                {text: 'Tất cả', value:-1},
                            ]" placeholder="Chọn" outlined hide-details></v-select>
                        </td>
                        <td>
                                <v-text-field dense v-model="filters.employeeName" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.businessTypeName" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <date-picker2  dense v-model="filters.date" outlined hide-details>
                            </date-picker2>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.amount" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.confirmedByName" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <date-picker2 dense v-model="filters.confirmedDate" placeholder="Chọn" outlined hide-details>
                            </date-picker2>
                        </td>
                        <td>
                            <v-text-field dense v-model="filters.note" placeholder="Chọn" outlined hide-details></v-text-field>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <!-- ********* Xoa ****** -->
                    <v-icon
                        small color="error"
                        @click="deleteItem(item)"
                        v-if="!item.isLock"
                    >
                        mdi-delete
                    </v-icon>
                    <v-icon
                        small color="disable"
                        v-else
                    >
                        mdi-lock
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import http from '@/components/services/http-common';
import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Business',
    components: {
        CreateButton,
        "create-dialog": Create,
        "data-filter": DataFilter
    },
    watch: {
        tableParams: {
            handler () {
                this.fetchData();
                this.fetchCity();
            },
            deep: true,
        },
        filters: {
            handler () {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month: {
            handler(val)
            {
                this.filters.from = moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to = moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            console.log(user);
            return webAllowed.includes(user.employeeCode);
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },

    props: ["breadcrumbs", "icon"],
    data() {
        return {
            ROOT_URL: "BusinessRequests/",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },

            month: moment().format("YYYY-MM"),
            leavePlans:[],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                businessTypeCode:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            rowCount: 0,

            headers: [
                {text:"", value: "actions"},
                {text: "Mã", value: "code"},
                {text: "Trạng thái", value: "status"},
                {text: "Tên nhân viên", value: "employeeName"},
                {text: "Loại công tác", value: "businessTypeName"},
                {text: "Ngày công tác", value: "date"},
                {text: "Số giờ", value: "amount"},
                {text: "Người duyệt", value: "confirmedBy"},
                {text: "Ngày duyệt", value: "confirmedDate"},
                {text: "Ghi chú", value: "note"},
            ],

            items: [],
            options: {},
            totalItem: 0,
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},
        }
    },

    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        //DATA JS
        fetchData () {
            this.isLoading=true;
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
                this.isLoading = false;
            })
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL +this.targetItem.code).then(() => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    
    created() {
        this.fetchData();
    }
}
</script>
<style scoped>

::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>