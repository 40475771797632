<template>
    <div class="ward">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb> -->
        <create-dialog ref="createDialog" @needFetch="fetchData"></create-dialog>
        <v-card>
            <v-card-title>
                Danh sách phường
            <v-spacer></v-spacer>
            <import-upload :url="WARD_IMPORT_URL"></import-upload>
            <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItem"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
// import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import http from '@/components/services/http-common';
import WardCreateDialog from './Create';
import ImportUpload from '@/components/ImportUpload';

export default {
    name: 'Ward',
    components: {
        // myBreadCrumb,
        'data-filter': DataFilter,
        'create-dialog': WardCreateDialog,
        'import-upload': ImportUpload,
    },
    data () {
        return {
            WARD_IMPORT_URL: "wards/import",

            //filter
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],
            },

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:[],
                from: undefined,
                to: undefined,
            },

            //PAGE DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            rootUrl: "wards",
            
            //datatable config
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,
            dialog: false,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                {
                    text: 'Mã phường xã',
                    align: 'start',
                    sortable: false,
                    value: 'code',
                },
                {
                    text: 'Tên tên thành phố',
                    align: 'start',
                    value: 'cityName',
                },
                {
                    text: 'Tên tên quận',
                    align: 'start',
                    value: 'districtName',
                },
                {
                    text: 'Tên quận huyện',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Thao tác', value: 'actions', sortable: false },
            ],
        }
    },
    methods:{
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData () {
            const { page, itemsPerPage } = this.options;
            this.params =  { ...this.params, 
                                sortBy: this.options.sortBy, //array
                                sortDesc: this.options.sortDesc, //array
                                page: this.options.page,
                                itemsPerPage: this.options.itemsPerPage,
                                };
            if(page != undefined || itemsPerPage != undefined)
                http.get(this.rootUrl,{
                    params: this.params
                }).then(res => {
                    this.isLoading = false;
                    this.items = res.data.items;
                    this.totalItem = res.data.totalItem;
                }).catch(err => {
                    console.log(err);
                });
        },
        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate();
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.rootUrl + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    mounted () {
        this.fetchData();
    }
}
</script>

<style lang="stylus" scoped>

</style>