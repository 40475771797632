<template>
    <div class="create-overtime-type">
        <v-dialog v-model="value.open" max-width="600px" @keydown.esc="close" @click:outside="close">
            <v-card>
                <v-form @submit.prevent="createData" novalidate ref="form">
                    <v-card-title class="secondary white--text">
                        {{ formTitle }}
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                v-model="form.name"
                                label="Tên ca"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                v-model="form.symbol"
                                label="Kí hiệu"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                v-model="form.hasLunch"
                                label="Được tính cơm"
                                required
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                v-model="form.requireEnroll"
                                label="Ràng buộc chấm công"
                                required
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                    >
                        Thoát
                    </v-btn>
                    <v-btn color="blue darken-1"
                        text
                        type="submit"
                    >
                        Lưu
                    </v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import http from '@/components/services/http-common';
import EventBus  from "@/components/services/EventBus";

export default {
    name: "CreateOvertime",
    components: {
        
    },
    computed: {
        formTitle(){
            return this.value.isUpdate ? "Cập nhật kế hoạch tăng ca" : "Tạo mới kế hoạch tăng ca";
        },
    },
    watch: {
        'value.isUpdate' (val){
            if(val) {
                this.fetchUpdate();
            }
        }
    },
    props: ["value"],
    data () {
        return {
            //************* Form config */
            rootUrl: "overtimetypes",
            valid: false,

            form: {
                name: "",
                maxMonth: "",
                maxYear: "",
                scale: 1,
                holidayScale:1,
                dayOffScale:1,
                symbol: "TC",
                isHoliday: false,
                hasLunch: false,
                lunchLimit: 4,
                isOvertime: true,
                requireEnroll: true,
            },
            defaultForm:{
                name: "",
                maxMonth: "",
                maxYear: "",
                scale: 1,
                holidayScale:1,
                dayOffScale:1,
                symbol: "TC",
                isHoliday: false,
                hasLunch: false,
                lunchLimit: 4,
                isOvertime: true,
                requireEnroll: true,
            },
        }
    },
    methods: {
        close () {
            this.$emit("closeDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        fetchUpdate () {
            http.get(`${this.rootUrl}/${this.value.item.code}`).then(res => {
                let data = res.data;
                this.form = Object.assign({}, res.data);
            }).catch(err => {
                console.log(err)

            });
        },
        createData () {
            switch (this.value.isUpdate) {
                case false: {
                    http.post(this.rootUrl, this.form).then(res => {
                        this.$emit("closeDialog");
                        this.$emit('fetchData');
                    }).catch(err => {
                        console.log(err)
                    });
                    break;
                }
                case true: {
                    http.put(this.rootUrl + "/" + this.value.item.code, this.form).then(res => {
                        this.close();
                        this.$emit('fetchData');
                    }).catch(err => {
                        console.log(err)
                    });
                    break;
                }
            }
        },
    },

    created () {
        
    }

}
</script>

<style scoped>

</style>