import http from '@/components/services/http-common'

const baseUrl = `${process.env.VUE_APP_BASE_URL}api/it/departments`

export default class DepartmentService {
  gets = () => http.get(`${baseUrl}/`).then(res => res).then(d => d.data)
  
  getDeptIp = (id) => http.get(`${baseUrl}/ip-list/${id}`).then(res => res).then(d => d.data)

  updateIp = (id, data) => http.put(`${baseUrl}/update-ip/${id}`, data).then(res => res).then(d => d.data)
}