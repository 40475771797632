<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-transition"
    @click:outside="closeHandler()"
  >
    <v-card v-if="!google">
      <v-card-title>Video</v-card-title>
      <v-card-text>
        <span v-html="embedYoutube"></span>
      </v-card-text>
    </v-card>
    <v-card v-if="google">
      <v-card-title>Video</v-card-title>
      <v-card-text>
        <video :src="youtubeUrl" width="100%" controls></video>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    youtubeUrl: {
      type: String,
      default:"",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    google: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  computed:{
    embedYoutube() {
        // getId
        const videoId = this.getId(this.youtubeUrl);
        const iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>';
      return iframeMarkup
    }
  },
  methods: {
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;
    },
    closeHandler(){
      this.$emit('close-youtube-dialog')
    }
  }
}
</script>