import Vue from 'vue';
import VueRouter from 'vue-router';
const Login = () => import(/* webpackChunkName: "Login" */ '../views/Navless/Login.vue');
const RecruitNavless = () => import(/* webpackChunkName: "Recruit" */ '../views/Recruit.vue');
const Redirect = () => import(/* webpackChunkName: "Redirect" */ '../views/Navless/Redirect.vue');
const UserPending = () => import(/* webpackChunkName: "Redirect" */ '../views/Navless/UserPending.vue');
// const Register = () => import(/* webpackChunkName: "Register" */ '../views/Register.vue');
// import FaceData from '../views/FaceData.vue';
import Navless from '../views/layouts/NavlessLayout.vue';
import AppLayout from '../views/layouts/App.vue';

// import Leave from '../views/Request/Leave/Index.vue';

// import TimeSheet2 from '@/views/HR/TimeSheet/Index2.vue';
// import Salary from './pages/Salary';

// import WorkType from '../views/WorkType/Index.vue';
// import QuitRequest from '../views/Request/Quit/Index.vue';
// import HRform from '../views/Employee/Form.vue';
// import ConfirmQuitRequest from '../views/Confirms/QuitRequest/Index.vue';
// import SignUp from '../views/Candidate/SignUp';

// import Allowance from './pages/Allowance';
// import SalaryReport from './pages/SalaryReport';

//layouts
import BaseRoute from './subLayouts/Base';
import EmploymentRoute from './subLayouts/Employment';
import Recruit from './subLayouts/Recruit';
import ItSection from './subLayouts/ItSection';
import MktSection from './subLayouts/MktSection';
import OcSection from './subLayouts/OcSection';
import CmSection from './subLayouts/CmSection';
import SalaryEnrollmentRoute from './subLayouts/SalaryEnrollment';
import SystemConfigRoute from './subLayouts/SystemConfig';
import * as moment from "moment/moment";

//navless 
import ChangePassword from '@/views/Navless/ChangePassword';
import ForgetPassword from '@/views/Navless/ForgetPassword';
import LaDSection from './subLayouts/LaDSection';
import WareHouseSection from './subLayouts/WareHouseSection';


Vue.use(VueRouter)

const routes = [
  {
    path: "/admin",
    component: AppLayout,
    children: [
      ...BaseRoute,
      ...Recruit,
      ...EmploymentRoute,
      ...SalaryEnrollmentRoute,
      ...SystemConfigRoute,
      ...ItSection,
      ...MktSection,
      ...OcSection,
      ...CmSection,
      ...LaDSection,
      ...WareHouseSection,

      {
        path: 'home',
        redirect: {name: 'Home'}
      },
      
      {
        path:"Confirm",
      },

    ]
  },
  {
    path: '/home',
    alias:'',
    component: Navless,
    children: [
      {
        path: "login",
        alias:'',
        component: Login,
        name: "login",
        props: true
      },
      {
        path: "user-pending",
        component: UserPending,
        name: "UserPending",
      },
      {
        path: "tuyen-dung",
        alias:'',
        component: RecruitNavless,
        name: "RecruitNavless"
      },
      
      {
        path: "redirect/:id?",
        alias:'',
        component: Redirect,
        name: "redirect",
        props: true
      },
      {
        path: "change-password",
        component: ChangePassword,
        name: "ChangePassword",
      },
      {
        path: "forget-password",
        component: ForgetPassword,
        name: "ForgetPassword",
      },
      // {
      //   path: "signup",
      //   component: SignUp ,
      //   name: "signup",
      // }
      // {
      //   path: 'hr-form',
      //   alias: ["hr/hr-form"],
      //   name: 'HRform',
      //   component: HRform
      // },
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    }
    return { x: 0, y: 0 }
  },
});
const AllowAnonymousRoute = ['login', 'register', 'signup', 'redirect', 'ChangePassword', 'RecruitNavless', 'ForgetPassword']

router.beforeEach((to, from, next) => {
  var tokenStr = localStorage.getItem("token");
  // var validTo = (tokenStr != null) ? JSON.parse(atob(tokenStr.split(".")[1])).exp * 1000 : 0;
  var validTo = moment(localStorage.getItem("validTo"));
  var now = moment();
  if(!(AllowAnonymousRoute.includes(to.name))){
    if (!tokenStr || (now > validTo || validTo == 0)) {
      var redirectData = findRouteName(to)
      
      next({ name: 'login', query: {
        redirect: true,
        route: redirectData.routeName,
        id: redirectData.id,
        id2: redirectData.id2
      } });
    }
    else{
      next();
    }
  }else {
    next();
  }
})

function findRouteName(from) {
  const matchedRoutes = from.matched;
  let params = from.params
  const routeName = matchedRoutes[matchedRoutes.length - 1].name;
  console.log(params)
  return {
    routeName: routeName,
    id: params.id,
    id2: params.id2,
  }
}
export default router
