<template>
    <v-dialog v-model="dialog" max-width="800px" scrollable persistent :retain-focus="false"  fullscreen eager>
        <v-card>
            <v-card-title>{{isEdit ? 'Thay đổi': 'Tạo'}} báo cáo {{isEdit ? item.id: ''}}
            <v-spacer></v-spacer>
            <v-btn icon color="red" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-card-text class="text--primary">
                <v-row>
                    <v-col cols="12">
                        <v-form ref="form" v-model="valid" @submit.prevent="save">
                            <v-row class="mt-1">
                                <v-col cols="12">
                                    <v-text-field label="Mã" v-model="form.code" hide-details="auto"
                                    :rules='[v=>!!v||"Chọn dữ liệu"]'
                                    persistent-placeholder outlined ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Tên danh mục" v-model="form.name" hide-details="auto"
                                    :rules='[v=>!!v||"Chọn dữ liệu"]'
                                    persistent-placeholder outlined ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea label="Mô tả" v-model="form.description" hide-details="auto"
                                    :rules='[v=>!!v||"Chọn dữ liệu"]'
                                    persistent-placeholder outlined ></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-select label="Hiển thị" :items="[
                                        { text: 'Hiển thị', value: 0},
                                        { text: 'Ẩn', value: 1},
                                    ]" v-model="form.isHide" hide-details="auto" outlined></v-select>
                                </v-col>
                                <v-subheader style="width: 100%;" class="mb-0">Thông tin báo cáo <v-btn class="my-auto" x-small color="success" @click="printTest">Tạo bản In</v-btn></v-subheader> 
                                
                                
                                
                                <v-col cols="6">
                                    <editor v-model="form.template"/>
                                </v-col>
                                <v-col cols="6" class="pt-0" >
                                    <div class="d-block px-4" style="width: 100%; " >
                                        <v-btn x-small @click="showPreview = !showPreview">Preview</v-btn>
                                    </div>
                                    <v-expand-transition>
                                    <div v-show="showPreview">

                                        <iframe  id="printf" name="printf" :srcdoc="form.template" frameborder="0" width="794px" height="500px" class="mx-auto"></iframe>
                                    </div>
                                    </v-expand-transition>
                                    <!-- <span id="printArea"  v-html="form.template"></span> -->
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="imageUploading" small color="primary" form="form" @click="save">Lưu </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from './services/api.js'
import moment from 'moment/moment'
import Editor from '@/components/Editor/Editor.vue';

export default {
    props:["value"],
    components:{
        Editor
    },
    watch:{
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
        dialog: {
            handler(val){
                if(val)
                {
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    console.log("check is edit")
                    if(this.isEdit)
                    {
                        this.fetch()
                        this.headerCreated = true
                    }
                }
                else{
                    this.close();
                }
            },
        },

        imageFiles() {
            this.form.images = ""
        },
       
        employeeTextPaste(newVal) {
            this.form.ApplyToEmployeeCodes = newVal.length==0? []: newVal.split(',')
        },


    },
    computed: {
        // dialog: {
        //     get: function () {
        //         return this.value;
        //     },
        //     set: function (val){
        //         this.$emit("input", val)
        //     }
        // },
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        isEdit() {
            return !!this.item;
        },
        validDetail(){
            console.log(this.rules.required);
        },
        

        employeeReportCategoryDescription() {
            let result = "";
            if(this.categories.length>0){
                if(this.form.employeeReportCategoryCode)
                {
                    let resultObj = this.categories.filter(x=>x.code == this.form.employeeReportCategoryCode)
                    if(resultObj.length>0){
                        result = resultObj[0].description
                    }
                }
            }
            return result
        },
        imageStatus() {
            var result = {
                name: 'Hình ảnh khả dụng',
                color: 'green',
                value: 1
            }
            this.imageFiles.forEach(imageFile => {
                let lastModifiedTimestamp = imageFile.lastModified;
                let createdDate = moment(lastModifiedTimestamp)
                console.log(createdDate)
                if(createdDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                    result = {
                        name: 'Hình ảnh cũ, kiểm tra lại các hình ảnh chụp trong hôm nay',
                        color: 'error',
                        value: 0
                    } 
                }
            });
            return result
        }
    },
    data() {
        return {
            showPreview: false,
            cameraOrUpload: 0,

            imageFiles: [],

            img: null,
            camera: null,
            deviceId: null,
            devices: [],
            imageUploading: false,
            uploaded: 0,
            totalUpload: 0,
            
            qrSrc: "",
            valid: false,
            statuses: [{text: 'Áp dụng tất cả phòng ban', value: 1}, {text: 'Áp dụng một số', value: 0}],

            dialog: false,
            item: null,

            toDepartments:[],
            fromDepartments:[],
            




            form: {
                code: "",
                name: "",
                description: "",
                isHide: 0,
                template: ""

            },
            defaultForm: {
                code: "",
                name: "",
                description: "",
                isHide: 0,
                template: ""
            },

            sampleTemplate: "",
            employeeTextPaste:'',

            /**header */
            categories: [],
            departments: [],
            employees:[],
            /** Detail Header*/
            detailHeaders: [
                {text:"Tên dụng cụ", value: "id", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "name", width:"7rem", align:"center"},
                {text:"Số lượng", value: "toDepartmentCode", width:"7rem"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "employeeReportCategoryCode", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "images", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "toDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "fromDepartmentNote", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "status", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "createdBy", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedAt", width:"7rem", align:"center"},
                {text:"Số lượng kiểm đếm", value: "updatedBy", width:"7rem", align:"center"},
                {text:"", value: "actions", width:"7rem", align:"center"},
            ],
            properties:[],
            detail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                quantity:0,
                actualQuantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
            },
            detailItems:[],

            propertyValid: false,
            amountValid: false,

            
            rules: {
                required: value => !!value || 'Required.'
            },

            /**Stepper params */
            headerCreated: false,
        }
    }, 
    methods: {
        printTest() {
            window.frames["printf"].focus();
            window.frames["printf"].print();
        },

        onCapture() {
            this.img = this.$refs.webcam.capture();
            // convert to blob then upload
            const contentType = 'image/jpeg';
            const filename = 'example.jpg';
            
            const file = this.base64ToFile(this.img.split(',')[1], filename);

            console.log(file)
            this.imageUploading = true
            this.totalUpload +=1
            this.apiService.uploadFile([file]).then(d => {
                var joinString = [this.form.images, d]
                this.form.images = joinString.filter(x=>!!x).join(';')
            }).finally(() => {
                this.uploaded +=1
                if(this.totalUpload == this.uploaded){
                    this.imageUploading = false
                }
            })
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },

        uploadFiles() {
            this.imageUploading = true
            this.totalUpload = this.imageFiles.length

            return this.apiService.uploadFile(this.imageFiles).then(d => {
                var joinString = [this.form.images, d]
                this.form.images = joinString.filter(x=>!!x).join(';')
            }).finally(() => {
                this.uploaded = this.totalUpload
                if(this.totalUpload == this.uploaded){
                    this.imageUploading = false
                }
            })
        },

        base64ToFile(base64String, filename, mimeType = ''){
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });

            return new File([blob], filename);
        },

        getUnit(property) {
            var data = this.properties.find(x=>x.code == property);
            if(!data)
                return ""
            else
                return data.propertyUnitName
        },
        getDepartments() {
            this.apiService.getDepartments().then(d => {
                this.departments = d
            })
        },

        getCategories() {
            this.apiService.getCategories(this.form.ToDepartmentCode).then(d => {
                this.categories = d
            })
        },

        getEmployees() {
            this.apiService.getEmployees().then(d => {
                this.employees = d
            })
        },
        close(){
            this.dialog = false
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.$refs.form.resetValidation();

            this.uploaded = 0
            this.totalUpload = 0

            this.$emit('fetchData');
            this.$emit('close-dialog');


        },

        save() {
            this.$refs.form.validate();
            if(this.valid)
            {
                //tao phieu
                if(!this.isEdit) {
                    return this.apiService.post( this.form).then(res => {
                        // this.$emit("fetchData", res.data.code);
                        
                        // this.fetchDetail();
                        // this.form = Object.assign({}, res.data.data);
                    }).then(()=> { 
                        this.close()
                    });
                }

                //update phieu
                if(this.isEdit) {
                    console.log(this.item)
                    return this.apiService.put(this.item.code,this.form).then(res => {
                        this.close()
                    }).catch(() => {
                    });
                }
            }
        },
        fetch() {
            this.apiService.getItem(this.item.code).then(d => {
                console.log(d)
                this.form = Object.assign({}, d);
            })
        },
        getToDepartments() {
            this.apiService.getToDepartments().then(d => {
                console.log(d)
                this.toDepartments = d
            })
        },

        getFromDepartments() {
            this.apiService.getFromDepartments().then(d => {
                this.fromDepartments = d
            })
        }
        // fetchDepartment()
        // {
        //     return http.get("propertyRequests/get-departments").then(res => {
        //         this.departments = res.data;
        //     });
        // },
        // fetchProperty()
        // {
        //     return http.get("properties").then(res => {
        //         this.properties = res.data.items;
        //     });
        // },
        // fetchDetail() {
        //     let url = `propertyRequests/detail/${this.form.code}`
        //     return http.get(url).then(res => {
        //         this.detailItems = res.data;
        //     })
        // },
        

        //SERVICES
    },
    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted() {
        // this.getToDepartments()
        // this.getFromDepartments()
        
        // this.startVideo()
        // this.getDepartments();
        // this.getEmployees()
    }
}
</script>