<template>
    <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
                <v-card-title >
                    {{isEdit ? "Thay đổi" :"Thêm"}} phân công nhân viên
                    <v-spacer></v-spacer> <v-icon small @click="dialog=false"> mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="pt-3">
                    <v-form v-model="isValid" ref="form" class="d-flex" @submit.prevent="saveData" id="form" >
                    <v-row>
                        <v-col cols="6" class="">
                            <v-text-field v-model="form.title" label="Tựa danh mục" ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="">
                            <v-text-field v-model="form.order" label="Thứ tự hiển thị" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="">
                            <v-checkbox v-model="form.isFeature" label="Đặc biệt"></v-checkbox>
                            <v-checkbox v-model="form.isVisible" label="Hiển thị"></v-checkbox>
                        </v-col>
                    </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="success" type="submit" form="form"> Lưu</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        value: {
            handler(val){
                if(val)
                {
                    if(this.employee){
                    }
                    if(this.isEdit)
                        this.fetch().then(()=>{
                            this.fetchData = false;
                        });
                }
                else{
                    this.close();
                }
            }
        },

        // FETCHING DATA
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "contentCategories/",

            isValid: true,
            fetchData: true,
            form: {
                code: null,
                title:null,
                isFeature: false,
                isVisible: true,
                order: 0,
            },
            defaultForm:{
                code: null,
                title:null,
                isFeature: false,
                isVisible: true,
                order: 0,
            }
        }
    },
    methods: {
        
        
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                console.log(res.data, "ggg");
                this.form = Object.assign({}, res.data);
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    return http.post(this.ROOT_URL, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fetchData=true;
        }
    },
    created() {
    }
    
}
</script>
<style scoped>
form .col {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>