import http from "@/components/services/http-common"


export default class Api {
    getEmployees = () => http.get("employees/simple").then(res =>res.data)
    
    post = (request) => http.post('Contractsv2', request)
    
    getContractCategory = () => http.get("contractCategories/simple").then(r=>r.data)

    getContractCategoryTemplate = (id) => http.get(`contractsv2/get-template/${id}`).then(r=>r.data)

    getItem = (id) => http.get(`contractsv2/${id}`).then(res=>res.data)
    
    get = () => http.get('contractsv2/new').then(res => res.data)
    getPending = () => http.get('contractsv2/pending').then(res => res.data)
    getReady = () => http.get('contractsv2/ready').then(res => res.data)
    getReturn = () => http.get('contractsv2/return').then(res => res.data)
    getComplete = () => http.get('contractsv2/complete').then(res => res.data)
    
    getResign = () => http.get('contractsv2/resign').then(res => res.data)

    delete = (id) =>  http.delete(`contractsv2/${id}`)

    // statuses
    changeStatus = (id, status) => http.get(`contractsv2/change-status/${id}`, {
        params: {
            status: status
        }
    })
    
    verify = (id, status, type = "HR") => http.get(`contractsv2/manager-status/${id}`, {
        params: {
            status: status,
            statusType: type
        }
    })

    resend = (id) =>  http.get(`contractsv2/resend/${id}`)

    getSalary = (employeeCode, date) => http.get("contractsv2/get-salary", {
        params: {
            employeeCode: employeeCode,
            date: date
        }
    }).then(r =>r.data)

    uploadConfirm = (id, attachment) => {
        let formData = new FormData();
        formData.append('attachment', attachment)
        return http.post('contractsv2/upload-confirm/'+id, formData)
    }
}
