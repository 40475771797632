<template>
  
  <div class="recruitment-request">
    <!-- <v-breadcrumbs class="bread-crumbs"
      :items="breadcrumbs" large >
    
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :exact="item.exact"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>

      <!-- <create-dialog-health-insurance ref="createHealthInsurance" @needFetch="fetchData"></create-dialog-health-insurance> -->
      <v-card>
          <v-card-title>
              <!-- Danh sách bảo hiểm -->
              <v-spacer></v-spacer>
              <v-btn class="" color="teal darken-4" dark :to="{ name: 'HrEmployeeFamilyCreate', query: {
                returnPage: page
              } }"> Tạo mới</v-btn>
              <v-text-field v-model="search" hide-details label="Tìm kiếm" persistent-placeholder outlined style="max-width: 250px;" dense class="mx-2"></v-text-field>
              <export-excel :items="data" :headers="headers"></export-excel>
              <!-- <v-btn class="" color="teal darken-4" dark :to="{ name: 'EmployeeFamilyCreate', query: {
                returnPage: page
              } }"> Tạo mới</v-btn> -->
              <!-- <create-button @click="dialog={state: true, item:null}"></create-button> -->
          </v-card-title>
          <v-data-table height="600px"
          :headers="headers"
          :page="page"
          :items="data"
          :search="search"
          :loading="isLoading"
          >
              <!-- BHYT -->
              <template v-slot:[`item.actions`]="{ item }">
                  <v-btn x-small color="error" @click="deleteItem(item)" class="mx-2"><v-icon small>mdi-pencil</v-icon> Xóa</v-btn>
                  <v-btn x-small color="white" @click="editItem(item)"><v-icon small>mdi-pencil</v-icon> Chi tiết</v-btn>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                  <v-btn small color="error" style="width: 50px; height: 50px; " class="ma-2" @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <template v-slot:[`item.relationType`]="{ item }">{{relationTypes[item.relationType]}}
                <!-- <v-chip color="pink lighten-4">
                  <b>{{relationTypes[item.relationType]}}</b>
                </v-chip> -->
              </template>
              <template v-slot:[`item.isDependant`]="{ item }">
                  <v-icon color="green" v-if="item.isDependant == 1">mdi-check</v-icon>
              </template>
              <template v-slot:[`item.doB`]="{ item }">
                  {{ formatDate(item.doB) }}
              </template>
              <template v-slot:[`item.availableIn`]="{ item }">
                  {{ formatDate(item.validfrom) }} - {{ formatDate(item.validTo) }}
              </template>
          </v-data-table>
          
      </v-card>

      <!-- ************** DELETE CONFIRMATION *************** -->
      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
              <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import EmployeeFamilyIndexApi from './api.js'
import MyBreadcrumb from '../../../components/MyBreadcrumb.vue';
import ExportExcel from '@/components/ExportExcel'

export default {
  components: { 
    MyBreadcrumb,
    ExportExcel
  },
  data() {
    return {
      //DATATABLE
      data: [],
      relationTypes: [],
      page: 0,
      totalItem: 0,
      search:"",
      options: {},
      isLoading: true,
      targetItem: undefined,
      headers: [
          { text: this.$t('view.action.detail'), value: 'actions', sortable: false, width: "12rem"},
          { text: 'Mã nhân sự', align: 'start', sortable: false, value: 'employeeCode', },
          { text: 'Tên nhân viên', align: 'start', sortable: false, value: 'employeeName', },
          { text: 'Tên thành viên', align: 'start', value: 'memberName', width:'15rem' },
          { text: 'Mối quan hệ', align: 'start', value: 'relationType', },
          { text: 'Số điện thoại', value: 'phoneNumber' },
          { text: 'CCCD', align: 'start', value: 'memberId', },
          { text: 'Ngày sinh', value: 'doB' },
          { text: 'Khả dụng', value: 'availableIn'},
          { text: 'Đối tượng phụ thuộc', value: 'isDependant' },
      ],

      dialogDelete: false,
      // cfmDeleteItem: null,
      
      breadcrumbs: [
        {
          text: 'Thông tin về gia đình',
          disabled: false,
          to: {name: 'EmployeeFamily'}
        },
      ]
    }
  },
  methods: {
    
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    
    editItem (item) {
      this.$router.push({
        name: 'HrEmployeeFamilyEdit',
        params: { id: item.id },
        query: { returnPage: this.$route?.returnPage??0
        }
      })
    },

    //fetching
    gets() {
      this.isLoading = true
      return EmployeeFamilyIndexApi.gets().then(d => {
        console.log(d)
        this.data = d
      }).finally(()=> {
        this.isLoading = false
      })
    },
    
    deleteItem (item) {
          this.dialogDelete = true;
          this.targetItem = item;
      },
    cfmDeleteItem() {
      return EmployeeFamilyIndexApi.deleteItem(this.targetItem.id).then(() => {
        this.targetItem = null
        this.dialogDelete = false
      }).finally(() => {
        return this.gets()
      })
    },
  },

  created() {
    this.gets()
    
    EmployeeFamilyIndexApi.getRelationType().then(r => {
      this.relationTypes = r.dict
    })
  }
}
</script>