<template>
  <v-form v-model="isValid" @submit.prevent="submitForm" ref="temporary_store_detail_form" id="temporary_store_detail_form">
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="rounded-lg">
          <v-card-title>
            <v-subheader class="text-primary font-weight-bold">Chọn nhân viên</v-subheader>
            <v-spacer></v-spacer>
            <v-text-field  v-model="search" style="max-width: 200px" hide-details placeholder="Tìm kiếm" outlined dense></v-text-field>
          </v-card-title>
          <v-data-table :search="search" item-key="code" dense show-select :items="employees" single-select v-model="employeeSelect" :headers="employeeHeaders" :items-per-page="5"></v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" >
        <v-autocomplete label="Chọn chi nhánh" hide-details="auto" :items="temporaryStores" item-text="name" item-value="id"
         v-model="form.temporaryStoreId" dense outlined persistent-placeholder :rules="[v=>!!v||'Nhập dữ liệu này']"></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense outlined hide-details="auto" v-model="form.validFrom" label="Từ ngày" type="date"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense outlined hide-details="auto" v-model="form.validTo" label="Đến ngày" type="date"></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import TemporaryStoreDetailApi from './api'
import * as moment from "moment/moment";
export default {
  mounted() {
    //********Global Events Listener */
    this.$root.$on('submitForm', () => {
        this.submiFormHandler()
    });
    
    this.$root.$on('editForm', (data) => {
        this.editItem = data.id
        this.fetchData()
    });
    
    this.$root.$on('resetForm', () => {
        this.resetFormHandler()
    });

    //********API */
    var endMonth = this.$formatter.formatDate(moment().endOf('month'), "YYYY-MM-DD")
    this.$apiService.fetchEmployeeSimple(endMonth).then(r => {
      this.employees = r.data
    })
    TemporaryStoreDetailApi.getTempStore().then(d => {
      this.temporaryStores = d.data
    })
  },
  destroyed() {
    //********OFF Global Events Listener */
    this.$root.$off('submitForm', () => {
        this.submiFormHandler()
    });
    
    this.$root.$off('editForm', (data) => {
        this.editItem = data.id
        this.fetchData()
    });
    
    this.$root.$off('resetForm', () => {
        this.resetFormHandler()
    });
  },
  watch: {
    employeeSelect(value) {
      this.form.employeeCode = value[0].code
    }
  },
  methods:{
    formValidate(){
      this.$refs.temporary_store_detail_form.validate();
        if(!this.form.employeeCode) {
          alert("Chọn 1 nhân viên")
          return
        }
    },
    submiFormHandler() {
      if(!this.editItem){
        this.formValidate()
        if(this.isValid){
          return TemporaryStoreDetailApi.post(this.form).then(() => {
            this.$emit('submitted')
          })
        }

      } else{
        this.formValidate()
        return TemporaryStoreDetailApi.put(this.editItem, this.form).then(() => {
            this.$emit('submitted')
          })
      }

    },
    resetFormHandler() {
      this.form.employeeCode= null,
      this.form.temporaryStoreId=null,
      this.form.validFrom= '1900-01-01',
      this.form.validTo='2100-01-01'
    },
    fetchData() {
      TemporaryStoreDetailApi.get(this.editItem).then(d => {

        this.search = d.data.employeeCode
        this.employeeSelect = this.employees.filter(x=>x.code == d.data.employeeCode)
        this.form.employeeCode = d.data.employeeCode
        this.form.temporaryStoreId = d.data.temporaryStoreId
        this.form.validFrom = this.$formatter.formatDate(d.data.validFrom, 'YYYY-MM-DD')
        this.form.validTo = this.$formatter.formatDate(d.data.validTo, 'YYYY-MM-DD')
      })
    }

  },
  data() {
    return {
      search: "",
      editItem: null,
      employees: [],
      employeeSelect: [],
      temporaryStores: [],
      employeeHeaders: [
        { text: 'MSNS', value: 'code' },
        { text: 'Tên', value: 'name', width:"200"},
        { text: 'Giới tính', value: 'gender' },
        { text: 'Chức vụ', value: 'positionName', width:"150" },
        { text: 'Chức danh', value: 'positionStateName', width:"150" },
        { text: 'Phòng ban/Chi nhánh', value: 'departmentName' },

      ],
      isValid: true,
      form: {
        employeeCode: null,
        temporaryStoreId:null,
        validFrom: '1900-01-01',
        validTo:'2100-01-01'
      }
    }
  }
}
</script>