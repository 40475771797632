<template>
    <v-dialog v-model="dialog" max-width="600px">
        <v-card>
            <v-form v-model="isValid" ref="form" @submit.prevent="saveData" id="form">
                <v-card-title>
                    {{isEdit ? "Thay đổi" :"Thêm"}} phép cho nhân viên
                    <v-spacer></v-spacer> <v-icon small @click="dialog=false"> mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete item-text="name" item-value="code" :items="employees" v-model="form.employeeCode" :search-input.sync="employeeSearch" :readonly="!!employee"
                            label="Nhân viên" hide-no-data @update:search-input="fetchEmployee(employeeSearch)" :rules="[ v => !!v || 'Chọn nhân viên']">
                                <template v-slot:selection="data">
                                    <strong>{{ data.item.code }}</strong> {{`: ${data.item.name} - ${data.item.department}`}}
                                </template>
                                <template v-slot:item="data">
                                    <strong>{{ data.item.code }} </strong> {{`: ${data.item.name} - ${data.item.department}`}}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col md="6">
                            <v-autocomplete item-text="name" item-value="code" :items="leavePlans" v-model="form.leavePlanCode" :readonly="isEdit"
                            label="Loại phép" hide-no-data></v-autocomplete>
                        </v-col>
                        <v-col md="6">
                            <v-text-field v-model="form.amount" label="Số lượng" :rules="[v=>v!=``||'Nhập số liệu']"></v-text-field>
                        </v-col>
                        <v-col md="6">
                            <date-picker2 :width="300" :range="false" v-model="form.date" label="Ngày cấp"></date-picker2>
                        </v-col>
                        <v-col md="6">
                            <date-picker2 :width="300" :range="false" v-model="form.expireDate" label="Ngày hết hạn"></date-picker2>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox label="Khởi tạo lần đầu" v-model="form.init"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea label="Ghi chú" v-model="form.note" outlined></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="success" type="submit"> Lưu</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        value: {
            handler(val){
                this.fetchLeavePlan();
                if(val)
                {
                    if(this.employee){
                        this.fetchEmployee(this.employee);
                        this.form.employeeCode = this.employee;
                    }
                    if(this.isEdit)
                    {
                        this.fetch().then(()=>{
                            this.fetchEmployee(this.form.employeeCode, true);
                        });
                    }
                }
                else{
                    this.close();
                }
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "leaveEnrollments/",
            EMPLOYEE_FETCH: "employees/search",

            isValid: true,
            leavePlans:[],
            employeeSearch: null,
            employees: [],
            form: {
                employeeCode: null,
                leavePlanCode: null,
                amount: null,
                total: 0,
                init: false,
                note: null,
                date: "",
                expireDate: "",
            },
            defaultForm:{
                employeeCode: null,
                leavePlanCode: null,
                amount: null,
                total: 0,
                init: false,
                note: null,
                date: "",
                expireDate: "",
            }
        }
    },
    methods: {
        formatDate(val){
            return moment(val, "YYYY/MM/DD").format("YYYY/MM/DD");
        },
        fetchEmployee(val) {
            return http.get(this.EMPLOYEE_FETCH).then(res => {
                this.employees = res.data;
            });
        },
        fetchLeavePlan (){
            return http.get("leavePlans/fetch").then(res => {
                this.leavePlans = res.data.items;
            });
        },
        fetch(){
            return http.get(this.ROOT_URL+this.item.employeeCode+"/"+this.item.leavePlanCode).then(res => {
                this.form = Object.assign({}, res.data);
                // this.form.date = this.formatDate(this.form.date);
                // this.form.expireDate = this.formatDate(this.form.expireDate);
                this.fetchEmployee(this.form.employeeCode);
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    return http.post(this.ROOT_URL, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.employeeCode+"/"+this.item.leavePlanCode, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
        }
    },
    created() {
    }
    
}
</script>