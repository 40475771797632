import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00334D',
        success: '#004D40',
        warning: '#e2a300',
        error: '#a71100'
      },
    },
  },
});
