<template>
    <div class="schedule">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)"></myBreadCrumb> -->
        <v-card>
            <v-card-title class="d-flex justify-end"> 
                <span class="d-none d-md-flex">Lịch làm việc</span><v-spacer></v-spacer>
                
                <!-- Menu date picker -->
                <date-picker2 :range="true" outlined v-model="filters.fromTo" :hide-details="true" :dense="true" :width="200"></date-picker2>
                <v-autocomplete label="Phòng" placeholder="Phòng" hide-details hide-no-data clearable v-model="filters.departmentCode" style="max-width:15rem"
                :items="departments" item-value="code" item-text="name" :search-input.sync="departmentSearch" @update:search-input="fetchDepartment" solo dense class="ml-1"
                hint="Nhập phòng ban phân công"></v-autocomplete>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>
            <v-data-table fixed-header height="600px"
            disable-sort
            :headers="combinedHeader"
            :items="tableData"

            :mobile-breakpoint="0"
            :page.sync="tableParams.page"
            :sort-by.sync="tableParams.sortBy"
            :sort-desc.sync="tableParams.sortDesc"
            :server-items-length="rowCount"
            :loading="isLoading">
                <template v-slot:[`body.prepend`]="">
                    <tr>
                        <td>
                            <v-text-field v-model="filters.employeeCode" placeholder="Tìm kiếm"></v-text-field>
                        </td>
                        <td>
                            <v-text-field v-model="filters.employeeName" placeholder="Tìm kiếm"></v-text-field>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>
                <template v-for="dateHeader in dateHeaders" v-slot:[`item.${dateHeader.value}`]="{ item }">
                    <div v-if="item[dateHeader.value]" :key="dateHeader.value" class="ma-auto" style="cursor:pointer" @click="detail($event, {item, dateHeader}, item[dateHeader.value].code )">
                        <v-alert  v-if="item[dateHeader.value].type=='CS'" color="cyan" text class="ma-auto" rounded="0" outlined>
                            Ca sáng
                        </v-alert>
                        <v-alert  v-if="item[dateHeader.value].type=='CC'" color="purple" text class="ma-auto" rounded="0" outlined>
                            Ca chiều
                        </v-alert>
                        <v-alert  v-if="item[dateHeader.value].type=='CT'" color="indigo" text class="ma-auto" rounded="0" outlined>
                            Ca tối
                        </v-alert>
                        <v-alert  v-if="item[dateHeader.value].type=='CG'" color="orange" text class="ma-auto" rounded="0" outlined>
                            Ca gãy
                        </v-alert>
                    </div>
                    <div :key="dateHeader.value" v-else>
                        <v-alert v-if="item.defaultShiftWorkDays.includes(dateHeader.dow)" color="grey" text class="ma-auto" rounded="0" outlined style="cursor:pointer"  @click="detail($event, {item, dateHeader})">
                            {{item.defaultShiftName}} {{!!item.type?item.type: 'Không phân ca'}} 
                        </v-alert>
                        <v-alert v-else color="red" text class="ma-auto" rounded="0" outlined style="cursor:pointer" @click="detail($event, {item, dateHeader})">
                            Ngày nghỉ 
                        </v-alert>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog">
            <v-card>
                <v-toolbar color="cyan darken-3" dark >Điều chỉnh ca làm việc</v-toolbar>
                <v-card-text class="mt-4">
                    <v-form ref="form" v-model="valid">
                        <v-row no-gutters>
                            <v-col>
                                <v-select :items="[
                                    {text: 'Ca sáng', value: 'CS'},
                                    {text: 'Ca chiều', value: 'CC'},
                                    {text: 'Ca tối', value: 'CT'},
                                    {text: 'Ca gãy', value: 'CG'},
                                ]" label="Ca làm việc" v-model="form.type" :rules="[v=>!!v || 'Dữ liệu bắt buộc chọn']"></v-select>
                                <v-textarea v-model="form.note" solo outlined flat></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text v-if="isUpdate" @click="reset">
                        <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                    
                    <v-btn text v-if="!isUpdate" @click="create">
                        <v-icon color="primary">mdi-content-save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter';
import moment from 'moment/moment';

export default {
    components: {
        // myBreadCrumb,
        "data-filter": DataFilter,
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        combinedHeader() {
            return this.headers.concat(this.dateHeaders);
        },
        tableData () {
            var tableData = this.data.map(x => {
                let obj = {
                    code: x.code,
                    name: x.name,
                    defaultShift: x.defaultShift,
                    defaultShiftName: x.defaultShiftName,
                    defaultShiftHour: x.defaultShiftHour,
                    positionState: x.positionState,
                    departmentTypeCode: x.departmentTypeCode,
                    defaultShiftWorkDays: x.defaultShiftWorkDays,
                    type: x.type,
                }
                x.workSchedules.forEach(y => {
                    obj[y.dateCode] = y
                });
                return obj;
            });
            return tableData;
        }
    },
    watch: {
        // dates: {
        //     handler (val){
        //         if(val.length == 1)
        //         {
        //             var curr = new Date(val); // get current date
        //             var dayms = (24*60*60*1000) *  curr.getDay();
        //             var lastms = (24*60*60*1000) *  6;

        //             var first = new Date(curr.getTime() - dayms); // First day is the day of the month - the day of the week
        //             var last = new Date(first.getTime() + lastms); // last day is the first day + 6
        //             var firstdayStr = first.toISOString().slice(0, 10);
        //             var lastdayStr = last.toISOString().slice(0, 10);
        //             this.dates = [firstdayStr, lastdayStr];
        //         }
        //     },
        //     deep: true
        // },
        
        dialog (val) {
            val || this.close()
        },
        
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
    },
    data () {
        return {
            ROOT_URL: "WorkSchedules/",
            SHIFT_URL: "shifts/fetch",
            //breadcrumbs
            //datetime
            dates: [moment().startOf("week").format("YYYY/MM/DD"), moment().endOf("week").format("YYYY/MM/DD")],
            menu:false,

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                fromTo:[
                    moment().startOf("week").format("YYYY-MM-DD"),
                    moment().endOf("week").format("YYYY-MM-DD"),
                ],
            },
            rowCount: 0,
            valid: true,
            //form
            rules: {
                shiftRule: [
                    v => !!v || 'Chọn ca làm việc',
                ],
            },
            
            departments:[],
            departmentSearch:null,
            form: {
                code: "",
                date:"",
                employeeCode: "",
                type: "",
                note:"Phục vụ công việc.",
            },
            defaultForm: {
                code: "",
                date:"",
                employeeCode: "",
                type:"",
                note:"Phục vụ công việc.",
            },

            //page data
            shifts: [],
            options: {},
            data: [],
            isLoading: false,
            headers: [
                { text: 'Mã nhân viên', value: 'code', width: 150 },
                { text: 'Tên nhân viên', value: 'name', width: 150},
            ],
            dateHeaders: [{text: "Chọn tuần", value: undefined}],

            //dialog
            dialog: false,
            isUpdate: false,
        }
    },
    methods: {
        setDate() {
            let dayms = (24*60*60*1000);
            let first = new Date(this.filters.fromTo[0]);
            let last = new Date(this.filters.fromTo[1]);
            console.log(first, last);
            this.dateHeaders = [];
            const zeroPad = (num, places) => String(num).padStart(places, '0');
            while(first <= last){
                let dow = first.getDay();
                let localeDay =  `${first.toLocaleString("vi-VN", {weekday: 'short'})} ${zeroPad(first.getDate(), 2)}/${zeroPad(first.getMonth()+1, 2)}/${first.getFullYear()}`;
                let dayStr = first.toISOString().slice(0,10);
                let dayCode = `${first.getFullYear()}${zeroPad(first.getMonth()+1, 2)}${zeroPad(first.getDate(), 2)}`;
                this.dateHeaders.push({text: localeDay, value:dayCode, cellClass: ["cell-custom"], dow:  dow });
                first = new Date(first.getTime() + dayms);
            }
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            // this.isLoading=true;
            this.setDate();
            return http.get(this.ROOT_URL, {
                params: this.params
            }).then(res => {
                console.log(res.data)
                this.isLoading=false;
                this.data  = res.data.results;
                this.rowCount  = res.data.rowCount;
            }).catch(err => {
                throw err;
            });
        },
        fetchShift (val=null, val2=null) {
            return http.get(this.SHIFT_URL, {
                    params: {
                        hour: val,
                        positionStateCode:val2
                    }
                }).then(res => {
                    this.shifts = res.data;
                });
        },
        detail( event,data, id = "") {
            if(event){
                event.preventDefault()
            }
            this.form.date = `${data.dateHeader.value.slice(-8,-4)}-${data.dateHeader.value.slice(-4,-2)}-${data.dateHeader.value.slice(-2)}`;
            this.form.employeeCode = data.item.code;
            this.fetchShift(data.item.defaultShiftHour, data.item.positionState);
            if(id)
            {
                this.isUpdate = true;
                http.get(this.ROOT_URL + id).then(res => {
                    this.form = Object.assign({}, res.data)
                    console.log(this.form);
                }).catch(err => {throw err });
                
            }
            this.dialog = true;
        },
        reset (){
            http.delete(this.ROOT_URL+this.form.code)
            .then(res => {
                this.fetchData();
                this.close();
            }).catch(err => { throw err});
        },
        
        create (){
            this.$refs.form.validate();
            if(this.valid)
            {
                return http.post(this.ROOT_URL, this.form)
                .then(res => {
                    this.fetchData();
                    this.close();
                }).catch(err => { throw err});
            }
        },
        
        close() {
            this.isUpdate = false;
            this.dialog = false;
            this.$refs.form.resetValidation()
            this.form = Object.assign({}, this.defaultForm);
        },
    },
    created () {
        let today = new Date().toISOString().slice(0,10);
        this.dates = [today];
    },
    mounted() {
        this.$nextTick(() => {
            this.setDate();
        })
    }
}
</script>

<style scoped>
::v-deep .cell-custom {
    padding: 0 !important;
}
.v-text-field.date-sm{
      max-width: 300px;
};
</style>
