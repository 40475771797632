<template>
    <div class="shift">
        <create-dialog ref="createDialog"></create-dialog>

        <!-- Datatable -->
        <v-card>
            <v-card-title>
                Danh sách ca làm việc
            <v-spacer></v-spacer>
            <v-text-field v-model="search" @keydown.enter="fetchData" class="mr-5" style="max-width:300px"
                append-icon="mdi-magnify"
                label="Tìm kiếm"
                single-line outlined dense
                hide-details
            ></v-text-field>
            <create-button @click="openDialog"></create-button>
            </v-card-title>
            <v-data-table height="600px"
            :sort-by="['stateName']"
            :search="search"
            :headers="headers"
            :items="shifts"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.stateName`]="{ item }" >
                    <v-chip color="light-green" dark class="font-weight-bold">
                        {{ item.stateName }}
                    </v-chip>
                </template>
                <template v-slot:[`item.startHour`]="{ item }" >
                    {{ formatTime(item.startHour) }}
                </template>
                <template v-slot:[`item.endHour`]="{ item }" >
                    {{ formatTime(item.endHour) }}
                </template>
                <template v-slot:[`item.startBreak`]="{ item }" >
                    {{ formatTime(item.startBreak) }}
                </template>
                <template  v-slot:[`item.endBreak`]="{ item }" >
                    {{ formatTime(item.endBreak) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn x-small class="mx-1 elevation-2" @click="editItem(item)" text>
                        <v-icon small >
                            mdi-pencil
                        </v-icon> Chi tiết
                    </v-btn>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import createDialog from './Create'
import http from '@/components/services/http-common'
import * as moment from "moment/moment"

export default {
    name:"Shift",
    components: {
        "create-dialog": createDialog
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    methods: {
        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData() {
            return http.get('shifts').then(res => {
                this.isLoading = false;
                this.shifts = res.data;
                // this.totalItem = res.data.totalItem;
            }).catch(err => {

            })
        },
        cfmDeleteItem() {
            http.delete("shifts/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err);
            });
        },
        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate(item.code);
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
    },
    data() {
        return {
            search: "",
            totalItem: 0,
            isLoading: true,
            dialogDelete: false,
            dialog: false,
            options: {},
            shifts: [],
            headers: [
                { text: 'Mã ca', value: 'code' },
                { text: 'Nhóm Ca', value: 'stateName'},
                { text: 'Tên ca', value: 'name'},
                { text: 'Thời gian bắt đầu', value: 'startHour'},
                { text: 'Thời gian kết thúc', value: 'endHour'},
                { text: 'Thời gian bắt đầu nghỉ', value: 'startBreak' },
                { text: 'Thời gian kết thúc nghỉ', value: 'endBreak' },
                { text: 'Thao tác', value: 'actions' },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
        }
    },
    created () {
        this.fetchData();
    }

}
</script>