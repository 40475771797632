<template>
    <v-tooltip bottom color="primary" z-index="3">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" @click="$emit('click')" class="" icon color="teal darken-4">
            <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span> Chi tiết</span>
    </v-tooltip>
</template>
<script>
export default {
    
}
</script>