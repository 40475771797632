<template>
    <div class="data-filter d-flex align-center">
        <!-- #FILTER MENU -->
        <v-menu
            v-model="filter.menu" :close-on-click="true"
            :close-on-content-click="false" :nudge-width="300" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="grey lighten-5" v-bind="attrs" v-on="on" small>
                    <v-icon>mdi-filter-menu</v-icon> Lọc
                </v-btn>
            </template>

            <v-card>
                <v-form @submit.prevent="fetchData">
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                            <v-list-item-title>Bộ lọc</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                        <v-list-item v-if="filter.fromOns != undefined">
                            <v-select label="Thời gian của" :items="filter.fromOns" clearable v-model="filter.fromOn">
                            </v-select>
                        </v-list-item>
                        <v-list-item v-if="filter.from && filter.to">
                            <date-picker title="Từ" v-model="filter.from"></date-picker>
                            <date-picker title="Đến" v-model="filter.to"></date-picker>
                        </v-list-item>
                        <v-list-item>
                            <v-text-field v-model="filter.search" class="mr-2" append-icon="mdi-magnify"
                                label="Tìm kiếm" single-line>
                            </v-text-field>
                        </v-list-item>

                        <v-list-item v-if="filter.searchAttrs">
                            <v-select :items="filter.searchAttrs" v-model="filter.searchAttr" label="Tìm kiếm trên">
                            </v-select>
                        </v-list-item>
                        <v-list-item v-for="(item, i) in filter.FilterAttrs" :key="i">
                            <v-autocomplete :items="item.items" v-model="filterValues[i]" :label="item.text" item-text="name" item-value="code" clearable>
                            </v-autocomplete>
                        </v-list-item>
                        <v-list-item v-if="filter.underOnly!=undefined">
                            <v-switch v-model="underOnly" label="Chưa đủ ngày"></v-switch>
                        </v-list-item>
                    </v-list>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    
                    <v-btn
                        text
                        @click="filter.menu = false"
                    >
                        Đóng
                    </v-btn>

                    <v-btn color="primary" text type="submit" >
                        Lọc
                    </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import DatePicker from "../components/DatePicker";

export default {
    name: "DataFilter",
    components: {
        'date-picker': DatePicker
    },
    props: ["value", "filter"],
    data () {
        return {
            outputParams: {},
            filterValues:[],
            underOnly:false
        }
    },
    watch: {

    },
    methods: {
        fetchData() {
            this.filterValues = Array.from(this.filterValues, item => item || "");
            this.outputParams =  { ...this.value,
                                searchs: [this.filter.search].concat(this.filterValues), //string
                                searchAttrs: [this.filter.searchAttr].concat(this.filter.FilterOn), //string
                                from: this.filter.fromOn ? this.filter.from : "",
                                to: this.filter.fromOn ? this.filter.to : "",
                                fromOn: this.filter.fromOn,
                                underOnly: this.underOnly
                                };
            console.log(this.outputParams);
            this.$emit("input", this.outputParams);
            this.$emit("fetchData", this.outputParams);
        }
    },
    created () {
    }
}

    // ************* CHU THICH *******************
    // params: { *DU LIEU OUT PUT
    //     sortBy: [],
    //     sortDesc: [],
    //     page: "",
    //     itemsPerPage: "",
    //     searchs: [], // CHUOI SEARCH
    //     searchAttrs: [], # TRUONG SEARCH
    // },

    // filter: {
    //     menu: false, # CLOSE - OPEN MENU
    //     search: "",
    //     searchAttr: "", TRUONG SEARCH DUOC CHON
    //     searchAttrs: undefined, SEARCH TREN TRUONG NAO
    //     from: undefined, THOI GIAN
    //     to: undefined, THOI GIAN SEARCH
    //     timeOn: "",
    //     FilterAttrs: [], #BIEN ARRAY ITEM FILTER [{}, {}, ...]
    //     FilterOn:[], #BIEN FILTER TREN TRUONG NAO
    // },
</script>