<template>
    <div class="overtime-type">
        <breadcrumbs :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openItem" >
        </breadcrumbs>
        <create-dialog v-model="dialogState" @closeDialog="closeHandler" @fetchData="fetchData" ref="dialog"></create-dialog>
        <v-card>
            <v-card-title>
                Danh sách loại tăng ca
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Tìm kiếm"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-data-table height="600px"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            :search="search"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn x-small class="mx-1 elevation-2" @click="editItem(item)" text>
                        <v-icon small >
                            mdi-pencil
                        </v-icon> Sửa
                    </v-btn>
                    <v-btn x-small class="mx-1" @click="deleteItem(item)" color="error">
                        <v-icon small >
                            mdi-delete
                        </v-icon>Xóa
                    </v-btn>
                </template>

                
                <template v-slot:[`item.hasOvertime`]="{ item }">
                    <v-icon v-if="item.hasOvertime ==1">
                        mdi-check
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>

import breadcrumbs from '@/components/BreadCrumb'
import createDialog from './Create'
import http from '@/components/services/http-common'

export default {
    name: "OvertimeType",
    components: {
        breadcrumbs,
        "create-dialog": createDialog,
    },
    computed: {

    },
    watch: {

    },
    data() {
        return {
            rootUrl: "overtimeTypes",
            isLoading: true,
            dialogCreate: false,
            dialogUpdate: false,
            dialogState: {
                open: false,
                item: undefined,
                isUpdate: false
            },
            search: "",
            selectedItem: {},
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Quản lí nhân viên ',
                disabled: true,
                to: {
                    path: "/hr/employees",
                    }
                },
            ],
            
            icon: {
                icon: "mdi-plus-thick",
                to: "employee.create"
            },
            headers: [
                { text: 'Thao tác', value: 'actions', width:"200px", sortable: false },
                { text: 'Mã loại tăng ca', value: 'code', width:"200px", sortable: true },
                { text: 'Tên loại tăng ca', value: 'name', width:"300px", sortable: true},
                // { text: 'Hệ số', value: 'scale', width:"200px", sortable: false },
                // { text: 'Hệ số ngày nghỉ', value: 'dayOffScale', width:"200px", sortable: false },
                // { text: 'Hệ số ngày lễ', value: 'holidayScale', sortable: false },
                { text: 'Được tính tăng ca', value: 'hasOvertime', sortable: false },
            ],
            items: [],
            dialogDelete: false,

        }
    },
    methods: {
        closeHandler() {
            this.dialogState.open = false;
            this.dialogState.isUpdate = false;
            this.dialogState.item = {};
        },
        fetchData () {
            http.get("overtimetypes?all=1").then(res => {
                this.isLoading = false;
                this.items = res.data.items;
            }).catch(err => {

            })
        },
        openItem (item) {
            this.dialogState.open = true;
        },
        editItem (item) {
            this.dialogState.open = true;
            this.dialogState.isUpdate = true;
            this.dialogState.item = item;
        },
        cfmDeleteItem() {
            http.delete(this.rootUrl +'/' + this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err);
            });
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
    },
    created() {
        this.fetchData();
    }
}
</script>
