import http from '@/components/services/http-common'
export const ORIGIN_GETS = 'origins';
export const CITY_FETCH = 'cities/fetch';

class ApiCaller {
    fetchOrigins() {
        return http.get(ORIGIN_GETS);
    }
    fetchCity(){
        return http.get(CITY_FETCH);
    }
    
    changeContractStatus(id, status, note){
        return http.get("contractsv2/change-status/"+id, {
            params: {
                status: status,
                note: note
            }
        });
    }
}
export default new ApiCaller();