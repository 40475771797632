<template>
  <v-dialog
  :value="dialog"
    persistent :overlay="false"
    max-width="600px"
    transition="dialog-transition"
  >
  
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        :complete="completeStep1"
        step="1" >
        Thông tin lịch trực {{select}}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="completeStep2"
        step="2" >
        Import lịch trực
      </v-stepper-step>
      
      <v-stepper-step
        :complete="completeStep3"
        step="3" >
        Preview
      </v-stepper-step>

    </v-stepper-header>
    
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="mb-12"
          flat
        >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field type="date" label="Tháng" hide-details="auto" outlined dense v-model="itSchedule.month"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined label="Mô tả" placeholder="Ghi chú" persistent-placeholder hide-details="auto" v-model="itSchedule.description"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        </v-card>
        
        <v-btn
          color="success"
          @click="stepperSubmit"
        >
          {{continueText}}
        </v-btn>

        <v-btn outlined class="float-right" @click="$emit('close'), resetForm1()">
          Thoát
        </v-btn>
      </v-stepper-content>
      
      <v-stepper-content step="2">
        <v-card
          class="mb-12"
          flat
        >
        <v-card-text>
          <!-- info -->
          <v-row class="mb-6">
            <v-col cols="12">
              <v-text-field outlined dense hide-details="auto" label="Tháng" readonly v-model="info.month"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea dense outlined hide-details="auto" label="Mô tả" readonly v-model="info.description"></v-textarea>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field outlined dense hide-details="auto" label="Tạo bởi" readonly v-model="info.createdBy"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined dense hide-details="auto" label="Tạo vào" readonly v-model="info.createdAt"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined dense hide-details="auto" label="Sửa bởi" readonly v-model="info.updatedBy"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined dense hide-details="auto" label="Sửa vào" readonly v-model="info.updatedAt"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-file-input label="File Import" outlined hide-details="auto" dense v-model="importTemplate"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        </v-card>
        <v-btn
          color="warning"
          @click="setStep(step-1), step=step-1"
        >
          Quay lại
        </v-btn> 
        <v-btn class="mx-3"
          color="success"
          @click="stepperSubmit"
        >
          {{continueText}}
        </v-btn>

        <v-btn outlined class="float-right" @click="$emit('close'), resetForm1(), resetForm2()">
          Thoát
        </v-btn>
      </v-stepper-content>
      
      <v-stepper-content step="3">
        <v-card
          class="mb-12"
          flat
        >
          <v-card-text>
            <v-text-field block outlined dense :hide-details="true" label="Tìm kiếm" v-model="previewSearch"></v-text-field>
            <v-data-table dense :headers="previewDataHeaders" :items="previewDatas" :search="previewSearch">
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-btn
          color="warning"
          @click="setStep(step-1), step=step-1"
        >
          Quay lại
        </v-btn> 
        <v-btn class="mx-3"
          color="success"
          @click="generateOr"
        >
          Sinh phiếu trực hệ thống
        </v-btn>

        <v-btn outlined class="float-right" @click="$emit('close'), resetForm1(), resetForm2()">
          Thoát
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import ItScheduleService from '../../services/ItScheduleService'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Number,
      default: null,
    }
  },
  watch: {
    select(val) {
      console.log(this.select, 'ITEM#####')
      if(val){
        this.step = 2
        this.fetchItem()
      }
    }
  },
  computed: {
    completeStep1() {
      return this.step> 1
    },
    completeStep2() {
      return this.step > 2
    },
    completeStep3() {
      return this.step > 3
    },
    continueText() {
      if(this.step==1) {
        return "Tiếp tục"
      }
      if(this.step ==2) {
        return "Import"
      }
      return ""
    }
  },
  data() {
    return {
      step: 1,
      itSchedule: {
        month: null,
        description: ''
      },
      info: {
        id: null,
        month: '',
        description: '',
        createdBy: '',
        createdAt: '',
        updatedBy: '',
        updatedAt: '',
      },
      importTemplate: null,

      previewDataHeaders: [
        {text: 'Mã NS', value: 'employeeCode'},
        {text: 'Ngày trực', value: 'workDate'},
        {text: 'Loại', value: 'type'},
        {text: 'Ghi chú', value: 'note'},
      ],
      previewDatas: [],
      previewSearch:'',
    }
  },
  methods: {
    stepperSubmit() {
      if(this.step == 1){
        return this.itScheduleService.postOrPut(this.itSchedule).then(d=> {
          this.info.id = d.data.id
          this.info.month = moment(d.data.month).format('DD/MM/YYYY')
          this.info.description = d.data.description
          this.info.createdBy = d.data.createdBy
          this.info.createdAt = moment(d.data.createdAt).format('DD/MM/YYYY')
          this.info.updatedBy = d.data.updatedBy
          this.info.updatedAt = moment(d.data.updatedAt).format('DD/MM/YYYY')
        }).then(() => {
          this.step = 2
          this.setStep(2)
        })
        
      }
      if(this.step == 2) {
        this.importExcel().then(() => {
          this.step = 3
          this.setStep(3)
        });
      }
    },

    setStep(step) {
      let data = {
        id: this.info.id,
        step: step
      }
      this.itScheduleService.setStep(data)
    },

    resetForm1() {
      this.itSchedule.month = null
      this.itSchedule.description = ''
    },
    
    resetForm2() {
      this.info.id = null
      this.info.month = ''
      this.info.description = ''
      this.info.createdBy = ''
      this.info.createdAt = ''
      this.info.updatedBy = ''
      this.info.updatedAt = ''
    },
    
    importExcel() {
        var formData = new FormData();
        formData.append("files", this.importTemplate);
        return this.itScheduleService.import(formData, this.info.id).then(d => {
          this.previewDatas = d.data
        });
    },

    generateOr() {
      return this.itScheduleService.generateOr(this.info.id);
    },

    fetchItem() {
      this.itScheduleService.get(this.select).then(d => {
        this.info.id = d.id
        this.info.month = d.month
        this.info.description = d.description
        this.info.createdBy = d.createdBy
        this.info.createdAt = d.createdAt
        this.info.updatedBy = d.updatedBy
        this.info.updatedAt = d.updatedAt

        this.step = d.step

        this.previewDatas = d.previewDatas
      })
    }
  },
  itScheduleService: null,
  created() {
    this.itScheduleService = new ItScheduleService()
  }

}
</script>