<template>
    <v-dialog v-model="dialog" width="600px">
        <v-card>
            <v-card-title class="secondary white--text">{{isEdit? "Thay đổi ": "Thêm "}}hệ số
                <v-spacer></v-spacer>
                <v-icon dark @click="dialog=false">mdi-close</v-icon>
            </v-card-title>
            <v-form @submit.prevent="save" v-model="valid" ref="form">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form.name" label="Tên" :rules="[v=>!!v||'Nhập dữ liệu',
                                                                                    v=> v.length<=50 ||'Dữ liệu dài hơn 50 kí tự']"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="form.normal" label="Tăng ca bình thường"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="form.dayOff" label="Tăng ca ngày off"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="form.holiday" label="Tăng ca lễ"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="form.isActive" label="Hoạt động"></v-checkbox>
                        </v-col>
                        <v-col cols=" 12">
                            <v-textarea v-model="form.note" label="Ghi chú" outlined></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" type="submit">Lưu</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    
    props:["value", "item"],
    computed:{
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            rootURL: "overtimeScales/",
            valid: true,
            form:{
                name:"",
                normal:1.5,
                dayOff:1.5,
                holiday:1.5,
                isActive:"",
                note:"",
            },
            default:{
                name:"",
                normal:1.5,
                dayOff:1.5,
                holiday:1.5,
                isActive:"",
                note:"",
            }
        }
    },
    methods:{
        save() {
            this.$refs.form.validate();
            if(this.valid)
            {
                return http.post(this.rootURL, this.form).then(res => {
                    this.$emit("fetch");
                    this.dialog = false;
                })

            }
        }
    }
}
</script>