<template>
    <div class="request-list">
        <myBreadCrumb :urls="JSON.stringify(breadcrumbs)"></myBreadCrumb>
        <v-card>
            <v-tabs v-model="mainTab" color="teal" right>
                <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                <v-tab>
                    Tăng ca
                </v-tab>
                <v-tab>
                    Phép
                </v-tab>
                <v-tab>
                    Công tác
                </v-tab>
            </v-tabs>
            
            <v-card-title> Danh sách yêu cầu
                <v-spacer></v-spacer>
                <data-filter v-model="params" :filter="filter"></data-filter>
                <!-- <v-select label="Trong vòng" class="limit"
                    :items="limitList" v-model="limit" @change="fetchData"
                ></v-select> -->
            </v-card-title>
            <v-tabs-items v-model="mainTab">
                <v-tab-item eager>
                    <v-data-table
                    :mobile-breakpoint="0"
                    :headers="headers0"
                    :items="datas"
                    :options.sync="options"
                    :server-items-length="totalItem"
                    :loading="isLoading"
                    loading-text="Đang tải dữ liệu...">
                    
                        <template v-slot:[`item.actions`]="{ item }">
                            <!-- ********* Edit ****** -->
                            <v-icon
                                v-if="item.status == 0"
                                small
                                class="mr-2"
                                color="success"
                                @click="accept(item)"
                            >
                                mdi-check
                            </v-icon>

                            <!-- ********* Xoa ****** -->
                            <v-icon
                                v-if="item.status == 0"
                                small
                                color="error"
                                @click="decline(item)"
                            >
                                mdi-close
                            </v-icon>

                            <!-- ********* Undo ****** -->
                            <v-icon
                                v-if="item.status != 0"
                                small
                                color="secondary"
                                @click="undo(item)"
                            >
                                mdi-undo
                            </v-icon>
                        </template>

                        <template
                            v-slot:[`item.status`]="{ item }"
                        >
                            <v-chip class="ma-2" :color="status(item.status).color" label dark small>
                            <v-icon left>
                                mdi-clock-time-nine-outline
                            </v-icon>
                            {{ status(item.status).text }}
                            </v-chip>
                        </template>

                        <!-- HEADER CUSTOM FILTER -->
                        <template v-slot:[`header.status`]="{ header }">
                            <select v-model="header.filter.search" @click.prevent="preventHeaderHandler($event)" @change="fetchData">
                                <option disabled value="">Trạng thái</option>
                                <option value="0">Chờ duyệt</option>
                                <option value="1">Chấp nhận</option>
                                <option value="2">Từ chối</option>
                            </select>
                            <!-- <input type="text" @click.prevent="preventHeaderHandler($event)" @keydown.enter="fetchData"
                                                v-model="header.filter.search" 
                                                :placeholder="header.text" style="display:inline; width: 70%"> -->
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item eager>
                    <v-data-table 
                    :mobile-breakpoint="0"
                    :headers="headers1"
                    :items="datas"
                    :options.sync="options"
                    :server-items-length="totalItem"
                    :loading="isLoading"
                    loading-text="Đang tải dữ liệu...">
                    
                        <template v-slot:[`item.actions`]="{ item }">

                            <!-- ********* Edit ****** -->
                            <v-icon
                                v-if="item.status == 0"
                                small
                                class="mr-2"
                                color="success"
                                @click="accept(item)"
                            >
                                mdi-check
                            </v-icon>

                            <!-- ********* Xoa ****** -->
                            <v-icon
                                v-if="item.status == 0"
                                small
                                color="error"
                                @click="decline(item)"
                            >
                                mdi-close
                            </v-icon>

                            <!-- ********* Undo ****** -->
                            <v-icon
                                v-if="item.status != 0"
                                small
                                color="secondary"
                                @click="undo(item)"
                            >
                                mdi-undo
                            </v-icon>
                        </template>

                        <template
                            v-slot:[`item.status`]="{ item }"
                        >
                            <v-chip class="ma-2" :color="status(item.status).color" label dark small>
                            <v-icon left>
                                mdi-clock-time-nine-outline
                            </v-icon>
                            {{ status(item.status).text }}
                            </v-chip>
                        </template>

                        <!-- HEADER CUSTOM FILTER -->
                        <template v-slot:[`header.status`]="{ header }">
                            <select v-model="header.filter.search" @click.prevent="preventHeaderHandler($event)" @change="fetchData">
                                <option disabled value="">Trạng thái</option>
                                <option value="0">Chờ duyệt</option>
                                <option value="1">Chấp nhận</option>
                                <option value="2">Từ chối</option>
                            </select>
                            <!-- <input type="text" @click.prevent="preventHeaderHandler($event)" @keydown.enter="fetchData"
                                                v-model="header.filter.search" 
                                                :placeholder="header.text" style="display:inline; width: 70%"> -->
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item eager>
                    <v-data-table 
                    :mobile-breakpoint="0"
                    :headers="headers2"
                    :items="datas"
                    :options.sync="options"
                    :server-items-length="totalItem"
                    :loading="isLoading"
                    loading-text="Đang tải dữ liệu...">
                    
                        <template v-slot:[`item.actions`]="{ item }">

                            <!-- ********* Edit ****** -->
                            <v-icon
                                v-if="item.status == 0"
                                small
                                class="mr-2"
                                color="success"
                                @click="accept(item)"
                            >
                                mdi-check
                            </v-icon>

                            <!-- ********* Xoa ****** -->
                            <v-icon
                                v-if="item.status == 0"
                                small
                                color="error"
                                @click="decline(item)"
                            >
                                mdi-close
                            </v-icon>

                            <!-- ********* Undo ****** -->
                            <v-icon
                                v-if="item.status != 0"
                                small
                                color="secondary"
                                @click="undo(item)"
                            >
                                mdi-undo
                            </v-icon>
                        </template>
                        
                        <template v-slot:[`item.confirmedDate`]="{ item }">
                            {{ item.confirmedDate == "01/01/0001" ? "" : item.confirmedDate}}
                        </template>
                        <template v-slot:[`item.status`]="{ item }" >
                            <v-chip class="ma-2" :color="status(item.status).color" label dark small>
                            <v-icon left>
                                mdi-clock-time-nine-outline
                            </v-icon>
                            {{ status(item.status).text }}
                            </v-chip>
                        </template>

                        <!-- HEADER CUSTOM FILTER -->
                        <template v-slot:[`header.status`]="{ header }">
                            <select v-model="header.filter.search" @click.prevent="preventHeaderHandler($event)" @change="fetchData">
                                <option disabled value="">Trạng thái</option>
                                <option value="0">Chờ duyệt</option>
                                <option value="1">Chấp nhận</option>
                                <option value="2">Từ chối</option>
                            </select>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>
<script>

import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import * as moment from "moment/moment";
import EventBus  from "@/components/services/EventBus";
import DataFilter from "@/components/DataFilter"

export default {
    name: "RequestList",
    components: {
        myBreadCrumb,
        "data-filter": DataFilter,
    },
    computed: {
        searchs() {
            let header = null;
            switch(this.mainTab) {
                case 0:{
                    header = this.headers0;
                    break;
                }
                case 1:{
                    header = this.headers1;
                    break;
                }
                case 2:{
                    header = this.headers2;
                    break;
                }
            }

            return header.filter(a => (a.filter)).map(a => {
                return a.filter.search;
            });
        },
        searchAttrs() {
            let header = null;
            switch(this.mainTab) {
                case 0:{
                    header = this.headers0;
                    break;
                }
                case 1:{
                    header = this.headers1;
                    break;
                }
                case 2:{
                    header = this.headers2;
                    break;
                }
            }
            return header.filter(a => (a.filter)).map(a => {
                return a.value;
            });
        },
        route (){
            let route = "";
            switch(this.mainTab) {
                case 0:{
                    route = "overtimeRequests";
                    break;
                }
                case 1:{
                    route = "leaveRequests";
                    break;
                }
                case 2:{
                    route = "businessRequests";
                    break;
                }
            }
            return route;
        }
    },
    watch: {
        mainTab: {
            handler (val) {
                this.data=[];
                this.fetchData();
            }
        },
        // options: {
        //     handler (val) {
        //         this.fetchData();
        //     },
        //     deep: true,
        // },
    },
    data () {
        return {
            rootUrl: "overtimerequests",
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Xét duyệt yêu cầu ',
                disabled: true,
                },
            ],
            //tab
            mainTab: 0,
            limitList: [
                {text: "1 Tháng", value: 1},
                {text: "3 Tháng", value: 3},
                {text: "5 Tháng", value: 5},
                {text: "Tất cả", value: 0},
            ],
            limit: 1,
            isLoading: true,
            totalItem:0,
            headers0: [
                { text: 'Thao tác', value: 'actions', align: "center", width: "100px"},
                { text: 'Mã đơn', value: 'code', width: "120px" },
                { text: 'Trạng thái', align:"center", value: 'status', width: "120px", 
                    filter: {
                        search: 0,
                    }
                },
                { text: 'Loại yêu cầu', align: 'start', value: 'overtimeTypeCode', align: "center", width: "200px"},
                { text: 'Tên nhân viên', align: 'center', value: 'employeeName', width: "200px"},
                { text: 'Ngày gửi', align: 'start', value: 'sentDate', width: "150px"},
                { text: 'Ngày yêu cầu', align: 'start', value: 'date', width: "150px"},
                { text: 'Người xác nhận', align: 'start', value: 'confirmedBy', width: "150px"},
                { text: 'Ngày xác nhận', align: 'start', value: 'confirmedByDate', width: "150px"},

                { text: 'Thời gian tăng ca', align: 'start', value: 'length', width: "100px"},
                { text: 'Ghi chú', align: 'center', value: 'note'},
            ],
            headers1: [
                { text: 'Thao tác', value: 'actions', align: "center", width: "100px"},
                { text: 'Mã đơn', value: 'code', width: "120px" },
                { text: 'Trạng thái', align:"center", value: 'status', width: "120px", 
                    filter: {
                        search: 0,
                    }
                },
                { text: 'Tên nhân viên', align: 'center', value: 'employeeName', width: "200px"},
                { text: 'Loại yêu cầu', align: 'start', value: 'leaveAmountType', align: "center", width: "200px"},
                { text: 'Ngày gửi', align: 'start', value: 'sentDate', width: "150px"},
                { text: 'Phép từ', align: 'start', value: 'from', width: "150px"},
                { text: 'Phép đến', align: 'start', value: 'to', width: "150px"},
                { text: 'Xác nhận', align: 'start', value: 'confirmBy', width: "150px"},
                { text: 'Ngày xác nhận', align: 'start', value: 'confirmByDate', width: "150px"},
                { text: 'Lý do', align: 'center', value: 'note'},
                // { text: 'Ghi chú', align: 'center', value: 'note', width: "300px"},
            ],
            headers2: [
                { text: 'Thao tác', value: 'actions', align: "center", width: "100px"},
                { text: 'Mã đơn', value: 'code', width: "120px" },
                { text: 'Trạng thái', align:"center", value: 'status', width: "120px", 
                    filter: {
                        search: 0,
                    }
                },
                
                { text: 'Ngày gửi', align: 'start', value: 'sentDate', width: "120px"},
                { text: 'Ngày công tác', align: 'start', value: 'date', width: "150px"},
                { text: 'Người gửi', align: 'center', value: 'name', width: "200px"},
                { text: 'Người xác nhận', align: 'center', value: 'confirmedName', width: "200px"},
                { text: 'Ngày xác nhận', align: 'center', value: 'confirmedDate', width: "200px"},
                { text: 'Loại yêu cầu', align: 'start', value: 'businessType', align: "center", width: "200px"},
                { text: 'Thời gian tăng ca', align: 'start', value: 'amountOvertime', width: "150px"},
                { text: 'Ghi chú', align: 'start', value: 'note', width: "300px"},
            ],
            datas: [],
            options: { sortBy: ["date"], sortDesc: [true], tab: 0},
            form: {
                search:"",
                searchArr: [],
            },

            
            //FILTER
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],

                fromOn: "",
                from: new Date(new Date().setDate(new Date().getDate()-30)).toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
            },

            filter: {
                menu: false,
                search: "",
                searchAttr: "EmployeeName",
                department: "",

                from: new Date(new Date().setDate(new Date().getDate()-30)).toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
                fromOns: [
                    {text: "Ngày gửi", value: "sentDate"}
                ],
                fromOn: "workDate",

                searchAttrs: [
                    {text: "Tên nhân viên", value: "EmployeeName"},
                    {text: "MSNV", value: "EmployeeCode"},
                ],
                FilterAttrs: [
                    {text: "Phòng", items: [] }
                ],
                FilterOn:["DepartmentCode"],
            },
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        preventHeaderHandler(e)
        {
            e.stopPropagation();
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        status(value) {
            let result = {};
            switch(value) {
                case 0: {
                    result.text = "Chờ duyệt";
                    result.color = "warning"
                    break;
                }
                case 1: {
                    result.text = "Đã duyệt"
                    result.color = "success"
                    break;
                }
                case 2: {
                    result.text = "Từ chối"
                    result.color = "error"
                    break;
                }
                default:{
                    result.text = "unknown"
                    break;
                }
            }
            return result;
        },
        fetchData() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            console.log(sortDesc);
            // var sortByArr = JSON.parse(JSON.stringify(sortBy));
            // var sortDescArr = JSON.parse(JSON.stringify(sortDesc));
            console.log(this.options)
            this.options.tab = this.mainTab;
            this.form.searchs = this.searchs;
            this.form.searchAttrs = this.searchAttrs;
            if(page != undefined || itemsPerPage != undefined)
                http.get(this.route + "/admin" + '?page='+ page + '&pageSize=' + itemsPerPage + '&sortBy=' + sortBy+ '&sortDesc=' + sortDesc+ '&limit=' + this.limit, {
                                params: this.form
                            }).then(res => {
                    console.log(res.data);
                    this.isLoading = false;
                    this.datas = res.data.items;
                    this.totalItem = res.data.totalItem;
                }).catch(err => {
                    console.log(err);
                });
        },
        accept(item) {
            http.get(this.route + "/accept/" + item.code).then(res => {
                let eventObj = {
                    color: "success",
                    message: "Cập nhật thành công",
                    toast: true,
                };
                this.messageToast(eventObj);
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            http.get(this.route + "/decline/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            http.get(this.route + "/undo/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        }
    },
    mounted () {
        this.fetchData();
    }
}
</script>

<style scoped>
.v-text-field{
      max-width: 250px;
}
</style>