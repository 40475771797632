<template>
    <div class="createUser">
        <v-dialog v-model="dialog" max-width="800px" scrollable>
            <v-card>
                <v-card-title>
                {{ formTitle }} 
                <v-spacer></v-spacer>
                <v-icon small @click="dialog=false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <form @submit.prevent="createData" novalidate id="formUser">
                        <v-row>
                            <v-col cols="12"><small class="error--text">Các ô có (*) là thông tin bắt buộc</small></v-col>
                            <v-col cols="6">
                                <v-autocomplete v-model="form.employeeCode" :search-input="employeeSearch" @update:search-input="fetchEmployee" :label="$t('view.user.employee') + ' *'" 
                                 hide-no-data :items="employees" item-text="name" item-value="code" :disabled="isUpdate">
                                    <template v-slot:item="data">
                                        <strong>{{ data.item.code }}: </strong> {{` ${data.item.name} - ${data.item.department}`}}
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="form.UserName"
                                :label="$t('view.user.user_name') +' *'" required :rules="[v=>!!v || 'Nhập dữ liệu']" ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <span v-if="form.allStore" class="red--text"><i>Được quyền thao tác trên tất cả các phòng ban</i></span>
                                <v-autocomplete v-else
                                :items="formData.departments"
                                v-model="form.Managers" small-chips deletable-chips
                                :label="$t('view.user.responsibled_department')" multiple
                                item-value="code"
                                item-text="name"
                                >
                                
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title ><strong>{{ data.item.code }}: </strong> {{` ${data.item.name} `}}</v-list-item-title>
                                            <v-list-item-subtitle >{{ data.item.description }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                :items="formData.roles"
                                v-model="form.RoleCodes" small-chips deletable-chips
                                :label="$t('view.user.permission')" multiple
                                required
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                v-model="form.Password"
                                :label="$t('view.user.password') + ' *'"
                                type="password"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                v-model="form.ConfirmPassword"
                                :label="$t('view.user.confirm_password') + ' *'"
                                type="password"
                                required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox
                                    v-model="form.IsActive"
                                    :label="form.IsActive?$t('view.user.actived') :$t('view.user.deactived') "
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" v-if="this.isUpdate">
                                <v-switch v-model="form.allowedStatistic" :true-value="1" :false-value="0" @change="enableAllowedStatistic" label="Hiển thị test data"></v-switch>
                                <small class="text-secondary">Developer feature</small>
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                >
                    Thoát
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    type="submit" form="formUser"
                >
                    Lưu
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="orange" @click="resetDevice"> <v-icon>mdi-refresh</v-icon> Reset Thiết bị</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import EmployeeDialog from './EmployeeDialog';
import ApiServices from '@/components/services/ApiServices';

export default {
    name: "UserCreateDialog",
    components: {
        "employee-dialog": EmployeeDialog
    },
    props:["value", "employee"],
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật nhân viên" : "Tạo mới nhân viên";
        },
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    watch: {
        dialog (val) {
            if(val){
                if(!!this.employee)
                {
                    this.fetchUpdate(this.employee);
                    this.isUpdate = true;
                }
                return val;
            }else {
                this.close();
            }
        },
    },
    data () {
        return {
            //URL
            FETCH_ROLE_URL: "roles/fetch",
            DEPARTMENT_URL: "departments",

            //PAGE DATA
            item: undefined,
            employeeDialog: false,
            isUpdate: false,
            employees:[],
            employeeSearch:null,
            form: {
                UserName: "",
                IsActive: false,
                RoleCodes: [],
                employeeCode: null,
                EmployeeName: "",
                Password: "",
                ConfirmPassword: "",
                Managers: "",
                allStore: false,
                allowedStatistic: 0
            },
            defaultForm: {
                UserName:"",
                IsActive: false,
                RoleCodes: [],
                employeeCode: null,
                EmployeeName: "",
                Password: "",
                ConfirmPassword: "",
                Managers: "",
                allStore: false,
                allowedStatistic: 0
            },
            formData: {
                Employees: [],
                roles: [],
            }
        }
    },
    methods: {
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.form = Object.assign({}, this.defaultForm);
        },
        rowHandler (val){
            this.employeeDialog = false;
            this.form.employeeCode = val.code;
            this.form.EmployeeName = val.name;
            this.form.UserName = val.phone;
        },
        enableAllowedStatistic(val) {
            console.log(this.item)
            return http.get('users/allowed-statistic', {
                params: {
                    value: val,
                    userCode: this.item.code
                }
            }).then(res => {
                console.log(res)
            })
        },
        fetchUpdate (code){
            let url = "users/" + code;
            if( !!this.employee)
                url = `users/employee/${this.employee}`;
            return http.get(url).then(res => {
                let data = res.data;
                this.item = {code: data.code};
                this.form.IsActive = data.isActive;
                this.form.UserName = data.username;
                this.form.employeeCode = data.employeeCode;
                this.form.allStore = data.allStore;
                this.form.allowedStatistic = data.allowedStatistic;

                this.fetchRoles();
                this.form.RoleCodes = data.roleCodes == "" ? [] : data.roleCodes.split(",");
                this.form.Managers = data.managers == "" ? [] : data.managers.split(",");
            }).catch(err => {
                this.isUpdate=false;
                console.log(err);
            });
        },
        
        fetchManageDepartment() {
            return http.get(this.DEPARTMENT_URL + "?dropDown=1")
            .then(res => {
                console.log(res.data);
                this.formData.departments = res.data;
            })
            .catch(err => {
                console.log(err);
            })
        },
        
        createData () {
            if(!this.isUpdate)
                return http.post('users', this.form)
                .then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                })
                .catch((err)=> {
                    console.log("err", err.response);
                })
            else{
                return http.put('users/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        },

        fetchRoles () {
            http.get(this.FETCH_ROLE_URL).then(res => {
                this.formData.roles = res.data.map(function(obj) {
                return {
                    text: obj.roleName,
                    value: obj.code,
                    }
                });
            }).catch(err =>{
                console.log("error connection");
            });
        },

        fetchEmployee(val){
            ApiServices.fetchEmployee(val).then(res=>{
                this.employees = res.data;
            })
        },
        resetDevice()
        {
            let url = `users/reset-firebase/${this.item.code}`
            return http.get(url);
        }
    },
    created () {
        // this.fetchEmployee();
        this.fetchEmployee(this.employee);
        this.fetchRoles();
        this.fetchManageDepartment();
    }
    
}
</script>