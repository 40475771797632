<template>
    <div class="createWard">
        <v-dialog v-model="dialog" max-width="80vw">
            <v-card>
                <v-form @submit.prevent="createData" ref="form">
                    <v-card-title>
                    <span class="headline"> {{ formTitle }} </span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                :disabled="isUpdate"
                                :rules="rules.codeRules"
                                v-model="form.Code"
                                label="Mã"
                                placeholder="Mã tự sinh nếu để trống"
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-autocomplete
                                    v-model="form.CityCode"
                                    :items="formData.cities"
                                    chips
                                    small-chips
                                    label="Thành phố"
                                    @change="fetchDistricts"
                                ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-autocomplete
                                    v-model="form.DistrictCode"
                                    :items="formData.districts"
                                    chips
                                    small-chips
                                    label="Quận huyện" 
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                :rules="rules.nameRules"
                                v-model="form.Name"
                                label="Tên"
                                required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    >
                        Thoát
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        type="submit"
                    >
                        Lưu
                    </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common'
export default {
    name: "DistrictCreateDialog",
    components:{

    },
    data() {
        return {
            //*************** Validate **************
            rules: {
                nameRules:[ 
                    v => !!v || 'Tên bắt buộc nhập',
                    v => v.length > 3 || 'Phải nhiều hơn 3 kí tự',
                ],
                codeRules:[ 
                    v => v ? v.length > 3 || 'Phải nhiều hơn 3 kí tự': true,
                ],
            },
            
            //************* Form config */
            rootUrl: "wards",
            valid: false,
            item: undefined,
            isUpdate: false,
            dialog: false,
            IDdateMenu: false,
            HireMenu: false,
            formData: {
                cities: [],
                districts: []
            },
            form: {
                Code: "",
                Name:"",
                CityCode: "",
                DistrictCode: "",
            },
            defaultForm: {
                Code: "",
                Name:"",
                CityCode: "",
                DistrictCode: "",
            },
        };
    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật phường xã" : "Tạo mới phường xã";
        }
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
    },
    methods: {
        fetchUpdate (){
            http.get(this.rootUrl + "/"+ this.item.code).then(res => {
                let data = res.data;
                this.form.Name = data.name;
                this.form.Code = data.code;
                this.form.CityCode = data.cityCode;
                this.fetchDistricts();
                this.form.DistrictCode = data.districtCode;
                console.log(data)
            }).catch(err => {
                console.log(err);
            });
        },
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        fetchCities() {
            http.get("cities?dropDown=1").then(res => {
                this.formData.cities = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
                console.log(res.data)
                
            }).catch(err =>{
                console.log("error connection");
            });
        },
        fetchDistricts() {
            http.get("cities/fetch-district/"+ this.form.CityCode).then(res => {
                this.formData.districts = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
                console.log(res.data)
                
            }).catch(err =>{
                console.log("error connection");
            });
        },
        createData () {
            this.$refs.form.validate()
            if(!this.isUpdate)
                http.post(this.rootUrl + "/", this.form).then(res => {
                    this.dialog = false;
                    this.$emit("needFetch");
                }).catch( (err) => {
                    console.log("co loi xay ra");
                })
            else{
                http.put(this.rootUrl + '/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    this.$emit("needFetch");
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        }
    },
    mounted() {
        this.fetchCities();
    }
}
</script>
