<template>
    <div class="createLeaveRequest">
        <v-dialog v-model="dialog" max-width="700px" scrollable>
            <v-card>
                <v-card-title>
                    Tạo kế hoạch nghỉ
                    <v-spacer></v-spacer>
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-form @submit.prevent="apply" v-model="valid" ref="form" id="form">
                        <v-container>
                            <v-row>
                                <v-col cols="6" md="6">
                                    <v-select dense v-model="form.staticSymbol" 
                                    label="Ký hiệu gốc" :items="formData.staticSymbols" :rules='[v=>!!v || "Chọn ký hiệu"]'
                                    required></v-select>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field dense
                                    v-model="form.symbol"
                                    label="Ký hiệu"
                                    :rules='[v=>!!v || "Nhập ký hiệu"]'
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field dense
                                    v-model="form.name"
                                    label="Tên kế hoạch"
                                    :rules='[v=>!!v || "Nhập tên của phép"]'
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field dense
                                    v-model="form.keyword"
                                    label="Từ khoá"
                                    :rules='[v=>!!v || "Nhập từ khóa"]'
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-switch v-model="form.hasAmount" @change="form.amount=null" dense></v-switch>
                                </v-col>
                                <v-col cols="4" md="10">
                                    <v-text-field dense
                                    min="0"
                                    :disabled="!form.hasAmount"
                                    v-model="form.amount"
                                    label="Số ngày nghỉ"
                                    type="number"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-switch dense v-model="form.hasExpire"></v-switch>
                                </v-col>
                                <v-col cols="4" md="5">
                                    <date-picker2 :range="false" v-model="form.validFrom" label="Ngày hiệu lực"
                                    :disabled="!form.hasExpire" :width="300"></date-picker2>
                                </v-col>
                                <v-col cols="4" md="5">
                                    <date-picker2 :range="false" v-model="form.expiredDate" label="Ngày hết hạn" 
                                    :disabled="!form.hasExpire" :width="300"></date-picker2>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="form.type" :items="[
                                        {text: 'Phép cơ bản', value:'CB'},
                                        {text:' Phép tháng', value:'PT'},
                                        {text:' Phép năm', value:'PN'},
                                        {text:' Khác', value:'PK'},
                                    ]" label="Loại phép">
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="form.gender" :items="[
                                        {text: 'Cả hai', value:2},
                                        {text:' Nam', value:1},
                                        {text:' Nữ', value:0},
                                    ]" label="Giới tính">
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-switch dense
                                    v-model="form.hasSalary"
                                    label="Có lương"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="6" md="3">
                                    <v-switch dense
                                    v-model="form.isActive"
                                    label="Kích hoạt"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea label="Mô tả" v-model="form.description" outlined>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" small type="submit" form="form" class="mx-1">
                            Lưu
                        </v-btn>
                        <v-btn color="red" dark small @click="applyAll" v-if="isEdit" class="mx-1">
                            Áp dụng
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import DatePicker from "@/components/DatePicker";
import moment from "moment/moment";

const start = moment().startOf('year').format("YYYY-MM-DD");
const end = moment().startOf('year').add(1, 'year').format("YYYY-MM-DD");
export default {
    name: "LeavePlanCreateDialog",
    props: ["value", "item"],
    components:{
        "date-picker": DatePicker
    },
    
    computed: {
        dialog:{
            get: function () {
                return this.value;
            },
            set: function(val) {
                this.$emit("input", val);
            }
        },
        isEdit() {
            return !!this.item;
        },
        formTitle(){
            return this.isUpdate ? "Cập nhật kế hoạch nghỉ" : "Tạo mới kế hoạch nghỉ";
        }
    },
    watch: {
        dialog: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        },
        hasExpired (val) {
            if(val)
                this.form.expiredDate = this.form.expiredDate ? this.form.expiredDate.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10) : new Date().toISOString().substr(0, 10);
            else
                this.form.expiredDate = this.form.expiredDate ? this.form.expiredDate.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10) : null;
        },
        // 'form.expiredDate' (val) {
        //     if(val)
        //         this.hasExpired = true;
        // },
        'form.name' (val) {
            let noAccent = this.removeAccents(val).replaceAll(" ", "_");
            this.form.keyword = noAccent;
        },
        'form.leaveTypeCode' (val) {
            if(val == "VH")
            {
                this.amountDisable = true;
                this.form.Amount = null;
            }
            else if(this.amountDisable)
                this.amountDisable = false;
        }
    },
    data() {
        return {
            //URL
            rootUrl: "leaveplans",
            DEPARTMENT_URL: "departments/fetch",
            APPLY_URL: "leaveplans/apply/",

            //*************** Validate **************
            rules: {
                // nameRules:[ 
                //     v => !!v || 'Tên nhân viên bắt buộc nhập',
                //     v => v.length > 5 || 'Phải nhiều hơn 5 kí tự',
                // ],
                
            },
            
            //************* Form config */
            activetab: 0,
            valid: false,
            isUpdate: false,
            IDdateMenu: false,

            amountDisable: false,
            hasExpired: false,
            formData: {
                leaveTypes: [],
                fromMenu: false,
                toMenu: false,
                PayMethods: [{text:"Có trả lương", value:"CL"}, {text:"Không trả lương", value:"KL"}],
                LeaveTypeMethods: [ {text:"Hằng năm", value:"HN"},
                                    {text:"Hằng tháng", value:"HT"},
                                    {text:"Phát sinh", value:"PS"},
                                    {text:"Không hạn nghỉ", value:"VH"}],
                staticSymbols: [ {text:"OFF", value:"OFF"},
                                 {text:"KL", value:"KL"},
                                 {text:"CD", value:"CD"},
                                 {text:"PN", value:"PN"},
                                ],
                genders:[
                    { text: "Cả hai", value: 2 },
                    { text: "Nam", value: 1 },
                    { text: "Nữ", value: 0 },
                    ]
            },

            //SUB DIALOG
            subDialog: false,
            subDialogItem : undefined,
            isAll: true,
            departments: [],
            genders: [
                {text: "Cả hai", value: ""},
                {text: "Nam", value: true},
                {text: "Nữ", value: false},
            ],
            subForm: {
                gender: "",
                isAll: true,
                departments: [],
            },

            valid: true,
            form: {
                name:"",
                amount: "",
                validTime: true,
                description: "",
                useLeave: true,
                payMethodCode: "",
                leaveTypeCode: "",
                isActive: true,
                symbol: "",
                staticSymbol: "",
                keyWord: "",
                validFrom: start,
                expiredDate: end,
                hasSalary: false,
                hasAmount: false,
                isAll:true,
                departmentCodes: "",
                employeeCodes: "",
                deparments: [],
                type:"CB",
                gender: 2
                // isAnnual: false,
                // monthly: false,
                // overtimeScales:"",
            },
            defaultForm: {
                name:"",
                amount: "",
                validTime: true,
                from: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
                description: "",
                useLeave: true,
                payMethodCode: "",
                leaveTypeCode: "",
                isActive: true,
                symbol: "",
                staticSymbol: "",
                keyWord: "",
                validFrom: start,
                expiredDate: end,
                hasSalary: false,
                hasAmount: true,
                isAll:true,
                departmentCodes: "",
                employeeCodes: "",
                deparments: [],
                type:"CB",
                gender: 2
                // isAnnual: false,
                // monthly: false,
                // overtimeScales:"",
            },
        };
    },
    methods: {
        removeAccents(str) {
            var AccentsMap = [
                "aàảãáạăằẳẵắặâầẩẫấậ",
                "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
                "dđ", "DĐ",
                "eèẻẽéẹêềểễếệ",
                "EÈẺẼÉẸÊỀỂỄẾỆ",
                "iìỉĩíị",
                "IÌỈĨÍỊ",
                "oòỏõóọôồổỗốộơờởỡớợ",
                "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
                "uùủũúụưừửữứự",
                "UÙỦŨÚỤƯỪỬỮỨỰ",
                "yỳỷỹýỵ",
                "YỲỶỸÝỴ"    
            ];
            for (var i=0; i<AccentsMap.length; i++) {
                var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
                var char = AccentsMap[i][0];
                str = str.replace(re, char);
            }
            return str;
        },
        close () {
            this.hasExpired = false;
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        fetchDepartment () {
            http.get(this.DEPARTMENT_URL ).then(res => {
                this.departments = res.data;
            }).catch(err =>{
                console.log("error connection");
            });
        },
        fetch (){
            return http.get(this.rootUrl + "/" + this.item.code).then(res => {
                let item = res.data;
                this.form = Object.assign({}, item);

            }).catch(err => {
                console.log(err);
            });
        },
        apply () {
            this.$refs.form.validate()
            if(this.valid)
            {
                if(!this.isEdit)
                    return http.post(this.rootUrl, this.form).then(res => {
                        this.dialog = false;
                        this.$parent.fetchData();
                    }).catch( (err) => {
                        console.log(err.response.status);
                    })
                else{
                    return http.put(this.rootUrl + '/' + this.item.code, this.form).then(res => {
                        this.dialog = false;
                        this.$parent.fetchData();
                    }).catch( (err) => {
                        console.log(err.response.status);
                    })
                }
            }
        },
        applyAll(){
            return http.get(this.rootUrl+"/applyAll/"+this.form.code).then(res => {
                this.dialog = false;
                this.$parent.fetchData();
            })
        }
    },
    mounted() {
        // this.fetchLeaveType();
    }
}
</script>
