<template>
    <div class="import-upload d-flex align-center">
        <v-dialog v-model="dialog" persistent max-width="50%" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" color="teal" dark v-bind="attrs" v-on="on" small>
                <v-icon>mdi-microsoft-excel</v-icon>
                    Nhập
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline">
                   {{title ?title:"Nhập excel"}}
                </v-card-title>
                <v-card-text>
                    Tải lên tập tin excel, để nhập dữ liệu.
                    <v-row no-gutters justify="center" align="center">
                        <v-col cols="10" >
                            <v-file-input show-size label="File input" @change="selectFile" accept=".xls,.xlsx" multiple>
                            </v-file-input>
                        </v-col>

                        <v-col cols="2" class="pl-2">
                            <v-btn color="success" dark small @click="upload">
                            Upload
                            <v-icon right dark>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <span class="caption warning--text"> <i>{{note}}</i></span>

                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    Thoát
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import UploadService from "../components/services/UploadService";
import EventBus  from "../components/services/EventBus"

export default {
    name:"ImportUpload",
    props:["value", "url", "note", "title"],
    computed: { 

    },
    data() {
        return {
            dialog: false,
            endpoint: process.env.VUE_APP_BASE_URL,
            ready: false,
            currentFiles: undefined,
            progress: 0,
            message: "",
            fileInfos: [],
            faceDatas: [],
            faceDescriptors: []
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        selectFile(files) {
            this.progress = 0;
            this.currentFiles = files;
        },
        upload() {
            if (!this.currentFiles) {
                this.message = "Hãy chọn file excel";
                return;
            }
            this.message = "";

            UploadService.upload(this.currentFiles, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            }, this.url)
            .then((response) => {
                let eventObj = {
                    color: "success",
                    message: "Nhập excel thành công",
                    toast: true,
                };
                this.messageToast(eventObj);
            })
            .catch((error) => {
            this.progress = 0;
            this.message = "Could not upload the file!";
            });
        }

    }
}
</script>