<template>
    <div class="timesheet-2">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon"></myBreadCrumb> -->
        <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog>
        <v-row no-gutters>
            <!-- SUM INFO -->
            <!-- <v-card class="col mb-3">
                <v-card-title>Tổng hợp giờ công nhân viên</v-card-title>
                <v-card-text>
                    <v-card-subtitle>Từ ngày: {{startMonth}}</v-card-subtitle>
                    <v-card-subtitle>Đến ngày: {{ endMonth}} </v-card-subtitle>
                </v-card-text>
            </v-card>
            <v-card class="col-12 col-md-2 mx-0 mx-md-3 mb-3">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-icon style="width: 15rem">
                            <v-icon color="blue" left> mdi-sigma </v-icon>
                            <v-subheader>
                                Tổng tăng ca thường
                            </v-subheader>
                        </v-list-item-icon>

                        <v-list-item-content class="text-">
                            <v-list-item-title>{{sumObj?sumObj.sumNormal:""}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    
                    <v-list-item>
                        <v-list-item-icon style="width: 15rem">
                            <v-icon color="indigo" left>
                                mdi-calendar-account
                            </v-icon>
                            <v-subheader>
                            Tổng tăng ca ngày nghỉ
                            </v-subheader>
                        </v-list-item-icon>

                        <v-list-item-content class="text-">
                            <v-list-item-title>{{sumObj?sumObj.sumDayOff:""}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon style="width: 15rem">
                            <v-icon color="pink" left>
                                mdi-ferry
                            </v-icon>
                            <v-subheader>
                            Tổng tăng ca lễ
                            </v-subheader>
                        </v-list-item-icon>

                        <v-list-item-content class="text-">
                            <v-list-item-title>{{sumObj?sumObj.sumHoliday:""}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card> -->
            
            <!-- <v-card class="col-12 col-md-2 mb-3">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <chart ref="chartOvertime" label="Tỉ lệ tăng ca"
                            :chartData="chartDataOvertime" maxWidth="300px"
                            chartType="pie" >
                            </chart>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->
            <v-card>
                 <v-card-title class="d-flex justify-space-between">
                    <month-picker v-model="month" label="Chọn tháng" width="200px" outlined inputClass="ma-3"></month-picker>
                    <v-spacer></v-spacer>
                    <v-sheet>
                        <v-btn color="white" small class="ma-3" @click="lockWorkTime=true"> <v-icon left color="cyan">mdi-lock-check</v-icon> 1. Khóa Công</v-btn>
                        <v-icon>mdi-arrow-right-bold</v-icon>
                        <v-btn color="white" small class="ma-3" @click="processData" :loading="processDataLoading"><v-icon color="primary" left>mdi-cogs</v-icon> 2. Tính Công</v-btn>
                        <v-icon>mdi-arrow-right-bold</v-icon>
                        <store-dialog v-model="finalizeReport" :month="month"></store-dialog>
                        <v-icon>mdi-arrow-right-bold</v-icon>
                        <v-btn color="white" small class="ma-3" @click="excelDialog=true" right> <v-icon left color="teal darken-4">mdi-microsoft-excel</v-icon> 4. Xuất Excel</v-btn>
                    </v-sheet>
                </v-card-title>
                <v-tabs color="teal">
                    <v-tab>Báo cáo công</v-tab>
                    <v-tab>Báo cáo tăng ca</v-tab>
                    <v-btn class="my-auto m3-auto" color="orange" icon  @click="fetchData"><v-icon>mdi-refresh</v-icon></v-btn>

                    <v-tab-item>
                        <v-data-table
                        :mobile-breakpoint="0"
                        height="500px" fixed-header
                        :headers="workTimeHeaders"
                        :items="dItems"
                        :page.sync="tableParams.page"
                        :sort-by.sync="tableParams.sortBy"
                        :sort-desc.sync="tableParams.sortDesc"
                        :server-items-length="rowCount"
                        :loading="isLoading"
                        loading-text="Đang tải dữ liệu..."
                        >
                            <template v-slot:[`body.prepend`]="" >
                                <tr class="filter">
                                    <td></td>
                                    <td>
                                        <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" hide-details outlined></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" hide-details outlined></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field placeholder="Nhập" dense v-model="filters.departmentName" hide-details outlined></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field placeholder="Nhập" dense v-model="filters.departmentType" hide-details outlined></v-text-field>
                                    </td>
                                    <td colspan="100%"></td>
                                </tr>
                            </template>

                            <template v-slot:[`item.actions`]="{item}" >
                                <v-btn x-small text @click="edit(item)" class="mx-1 elevation-2">Chi tiết</v-btn>
                            </template>
                            <template v-slot:[`item.isConfirmed`]="{ item }">
                                <v-chip :color="$helper.formatStatus(item.isConfirmed).color" label small outlined>
                                    <strong> {{$helper.formatStatus(item.isConfirmed).text}}</strong>
                                </v-chip>
                            </template>
                            <template v-slot:[`item.isLock`] = "{item}">
                                <v-icon v-if="item.isLock" color="grey">mdi-lock</v-icon>
                                <v-icon v-else color="green">mdi-lock-open</v-icon>
                            </template>

                            <!-- BAO CAO CONG -->
                            <template v-for="workTimeHeader in workTimeHeaders.slice(4, -(symbols.length + appendHeaders.length))" v-slot:[`item.${workTimeHeader.value}`]="{ item }">
                                <div :key="workTimeHeader.value">
                                    <div v-if="workTimeHeader.isSunday && item.isOffice" >
                                        <span class="orange--text">CN</span>
                                    </div>
                                    <div v-else :key="workTimeHeader.value">
                                        <div v-if="item[workTimeHeader.value]"  class="ma-auto" style="cursor:pointer">
                                            <span v-if="item[workTimeHeader.value].symbol!='x'">{{`${item[workTimeHeader.value].symbol}-`}}</span>{{item[workTimeHeader.value].validActualTime}}
                                            <!-- <span v-if="item[workTimeHeader.value].symbol!='x'">{{`${item[workTimeHeader.value].symbol} (${Math.min(round2Dec(item[workTimeHeader.value].leaveTime + item[workTimeHeader.value].actualWorkTime), item[workTimeHeader.value].shiftHour )})`}}</span>
                                            <span v-else> {{round2Dec(item[workTimeHeader.value].actualWorkTime + item[workTimeHeader.value].holidayActualTime + item[workTimeHeader.value].leaveTime)}} </span> -->
                                        </div>
                                        <div v-else>
                                            <span>KL</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>

                    <v-tab-item>
                        <v-data-table
                        :mobile-breakpoint="0"
                        height="500px" fixed-header
                        :headers="overtimeHeaders"
                        :items="dItems"
                        :page.sync="tableParams.page"
                        :sort-by.sync="tableParams.sortBy"
                        :sort-desc.sync="tableParams.sortDesc"
                        :server-items-length="rowCount"
                        :loading="isLoading"
                        loading-text="Đang tải dữ liệu...">
                            <!-- BAO CAO TANG CA -->
                            <template v-slot:[`item.actions`]="{item}" >
                                <v-btn x-small text @click="edit(item)" class="mx-1 elevation-2">Chi tiết</v-btn>
                            </template>

                            <template v-for="overtimeHeader in overtimeHeaders.slice(5, -(appendHeadersOvertime.length))" v-slot:[`item.${overtimeHeader.value}`]="{ item }">
                                <div :key="overtimeHeader.value" >
                                    <div v-if="item[overtimeHeader.value]"  class="ma-auto" style="cursor:pointer">
                                        <!-- <span v-if="item[overtimeHeader.value].isDayOff"> {{round2Dec(item[overtimeHeader.value].overtime + item[overtimeHeader.value].actualWorkTime)}} </span>
                                        <span v-if="item[overtimeHeader.value].leaveTime>0"> {{round2Dec(item[overtimeHeader.value].overtime + item[overtimeHeader.value].actualWorkTime 
                                                                                                        - (item[overtimeHeader.value].shiftHour - item[overtimeHeader.value].leaveTime))}} </span> -->
                                        <span> {{round2Dec(item[overtimeHeader.value].overtime)}} </span>
                                    </div>
                                    <div v-else>
                                        0
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs>

            </v-card>

        </v-row>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title>Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- KHÓA CÔNG -->
        <v-dialog v-model="lockWorkTime" width="500" scrollable>
            <v-card  :loading="lockDataLoading">
                <v-card-title>Khóa Công
                    <v-spacer></v-spacer>
                    <v-icon dark @click="lockWorkTime=false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="lockData" id="lockForm">
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete multiple item-text="name" item-value="code" :items="departments" v-model="lockDepartments" small-chips clearable deletable-chips
                                label="Chi Nhánh/Phòng Ban" hide-no-data @change="fetchDepartment" :rules='[v=>!!v||"Chọn dữ liệu"]' outlined>
                                    <template v-slot:[`prepend-item`]="" >
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-checkbox :value="lockDepartments==departments" @change="selectAll"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                Chọn tất cả
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" type="submit" form="lockForm">Khóa Công</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- //INIT -->
        <v-dialog v-model="initDialog" width="500">
            <v-card :loading="initLoading">
                <v-card-title class="secondary white--text">Tính Công
                    <v-spacer></v-spacer>
                    <v-icon dark @click="initDialog=false">mdi-close</v-icon>
                </v-card-title>
                <v-form @submit.prevent="initData">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <month-picker v-model="monthForm" label="Chọn tháng chốt công" width="500px"></month-picker>
                                <!-- <v-checkbox v-model="form.isAll" readonly label="Áp dụng cho tất cả phòng ban"></v-checkbox> -->
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="primary" type="submit">Tính Công</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <!-- CHỐT GIỜ CÔNG -->
        
        <excel-export v-model="excelDialog" :month="filters.month" :year="filters.year"></excel-export>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import ApiServices from '@/components/services/ApiServices';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import CreateDialog from './Create.vue';
import Excel from './Excel.vue'
// import CreateDialog from './Create.vue';
import * as moment from "moment/moment";
import Chart from '@/components/Charts/Chart.vue';
import StoreDialog from './Store.vue';
export default {
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,
        'store-dialog': StoreDialog,
        'excel-export': Excel,
        "chart": Chart,
        'time-selector': Excel,
    },
    props: ["breadcrumbs", "icon","department"],
    watch: {
        department(val) {
            console.log("has department");
        },
        lockWorkTime () {
            this.fetchLockData()
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.department){
                        // this.fetchDepartment(this.department);
                        this.form.departmentCode = this.department;
                    }
                }
            },
            "form.departmentCode": function(val){
                this.fetchPosition(this.form.departmentCode);
            },
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler: _.debounce(function(){
                    this.tableParams.page=1;
                    this.fetchData();
                }, 500),
            deep: true,
        },
        
        month:{
            handler(val){
                this.filters.month=moment(val, "YYYY-MM").format("MM");
                this.filters.year=moment(val, "YYYY-MM").format("YYYY");
            }
        },
        monthForm:{
            handler(val){
                this.form.processMonth=moment(val, "YYYY-MM").format("MM");
                this.form.processYear=moment(val, "YYYY-MM").format("YYYY");
            }
        },
    },
    computed: {
        startMonth: function() {
            return moment(this.month, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
        },
        endMonth: function() {
            return moment(this.month, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        dItems: function () {
            let mapped = this.items.map(x=>{
                let obj = Object.assign({}, x);
                delete obj.timesheets;
                delete obj.symbols;
                x.timesheets?.forEach(y => {
                    obj[y.date] = y.value;
                });
                for(var i=0; i < this.symbols.length; i++){
                    obj[this.symbols[i]]=x.symbolValues==null?0: x.symbolValues[i]
                }
                return obj;
            })
            return mapped;
        },
        workTimeHeaders: function () {
            let date = moment(this.month+"-01", "YYYY-MM-DD");
            let end = moment(date.format("YYYY-MM-DD")).endOf("month");
            // date = date.add(1,"days")
            let dates = [];
            let cHeaders = [];
            while(date<=end){
                dates.push({ text: date.format("YYYY-MM-DD"), value: date.format("YYYYMMDD"), width: 50, sortable: false, isSunday: date.day()==0, class: date.isoWeekday()==7?'red-header':''})
                date = date.add(1, "days");
            }
            let symbolHeaders = [];
            this.symbols.forEach(x=>{
                symbolHeaders.push({ text: x, value: x, width: 50, sortable: false})
            })
            cHeaders = this.headers.concat(dates).concat(symbolHeaders).concat(this.appendHeaders);
            return cHeaders;   
        },
        overtimeHeaders: function () {
            let date = moment(this.month+"-01", "YYYY-MM-DD");
            let end = moment(date.format("YYYY-MM-DD")).endOf("month");
            // date = date.add(1,"days")
            let dates = [];
            let cHeaders = [];
            while(date<=end){
                // if(date.isoWeekday()==1)
                //     this.sundayCodes.push(date.format("YYYYMMDD"));
                dates.push({ text: date.format("YYYY-MM-DD"), value: date.format("YYYYMMDD"), width: 50, sortable: false, isSunday: date.day()==0, class: date.isoWeekday()==7?'red-header':''})
                date = date.add(1, "days");
            }
            let symbolHeaders = [];
            this.symbols.forEach(x=>{
                symbolHeaders.push({ text: x, value: x, width: 50, sortable: false})
            })
            cHeaders = this.headers.concat(dates).concat(this.appendHeadersOvertime);
            return cHeaders;   
        }
    },
    data () {
        return {
            ROOT_URL: "monthlyTimesheets/",
            LOCKDATA_URL: "timeRollProcesses",
            DEPARTMENT_FETCH: "departmentTypes/fetch-department/",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },

            storeLoading: false,
            sundayCodes:[],
            month: moment().format("YYYY-MM"),
            monthForm: moment().format("YYYY-MM"),
            form:{
                processMonth: moment().format("MM"),
                processYear:moment().format("YYYY"),
                isAll: false,
            },
            leavePlans:[],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentName: null,
                workDate: moment().startOf("month").format("YYYY-MM-DD"),
                status: -1,
                isConfirmed: null,
                month: moment().format("MM"),
                year: moment().endOf("month").format("YYYY"),
            },
            rowCount: 0,

            departmentSearch:null,
            departments: [],
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            lockWorkTime: false,
            lockDataLoading: false,
            initDialog: false,
            initLoading: false,
            saveDialog:false,
            unSaveDialog:false,
            reloadDialog: false,
            reloadLoading: false,
            createDialog: false,
            finalizeReport: false,
            excelDialog:false,
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,
            sumObj:null,
            
            chartDataOvertime: {
                labels: ["Thường", "Ngày nghỉ", "Lễ"],
                datasets: [
                    {
                        label: "Số giờ",
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 2
                    },
                ],
            },

            headers: [
                { text: 'Thao tác', value: 'actions', width: 110},
                { text: 'Mã NV', align: 'start', value: 'code', width: 100},
                { text: 'Tên nhân viên', align: 'start', value: 'name', width: 200, divide: true},
                { text: 'Phòng', align: 'start', value: 'department', width: 200},
                // { text: 'Khối', align: 'start', value: 'departmentType', width: 200},
                // { text: 'Trạng thái', align: 'start', value: 'isLock', width: 100, sortable:false},
                // { text: 'Tháng', align: 'start', value: 'month', width: 100},
                // { text: 'Năm', align: 'start', value: 'year', width: 100},
            ],
            appendHeaders:[
                { text: 'Ngày công chuẩn', value: 'standardDate', width: 110, sortable: false},
                { text: 'Giờ công chuẩn', value: 'standardHour', width: 110, sortable: false},
                { text: 'Công thực tế', value: 'actualTime', width: 110, sortable: false},
                { text: 'Thiếu giờ', value: 'missingHour', width: 110, sortable: false},
                { text: 'Tăng ca chính thức', value: 'overtimeFinal', width: 110, sortable: false},
                { text: 'Làm thêm chính thức', value: 'extendFinal', width: 110, sortable: false},
                { text: 'Tổng giờ công sau bù', value: 'totalTimeFinal', width: 110, sortable: false}, //giờ công + giờ bù
                { text: 'Tổng giờ công chính thức', value: 'totalWorkTime', width: 110, sortable: false}, //giờ công + giờ bù + tăng ca + làm thêm
                { text: 'Cơm', value: 'lunch', width: 110, sortable: false},
            ],
            appendHeadersOvertime:[
                { text: 'Ngày công chuẩn', value: 'standardDate', width: 110, sortable: false},
                { text: 'Giờ công chuẩn', value: 'standardHour', width: 110, sortable: false},
                { text: 'Công thực tế', value: 'actualTime', width: 110, sortable: false},
                { text: 'Giờ làm thêm', value: 'extend', width: 110, sortable: false},

                { text: 'Giờ làm thiếu', value: 'missingHour', width: 110, sortable: false},
                { text: 'Công bù', value: 'missingCover', width: 110, sortable: false},
                { text: 'Công thiếu còn lại', value: 'missingRemain', width: 110, sortable: false},
                { text: 'Làm thêm còn lại', value: 'extendRemain', width: 110, sortable: false},

                { text: 'Tăng ca tối đa', value: 'overtimeBooking', width: 110, sortable: false},
                { text: 'Tăng ca thường', value: 'normalOvertime', width: 110, sortable: false},
                { text: 'Tăng ca ngày nghỉ', value: 'dayoffOvertime', width: 110, sortable: false},
                { text: 'Tăng ca lễ', value: 'holidayOvertime', width: 110, sortable: false},

                { text: 'Tăng ca chính thức', value: 'overtimeFinal', width: 110, sortable: false},
                { text: 'Làm thêm chính thức', value: 'extendFinal', width: 110, sortable: false},
                { text: 'Tổng giờ công sau bù', value: 'totalTimeFinal', width: 110, sortable: false},//giờ công + giờ bù
                { text: 'Cơm tăng ca', value: 'overtimeLunch', width: 110, sortable: false},
            ],
            symbols:[],
            interval: null,
            processDataLoading: false,

            //lockdata 
            lockDepartments: [],
        }
    },
    methods: {
        workTimeReport() {
            console.log("Work Time Report");
        },
        overTimeReport() {
           
        },
        round2Dec(val){
            return Math.round(val * 100) / 100
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchLeavePlan(){
            ApiServices.fetchLeavePlan().then(res=>{
                this.leavePlans = res.data;
            })
        },
        fetchDepartment() {
            return http.get("departments/fetch").then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            this.isLoading = true;
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.symbols = res.data.symbols;
                this.rowCount = res.data.rowCount;
                this.sumObj = res.data.sumObj;
                //chart
                this.chartDataOvertime.datasets[0].data=[];
                this.chartDataOvertime.datasets[0].backgroundColor=[];
                this.chartDataOvertime.datasets[0].borderColor=[];

                for (const property in this.sumObj) {
                    this.chartDataOvertime.datasets[0].data.push(this.sumObj[property]);
                    this.chartDataOvertime.datasets[0].backgroundColor.push(this.getRandomColor());
                    this.chartDataOvertime.datasets[0].borderColor.push('rgb(225, 225, 225)');
                }

                this.$refs.chartOvertime.update()
            })
            .catch(err => {
            }).finally(()=> {this.isLoading = false})
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        fetchLockData ()
        {
            return http.get("timeRollProcesses", {
                params: {
                    month: moment(this.month, "YYYY-MM").format("MM"),
                    year: moment(this.month, "YYYY-MM").format("YYYY"),
                }
            }).then(res => {
                this.lockDepartments = res.data.map(x=> {return x.departmentCode});
                })
        },
        lockData () {
            return http.post("TimeRollProcesses/lock", {
                lockDepartments: this.lockDepartments,
                month: moment(this.month, "YYYY-MM").format("MM"),
                year: moment(this.month, "YYYY-MM").format("YYYY"),
            }).then(res=> {
                this.lockWorkTime = false;
            })
        },
        selectAll(value)
        {
            this.lockDepartments = value ? this.departments.map(x=>{return x.code}): [];
        },
        processData ()
        {   
            // this.isLoading = true;
            this.processDataLoading = true;
            return http.get("monthlyTimesheets/process", {
                params: {
                    month: moment(this.month, "YYYY-MM").format("MM"),
                    year: moment(this.month, "YYYY-MM").format("YYYY"),
                }
            }).then(res=>{
                this.interval = setInterval(this.checkBackground, 1000);
            }).catch(e => {
                this.processDataLoading = false;
            });
        },
        store()
        {
            let month = moment(this.month, "YYYY-MM");
            this.storeLoading = false;
            return http.get("monthlyTimesheets/store", {
                params: {
                    month: month.format("MM"),
                    year: month.format("YYYY"),
                }
            }).then(res=>{
            }).finally(()=> {this.storeLoading = true});
        },

        // calData(item) {
        //     this.reloadLoading = true;
        //     return http.get("monthlyTimesheets/calculate", {
        //         params:{
        //             employeeCode: item.code,
        //             year: this.form.processYear,
        //             month: this.form.processMonth
        //         }
        //     }).then(res=>{
        //         this.reloadLoading = false;
        //     })
        // },
        reloadData(item){
            this.reloadDialog = true;
            return http.get("monthlyTimesheets/reload", {
                params:{
                    year: this.form.processYear,
                    month: this.form.processMonth
                }
            }).then(res=>{
            }).finally(()=>{
                this.reloadLoading = false;
            })
        },

        saveAll() {
            return http.post("monthlyTimesheets/save-all", this.form).then(res=>{
                this.saveDialog = false;
            })
        },
        unSaveAll() {
            return http.post("monthlyTimesheets/unsave-all", this.form).then(res=>{
                this.unSaveDialog = false;
            })
        },

        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
        checkBackground(){
            return http("monthlyTimesheets/background-status").then(res => {
                if(res.data==true)
                {
                    this.processDataLoading =true;
                    // this.isLoading = true;
                } else {
                    console.log("clear intervla", this.interval)
                    this.processDataLoading =false;
                    clearInterval(this.interval);
                }
            }).catch(err => {
                clearInterval(this.interval);
            })
        }
    },
    created () {
        this.fetchData();
        this.fetchDepartment();
        this.interval = setInterval(this.checkBackground, 5000);
    },
    beforeDestroy(){
        clearInterval(this.interval);
    },
    destroyed()
    {
        this.interval = clearInterval();
    }
}
</script>

<style scoped>
    @media screen and (min-width: 600px) {
        
        ::v-deep table > thead > tr > th:nth-child(1),
        ::v-deep table > tbody > tr > td:nth-child(1) {
            position: sticky !important; 
            position: -webkit-sticky !important; 
            left: 0;
            /* margin-left: -100px !important; */
            z-index: 3;
            background: white;
        }

        ::v-deep table > thead > tr > th:nth-child(2),
        ::v-deep table > tbody > tr > td:nth-child(2){
            position: sticky !important; 
            position: -webkit-sticky !important; 
            left: 110px !important; 
            z-index: 3;
            background: white;
        }
        ::v-deep table > thead > tr > th:nth-child(3),
        ::v-deep table > tbody > tr > td:nth-child(3){
            position: sticky !important;
            position: -webkit-sticky !important;
            left: 210px !important;
            z-index: 3;
            background: white;
        }
        
    }
    ::v-deep .v-data-table tbody
    tr:hover:not(.v-data-table__expanded__content) {
        background: white !important;
    }
    
    ::v-deep table > thead > tr > th:nth-child(1) {
        z-index: 4;
    }
    ::v-deep table > thead > tr > th:nth-child(2) {
        z-index: 4;
    }
    ::v-deep table > thead > tr > th:nth-child(3) {
        z-index: 4;
    }
    ::v-deep .red-header{
        color: red !important;
    }
</style>