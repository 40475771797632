<template>
    <div class="create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="600px">
            <v-card>
                <v-card-title class="mb-5">
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} nghỉ dài hạn
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select
                                clearable
                                v-model="form.typeCode"
                                :items="formData.longLeaveTypes"
                                item-value="code"
                                item-text="name"
                                label="Loại nghỉ"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                v-model="form.name"
                                label="Tên"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                v-model="form.alias"
                                label="Alias"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                v-model="form.totalDate"
                                label="Số ngày nghỉ tối đa"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch
                                v-model="form.hasSalary"
                                label="Có lương"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch
                                v-model="form.isActive"
                                label="Kích hoạt"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea solo flat outlined 
                                v-model="form.note"
                                label="Ghi chú">
                                </v-textarea>
                            </v-col>
                            
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark
                                color="blue darken-1"
                                type="submit"
                            >
                                Lưu
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import DatePicker from "@/components/DatePicker";
import http from '@/components/services/http-common';
export default {
    name: "LongLeave",
    components: {
        "date-picker": DatePicker,

    },
    props: ["value"],
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
            }
        },
    },
    data() {
        return {

            // URL
            ROOT_URL: "LongLeaves",
            
            //RULE
            rules: {
            },

            // DATA
            valid: false,
            ShiftObject: "",
            formData: {
                longLeaveTypes: [{name: " Thai sản", code:"TS"}, {name: "Nghỉ dài hạn", code: "DH"}],
            },
            form: {
                isActive: false,
                typeCode: "",
                hasSalary: false,
            },

            defaultForm: {
                isActive: false,
                typeCode: "",
                hasSalary: false,
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },

    methods: {
        
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },

        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                this.form.shiftCode = this.ShiftObject.code;
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                }else {
                    http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {

                    });
                }
            }
        },

        fetchData() {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                this.ContractUpdate = true;
                this.form = Object.assign({}, item)
            })
            .catch(err => {
                console.log(err);
            });
        },

        fetchFormData () {
            return http.get(this.FETCH_FORM_URL).then(res => {
                this.formData.departments =  res.data.departments;
                this.formData.contractTypes = res.data.contractTypes;
            })
        },

        fetchShift() {
            if(this.form.DepartmentTypeCode && this.form.PositionStateCode)
            {
                return http.get(this.DEPARTMENT_FETCH_SHIFT + this.form.DepartmentTypeCode, {
                    params: {
                        PositionStateCode: this.form.PositionStateCode,
                    }
                }).then(res => {
                    this.formData.shifts = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        fetchPosition() {
            if(this.form.DepartmentCode && this.form.PositionStateCode)
            {
                return http.get("departments/fetch-position/", {
                    params: {
                        id: this.form.DepartmentCode,
                        state: this.form.PositionStateCode,
                        }
                    })
                    .then(res => {
                        this.formData.positions = res.data;
                    })
                    .catch(err => {console.log(err)});
            }
        },
        fetchPositionState() {
            return http.get("PositionStates?dropDown=1")
            .then(res => {
                this.formData.positionStates = res.data;
            })
            .catch(err => {

            });
        },
        fetchDepartment() {
            if(this.form.DepartmentTypeCode != '')
            {
                return http.get("departmenttypes/fetch-department/" + this.form.DepartmentTypeCode)
                .then(res => {
                    this.formData.departments = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        fetchDepartmentType() {
            return http.get("departmenttypes?dropDown=1").then(res => {
                this.formData.departmentTypes = res.data;
            }).catch(err => {
                console.log(err)
            });
        },
        
        resetPosition () {
            this.form.PositionCode = "";
            this.formData.positions = [];
        },
        
        resetDepartment () {
            this.form.DepartmentCode = "";
            this.formData.departments = [];
        },

        resetShift () {
            this.form.ShiftCode = "";
            this.ShiftObject = {};
            this.formData.shifts = [];
        },

        //Employee Dialog JS
        rowHandler (e) {
            switch(this.selectedField){
                case 1: {
                    this.form.EmployeeACode = e.code;
                    this.form.EmployeeAName = e.name;
                    break;
                }
                case 2: {
                    this.form.EmployeeBCode = e.code;
                    this.form.EmployeeBName = e.name;
                    break;
                }
            }
        }
    }
}
</script>