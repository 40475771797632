<template>
    <v-dialog v-model="dialog" width="500px">
        <v-card>
            <v-toolbar flat color="secondary" dark>
                <v-toolbar-title>Xuất excel</v-toolbar-title>
                <v-spacer></v-spacer> 
                <v-icon small @click="dialog=false"> mdi-close</v-icon>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete :search-input.sync="employeeSearch" v-model="form.employeeCode" hide-no-data label="Chọn nhân viên" clearable
                        :items="employees" item-value="code" item-text="name" @update:search-input="fetchEmployee"></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete :search-input.sync="departmentSearch" v-model="form.departmentCode" hide-no-data label="Chọn phòng ban"
                        :items="departments" item-value="code" item-text="name" @update:search-input="fetchDepartment"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="exportExcel" small color="success">Xuất excel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import http from '@/components/services/http-common';
import ApiService from "@/components/services/ApiServices";
export default {
    props: ["value", "item"],
    computed:{
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    watch:{
        // employeeSearch:{
        //     handler(val){
        //         console.log(val)
        //         this.fetchEmployee();
        //     }
        // }
    },
    data() {
        return {
            ROOT_URL: "Timesheetreports",
            
            employeeSearch: null,
            employees: [],
            departmentSearch: null,
            departments: [],
            form:{
                EmployeeCode: null,
                DepartmentCode: null,
            }
        }
    },
    methods:{
        fetchEmployee(){
            return ApiService.fetchEmployee(this.employeeSearch).then(res=>{
                this.employees = res.data;
            })
        },
        fetchDepartment(){
            return ApiService.fetchDepartment(this.departmentSearch).then(res=>{
                this.departments = res.data;
            })
        },
        exportExcel () {
            return http.get(this.ROOT_URL + "/export/" + this.item.code, {
                params: this.form,
                responseType: 'arraybuffer',
            }).then(res =>{
                console.log("export")
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export.xlsx');
                document.body.appendChild(link);
                link.click();
            }).catch (err => {
                throw err;
            });
        },
    }
}
</script>