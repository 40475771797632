
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter';
import CreateDialog from '../Create.vue';
import * as moment from "moment/moment";
import apiService from '@/components/services/ApiServices';
import DialogYoutube from '../DialogYoutube'

export default {
    components: {
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,
        DialogYoutube,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        
    },
    data () {
        return {
            ROOT_URL: "YoutubeManager",
            exportPrompt: false,
            vimeoVideo: undefined,

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:15
            },
            isGoogle: false,
            selectYoutubeUrl:false,
            youtubeDialog:false,
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
                shiftCode:null,
                month: moment().startOf("months").format("yyyy-MM"),
            },
            departmentSearch:null,
            departments: [],
            shifts:[],
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["departmentName"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: '', value: 'actions', width: 70},
                { text: 'Mã', align: 'start', value: 'code', width: '7rem'},
                { text: 'Tạo bởi', align: 'start', value: 'createdByName', width: '200px'},
                { text: 'Phòng ban', align: 'start', value: 'departmentName', width: '130px'},
                { text: 'Tiêu đề', align: 'start', value: 'title', width: '100px'},
                { text: 'Tháng', align: 'start', value: 'monthFormated', width: '100px'},
                { text: 'Mô tả', align: 'start', value: 'description', width: '200px'},
                { text: 'Link video', align: 'start', value: 'youtubeUrl'},
                { text: 'Link google', align: 'start', value: 'googleUrl'},
                { text: 'Ngày tạo', align: 'start', value: 'createdAtFormated', width: '200px'},
            ],
            
        }
    },
    methods: {
        // vimeoUpload(){
        //     let formData = new FormData();
        //     formData.append("file_data", this.vimeoVideo);
        //     return http.post("https://api.vimeo.com/me/videos", formData,{
        //         params: {
                    
        //         },
        //         headers: {
        //             'Authorization': 'Bearer ' + "bAF9QGN1C8+pqMliyzPrDAN/JKDgPnFpXgVx8j3h3swG1trdQjk6bAJL1hXs/7hpkebX9yjUILPJzqgUvagxUiYXHYGM444HZHIdg5pbQXxCyzQANjA6L/Z3Qf51bt1P",
        //             "Content-Type": 'application/json',
        //             'Accept': 'application/vnd.vimeo.*+json;version=3.4'
        //         }
        //     })
        // },

        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
                this.isLoading = false;
            });
        },
        
        fetchDepartment() {
            http.get("departments")
            .then(res => {
                console.log(res.data, "********");
                this.departments = res.data;
            })
            .catch(err => console.log(err))
        },
        editItem(item){
            this.item = item;
            this.dialog = true;
        },
        
        deleteItem(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL+'/'+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchData();
        this.fetchDepartment();
    },
    
}