<template>
  <v-dialog v-model="value" max-width="600px" scrollable persistent>
      <v-card>
          <v-card-title class="font-weight-bold">{{isEdit ? 'Thay đổi': 'Tạo'}} loại thưởng{{isEdit ? item.id: ''}}
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="close(false)">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-card-title>
          <v-card-text class="text--primary">
            <v-form ref="create_bonus_form" id="create_bonus_form" v-model="valid" @submit.prevent="save">
              <v-row class="mt-0">
                <v-col cols="6">
                    <v-autocomplete v-model="form.employeeCode" :items="employees" :loading="employeeLoading" label="Nhân viên" persistent-placeholder outlined hide-details="auto"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-select v-model="form.bonusTypeCode" :items="bonusTypes" :loading="bonusTypeLoading" label="Loại thưởng" item-text="name" item-value="code" persistent-placeholder outlined hide-details="auto"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.amount" type="number" min="0" label="Số tiền" persistent-placeholder outlined hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.month" type="date" label="Tháng"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="teal darken-4" dark type="submit" form="create_bonus_form" style="width: 200px">Lưu </v-btn>
              
              <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import BonusApi from './api.js'
import moment from 'moment/moment'
import Editor from '@/components/Editor/Editor.vue';

export default {
  props:['value', 'item'],
  components:{
      Editor
  },
  watch:{
    // dialog: {
    //     handler(val){
    //         if(val)
    //         {
    //             this.getEmployees()
                
    //             if(this.isEdit)
    //             {
    //                 this.fetch()
    //                 this.headerCreated = true
    //             }
    //         }
    //         else{
    //             this.close();
    //         }
    //     },
    // },

    value(val) {
      this.$emit('input', val)
      // this.updateValue()
      if(val) {
        this.getEmployees()
        this.getBonusTypes()
        if(this.isEdit) {
            this.fetch()
        }
      }
    },

    imageFiles() {
        this.form.images = ""
    },


  },
  computed: {
      device: function() {
          return this.devices.find(n => n.deviceId === this.deviceId);
      },
      isEdit() {
          return !!this.item && this.value;
      },
      validDetail(){
          console.log(this.rules.required);
      },
      

      employeeReportCategoryDescription() {
          let result = "";
          if(this.categories.length>0){
              if(this.form.employeeReportCategoryCode)
              {
                  let resultObj = this.categories.filter(x=>x.code == this.form.employeeReportCategoryCode)
                  if(resultObj.length>0){
                      result = resultObj[0].description
                  }
              }
          }
          return result
      },
      imageStatus() {
          var result = {
              name: 'Hình ảnh khả dụng',
              color: 'green',
              value: 1
          }
          this.imageFiles.forEach(imageFile => {
              let lastModifiedTimestamp = imageFile.lastModified;
              let createdDate = moment(lastModifiedTimestamp)
              console.log(createdDate)
              if(createdDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                  result = {
                      name: 'Hình ảnh cũ, kiểm tra lại các hình ảnh chụp trong hôm nay',
                      color: 'error',
                      value: 0
                  } 
              }
          });
          return result
      }
  },
  data() {
      return {
          valid: false,
          statuses: [{text: 'Áp dụng tất cả phòng ban', value: 1}, {text: 'Áp dụng một số', value: 0}],

          dialog: false,
          // item: null,

          toDepartments:[],
          fromDepartments:[],
          contractCategories:[],
          templateHTML: "",
          length: null,
          lengthType:"",
          lengthTypeDictText: {
              'NAM': 'năm',
              'THANG': 'tháng'
          },
          lengthTypeDictCompute: {
              'NAM': 'years',
              'THANG': 'months'
          },

          form: {
              employeeCode: '',
              bonusTypeCode: '',
              amount: '',
              month: null

          },
          defaultForm: {
              employeeCode: '',
              bonusTypeCode: '',
              amount: '',
              month: null
          },
          employeeHireDate: null,

          sampleTemplate: "",
          employeeTextPaste:'',

          /**header */
          categories: [],
          departments: [],
          employees:[],
          employeeLoading: false,
          employeeGlobalSearch:"",
          employeeSelect: [],
          bonusTypes: [],
          bonusTypeLoading: false,

          /** Detail Header*/
          employeeHeaders: [
              {text:"Mã", value: "code"},
              {text:"Tên", value: "name", width: 150 },
              {text:"Địa chỉ", value: "address", width: 200},
              {text:"CCCD", value: "idNumber" },
              {text:"Ngày cấp", value: "idDate" },
              {text:"Nơi cấp", value: "idPlace", width:200 },
              {text:"Chức vụ", value: "positionName", width: 150 },
              {text:"Chức danh", value: "positionStateName" },
              {text:"Phòng ban", value: "departmentName" },
          ],
          properties:[],
          detail:{
              propertyCode:undefined,
              quantity:0,
              actualQuantity:0,
          },
          defaultDetail:{
              propertyCode:undefined,
              quantity:0,
              actualQuantity:0,
          },
          detailDefault:{
              propertyCode:undefined,
              quantity:0,
          },
          detailItems:[],

          propertyValid: false,
          amountValid: false,

          
          rules: {
              required: value => !!value || 'Required.'
          },

          /**Stepper params */
          headerCreated: false,
      }
  }, 
  methods: {
      thoundsandSep(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      printTest() {
          window.frames["printf"].focus();
          window.frames["printf"].print();
      },

      employeeSelectClass(item) {
          if(this.employeeSelect.length>0)
          {
              if(this.employeeSelect[0].code == item.code)
                  return 'highlighted-row'
          }
      },
      formatDate(value) {
          return moment(value).format("DD/MM/YYYY");
      },
      getDatePart(value) {
          let date = moment(value);
          let result = {
              day: date.day(),
              month: date.month(),
              year: date.year(),
          }
          console.log(result)
          return result
      },

      getUnit(property) {
          var data = this.properties.find(x=>x.code == property);
          if(!data)
              return ""
          else
              return data.propertyUnitName
      },
      getDepartments() {
          this.apiService.getDepartments().then(d => {
              this.departments = d
          })
      },

      getContractCategory() {
          return this.apiService.getContractCategory().then(d =>  {
              this.contractCategories = d
          })
      },

    close( stopClose = false){
        this.$emit("update:item", null)
        this.employeeSelect = []
        this.form = Object.assign({}, this.defaultForm);
        this.headerCreated = false;
        this.$refs.create_bonus_form.resetValidation();
        this.employeeHireDate = '',

        this.uploaded = 0
        this.totalUpload = 0
        this.$emit('fetchData')
        this.$emit('input', false)
        if(!stopClose){
            // this.$emit('close-dialog');
            // this.value = false
        }
    },

    save() {
        this.$refs.create_bonus_form.validate();
        if(this.valid)
        {
            //tao phieu
            if(!this.isEdit) {
                console.log(this.form)
                return BonusApi.post( this.form).then(() => {
                    // this.$emit("fetchData", res.data.code);
                    this.close(true);
                    
                    // this.fetchDetail();
                    // this.form = Object.assign({}, res.data.data);
                });
            }

            //update phieu
            if(this.isEdit) {
                return BonusApi.put(this.item.code,this.form).then(() => {
                    // this.$emit("fetchData");
                    this.close();
                    
                    // this.fetchDetail();
                    // this.form = Object.assign({}, res.data.data);
                });
            }
        }
    },
      fetch() {
          return BonusApi.get(this.item.code).then(d => {
            console.log(d)
            this.form.code = d.data.code
            this.form.name = d.data.name
            this.form.description = d.data.description
          })
      },
      getToDepartments() {
          this.apiService.getToDepartments().then(d => {
              console.log(d)
              this.toDepartments = d
          })
      },

      getFromDepartments() {
          this.apiService.getFromDepartments().then(d => {
              this.fromDepartments = d
          })
      },

      getSalary(){
          return this.apiService.getSalary(this.form.employeeCode, this.form.validFrom).then(d => {
              this.form.salary = d
              console.log(this.form.salary)
          })
      },
      
    getEmployees() {
      this.employeeLoading = true
      return BonusApi.getEmployees().then(d => {
        this.employees = d.map(x => {
          return {
            text: `${x.code}: ${x.name} (${x.departmentName})`,
            value: x.code
          }
        })
      }).finally(() =>  this.employeeLoading = false)
    },

    getBonusTypes() {
        this.bonusTypeLoading = true
        return BonusApi.getBonusTypes().then(d => {
            this.bonusTypes = d.data
        }).finally(() => {
            this.bonusTypeLoading = false
        })
    }
      
    //SERVICES
  },
  apiService: null,
  created() {
  },
  mounted() {
      // this.getContractCategory()
      // this.getToDepartments()
      // this.getFromDepartments()
      
      // this.startVideo()
      // this.getDepartments();
      // this.getEmployees()
  }
}
</script>

<style scoped>
.v-data-table__selected {
background-color: green !important; /* Customize the background color as needed */
font-weight: 500;
}
.v-data-table__selected td{
  color: white !important;
}
.v-data-table__selected td .v-input--selection-controls__input i {
  color: white;
}
</style>