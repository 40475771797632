<template>
    <div class="report">
        <breadcrumb :urls="JSON.stringify(breadcrumbs)"  @createForm="dialog.state=true" :icon="icon"> </breadcrumb>
        <create-timesheet-report v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-timesheet-report>
        <excel-export v-model="excelDialog" @CloseDialog="closeHandler" @fetchData="fetchData" :item="targetItem"></excel-export>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table height="600px"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItem"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <!-- ACTIONS -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="editItem(item)" x-small class="mx-1">
                        <v-icon small>
                            mdi-pencil
                        </v-icon> Chi tiết
                    </v-btn>
                    <v-btn @click="deleteItem(item)" color="error" x-small class="mx-1">
                        <v-icon small>
                            mdi-delete
                        </v-icon> Xóa
                    </v-btn>
                    <v-btn x-small @click="targetItem=item; excelDialog=true" color="teal" dark class="mx-1">
                        <v-icon small>
                            mdi-file-excel-outline
                        </v-icon> Xuất excel
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>

        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import http from '@/components/services/http-common';
import BreadCrumb from '@/components/BreadCrumb'
import CreateTimesheetReport from './Create';
import ExcelDialog from './Excel';

export default {
    name: "TimeSheetReport",
    components: {
        "breadcrumb": BreadCrumb,
        "create-timesheet-report": CreateTimesheetReport,
        "excel-export": ExcelDialog,
    },
    computed: {

    },
    watch: {
        excelDialog(val) {
            if(!this.excelDialog)
                this.targetItem=null;
        },
    },
    props:["breadcrumbs", "icon"],
    data () {
        return {
            ROOT_URL: "Timesheetreports",
            
            //DIALOG
            excelDialog: false,
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

            //filter
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],
            },

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },

            //datatable config
            totalItem: 0,
            search:"",
            options: {},
            isLoading: true,
            items: [],
            headers: [
                {
                    text: 'Mã',
                    align: 'start',
                    sortable: false,
                    value: 'code',
                },
                {
                    text: 'Tên báo cáo',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Từ',
                    align: 'start',
                    sortable: false,
                    value: 'from',
                },
                {
                    text: 'Đến',
                    align: 'start',
                    sortable: false,
                    value: 'to',
                },
                {
                    text: '',
                    align: 'start',
                    value: 'actions',
                },
            ],
            
        }
    },
    methods: {
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },

        fetchData() {
            return http.get(this.ROOT_URL)
            .then(res => {
                this.items = res.data.items;
                this.totalItem = res.data.totalItem
                this.isLoading = false;
                console.log("xx", this.headers);
            })
            .catch(err => {

            });
        },
        
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },
    },
    created () {
        this.fetchData();
    }
}
</script>