import http from '@/components/services/http-common';
// import * as moment from "moment/moment"

export const ROUTE = 'bonusTypes';
// global api
class BonusTypeApi {
  gets() {
    return http.get(`${ROUTE}`).then(r => r.data)
  }
  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => r.data)
  }

  post(data) {
    return http.post(`${ROUTE}`,data)
  }

  put(id, data) {
    return http.put(`${ROUTE}/${id}`, data)
  }

  deleteItem(id){
    return http.delete(`${ROUTE}/${id}`)
  }
  
}

export default new BonusTypeApi();