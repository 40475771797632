<template>
  <v-dialog v-model="dialog" max-width="850" scrollable eager persistent>
    <v-card class="rounded-xl" :loading="loading">
      <v-card-title class="title">
        Chi tiết
      </v-card-title>
      <!-- <v-card-subtitle class="red--text">
          Nhân viên kiểm tra, xác nhận và bổ sung các thông tin cần thiết.
      </v-card-subtitle> -->
      <v-card-text class="text--primary">
        <v-form v-model="isValid" @submit.prevent="submitForm" ref="temporary_store_form" id="temporary_store_form">
          <v-row class="mt-0">
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.code" label="Mã" dense persistent-placeholder readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.name" label="Tên NSO" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.validFrom" label="Từ ngày" readonly dense persistent-placeholder type="date"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.validTo" label="Đến ngày" readonly dense persistent-placeholder type="date"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select outlined hide-details="auto" v-model="form.model" :items="[
                'Mini', 'Standard', 'Flagship'
              ]" label="Mô hình" dense persistent-placeholder ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select outlined hide-details="auto" v-model="form.status" label="Trạng thái" :items="[
                {text: 'Open', value: 1},
                {text: 'Close', value: 0}
              ]" readonly dense persistent-placeholder ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined hide-details="auto" v-model="form.bveTotal" label="SL bảo vệ" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined hide-details="auto" v-model="form.nvmTotal" label="SL NVM" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined hide-details="auto" v-model="form.cot3Total" label="SL cột 3" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined hide-details="auto" v-model="form.cot2Total" label="SL cột 2" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined hide-details="auto" v-model="form.cot1Total" label="SL cột 1" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined hide-details="auto" v-model="form.slTotal" label="SL trưởng ca" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined hide-details="auto" v-model="form.smTotal" label="SL trưởng chi nhánh" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details="auto" v-model="form.expectTotal" label="Định biên tổng" dense persistent-placeholder ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete outlined hide-details="auto" v-model="form.departmentCode" :items="departments" item-value="code" item-text="name" label="Store chính thức" dense persistent-placeholder ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined hide-details="auto" v-model="form.description" label="Mô tả" dense persistent-placeholder ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="dialog=false, itemCode = null">Thoát</v-btn>
          <v-btn color="primary" type="submit" dark form="temporary_store_form">
              Lưu
          </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiServices from '@/components/services/ApiServices.js'
import TemporaryStoreApi from './api.js'
export default {
  mounted() {
    // this.fetchDistrict()
    // this.fetchWard()

    //********Global Events Listener */
    this.$root.$on('openDialog', () => {
      this.dialog = true
    });
    
    this.$root.$on('editDialog', (data) => {
      this.dialog = true
      this.itemCode = data.id
      this.fetchData()
    });
    

  },
  destroyed() {
    
    // this.dialog = false
    //********OFF Global Events Listener */
    this.$root.$off('openDialog');
    
    this.$root.$off('editDialog');
  },
  computed: {
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội", "Hàn Quốc"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    }
  },
  data() {
    return {
      REQUIRE_RELIGION: ['CG', 'TCG', 'TL'],
      REQUIRE_MARITAL: ['KH'],
      dialog: false,
      idFrontImageFile: null,
      idBackImageFile: null,
      bankImageFile: null,
      maritalStatusImageFile: null,
      religionImageFile: null,
      employee: null,
      isValid: null,
      isValid2: null,
      showTabValid: false,
      noTaxNumber: false,
      noInsuranceNumber: false,
      loading: false,
      addFamilyDialog:false,
      

      itemCode: null,
      initForm: false,
      
      form: {
        code: '',
        name: '',
        description: '',
        validFrom: null,
        validTo: null,
        model: '',
        bveTotal: '',
        nvmTotal: '',
        cot3Total: '',
        cot2Total: '',
        cot1Total: '',
        slTotal: '',
        smTotal: '',
        expectTotal: '',
        status: null,
        departmentCode: '',
      },
      
      defaultForm: {
        code: '',
        name: '',
        description: '',
        validFrom: null,
        validTo: null,
        model: '',
        bveTotal: '',
        nvmTotal: '',
        cot3Total: '',
        cot2Total: '',
        cot1Total: '',
        slTotal: '',
        smTotal: '',
        expectTotal: '',
        status: null,
        departmentCode: '',
      },
      departments: [],

      family:{
        relationType: null,
        memberName: '',
        phoneNumber: '',
        doB: '',
        idNumber: '',
      },
      
      familyDefault:{
        relationType: null,
        memberName: '',
        phoneNumber: '',
        doB: '',
        idNumber: '',
      },
      headers: [
        {text: '', value: 'actions'},
        {text: 'Mối quan hệ', value: 'relationType'},
        {text: 'Họ & tên', value: 'memberName'},
        {text: 'SDT', value: 'phoneNumber'},
        {text: 'Ngày sinh', value: 'doB'},
        {text: 'CCCD', value: 'idNumber'}
      ],
      familyMemberHeaders: [
        {text: '', value: 'actions'},
        {text: 'Mối quan hệ', value: 'relationType'},
        {text: 'Tên', value: 'memberName'},
        {text: 'CCCD', value: 'idNumber'},
        {text: 'SDT', value: 'phoneNumber'},
      ],
      tab: null
    }
  },
  watch: {
    
    "dialog": {
      handler(val) {
        if(val) {
          console.log(val)
          this.fetchDepartment()
        } else {
          //close dialog
          this.close()
        }
      }
    },
  },
  methods: {
    close() {
      Object.assign(this.form, this.defaultForm)
      this.noInsuranceNumber = false
      this.noTaxNumber = false
      this.itemCode = null
    },
    submitForm(){
      this.$refs.temporary_store_form.validate()
      if(this.isValid) {
        this.loading = true
        if(this.itemCode) {
          return TemporaryStoreApi.put(this.itemCode, this.form).finally(() => {
            this.loading = false
          })
        }else {
          return TemporaryStoreApi.post(this.form).finally(() => {
            this.loading = false
          })
        }
      }
    },

    fetchData() {
      this.initForm = true
      return TemporaryStoreApi.get(this.itemCode).then(d => {
        Object.assign(this.form, d)
        this.form.validFrom = this.$formatter.formatDate(this.form.validFrom, 'YYYY-MM-DD')
        this.form.validTo = this.$formatter.formatDate(this.form.validTo, 'YYYY-MM-DD')
      }).finally(() => {
        
      })
    },

    
    fetchDepartment() {
      return ApiServices.getDepartment('K00002').then(r => {
          this.departments = r.data
      })
    },
  },
}
</script>