<template>
    <div class="leave-index" >
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>
        <v-card>
            <v-card-title>
                <v-text-field outlined hide-details placeholder="Nhập Mã NS, tên NS,... để tìm kiếm" v-model="globalSearch" label="Tìm kiếm" persistent-placeholder></v-text-field>
                <v-checkbox label="Chuyển vùng" v-model="changeArea" @change="fetchData" class="mx-2"></v-checkbox>

                <v-spacer></v-spacer>
                <v-btn color="teal" @click="fetchData" icon><v-icon color="teal">mdi-refresh</v-icon></v-btn>
                <v-btn @click="exportExcel" color="teal" icon> <v-icon>mdi-microsoft-excel</v-icon></v-btn>
                <create-button @click="dialog.state=true" ></create-button>
                <v-divider vertical class="mx-2"></v-divider>
                <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>
        </v-card>
        <v-card v-if="true" class="mt-5">
            <v-data-table height="600px"
            :search="globalSearch"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"

            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`item.confirmedDate`]="{ item }">
                    {{ item.confirmedDate == "01/01/0001" ? "" : item.confirmedDate}}
                </template>
                
                <template v-slot:[`item.status`]="{ item }">
                    <v-select :items="[
                        {text: 'Chờ duyệt', value: 0},
                        {text: 'Đã xử lý', value: 1},
                        {text: 'Từ chối', value: 2}
                    ]" v-model="item.status" :hide-details="true" outlined @change="changeItemStatus(item.id, item.status)">
                    </v-select>
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <!-- ********* Edit ****** -->
                    <!-- <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon> -->

                    <!-- ********* Xoa ****** -->
                    <v-btn x-small color="error" @click="deleteItem(item)" v-if="!item.isLock">
                        <v-icon small>
                            mdi-delete
                        </v-icon> Thu hồi
                    </v-btn>
                    
                    <v-icon
                        small color="disable"
                        v-else
                    >
                        mdi-lock
                    </v-icon>
                </template>


                <!-- <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template> -->
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import http from '@/components/services/http-common';
import Create from '@/views/Request/EmployeeRotation/Create.vue';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Leave',
    components: {
        CreateButton,
        "create-dialog": Create,
        "data-filter": DataFilter
    },
    watch: {
        tableParams: {
            handler (val) {
                this.fetchData();
                this.fetchCity();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month: {
            handler(val)
            {
                this.filters.from = moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to = moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },

    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data() {
        return {
            ROOT_URL: "EmployeeRotationRequests/",

            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //filters
            month: moment().format("YYYY-MM"),
            changeArea: false,
            globalSearch:"",
            filters:{
                code:null,
                employeeName:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            rowCount: 0,

            //TABLE
            headers: [
                {text:"", value: "actions"},
                {text: "Mã", value: "id"},
                {text: "Mã nhân sự", value: "employeeCode"},
                {text: "Tên nhân sự", value: "employeeName"},
                {text: "Chuyển từ", value: "fromDepartmentName"},
                {text: "Chuyển đến", value: "toDepartmentName"},
                {text: "Bắt đầu từ", value: "validFrom"},
                // {text: "Kết thúc vào", value: "validTo"},
                {text: "Người gởi yêu cầu", value: "senderName"},
                {text: "Trạng thái", value: "status"},
                {text: "Ngày tạo", value: "createdAt"},
                {text: "Chấp nhận vào", value: "acceptedAt"},
                {text: "Loại", value: "type", width: "200px"},
                {text: "Ghi chú", value: "note", width: "200px"},
            ],

            items: [],
            options: {},
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

        }
    },

    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        
        status(value) {
            let result = {};
            switch(value) {
                case 0: {
                    result.text = "Chờ duyệt";
                    result.color = "warning"
                    break;
                }
                case 1: {
                    result.text = "Đã duyệt"
                    result.color = "success"
                    break;
                }
                case 2: {
                    result.text = "Từ chối"
                    result.color = "error"
                    break;
                }
                default:{
                    result.text = "unknown"
                    break;
                }
            }
            return result;
        },

        //DATA JS
        fetchData () {
            return http.get(`${this.ROOT_URL}hr`, {
                params: {
                    month: this.month,
                    changeArea: this.changeArea
                }
            })
            .then(res => {
                this.items = res.data;
                this.rowCount = res.data;
                this.isLoading = false;
            })
        },
        exportExcel(){
            return http.get(this.ROOT_URL+"hr/export", {
                params: {
                    month: moment(this.month).format('YYYY-MM-DD')
                },
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH SACH_PHIEU_HT_${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },

        changeItemStatus(id, status){
            return http.get(`${this.ROOT_URL}hr/change-status`, {
                params: {
                    id: id,
                    status: status
                }
            })
            .catch(()=> {this.fetchData()})
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "undo/" +this.targetItem.id).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    
    created() {
        this.fetchData();
    }
}
</script>