<template>
    <div class="role">
        <create-dialog ref="createDialog"></create-dialog>
        <user-role v-model="roleUserDialog" :item="currentItem" @closeDialog="roleUserDialog=false" ></user-role>
        <v-card>
            <v-card-title>
                Danh sách phân quyền
            <v-spacer></v-spacer>
            <v-text-field outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Tìm kiếm"
                single-line
                hide-details style="max-width:300px"
            ></v-text-field>
            <create-button @click="openDialog"></create-button>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="roles"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="mr-2"
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                    
                    <v-icon
                        small
                        @click="userDialog(item)"
                    >
                        mdi-account-arrow-left
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>

import http from '@/components/services/http-common'
import RoleCreateDialog from './Create'
import RoleUser from './User'

export default {
    name: "Role-index",
    components: {
        "create-dialog": RoleCreateDialog,
        "user-role": RoleUser,
    },
    data() {
        return {
            currentItem: null,
            roleUserDialog: false,
            roles: [],
            search:"",
            dialogDelete:  false,
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            headers: [
                { text: 'Mã quyền', value: 'code' },
                {
                    text: 'Tên quyền',
                    align: 'start',
                    sortable: false,
                    value: 'roleName',
                },
                { text: 'Mô tả', value: 'description' },
                { text: 'Thao tác', value: 'actions', sortable: false },
            ],
            icon: {
                icon: "mdi-plus-thick",
                to: "employee.create"
            },

        }
    },
    methods: {
        userDialog(item) {
            this.roleUserDialog = true;
            this.currentItem = item;
        },
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData() {
            return http.get("roles",{
                params: {dropDown:1}
            }).then(res => {
                console.log(res.data)
                this.roles = res.data;
            }).catch(err => {
                console.log(err);
            })
        },
        cfmDeleteItem() {
            http.delete("roles/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err);
            });
        },
        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate(item.code);
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
    },
    created () {
        this.fetchData();
    }
}
</script>