<template>
  <v-card>
    <create-schedule-dialog :dialog="createScheduleDialog" @close="createScheduleDialog=false, selectItem=null" :select="selectItem" ></create-schedule-dialog>
    <v-toolbar color="white" flat>
        <v-toolbar-title class="mx-4">Danh sách lịch trực</v-toolbar-title>
        <v-spacer></v-spacer>
        
        <!-- <cfm-dialog :dialog="cfmDialog" :item="selectItem" @close-cfm-dialog="cfmDialog=false, selectItem=null"></cfm-dialog> -->
        <v-menu
            left
            bottom offset-y
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="success" class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    Hành động<v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>

            <v-list>
            <v-list-item @click="createScheduleDialog=true">
                <v-list-item-icon>
                  <v-icon color="primary" right>mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Thêm phiếu </v-list-item-title>
            </v-list-item>
            </v-list>
        </v-menu>
    </v-toolbar>
    <v-card-text>
      <v-text-field block outline dense hide-details prepend-inner-icon="mdi-magnify" v-model="search" placeholder="Tìm kiếm"></v-text-field>
      <v-data-table 
        :items="schedules"
        :headers="headers"
        :search="search"
        :sort-desc="['deptType']"
        @contextmenu:row.prevent="rowRightClickHandler"
      >
      <template v-slot:[`item.actions`]={item}>
        <v-btn color="primary" small @click="createScheduleDialog=true, selectItem=item.id">Chi tiết</v-btn>
      </template>
      </v-data-table>

      <!-- right click menu -->
      <!-- <r-click-menu :showMenu="rClickMenu" :x="rClickMenuX" :y="rClickMenuY" @open-ip-dialog="openIpDialogHandler" @close-rclick-menu="rClickMenu=false"></r-click-menu>
      <ip-dialog :show="ipDialog" :form="formIp" :ipList="ipList" :selectItem="selectItem" @close="ipDialog=false"></ip-dialog> -->

    </v-card-text>
  </v-card>
</template>

<script>
import ItScheduleService from '../../services/ItScheduleService'
import CreateScheduleDialog from './CreateScheduleDialog'
// import CfmDialog from './CfmDialog.vue'

export default {
  components:{
    // CfmDialog
    CreateScheduleDialog
  },

  data() {
    return {
      schedules: [],
      selectItem: null,
      search: "",
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Tháng', value: 'month'},
        {text: 'Description', value: 'description'},
        {text: 'Tạo bởi', value: 'createdBy'},
        {text: 'Tạo vào', value: 'createdAt'},
        {text: 'Sửa bởi', value: 'updatedBy'},
        {text: 'Sửa vào', value: 'updatedAt'},
        {text: 'Hành động', value: 'actions'},
      ],

      //dialog IP
      ipDialog: false,
      ipList: '',
      formIp: {
        ip1: '',
        ip2: '',
        ip3: ''
      },

      //cfmDialog
      cfmDialog: false,
      createScheduleDialog: false



    }
  },
  methods: {
    rowRightClickHandler(e, select) {
      this.rClickMenuX = e.clientX
      this.rClickMenuY = e.clientY
      this.rClickMenu = true
      this.selectItem = select.item
    },

    openIpDialogHandler() {
      this.ipDialog = true
      this.departmentService.getDeptIp(this.selectItem.code).then(d => {
        this.ipList = d
        let ipArr = this.ipList.split(',')
        this.formIp.ip1 = ipArr[0]
        this.formIp.ip2 = ipArr[1]
        this.formIp.ip3 = ipArr[2]
      })
    },

    closeIpDialogHandler() {
      this.selectItem = {}
      this.ipDialog = false
    },
  },

  itScheduleService: null,
  created() {
    this.itScheduleService = new ItScheduleService();
  },
  mounted() {
    this.itScheduleService.gets().then(d => this.schedules=d)
  }
  
}
</script>