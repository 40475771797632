import http from "@/components/services/http-common"
import moment from 'moment';

class OcSupport {
  gets(month) {
    return http.get('OcSupport', {
      params: {
        month: moment(month).format("YYYY-MM-DD")
      }
    }).then(r => {
      return r.data
    })
  }
}

export default new OcSupport();