
import http from '@/components/services/http-common';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
// import CreateDialog from './Create.vue';
import * as moment from "moment/moment";
// import Export from './Export.vue';
// import Create from './Create';

export default {
    name: "ConfirmQuit",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter,
        // 'export-dialog': Export,
        // "create-dialog": Create,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "quitRequests/manager",
            MANAGER_ACCEPT_URL: "quitRequests/manager-accept/",
            ASM_ACCEPT_URL: "quitRequests/asm-accept/",
            HR_ACCEPT_URL: "quitRequests/hr-accept/",
            
            exportDialog: false,
            //filter
            dialog:false,
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            month: moment().format("YYYY-MM"),
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
                status: -1,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                date: null,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                // { text: 'Thao tác', value: 'actions', sortable: false },
                { text: 'Mã', align: 'start', value: 'code', width: '7rem'},
                { text: 'Mã NS', align: 'start', value: 'quitEmployeeCode', width: '7rem'},
                { text: 'Nhân viên', align: 'start', value: 'quitEmployee', width: '9rem'},
                { text: 'Ngày nghỉ', align: 'start', value: 'quitDateStr', width: '7rem'},
                { text: 'Lý do', align: 'start', value: 'quitReason', width: 200},
                { text: 'TCN duyệt', align: 'start', value: 'managerAccept', width: 250},
                { text: 'ASM duyệt', align: 'start', value: 'asmAccept', width: 250},
                { text: 'HR duyệt', align: 'start', value: 'hrAccept', width: 250},
            ],

            HRquitStatuses: [
                {text: "Mới", value: 0},
                {text: "Chấp nhận", value: 1},
                {text: "Từ chối", value: 2},
                {text: "Nghỉ ngang", value: 3},
            ],
            
            quitStatuses: [
                {text: "Mới", value: 0},
                {text: "Chấp nhận", value: 1},
                {text: "Từ chối", value: 2},
            ],

            managerAcceptDialog: false,
            asmAcceptDialog: false,
            hrAcceptDialog: false,

            managerAccept: {
                quitDate: null,
                status: 0
            },
            asmAccept: {
                quitDate: null,
                status: 0
            },
            hrAccept: {
                quitDate: null,
                status: 0,
                note: ""
            }
            
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.items;
            })
            .catch(err => {

            })
        },

        accept(item) {
            return http.get(this.ROOT_URL + "accept/" + item.code).then(res => {
                // let eventObj = {
                //     color: "success",
                //     message: "Cập nhật thành công",
                //     toast: true,
                // };
                // this.messageToast(eventObj);
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            return http.get(this.ROOT_URL + "decline/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            return http.get(this.ROOT_URL + "undo/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },

        cfmDeleteItem() {
            http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },

        managerConfirm(status, id)
        {
            return http.get(this.MANAGER_ACCEPT_URL+this.targetItem.code, {
                params:{
                    status: this.managerAccept.status,
                    quitDate: this.managerAccept.quitDate
                }
            }).then(res => {return res});
        },

        asmConfirm(status, id)
        {
            return http.get(this.ASM_ACCEPT_URL+this.targetItem.code, {
                params:{
                    status: this.asmAccept.status,
                    quitDate: this.asmAccept.quitDate
                }
            }).then(res => {return res}).catch(e => {
                var item = this.items.find(x=>x.code == id);
                item.asmAccept = !status;
                return});
        },

        hrConfirm(status, id)
        {
            return http.get(this.HR_ACCEPT_URL+this.targetItem.code, {
                params:{
                    status: this.hrAccept.status,
                    quitDate: this.hrAccept.quitDate
                }
            }).then(res => {return res}).catch(e => {
                var item = this.items.find(x=>x.code == id);
                item.hrAccept = !status;
                return});
        },

    },
    created () {
        this.fetchData();
    }
}