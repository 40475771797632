<template>
  <v-card color="teal darken-4 rounded-xl" >
    <v-card-title class="text-title green--text text--lighten-4">{{ $t("employee_page.home.media_and_annoucement") }}</v-card-title>
    <v-card-text v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="orange"
      ></v-progress-circular>
    </v-card-text>
    <v-card-text style="height:70vh; overflow: auto" class="px-6" v-else>
      <v-card v-for="item in data" :key="item.code" flat elevation="6" class="mb-6 rounded-lg">
        <v-card-title class="text-subtitle-1 text-primary font-weight-bold">
          <v-avatar size="32px" class="mr-2">
            <v-img :src="'https://tshrmapi.trungsonpharma.com/'+item.createdByAvatar"></v-img>
          </v-avatar> {{item.createdBy}}
        </v-card-title>
        <v-card-subtitle>
          {{ $t("employee_page.home.media.posted_at") }} {{formatDate(item.createdAt)}}
        </v-card-subtitle>
        <v-img
          height="250"
          :src="item.avatar == null?'/static/annoucement.jpg': item.avatar"
        ></v-img>
        <v-card-title class="teal--text text--darken-4">
          {{item.title == 'null' ? '' : item.title}}
        </v-card-title>
        <v-card-text style="max-height: 250px; overflow: hidden">
          <div class="overlay-container">
            <span v-html="item.body"></span>
            <div class="overlayx"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-4" outlined class="mx-2" :to="{ name:'NewsDetail', params: { id: item.code }}">{{ $t("employee_page.home.media.read_more") }}</v-btn>

          <v-btn icon class="ml-2" v-if="item.pdf" :href="item.pdf"><v-icon color="red">mdi-attachment</v-icon></v-btn>
          <span v-if="item.pdf">
            {{ $t("employee_page.home.media.attachments") }}
          </span> 
        </v-card-actions>
      </v-card>
    </v-card-text>
    
  </v-card>
</template>
<script>
import http from '../components/services/http-common';
import * as moment from "moment/moment";
export default {
  // props:['data'],
  data() {
    return {
      data: [],
      loading: false
    }
  },
  methods: {
    formatDate(value, format = "DD/MM/YYYY") {
        return moment(value).format(format);
    },
    getData() {
      this.loading = true
      return http.get('contents/web/main').then(res => {
        this.data = res.data.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>
.overlay-container {
  position: relative;
}
.overlayx {
  width: 100%; /* Adjust width as needed */
  height: 100%;
  max-height: 250px; /* Adjust height as needed */
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .0), rgba(255, 255, 255, 1));
  pointer-events: none;
  top: 0px;
  left: 0px;
}

</style>