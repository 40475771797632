import WareHouseSection from '@/views/layouts/SubLayouts/WareHouseSection.vue';
//*********** Hiring */
import WHDathang from '@/views/WareHouse/DatHang/Index';
// import ItScheduleList from '@/views/ItSection/pages/ItSchedule/ItScheduleList';

export default [
    {
      path: 'warehouse-section',
      name: 'WareHouseSection',
      component: WareHouseSection,
      children:[
        {
            path: 'wh-dat-hang',
            name: 'WHDathang',
            component: WHDathang,
        },
        
      ]
    },
    
  ]
