<template>
    <v-dialog width="600px" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="green darken-4" dark  v-bind="attrs" v-on="on" block>
                <v-icon left>mdi-clipboard-account-outline</v-icon> <span class="orange--text text--lighten-2 font-weight-bold">Thực hiện đánh giá</span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Đánh giá nhân viên
                <v-spacer></v-spacer>
                <v-icon @click="dialog=false">mdi-close</v-icon>
            </v-card-title>
            <v-data-table :items="items" hide-default-footer disable-pagination :headers="headers">
                <template v-slot:[`body.append`]="{  }">
                    <tr>
                        <td></td>
                        <td><v-text-field dense v-model="sum" type="number"></v-text-field></td>
                    </tr>
                </template>
                
                <template v-slot:[`item.score`]="{item}">
                    <v-edit-dialog :return-value.sync="item.score" @save="save(item)" large>
                    {{ item.score }}
                    <template v-slot:input>
                        <v-select v-model="item.score" style="max-width:7rem" label="Đánh giá" :items="[
                            {text: 'Đạt', value: 1},
                            {text: 'Không đạt', value: 0},
                        ]"></v-select>
                        <!-- <v-text-field v-model="item.score" label="Edit" single-line counter type="number" min="0" max="4"></v-text-field> -->
                    </template>
                    </v-edit-dialog>
                </template>
            </v-data-table>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small text color="teal">Lưu</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common'
export default {
    props:["item"],
    data() {
        return {
            dialog: false,
            data:{},
            items:[],
            headers:[
                {text:"Tiêu chí đánh giá", value: "candidateTargetCodeNavigation.name"},
                {text:"Điểm", value: "score", width: 100},
                {text:"Ghi chú", value: "note"},
            ]
        }
    },
    watch:{
        dialog: function (value) {
            if(value){
                this.fetchData();
            }
        }
    },
    computed:{
        sum:  function () {
            var result = 0 ;
            if(this.items.length>0)
                result= this.items.reduce((a, {score}) => Number.parseInt(a) + Number.parseInt(score), 0);
            return result;
        }
    },
    methods:{
        fetchData() {
            if(this.item)
            {
                return http.get("candidates/appraise/"+this.item.code).then(res => {
                    console.log(res.data);
                    this.items = res.data.candidateAppraiseTargets;
                    this.data =res.data;
                })
            }
        },
        save(item){
            return http.get("candidates/set-score", {
                params: {
                    appraise: this.data.code,
                    target: item.candidateTargetCode,
                    score: item.score,
                }
            }).then(res => {

            })
        }
    },
    created() {
    }
}
</script>