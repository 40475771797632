const googleQRURL = "https://chart.googleapis.com/chart?";
import http from '@/components/services/http-common';
import moment from 'moment/moment';
const FETCH_DEPARTMENT_URL = ""
export default {
    props:["value", "item"],
    watch:{
        value: {
            handler(val){
                if(val)
                {
                    // this.fetchContractType();
                    // if(this.employee){
                    //     this.fetchEmployee(this.employee);
                    //     this.form.employeeAcode = this.employee;
                    // }
                    if(this.isEdit)
                    {
                        this.fetch();
                        this.headerCreated = true;
                    }
                }
                else{
                    this.close();
                }
            }
        },
        "form.name":{
            handler(val){
                this.form.params = this.convertToSlug(val);
            }
        },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            endpoint: `https://private-label.trungsonpharma.com/`,
            qrSrc: "",
            valid: false,
            form: {
                code: "",
                codeTypeSupport: "",
                codeDepartmentReceive: "",
                titleSender: "",
                descriptionSender: "",
                files: undefined,
                status:"SS1",
                progressSupports: []
            },
            defaultForm: {
                code: "",
                codeTypeSupport: "",
                codeDepartmentReceive: "",
                titleSender: "",
                descriptionSender: "",
                files: undefined,
                status:"SS1",
                progressSupports: []
            },

            /**header */
            departments: [],
            /** Detail Header*/
            detailHeaders: [
                {text:"Tên dụng cụ", value: "property", width:"7rem"},
                {text:"Số lượng", value: "quantity", width:"7rem"},
                {text:"", value: "actions", width:"7rem", align:"center"},
            ],
            types:[],
            detail:{
                propertyCode:undefined,
                quantity:0,
            },
            defaultDetail:{
                propertyCode:undefined,
                quantity:0,
            },
            detailDefault:{
                propertyCode:undefined,
                quantity:0,
            },
            detailItems:[],

            /**Stepper params */
            headerCreated: false,

            //receive
            receiveDialog:false,
            completeDialog:false,
            denyDialog:false,

            bottomNav: "info"

            /** Progress */
        }
    }, 
    methods: {
        isImg(string){
            var result = false;
            var IMG_EXTENSION = ["jpg", "png", "gif", "bmp", "jpeg"];
            IMG_EXTENSION.every(e=> {
                result = string.includes(String(e));
                if(result)
                    return false;
            })
            console.log(result);
            return result;
        },
        zoom(string){
            if( !this.isImg(string)){
                window.open(string);
            }else{
                var image = new Image();
                image.src = string;
            
                var w = window.open("",'_blank');
                w.document.write(image.outerHTML);
                w.document.close(); 
            }
        },
        getUnit(property) {
            var data = this.properties.find(x=>x.code == property);
            if(!data)
                return ""
            else
                return data.propertyUnitName
        },
        close(){
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
            this.headerCreated = false;
            this.bottomNav = "info";
        },

        save() {
            this.$refs.form.validate();
            if(this.valid)
            {
                if(this.isEdit)
                {
                    var url = `supports/${this.item.code}`;
                    return http.put(url, this.form).then(res => {
                        this.$emit("fetchData");
                    });
                }else {
                    let form_data = new FormData();

                    for ( var key in this.form ) {
                        if(Array.isArray(this.form[key]))
                        {
                            let array = this.form[key];
                            array.forEach(e => {
                                form_data.append(key, e);
                            });
                        }else
                            form_data.append(key, this.form[key]);
                    }
                    
                    let config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    return http.post("supports", form_data, config).then(res => {
                        this.$emit("fetchData");
                        this.headerCreated = true;
                        this.form = Object.assign({}, res.data.data);
                    });
                }
            }
        },
        setStatus(status){
            let url = `supports/set-status/${this.item.code}`
            return http.get(url,{
                params:{
                    status: status
                }
            }).then(res => {
                this.form.status=status;
                this.receiveDialog = false;
                this.completeDialog = false;
                this.denyDialog = false;
                this.$emit("fetchData");
            })
        },
        fetch() {
            let url = `supports/${this.item.code}`;
            return http.get(url).then(res => {
                console.log(res.data);
                this.form = Object.assign({}, res.data);
            })
        },
        fetchDepartment()
        {
            return http.get("propertyRequests/get-departments").then(res => {
                this.departments = res.data;
            });
        },
        progressMapping(datas) {
            var result = [];
            datas.forEach(x=> {
                if(x.dateSenderStr){
                    let itemS= {
                        date: x.dateSenderStr,
                        description: x.descriptionSender,
                        status: x.acceptSender,
                        type: "sender"
                    }
                    result.push(itemS)
                }
                if(x.receiveDateStr){
                    let itemR = {
                        date: x.receiveDateStr,
                        description: x.descriptionReceive,
                        status: x.acceptReceive,
                        type: "receive"
                    }
                    result.push(itemR)
                }
            });
            result.sort((a, b) => {
                return a.date - b.date;
            });
            return result;
        },
        fetchType()
        {
            return http.get("typeSupports").then(res => {
                this.types = res.data;
            });
        },
        fetchDetail() {
            let url = `propertyRequests/detail/${this.form.code}`
            return http.get(url).then(res => {
                this.detailItems = res.data;
            })
        },
        createDetail() {
            let url = `propertyRequests/detail/${this.form.code}`
            return http.post(url, {
                propertyCode: this.detail.propertyCode,
                quantity: this.detail.quantity,
            }).then(res => {
                var item = {
                    propertyCode: res.data.propertyCode,
                    property: res.data.property,
                    quantity: res.data.quantity
                };
                this.detail=Object({}, this.defaultDetail);
                this.detailItems.push(item);
            });
        },
        deleteDetail(item) {
            let url = `propertyRequests/detail/${this.form.code}`;
            return http.delete(url, {
                data:{
                    propertyCode: item.propertyCode,
                }
            }).then(res => {
                this.detailItems = this.detailItems.filter(x=>x.propertyCode != res.data.data.propertyCode)
            })
        }

        //SERVICES
    },
    mounted() {
        this.fetchDepartment();
        this.fetchType();
    }
}