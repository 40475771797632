import http from '@/components/services/http-common';
import apiService from '@/components/services/ApiServices';
import * as moment from "moment/moment";

import CreateDialog from '../Create.vue';

export default {
    components: {
        "create-dialog": CreateDialog
    },
    name: 'MonthlyEmployee',
    props:["breadcrumbs", "icon"],
    data(){
        return {
            isLoading: true,
            items: [],
            dialog: false,
            item: null,
            search:"",
            headers:[
                // { text: 'Thao tác', value: 'actions', sortable: false, width: "7rem"},
                { text: 'Mã NS', value: 'employeeCode', sortable: false, width: "7rem"},
                { text: 'Tên', value: 'name', sortable: false, width: "7rem"},
                { text: 'Phòng', value: 'department', sortable: false, width: "7rem"},
                { text: 'Lần cuối checkin', value: 'lastDate', sortable: false, width: "7rem"}
            ],
            month: moment().format("YYYY-MM-DD"),

            cfmItem: undefined,
            cfmDeleteDialog:false,

            filter: {
                from: moment().format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD'),
            }
        }
    },
    watch :{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        
        filter: {
            handler(newVal, oldVal) {
                console.log(newVal, oldVal);
                this.fetchData(this.filter);
            },
            deep: true,
        },
    },
    methods:{
        fetchData(){
            return apiService.fetchCurrentEmployee(this.filter).then(res => {
                this.items  = res.data.data;
                this.isLoading = false;
            })
        },
        
        editItem(item) {
            this.dialog= true;
            this.item = item;
        },
        
        cfmDelete(item) {
            this.cfmDeleteDialog=true;
            this.cfmItem= item;
        },

        deleteItem()
        {
            // let url = `CurrentEmployees/${this.cfmItem.code}`
            // return http.delete(url).then(res=>{
            //     this.fetchData();
            // });
        },
        
        exportData(){
            return http.get("CurrentEmployees/export", {
                params: this.filter,
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DS_tinh_trang_cham_cong_${moment(this.filter.from).format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },
    },

    created(){
        this.fetchData();
    }
}