<template>
  <div>
    <v-card>
            <v-toolbar color="white" flat>
                <v-toolbar-title class="mx-4">Danh sách phiếu</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="fetchData"><v-icon left >mdi-refresh</v-icon> Làm mới</v-btn>
                
                <v-dialog
                    ref="monthDialog"
                    :return-value.sync="month"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense style="max-width: 200px;"
                        v-model="month"
                        label="Chọn tháng *"
                        prepend-icon="mdi-calendar"
                        readonly hide-details
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker dense locale="vi"
                    v-model="month"
                    type="month"
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.monthDialog.save(month)"
                    >
                        Chọn
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
                
                <!-- //actions -->
                <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success" class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="createDialog=true">
                          
                          <vue-excel-xlsx
                            :data="items"
                            :columns = "convertToExcelLabel(headers)"
                            :file-name="'DANH_SACH_PHIEU_HT'"
                            :file-type="'xlsx'"
                            :sheet-name="'sheetname'"
                            >
                            <v-list-item-title>Xuất excel <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                          </vue-excel-xlsx>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            
            <v-card-title>
                <v-text-field hide-details v-model="search" placeholder="Tìm kiếm" label="Tìm kiếm" persistent-placeholder outlined dense></v-text-field>
            </v-card-title>
            <v-data-table :items="items" :headers="headers" fixed-header height="calc(100vh - 300px)" :search="search">
              <template v-slot:[`header.departmentSenderName`]="{header}">
                <v-icon small left color="success">mdi-arrow-right</v-icon> {{ header.text }}
              </template>
              <template v-slot:[`header.departmentReceiveName`]="{header}">
                <v-icon small left color="red">mdi-arrow-left</v-icon> {{ header.text }}
              </template>
              <template v-slot:[`header.senderName`]="{header}">
                <v-icon small left color="success">mdi-arrow-right</v-icon> {{ header.text }}
              </template>
              <template v-slot:[`header.receiveName`]="{header}">
                <v-icon small left color="red">mdi-arrow-left</v-icon> {{ header.text }}
              </template>
              <template v-slot:[`item.supportStatusName`]="{item}" >
                <v-chip :color="statusDict[item.supportStatusName].color" dark><b>{{ item.supportStatusName }}</b></v-chip>
              </template>

                <template v-slot:[`item.actions`]="{item}" >
                    <!-- <edit-button @click="edit(item)"></edit-button>
                    <delete-button @click="deleteItem(item)"></delete-button> -->
                    <!-- <v-btn x-small color="error"  @click="deleteItem(item)"><v-icon left>mdi-delete</v-icon> Xóa</v-btn> -->
                    
                    <v-menu dense left bottom offset-y >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" x-small
                                v-bind="attrs"
                                v-on="on"
                            >
                                Thao tác<v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="deleteItem(item)" dense>
                                <v-list-item-icon>
                                    <v-icon color="error">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Xóa </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="edit(item)" dense>
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-pen</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Chi tiết </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                
                <template v-slot:[`item.description`]="{item}" >
                    <span v-html="item.description"></span>
                </template>

            </v-data-table>

        </v-card>
  </div>
</template>

<script>
import OcSupport from './OcSupport';

export default {
  watch: {
    month(val){
      this.fetchData();
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      return OcSupport.gets(this.month).then(d => {
        this.items = d
      })
    },
    
    convertToExcelLabel(value) {
      if(Array.isArray(value)) {
          var exceptCols = ['actions', 'images']
          return value.filter(x=>!exceptCols.includes(x.value)).map(x => {
              return {
                  label: x.text,
                  field: x.value
              }
          })
      }
    },
  },
  data() {
    return {
      headers: [
        { text: 'Mã', value: 'code', sortable: false, width: '2rem'},
        { text: 'Ngày gởi', value: 'sendDateStr', sortable: false, width: '7rem'},
        { text: 'Phân loại', value: 'typeSupportName', sortable: false, width: '7rem'},
        { text: 'Phòng ban y/c hỗ trợ', value: 'departmentSenderName', sortable: false, width: '7rem'},
        { text: 'Phòng ban tiếp nhận', value: 'departmentReceiveName', sortable: false, width: '7rem'},
        { text: 'Người y/c hỗ trợ', value: 'senderName', sortable: false, width: '7rem'},
        { text: 'Người tiếp nhận', value: 'receiveName', sortable: false, width: '7rem'},
        { text: 'Ghi chú người gởi', value: 'descriptionSender', sortable: false, width: '7rem'},
        { text: 'Trạng thái', value: 'supportStatusName', sortable: false, width: '7rem'},
      ],
      statusDict: {
        'Hoàn thành': {color: 'green'},
        'Tiếp nhận': {color: 'orange'},
        'Tạo mới': {color: 'blue'},
        'Từ chối': {color: 'red'},
      },
      items: [],
      search: '',
      month: null,
    }
  }

}
</script>