<template>
  <div>
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
    
    <v-row>
        <v-col cols="12" md="9">
          <v-card :loading="isLoading">
              <v-card-title>Thông tin người thân</v-card-title>

              <v-card-text class="text--primary">
                    <v-form ref="recruitment_request_form" id="recruitment_request_form" v-model="isValid" @submit.prevent="save">
                      
                      <span class="green--text text--darken-4">THÔNG TIN CƠ BẢN</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Tên nhân viên
                        </v-col>
                        <v-col cols="12" md="6">
                          <!-- <v-text-field outlined hide-details="auto" v-model="form.employeeCode" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field> -->
                          
                          <v-autocomplete item-text="name" item-value="code" :items="employees" v-model="form.employeeCode" clearable 
                          label="Nhân viên" hide-no-data @change="fetchEmployee" :rules='[v=>!!v||"Chọn dữ liệu"]'>
                              <template v-slot:selection="data">
                                  {{` ${data.item.name} - ${data.item.department}`}}
                              </template>
                              <template v-slot:item="data">
                                  {{` ${data.item.name} - ${data.item.department}`}}
                              </template>
                          </v-autocomplete>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Mối quan hệ
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" 
                          :items="relationTypes" item-text="value" item-value="key" v-model="form.relationType" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Tên người thân
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.memberName" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          CCCD người thân
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.memberId" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Ngày sinh
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined type="date" hide-details="auto" v-model="form.doB" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Số điện thoại
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.phoneNumber" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                         Người phụ thuộc
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-checkbox hide-details="auto" v-model="form.isDependant" :false-value="0" :true-value="1"></v-checkbox>
                          <!-- <v-text-field outlined hide-details="auto" v-model="form.isDependant" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field> -->
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center" v-if="form.isDependant==1">
                         Thời gian phụ thuộc
                        </v-col>
                        <v-col cols="6" md="3" v-if="form.isDependant==1">
                           <v-text-field type="date" v-model="form.validFrom"></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3" v-if="form.isDependant==1">
                           <v-text-field type="date" v-model="form.validTo"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                         Hoạt động
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-checkbox hide-details="auto" v-model="form.isActive" :false-value="0" :true-value="1"></v-checkbox>
                          <!-- <v-text-field outlined hide-details="auto" v-model="form.isDependant" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field> -->
                        </v-col>
                      </v-row>
                    </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
              </v-card-actions>
              
              <v-fab-transition >
                <v-btn style="bottom: 20px;"
                  color="success"
                  fab
                  dark
                  large
                  fixed
                  bottom
                  right offset
                  type="submit" form="recruitment_request_form"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-fab-transition>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn block type="submit" color="success" form="recruitment_request_form" style="width: 200px;" class="my-2">Lưu</v-btn>
          <v-btn block outlined type="button" color="error" style="width: 200px;" class="my-2" @click="$router.push({name: 'EmployeeFamily', query: {
            returnPage: $route.query.returnPage
          }})">Quay lại danh sách </v-btn>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import EmployeeFamilyIndexApi from './api.js'
import * as moment from "moment/moment";

import router from "@/router/index";
export default {
  watch: {
  },
  computed: {
    isEdit() {

      return !!this.$route.params.id
    },

    currentId() {
      if(this.$route.params.id){
        return this.$route.params.id
      } else 
        return null
    },

    breadcrumbs() {
      return [
        {
            text: 'Phiếu yêu cầu tuyển dụng',
            to: {name: 'EmployeeFamily'},
            exact: true
        },
        {
          text: this.isEdit ? 'Chỉnh sửa '+this.$route.params.id : 'Tạo mới',
          to: {name: this.isEdit ? 'EmployeeFamilyEdit' : 'EmployeeFamilyCreate'}
        },
      ]
    }
  },

  data() {
    return {
      isLoading: false,

      jobs: [],
      contractTypes: [],
      recruitTypes: [
        {text: 'Trong & ngoài kế hoạch', value: 'BOTH'},
        {text: 'Trong kế hoạch', value: 'INPLAN'},
        {text: 'Ngoài kế hoạch', value: 'OFFPLAN'},
        {text: 'Không có', value: 'NONE'},
      ],
      genders: [
        {text: "Nam", value: 1},
        {text: "Nữ", value: 0},
      ],
      employeeSearch: '',
      employees: [],
      relationTypes: [],
      workExps: [],
      appearances: [],
      educationLevels: [],

      educationMajorSearch: '',
      educationMajors: [],

      isValid: true,
      jobDescription: null,
      jobDescriptionShow: true,
      jobLoading:  false,
      form: {
        employeeCode: 'FROM_CLAIM',
        memberName: '',
        relationType: '',
        doB: null,
        phoneNumber: '',
        isDependant: 0,
        isActive: 1,
        validFrom: moment().format("YYYY-MM-DD"),
        validTo: '2100-01-01'
      },
      dateDialog: false
    }
  },
  methods: {
    save() {
      let thisRouter = router
      this.$refs.recruitment_request_form.validate()
      if(this.isValid) {
        if(!this.isEdit){
          return EmployeeFamilyIndexApi.post(this.form).then(() => {
            thisRouter.push({ name: 'EmployeeFamily' })
          }).catch(e => {
            alert("error")
          })
        } else {
          return EmployeeFamilyIndexApi.put(this.currentId, this.form).then(() => {
            thisRouter.push({ name: 'EmployeeFamily' })
          }).catch(e => {
            alert("error")
          })
        }
      }
    },

    getData() {
      this.isLoading = true

      return EmployeeFamilyIndexApi.get(this.currentId).then(d => {
        this.form = Object.assign({}, d.data)
        Object.assign(this.form, d.requirement)
      }).finally(() => {
        this.isLoading = false
      })
    },

    getJobDescription(val) {
      this.jobLoading = true
      EmployeeFamilyIndexApi.getJobDescription(val).then(x=> {
        this.jobDescription = x.description
      }).finally(()=> {
        this.jobLoading = false
      })
    },
    
    fetchEmployee() {
        return EmployeeFamilyIndexApi.fetchEmployee(this.EMPLOYEE_FETCH).then(d => {
            this.employees = d.map(i => {
                return {
                code: i.code,
                name: i.code+': '+i.name,
                department: i.department
              }
            });
        });
    },

  },
  created() {
    // EmployeeFamilyIndexApi.getDropDown().then(d => {
    //   this.contractTypes = d.contractTypes
    //   this.workExps = d.workExps
    //   this.appearances = d.appearances
    //   this.educationLevels = d.educationLevels
    //   this.educationMajors = d.educationMajors
    // })
    
    EmployeeFamilyIndexApi.getRelationType().then(r => {
      this.relationTypes = r.data
    })
    this.fetchEmployee()
  },

  mounted() {
    if(this.isEdit) {
      this.getData()
    }
  }
}
</script>