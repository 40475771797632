<template>
    <v-dialog v-model="dialog" max-width="600px" persistent :fullscreen="fullscreen">
        <v-card>
            <v-form v-model="isValid" ref="form" @submit.prevent="saveData" id="form" >
                <v-card-title>
                    {{isEdit ? "Thay đổi" :"Thêm"}} báo cáo hình ảnh
                    <v-spacer></v-spacer> 
                    <v-icon small @click="fullscreen=!fullscreen" class="mx-1" color="blue"> mdi-fullscreen</v-icon>
                    <v-icon small @click="dialog=false" class="mx-1"> mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row class="my-0">
                        <v-col cols="12" >
                            <v-select
                                outlined dense hide-details="auto"
                                :items="imageReportCategories"
                                v-model="form.imageReportCategoryCode"
                                label="Danh mục báo cáo" :rules="[
                                    v=>!!v || 'Chọn dữ liệu này'
                                ]"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" v-if="description">
                            <b>Quy cách: </b>
                            <i>{{description}}</i>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <v-text-field label="Tên báo cáo" v-model="form.name" :rules='[v=>!!v||"Chọn dữ liệu"]' outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <v-autocomplete item-text="name" item-value="code" :items="departments" v-model="form.departmentCode" outlined
                            label="Phòng(Chi nhánh)" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]'></v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-textarea label="Mô tả" v-model="form.description" :rules='[v=>!!v||"Chọn dữ liệu"]' outlined
                                hide-details></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-file-input label="Hình ảnh" hint="Chọn nhiều hình ảnh" multiple v-model="attachmentFile"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="submitDisable" type="submit"> Lưu</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    this.fetchCategory();
                    if(this.isEdit)
                        this.fetch().then(()=>{
                            this.fetchData = false;
                        });
                }
                else{
                    this.close();
                }
            }
        },

        // FETCHING DATA
        "form.departmentCode": function(val){
            this.fetchDepartment();
        },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
        description() {
            try {
                let returnStr = ""
                if(this.form.imageReportCategoryCode){
                    var item = this.imageReportCategories.find(x=>x.value == this.form.imageReportCategoryCode)
                    returnStr = item.description
                }
                return returnStr;

            } catch(e){
                alert(e)
                return ""
            }
        }
    },
    data() {
        return {
            ROOT_URL: "imageReports/",
            DEPARTMENT_FETCH: "departments/",

            isValid: true,
            fullscreen: false,
            departments: [],
            positions: [],
            scales: [],
            imageReportCategories: [],
            employeeSearch: null,
            employees: [],
            fetchData: true,
            attachmentFile: null,
            form: {
                name:"",
                description:"",
                departmentCode: null,
                attachmentFile:null,
                imageReportCategoryCode:"",
            },
            defaultForm:{
                name:"",
                description:"",
                departmentCode: null,
                attachmentFile:null,
                imageReportCategoryCode:"",
            },
            submitDisable: false,
        }
    },
    methods: {
        fetchDepartment(val) {
            return http.get(this.DEPARTMENT_FETCH).then(res => {
                this.departments = res.data;
            });
        },
        fetchPosition(val) {
            if(!this.fetchData)
            {
                this.positions=[];
                this.form.positionCode=null;
            }
            return http.get(this.POSITION_FETCH+val).then(res => {
                this.positions = res.data;
            });
        },
        
        
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                this.form = Object.assign({}, res.data.item);
            })
        },
        fetchCategory() {
            
            if(this.isEdit) {
                return http.get("imageReportCategories/fetch", {
                    params: {showOnly: 1}
                }).then(d => {
                    this.imageReportCategories = d.data.map(x => {return {
                        text: x.name,
                        value: x.code,
                        description: x.description
                    }})
                })
            } else {
                return http.get("imageReportCategories/fetch").then(d => {
                    this.imageReportCategories = d.data.map(x => {return {
                        text: x.name,
                        value: x.code,
                        description: x.description
                    }})
                })
            }
        },

        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                this.submitDisable = true;
                if(!this.isEdit){
                    var formData = new FormData();
                    if(this.attachmentFile != null){
                        this.form.attachmentFile = this.attachmentFile.forEach(file => {
                        formData.append("attachmentFile", file);
                        });
                    }
                    
                    for (const [key, value] of Object.entries(this.form)) {
                        formData.append(key, value);
                    }
                    return http.post(this.ROOT_URL, formData).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    }).finally(() => {
                        this.submitDisable = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    }).finally(() => {
                        this.submitDisable = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fullscreen = false;
            this.fetchData=true;
        }
    },
    created() {
        this.fetchDepartment();
    }
    
}
</script>