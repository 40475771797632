<template>
    <div class="create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="80%">
            <v-card>
                <v-card-title class="mb-5">
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} kỹ năng
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                        <v-row>
                            <v-col cols="4">
                                <v-autocomplete v-model="form.skillCode" :items="formData.skills" 
                                item-text="name" item-value="code"
                                label="Năng lực">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field v-model="form.name" outlined label="Tên kỹ năng">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-textarea v-model="form.description" outlined label="Mô tả">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark
                                color="blue darken-1"
                                type="submit"
                            >
                                Lưu
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import DatePicker from "@/components/DatePicker";
import http from '@/components/services/http-common';
import * as moment from "moment/moment";

export default {
    components: {
        "date-picker": DatePicker,

    },
    props: ["value"],
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
            }
        },
    },
    data() {
        return {

            // URL
            ROOT_URL: "abilities",
            
            //RULE
            rules: {
                //ANCHOR TAB 3 - Rules
                ContractTypeRules: [
                    v => !!v || 'Bắt buộc nhập loại hợp đồng'
                ]
            },

            // DATA
            valid: false,
            ShiftObject: "",
            formData: {
                skills: [],
            },
            form: {
                Code: "AUTO",
                skillCode: "",
                name: "",
                note:"",
            },

            defaultForm: {
                Code: "AUTO",
                skillCode: "",
                name: "",
                note:"",
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },

        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                this.form.shiftCode = this.ShiftObject.code;
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                }else {
                    http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {

                    });
                }
            }
        },

        fetchData() {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                this.form = Object.assign({}, item)
            })
            .catch(err => {
                console.log(err);
            });
        },
        
        fetchForm() {
            return http.get(this.ROOT_URL + "/fetch")
            .then(res => {
                //formData
                this.formData.skills = res.data.skills;
                console.log(this.formData)
            })
            .catch(err => {
                console.log(err);
            });
        },
    },
    created(){
        this.fetchForm();
    }
    
}
</script>