<template>
<div>
    <!-- <v-main> -->
      <v-container style="min-height:calc(100vh - 124px)" class="mt-3">
        <v-fade-transition mode="out-in">
          <!--The Main Content component go here-->
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    <!-- </v-main> -->
</div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
import http from '@/components/services/http-common';
export default {
    components:{
        SideBar
    },
    
    data(){
      return {
        overlay: true,
        dbMenus: [],
        items: [
            { title: "Tổng quan", icon: "mdi-chart-bell-curve", route:{name: "CandidateOverview"}, auth: "module:recruit"},
            { title: "Danh sách", icon: "mdi-bulletin-board", route:{ name: 'Candidate'}, auth: "module:recruit"},
            { title: "Công việc ứng tuyển", icon: "mdi-database", route:{name: "Job"}, auth: "module:recruit"},
            { title: "Yêu cầu tuyển dụng", icon: "mdi-database", route:{name: "AdminRecruitmentRequest"}, auth: "module:recruit"},
            { title: "Tiêu chí tuyển dụng theo asm", icon: "mdi-database", route:{name: "AsmBasePlan"}, auth: "module:recruit"},
        ],
      }
    },
    methods: {

      getMenu() {
        return http.get("menus/sections/RECRUIT").then(r => {
          this.dbMenus = r.data.data.map(x => {
            return {
              title: x.title,
              icon: x.icon,
              route: {name: x.routeName},
              auth: x==null ? '' :x.claims.split(','),
              subheader: x.type == 'SUBHEADER',
              group: x.type == 'GROUP' ? x.href : undefined,
              subs: x.subMenus ==null? undefined: x.subMenus.map(s => {
                return {
                  title: s.title,
                  icon: s.icon,
                  route: {name: s.routeName},
                  auth: x==null ? '' :s.claims.split(','),
                  subheader: s.type == 'SUBHEADER',
                }
              })
            }
          })

          // console.log(this.dbMenus)
        })
      },
    },

    created(){
      this.getMenu().then(() => {
        this.$emit('updateSidebar', this.dbMenus);
      })
    },
}
</script>