import http from "@/components/services/http-common";

const SURVEY_URL = "surveys";
const DEPARTMENT_URL = "departments";
const EMPLOYEE_URL = "employees";
export default class Api {
    
    getDepartments = () => http.get(`${DEPARTMENT_URL}`).then(r => r.data)

    getEmployees = () => http.get(`${EMPLOYEE_URL}/fetch`, {
        params: {
            detail: true
        }
    }).then(r => r.data)

    getTemplates = () => http.get(`${SURVEY_URL}`).then(r => { 
        return r.data
    })

    getTemplate = (id) => http.get(`${SURVEY_URL}/${id}`).then(r => { 
        return r.data
    })

    putTemplate = (id, data) => http.put(`${SURVEY_URL}/${id}`, data).then(r => { 
        return r.data
    })

    publishTemplate = (id) => http.get(`${SURVEY_URL}/${id}/publish`)

    postTemplate = (data) => http.post(`${SURVEY_URL}`, data).then(r => { 
        return r.data
    })
    
    deleteTemplate = (id) => http.delete(`${SURVEY_URL}/${id}`).then(r => { 
        return r.data
    })

    postQuestion = (id, data) => http.post(`${SURVEY_URL}/${id}/questions/create`, data).then(r => { 
        return r.data
    })
    
    getQuestions = (id) => http.get(`${SURVEY_URL}/${id}/questions`).then(r => { 
        return r.data
    })
    
    deleteQuestion = (id, questionId) => http.delete(`${SURVEY_URL}/${id}/questions/delete`, {
        params: {
            questionId: questionId
        }
    }).then(r => { 
        return r.data
    })

    changeQuestionOrder = (id, questionId, mode) =>  http.get(`${SURVEY_URL}/${id}/questions/change-order`, {
        params: {
            questionId: questionId,
            mode: mode
        }
    }).then(r => r.data)
}
