<template>
    <div class="info-update">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb> -->
        <!-- <create-dialog ref="createDialog" @CloseDialog="closeHandler" @fetchData="fetchData" v-model="dialog"></create-dialog> -->
         <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg">
                    <v-card-title>
                        <v-text-field label="Từ ngày" @change="fetchData"
                            v-model="filter.fromDate" type="date" outlined dense hide-details class="mx-2 my-1" style="max-width:18rem"></v-text-field>
                        <v-text-field label="Đến ngày" @change="fetchData"
                            v-model="filter.toDate" type="date" outlined dense hide-details class="mx-2 my-1" style="max-width:18rem"></v-text-field>
                        <v-text-field v-model="search" @keydown.enter="fetchData" class="mx-2 my-1" style="max-width:18rem" outlined dense
                            append-icon="mdi-magnify"
                            label="Tìm kiếm"
                            single-line
                            hide-details
                        ></v-text-field>
                        
                        <v-spacer></v-spacer>
                        <v-btn small @click="fetchData" icon><v-icon>mdi-refresh</v-icon></v-btn>
                        <create-button @click="openDialog"></create-button>
                    </v-card-title>
                </v-card>
                <v-card class="mt-5 rounded-lg">
                    <v-card-title>
                        Danh sách phiếu
                    </v-card-title>
                    <v-data-table
                    :mobile-breakpoint="0"
                    height="600px"
                    :headers="headers"
                    :items="items"
                    :items-per-page="-1"
                    loading-text="Đang tải dữ liệu..."
                    show-expand
                    :loading="isLoading"
                    item-key="code"
                    :single-expand="true"
                    :expanded.sync="expanded"
                    >
                        <template v-slot:[`item.typeCode`]="{ item }">
                            <v-select solo dense hide-details :items="types" v-model="item.typeCode" readonly></v-select>
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }">
                            {{ $formatter.formatDate(item.createdAt) }}
                        </template>
                        <template v-slot:[`item.anonymousType`]="{ item }">
                            <v-radio-group v-model="item.anonymousType" row readonly>
                                <v-radio :label="`Bình thường`" :value="0" color="success" ></v-radio>
                                <v-radio :label="`Ẩn danh`" :value="1"  color="indigo"></v-radio>
                            </v-radio-group>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <!-- <v-btn x-small @click="editItem(item)" class="mx-1">
                                <v-icon small>mdi-pencil</v-icon> Xem
                            </v-btn> -->
                            <v-btn @click="editItem(item)" icon class="mx-1" color="teal darken-4" dark>
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                            <v-btn @click="deleteItem(item)" icon class="mx-1" color="error">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:[`item.detail`]="{ item }">
                            {{ shortenString(item.detail) }}
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-select solo dense hide-details :items="status" v-model="item.status" readonly></v-select>
                        </template>


                    </v-data-table>
                </v-card>
            </v-col>
         </v-row>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="error" @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <create-dialog @reload="fetchData"></create-dialog>
    </div>
</template>

<script>
import CreateButton from '@/components/Crud/CreateButton'
import http from '@/components/services/http-common'
import InternalDenouceApi from './api.js'
import * as moment from "moment/moment";
import ApiServices from '@/components/services/ApiServices.js';
import Create from './Create'

export default {
    name: 'InfoUpdateIndex',
    components: {
        CreateButton,
        'create-dialog': Create
    },
    props:["breadcrumbs", "icon"],
    data () {
        return {
            // URLS
            ROOT_URL: "jobs",

            rootUrl: "positions",

            //dialog
            // dialog: {state: false, item: undefined},
            
            //datatable config
            totalItem: 0,
            items: [],
            stores: [],
            search:"",
            filter: {
                fromDate: moment().startOf("month").format("YYYY-MM-DD"),
                toDate: moment().endOf("month").format("YYYY-MM-DD")
            },
            status: [
                {text: "Nháp", value: -1 },
                {text: "Tạo mới", value: 0 },
                {text: "Hoàn tất", value: 1, },
                {text: "Từ chối", value: 2, }
            ],
            options: {},
            isLoading: false,
            dialogDelete: false,
            targetItem: undefined,
            employee: null,
            dialog:false,
            isValid: false,
            base64: '',

            expanded: [],
            types: [
                { text: "Khiếu nại", value: "KN" },
                { text: "Phản ánh", value: "PA" },
                { text: "Góp ý", value: "GY" },
            ],
            headers: [
              { text: 'Thao tác', align: 'start', sortable: false, value: 'actions', width: "150" },
              { text: 'Id', value: 'id' },
              { text: 'Loại', value: 'typeCode', width: "200px" },
              { text: 'Thông tin cá nhân', value: 'anonymousType'},
              { text: 'Nội dung', value: 'detail'},
              { text: 'Trạng thái', value: 'status', width: "200px" },
              { text: 'Ngày tạo', value: 'createdAt' },
            ],
        }
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
    //   dialog:{
    //     handler(val) {
    //       if(val) {
    //       this.openDialog()
    //       } else {
    //         this.closeHandler()
    //       }
    //     }
    //   },
      search:{
          handler() {
            this.options.page = 1;
          }
      },
    },
    methods:{
        openDialog(){
            this.$root.$emit('openDialog')
            // this.getEmployee()
        },
        fetchData () {
            this.isLoading = true
            return InternalDenouceApi.gets(this.filter.fromDate, this.filter.toDate).then(d => {
                this.items = d
            }).finally(() => {
                this.isLoading = false
            })
        },
        editItem(item) {
            this.$root.$emit('editDialog', item)
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return  InternalDenouceApi.remove(this.targetItem.id).then(() => {
                this.dialogDelete = false;
                this.fetchData()
            })
        },

        closeHandler() {
            // this.dialog = {state: false, item: undefined}
        },

        
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },

        //private
        shortenString(string, length =50) {
            if(string){
                return  string.substring(0,length);
            }else {
                return ""
            }
        }

    },


    mounted () {
        this.fetchData()
        // this.fetchOrigin()
        // this.fetchCity()
        // this.fetchBank()
        // ApiServices.fetchReligion().then(r => {
        //     this.religions = r.data
        // })
        // ApiServices.fetchEthnicity().then(r => {
        //     this.ethnicities = r.data
        // })
    },
    created()
    {
        this.$emit("setUrl", this.breadcrumbs);
    }, 
    destroyed() {
        this.$emit("setUrl", []);
    }
}
</script>

<style lang="stylus" scoped>

</style>