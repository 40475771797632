<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable >
      <v-card>
          <v-card-title class="font-weight-bold">{{isEdit ? 'Thay đổi': 'Tạo'}} dữ liệu{{isEdit ? item.id: ''}}
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="close">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-card-title>
          <v-card-text class="text--primary">
            <v-form ref="form" id="form" v-model="valid" @submit.prevent="save">
              <v-row class="my-0">
                <v-col cols="6">Mã </v-col>
                <v-col cols="6">
                  <v-text-field v-model="form.code" :rules="[v=> !!v || 'Bạn phải nhập dữ liệu này']" outlined hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="6">Chức vụ </v-col>
                <v-col cols="6">
                  <v-select outlined rounded filled hide-details="auto" v-model="form.positionCode" :items="positions" 
                  item-text="name" item-value="code"
                  @change="getPositionState"
                  ></v-select>
                </v-col>
                
                <v-col cols="6">Chức danh </v-col>
                <v-col cols="6">
                  <v-select filled rounded :disabled="!form.positionCode" outlined v-model="form.positionStateCode"
                   hide-details="auto" :items="positionStates" item-text="name" item-value="code"></v-select>
                </v-col>

                <v-col cols="6">Mức lương </v-col>
                <v-col cols="6">
                  <v-text-field type="number" v-model="form.baseSalary" outlined hide-details="auto"></v-text-field>
                </v-col>
                
                <v-col cols="6">Trạng thái </v-col>
                <v-col cols="6">
                  <v-select class="font-weight-bold" filled rounded :background-color="`${form.isActive ==1 ? 'green': 'red'} lighten-3`" solo v-model="form.isActive"
                   hide-details="auto" :items="[
                    {text: 'Đang hoạt động', value: 1},
                    {text: 'Đã tắt', value: 0},
                   ]"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" form="form" style="width: 200px">Lưu </v-btn>
              <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import api from './api.js'
import moment from 'moment/moment'
import Editor from '@/components/Editor/Editor.vue';
import ApiServices from '@/components/services/ApiServices.js'

export default {
  props:["value"],
  components:{
      Editor
  },
  watch:{
      dialog: {
          handler(val){
              if(val)
              {
                  // this.getEmployees()
                  // this.fetchContractType();
                  // if(this.employee){
                  //     this.fetchEmployee(this.employee);
                  //     this.form.employeeAcode = this.employee;
                  // }
                  console.log("check is edit")
                  if(this.isEdit)
                  {
                      this.fetch()
                      this.headerCreated = true
                  }
              }
              else{
                  this.close();
              }
          },
      },

      imageFiles() {
          this.form.images = ""
      },


  },
  computed: {
      device: function() {
          return this.devices.find(n => n.deviceId === this.deviceId);
      },
      isEdit() {
          return !!this.item;
      },
      validDetail(){
          console.log(this.rules.required);
      },
      templateHTMLFilled() {
          var result = this.templateHTML.replace("--EmployeeName--", this.form.employeeName)
                           .replace("--BirthDay--", this.formatDate(this.form.birthDay))
                           .replace("--Gender--", this.form.gender?'Nam':'Nữ')
                           .replace("--PhoneNumber--", this.form.phoneNumber)
                           .replace("--Mail--", this.form.mail)
                           .replace("--Address--", this.form.address)
                           .replace("--IdNumber--", this.form.idNumber)
                           .replace("--IdDate--", this.formatDate(this.form.idDate))
                           .replace("--IdPlace--", this.form.idPlace)
                           .replace("--ValidFrom--", this.formatDate(this.form.validFrom) )
                           .replace("--ValidTo--", this.formatDate(this.form.validTo))
                           .replace("--Length--", this.form.length)
                           .replace("--LengthType--", this.lengthTypeDictText[this.form.lengthType])
                           .replace("--Position--", this.form.positionName)
                           .replace("--PositionState--", this.form.positionStateName)
                           .replace("--ContractNumber--", this.form.contractNumber)
                           .replace("--Salary--", this.form.salary)
                           .replace("--ContractNumber--", this.form.contractNumber)
          return result
      },
      

      employeeReportCategoryDescription() {
          let result = "";
          if(this.categories.length>0){
              if(this.form.employeeReportCategoryCode)
              {
                  let resultObj = this.categories.filter(x=>x.code == this.form.employeeReportCategoryCode)
                  if(resultObj.length>0){
                      result = resultObj[0].description
                  }
              }
          }
          return result
      },
      imageStatus() {
          var result = {
              name: 'Hình ảnh khả dụng',
              color: 'green',
              value: 1
          }
          this.imageFiles.forEach(imageFile => {
              let lastModifiedTimestamp = imageFile.lastModified;
              let createdDate = moment(lastModifiedTimestamp)
              console.log(createdDate)
              if(createdDate.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
                  result = {
                      name: 'Hình ảnh cũ, kiểm tra lại các hình ảnh chụp trong hôm nay',
                      color: 'error',
                      value: 0
                  } 
              }
          });
          return result
      }
  },
  data() {
      return {
          valid: false,
          statuses: [{text: 'Áp dụng tất cả phòng ban', value: 1}, {text: 'Áp dụng một số', value: 0}],

          dialog: false,
          item: null,

          toDepartments:[],
          fromDepartments:[],
          contractCategories:[],
          positions:[],
          positionStates:[],
          templateHTML: "",
          length: null,
          lengthType:"",
          lengthTypeDictText: {
              'NAM': 'năm',
              'THANG': 'tháng'
          },
          lengthTypeDictCompute: {
              'NAM': 'years',
              'THANG': 'months'
          },

          form: {
            code:'',
            positionCode: '',
            positonStateCode: '',
            baseSalary: '',
            positionName: 'defaultName',
            positionStateName: 'defaultName',
            isActive: 1

          },
          defaultForm: {
            code:'',
            positionCode: '',
            positonStateCode: '',
            baseSalary: '',
            positionName: 'defaultName',
            positionStateName: 'defaultName',
            isActive: 1
          },

          sampleTemplate: "",
          employeeTextPaste:'',

          /**header */
          categories: [],
          departments: [],
          employees:[],
          employeeGlobalSearch:"",
          employeeSelect: [],
          employeeLoading: false,

          /** Detail Header*/
          detailHeaders: [
              {text:"Tên dụng cụ", value: "id", width:"7rem"},
              {text:"Số lượng kiểm đếm", value: "name", width:"7rem", align:"center"},
              {text:"Số lượng", value: "toDepartmentCode", width:"7rem"},
              {text:"Số lượng kiểm đếm", value: "fromDepartmentCode", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "employeeReportCategoryCode", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "images", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "toDepartmentNote", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "fromDepartmentNote", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "status", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "createdAt", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "createdBy", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "updatedAt", width:"7rem", align:"center"},
              {text:"Số lượng kiểm đếm", value: "updatedBy", width:"7rem", align:"center"},
              {text:"", value: "actions", width:"7rem", align:"center"},
          ],
          employeeHeaders: [
              {text:"Mã", value: "code"},
              {text:"Tên", value: "name", width: 150 },
              {text:"Địa chỉ", value: "address", width: 200},
              {text:"CCCD", value: "idNumber" },
              {text:"Ngày cấp", value: "idDate" },
              {text:"Nơi cấp", value: "idPlace", width:200 },
              {text:"Chức vụ", value: "positionName", width: 150 },
              {text:"Chức danh", value: "positionStateName" },
              {text:"Phòng ban", value: "departmentName" },
          ],
          properties:[],
          detail:{
              propertyCode:undefined,
              quantity:0,
              actualQuantity:0,
          },
          defaultDetail:{
              propertyCode:undefined,
              quantity:0,
              actualQuantity:0,
          },
          detailDefault:{
              propertyCode:undefined,
              quantity:0,
          },
          detailItems:[],

          propertyValid: false,
          amountValid: false,

          
          rules: {
              required: value => !!value || 'Required.'
          },

          /**Stepper params */
          headerCreated: false,
      }
  }, 
  methods: {
      printTest() {
          window.frames["printf"].focus();
          window.frames["printf"].print();
      },
      employeeSetInfo(event) {
          if(event.value) {
              this.form.employeeCode = event.item.code
              this.form.employeeName = event.item.name
              this.form.positionCode = event.item.positionCode
              this.form.positionName = event.item.positionName
              this.form.positionStateName = event.item.positionStateName
              this.form.positionStateCode = event.item.positionStateCode
              this.form.departmentCode = event.item.departmentCode
              this.form.departmentName = event.item.departmentName
              this.form.phoneNumber = event.item.phoneNumber
              this.form.mail = event.item.mail
              this.form.address = event.item.address
              this.form.gender = event.item.gender == 'Nam'? true : false
              this.form.birthDay = moment(event.item.birthDay).format("YYYY-MM-DD")
              this.form.idNumber = event.item.idNumber
              this.form.idDate = moment(event.item.idDate).format("YYYY-MM-DD")
              this.form.idPlace = event.item.idPlace
          } else {
              this.form = Object.assign({}, this.defaultForm);
          }
      },

      employeeSelectClass(item) {
          if(this.employeeSelect.length>0)
          {
              if(this.employeeSelect[0].code == item.code)
                  return 'highlighted-row'
          }
      },
      formatDate(value) {
          return moment(value).format("DD/MM/YYYY");
      },

      getPosition() {
        return ApiServices.getPosition().then(d => {
          this.positions = d
        })
      },
      getPositionState() {
        this.form.positonStateCode = null
        return ApiServices.getPositionState(this.form.positionCode).then(d => {
          this.positionStates = d
        })
      },


      getUnit(property) {
          var data = this.properties.find(x=>x.code == property);
          if(!data)
              return ""
          else
              return data.propertyUnitName
      },

      close(){
          this.dialog = false
          this.item = null
          this.employeeSelect = []
          this.form = Object.assign({}, this.defaultForm);
          this.headerCreated = false;
          this.$refs.form.resetValidation();

          this.uploaded = 0
          this.totalUpload = 0

          this.$emit('fetchData');
          this.$emit('close-dialog');


      },

      save() {
          this.$refs.form.validate();
          if(this.valid)
          {
              //tao phieu
              if(!this.isEdit) {
                  return this.moduleApiService.post( this.form).then(res => {
                      // this.$emit("fetchData", res.data.code);
                      this.close();
                      
                      // this.fetchDetail();
                      // this.form = Object.assign({}, res.data.data);
                  });
              }

              //update phieu
              if(this.isEdit) {
                  return this.moduleApiService.put(this.item.id,this.form).then(res => {
                      // this.$emit("fetchData");
                      this.close();
                      
                      // this.fetchDetail();
                      // this.form = Object.assign({}, res.data.data);
                  });
              }
          }
      },
      fetch() {
          return this.moduleApiService.getItem(this.item.id).then(d => {
              // this.employeeSelect = [{code: d.employeeCode}]
              console.log(d)
              this.form = Object.assign({}, d);
          }).then(()=> {
            return ApiServices.getPositionState(this.form.positionCode).then(d => {
              this.positionStates = d
            })
          })
      },
      getToDepartments() {
          this.apiService.getToDepartments().then(d => {
              console.log(d)
              this.toDepartments = d
          })
      },

      getFromDepartments() {
          this.apiService.getFromDepartments().then(d => {
              this.fromDepartments = d
          })
      },

      contractCategoryChange(value) {
          var contractCategoryObj = this.contractCategories.find(x=>x.code == value)
          if(contractCategoryObj) {
              this.form.length = contractCategoryObj.length
              this.form.lengthType = contractCategoryObj.lengthType
              this.form.validTo = moment(this.form.validFrom).add(this.form.length, this.lengthTypeDictCompute[this.form.lengthType]).format("YYYY-MM-DD")
              //gen SOHOPDONG
              let currentYear = moment().year()
              let employeeCodeFormat = this.form.employeeCode.replace('NS', '')
              let contractNumber = `${employeeCodeFormat}/${currentYear}/${contractCategoryObj.prefix}`
              this.form.contractNumber = contractNumber
          }

          return this.apiService.getContractCategoryTemplate(value).then(d => {this.templateHTML = d})

      }
      

      //SERVICES
  },
  moduleApiService: null,
  created() {
      this.moduleApiService = new api()
  },
  mounted() {
      this.getPosition()
      // this.getToDepartments()
      // this.getFromDepartments()
      
      // this.startVideo()
      // this.getDepartments();
      // this.getEmployees()
  }
}
</script>

<style scoped>
.v-data-table__selected {
background-color: green !important; /* Customize the background color as needed */
font-weight: 500;
}
.v-data-table__selected td{
  color: white !important;
}
.v-data-table__selected td .v-input--selection-controls__input i {
  color: white;
}
</style>