import http from '@/components/services/http-common';
// import * as moment from "moment/moment"
class InsuranceApi {
  get(id) {
    return http.get('insurances/'+id).then(r =>{
      return r.data
    })
  }
}

export default new InsuranceApi();