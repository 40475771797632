<template>
    <div class="shift-create">
        <v-dialog v-model="dialog" max-width="800px" scrollable>
            <v-card>
                <v-card-title class="secondary white--text">
                <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-form @submit.prevent="createData" novalidate ref="form" id="form">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                v-model="form.Name"
                                label="Tên ca"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                @mousedown="fetchPositionState"
                                v-model="form.PositionStateCode"
                                :items="formData.positionStates"
                                label="Trạng thái làm việc"
                                required
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                v-model="form.WorkType"
                                :items="[
                                    {text: 'CT', value:'CT'},
                                    {text: 'CB', value:'CB'},
                                    {text: 'KC', value:'KC'},
                                ]"
                                label="Loại công"
                                required
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="form.DayOffInMonth" label="Nghỉ trong tháng">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                deletable-chips v-model="form.ArrDow"
                                :items="formData.dow" label="Ngày làm việc" multiple
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <timepicker v-model="form.StartHour" title="Bắt đầu ca"> </timepicker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <timepicker v-model="form.EndHour" title="Kết thúc ca"> </timepicker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <timepicker v-model="form.StartBreak" :title="(form.isSpecial) ? 'Kết thúc ca 1': 'Bắt đầu nghỉ'"> </timepicker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <timepicker v-model="form.EndBreak" :title="(form.isSpecial) ? 'Bắt đầu ca 2': 'Kết thúc nghỉ'"> </timepicker>
                            </v-col>
                            <v-col cols="6">
                                <v-switch v-model="form.isSpecial" label="Ca đặc biệt">
                                </v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch v-model="form.isOffice" label="Hành chính văn phòng">
                                </v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch v-model="form.timeRestrict" :label='`${form.timeRestrict ? "Ràng buộc thời gian" : "Không ràng buộc thời gian"}`'>
                                </v-switch>
                            </v-col>
                            <v-col cols="6">
                                
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field type="number" v-model="form.AllowLate" label="Cho phép trễ" suffix="(phút)"> </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field type="number" v-model="form.AllowEarly" label="Cho phép sớm" suffix="(phút)"> </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field type="number" min="0" v-model="form.Lunch" label="Hệ số cơm trưa" > </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field type="number" min="0" v-model="form.AllowOvertime" label="Cho phép tăng ca (*)"> </v-text-field>
                            </v-col>
                        </v-row>
                        
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    >
                        Thoát
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text form="form"
                        type="submit"
                    >
                        Lưu
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TimePicker from "@/components/TimePicker";
import http from '@/components/services/http-common';
import * as moment from "moment/moment";

export default {
    name:"createShift",
    components: {
        "timepicker": TimePicker
    },
    data() {
        return {
            // URL
            POSITION_STATE_URL: "PositionStates",

            //FORM DATA
            isUpdate: false,
            dialog: false,
            form:{
                ArrDow: [],
                Dow: "",
                Name: "",
                DepartmentCode: "",
                PositionStateCode: "",
                StartHour: null,
                EndHour: null,
                StartBreak: null,
                EndBreak: null,
                isSpecial: false,
                isOffice: false,
                startSecond: false,
                DepartmentTypeCode:"",
                AllowEarly: 0,
                AllowLate: 0,
                Lunch: 0,
                WorkType: "CT",
                AllowOvertime: "24",
                DayOffInMonth: 0,
                timeRestrict: false,
            },
            defaultForm:{
                ArrDow: [],
                Dow: "",
                Name: "",
                DepartmentCode: "",
                PositionStateCode: "",
                StartHour: null,
                EndHour: null,
                StartBreak: null,
                EndBreak: null,
                isSpecial: false,
                isOffice: false,
                startSecond: false,
                DepartmentTypeCode:"",
                AllowEarly: 0,
                AllowLate: 0,
                Lunch: 0,
                WorkType: "CT",
                AllowOvertime: "24",
                DayOffInMonth: 0,
                timeRestrict: false,
            },
            formData: {
                dow: [
                    {text: "Chủ nhật", value: "0"},
                    {text: "Thứ hai", value: "1"},
                    {text: "Thứ ba", value: "2"},
                    {text: "Thứ tư", value: "3"},
                    {text: "Thứ năm", value: "4"},
                    {text: "Thứ sáu", value: "5"},
                    {text: "Thứ bảy", value: "6"},
                ],
                timeMenu: "",
                departments: [],
                positionStates: []
            }
        }
    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật ca" : "Tạo mới ca";
        },
        Dow () {
            let mapped = this.form.ArrDow.map(x => {
                let result = [];
                result.push(x);
                return result;
            });
            return mapped.join();
        },
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
    },
    methods: {
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        
        fetchPositionState() {
            http.get(this.POSITION_STATE_URL + "?dropDown=1")
            .then(res => {
                let data=  res.data;
                this.formData.positionStates = data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });

            })
            .catch(err => {

            });
        },

        fetchUpdate (code){
            http.get("shifts/"+code).then(res => {
                let data = res.data;
                this.form.PositionStateCode = data.positionStateCode;
                this.form.Code = data.code;
                this.form.Name = data.name;
                this.form.DepartmentTypeCode = data.departmentTypeCode;
                this.form.ArrDow = (data.dow) ? data.dow.split(",") : [];
                this.form.StartHour = moment(data.startHour).format("HH:mm");
                this.form.EndHour = moment(data.endHour).format("HH:mm");
                this.form.StartBreak = moment(data.startBreak).format("HH:mm");
                this.form.EndBreak = moment(data.endBreak).format("HH:mm");
                this.form.AllowEarly = data.allowEarly;
                this.form.AllowLate = data.allowLate;
                this.form.Lunch = data.lunch;
                this.form.WorkType = data.workType;
                this.form.AllowOvertime = data.allowOvertime;
                this.form.isSpecial = data.isSpecial;
                this.form.isOffice = data.isOffice;
                this.form.DayOffInMonth = data.dayOffInMonth;
                this.form.timeRestrict = data.timeRestrict;
            }).catch(err => {
                console.log(err);
            });
        },
        fetchDepartmentTypes () {
            http.get("departmenttypes?dropDown=1").then(res => {
                this.formData.departmentTypes = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });

            }).catch(err => {
                console.log(err);
            });
        },
        createData () {
            // this.$refs.form.validate();
            this.form.Dow = this.Dow;
            let form =  {...this.form};
            delete form.ArrDow;
            if(!this.isUpdate)
                http.post('shifts', form).then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            else{
                http.put('shifts/' + this.item.code, form).then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        }
    },
    created() {
        this.fetchDepartmentTypes();
        this.fetchPositionState();
    },
    mounted() {
    }
}
</script>