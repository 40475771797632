import http from "@/components/services/http-common"

export default class Api {
  getOnlineEmployee = () => http.get('EmployeeDashboard/current-emp').then(res => res.data)
  
  getTotalEmployee = () => http.get('EmployeeDashboard/total-emp').then(res => res.data)

  getNewEmployee = () => http.get('EmployeeDashboard/new-emp').then(res => res.data)

  getUnconfirmeds = () => http.get('EmployeeDashboard/unconfirmeds').then(res => res.data)
  
  getTimesheetUnconfirmed = () => http.get('EmployeeDashboard/timesheet-Unconfirmed').then(res => res.data)
  
  getEmployeeDtRatio = () => http.get('EmployeeDashboard/employee-dt-ratio').then(res => res.data)

  getCheckinBlackList = () => http.get('EmployeeDashboard/checkin-blacklist').then(res => res.data)
  
}