<template>
  <div class="organization-map">
    <v-breadcrumbs class="bread-crumbs"
      :items="breadcrumbs" large >
    
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :exact="item.exact"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
      <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb> -->
      

      <!-- <create-dialog-health-insurance ref="createHealthInsurance" @needFetch="fetchData"></create-dialog-health-insurance> -->
      <v-card>
          <v-card-title>
              <!-- Danh sách bảo hiểm -->
              <v-spacer></v-spacer>
              <v-btn class="" color="teal darken-4" dark :to="{ name: 'OrganizationMapCreate' }"> Tạo mới</v-btn>
              <!-- <create-button @click="dialog={state: true, item:null}"></create-button> -->
          </v-card-title>
          <v-data-table height="600px"
          :headers="headers"
          :page="page"
          :items="data"
          :search="search">
              <!-- BHYT -->
              <template v-slot:[`item.actions`]="{ item }">
                  <v-btn small color="warning" style="width: 50px; height: 50px; " class="ma-2" @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                  <v-btn small color="error" style="width: 50px; height: 50px; " class="ma-2" @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <template v-slot:[`item.validFrom`]="{ item }">
                  {{ formatDate(item.validFrom) }}
              </template>
              <template v-slot:[`item.validTo`]="{ item }">
                  {{ formatDate(item.validTo) }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                  {{ formatDate(item.createdAt) }}
              </template>
              <template v-slot:[`item.registerDate`]="{ item }">
                  <v-chip color="blue-grey" label v-if="!item.registerDate" dark class="font-weight-medium" small>
                      Không có thông tin
                  </v-chip>
                  <span v-else>{{item.registerDate}}</span>
              </template>
              <template v-slot:[`item.sourceUrl`]="{ item }">
                  <v-btn color="warning" small @click="pdfDialog = true, pdfSource = item.sourceUrl"> <v-icon left>mdi-eye</v-icon> Xem PDF</v-btn>
              </template>

              <!-- <template v-slot:[`item.actions`]="{ item }">
                  <edit-button @click="editItem(item)"></edit-button>
                  <delete-button @click="deleteItem(item)"></delete-button>
              </template> -->
          </v-data-table>
          
      </v-card>
      
      <!-- ************** ShowPDF *************** -->
      <v-dialog v-model="pdfDialog" width="800px">
        <v-card>
          <vue-pdf-embed :source="pdfSource" />
        </v-card>
      </v-dialog>

      <!-- ************** DELETE CONFIRMATION *************** -->
      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
              <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>
<script>

// import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import CreateInsurance from './Create'
import * as moment from "moment/moment";
import DataFilter from '@/components/DataFilter';
import EventBus  from "@/components/services/EventBus";
import OrganizationMapIndexApi from './api.js'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
// import router from "@/router/index";

export default {
  name: "Insurance",
  components: {
      // myBreadCrumb,
      "create-dialog": CreateInsurance,
      "data-filter": DataFilter,
      VuePdfEmbed
  },
  computed: {
      
  },
  watch: {
  },
  data () {
      return {
          // URL
          ROOT_URL: "Insurances",
          DEPARTMENT_URL: "Departments",

          exportPrompt: false,
          page: 1,
          //filter
          params: {
              sortBy: [],
              sortDesc: [],
              page: "",
              itemsPerPage: "",
              searchs: [],
              searchAttrs: [],

              fromOn: "",
              from: "",
              to: ""
          },

          filter: {
              menu: false,
              search: "",
              searchAttr: "EmployeeName",
              department: "",

              from: new Date().toISOString().substr(0, 10),
              to: new Date().toISOString().substr(0, 10),
              fromOns: [
                  {text: "Hạn BHYT", value: "HealthInsuranceDate"},
                  {text: "Ngày nộp sổ", value: "RegisterDate"},
                  {text: "Ngày rút sổ", value: "WithdrawDate"}
              ],
              fromOn: undefined,

              searchAttrs: [
                  {text: "Tên nhân viên", value: "EmployeeName"},
                  {text: "MSNV", value: "EmployeeCode"},
              ],
              FilterAttrs: [
                  {text: "Phòng", items: [] }
              ],
              FilterOn:["DepartmentCode"],
          },

          //DIALOG
          dialog: {
              state: false,
              item: undefined,
          },
          dialogDelete: false,
          targetItem: {},

          //DATA
          icon:{
              icon: "mdi-plus-thick",
              to: ""
          },

          //DATATABLE
          data: [],
          totalItem: 0,
          search:"",
          options: {},
          isLoading: true,
          targetItem: undefined,
          headers: [
              { text: 'Thao tác', value: 'actions', sortable: false, width: "12rem"},
              { text: 'Mã số', align: 'start', sortable: false, value: 'id', },
              { text: 'Tên', align: 'start', value: 'name', width:'15rem' },
              { text: 'Hiệu lực từ', align: 'start', value: 'validFrom', },
              { text: 'Hiệu lực đến', value: 'validTo' },
              { text: 'Tạo vào', value: 'createdAt' },
              { text: 'Tạo bởi', value: 'createdBy' },
              { text: 'Hoạt động', value: 'isActive' },
              { text: 'Link File', value: 'sourceUrl' },
          ],
          
          pdfSource: 'https://storage.googleapis.com/tshrm-bucket/Uploads/OrganizationMap/a15wkuku.pdf',
          pdfDialog: false,

          breadcrumbs: [
            {
              text: 'Danh sách sơ đồ hệ thống',
              disabled: false,
              to: {name: 'OrganizationMap'}
            },
          ]
      }
  },
  
  methods:{
      messageToast (eventObj) {
          EventBus.$emit('hasMessage', eventObj);
      },
      formatDate(value) {
          return moment(value).format("DD/MM/YYYY");
      },
      closeHandler () {
          this.dialog = {state: false, item: undefined}
      },
      editItem (item) {
        console.log(this.$route)
          // this.dialog = {state: true, item: item}
          this.$router.push({name: 'OrganizationMapCreateEdit', params: { id: item.id }})
      },
      
      fetchDepartment() {
          return http(this.DEPARTMENT_URL, {
              params: {dropDown: 1}
          })
          .then(res => {
              this.filter.FilterAttrs[0]={text: "Phòng", items: res.data };
          })
          .catch(err => console.log(err))
      },
      fetchData () {
          return OrganizationMapIndexApi.gets().then(d => {
            this.data = d.list
          });
          // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
          
          // this.params =  { ...this.params, 
          //                     sortBy: this.options.sortBy, //array
          //                     sortDesc: this.options.sortDesc, //array
          //                     page: this.options.page,
          //                     itemsPerPage: this.options.itemsPerPage,
          //                     };
          // if(page != undefined || itemsPerPage != undefined)
          //     return http.get(this.ROOT_URL, {
          //         params: this.params
          //     })
          //     .then(res => {
          //         console.log(res)
          //         this.isLoading = false;
          //         this.data = res.data.items;
          //         this.totalItem = res.data.totalItem;
          //     })
          //     .catch(err => { throw err});
      },
      
      deleteItem (item) {
          this.dialogDelete = true;
          this.targetItem = item;
      },
      cfmDeleteItem() {
          return OrganizationMapIndexApi.deleteItem(this.targetItem.id).then(() => {
            this.targetItem = null
            this.dialogDelete = false
            this.fetchData()
          })
      },
  },
  created () {
      this.fetchData();
      this.fetchDepartment();
  }
}
</script>