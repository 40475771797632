<template>
    <div class="work-history">
    <v-dialog v-model="dialog" max-width="700px">
        <v-card tile>
            <v-toolbar color="secondary" flat dark>
                <v-toolbar-title>Danh sách hợp đồng</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon small @click="dialog=false"> mdi-close</v-icon>
            </v-toolbar>
            <v-card-text class="mt-3">
                <v-data-table :items="items" :headers="headers">
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>

import http from '@/components/services/http-common';
import UploadService from "@/components/services/UploadService";
import moment from 'moment/moment';
export default {
    components:{
    },
    props:["value", "employee"],
    watch:{
        dialog: {
            handler(value){
                if(value)
                {
                    // this.fetchDepartment();
                    this.fetch();
                }
                else{
                    this.close();
                }
            }
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    data() {
        return {
            CONTRACT_URL: "contracts",
            DEPARTMENT_URL: "departments",
            IMPORT_URL: "contracts/import",
            EXPORT_URL: "contracts/export",
            items:[],
            headers:[
                { text: 'Số hợp đồng', value: 'contractNumber', width: 100},
                { text: 'Loại hợp đồng', value: 'contractType', width: 100},
                { text: 'Ngày kí', value: 'signDate', width: 200},
                { text: 'Ngày hết hạn', value: 'expireDate', width: '7rem'},
                { text: 'Lương thỏa thuận', value: 'salary', width: 200},
                { text: 'Thời hạn', value: 'length', width: 100},
                { text: 'Đơn vị', value: 'lengthUnit', width: 150},
            ]
        }
    },
    methods: {
        fetch(){
            return http.get("employees/contract-history/"+this.employee).then(res => {
                this.items = res.data;
            })
        },
        fetchData (e) {
            return http.get(this.CONTRACT_URL)
            .then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.rowCount = res.data.rowCOunt;
                console.log(res.data);
            }).catch(err => {})
        },
        saveData() {
        },
        close(){
        }
    },
    created() {
    }
    
}
</script>
<style scoped>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .7;
        position: absolute;
        width: 100%;
    }
</style>