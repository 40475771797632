import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'recruitmentRequests';
// global api
class RecruitmentRequestIndexApi {
  gets() {
    return http.get(`${ROUTE}`).then(r => r.data.data)
  }
  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => {
      r.data.data.expectCompleteDate = moment(r.data.data.expectCompleteDate).format('YYYY-MM-DD')

      //requirement
      r.data.requirement.gender = r.data.requirement.gender.split(';').map(x=> {return Number(x)})
      r.data.requirement.workExp = r.data.requirement.workExp.split(';').map(x=> {return Number(x)})
      r.data.requirement.appearance = r.data.requirement.appearance.split(';').map(x=> {return Number(x)})
      r.data.requirement.educationLevel = r.data.requirement.educationLevel.split(';')
      r.data.requirement.educationMajor = r.data.requirement.educationMajor.split(';')

      return r.data
    })
  }

  post(payload) {
    return http.post(`${ROUTE}`, payload)
  }

  getDropDown() {
    return http.get(`${ROUTE}/get-dropdown`).then(r => r.data)
  }

  getJob() {
    return http.get(`Jobs`).then(r => r.data.map(x => {
        return {
          text: x.name,
          value: x.code
        }
      }
    ))
  }

  getJobDescription(id) {
    return http.get(`Jobs/get-description/${id}`).then(r => r.data)
  }

  deleteItem(id) {
    return http.delete(`${ROUTE}/${id}`).then(r => r.data)
  }

}

export default new RecruitmentRequestIndexApi();