import SalaryEnrollment from '@/views/layouts/SubLayouts/SalaryEnrollment.vue';
import TimeRollProcess from '@/views/SalaryAndWork/MTimesheet/Index.vue';
import MonthlyTimeSheet from '@/views/SalaryAndWork/MTimesheet/MonthlyTimeSheet.vue';

//Salary and worktime
import SalaryAndWork from '@/views/SalaryAndWork/Index.vue'
import TimeSheet from '@/views/SalaryAndWork/TimeSheet/Index.vue';
import TimeSheet2 from '@/views/SalaryAndWork/TimeSheet/Index2.vue';
import Attendance from '@/views/SalaryAndWork/Attendance/Index.vue';
import OvertimeScale from '@/views/SalaryAndWork/OvertimeScale/Index.vue';

//confirm
import ConfirmLongLeaveRequest from '@/views/Confirm/LongLeave/Index.vue';
import ConfirmShortRequest from '@/views/Confirm/ShortLeave/Index.vue';
import ConfirmOvertime from '@/views/Confirm/Overtime/Index.vue';
import ConfirmLeave from '@/views/Confirm/Leave/Index.vue';
import ConfirmBusiness from '@/views/Confirm/Business/Index.vue';
import ConfirmOvertimeBooking from '@/views/Confirm/OvertimeBooking/Index.vue';

import SalaryImport from '@/views/SalaryAndWork/SalaryImport/Index.vue';
import SalarySupport from '@/views/SalaryAndWork/SalarySupport/Index.vue';
import SalarySetting from '@/views/SalaryAndWork/Setting/Index.vue';
import SalaryConfig from '@/views/SalaryAndWork/SalaryConfig/Index.vue';
import TimesheetSupport from '@/views/SalaryAndWork/TimesheetSupport/Index.vue';

import Supplement  from '@/views/SalaryAndWork/Supplement/Index.vue';

import EmployeePayroll  from '@/views/SalaryAndWork/EmployeePayroll/Index.vue';

import MonthlySalary  from '@/views/SalaryAndWork/MonthlySalary/Index.vue';
import SecuritySalary  from '@/views/SalaryAndWork/SecuritySalary/Index.vue';
import SalaryAdjustment  from '@/views/SalaryAndWork/SalaryAdjustment/Index.vue';
import Salary  from '@/views/SalaryAndWork/Salary/Index.vue';
import Bonus from '@/views/SalaryAndWork/Bonus/Index.vue'
import BonusType from '@/views/SalaryAndWork/BonusType/Index.vue'
import SalaryEmployeeFamily from '@/views/SalaryAndWork/EmployeeFamily/Index.vue'

export default [
    {
      path: 'salaryEnrollment',
      name: 'SalaryEnrollment',
      component: SalaryEnrollment,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      },
      redirect: {name: 'MonthlyTimeSheet'},
      children:[
        {
          path: 'salary-employee-family',
          name: 'SalaryEmployeeFamily',
          component: SalaryEmployeeFamily
        },
        {
          path: 'bonus',
          name: 'Bonus',
          component: Bonus
        },
        {
          path: 'bonus-type',
          name: 'BonusType',
          component: BonusType
        },
        {
          path: 'salary',
          name: 'Salary',
          component: Salary
        },
        {
          path: 'salary-adjustment',
          name: 'SalaryAdjustment',
          component: SalaryAdjustment
        },
        {
          path: 'monthly-salary',
          name: 'MonthlySalary',
          component: MonthlySalary
        },
        {
          path: 'security-salary',
          name: 'SecuritySalary',
          component: SecuritySalary
        },
        {
          path: 'supplement',
          name: 'Supplement',
          component: Supplement
        },
        {
          path: 'employee-payroll',
          name: 'EmployeePayroll',
          component: EmployeePayroll
        },
        {
          path: 'salary-support',
          name: 'SalarySupport',
          component: SalarySupport
        },
        {
          path: 'timesheet-support',
          name: 'TimesheetSupport',
          component: TimesheetSupport
        },
        {
          path: 'salary-import',
          name: 'SalaryImport',
          component: SalaryImport
        },
        {
          path: 'salary-config',
          name: 'SalaryConfig',
          component: SalaryConfig
        },
        {
          path: 'salary-confirm-overtime-booking',
          name: 'SalaryConfirmOvertimeBooking',
          component: ConfirmOvertimeBooking,
          meta:{
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Xác nhận giới hạn tăng ca',
                disabled: true,
                to: {
                    name: "ConfirmOvertime",
                    }
                },
              ],
          }
        },
        {
          path: 'salary-confirm-short-leave',
          name: 'SalaryConfirmShortRequest',
          component: ConfirmShortRequest
        },
        {
          path: 'salary-setting',
          name: 'SalarySetting',
          component: SalarySetting
        },
        {
          path:'salary-confirm-overtime',
          name: 'SalaryConfirmOvertime',
          component: ConfirmOvertime,
          meta:{
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Xác nhận tăng ca',
                disabled: true,
                to: {
                    name: "ConfirmOvertime",
                    }
                },
              ],
          }
        },
        {
          path:'salary-confirm-leave',
          name: 'SalaryConfirmLeave',
          component: ConfirmLeave,
          meta:{
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Xác nhận nghỉ phép',
                disabled: true,
                to: {
                    name: "SalaryConfirmLeave",
                    }
                },
              ],
          }
        },
        {
          path:'salary-confirm-business',
          name: 'SalaryConfirmBusiness',
          component: ConfirmBusiness,
          meta:{
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Xác nhận công tác',
                disabled: true,
                to: {
                    name: "ConfirmBusiness",
                    }
                },
              ],
          }
        },
        {
          path: 'salary-confirm-long-leave',
          name: 'SalaryConfirmLongLeaveRequest',
          component: ConfirmLongLeaveRequest,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Duyệt nghỉ dài hạn',
                disabled: true,
                to: {
                    name: "LongLeaveConfirm",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
              },
          },
        },

        //********** SALARY */
        {
          path: "salary-and-worktime",
          component: SalaryAndWork,
          children:[
            {
              path: 'time-roll-process',
              name: 'TimeRollProcess',
              component: TimeRollProcess,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Trạng thái chốt công',
                    disabled: true,
                    to: {
                        name: "TimeRollProcess",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },

            {
              path: 'monthly-timesheet',
              name: 'MonthlyTimeSheet',
              component: MonthlyTimeSheet,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Trạng thái chốt công',
                    disabled: true,
                    to: {
                        name: "MonthlyTimeSheet",
                        }
                    },
                ],
              }
            },
            {
              path: 'timesheet',
              name: 'TimeSheet',
              component: TimeSheet,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Ngày công',
                    disabled: true,
                    to: {
                        name: "Timesheet",
                        }
                    },
                ],
              }
            },
            {
              path: 'timesheet-list',
              name: 'TimeSheet2',
              component: TimeSheet2,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Ngày công',
                    disabled: true,
                    to: {
                        name: "Timesheet2",
                        }
                    },
                ],
              }
            },
            {
              path: 'overtime-scales',
              name: 'OvertimeScale',
              component: OvertimeScale,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Hệ số tăng ca',
                    disabled: true,
                    to: {
                        name: "OvertimeScale",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'attendances',
              name: 'Attendance',
              component: Attendance,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Danh sách chấm công',
                    disabled: true,
                    to: {
                        name: "Attendance",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
          ]
        },
      ]
    },
    
  ]
