<template>
  <div>
    <v-card >
      <v-card-title>Cài đặt lương bảo vệ
        <v-spacer></v-spacer>
        <v-btn @click="importDialog = true" text color="teal darken-4">Nhập dữ liệu</v-btn>
        <v-btn class="mx-2" @click="getData" small icon color="teal darken-4"><v-icon >mdi-refresh</v-icon></v-btn>
        <v-text-field label="Tháng" v-model="month" style="max-width: 200px;" type="date" hint="Chọn ngày đầu tháng vd 01/09/2023"></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row  class="">
          <v-col cols="6" md="2">
            <v-text-field hide-details v-model="filter.employeeCode" outlined dense label="MSNS" @change="getData"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field hide-details v-model="filter.employeeName" outlined dense label="Họ và tên" @change="getData"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-autocomplete clearable :items="departments" hide-details v-model="filter.departmentCode" outlined dense label="Chi nhánh" @change="getData"></v-autocomplete items="departments">
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-data-table :headers="headers" :items="supplements" :loading="isLoading" fixed-header height="65vh">
          <template v-slot:[`item.pC_Khu_Vuc`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.pC_Khu_Vuc" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_BH`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.pC_BH" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.he_So`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.he_So" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.luong_Hieu_Qua_2`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.luong_Hieu_Qua_2" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_Dien_Thoai`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.pC_Dien_Thoai" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_Cong_Tac`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.pC_Cong_Tac" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.pC_Dao_Tao`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.pC_Dao_Tao" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.truy_Lanh`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.truy_Lanh" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.truy_Thu`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.truy_Thu" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.salary`]="{ item }" >
            <v-text-field dense hide-details solo v-model="item.salary" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.isInsurance`]="{ item }" >
            <v-checkbox v-model="item.isInsurance" :false-value="0" :true-value="1" dense @change="updateCell(item)"></v-checkbox>
            <!-- <v-text-field dense hide-details solo v-model="item.isInsurance" type="text" @change="updateCell(item)"></v-text-field> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="importDialog" width="600px"> 
      <v-card :loading="importLoading">
        <v-card-title>Import dữ liệu</v-card-title>
        <v-card-subtitle>
          <small class="info--text mr-6">Template excel: </small>
          <export-excel :items="supplements" :headers="headers" class="mx-6"></export-excel>
        </v-card-subtitle>
        <v-card-text>
          <v-form v-model="validImport" id="import_supplement" ref="import_supplement" @submit.prevent="importHandler">
            <v-row>
              <v-col>
                <v-file-input v-model="importFile" label="File import" persistent-placeholder :rules="[v => !!v || 'Chọn file import']"></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="teal darken-4" form="import_supplement" type="submit" small dark>
            <span v-if="importLoading"> Dữ liệu đang import. vui lòng không chuyển trang</span>
            <span v-else>Nhập dữ liệu</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import moment from 'moment';
import SecuritySalary from './api'
import ApiServices from '@/components/services/ApiServices'
import ExportExcel from '@/components/ExportExcel'

export default {
  data() {
    return {
      columns: [{ prop: "name" }, { prop: "details" }],
      rows: [{
        name: "1",
        details: "Item 1",
      }],
      isLoading: false,
      month: `${moment().year()}-${moment().month()+1}-01`,
      filter: {
        employeeCode: '',
        employeeName: '',
        departmentCode: '',
      },
      departments:[],

      importFile: null,
      importDialog: false,
      importLoading: false,
      validImport: true,
      headers: [
          // { text: 'Mã', value: 'ma' },
          { text: 'MSNV', value: 'employeeCode' },
          { text: 'Họ Tên', value: 'employeeName', width: 150 },
          { text: 'Vị trí', value: 'positionName' },
          { text: 'Chức danh', value: 'positionStateName' },
          { text: 'Phòng ban', value: 'departmentName', width: 150 },
          { text: 'Khối', value: 'departmentTypeName' , width: 150},
          { text: 'Ngày nhận việc', value: 'hireDate' },
          { text: 'Vào bảo hiểm', value: 'isInsurance', width: 150 },
          { text: 'Lương', value: 'salary', width: 150 },
          { text: 'Giờ công chuẩn', value: 'standardHour', width: 150 },
        ],

        supplements: [],
    };
  },
  methods: {
    getData() {
      this.isLoading = true
      return SecuritySalary.gets(this.month, this.filter).then(d => d.data).then(data => {
        this.supplements = data
        this.isLoading = false
      })
    },
    getDepartment() {
      return ApiServices.fetchDepartment().then(r =>{
        this.departments = r.data.map(x => {
          return {
            text: x.name,
            value: x.code
          }
        })
      });
    },

    updateCell(item){
      return SecuritySalary.updateCell(item).then(() => this.getData())
    },

    importHandler() {
      // alert("import")
      this.importLoading = true
      return SecuritySalary.importing(this.month, this.importFile).finally(() => {
        this.importLoading = false
      })
    }
  },
  components: {
    ExportExcel
  },
  created() {
    this.getDepartment()
    this.getData()
  }
};
</script>