<template>
  <div>
    <v-card>
      <v-card-title>
        Bảng lương tháng
        <v-text-field style="max-width: 200px;" class="mx-2" outlined hide-details v-model="month" @change="getData()"
        type="date" dense placeholder="Chọn tháng" persistent-placeholder></v-text-field>

        <v-spacer></v-spacer>
        <v-btn icon color="teal" @click="getData" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
        <export-excel :items="items" :headers="headers"> </export-excel>
        <v-btn class="ml-2" @click="initHandler">Tạo bảng lương</v-btn >
      </v-card-title>

      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" xl="2" md="3">
            <v-text-field dense v-model="search" outlined placeholder="Tìm kiếm" hide-details persistent-placeholder></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :loading="isLoading" :items="items" :headers="headers" :search="search">
          <template v-slot:[`item.ngay_Nhan_Viec`]="{ item }">
            {{ formatDate(item.ngay_Nhan_Viec) }}
          </template>
          <template v-slot:[`item.salary`]="{ item }" >
            <v-text-field style="max-width: 250px;" dense hide-details solo v-model="item.salary" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.lunchMoney`]="{ item }" >
            <v-text-field style="max-width: 250px;" dense hide-details solo v-model="item.lunchMoney" @change="updateCell(item)"></v-text-field>
          </template>
          <template v-slot:[`item.kpiSalary2`]="{ item }" >
            <v-text-field style="max-width: 250px;" dense hide-details solo v-model="item.kpiSalary2" @change="updateCell(item)"></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import MonthlySalaryApi from './api.js'
import moment from 'moment';
import ExportExcel from '@/components/ExportExcel'

export default {
  data() {
    return {
      items: [],
      search: '',
      isLoading: false,
      headers: [
        { text: 'Mã', value: 'code' },
        { text: 'Mã NS', value: 'employeeCode' },
        { text: 'Tháng', value: 'month' },
        { text: 'Lương', value: 'salary' },
        { text: 'Tiền ăn', value: 'lunchMoney' },
        { text: 'Lương tháng trước', value: 'lastMonthCode' },
        { text: 'Lương KPI 2', value: 'kpiSalary2' },
        
      ],

      month: moment().startOf('month').format('YYYY-MM-DD')
    }
  },
  methods: {
    formatDate(value) {
        return moment(value).format("DD/MM/YYYY");
    },

    getData() {
      this.isLoading = true
      return MonthlySalaryApi.gets(this.month).then(d=> {
        this.items = d.data
        this.isLoading = false
        console.log(d)
      })
    },
    
    updateCell(item){
      console.log(item)
      return MonthlySalaryApi.updateCell(item).then(() => this.getData())
    },

    initHandler() {
      this.isLoading = true
      return MonthlySalaryApi.initData(this.month).then(() => {
        this.isLoading = false
        this.getData()
      })
    }
  },
  components: {
    ExportExcel
  },
  created()  {
    this.getData()
  }
}
</script>