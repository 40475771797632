import axios from "axios";
import router from "../../router/index";
import EventBus  from "../../components/services/EventBus";

//https://apikiemke.trungsonpharma.com/
//NS2977
// http://192.168.1.54:5100

const HTTP = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL2,
  baseURL:"https://apikiemke.trungsonpharma.com/",
  headers: {
    Accept: '*/*', 'Content-Type': 'application/json;charset=utf-8',
    // 'Authorization': "Bearer " + (localStorage.hasOwnProperty ? localStorage.getItem("token") : ""),
  },
});
// const TOKEN = "Bearer " + localStorage.getItem("token");
// HTTP.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");

// Add request interceptor
// HTTP.interceptors.request.use(
//   function(config) {
//     const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsInByaUtleSI6ImFjZjAxMzQxMDE5MjE1OGJhODBlZGY0MmEyMjIxZTRmIn0.eyJ1c2VyX2lkIjoiTlMyOTc3IiwiZXhwaXJlcyI6MTY4NzA3ODIwMC44Njc5NzU3fQ.VI-8IKg6Cj1BTC_TS2Bv8wFd84ob6s3POQDP0v90Ftw"; 
//     if (token) {
//       config.headers["Authorization"] = "Bearer " + token ?? "";
//     }
//     return config;
//   },
//   function(error) {
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
HTTP.interceptors.response.use(
  (response) => {
    if(response.data.hasOwnProperty('message'))
    {
      let eventObj = {
        color: "success",
        message: response.data.message,
        warning: response.data.warning,
        toast: true,
    };
    EventBus.$emit('hasMessage', eventObj);
    }
    return response;
  },
  (err) => {
    if(err.response)
      switch (err.response.status) {
        case 401:
          {
            if(router.currentRoute.name != 'login')
              router.push({name: "login"}).catch(()=>{})
            break;
          }
        case 403:
          {
            let eventObj = {
                color: "error",
                message: "Bạn không có quyền truy cập",
                toast: false,
            };
            EventBus.$emit('hasError', eventObj);
            break;
          }
        case 400:
          {
            if(err.response.data instanceof Blob) {
              convertBlobToObject(err.response.data).then(r => {
                let message = r.message;
                message = message??"Lỗi 400, dữ liệu gởi đi không đúng";
                let eventObj = {
                    color: "error",
                    message: message,
                    toast: false,
                };
                EventBus.$emit('hasError', eventObj);
              })
            }else {
              let message = err.response.data instanceof ArrayBuffer ? JSON.parse(decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(err.response.data))))).message : err.response.data.message;
              message = message??"Lỗi 400, dữ liệu gởi đi không đúng";
              let eventObj = {
                  color: "error",
                  message: message,
                  toast: false,
              };
              EventBus.$emit('hasError', eventObj);
            }
            break;
          }
          
          case 500:
            {
              let message = err.response.data instanceof ArrayBuffer ? JSON.parse(decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(err.response.data))))).message : err.response.data.message;
              message = message??"Lỗi 500! liên hệ admin xử lý";
              let eventObj = {
                  color: "error",
                  message: message,
                  toast: false,
              };
              EventBus.$emit('hasError', eventObj);

              break;
            }
            
            default:
              break;
      }
    return Promise.reject(err);
  }
);
function convertBlobToObject(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const jsonContent = reader.result;
      const object = JSON.parse(jsonContent);
      resolve(object);
    };
    reader.onerror = () => {
      reject(new Error('Unable to convert Blob to object.'));
    };
    reader.readAsText(blob);
  });
}

export default HTTP;