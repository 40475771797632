<template>
    <div class="createCandidate">
    <v-dialog ref="dialog"
      v-model="dialog"
      scrollable
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
                Chi tiết ứng viên
            <v-spacer></v-spacer>
            <v-btn class="mx-3" type="submit" color="success" form="form"><v-icon small left>mdi-content-save</v-icon> Lưu</v-btn>
            <v-icon @click="dialog=false" color="error">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
            <v-form class="mt-3" v-model="valid" ref="form" @submit.prevent="save" id="form">
                <v-row>
                    <v-col cols="12" md="8" class="flex-grow-0 flex-shrink-1">
                        <v-row>
                            <v-col cols="12" md="4" class="text-center">
                                <v-avatar class="elevation-3 mx-auto"
                                    color="grey" size="172">
                                    <v-img  v-if="form.avatarUrl!=null" :src="form.avatarUrl"></v-img>
                                </v-avatar>
                            </v-col>
                            <!-- basic -->
                            <v-col cols="12" md="8">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field label="Tên ứng viên" class="" v-model="form.name" hide-details="auto"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" >
                                        <v-select label="Giới tính" v-model="form.gender"
                                        :items='[{text:"Nam", value:true}, {text: "Nữ", value: false}]' hide-details="auto"></v-select>
                                    </v-col>
                                    <v-col cols="6" >
                                        <v-text-field type="date" v-model="form.birthDay" label="Sinh nhật"  hide-details="auto"></v-text-field>
                                        <!-- <date-picker2 :width="600" v-model="form.birthDay" label="Sinh nhật" outlined hide-details ="true"></date-picker2> -->
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete label="Công việc" v-model="form.jobCode" hide-details="auto"
                                        :items="jobs" item-text="name" item-value="code"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete label="Nơi làm việc mong muốn 1:" hide-details="auto" v-model="form.city1Code" item-text="name" item-value="code" :items="cities"></v-autocomplete >
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete  label="Quận (huyện)" hide-details="auto" v-model="form.jobDistrict1Code" item-text="name" item-value="code" :items="jobDistrict1s"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete label="Nơi làm việc mong muốn 2:" hide-details="auto" v-model="form.city2Code" item-text="name" item-value="code" :items="cities"></v-autocomplete >
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete  label="Quận (huyện)" hide-details="auto" v-model="form.jobDistrict2Code" item-text="name" item-value="code" :items="jobDistrict2s"></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <h3 class="green--text text--darken-4">THÔNG TIN NHÂN VIÊN</h3>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" class="">
                                <v-tabs color="brown" :vertical="$vuetify.breakpoint.name!='xs'" show-arrows="" >
                                    <v-tab><span style="min-width: 172px;">Thông tin cơ bản</span></v-tab>
                                    <v-tab>Thông tin chi tiết</v-tab>
                                    <v-tab>Địa chỉ</v-tab>
                                    <v-tab>Học vấn</v-tab>
                                    <v-tab>Kinh nghiệm làm việc</v-tab>
                                    <v-tab>File đính kèm</v-tab>
                                    
                                    <!-- thong tin co ban -->
                                    <v-tab-item >
                                        <v-container>
                                            <v-row>
                                                <h3 class="text-subtitle-2 font-weight-bold d-block" style="width: 100%;">Liên hệ</h3>
                                                <v-col cols="12">
                                                    <v-text-field append-outer-icon="mdi-clipboard-file" @click:append-outer="copyText('phoneInput')" ref="phoneInput" label="Số điện thoại" v-model="form.phone" :rules="[
                                                                                v => !!v || 'Nhập SDT', v => (v || '').length <=15 || 'SDT quá dài',
                                                                                v => (v || '').length >9 || 'SDT quá ngắn',
                                                                                v =>  !/\D/.test(v) || 'SDT không đúng']" hide-details="auto"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field append-outer-icon="mdi-clipboard-file" @click:append-outer="copyText('emailInput')" ref="emailInput" label="Email" v-model="form.mail" hide-details="auto" 
                                                                        :rules="[
                                                                                v => !!v || 'Nhập Email', v => (v || '').length <=50 || 'SDT quá dài',
                                                                                v =>  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email không đúng']"></v-text-field>
                                                </v-col>
                                                <h3 class="text-subtitle-2 font-weight-bold d-block" style="width: 100%;">Thông tin khác</h3>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Nguồn tuyển dụng" v-model="form.recruitChannel" 
                                                    :items="recruitChannels" hide-details="auto"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Kinh nghiệm làm việc" v-model="form.yearExperience"
                                                    :items="yearExperiences" hide-details="auto"></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>

                                    <!-- thong tin chi tiet -->
                                    <v-tab-item >
                                        <v-container>
                                            <v-row>  
                                                <h3 class="text-subtitle-2 font-weight-bold d-block" style="width: 100%;">Ngoại hình</h3>
                                                <v-col cols="12">
                                                    <v-text-field label="Chiều cao" v-model="form.height" ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="Cân nặng" v-model="form.weight" ></v-text-field>
                                                </v-col>
                                                <h3 class="text-subtitle-2 font-weight-bold d-block" style="width: 100%;">Thông tin khác</h3>
                                                <v-col cols="12">
                                                    <v-select label="Quốc tịch" v-model="form.originCode" :items="origins" item-text="name" item-value="code"></v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-select label="Dân tộc" v-model="form.ethnicityCode" :items="ethnicities" item-text="name" item-value="code"></v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-select label="Tôn giáo" v-model="form.religionCode" :items="religions" item-text="name" item-value="code"></v-select>
                                                </v-col>

                                                <h3 class="text-subtitle-2 font-weight-bold d-block" style="width: 100%;">Định danh công dân</h3>
                                                <v-col cols="12">
                                                    <v-text-field type="date" label="Ngày cấp" v-model="form.idDate"  hide-details="auto"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="Nơi cấp" v-model="form.idNumber"  hide-details="auto"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="CMND" v-model="form.idNumber" hide-details="auto"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>
                                    <!-- Dia chi  -->
                                    <v-tab-item >
                                        <v-container>
                                            <v-row>  
                                                <h3 class="text-subtitle-2 font-weight-bold ">Địa chỉ thường trú</h3>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Thành phố" v-model="form.cityCode" 
                                                    :items="cities" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Quận" v-model="form.districtCode" 
                                                    :items="districts" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Phường" v-model="form.wardCode"  
                                                    :items="wards" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="Địa chỉ" v-model="form.address" ></v-text-field>
                                                </v-col>

                                                <h3 class="text-subtitle-2 font-weight-bold ">Địa chỉ tạm trú</h3>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Thành phố" v-model="form.dCityCode"
                                                    :items="cities" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Quận" v-model="form.dDistrictCode" 
                                                    :items="dDistricts" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Phường" v-model="form.dWardCode" 
                                                    :items="dWards" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="Địa chỉ" v-model="form.dAddress" ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>
                                    <!-- Hoc Van -->
                                    <v-tab-item >
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Trình độ học vấn" v-model="form.educationLevelCode"
                                                    :items="educationLevels" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Chuyên nghành" v-model="form.educationMajorCode"
                                                    :items="educationMajors" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-autocomplete label="Trường" v-model="form.universityCode"
                                                    :items="universities" item-text="name" item-value="code"></v-autocomplete>
                                                </v-col>
                                                
                                                <v-col cols="12">
                                                    <v-text-field :value="formatPharmaCert(form.pharmaCert)" label="Chứng chỉ hành nghề dược"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>

                                    <v-tab-item >
                                        <v-container>
                                            <v-row>
                                                <v-col v-if="form.yearExperience == 0">
                                                    Chưa có kinh nghiệm làm việc
                                                </v-col>
                                                
                                                <v-col v-else>
                                                    <v-data-table :headers="expCompanyHeaders" :items="expCompanies">

                                                        <template v-slot:[`item.from`]="{ item }" >
                                                            {{ $formatter.formatDate(item.from) }}
                                                        </template>
                                                        
                                                        <template v-slot:[`item.to`]="{ item }" >
                                                            {{ $formatter.formatDate(item.to) }}
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>

                                    <v-tab-item >
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <label class="d-block">File đính kèm</label>
                                                    <span class="error--text" v-if="!form.attachmentUrl">Không có file đính kèm</span>
                                                    <v-btn v-else color="green darken-4" text target="_blank" :href="form.attachmentUrl"><v-icon left>mdi-file</v-icon> Tải xuống</v-btn>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <label class="d-block">CMND/CCCD mặt trước</label>
                                                    <img :src="form.idFrontImageUrl" alt="" srcset="" width="100%">
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <label class="d-block">CMND/CCCD mặt sau</label>
                                                    <img :src="form.idBackImageUrl" alt="" srcset="" width="100%">
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>
                                </v-tabs>
                            </v-col>
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>

                            <!-- Section cho admin nhan su -->
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-card class="rounded-xl" elevation="5">
                            <v-card-title class="text-subtitle-1 font-weight-bold green--text text--darken-4">
                                    <v-divider></v-divider>
                                <span class="px-4">ĐÁNH GIÁ NHÂN VIÊN</span>
                                    <v-divider></v-divider>
                            </v-card-title>
                            <v-card-text>
                            <v-row class="">
                                <v-col cols="12">
                                    <v-select label="Trạng thái" v-model="form.status" :items="statuses"
                                    filled dense rounded hide-details="auto"></v-select>
                                </v-col>
                                <v-col v-show="form.status == 'FAIL'">
                                    <v-select
                                        :items="failNotes"
                                        v-model="form.failNote"
                                        label="Lý do hồ sơ loại"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <date-picker2 :width="400" v-model="form.interviewAt" :range="false" label="Ngày phỏng vấn" :dense="true" :hideDetails="true" outlined hide-details></date-picker2>
                                </v-col>
                                
                                <v-col cols="12">
                                    <v-text-field label="Mức lương thỏa thuận" v-model="form.dealSalary" prepend-icon="mdi-cash" :hint="currencyFormat(form.dealSalary)" suffix="vnd"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Mức lương mong đợi" v-model="form.expectSalary" prepend-icon="mdi-cash" :hint="currencyFormat(form.expectSalary)" suffix="vnd"></v-text-field>
                                </v-col>
                                <v-col>
                                    <appraise-dialog :item="form" ></appraise-dialog>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea label="Nhận xét" v-model="form.comment" hide-details
                                    outlined dense></v-textarea>
                                </v-col>
                                
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        filled rounded
                                        :items="notAcceptNotes"
                                        v-model="form.notAcceptNote" clearable
                                        label="Lý do không nhận việc"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import http from '@/components/services/http-common'
import ApiService from '@/components/services/ApiServices'
import moment from 'moment';
import Appraise from './Appraise.vue';

export default {
    components: {
        "date-picker": DatePicker,
        "appraise-dialog": Appraise,
    },
    props: ["value", "item"],
    watch: {
        dialog: {
            handler(val){
                this.fetchJob()
                this.fetchCity()
                this.fetchEducationLevel()
                this.fetchEducationMajor()
                this.fetchUniversity()
                this.fetchOrigin()
                this.fetchEthnicity()
                this.fetchReligion()
                if(val)
                {
                    this.$nextTick(() => {
                        console.log("run")
                        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
                    });
                    if(this.isEdit)
                        this.fetch().then(() => {
                            this.fetchJobDistrict1(this.form.city1Code, true)
                            this.fetchJobDistrict2(this.form.city2Code, true)
                            this.fetchDistrict(this.form.cityCode, true)
                            this.fetchWard(this.form.districtCode, true)
                            
                            this.fetchDDistrict(this.form.dCityCode, true)
                            this.fetchDWard(this.form.dDistrictCode, true)
                        });
                }
                else{
                    this.close();
                }
            }
        }
    },
    computed:{
        dialog: {
            get: function() {
                return this.value;
            },
            set: function(val) {
                return this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            endpoint: process.env.VUE_APP_BASE_URL,
            ROOT_URL: "candidates",
            JOB_URL: "candidates/fetch-job",
            //DIALOG
            dialogDelete: false,
            targetItem: {},

            failNotes: [
                "Không đến phỏng vấn",
                "Nhảy việc nhiều",
                "Không phù hợp văn hóa công ty",
                "Yêu cầu mức lương cao",
                "Địa điểm làm việc không phù hợp",
                "Độ tuổi không phù hợp",
                "Liên hệ nhiều lần không được",
                "Khác"
            ],
            notAcceptNotes: [
                "Đã tìm được công việc khác",
                "Địa điểm làm việc không phù hợp",
                "Công ty khác đãi ngộ tốt hơn",
                "Đang bận việc gia đình",
                "Khác",
            ],

            valid: true,
            expCompanies: [],

            form: {
                //tab1
                avatarUrl:'',
                name: '',
                phoneNumber: '',
                mail: '',
                gender: false,
                birthDay: new Date().toISOString().substr(0, 10),
                jobCode: null,
                cityCode: null,
                districtCode: null,
                wardCode: null,
                address: null,
                DCityCode:null,
                DDistrictCode:null,
                DWardCode:null,
                DAddress:null,
                
                city1Code: null,
                city2Code: null,

                educationLevelCode:null,
                educationMajorCode:null,
                universityCode:null,
                recruitChannel:null,
                yearExperience:null,
                interviewAt: moment().format("YYYY-MM-DD"),

                attachmentFile: undefined,
                attachmentUrl: "",
                dealSalary:null,
                expectSalary:null,
                height: 1,
                weight: 40,
                
                failNote:"",
                notAcceptNote: "",
                pharmaCert: "",
                idFrontImageUrl: "",
                idBackImageUrl: "",

                originCode: "",
                ethnicityCode: "",
                religionCode: "",
                jobDistrict1Code: "",
                jobDistrict2Code: "",

            },
            defaultForm: {
                //tab1
                avatarUrl:'',
                name: '',
                phoneNumber: '',
                mail: '',
                gender: false,
                birthDay: new Date().toISOString().substr(0, 10),
                jobCode: null,
                cityCode: null,
                districtCode: null,
                wardCode: null,
                address: null,
                DCityCode:null,
                DDistrictCode:null,
                DWardCode:null,
                DAddress:null,
                
                city1Code: null,
                city2Code: null,

                educationLevelCode:null,
                educationMajorCode:null,
                universityCode:null,
                recruitChannel:null,
                yearExperience:null,
                interviewAt: moment().format("YYYY-MM-DD"),

                attachmentFile: undefined,
                attachmentUrl: "",
                dealSalary:null,
                expectSalary:null,
                height: 1,
                weight: 40,
                
                failNote:"",
                notAcceptNote: "",
                pharmaCert: "",
                idFrontImageUrl: "",
                idBackImageUrl: "",

                originCode: "",
                ethnicityCode: "",
                religionCode: "",
                jobDistrict1Code: "",
                jobDistrict2Code: "",
            },
            
            cities:[],
            districts:[],
            wards:[],

            dCities:[],
            dDistricts:[],
            dWards:[],
            jobs:[],
            educationLevels:[],
            educationMajors:[],
            universities:[],
            origins: [],
            ethnicities:[],
            religions:[],
            jobDistrict1s:[],
            
            expCompanyHeaders: [
                { text: 'Tên', value: 'name'},
                { text: 'Vị trí', value: 'position'},
                { text: 'Từ', value: 'from'},
                { text: 'Đến', value: 'to'},
                { text: 'Ghi chú', value: 'quitNote', width: 250},
                { text: 'Tham chiếu', value: 'reference'},
            ],

            statuses: [
                {text: "Mới", value:"NEW"},
                {text: "Phỏng vấn", value:"PEND"},
                {text: "Đạt", value:"PASS"},
                {text: "Loại", value:"FAIL"},
                {text: "Không phỏng vấn", value:"NMEET"},
                {text: "Không liên hệ", value:"BLACK"},
            ],
            recruitChannels:[
                {text: "Mạng xã hội", value: "MXH"},
                {text: "Tờ rơi", value: "TR"},
                {text: "Được giới thiệu", value: "N"},
                {text: "Trang tuyển dụng", value: "TTD"},
            ],
            yearExperiences:[
                {text: " Chưa có kinh nghiệm", value: 0},
                {text: " 6 tháng", value: 0.5},
                {text: " 1 năm", value: 1},
                {text: " 2 năm", value: 2},
                {text: " 3 năm", value: 3},
            ],
        }
    },
    methods: {
        
        copyText(refName) {
            let textToCopy = this.$refs[refName].$el.querySelector('input')
            textToCopy.select()
            document.execCommand("copy");
        },
        formatPharmaCert(value) {
            const pharmaCerts = {
                "KHONG": "Không có",
                "CD": "CCHN Cao đẳng",
                "DH": "CCHN Đại học",
            }
            return pharmaCerts[value]
        },
        currencyFormat(x) {
            if(x!=null)
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        createEmployee () {
            var candidate = "2"
            this.$router.replace({name:'Employee', params:{candidate}});
        },
        download() {
            return http.get(this.form.attachmentUrl,
            {
                params: {
                    download: this.form.attachmentUrl
                },
                responseType: 'arraybuffer',
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = this.form.attachmentUrl;
                link.setAttribute('download', 'hosoungvien_' + this.form.name +'.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        },
        close () {
            this.$emit("CloseDialog");
            this.expCompanies = []
            this.form = Object.assign({}, this.defaultForm);
        },

        save(){
            return http.put(this.ROOT_URL+"/"+this.item.code, this.form).then(res => {
                this.dialog=false;
                this.$emit("fetch");
            });
        },

        // ********** Fetch data *************
        fetch () {
            return http.get(this.ROOT_URL + "/" + this.item.code)
            .then(res => {
                let item = res.data.item
                this.expCompanies = res.data.workHistory
                this.form = Object.assign({}, item)
                this.form.birthDay = moment(this.form.birthDay, "DD/MM/YYYY").format("YYYY-MM-DD")
                this.form.idDate = moment(this.form.idDate,"YYYY-MM-DD").format("YYYY-MM-DD")
                // this.form.idDate = moment(this.form.idDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                // this.form.iDdate = this.form.iDdateString
            })
            .catch(err => {
                console.log(err);
            });
        },
        
        fetchJob () {
            return http.get(this.JOB_URL)
            .then(res => {
                this.jobs = res.data.items;
            }).catch(err => {throw err});
        },
        fetchCity () {
            return http.get("cities", {
                params: {dropDown: 1}
            }).then(res => {
                this.cities = res.data.items;
            }).catch(err => {
                console.log(err);
            });
        },
        fetchDistrict (val, init = false) {
            if(val){
                if(!init)
                {
                    this.districts = [];
                    this.form.districtCode=null;
                }
                return http.get("cities/fetch-district/"+val).then(res => {
                    this.districts = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        fetchJobDistrict1 (val, init = false) {
            if(val){
                if(!init)
                {
                    this.jobDistrict1s = [];
                    this.form.jobDistrict1Code=null;
                }
                return http.get("cities/fetch-district/"+val).then(res => {
                    this.jobDistrict1s = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        fetchJobDistrict2 (val, init = false) {
            if(val){
                if(!init)
                {
                    this.jobDistrict2s = [];
                    this.form.jobDistrict2Code=null;
                }
                return http.get("cities/fetch-district/"+val).then(res => {
                    this.jobDistrict2s = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        
        fetchWard (val, init = false) {
            if(val){
                if(!init)
                {
                    this.wards = [];
                    this.form.wardCode=null;
                }
                return http.get("districts/fetch-ward/"+val).then(res => {
                    this.wards = res.data;
                });
            }
        },
        
        fetchDDistrict (val, init = false) {
            if(val){
                if(!init)
                {
                    this.dDistricts = [];
                    this.form.dDistrictCode=null;
                }
                return http.get("cities/fetch-district/"+val).then(res => {
                    this.dDistricts = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        
        fetchDWard (val, init = false) {
            if(val){
                if(!init)
                {
                    this.dWards = [];
                    this.form.dWardCode=null;
                }
                return http.get("districts/fetch-ward/"+val).then(res => {
                    this.dWards = res.data;
                });
            }
        },
        
        fetchEducationLevel(){
            ApiService.fetchEducationLevel().then(res=>{
                this.educationLevels = res.data;
            })
        },
        fetchEducationMajor(){
            ApiService.fetchEducationMajor().then(res=>{
                this.educationMajors = res.data;
            })
        },
        fetchUniversity(){
            ApiService.fetchUniversity().then(res=>{
                this.universities = res.data;
            })
        },
        
        fetchOrigin(){
            return ApiService.fetchOrigin().then(res=>{
                this.origins = res.data;
            })
        },
        fetchEthnicity(){
            return ApiService.fetchEthnicity().then(res=>{
                this.ethnicities = res.data;
            })
        },
        fetchReligion(){
            return ApiService.fetchReligion().then(res=>{
                this.religions = res.data;
            })
        },

        // fetchEducation () {
        //     http.get("educationlevels").then(res => {
        //         this.formData.EducationLevels = res.data.items.map(function(obj) {
        //         return {
        //             text: obj.name,
        //             value: obj.code,
        //             }
        //         });
        //     }).catch(err => {
        //         console.log(err)
        //     });
        //     http.get("universities").then(res => {
        //         this.Universities = res.data.items.map(function(obj) {
        //         return {
        //             text: obj.name,
        //             value: obj.code,
        //             }
        //         });
        //     }).catch(err => {
        //         console.log(err)
        //     });
        //     http.get("academicranks").then(res => {
        //         this.formData.AcademicRanks = res.data.items.map(function(obj) {
        //         return {
        //             text: obj.name,
        //             value: obj.code,
        //             }
        //         });
        //     }).catch(err => {
        //         console.log(err)
        //     });
        //     http.get("educationmajors").then(res => {
        //         this.formData.EducationMajors = res.data.items.map(function(obj) {
        //         return {
        //             text: obj.name,
        //             value: obj.code,
        //             }
        //         });
        //     }).catch(err => {
        //         console.log(err)
        //     });
        // },
    },
    created () {
    }

}
</script>
<style scoped>
::v-deep .v-input label.v-label.v-label--active.theme--light {
    font-weight: bold !important;
}
</style>