import http from '@/components/services/http-common';
// import * as moment from "moment/moment"

export const ROUTE = 'bonuses';
// global api
class BonusApi {
  gets(month) {
    return http.get(`${ROUTE}`, {
      params: {
        month: month
      }
    }).then(r => r.data)
  }
  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => r.data)
  }

  post(data) {
    return http.post(`${ROUTE}`,data)
  }

  put(id, data) {
    return http.put(`${ROUTE}/${id}`, data)
  }

  deleteItem(id){
    return http.delete(`${ROUTE}/${id}`)
  }

  //drop down data
  
  //get employees
  getEmployees() {
    return http.get('employees/simple').then(r => r.data)
  }
  getBonusTypes() {
    return http.get('bonusTypes').then(r => r.data)
  }
  getHistories() {
    return http.get('bonuses/histories').then(r =>r.data)
  }

  importFile(month, type, file) {
    let formData = new FormData()
    formData.append('month', month)
    formData.append('bonusTypeCode', type)
    formData.append('importFile', file)
    return http.post('bonuses/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  
}

export default new BonusApi();