import moment from 'moment';
export const ROUTE = 'TemporaryStores';
import http from '@/components/services/http-common';

class TemporaryStoreDetailApi {
  gets() {
    return http.get(`${ROUTE}/employees`).then(r=>r.data)
  }
  get(id) {
    return http.get(`${ROUTE}/employees/${id}`).then(r=>r.data)
  }
  post(data) {
    return http.post(`${ROUTE}/employees`, data)
  }
  put(id, data) {
    return http.put(`${ROUTE}/employees/${id}`, data)
  }
  delete(data) {
    return http.delete(`${ROUTE}/employees/${data.id}`)
  }

  getTempStore() {
    return http.get(`${ROUTE}`).then(r=>r.data)
  }
}
export default new TemporaryStoreDetailApi();