import http from '@/components/services/http-common';
import apiService from '@/components/services/ApiServices';
import * as moment from "moment/moment";

import CreateDialog from '../Create.vue';

export default {
    components: {
        "create-dialog": CreateDialog
    },
    name: 'MonthlyEmployee',
    props:["breadcrumbs", "icon"],
    data(){
        return {
            isLoading: true,
            items: [],
            dialog: false,
            item: null,
            headers:[
                { text: 'Thao tác', value: 'actions', sortable: false, width: "7rem"},
                { text: 'Mã', value: 'code', sortable: false, width: "7rem"},
                { text: 'Tháng', value: 'month', sortable: false, width: "7rem"},
                { text: 'Tên', value: 'name', sortable: false, width: "7rem"},
                { text: 'Số lượng tổng', value: 'total', sortable: false, width: "7rem"},
                { text: 'Số lượng nghỉ', value: 'quit', sortable: false, width: "7rem"},
                { text: 'Số lượng tạm nghỉ', value: 'suspend', sortable: false, width: "7rem"},
                { text: 'Số lượng thai sản', value: 'pregnant', sortable: false, width: "7rem"},
                { text: 'Số lượng đang làm việc', value: 'online', sortable: false, width: "7rem"},
                { text: 'Khác', value: 'other', sortable: false, width: "7rem"},
                { text: 'Ghi chú', value: 'note', sortable: false, width: "7rem"}
            ],
            month: moment().format("YYYY-MM-DD"),

            cfmItem: undefined,
            cfmDeleteDialog:false,
        }
    },
    watch :{
        
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
    },
    methods:{
        fetchData(){
            return apiService.fetchMonthlyEmployee(this.month).then(res => {
                this.items  = res.data;
                this.isLoading = false;
            })
        },
        
        editItem(item) {
            this.dialog= true;
            this.item = item;
        },
        
        cfmDelete(item) {
            this.cfmDeleteDialog=true;
            this.cfmItem= item;
        },

        deleteItem()
        {
            let url = `monthlyEmployees/${this.cfmItem.code}`
            return http.delete(url).then(res=>{
                this.fetchData();
            });
        }
    },

    created(){
        this.fetchData();
    }
}