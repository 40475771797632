<template>
    <div class="position">
        <create-dialog ref="createDialog" @needFetch="fetchData" v-model="dialog"></create-dialog>
        <v-card>
            <v-card-title>
                Danh sách chức vụ
            <v-spacer></v-spacer>
            
            <v-text-field v-model="search" @keydown.enter="fetchData" class="mr-5" style="max-width:300px" outlined dense
                append-icon="mdi-magnify" label="Tìm kiếm" single-line hide-details
            ></v-text-field>
            <create-button @click="openDialog"></create-button>
            </v-card-title>
            <v-data-table height="600px"
            :headers="headers"
            :items="positions"
            :options.sync="options"
            :server-items-length="totalItem"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..." >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import http from '@/components/services/http-common'
import PositionCreateDialog from './Create'

export default {
    name: 'Position',
    components: {
        'create-dialog': PositionCreateDialog
    },
    data () {
        return {
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            rootUrl: "positions",
            
            //datatable config
            totalItem: 0,
            positions: [],
            search:"",
            options: {},
            isLoading: true,
            dialog: false,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', sortable: false, width: '7rem'},
                { text: 'Mã vị trí', align: 'start', sortable: false, value: 'code', width: '7rem'},
                { text: 'Tên vị trí', align: 'start', value: 'name', width: '14rem'},
                { text: 'Thứ tự', value: 'order', sortable: false, width: '7rem' },
                { text: 'Mô tả', value: 'description' },
                // { text: 'Hình thức làm việc', value: 'state' },
                // { text: 'Cấp trên', value: 'parent' },
            ],
        }
    },
    methods:{
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData () {
            const { page, itemsPerPage } = this.options;
            if(page != undefined || itemsPerPage != undefined)
                http.get(this.rootUrl + '?search='+this.search + '&page='+ page + '&pageSize=' + itemsPerPage).then(res => {
                    this.isLoading = false;
                    this.positions = res.data.items;
                    this.totalItem = res.data.totalItem;
                    console.log(res.data);
                }).catch(err => {
                    console.log(err);
                });
        },
        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate();
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.rootUrl + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    mounted () {
        this.fetchData();
    }
}
</script>

<style lang="stylus" scoped>

</style>