<template>
  <div>
    <my-breadcrumb :data="breadcrumb"></my-breadcrumb>
    <v-card>
      <v-card-title>
        <v-text-field style="max-width: 200px;" class="mx-2" 
        outlined hide-details v-model="month" @change="getData" type="date" dense placeholder="Chọn tháng" persistent-placeholder></v-text-field>

        <v-spacer></v-spacer>
        <v-btn icon color="teal" @click="getData" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
        <export-excel :items="items" :headers="headers"> </export-excel>
        <v-btn class="ml-2" @click="applyHandler" color="teal darken-4" small dark>Tạo bảng lương</v-btn >
      </v-card-title>

      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" xl="2" md="3">
            <v-text-field dense v-model="searchInput" @keydown.enter="search=searchInput" outlined placeholder="Tìm kiếm" hide-details persistent-placeholder></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :items="items" :headers="headers" :search="search" :loading="isLoading" dense height="600px" :items-per-page="100" >
          <template v-slot:[`item.ngay_Nhan_Viec`]="{ item }">
            {{ formatDate(item.ngay_Nhan_Viec) }}
          </template>
          <template v-slot:[`item.luong_Thang_5_2023`]="{ item }">
            {{ addThousandSeparator(item.luong_Thang_5_2023) }}
          </template>
          
          <template v-slot:[`item.tong_Gross_Luong_PC_Thuc_Te`]="{ item }">
            {{ addThousandSeparator(item.tong_Gross_Luong_PC_Thuc_Te) }}
          </template>
          <template v-slot:[`item.luong_Tang_Ca`]="{ item }">
            {{ addThousandSeparator(item.luong_Tang_Ca) }}
          </template>

          
          <!-- <template v-slot:[`header.luong_Tang_Ca`]="{ item }">
            {{ addThousandSeparator(item.luong_Tang_Ca) }}
          </template> -->

          <template v-slot:[`item.vi_Tri`]="{ item }">
            <h3>Khối: <span class="font-weight-medium">{{ item.khoi }}</span></h3>
            <h3 class="teal--text text--darken-2">Đơn vị: <span class="font-weight-medium">{{ item.ma_CN }}</span></h3>
            <h3>Chức vụ: <span class="font-weight-medium">{{ item.vi_Tri }}</span></h3>
            <h3>Chức danh: <span class="font-weight-medium">{{ item.cot }}</span></h3>
            <h3>Ngày nhận việc: <span class="font-weight-medium">{{ formatDate(item.ngay_Nhan_Viec) }}</span></h3>
          </template>
          <template v-slot:body.append>
            <tr>
              <td :colspan="headers.length" style="background: white;">
                <v-btn text color="teal darken-4" class="ml-6">Tải thêm dữ liệu ....</v-btn>  
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
      
    <v-dialog>
      <v-card>
        <v-card-title>
          Chi tiết
        </v-card-title>
        <v-card-text>
          <v-table>
            
          </v-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import EmployeePayrollApi from './api.js'
import moment from 'moment';
import ExportExcel from '@/components/ExportExcel'
import MyBreadcrumb from '@/components/MyBreadcrumb'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  data() {
    return {
      breadcrumb: [
        {text: "Bảng lương tháng", to: {name: 'EmployeePayroll'}}
      ],

      items: [],
      search: '',
      searchInput: '',
      isLoading: false,
      headers: [
        // { text: 'Code', value: 'code' },
        // { text: 'Ma_Concate', value: 'ma_Concate', cellClass: 'basic-yellow', width: 70},
        { text: 'MSNV', value: 'msnv', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header', width: 100},
        { text: 'Họ và tên', value: 'ho_Ten', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header', width: 150 },
        { text: 'Vị trí', value: 'vi_Tri', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header', width: 300 },
        { text: 'Thâm niên (Tháng)', value: 'tham_Nien_Months', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header' },
        { text: 'HĐ', value: 'loai_HD', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header' },
        { text: 'BH_KBH', value: 'bH_KBH', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header' },
        { text: 'Ngày nghỉ', value: 'ngay_Nghi_Viec', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header' },
        { text: 'Loại nghỉ', value: 'loai_Nghi', cellClass: 'basic-yellow with-divider', class: 'yellow-header fixed-header' },
        { text: 'Lương tháng trước', value: 'luong_Thang_4_2023', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'Lương tháng hiện tại', value: 'luong_Thang_5_2023', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'Lương CB', value: 'luong_Co_Ban', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'Thâm niên', value: 'luong_Tham_Nien', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'PC Khu vực', value: 'phu_Cap_Khu_Vuc', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'HT Đào tạo', value: 'hoTro_DaoTao', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'HQ1', value: 'luong_Hieu_Qua_1_KPI', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'HQ2', value: 'luong_Hieu_Qua_2_KPI', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'Đơn giá cơm', value: 'an_Giua_Ca_Ngay', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'PC Điện thoại', value: 'dien_Thoai', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'PC Công tác', value: 'khoan_Chi_Cong_Tac', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'Tong_TN_Gop', value: 'tong_TN_Gop', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header', sortable: false  },
        { text: 'GCC', value: 'gC_Chuan', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false   },
        { text: 'GCTL', value: 'gC_Tinh_Luong', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false  },
        { text: 'GCLT', value: 'gC_Tang_Them', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false   },
        { text: 'GCTC', value: 'gC_Tang_Ca', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false   },
        { text: 'GCL', value: 'gC_Le_Tet', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false   },
        { text: 'Số cơm', value: 'cong_Tinh_Com', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false   },
        { text: 'Tổng GC', value: 'tong_Gio_Cong', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false   },
        { text: 'Ký hiệu', value: 'cT_CB_KC', cellClass: 'timesheet-coral with-divider', class: 'coral-header fixed-header', sortable: false   },
        { text: 'Lương CB', value: 'luong_Co_Ban_2', cellClass: 'actual-green with-divider', class: 'green-header fixed-header', sortable: false },
        { text: 'Lương thâm niên', value: 'luong_Tham_Nien_2', cellClass: 'actual-green with-divider', class: 'green-header fixed-header', sortable: false },
        { text: 'Lương LT', value: 'luong_Lam_Them', cellClass: 'actual-green with-divider', class: 'green-header fixed-header', sortable: false },
        { text: 'Lương TC', value: 'luong_Tang_Ca', cellClass: 'actual-green with-divider', class: 'green-header fixed-header', sortable: false },
        { text: 'Lương lễ', value: 'luong_Le_Tet', cellClass: 'actual-green with-divider', class: 'green-header fixed-header', sortable: false },
        { text: 'PC Khu vực', value: 'phu_Cap_Khu_Vuc_2', cellClass: 'actual-green with-divider', class: 'green-header fixed-header', sortable: false },
        { text: 'HT Đào tạo', value: 'hoTro_DaoTao_2', cellClass: 'actual-green with-divider', class: 'green-header fixed-header', sortable: false },
        { text: 'PC Chuyên môn', value: 'phu_Cap_Chuyen_Mon', cellClass: 'actual-green with-divider', class: 'green-header  fixed-header', sortable: false },
        { text: 'HQ1', value: 'luong_Hieu_Qua_1_KPI_2', cellClass: 'actual-green with-divider', class: 'green-header  fixed-header', sortable: false },
        { text: 'HQ2', value: 'luong_Hieu_Qua_2_KPI_2', cellClass: 'actual-green with-divider', class: 'green-header  fixed-header', sortable: false },
        { text: 'Tiền cơm', value: 'an_Giua_Ca_Ngay_2', cellClass: 'actual-green with-divider', class: 'green-header  fixed-header', sortable: false },  //tiền cơm sau khi nhân số cơm
        { text: 'PC Điện thoại', value: 'dien_Thoai_2', cellClass: 'actual-green with-divider', class: 'green-header  fixed-header', sortable: false },
        { text: 'PC Công tác', value: 'khoan_Chi_Cong_Tac_2', cellClass: 'actual-green with-divider', class: 'green-header  fixed-header', sortable: false },
        { text: 'Tong_Gross_Luong_PC_Thuc_Te', value: 'tong_Gross_Luong_PC_Thuc_Te', cellClass: 'actual-green with-divider', class: 'green-header  fixed-header' },
        { text: 'Truy lãnh', value: 'bo_Sung_Hoan_Tra', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header' },
        { text: 'Truy thu', value: 'truy_Thu_Tru_Khac', cellClass: 'salary-blue with-divider', class: 'blue-header fixed-header' },
        { text: 'Tổng thu nhập', value: 'tong_Thu_Nhap', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Quỹ BHXH/YT', value: 'quybhxh_yt', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Quỹ BHTN', value: 'quybhtn', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'BH 10.5%', value: 'bh10_5', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'CĐ phí', value: 'cdp_1', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' }, 

        { text: 'BH 21.5%', value:'bh21_5', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Công đoàn phí', value:'kpcd2', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Người PT', value:'so_npt', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Thu nhập chịu thuế', value:'thu_nhap_chiu_thue', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Thu nhập tính thuế', value:'thu_nhap_tinh_thue', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Thuế TNCN', value:'thue_tncn', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Hoàn ứng', value:'hoan_ung', cellClass: 'actual-green with-divider', class: 'green-header fixed-header' },
        { text: 'Thực lãnh', value:'thunhapthuclanh', class: 'coral-header fixed-header' },

        // { text: 'Thang', value: 'thang' },
      ],

      month: moment().startOf('month').format('YYYY-MM-DD')
    }
  },
  methods: {
    formatDate(value) {
        return moment(value).format("DD/MM/YYYY");
    },
    addThousandSeparator(number) {
      // Convert the number to a string
      let numberString = number.toString();

      // Split the string into integer and decimal parts
      let parts = numberString.split('.');

      // Add thousand separators to the integer part
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Join the integer and decimal parts back together
      let result = parts.join('.');

      return result;
    },

    getData() {
      return EmployeePayrollApi.gets(this.month).then(d=> {
        
        this.items = d.data
        console.log(d)
      })
    },
    applyHandler() {
      this.isLoading = true
      return EmployeePayrollApi.apply(this.month).then(()=> {
        this.getData()
        this.isLoading = false
      })
    }
  },
  components: {
    ExportExcel,
    MyBreadcrumb

  },
  created()  {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
// header color
::v-deep .fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
::v-deep .white-header{
  color: black !important;
  background: white;
}
::v-deep .yellow-header{
  color: black !important;
  background: #FFF59D;
}
::v-deep .blue-header{
  color: black !important;
  background: #90CAF9;
}
::v-deep .coral-header{
  color: black !important;
  background: #FFAB91;
}
::v-deep .green-header{
  color: black !important;
  background: #A5D6A7;
}

// collumn colors
// ::v-deep tr:has(.salary-blue):hover{
//   background: rgba(0,0,0, .2);
// }

// ::v-deep .basic-yellow {
//   background: rgba(255, 253, 231, .5);
// }
// ::v-deep .salary-blue {
//   background: rgba(227, 242, 253, .5);
// }
// ::v-deep .timesheet-coral {
//   background: rgba(255, 235, 238, .5);
// }
// ::v-deep .actual-green {
//   background: rgba(232, 245, 233, .5);
// }


::v-deep table > tbody > tr > td:nth-child(1),
  table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 0;
    background: rgba(255, 255, 255, 1);
  }
::v-deep table > thead > tr > th:nth-child(1) {
    z-index: 9997;
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
  }
  
::v-deep table > tbody > tr > td:nth-child(2)
 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 100px;
    z-index: 0;
    background: rgba(255, 255, 255, 1);
  }
::v-deep table > thead > tr > th:nth-child(2) {
    z-index: 99;
    // position: sticky !important;
    // position: -webkit-sticky !important;
    left: 100px;
    // background: red;
  }
  
::v-deep .with-divider {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
::v-deep .row-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
} 

</style>