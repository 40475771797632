<template>
    <div>
        <!-- <create-dialog :value="dialog"
        @close-dialog="dialog=false, item=null"
        :item="item" @fetchData="fetchData" @setSelect="setSelect"></create-dialog> -->
        <v-card>
            <v-toolbar color="white" flat>
                <v-toolbar-title class="mx-4">Cài đặt ASM</v-toolbar-title>
                <v-spacer></v-spacer>
                
                <!-- //actions -->
                <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success" class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                    <v-list-item @click="dialog=true">
                        <v-list-item-title>Thêm ASM <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            
            <v-card-title>
                <v-row justify="start">
                    <!-- <v-col cols="12" md="2">
                        <v-dialog
                            ref="monthDialog"
                            :return-value.sync="month"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense 
                                v-model="month"
                                label="Chọn tháng *"
                                prepend-icon="mdi-calendar"
                                readonly hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker dense locale="vi"
                            v-model="month"
                            type="month"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.monthDialog.save(month)"
                            >
                                Chọn
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col> -->
                    <v-col cols="12" md="12">
                        <v-text-field v-model="globalSearch" hide-details outlined dense full-width
                             placeholder="Tìm kiếm" persistent-placeholder label="Tìm mã phiếu" ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="2">
                        <v-select v-model="filterStatus" hide-details outlined dense :items="['Mới', 'Đã nhận', 'Hoàn thành', 'Chối từ']" clearable
                             placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm mã phiếu" ></v-select>
                    </v-col> -->
                    <!-- <v-col cols="12" md="2">
                        <v-text-field v-model="search" hide-details outlined dense
                             placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm chi nhánh" ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" md="2">
                        <v-text-field v-model="filterDepartment" hide-details outlined dense
                             placeholder="Tìm kiếm chi nhánh" persistent-placeholder label="Tìm kiếm chi nhánh" ></v-text-field>
                    </v-col> -->
                </v-row>
            </v-card-title>
        </v-card>
        <v-row class="mt-5">
            <v-col cols="12" md="3">
                <v-card>
                    <v-card-title> ASM</v-card-title>
                    <v-data-table :items="items1" :headers="headers1"  height="calc(100vh - 300px)" 
                    :search="globalSearch" single-select show-select item-key="code" v-model="item1Select">
                        <template v-slot:[`item.actions`]="{item}" >
                            <v-menu dense
                                left
                                bottom offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" x-small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        Thao tác<v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    
                                    <v-list-item @click="edit(item.id)" dense>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-cursor-text</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Chi tiết </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="exportTemplate(item)" dense>
                                        <v-list-item-icon>
                                            <v-icon color="green">mdi-microsoft-excel</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Xuất file</v-list-item-title>
                                    </v-list-item>
                                    
                                    <v-list-item @click="publish(item.id)" dense>
                                        <v-list-item-icon>
                                            <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                        <v-list-item-icon>
                                            <v-icon color="error">mdi-delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Xóa</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- <edit-button @click="edit(item)"></edit-button>
                            <delete-button @click="deleteItem(item)"></delete-button> -->
                        </template>

                        <template v-slot:[`item.isPublished`]="{item}" >
                            <v-icon color="success" v-if="item.isPublished==1">mdi-check</v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title>Phòng ban của: {{selectedTemplate}}
                        <v-spacer></v-spacer>
                        <v-btn v-if="item2Select.length>0 && item1Select.length>0" small outlined color="error" @click="removeAsmDept">Gỡ phòng ban</v-btn>
                    </v-card-title>

                    <!-- datatable -->
                    <v-data-table
                    show-select item-key="code" v-model="item2Select"
                    :items="items2"
                    :headers="headers2" 
                    no-data-text="Không có dữ liệu"
                    height=""
                    >
                    </v-data-table>
                </v-card>
            </v-col>
            
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title>Phòng ban khả dụng: {{selectedTemplate}}
                    <v-spacer></v-spacer>
                    <v-btn v-if="item3Select.length>0 && item1Select.length>0" small outlined color="success" @click="addAsmDept">Thêm phòng ban</v-btn>
                    </v-card-title>

                    <!-- datatable -->
                    <v-data-table
                    show-select item-key="code" v-model="item3Select"
                    :items="items3"
                    :headers="headers2" 
                    no-data-text="Không có dữ liệu"
                    height="300px"
                    >
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này? ID: {{targetItem}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteTemplate">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loadingDialog" width="" persistent>
            <v-card>
                <v-card-title class="text-center">
                    <v-spacer></v-spacer>
                    Đang xuất file
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="text-center">
                    <v-progress-circular
                    size="70"
                    indeterminate
                    color="orange"
                    ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CreateDialog from './Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import api from './api.js'

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    computed: {
        selectedTemplate() {
            let rslt = this.item1Select.length>0 ? `${this.item1Select[0].code }`: ""

            return rslt
        },

        items2() {
            let validDept = this.departments.filter(x=> {
                return (x.deptType == 'Vận hành' && x.asmCode)
            })
            if(!this.selectedTemplate){
                return validDept
            } else {
                return validDept.filter(x =>{
                    if(x.asmCode ==null) return false
                    else{
                        console.log(x.asmCode.toString())
                        console.log(this.selectedTemplate.toString())
                        return x.asmCode.toString() == this.selectedTemplate.toString()
                    }
                })
            }
        },
        items3() {
            let validDept = this.departments.filter(x=> {
                return (x.deptType == 'Vận hành' && !x.asmCode)
            })
            return validDept
        }
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        },
        // item1Select() {
        //     if(this.selectedTemplate != ""){
        //         this.getQuestions()
        //     } else{
        //         this.questions=[]
        //     }
        // }
    },
    data () {
        return {
            month: moment().startOf("months").format("yyyy-MM"),
            item: null,

            item1Select: [],
            item2Select: [],
            item3Select: [],
            items1:[],
            // items2:[],
            departments: [],
            search:"",

            filterCode:"", 
            filterStatus:"", 
            filterDepartment:"", 
            currentItem: "",

            loadingDialog: false,
            globalSearch:"",
            headers1: [
                // {text:"Thao tác", value: "actions", width:"100px"},
                {text:"Tên ASM", value: "name", width:"150px"},
                // {text:"Mã NS", value: "code"  },
                // {text: 'Xuất bản', value: 'isPublished', width: '200px'}
            ],
            
            headers2: [
                // {text:"Thao tác", value: "actions", width:"7rem"},
                {text:"Mã", value: "code", width:"2rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Tên phòng ban", value: "name", width:"7rem"},
                
                // {text:"Mã NS ASM", value: "asmCode", width:"5rem"},
                {text:"Asm", value: "asm", width:"5rem"},
            ],

            questionTypes: [
                {text: "Tự luận", value: "TEXT"},
                {text: "Lựa chọn", value: "OPTION"},
                {text: "Nhiều lựa chọn", value: "MULTI_OPTION", disabled: true},
            ],
            question:{
                type: "OPTION",
                name:"",
            },
            questions:[],

            answerHeader:[
                {text:"Câu trả lời", value:"name"},
                {text:"Xóa", value:"actions", width: 150}

            ],
            answers: [],
            answer:"",

            dialogDelete: false,
            targetItem: null,
            dialog: false,
            questionDialog:false,
        }
    },
    methods: {
        getAsm() {
            return this.apiService.getAsm().then(d => {
                this.items1 = d
            })
        },
        getDepartment() {
            return this.apiService.getDepartment().then(d => {
                console.log(d)
                this.departments = d
            })
        },
        removeAsmDept() {
            let data = {
                asmCode: this.selectedTemplate,
                values: this.item2Select.map(x=>x.code),
                mode: 'REMOVE'
            }
            this.apiService.setAsmConfig(data).then(() => {
                this.item2Select = []
                this.item3Select = []
                return this.getDepartment()
            })
        },
        addAsmDept() {
            let data = {
                asmCode: this.selectedTemplate,
                values: this.item3Select.map(x=>x.code),
                mode: 'ADD'
            }
            this.apiService.setAsmConfig(data).then(() => {
                this.item2Select = []
                this.item3Select = []
                return this.getDepartment()
            })
        },

        fetchData() {
            return this.apiService.getTemplates().then(d => {
                this.items1 = d.data
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        publish(item) {
            this.apiService.publishTemplate(item).then(() => this.fetchData())
        },

        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `propertyRequests/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        //template
        deleteTemplate() {
            return this.apiService.deleteTemplate(this.targetItem).then(()=> {
                this.targetItem=undefined
                this.dialogDelete = false
                this.fetchData()
            })
        },
        
        exportTemplate(item){
            try {
                this.loadingDialog = true
                return http.get("surveys/"+item.id+"/export", {
                    params: {
                        month: moment(this.month).format('YYYY-MM-DD')
                    },
                    responseType: 'blob',
                }).then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `EXPORT_KHAO_SAT${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }).finally(()=> {
                    this.loadingDialog=false
                });
            } catch(e){
                alert(e)
            }
        },

        ///QUESTION
        closeQuestion() {
            this.questionDialog = false
            this.answers = []
            this.answer = ""
            this.question.name = ""
            this.question.type = "OPTION"
        },
        getQuestions() {
            try{
                return this.apiService.getQuestions(this.selectedTemplate).then(d => {
                    this.questions = d.data
                })
            }catch(e)
            {
                alert(e)
            }
        },
        saveQuestion(){
            let mappedAnswer = this.answers.map(x=>x.name)
            let postData = {
                name: this.question.name,
                type: this.question.type,
                answers: mappedAnswer
            }
            return this.apiService.postQuestion(this.selectedTemplate, postData).then(() => {
                this.closeQuestion()
                return this.getQuestions()
            })
        },
        deleteQuestion(id) {
            return this.apiService.deleteQuestion(this.selectedTemplate, id).then(() => {
                return this.getQuestions()
            })
        },
        changeQuestionOrder(id, mode) {
            return this.apiService.changeQuestionOrder(this.selectedTemplate, id, mode).then(() => {
                return this.getQuestions()
            })
        },

        /// ANSWER
        addAnswer() {
            this.answers.push({name: this.answer});
            this.answer="";
        },
        deleteAnswer(index) {
            // let index = this.answers.findIndex(obj => obj.name === name);
            if (index !== -1) {
            let removedObject = this.answers.splice(index, 1); // Remove the object from the array
            } else {
                alert('Object not found in the array.');
            }
        }
    },

    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted () {
        this.getAsm()
        this.getDepartment()
        // this.fetchData();
    }
}
</script>