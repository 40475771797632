import http from "@/components/services/http-common";

const SURVEY_EMPLOYEE_URL = "surveyEmployees";
const SURVEY_URL = "surveys";
export default class Api {
    
    getValid = () => http.get(`${SURVEY_EMPLOYEE_URL}/valid`).then(r => r.data)
    
    initAnswers = (id) => http.get(`${SURVEY_EMPLOYEE_URL}/detail/${id}/init-answers`).then(r => { 
        return r.data
    })
    
    getQuestions = (id) => http.get(`${SURVEY_EMPLOYEE_URL}/detail/${id}/questions`).then(r => { 
        return r.data
    })
    
    getTemplate = (id) => http.get(`${SURVEY_URL}/${id}`).then(r => { 
        return r.data
    })

    getTemplateAnswers = (id) => http.get(`${SURVEY_EMPLOYEE_URL}/detail/${id}/answers`).then(r => { 
        return r.data
    })

    postAnswers = (id, data) => http.post(`${SURVEY_EMPLOYEE_URL}/detail/${id}/answers`, data).then(r => r.data)
}