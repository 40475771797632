import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'EmployeeInfoUpdates';
// global api
class EmployeeInfoUpdateApi {
  gets(id) {
    return http.get(`${ROUTE}/hr/${id}`).then(r => r.data.data)
  }
  init() {
    return http.get(`${ROUTE}/init`).then(r => r.data.data)
  }

  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => {
      r.data.data.idDate = moment(r.data.data.idDate).format("YYYY-MM-DD")
      r.data.data.birthday = moment(r.data.data.birthday).format("YYYY-MM-DD")
      return r.data
    })
  }

  post(payload) {
    return http.post(`${ROUTE}`, payload)
  }
  
  put(id, payload) {
    return http.put(`${ROUTE}/${id}`, payload)
  }

  getDropDown() {
    return http.get(`${ROUTE}/get-dropdown`).then(r => r.data)
  }

  getJob() {
    return http.get(`Jobs`).then(r => r.data.map(x => {
        return {
          text: x.name,
          value: x.code
        }
      }
    ))
  }

  getRelationType() {
    return http.get(`${ROUTE}/get-relationTypes`).then(r => r.data)
  }

  getJobDescription(id) {
    return http.get(`Jobs/get-description/${id}`).then(r => r.data)
  }

  deleteItem(id) {
    return http.delete(`${ROUTE}/${id}`).then(r => r.data)
  }

  //fetching 
  getOrigin() {
    return http.get(`origins`).then(r => r.data)
  }
  getEthinicity() {
    return http.get(`ethnicities`).then(r => r.data)
  }
  getReligion() {
    return http.get(`religions`).then(r => r.data)
  }
  getCity() {
    return http.get(`cities/fetch`).then(r => r.data)
  }
  getDistrict(id) {
    return http.get(`cities/fetch-district/${id}`).then(r => r.data)
  }
  getWard(id) {
    return http.get(`districts/fetch-ward/${id}`).then(r => r.data)
  }
  
  getEducationLevel() {
    return http.get(`educationLevels`).then(r => r.data.items)
  }

  getEducationMajor() {
    return http.get(`educationMajors`).then(r => r.data.items)
  }

  getUniversity() {
    return http.get(`universities`).then(r => r.data.items)
  }

  //** Allow */
  getAllow() {
    return http.get(`${ROUTE}/sessions`).then(r => r.data)
  }
  postAllow(payload) {
    return http.post(`${ROUTE}/sessions`, payload)
  }

  deleteAllow(id) {
    return http.delete(`${ROUTE}/sessions/${id}`)
  }

  //set active
  statusAllow(id, status) {
    return http.get(`${ROUTE}/sessions/status/${id}`, {
      params: {
        status: status
      }
    })
  }

  applyAllow(id) {
    return http.get(`${ROUTE}/sessions/${id}/apply`)
  }
  
  undoAllow(id) {
    return http.get(`${ROUTE}/sessions/${id}/undo`)
  }

  exportAllowDetail(id) {
    return http.get(`${ROUTE}/sessions/${id}/export`).then(r => r.data)
  }
}

export default new EmployeeInfoUpdateApi();