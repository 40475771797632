<template>
  <div class="text-center d-block d-sm-none">
    <v-menu :offset-y="true" :nudge-bottom="10" bottom :close-on-click="true" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="grey" class="mx-1" v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list class="px-2">
          <slot></slot>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
    props:['on', 'value'],
    mounted(){
    }
}
</script>
