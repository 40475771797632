<template>
  <v-breadcrumbs class="bread-crumbs" :items="data" large style="margin-top: -18px">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" :exact="item.exact" >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style scoped>
  .v-breadcrumbs:deep(a) {
    color: #004D40;
  }
  ::v-deep a.v-breadcrumbs__item--disabled.v-breadcrumbs__item {
    color: rgba(0, 0, 0, 0.8) !important;
  }
</style>