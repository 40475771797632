<template>
    <div class="create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="80%">
            <v-card>
                <v-card-title class="mb-5">
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} kỹ năng
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                        <v-row>
                            <v-col>
                                <v-text-field v-model="form.name" outlined label="Tên kỹ năng">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-textarea v-model="form.description" outlined label="Mô tả">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark
                                color="blue darken-1"
                                type="submit"
                            >
                                Lưu
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import DatePicker from "@/components/DatePicker";
import http from '@/components/services/http-common';
import * as moment from "moment/moment";

export default {
    components: {
        "date-picker": DatePicker,

    },
    props: ["value"],
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
            }
        },
        
        ShiftObject: {
            handler(val) {
                if(val)
                {
                    this.form = { ...this.form,
                                                ShiftCode: val.code ?  val.code : this.form.ShiftCode,
                                                StartHour: val.startHour ?  this.formatTime(val.startHour) : this.form.StartHour,
                                                EndHour: val.endHour ?  this.formatTime(val.endHour) : this.form.EndHour,
                                                StartBreak: val.startBreak ?  this.formatTime(val.startBreak) : this.form.StartBreak,
                                                EndBreak: val.endBreak ?  this.formatTime(val.endBreak) : this.form.EndBreak,
                                                }
                }
            },
            deep:true
        },
    },
    data() {
        return {

            // URL
            ROOT_URL: "skills",
            FETCH_FORM_URL: "contracts/fetch-form",
            DEPARTMENT_FETCH_SHIFT: "departmenttypes/fetch-shift/",
            
            //RULE
            rules: {
                //ANCHOR TAB 3 - Rules
                ContractTypeRules: [
                    v => !!v || 'Bắt buộc nhập loại hợp đồng'
                ],
                CodeRules: [
                    v => !!v || 'Bắt buộc nhập mã hợp đồng'
                ],
                EmployeeARules: [
                    v => !!v || 'Bắt buộc chọn nhân viên A'
                ],
                EmployeeBRules: [
                    v => !!v || 'Bắt buộc chọn nhân viên B'
                ],
                SignDateRules: [
                    v => !!v || 'Bắt buộc chọn ngày ký',
                ],
                ExpireDateRules: [
                    v => !!v || 'Bắt buộc chọn ngày hết hạn',
                ],
                PositionRules: [
                    v => !!v || 'Bắt buộc chọn vị trí làm việc',
                ],
                PositionStateRules: [
                    v => !!v || 'Bắt buộc chọn tình trạng làm việc',
                ],
                DepartmentTypeRules: [
                    v => !!v || 'Bắt buộc chọn khối làm việc',
                ],
                DepartmentRules: [
                    v => !!v || 'Bắt buộc chọn phòng làm việc',
                ],
                ShiftRules: [
                    v => !!v || 'Bắt buộc chọn ca làm việc',
                ],
                SalaryRules: [
                    v => !!v || 'Bắt buộc nhập tiền lương',
                ]
            },

            // DATA
            valid: false,
            ShiftObject: "",
            formData: {
                contractTypes: [],
                employees: [],
                positions: [],
                positionStates: [],
                departments: [],
                departmentTypes: [],
                shifts: [],
            },
            form: {
                Code: "AUTO",
            },

            defaultForm: {
                Code: "AUTO",
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },

        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                this.form.shiftCode = this.ShiftObject.code;
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                }else {
                    http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {

                    });
                }
            }
        },

        fetchData() {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                this.form = Object.assign({}, item)
            })
            .catch(err => {
                console.log(err);
            });
        },

    },
    created(){
    }
    
}
</script>