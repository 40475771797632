<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" >
          Nhập excel
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Nhập excel 
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col>
                    <v-file-input v-model="form.importFile" label="File import"></v-file-input>
                </v-col>
            </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="upload" >
            Thực thi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const import_URL = 'employees/import';
import http from '@/components/services/http-common';
export default {
    data(){
        return {
            dialog: false,
            form: {
                importFile: undefined,
            }
        }
    },
    methods: {
        upload() {
            var formData = new FormData();
            formData.append("files", this.form.importFile);
            console.log(this.form.importFile);
            return http.post(import_URL, formData).then(res => {
                console.log(res.data);
            })
        }
    }
}
</script>