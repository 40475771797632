import http from '@/components/services/http-common';
// import * as moment from "moment/moment"

export const ROUTE = 'monthlySalaries';

class MonthlySalaryApi {
  gets (month) {
    return http.get(`${ROUTE}`, {
      params: {
        month: month
      }
    }).then(r => r.data)
  }

  updateCell(data){
    return http.put(`${ROUTE}/${data.code}`, data)
  }

  initData(month) {
    return http.post(`${ROUTE}/init`,null, {
      params: {
        month: month
      }
    })
  }
}

export default new MonthlySalaryApi ();