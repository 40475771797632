import http from "@/components/services/http-common";

const GET_ASM_URL = "employees/fetch";
const GET_DEPARTMENT_URL = "departments";
const GET_ASM_CONFIG_URL = "departments/asm-config";

export default class Api {
  getAsm = () => http.get(GET_ASM_URL, {
    params: {
      asm: true
    }
  }).then(d =>d.data)
  
  getDepartment = () => http.get(GET_DEPARTMENT_URL).then(d => d.data)

  setAsmConfig = (data) => http.post(GET_ASM_CONFIG_URL, data)
}