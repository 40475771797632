import Assignment from '@/views/HR/Assignment/Index.vue';
import AssignmentCreate from '@/views/HR/Assignment/Create.vue';


export default [
    {
      path: 'assignments',
      alias: ["hr/assignments"],
      name: 'Assignment',
      component: Assignment,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Danh sách nhân viên',
            disabled: true,
            to: {
                name: "Assignment",
                }
            }
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
    
    {
      path: 'assignments/create',
      alias: ["hr/assignments/create"],
      name: 'AssignmentCreate',
      component: AssignmentCreate,
      props: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Danh sách phân công',
            disabled: false,
            to: {
                name: "Assignment",
                }
            },
            {
            text: 'Thêm mới phân công',
            disabled: true,
            to: {
                name: "AssignmentCreate",
                }
            }
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
    
    {
      path: 'assignments/edit/:id',
      alias: ["hr/assignments/edit/:id"],
      name: 'AssignmentEdit',
      component: AssignmentCreate,
      props: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Danh sách phân công',
            disabled: false,
            to: {
                name: "Assignment",
                }
            },
            {
            text: 'Chỉnh sửa phân công',
            disabled: true,
            to: {
                name: "AssignmentEdit",
                }
            }
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
  ]
