<template>
    <v-dialog v-model="dialog" hide-overlay transition="dialog-bottom-transition" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <!-- <excel-button  v-bind="attrs" v-on="on"></excel-button> -->
            <v-btn color="teal" icon dark v-bind="attrs" v-on="on" class="ml-1 d-flex rounded" large> 
                <v-icon dark >
                    mdi-microsoft-excel
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="white--text secondary"> Xuất excel
                <v-spacer></v-spacer>
                <v-icon color="white" @click="dialog=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row class="my-0">
                    <v-col cols="12">
                        <v-select outlined 
                        v-model="form.columns" :items="formData.columns"
                        label="Cột cần xuất" small-chips multiple hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined
                            :items="formData.departmentTypes" v-model="form.DepartmentTypeCodes"
                            label="Khối" clearable multiple hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined 
                        :items="formData.departments"
                        v-model="form.DepartmentCodes" label="Phòng"
                        multiple clearable hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined
                         label="Thành phố"
                        :items="formData.cities" item-text="name" item-value="code"
                        v-model="form.cityCodes" multiple clearable hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined
                         label="Chức vụ"
                        :items="formData.positions"
                        v-model="form.PositionCodes" multiple clearable hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined
                         label="Chức danh"
                        :items="formData.positionStates" item-value="code" item-text="name"
                        v-model="form.PositionStateCodes" multiple clearable hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined
                         label="Asm"
                        :items="formData.asms" item-value="code" item-text="name"
                        v-model="form.asmCodes" multiple clearable hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined
                         label="Tôn giáo"
                        :items="formData.religions" item-value="code" item-text="name"
                        v-model="form.religionCodes" multiple clearable hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                        <date-picker2 width="600" label="Ngày nghỉ việc" v-model="form.quitDate" :range="true" hideDetails outlined ></date-picker2>
                    </v-col>
                    <v-col cols="12">
                        <date-picker2 width="600" label="Sinh nhật" v-model="form.birthDay" :range="true" hideDetails outlined ></date-picker2>
                    </v-col>
                    <v-col cols="12">
                        <v-select :items="[
                            {text: 'Tất cả', value: 2},
                            {text: 'Làm việc', value: 1},
                            {text: 'Nghỉ việc', value: 0},
                        ]" hide-details v-model="form.listType" outlined></v-select>
                    </v-col>
                </v-row>
                    <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed dark color="teal darken-4" @click="getExport">
                    Tải về file
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as moment from "moment/moment"
import http from '@/components/services/http-common';
import UploadService from "@/components/services/UploadService";
import EventBus  from "@/components/services/EventBus";
import DatePicker from "@/components/DatePicker";

export default {
    name: "Excel",
    props: ['value'],
    components: {
        "date-picker": DatePicker
    },
    computed:{
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
    },
    watch: {
        options: {
                handler (val) {
                    this.fetchData();
                },
                deep: true,
            },
        value:{
            handler(val)
            {
                if(val){
                    this.fetchDepartmentType();
                    this.fetchDepartment();
                    this.fetchPosition();
                    this.fetchPositionState();
                    this.fetchCity();
                    this.fetchAsm();
                    this.fetchReligion();
                }
            }
        }

    },
    data () {
        return {
            progress: 0,
            currentFiles: undefined,
            message: "",
            fileInfos: [],

            //**************** TABLE CONFIG */
            employees: [],
            options: {},
            totalItem: 0,
            isLoading: false,

            formData: {
                departmentTypes: [],
                departments: [],
                positions: [],
                shifts:[],
                cities:[],
                positionStates: [],
                asms:[],
                religions:[],
                columns: [
                    {text: "Mã", value:"code"},
                    {text: "SDT", value:"phone"},
                    {text: "Tên", value:"name"},
                    {text: "Khối", value:"departmentType"},
                    {text: "Phòng ban", value:"department"},
                    {text: "Sinh nhật", value:"birthday"},
                    {text: "Giới tính", value:"gender"},
                    {text: "Hôn nhân", value:"marriage"},
                    {text: "Vị trí", value:"position"},
                    {text: "Cột", value:"positionState"},
                    {text: "Vùng", value:"departmentCity"},
                    {text: "Ca làm việc", value: "shift"},
                    {text: "Trưởng bộ phận", value:"manager"},
                    {text: "ASM", value:"AreaManager"},
                    {text: "Số CMND", value:"iDnumber"},
                    {text: "Ngày cấp", value:"iDdate"},
                    {text: "Nơi cấp", value:"iDplace"},
                    {text: "Ngân hàng", value:"bankName"},
                    {text: "Tài khoản", value:"bankNumber"},
                    {text: "Ngày nhận việc", value:"hireDate"},
                    {text: "Ngày nghỉ việc", value:"quitDate"},
                    {text: "Địa chỉ", value:"address"},
                    {text: "Phường", value:"ward"},
                    {text: "Quận", value:"district"},
                    {text: "Thành phố", value:"city"},
                    {text: "Học vấn", value:"educationLevelName"},
                    {text: "Chuyên ngành", value:"educationMajorName"},
                    {text: "Trường", value:"universityName"},
                    {text: "Vị trí", value:"positionCode"},
                    {text: "Phòng ban", value:"departmentCode"},
                    {text: "Khối", value:"departmentTypeCode"},
                    {text: "Mã Ca", value:"shiftCode"},
                    {text: "Tình trạng LV", value:"workStatus"},
                ]
            },
            form: {
                DepartmentTypeCodes: [],
                DepartmentCodes: [],
                ShiftCodes: [],
                PositionCodes: [],
                cities:[],
                positionStates:[],
                asmCodes:[],
                religionCodes:[],
                columns: [
                    "code",
                    "phone",
                    "name",
                    "departmentType",
                    "department",
                    "birthday",
                    "gender",
                    "marriage",
                    "position",
                    "positionState",
                    "departmentCity",
                    "shift",
                    "manager",
                    "AreaManager",
                    "iDnumber",
                    "iDdate",
                    "iDplace",
                    "bankName",
                    "bankNumber",
                    "hireDate",
                    "quitDate",
                    "address",
                    "ward",
                    "district",
                    "city",
                    "educationLevelName",
                    "educationMajorName",
                    "universityName",
                    "workStatus"
                    // "positionCode",
                    // "departmentCode",
                    // "departmentTypeCode",
                    // "defaultShiftCode",
                ],
                birthDay:[],
                isQuit:false,
                isSuspend:false,
                isPregnant:false,
                listType: 2,
                FromHireDate: new Date().toISOString().substr(0, 10),
                ToHireDate: new Date().toISOString().substr(0, 10),
            },

            //************* data table config ********
            headers: [
                { text: 'Mã nhân viên', value: 'code' },
                {
                    text: 'Tên nhân viên',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Hợp đồng', value: 'contract', sortable: false },
                // { text: 'Phúc lợi', value: 'benefit', sortable: false },
                { text: 'Giới tính', value: 'gender' },
                { text: 'Ngày sinh', value: 'birthday' },
                { text: 'Chức vụ', value: 'position' },
                { text: 'Phòng ban', value: 'department' },
                { text: 'Khối', value: 'group' },
            ],
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        fetchData () {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            if(page != undefined || itemsPerPage != undefined)
                return http.get('employees/preview-export?page='+ page + '&pageSize=' + itemsPerPage, {
                    params: this.form
                }).then(res => {
                    this.isLoading = false;
                    this.employees = res.data.items;
                    this.totalItem = res.data.totalItem;
                }).catch(err => {
                    console.log(err);
                });
        },

        fetchDepartmentType() {
            http.get("departmenttypes?dropDown=1").then(res => {
                this.formData.departmentTypes = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code,
                    }
                });
            })
            .catch(err => {
                console.log("err");
            });
        },
        fetchDepartment() {
            this.form.DepartmentCode = '';
            this.formData.departments = [];
            if(this.form.DepartmentTypeCode != '')
                http.get("departments?dropDown=1").then(res => {
                    this.formData.departments = res.data.map(function(obj) {
                    return {
                        text: obj.name,
                        value: obj.code,
                        }
                    });
                }).catch(err => {
                    console.log(err);
                });
        },
        
        fetchPosition() {
            return http.get("positions?dropDown=1").then(res => {
                this.formData.positions = res.data.map(function(obj) {
                return {
                    text: obj.name,
                    value: obj.code
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        },
        fetchPositionState() {
            return http.get("positionStates").then(res => {
                this.formData.positionStates = res.data;
            }).catch(err => {
                console.log(err);
            });
        },

        fetchShift() {
            if(this.form.DepartmentTypeCode != '')
                http.get("departmenttypes/fetch-shift/" + this.form.DepartmentTypeCode).then(res => {
                    this.formData.shifts = res.data.map(function(obj) {
                    return {
                        text: obj.name,
                        value: obj.code,
                        }
                    });
                    console.log(res)
                }).catch(err => {
                    console.log(err);
                });
        },
        fetchReligion() {
            return http.get("religions").then(res => {
                this.formData.religions = res.data;
            });
        },
        
        fetchAsm() {
            return http.get("employees/fetch").then(res => {
                this.formData.asms = res.data;
                console.log(res.data)
            })
        },
        fetchCity(){
            return http.get("cities/fetch?search=&asm=true").then(res => {
                this.formData.cities = res.data;
            })
        },
        selectFile(files) {
            this.progress = 0;
            this.currentFiles = files;
        },
        upload() {
            if (!this.currentFiles) {
                this.message = "Chọn file excel!";
                return;
            }

            this.message = "";
            UploadService.upload(this.currentFiles, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            }, "employees/import-data")
            .then((response) => {
                let eventObj = {
                    color: "success",
                    messages: "Upload file thành công!!"
                }
                EventBus.$emit('hasMessage', eventObj);
                this.$emit("needFetch");
                // this.message = response.data.message;
            // return {"data": [{"url": "url xxxx", "name": "xxx"}]};
            })
            .catch((error) => {
                if(error.response.status){
                    let eventObj = {
                        color: "error",
                        messages: "Đã có lỗi xảy ra, vui lòng kiểm tra dữ liệu file!"
                    }
                    EventBus.$emit('hasMessage', eventObj);
                }
                switch (error.response.status) {
                    case 401: {
                        console.log("co loi xay ra");
                    break;
                    }
                    case 500: {
                        console.log("co loi xay ra");
                    break;
                    }
                }
            
            this.currentFiles = undefined;
            this.progress = 0;
            this.message = "Could not upload the file!";
            this.currentFiles = undefined;
            });
        },
        getExport() {
            return http.get("employees/export", {
                params: this.form,
                responseType: 'arraybuffer',
            }).then((res)  => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'DU_LIEU_NHAN_VIEN.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((err)  => {
                console.log(err);
            });
        },
    },
    created () {
    }
}
</script>