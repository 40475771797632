<template>
  <div>
    <my-breadcrumb :data="breadcrumb"></my-breadcrumb>
    <v-card>
      <v-card-title>
        <v-text-field style="max-width: 200px;" class="mx-2" 
        outlined hide-details v-model="month" @change="getData" type="date" dense placeholder="Chọn tháng" persistent-placeholder></v-text-field>

        <v-spacer></v-spacer>
        <v-btn icon color="teal" @click="getData" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
        <export-excel :items="items" :headers="headers"> </export-excel>
        <v-btn small color="teal darken-4" class="ml-2" dark
        @click="openDialog">Tạo mới</v-btn>
      </v-card-title>

      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" xl="2" md="3">
            <v-text-field dense v-model="search" outlined placeholder="Tìm kiếm" hide-details persistent-placeholder></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :items="items" :headers="headers" :search="search" :loading="isLoading" dense height="600px">
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="error" small @click="targetItem = item, dialogDelete=true">xóa</v-btn>
            <v-btn color="teal darken-4" small @click="targetItem = item, dialogCreate=true" dark>sửa</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <create-dialog @fetchData="getData" :item.sync="targetItem" v-model="dialogCreate"></create-dialog>
    <confirm-dialog v-model="dialogDelete" :item="targetItem" @confirm="deleteItem" ></confirm-dialog>
  </div>
</template>

<script>

import BonusTypeApi from './api.js'
import moment from 'moment';
import ExportExcel from '@/components/ExportExcel'
import MyBreadcrumb from '@/components/MyBreadcrumb'
import CreateDialog from './Create.vue'
import ConfirmDialog from '@/components/ConfirmDialog'


export default {
  data() {
    return {
      breadcrumb: [
        {text: "Danh mục thưởng", to: {name: 'BonusType'}}
      ],

      items: [],
      targetItem: null,
      search: '',
      isLoading: false,
      headers: [
        { text: '', value: 'actions', cellClass: 'basic-yellow', class: 'yellow-header fixed-header', width: 50},
        { text: 'Code', value: 'code', cellClass: 'basic-yellow', class: 'yellow-header fixed-header', width: 100},
        { text: 'Tên loại thưởng', value: 'name', cellClass: 'basic-yellow', class: 'yellow-header fixed-header', width: 150 },
        { text: 'Mô tả', value: 'description', cellClass: 'basic-yellow', class: 'yellow-header fixed-header' },
      ],
      dialog: false,
      dialogDelete:false,
      dialogCreate: false,

      month: moment().startOf('month').format('YYYY-MM-DD')
    }
  },
  methods: {
    formatDate(value) {
        return moment(value).format("DD/MM/YYYY");
    },
    addThousandSeparator(number) {
      // Convert the number to a string
      let numberString = number.toString();

      // Split the string into integer and decimal parts
      let parts = numberString.split('.');

      // Add thousand separators to the integer part
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Join the integer and decimal parts back together
      let result = parts.join('.');

      return result;
    },

    deleteItem(item) {
      return BonusTypeApi.deleteItem(item.code).then(() => {
        this.items = this.items.filter(x=>x.code != item.code)
        this.dialogDelete = false
        this.targetItem = null
      })
    },

    openDialog(event, id) {
        if(id){
            alert(event, id)
        }else {
          this.dialogCreate = true
        }
    },
    getData() {
      return BonusTypeApi.gets(this.month).then(d=> {
        this.items = d.data
      })
    },
    confirmDeleteHandler() {

    }
  },
  components: {
    ExportExcel,
    MyBreadcrumb,
    CreateDialog,
    ConfirmDialog

  },
  created()  {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
// header color
::v-deep .fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
::v-deep .white-header{
  color: black !important;
  background: white;
}
::v-deep .yellow-header{
  color: black !important;
  background: #FFF59D;
}
::v-deep .blue-header{
  color: black !important;
  background: #90CAF9;
}
::v-deep .coral-header{
  color: black !important;
  background: #FFAB91;
}
::v-deep .green-header{
  color: black !important;
  background: #A5D6A7;
}

// collumn colors
// ::v-deep tr:has(.salary-blue):hover{
//   background: rgba(0,0,0, .2);
// }

::v-deep .basic-yellow {
  background: rgba(255, 253, 231, .5);
}
::v-deep .salary-blue {
  background: rgba(227, 242, 253, .5);
}
::v-deep .timesheet-coral {
  background: rgba(255, 235, 238, .5);
}
::v-deep .actual-green {
  background: rgba(232, 245, 233, .5);
}


::v-deep table > tbody > tr > td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 0;
    background: rgba(255, 253, 231, 1);
  }
::v-deep table > thead > tr > th:nth-child(1) {
    z-index: 1;
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
  }
  
::v-deep table > tbody > tr > td:nth-child(2)
 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 50px;
    z-index: 0;
    background: rgba(255, 253, 231, 1);
  }
::v-deep table > thead > tr > th:nth-child(2) {
    z-index: 0;
    // position: sticky !important;
    // position: -webkit-sticky !important;
    left: 50px;
    // background: red;
  }
</style>