
import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch();
                }
                else{
                    this.close();
                }
            }
        },

    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "quitRequests/",

            isValid: true,
            fetchData: false,
            form: {
                quitDate: null,
                quitReason:"",
                note:""
            },
            defaultForm:{
                quitDate: null,
                quitReason:"",
                note:""
            },
            quitReasons: [
                "Khác",
            ]
        }
    },
    methods: {
        fetch(employee = false){
            this.fetchData =true;
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                this.form = Object.assign({}, res.data);
                this.fetchData =false;
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    return http.post(this.ROOT_URL, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fetchData=false;
        }
    },
    created() {
    }
}