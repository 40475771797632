<template>
    <div class="create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="600px">
            <v-card>
                <v-card-title class="mb-5">
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} phiếu nghỉ
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-autocomplete :items="formData.longLeaves" v-model="form.longLeaveCode" label="Loại nghỉ" item-text="name" item-value="code">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-text-field :value="totalDate" readonly label="Tông ngày"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="form.dates"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.dates"
                                        label="Thời gian nghỉ"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker range
                                    v-model="form.dates"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(form.dates)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-textarea v-model="form.note" label="Ghi chú" outlined></v-textarea>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark
                                color="blue darken-1"
                                type="submit"
                            >
                                Lưu
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import DatePicker from "../../../components/DatePicker";
import http from '../../../components/services/http-common';
import EventBus  from "../../../components/services/EventBus";
import * as moment from "moment/moment";

export default {
    components: {
        "date-picker": DatePicker,

    },
    props: ["value"],
    computed: {
        totalDate () {
            let total =0;
            if(this.form.dates.length > 0)
            {
                var start = moment(this.form.dates[0], "YYYY-MM-DD");
                var end = moment(this.form.dates[1], "YYYY-MM-DD");

                //Difference in number of days
                total = Math.abs(moment.duration(start.diff(end)).asDays()) + 1;
            }
            return total ;
        }
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
            }
        },
    },
    data() {
        return {

            // URL
            ROOT_URL: "longleaverequests",
            
            //RULE
            rules: {
                //ANCHOR TAB 3 - Rules
            },

            // DATA
            valid: false,
            ShiftObject: "",
            formData: {
                longLeaves: [
                ],
            },
            form: {
                longLeaveCode: "",
                dates:[],
                from: "",
                to: "",
            },

            defaultForm: {
                longLeaveCode: "",
                dates:[],
                from: "",
                to: "",
            },
            menu: false,
            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        fetchForm () {
            return http.get(this.ROOT_URL+"/fetch").then(res => {
                this.formData.longLeaves = res.data.longLeaves;
                console.log(this.formData.longLeaves)
            })
        },
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },

        saveData() {
            this.form.from = this.form.dates[0];
            this.form.to = this.form.dates[1];
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                this.form.shiftCode = this.ShiftObject.code;
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                }else {
                    http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {

                    });
                }
            }
        },

        fetchData() {
            return http.get(this.CONTRACT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.data.item;
                let additional = res.data.data.additional;
                this.ContractUpdate = true;
                this.form = {...this.form, 
                    Code: item.code,
                    EmployeeACode: item.employeeACode,
                    EmployeeBCode: item.employeeBCode,
                    EmployeeAName: additional.employeeAName,
                    EmployeeBName: additional.employeeBName,
                    SignDate: item.signDate.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10),
                    ExpireDate: item.expireDate.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10),
                    ContractTypeCode: item.contractTypeCode,
                    ContractNumber: item.contractNumber,
                    PositionCode: item.positionCode,
                    PositionStateCode: item.positionStateCode,
                    DepartmentTypeCode: item.departmentTypeCode,
                    DepartmentCode: item.departmentCode,
                    ShiftCode: item.shiftCode,
                    StartHour: item.startHour,
                    EndHour: item.endHour,
                    StartBreak: item.startBreak,
                    EndBreak: item.endBreak,
                    Salary: item.salary,
                    Allowance: item.allowance,
                    OtherAllowance: item.otherAllowance,
                    Note: item.note,
                    IsCurrent: item.isCurrent,
                }
                this.ShiftObject = {code: item.shiftCode}
            })
            .catch(err => {
                console.log(err);
            });
        },

    },
    created(){
        this.fetchForm();
    }
    
}
</script>