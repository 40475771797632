<template>
  <div>
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
    
    <v-row>
        <v-col cols="12" md="9">
          <v-card :loading="isLoading">
              <v-card-title>Điều chỉnh thông tin nhân viên</v-card-title>

              <v-card-text class="text--primary">
                    <v-form ref="employee_info_update_form" id="employee_info_update_form" v-model="isValid" @submit.prevent="save">
                      
                      <span class="green--text text--darken-4">THÔNG TIN CÁ NHÂN</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Giới tính <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select :items="genders" outlined hide-details="auto" v-model="form.gender" :rules="[v=> v!=null || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Ngày sinh <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.birthday" type="date"  :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Số CCCD <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.idNumber" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Nơi cấp CCCD <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete outlined hide-details="auto" :items="idPlaces" v-model="form.idPlace" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-autocomplete>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                         Ngày cấp CCCD <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" type="date" v-model="form.idDate" :false-value="0" :true-value="1"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Quốc tịch <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" v-model="form.originCode" item-value="code" item-text="name" :items="origins" 
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Tôn giáo <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" v-model="form.religionCode" item-value="code" item-text="name" :items="religions"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Dân tộc <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" v-model="form.ethnicityCode" item-value="code" item-text="name" :items="ethnicities"
                           :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                      </v-row>
                      
                      <span class="green--text text--darken-4">ĐỊA CHỈ THƯỜNG TRÚ</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Thành phố <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete outlined hide-details="auto" v-model="form.addressCity1Code" 
                          :items="cities1" item-text="name" item-value="code"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']" @change="getDistrict1"></v-autocomplete>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Quận/huyện <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete outlined hide-details="auto" 
                          v-model="form.addressDistrict1Code" @change="getWard1"
                          :items="districts1" item-text="name" item-value="code" 
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-autocomplete>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Phường xã <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete outlined hide-details="auto" v-model="form.addressWard1Code"
                          :items="wards1" item-text="name" item-value="code" 
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Địa chỉ (tên đường, số nhà) <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" v-model="form.address1" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                      </v-row>
                      
                      <span class="green--text text--darken-4">ĐỊA CHỈ TẠM TRÚ</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Thành phố (Thường trú) <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete outlined hide-details="auto" v-model="form.addressCity2Code" @change="getDistrict2"
                          :items="cities2" item-text="name" item-value="code"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-autocomplete>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Quận/huyện <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete outlined hide-details="auto" 
                          v-model="form.addressDistrict2Code"
                          :items="districts2" item-text="name" item-value="code"
                          @change="getWard2"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-autocomplete>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Phường xã <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete outlined hide-details="auto" 
                          v-model="form.addressWard2Code"
                          :items="wards2" item-text="name" item-value="code"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Địa chỉ (tên đường, số nhà) <span style="color: red;">*</span>
                          <p>
                            <small class="red--text">Tên đường, Hẻm(nếu có), số nhà</small>
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field outlined hide-details="auto" 
                          placeholder="Tên đường, Hẻm(nếu có), số nhà"
                          v-model="form.address2" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-text-field>
                        </v-col>
                      </v-row>
                      
                      <span class="green--text text--darken-4">HỌC VẤN - VĂN BẰNG 1</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Trình độ học vấn (chính) <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" v-model="form.educationLevel1Code" :items="educationLevels" item-value="code" item-text="name" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Chuyên ngành <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" v-model="form.educationMajor1Code" :items="educationMajors" item-value="code" item-text="name"
                          :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Cơ sở đào tạo <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                        <v-select outlined hide-details="auto" v-model="form.university1Code" :items="universities" item-value="code" item-text="name" 
                        :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Trạng thái tốt nghiệp <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" v-model="form.graduateStatus1"
                          :items="graduateStatuses" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                      </v-row>
                      
                      <span class="green--text text--darken-4">HỌC VẤN - VĂN BẰNG 2</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Trình độ học vấn
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select clearable outlined hide-details="auto" v-model="form.educationLevel2Code" :items="educationLevels" item-value="code" item-text="name"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Chuyên ngành
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select clearable outlined hide-details="auto" v-model="form.educationMajor2Code" :items="educationMajors" item-value="code" item-text="name"></v-select>
                        </v-col>
                        
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Cơ sở đào tạo
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select clearable outlined hide-details="auto" v-model="form.university2Code" :items="universities" item-value="code" item-text="name"></v-select>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Trạng thái tốt nghiệp
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select clearable outlined hide-details="auto" v-model="form.graduateStatus2"
                          :items="graduateStatuses"></v-select>
                        </v-col>
                      </v-row>
                      
                      <span class="green--text text--darken-4">THÔNG TIN KHÁC</span>
                      <v-divider style="width:100%"></v-divider>
                      <v-row class="ma-0">
                        <v-col cols="12" md="6" class="d-flex align-center">
                          Chứng chỉ hành nghề <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" :items="pharmaCerts" v-model="form.pharmaCert" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>

                        <v-col cols="12" md="6" class="d-flex align-center">
                          Tình trạng hôn nhân <span style="color: red;">*</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select outlined hide-details="auto" :items="maritalStatuses" v-model="form.maritalStatus" :rules="[v=> !!v || 'Bạn hãy nhập dữ liệu này']"></v-select>
                        </v-col>
                      </v-row>
                    </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
              </v-card-actions>
              
              <v-fab-transition >
                <v-btn style="bottom: 140px;"
                  color="success"
                  fab
                  dark
                  large
                  fixed
                  bottom
                  right offset
                  type="submit" form="employee_info_update_form"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-fab-transition>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn block type="submit" color="success" form="employee_info_update_form" style="width: 200px;" class="my-2">Lưu</v-btn>
          <v-btn block outlined type="button" color="error" style="width: 200px;" class="my-2" @click="$router.push({name: 'EmployeeFamily', query: {
            returnPage: $route.query.returnPage
          }})">Quay lại danh sách </v-btn>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import EmployeeInfoUpdateApi from './api.js'

import router from "@/router/index";
export default {
  computed: {
    isEdit() {

      return !!this.$route.params.id
    },

    currentId() {
      if(this.$route.params.id){
        return this.$route.params.id
      } else 
        return null
    },

    breadcrumbs() {
      return [
        {
            text: 'Phiếu yêu cầu tuyển dụng',
            to: {name: 'EmployeeInfoUpdate'},
            exact: true
        },
        {
          text: this.isEdit ? 'Chỉnh sửa '+this.$route.params.id : 'Tạo mới',
          to: {name: this.isEdit ? 'EmployeeInfoUpdateEdit' : 'EmployeeInfoUpdateCreate'}
        },
      ]
    },
    
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội"];
        let cityData = this.cities1.map(x=>{return x.name});
        return fixedData.concat(cityData);
    },
  },

  data() {
    return {
      isLoading: false,
      genders: [
        {text: 'Nam', value: true},
        {text: 'Nữ', value: false}
      ],
      pharmaCerts: [
        {text:'Đại học', value: 'DH'},
        {text:'Cao đẳng', value: 'CD'},
        {text:'Không có', value: 'KHONG'},
      ],
      maritalStatuses: [
        {text:'Kết hôn', value: 'KH'},
        {text:'Độc thân', value: 'DT'},
      ],
      origins: [],
      religions:[],
      ethnicities:[],
      cities1:[],
      cities2:[],
      districts1: [],
      districts2: [],
      wards1: [],
      wards2: [],
      educationLevels: [],
      educationsMajors: [],
      universities: [],

      recruitTypes: [
        {text: 'Trong & ngoài kế hoạch', value: 'BOTH'},
        {text: 'Trong kế hoạch', value: 'INPLAN'},
        {text: 'Ngoài kế hoạch', value: 'OFFPLAN'},
        {text: 'Không có', value: 'NONE'},
      ],
      graduateStatuses: [
        {text: 'Đã tốt nghiệp', value: 1},
        {text: 'Chưa tốt nghiệp', value: 0},
      ],
      relationTypes: [],
      workExps: [],
      appearances: [],

      educationMajorSearch: '',
      educationMajors: [],

      isValid: true,
      jobDescription: null,
      jobDescriptionShow: true,
      jobLoading:  false,
      form: {
        employeeCode: '',
        gender: null,
        birthday: new Date(),
        idNumber: '',
        idPlace: '',
        idDate: new Date(),
        originCode: '',
        religionCode: '',
        ethnicityCode: '',
        addressCity1Code: '',
        addressDistrict1Code: '',
        addressWard1Code: '',
        address1: '',
        addressCity2Code: '',
        addressDistrict2Code: '',
        addressWard2Code: '',
        address2: '',
        educationLevel1Code: '',
        educationMajor1Code: '',
        university1Code: '',
        graduateStatus1: 0,
        educationLevel2Code: '',
        educationMajor2Code: '',
        university2Code: '',
        graduateStatus2: null,
        pharmaCert: '',
        maritalStatus: '',
        description: '',
        isRollback: false,
        idRollback: null,
        status: 0,
      }
    }
  },
  methods: {
    save() {
      let thisRouter = router
      //this.$refs.employee_info_update_form.validate()
      this.isValid = true
      if(this.isValid) {
        if(!this.isEdit){
          return EmployeeInfoUpdateApi.post(this.form).then(() => {
            thisRouter.push({ name: 'EmployeeInfoUpdate' })
          }).catch(e => {
            console.log("error"+ e)
          })
        } else {
          return EmployeeInfoUpdateApi.put(this.currentId, this.form).then(() => {
            thisRouter.push({ name: 'EmployeeFamily' })
          }).catch(e => {
            console.log("error")
          })
        }
      }
    },

    getData() {
      this.isLoading = true

      return EmployeeInfoUpdateApi.get(this.currentId).then(d => {
        console.log(d.data)
        //prefectch
        this.getDistrict1(d.data.addressCity1Code)
        this.getDistrict2(d.data.addressCity2Code)
        this.getWard1(d.data.addressDistrict1Code)
        this.getWard2(d.data.addressDistrict2Code)
        this.form = Object.assign({}, d.data)
        Object.assign(this.form, d.requirement)
      }).finally(() => {
        this.isLoading = false
      })
    },

    init(){
      return EmployeeInfoUpdateApi.init().then(d => {
        this.form = Object.assign({}, d.data)
      })
    },

    getJobDescription(val) {
      this.jobLoading = true
      EmployeeInfoUpdateApi.getJobDescription(val).then(x=> {
        this.jobDescription = x.description
      }).finally(()=> {
        this.jobLoading = false
      })
    },

    getFetchData() {
      EmployeeInfoUpdateApi.getOrigin().then(x => {
        this.origins = x
      })
      EmployeeInfoUpdateApi.getReligion().then(x => {
        this.religions = x
      })
      EmployeeInfoUpdateApi.getEthinicity().then(x => {
        this.ethnicities = x
      })
      EmployeeInfoUpdateApi.getEducationLevel().then(x => {
        this.educationLevels = x
      })
      EmployeeInfoUpdateApi.getEducationMajor().then(x => {
        this.educationMajors = x
      })
      EmployeeInfoUpdateApi.getUniversity().then(x => {
        this.universities = x
      })
      EmployeeInfoUpdateApi.getCity().then(x => {
        this.cities1 = x
        this.cities2 = x
      })
    },

    getDistrict1(value) {
      this.districts1 =[]
      this.form.addressDistrict1Code = null
      return EmployeeInfoUpdateApi.getDistrict(value).then(data=> {
        this.districts1 = data
      })
    },
    
    getDistrict2(value) {
      this.districts2 =[]
      this.form.addressDistrict2Code = null
      return EmployeeInfoUpdateApi.getDistrict(value).then(data=> {
        this.districts2 = data
      })
    },
    
    getWard1(value) {
      this.wards1 =[]
      this.form.addressWard1Code = null
      return EmployeeInfoUpdateApi.getWard(value).then(data=> {
        this.wards1 = data
      })
    },

    getWard2(value) {
      this.wards2 =[]
      this.form.addressWard2Code = null
      return EmployeeInfoUpdateApi.getWard(value).then(data=> {
        this.wards2 = data
      })
    }

  },
  created() {
    this.getFetchData()
  },

  mounted() {
    if(this.isEdit) {
      this.getData()
    }
  }
}
</script>