<template>
  
  <div class="recruitment-request">
    <!-- <v-breadcrumbs class="bread-crumbs"
      :items="breadcrumbs" large >
    
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :exact="item.exact"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
      <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb> -->
      

      <!-- <create-dialog-health-insurance ref="createHealthInsurance" @needFetch="fetchData"></create-dialog-health-insurance> -->
      <v-card>
          <v-card-title>
              <!-- Danh sách bảo hiểm -->
              <v-spacer></v-spacer>
              <v-btn class="" color="teal darken-4" dark :to="{ name: 'EmployeeInfoUpdateCreate', query: {
                returnPage: page
              } }"> Tạo mới</v-btn>
              <!-- <create-button @click="dialog={state: true, item:null}"></create-button> -->
          </v-card-title>
          <v-data-table height="600px"
          :headers="headers"
          :page="page"
          :items="data"
          :search="search"
          :loading="isLoading"
          >
              <!-- BHYT -->
              <template v-slot:[`item.actions`]="{ item }">
                  <v-btn x-small color="white" @click="editItem(item)"><v-icon small>mdi-pencil</v-icon> Chi tiết</v-btn>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                  <v-btn small color="error" style="width: 50px; height: 50px; " class="ma-2" @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <template v-slot:[`item.relationType`]="{ item }">
                <v-chip color="pink lighten-4">
                  <b>{{relationTypes[item.relationType]}}</b>
                </v-chip>
              </template>
              <template v-slot:[`item.isDependant`]="{ item }">
                  <v-icon color="green" v-if="item.isDependant == 1">mdi-check</v-icon>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                  {{ formatDate(item.createdAt, true) }}
              </template>
              <template v-slot:[`item.remove`]="{ item }">
                <v-btn text x-small color="error" @click="dialogDelete=true, targetItem = item">Thu hồi</v-btn>
              </template>
              <template v-slot:[`item.isLatest`]="{ item }">
                <v-icon color="green" v-if="item.isLatest">mdi-check</v-icon>
              </template>
          </v-data-table>
          
      </v-card>

      <!-- ************** DELETE CONFIRMATION *************** -->
      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
              <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import EmployeeFamilyIndexApi from './api.js'
import MyBreadcrumb from '../../../components/MyBreadcrumb.vue';

export default {
  components: { MyBreadcrumb },
  data() {
    return {
      //DATATABLE
      data: [],
      relationTypes: [],
      page: 0,
      totalItem: 0,
      search:"",
      options: {},
      isLoading: true,
      targetItem: undefined,
      headers: [
          { text: 'Thao tác', value: 'actions', sortable: false, width: "12rem"},
          { text: 'Mã nhân sự', align: 'start', sortable: false, value: 'employeeCode', },
          { text: 'Tạo bởi', align: 'start', value: 'createdBy', width:'15rem' },
          { text: 'Tạo vào', align: 'start', value: 'createdAt', },
          { text: 'Trạng thái', value: 'status' },
          { text: 'Mới nhất', value: 'isLatest' },
          { text: '', value: 'remove' },
      ],

      dialogDelete: false,
      // cfmDeleteItem: null,
      
      breadcrumbs: [
        {
          text: 'Thông tin về gia đình',
          disabled: false,
          to: {name: 'EmployeeInfoUpdate'}
        },
      ]
    }
  },
  methods: {
    
    convertToExcelLabel(value) {
      if(Array.isArray(value)) {
          var exceptCols = []
          return value.filter(x=>!exceptCols.includes(x.value)).map(x => {
              return {
                  label: x.text,
                  field: x.value
              }
          })
      }
    },
    formatDate(value, time = false) {
      if(time==true) {
        return moment(value).format("DD/MM/YYYY hh:mm:ss");
      }
      return moment(value).format("DD/MM/YYYY");
    },
    
    editItem (item) {
      this.$router.push({
        name: 'EmployeeInfoUpdateEdit',
        params: { id: item.id },
        query: { returnPage: this.$route?.returnPage??0
        }
      })
    },

    //fetching
    gets() {
      this.isLoading = true
      return EmployeeFamilyIndexApi.gets().then(d => {
        this.data = d
      }).finally(()=> {
        this.isLoading = false
      })
    },
    
    deleteItem (item) {
          this.dialogDelete = true;
          this.targetItem = item;
      },
    cfmDeleteItem() {
      return EmployeeFamilyIndexApi.deleteItem(this.targetItem.id).then(() => {
        this.targetItem = null
        this.dialogDelete = false
      }).finally(() => {
        return this.gets()
      })
    },
  },

  created() {
    this.gets()
    
    EmployeeFamilyIndexApi.getRelationType().then(r => {
      this.relationTypes = r.dict
    })
  }
}
</script>