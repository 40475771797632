<template>
  
  <div class="recruitment-request">
    <!-- <v-breadcrumbs class="bread-crumbs"
      :items="breadcrumbs" large >
    
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :exact="item.exact"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
      <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb> -->
      

      <!-- <create-dialog-health-insurance ref="createHealthInsurance" @needFetch="fetchData"></create-dialog-health-insurance> -->
      <v-card>
          <v-card-title>
              <!-- Danh sách bảo hiểm -->
              <v-spacer></v-spacer>
              <v-btn class="" color="teal darken-4" dark :to="{ name: 'RecruitmentRequestCreate', query: {
                returnPage: page
              } }"> Tạo mới</v-btn>
              <!-- <create-button @click="dialog={state: true, item:null}"></create-button> -->
          </v-card-title>
          <v-data-table height="600px"
          :headers="headers"
          :page="page"
          :items="data"
          :search="search"
          :loading="isLoading"
          >
              <!-- BHYT -->
              <template v-slot:[`item.actions`]="{ item }">
                  <v-btn x-small color="white" @click="editItem(item)"><v-icon small>mdi-pencil</v-icon> Chi tiết</v-btn>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                  <v-btn small color="error" style="width: 50px; height: 50px; " class="ma-2" @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <template v-slot:[`item.validFrom`]="{ item }">
                  {{ formatDate(item.validFrom) }}
              </template>
              <template v-slot:[`item.validTo`]="{ item }">
                  {{ formatDate(item.validTo) }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                  {{ formatDate(item.createdAt) }}
              </template>
          </v-data-table>
          
      </v-card>

      <!-- ************** DELETE CONFIRMATION *************** -->
      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
              <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import RecruitmentRequestIndexApi from './api.js'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import MyBreadcrumb from '../../../components/MyBreadcrumb.vue';

export default {
  components: { MyBreadcrumb },
  data() {
    return {
      //DATATABLE
      data: [],
      page: 0,
      totalItem: 0,
      search:"",
      options: {},
      isLoading: true,
      targetItem: undefined,
      headers: [
          { text: 'Thao tác', value: 'actions', sortable: false, width: "12rem"},
          { text: 'Số phiếu', align: 'start', sortable: false, value: 'id', },
          { text: 'Người yêu cầu', align: 'start', value: 'employeeName', width:'15rem' },
          { text: 'Chức danh', align: 'start', value: 'positionStateName', },
          { text: 'Phòng ban', value: 'departmentName' },
          { text: 'Công việc', value: 'jobName' },
          { text: 'Số lượng yêu cầu', value: 'amount' },
          { text: 'Ngày tạo', value: 'createdAt' },
          { text: 'Trạng thái', value: 'status' },
          { text: 'Xóa', value: 'delete' },
      ],

      dialogDelete: false,
      // cfmDeleteItem: null,
      
      breadcrumbs: [
        {
          text: 'Danh sách sơ đồ hệ thống',
          disabled: false,
          to: {name: 'RecruitmentRequest'}
        },
      ]
    }
  },
  methods: {
    
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    
    editItem (item) {
      console.log(this.$route)
      this.$router.push({
        name: 'RecruitmentRequestEdit',
        params: { id: item.id },
        query: { returnPage: this.$route?.returnPage??0
        }
      })
    },

    //fetching
    gets() {
      this.isLoading = true
      return RecruitmentRequestIndexApi.gets().then(d => {
        console.log(d)
        this.data = d
      }).finally(()=> {
        this.isLoading = false
      })
    },
    
    deleteItem (item) {
          this.dialogDelete = true;
          this.targetItem = item;
      },
    cfmDeleteItem() {
      return RecruitmentRequestIndexApi.deleteItem(this.targetItem.id).then(() => {
        this.targetItem = null
        this.dialogDelete = false
      }).finally(() => {
        return this.gets()
      })
    },
  },

  created() {
    this.gets()
  }
}
</script>