<template>
  <div class="content-template">
    <v-card>
      <v-card-title>Template bài viết
        <v-spacer></v-spacer>
        <v-btn small @click="getItems" class="mx-2">refresh</v-btn>
        <v-btn dark color="teal darken-4" small @click="dialog=true">Thêm mới</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" :search="search">
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small color="" @click="openDetail(item.id)">Chi tiết </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="800" persistent >
      <create-form @close="closeDialog" :id="selected" @set-id="setId"></create-form>
    </v-dialog>
  </div>
</template>

<script>
import ContentTemplateApi from './api'
import CreateForm from './Create'

export default {
  components: {
    CreateForm
  },
  data() {
    return {
      items: [],
      selected: -1,
      headers: [
        { text: 'Thao tác', value: 'actions',width: 120},
        { text: 'ID', value: 'id'},
        { text: 'Tên', value: 'name',width: 120},
        { text: 'Phân loại', value: 'type',width: 120},
        { text: 'Ảnh', value: 'avatar',width: 120},
        { text: 'Ghi chú', value: 'note',width: 120},
        { text: 'Tiến trình', value: 'step',width: 120},
      ],
      search: "",

      dialog: false,
    }
  },
  methods: {
    getItems() {
      return ContentTemplateApi.gets().then(d => {
        this.items = d
      })
    },
    resetForm() {
    },
    closeDialog() {
      this.dialog = false
      this.selected = -1
    },
    openDetail(id) {
      this.selected = id
      this.dialog=true
    },
    setId(e){
      this.selected = e 
      console.log(e)
    }
  },

  mounted() {
    this.getItems()
  }
}
</script>
