<template>
    <div class="leave-index" >
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog> -->

        <v-card v-if="true">
            <v-card-title>
                <v-spacer></v-spacer>
                <!-- <v-btn small color="teal darken-4" dark @click="dialog.state=true">
                    <v-icon small left>mdi-upload</v-icon> Upload excel
                </v-btn> -->
                <!-- <create-button @click="dialog.state=true" ></create-button> -->
                <v-divider vertical class="mx-2"></v-divider>
                <!-- <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker> -->
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>
            <v-data-table height="600px"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
            
                <template v-slot:[`top`]>
                    <v-row class="mx-1 mb-4">
                        <v-col cols="6" md="4">
                            <v-text-field v-model="search" outlined dense label="Tìm kiếu theo tháng" placeholder="dd/mm/yyyy" hide-details></v-text-field>
                        </v-col>
                        <!-- <v-col cols="6" md="4">
                            <v-text-field v-model="filterEmployeeName" outlined dense label="Tìm kiếu tên NS" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" md="4">
                            <v-text-field v-model="filterDepartmentName" outlined dense label="Tìm kiếu tên phòng/chi nhánh" hide-details></v-text-field>
                        </v-col> -->
                    </v-row>
                </template>

                <template v-slot:[`item.month`]="{ item }">
                    {{formatDate(item.month)}}
                </template>

                <template v-slot:[`item.lCoBan`]="{ item }">
                    {{formatNumber(item.lCoBan)}}
                </template>

                <template v-slot:[`item.lThucTe`]="{ item }">
                    {{formatNumber(item.lThucTe)}}
                </template>

                <template v-slot:[`item.lLamThem`]="{ item }">
                    {{formatNumber(item.lLamThem)}}
                </template>
                
                <template v-slot:[`item.lTangCa`]="{ item }">
                    {{formatNumber(item.lTangCa)}}
                </template>

                <template v-slot:[`item.lLeTet`]="{ item }">
                    {{formatNumber(item.lLeTet)}}
                </template>

                <template v-slot:[`item.pcKhuVuc`]="{ item }">
                    {{formatNumber(item.pcKhuVuc)}}
                </template>

                <template v-slot:[`item.pcDaoTao`]="{ item }">
                    {{formatNumber(item.pcDaoTao)}}
                </template>

                <template v-slot:[`item.pcCongTac`]="{ item }">
                    {{formatNumber(item.pcCongTac)}}
                </template>

                <template v-slot:[`item.thuongQuy`]="{ item }">
                    {{formatNumber(item.thuongQuy)}}
                </template>

                <template v-slot:[`item.thuongTienAn`]="{ item }">
                    {{formatNumber(item.thuongTienAn)}}
                </template>

                <template v-slot:[`item.thuongTruyLanh`]="{ item }">
                    {{formatNumber(item.thuongTruyLanh)}}
                </template>

                <template v-slot:[`item.thucNhan`]="{ item }">
                    {{formatNumber(item.thucNhan)}}
                </template>

                <template v-slot:[`item.tongLuong`]="{ item }">
                    {{formatNumber(item.tongLuong)}}
                </template>

                <template v-slot:[`item.tongKhauTru`]="{ item }">
                    {{formatNumber(item.tongKhauTru)}}
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import http from '@/components/services/http-common';
// import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Leave',
    components: {
        CreateButton,
        // "create-dialog": Create,
        "data-filter": DataFilter
    },
    watch: {
        tableParams: {
            handler (val) {
                this.fetchData();
                this.fetchCity();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month: {
            handler(val)
            {
                this.filters.from = moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to = moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },

    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data() {
        return {
            ROOT_URL: "EmployeeSalaryImports/",

            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //filters
            month: moment().format("YYYY-MM"),
            filters:{
                code:null,
                employeeName:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            rowCount: 0,

            //TABLE
            filterEmployeeCode:"",
            filterEmployeeName:"",
            filterDepartmentName:"",
            search: "",
            headers: [
                {text: "Tháng", value: "month",
                    filter: value => {
                        console.log(value.includes(moment(this.search).format("YYYY-MM-DD")));
                        if (!this.search) return true
            
                        return this.formatDate(value).includes(this.search)
                    },
                  },
                {text: "Mã NS", value: "maNS"},
                {text: "Tên NS", value: "ten"},
                {text: "Chức vụ", value: "chucVu"},
                {text: "Chi nhánh/Phòng ban", value: "chiNhanh",},
                {text: "STK", value: "stk"},
                {text: "Ngân Hàng", value: "nganHang"},
                {text: "Giờ công cơ bản", value: "gcCoBan"},
                {text: "Giờ công thực tế", value: "gcThucTe"},
                {text: "Giờ công làm thêm", value: "gcLamThem"},
                {text: "Giờ công tăng ca", value: "gcTangCa"},
                {text: "Giờ công lễ tết", value: "gcLeTet"},
                {text: "Khấu trừ tạm ứng", value: "ktTamUng"},
                {text: "Khấu trừ BHXH", value: "ktBHXH"},
                {text: "Khấu trừ TNCN", value: "ktTNCN"},
                {text: "Khấu trừ truy thu", value: "ktTruyThu"},
                {text: "Lương cơ bản", value: "lCoBan"},
                {text: "Lương thực tế", value: "lThucTe"},
                {text: "Lương làm thêm", value: "lLamThem"},
                {text: "Lương tăng ca", value: "lTangCa"},
                {text: "Lương lễ tết", value: "lLeTet"},
                {text: "Phụ cấp khu vực", value: "pcKhuVuc"},
                {text: "Phụ cấp đào tạo", value: "pcDaoTao"},
                {text: "Phụ cấp công tác", value: "pcCongTac"},
                {text: "Thưởng quý", value: "thuongQuy"},
                {text: "Thưởng tiền ăn", value: "thuongTienAn"},
                {text: "Thưởng truy lãnh", value: "thuongTruyLanh"},
                {text: "Tổng lương", value: "tongLuong"},
                {text: "Tổng khấu trừ", value: "tongKhauTru"},
                {text: "Thực nhận", value: "thucNhan"},
                {text: "Bằng chữ", value: "bangChu", width:"350px"},
            ],

            items: [],
            options: {},
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

        }
    },

    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatNumber(value){
            return value.toLocaleString('en-US', { style: 'decimal' });
        },
        
        status(value) {
            let result = {};
            switch(value) {
                case 0: {
                    result.text = "Chờ duyệt";
                    result.color = "warning"
                    break;
                }
                case 1: {
                    result.text = "Đã duyệt"
                    result.color = "success"
                    break;
                }
                case 2: {
                    result.text = "Từ chối"
                    result.color = "error"
                    break;
                }
                default:{
                    result.text = "unknown"
                    break;
                }
            }
            return result;
        },

        //DATA JS
        fetchData () {
            return http.get(this.ROOT_URL + "employee/show", {
                params: {
                    // month: this.month
                }
            })
            .then(res => {
                this.items = res.data.data
                this.isLoading = false;
            })
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "undo/" +this.targetItem.id).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    
    created() {
        this.fetchData();
    }
}
</script>