<template>
    <div class="excel">
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark small
                v-bind="attrs" v-on="on" >
                Xuất excel
                </v-btn>
            </template>
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>Xuất excel</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <v-row>
                        <v-col cols="12" >
                            <v-text-field label="Tên báo cáo" v-model="filters.title"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Từ ngày" v-model="filters.fromTo[0]" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Đến ngày" v-model="filters.fromTo[1]" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete :items="jobs" item-text="name" item-value="code" label="Công việc ứng tuyển" clearable
                            v-model="filters.jobCode"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete :items="statuses" label="Trạng thái"
                            v-model="filters.status"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete :items="cities" item-text="name" item-value="code" label="Thành phố" clearable
                            v-model="filters.cityCode"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete :items="cities" item-text="name" item-value="code" label="Thành phố mong muốn 1" clearable
                            v-model="filters.city1Code"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete :items="cities" item-text="name" item-value="code" label="Thành phố mong muốn 2" clearable
                            v-model="filters.city2Code"></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12" md="6">
                            <date-picker2 v-model="filters.fromTo"></date-picker2>
                        </v-col> -->
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn text small color="green" class="mx-auto" @click="exportExcel"> Xuất dữ liệu excel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import * as moment from "moment/moment";
import { months } from 'moment';
export default {
    props: ["jobs", "cities"],
    watch:{
        // dialog:{
        //     handler(val){
        //         if(val==true)
        //             this.fetchJob();
        //     }
        // }
    },
    data() {
        return {
            ROOT_URL: "candidates/",
            dialog: false,
            // jobs:[],
            statuses: [
                {text: "Mới", value: "NEW"},
                {text: "Chờ", value: "PEND"},
                {text: "Đạt", value: "PASS"},
                {text: "Loại", value: "FAIL"},
            ],
            filters: {
                status:null,
                cityCode:null,
                city1Code:null,
                city2Code:null,
                jobCode:null,
                fromTo:[moment().startOf("months").format("YYYY-MM-DD"), moment().endOf("months").format("YYYY-MM-DD")],
                title:"Dữ liệu tuyển dụng",
            },
        }
    },
    methods:{
        exportExcel() {
            return http.get(this.ROOT_URL+"export", {
                params:this.filters,
                responseType: 'arraybuffer',
            }).then(res=>{
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'excel_ung_vien.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },
        // fetchJob() {
        //     return http.get("jobs").then(res=> {
        //         this.jobs = res.data;
        //     })
        // },
    }
}
</script>