import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'departments';
// global api
class DepartmentApi {
  gets(type="OFFICE") {
    return http.get(`${ROUTE}/org-chart`, {
      params: {
        type: type
      }
    }).then(r => r.data.data)
  }
  
  getEmployee() {
    return http.get(`${ROUTE}/employee`).then(r => r.data.data)
  }

  get(id) {
    return http.get(`${ROUTE}/${id}`).then(r => r.data)
  }

  post(data) {
    return http.post(`${ROUTE}`, data)
  }
  put(id, data) {
    return http.put(`${ROUTE}/${id}`, data)
  }
  remove(id){
    return http.delete(`${ROUTE}/${id}`)
  }
  
  getFamilyTemp(id) {
    return http.get(`employeeFamilies/temp/${id}`).then(r => r.data)
  }

}

export default new DepartmentApi();