<template>
  
  <div class="recruitment-request">
    <!-- <v-breadcrumbs class="bread-crumbs"
      :items="breadcrumbs" large >
    
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :exact="item.exact"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->
    <my-breadcrumb :data="breadcrumbs"></my-breadcrumb>
      <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb> -->
      

      <!-- <create-dialog-health-insurance ref="createHealthInsurance" @needFetch="fetchData"></create-dialog-health-insurance> -->
      <v-card>
          <v-card-title>
              <!-- Danh sách bảo hiểm -->
              <v-spacer></v-spacer>
              <v-btn icon @click="gets" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
              <v-btn class="" color="teal darken-4" dark @click="createDialog = true"> Tạo mới</v-btn>
              <!-- <create-button @click="dialog={state: true, item:null}"></create-button> -->
          </v-card-title>
          <v-data-table height="600px"
          :headers="headers"
          :page="page"
          :items="data"
          :search="search"
          :loading="isLoading"
          >
              <!-- BHYT -->
              <template v-slot:[`item.actions`]="{ item }">
                  <v-btn x-small class="mx-1" color="white" @click="editItem(item)"><v-icon small>mdi-pencil</v-icon> Chi tiết</v-btn>
                  <v-btn x-small class="mx-1" color="error" @click="dialogDelete=true, targetItem = item"><v-icon small>mdi-pencil</v-icon> Xóa</v-btn>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                  <v-btn small color="error" style="width: 50px; height: 50px; " class="ma-2" @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <template v-slot:[`item.relationType`]="{ item }">
                {{relationTypes[item.relationType]}}
                <!-- <v-chip color="pink lighten-4">
                  <b>{{relationTypes[item.relationType]}}</b>
                </v-chip> -->
              </template>
              <template v-slot:[`item.isDependant`]="{ item }">
                  <v-icon color="green" v-if="item.isDependant == 1">mdi-check</v-icon>
              </template>
              <template v-slot:[`item.doB`]="{ item }">
                  {{ formatDate(item.doB) }}
              </template>
          </v-data-table>
          
      </v-card>

      <!-- ************** DELETE CONFIRMATION *************** -->
      <!-- <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
              <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
              <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog> -->
      <create-dialog v-model="createDialog" :item.sync="targetItem"></create-dialog>
    <confirm-dialog v-model="dialogDelete" :item="targetItem" @confirm="cfmDeleteItem" ></confirm-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import EmployeeFamilyIndexApi from './api.js'
import MyBreadcrumb from '../../../components/MyBreadcrumb.vue';
import ExportExcel from '@/components/ExportExcel'
import CreateDialog from './Create.vue'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: { 
    MyBreadcrumb,
    ExportExcel,
    CreateDialog,
    ConfirmDialog
  },
  data() {
    return {
      createDialog: false,
      //DATATABLE
      data: [],
      relationTypes: [],
      page: 0,
      totalItem: 0,
      search:"",
      options: {},
      isLoading: true,
      targetItem: undefined,
      headers: [
          { text: this.$t('view.action.detail'), value: 'actions', sortable: false, width: "12rem"},
          { text: 'Mã nhân sự', align: 'start', sortable: false, value: 'employeeCode', },
          { text: 'Họ tên NPT', align: 'start', value: 'memberName', width:'15rem' },
          { text: 'Mối quan hệ', align: 'start', value: 'relationType', },
          { text: 'CCCD', value: 'memberId' },
          { text: 'Số điện thoại', value: 'phoneNumber' },
          { text: 'Ngày sinh NPT', value: 'doB' },
          { text: 'Đối tượng phụ thuộc', value: 'isDependant' },
          // { text: 'Đối tượng phụ thuộc', value: 'dateBack' },
      ],

      dialogDelete: false,
      
      // cfmDeleteItem: null,
      
      breadcrumbs: [
        {
          text: 'Thông tin về gia đình',
          disabled: false,
          to: {name: 'EmployeeFamily'}
        },
      ]
    }
  },
  methods: {
    
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    
    editItem (item) {
      this.targetItem = item
      this.createDialog  =true
    },

    //fetching
    gets() {
      this.isLoading = true
      return EmployeeFamilyIndexApi.gets().then(d => {
        this.data = d
      }).finally(()=> {
        this.isLoading = false
      })
    },
    
    deleteItem (item) {
          this.dialogDelete = true;
          this.targetItem = item;
      },
    cfmDeleteItem() {
      console.log(this.targetItem)
      return EmployeeFamilyIndexApi.deleteItem(this.targetItem.id).then(() => {
        this.targetItem = null
        this.dialogDelete = false
      }).finally(() => {
        return this.gets()
      })
    },
  },

  created() {
    this.gets()
    
    EmployeeFamilyIndexApi.getRelationType().then(r => {
      this.relationTypes = r.dict
    })
  }
}
</script>