<template>
    <div class="create-timesheet-report">
        <v-dialog v-model="value.state" persistent scrollable width="600px">
            <v-card>
                <v-card-title class="headlight secondary white--text"> Chốt giờ công
                    <v-spacer></v-spacer>
                    <v-btn icon @click.prevent="close" dark>
                        <v-icon >mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-stepper v-model="stepProgress" vertical flat>
                        <!-- STEP 1 -->
                        <v-stepper-step :complete="stepProgress > 1" step="1" color="orange">
                            Thông tin cơ bản
                        </v-stepper-step>

                        <v-stepper-content step="1">
                            <v-card class="mb-12" flat>
                                <v-form ref="form" @submit.prevent>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field label="Tên báo cáo" color="teal" v-model="form.name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu
                                                ref="menu"
                                                v-model="dateMenu"
                                                :close-on-content-click="false"
                                                :return-value.sync="dateMonth"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="dateMonth"
                                                    label="Tháng"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                locale="vi"
                                                v-model="dateMonth"
                                                type="month"
                                                no-title
                                                scrollable
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="teal"
                                                    @click="dateMenu = false"
                                                >
                                                    Thoát
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="teal"
                                                    @click="$refs.menu.save(dateMonth)"
                                                >
                                                    OK
                                                </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card>
                        <v-btn color="orange" dark @click="finishStep(1)" >
                            Tiếp tục
                        </v-btn>
                        </v-stepper-content>
                        <!-- STEP 2 -->
                        <v-stepper-step :complete="stepProgress > 2" step="2" color="orange" >
                            Xác nhận đơn nghỉ dài hạn
                        </v-stepper-step>

                        <v-stepper-content step="2">
                            <v-card flat class="mb-12" >
                                <v-data-table :headers="longLeaveRequestHeaders" :items="longLeaveRequests">
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-btn-toggle dense rounded @click.prevent>
                                            <v-btn v-if="item.status == 0" small color="success" @click="accept(item, 'long-leave')">
                                                <!-- <v-icon color="white">
                                                    mdi-check
                                                </v-icon> -->
                                                Chấp nhận
                                            </v-btn>

                                            <!-- ********* Xoa ****** -->
                                            <v-btn v-if="item.status == 0" small  color="error" @click="decline(item, 'long-leave')">
                                                <!-- <v-icon color="white">
                                                    mdi-close
                                                </v-icon> -->
                                                Từ chối
                                            </v-btn>
                                        </v-btn-toggle>
                                    </template>
                                </v-data-table>
                            </v-card>
                            <v-btn
                                color="orange" dark :disabled="longLeaveRequests.length!=0"
                                @click="finishStep(2)"
                            >
                                Tiếp tục
                            </v-btn>
                        </v-stepper-content>
                        <!-- STEP 3 -->
                        <v-stepper-step :complete="stepProgress > 3" step="3" color="orange">
                            Xác nhận đơn/phiếu yêu cầu
                        </v-stepper-step>

                        <v-stepper-content step="3">
                        <v-card flat class="mb-12" >
                            <v-data-table :headers="businessHeaders" :items="businesses" class="my-1">
                                <template v-slot:top>
                                    <v-subheader style="subtitle-1">Đơn công tác chưa x/n</v-subheader>
                                </template>
                            </v-data-table>
                            
                            <v-data-table :headers="overtimeHeaders" :items="overtimes" class="my-1">
                                <template v-slot:top>
                                    <v-subheader style="subtitle-1">Đơn công tác chưa x/n</v-subheader>
                                </template>
                            </v-data-table>

                            <v-data-table :headers="leaveHeaders" :items="leaves" class="my-1">
                                <template v-slot:top>
                                    <v-subheader style="subtitle-1">Đơn công tác chưa x/n</v-subheader>
                                </template>
                                
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-btn-toggle dense rounded @click.prevent>
                                        <v-btn small color="success" @click="accept(item, 'leave')">
                                            Chấp nhận
                                        </v-btn>

                                        <v-btn small  color="error" @click="decline(item, 'leave')">
                                            Từ chối
                                        </v-btn>
                                    </v-btn-toggle>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-btn
                            color="orange" :dark="businesses.length==0 && overtimes.length==0 && leaves.length==0" 
                            :disabled="false"
                            @click="finishStep(3)"
                        >
                            Tiếp tục
                        </v-btn>
                        </v-stepper-content>
                        <!-- STEP 4 -->
                        <v-stepper-step step="4" color="orange" :complete="stepProgress > 4">
                            Xác nhận giờ công
                            <small>Giờ công sẽ bị khóa sau khi hoàn tất</small>
                        </v-stepper-step>
                        <v-stepper-content step="4" >
                            <v-card flat class="mb-12" >
                                <v-data-table :items="timeSheets" :headers="timeSheetHeaders">
                                    <template v-slot:[`item.isConfirm`]="{ item }">
                                        <span v-show="!item.isConfirm">Chưa xác nhận</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-btn-toggle dense rounded @click.prevent>
                                            <v-btn small color="success" @click="tsQuickAccept(item)">
                                                X/n nhanh
                                            </v-btn>

                                            <v-btn small  color="primary" @click="decline(item, 'timeSheet')">
                                                Chi tiết
                                            </v-btn>
                                        </v-btn-toggle>
                                    </template>
                                </v-data-table>
                            </v-card>
                            <v-btn
                                color="orange" dark
                                @click="finishStep(4)"
                            >
                                Tiếp tục
                            </v-btn>
                        </v-stepper-content>
                    </v-stepper>
                </v-card-text>
                <v-card-actions v-if="stepProgress==5">
                    <v-btn block depressed color="green" class="white--text" :disabled="stepProgress!=5" @click="ProccessReport">Chốt báo cáo </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loadingDialog" class="loading-dialog" width="500px" fullscreen>
            <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.5);">
                <v-layout justify-center align-center class="flex-column">
                    <v-sheet color="transparent" >
                        <v-progress-circular class="mx-auto"
                        indeterminate size="64"
                        color="red"
                        ></v-progress-circular>
                    </v-sheet>
                    <div class="d-flex white--text">
                        <h3 > Hệ thống đang xử lý vui lòng chờ</h3>
                    </div>
                </v-layout>
            </v-container>
        </v-dialog>
    </div>
</template>

<script>
import http from "../../../../components/services/http-common"
import DatePicker from "../../../../components/DatePicker.vue"
import moment from 'moment';

export default {
    name: "CreateTimesheetReport",
    components: {
        "date-picker": DatePicker,
    },
    props: ["value", "item"],
    computed: {
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                {
                    this.getData();
                }
                if(val.state)
                    return val || this.close();
            }
        },
        stepProgress:{
            handler(val) {
                console.log(val);
                switch (val) {
                    case 1:
                        break;
                    case 2:
                        this.getLongLeaveRequests(this.form.code)
                        break;
                    case 3:
                        this.getRequests(this.form.code)
                        break;
                    case 4:
                        this.getTimeSheets(this.form.code);
                    default:
                        break;
                }
            }
        },
        dateMonth: {
            handler(val) {
                this.getMonthRange(val);
            }
        }
    },
    data () {
        return {
            //URL
            ROOT_URL: "TimesheetReports/",
            CONFIRM_URL: "ConfirmRequests/",

            //Steppers
            stepProgress: 1,
            loadingDialog: false,
            
            //data
            form: {
                code: "",
                name:"Báo cáo giờ công tháng ",
                month:"",
                from: "",
                to: "",
            },
            formDefault: {
                code: "",
                name:"Báo cáo giờ công tháng ",
                month:"",
                from: "",
                to: "",
            },
            defaultName: "Báo cáo giờ công tháng ",
            dateMenu: false,
            dateMonth: "",

            //tables
            longLeaveRequestHeaders: [
                {text: "Mã", value: "code"},
                {text: "Tên nhân viên", value: "name"},
                {text: "Loại nghỉ", value: "longleave"},
                {text: "Trạng thái", value: "status"},
                {text: "", value: "actions", sortable: false},
            ],
            longLeaveRequests: [],
            
            businessHeaders: [
                {text: "Mã", value: "code"},
                {text: "Tên nhân viên", value: "name"},
                {text: "Loại", value: "business"},
                {text: "Trạng thái", value: "status"},
                {text: "", value: "actions", sortable: false},
            ],
            businesses: [],

            overtimeHeaders: [
                {text: "Mã", value: "code"},
                {text: "Loại", value: "overtime"},
                {text: "Tên nhân viên", value: "name"},
                {text: "Trạng thái", value: "status"},
                {text: "", value: "actions", sortable: false},
            ],
            overtimes: [],
            
            leaveHeaders: [
                {text: "Mã", value: "code"},
                {text: "Loại", value: "leaves"},
                {text: "Tên nhân viên", value: "name"},
                {text: "Trạng thái", value: "status"},
                {text: "", value: "actions", sortable: false},
            ],
            leaves: [],

            timeSheetHeaders: [
                {text: "Mã", value: "code"},
                {text: "Tên nhân viên", value: "name"},
                {text: "Đã làm", value: "totalWorktimeSalary"},
                {text: "Tổng giờ", value: "shiftHour"},
                {text: "Xác nhận", value: "isConfirm"},
                {text: "", value: "actions", sortable: false},
            ],
            timeSheets: [],
        }
    },
    methods: {
        getMonthRange(monthStr) {
            let year = monthStr.split("-")[0];
            let month = monthStr.split("-")[1];
            var firstDay = new Date(year, month-1, 1);
            var lastDay = new Date(year, month, 0);

            this.form.from = moment(firstDay).format("yyyy/MM/DD");
            this.form.to = moment(lastDay).format("yyyy/MM/DD");
        },
        close () {
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.formDefault);
            this.dateMonth = "",
            this.items = [];
            this.headers = [];
            this.stepProgress = 1;
            this.$emit("CloseDialog");
        },
        getData () {
            return http.get(this.ROOT_URL + this.value.item.code).then(res => {
                this.form = Object.assign({}, res.data.item);
                this.stepProgress = this.form.step +1;
                let y = this.form.from.split("-")[0];
                let m = this.form.from.split("-")[1];
                this.dateMonth = `${y}-${m}`;
            })
            .catch(err => {
                throw err;
            })
        },
        getLongLeaveRequests(id){
            return http.get(this.ROOT_URL+id+"/long-leaves").then(res => {
                this.longLeaveRequests = res.data.items;
            })
        },
        getRequests(id){
            return http.get(this.ROOT_URL+id+"/requests").then(res => {
                this.businesses = res.data.businesses;
                this.overtimes = res.data.overtimes;
                this.leaves = res.data.leaves;
            })
        },
        getTimeSheets(id){
            return http.get(this.ROOT_URL+id+"/timesheets").then(res => {
                this.timeSheets = res.data.timeSheets;
            })
        },
        
        //Create
        ProccessReport () {
            this.loadingDialog = true;
            return http.get(this.ROOT_URL+ this.form.code +"/process-report", {
                // responseType: 'arraybuffer',
            }).then(res => {
                
                this.loadingDialog = false;
                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'bao_cao_cham_cong.xlsx');
                // document.body.appendChild(link);
                // link.click();
            }).catch(err => console.log(err))
        },

        finishStep(step){
            switch (step) {
                case 1:
                    return http.post(this.ROOT_URL, this.form).then(res => {
                        this.form = Object.assign({}, res.data.timesheetReport);
                        this.stepProgress = res.data.timesheetReport.step + 1;
                        this.$emit("fetchData");
                    }).catch(err => console.log(err))
            
                case 2:
                    return http.get(this.ROOT_URL+this.form.code+"/step-2").then(res => {
                        this.stepProgress = res.data.report.step + 1;
                    }).catch(err => console.log(err))

                case 3:
                    return http.get(this.ROOT_URL+this.form.code+"/step-3").then(res => {
                        this.stepProgress = res.data.report.step + 1;
                    }).catch(err => console.log(err))
                    
                case 4:
                    return http.get(this.ROOT_URL+this.form.code+"/step-4").then(res => {
                        this.stepProgress = res.data.report.step + 1;
                    }).catch(err => console.log(err))
                default:
                    break;
            }
        },

        saveData () {
            if(this.valid)
                http.post(this.ROOT_URL, this.form)
                .then(res => {
                    this.value.item = {code: res.data.code};
                    this.getData();
                    this.$emit("fetchData");
                })
                .catch(err => {throw err})
            else 
                console("form co loi validation");
        },
        
        accept(item, request) {
            this.isLoading = true;
            return http.get(this.CONFIRM_URL + request + "/accept/" + item.code).then(res => {
                switch (request) {
                    case "long-leave":
                        this.getLongLeaveRequests(this.form.code)
                        break;
                    default:
                        this.getRequests(this.form.code)
                        break;
                }
                this.isLoading=false;
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item, request) {
            http.get(this.CONFIRM_URL+ request+ "/deny/" + item.code).then(res => {
                switch (request) {
                    case "long-leave":
                        this.getLongLeaveRequests(this.form.code)
                        break;
                    default:
                        this.getRequests(this.form.code)
                        break;
                }
                this.isLoading=false;
            }).catch(err => {
                console.log(err)
            });
        },

        //TIMEsheet Accpet
        tsQuickAccept(item) {
            this.isLoading = true;
            return http.post(this.ROOT_URL+this.form.code + "/timesheet/" + item.code).then(res => {
                this.getTimeSheets(this.form.code);
            }).catch(err => {
                console.log(err)
            });
        },

        tsDetailAccept(item) {
            http.get(this.CONFIRM_URL+ request+ "/deny/" + item.code).then(res => {
                switch (request) {
                    case "long-leave":
                        this.getLongLeaveRequests(this.form.code)
                        break;
                    default:
                        this.getRequests(this.form.code)
                        break;
                }
                this.isLoading=false;
            }).catch(err => {
                console.log(err)
            });
        },

    },
    created() {
        console.log(new Date(Date.UTC(2021, 0, 1)))
    }
    
}
</script>
<style scoped>
.loading-dialog{
    box-shadow: none;
    height: 200px;
}
</style>