<template>
    <div class="data-filter">
        <!-- #FILTER MENU -->
        <v-menu
            v-model="menu"
            :close-on-click="true"
            :close-on-content-click="false"
            :nudge-width="350"
            offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="blue-grey darken-2" dark v-bind="attrs" v-on="on" class="my-5">
                    <v-icon small dark>mdi-filter-menu</v-icon> Lọc
                </v-btn>
            </template>

            <v-card>
                <v-form @submit.prevent="emitFilter">
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                            <v-list-item-title>Bộ lọc</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>
                    <v-list>
                        <!-- datetime filter -->
                        <v-list-item v-if="filterProps.dateOns" >
                            <v-select v-model="dateOn" :items="filterProps.dateOns" item-text="name" item-value="code" label="Ngày của"  solo flat hide-details="" dense clearable>
                            </v-select>
                        </v-list-item>
                        <v-list-item v-if="filterProps.dateOns">
                            <date-picker v-model="from" title="Từ"></date-picker>
                            <date-picker v-model="to" title="Đến"></date-picker>
                        </v-list-item>

                        <!-- search -->
                        <v-list-item >
                            <v-select v-model="searchOn" :items="filterProps.searchOns" item-text="name" item-value="code" label="Tìm trên" solo flat hide-details="" dense clearable>
                            </v-select>
                        </v-list-item>
                        <v-list-item >
                            <v-text-field v-model="search" label="Tìm kiếm" solo outlined flat hide-details="" dense></v-text-field>
                        </v-list-item>

                        <!-- Filter -->
                        <v-list-item v-for="(cb, i) in filterProps.checkboxes" :key="i">
                            <v-checkbox v-model="filters[cb.value]" :label="cb.text"></v-checkbox>
                        </v-list-item>
                        <v-list-item v-if="filterProps.autocompletes">
                            <v-autocomplete v-model="filters[cb.value]" :label="cb.text"></v-autocomplete>
                        </v-list-item>
                    </v-list>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    
                    <v-btn
                        text
                        @click="menu = false"
                    >
                        Đóng
                    </v-btn>

                    <v-btn color="primary" text type="submit" >
                        Lọc
                    </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import DatePicker from "../components/DatePicker";

export default {
    name: "DataFilter",
    components: {
        'date-picker': DatePicker
    },
    props: ["value", "filterProps", "defaults"],
    data () {
        return {
            dateOn:"",
            from: new Date().toISOString().substring(0,10),
            to: new Date().toISOString().substring(0,10),
            searchOn:"",
            search:"",
            filters: {},

            menu:false,
        }
    },
    watch: {

    },
    methods: {
        emitFilter() {
            // this.$emit("input", this.outputParams);
            let resultObj = {
                search: this.search,
                searchOn: this.searchOn,
                from: this.from,
                to: this.to,
                dateOn: this.dateOn,
                filters: Object.entries(this.filters).map(arr => {return arr[1]}),
                filterOns: Object.entries(this.filters).map(arr => {return arr[0]}),
            }
            this.$emit("fetchData", resultObj);
        }
    },
    created () {
        this.dateOn = this.$props.defaults.dateOn;
        this.searchOn = this.$props.defaults.searchOn;
        this.filters = Object.assign({}, this.$props.defaults.filters)
    }

}
</script>