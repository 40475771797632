import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'InternalDenouces';
// global api
class InternalDenouceApi {
  gets(fromDate, toDate) {
    return http.get(`${ROUTE}/admin`, {
      params: {
        fromDate: fromDate,
        toDate: toDate
      }
    }).then(r => r.data.data)
  }
  
  get(id) {
    return http.get(`${ROUTE}/${id}`, {
    }).then(r => r.data.data)
  }

  post() {
    return http.post(`${ROUTE}`, {
    })
  }
  put(id, form) {
    return http.put(`${ROUTE}/${id}`, form)
  }
  
  remove(id) {
    return http.delete(`${ROUTE}/${id}`, {
    })
  }

  addEmployee(id, employeeCode) {
    return http.post(`${ROUTE}/form-employee/${id}`, {},{
      params: {
        employeeCode: employeeCode,
        action: 'ADD'
      }
    })
  }
  
  removeEmployee(id, employeeCode) {
    return http.post(`${ROUTE}/form-employee/${id}`, {},{
      params: {
        employeeCode: employeeCode,
        action: 'REMOVE'
      }
    })
  }

  uploadImage(id, file, type) {
    var formData = new FormData()
    formData.append('file', file)
    return http.post(`${ROUTE}/upload-image/${id}`, formData, {
      params: {
        type: type,
        action: 'ADD'
      }
    })
  }
  
  removeImage(id, file, type) {
    var formData = new FormData()
    formData.append('file', file)
    return http.post(`${ROUTE}/upload-image/${id}`, formData, {
      params: {
        type: type,
        action: 'REMOVE'
      }
    })
  }

  changeStatus(id, status){
    return http.get(`${ROUTE}/change-status/${id}`, {
      params: {
        status: status
      }
    })
  }
}

export default new InternalDenouceApi();