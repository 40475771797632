<template>
  <div class="home" >
    <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" ></myBreadCrumb> -->
    <v-row>
      <v-col cols="12" md="6" lg="4" xl="3">
        <router-link :to="{ name: 'Enrollment'}" style="text-decoration: none; color: inherit;">
          <v-card height="100%" class=" rounded-lg" style=" overflow:hidden !important">
            <div class="d-flex justify-start">
              <v-avatar size="128" color="indigo lighten-4" tile rounded="">
                <img src="https://cdn-icons-png.flaticon.com/512/3094/3094770.png" style="width:72px; height: 72px" alt="">
                <!-- <v-icon dark x-large>mdi-book-check-outline</v-icon> -->
              </v-avatar>
              <v-card-text class="" style="word-break: break-word">
                  <span class="text-subtitle-1 indigo--text text--darken-4" style="text-transform: uppercase;">
                    {{ $t("employee_page.home.enrollment") }} </span>
                <div class="overline font-weight-bold">
                  <clock></clock>
                </div>
              </v-card-text>
            </div>
            <v-sheet color="indigo darken-4"  height="4px"></v-sheet>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12"  md="6" lg="4" xl="3">
        <router-link :to="{ name: 'LeaveRequest'}" style="text-decoration: none; color: inherit;">
          <v-card height="100%" class=" rounded-lg" style=" overflow:hidden !important">
            <div class="d-flex justify-start">
              <v-avatar size="128" color="red lighten-4" tile rounded=""> 
                <!-- <v-icon dark x-large>mdi-bag-suitcase</v-icon> -->
                <img src="https://cdn-icons-png.flaticon.com/512/1589/1589649.png" style="width:72px; height: 72px" alt="">
              </v-avatar>
              <v-card-text class="" style="word-break: break-word">
                   <span class="text-subtitle-1 red--text text--darken-4" style="text-transform: uppercase;">{{ $t("employee_page.home.leave_request") }} </span>
                   
                  <!-- <div class="d-flex justify-between overline">
                      <div class="flex-grow-1"><v-icon small color="blue" class="mr-2">mdi-pencil</v-icon> 3</div>
                      <div class="flex-grow-1"><v-icon small color="success" class="mr-2">mdi-check</v-icon> 3</div>
                      <div class="flex-grow-1"><v-icon small color="red" class="mr-2">mdi-close</v-icon> 3</div>
                  </div> -->
                  <!-- <div class="subtitle text-right">
                    <v-icon color="red">mdi-dots-horizontal</v-icon>
                  </div> -->
              </v-card-text>
            </div>
            <v-sheet color="red darken-4" height="4px"></v-sheet>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12"  md="6" lg="4" xl="3">
        <router-link :to="{ name: 'OvertimeRequest'}" style="text-decoration: none; color: inherit;">
          <v-card height="100%" class=" rounded-lg" style=" overflow:hidden !important">
            <div class="d-flex justify-start">
              <v-avatar size="128" color="orange lighten-4" tile rounded="">
                <img src="https://cdn-icons-png.flaticon.com/512/5260/5260337.png" style="width:72px; height: 72px" alt="">
                <!-- <v-icon dark x-large>mdi-clock-alert</v-icon> -->
              </v-avatar>
              <v-card-text class="" style="word-break: break-word;">
                <span class="text-subtitle-1 orange--text text--darken-4" style="text-transform: uppercase">
                  {{ $t("employee_page.home.overtime_request") }}
                </span>
                  
                  <!-- <div class="subtitle text-right">
                    <v-icon color="orange">mdi-dots-horizontal</v-icon>
                  </div> -->
              </v-card-text>
            </div>
            <v-sheet color="orange darken-4" height="4px"></v-sheet>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12"  md="6" lg="4" xl="3">
        <router-link :to="{ name: 'BusinessRequest'}" style="text-decoration: none; color: inherit;">
          <v-card height="100%" class=" rounded-lg" style=" overflow:hidden !important">
            <div class="d-flex justify-start">
              <v-avatar size="128" color="teal lighten-4" tile rounded="">
                <img src="https://cdn-icons-png.flaticon.com/512/5766/5766406.png" style="width:72px; height: 72px" alt="">
                <!-- <v-icon dark x-large>mdi-earth-plus</v-icon> -->
              </v-avatar>
              <v-card-text class="text-subtitle-1 teal--text text--darken-4 text-truncate" >
                  <span style="text-transform: uppercase">
                    {{ $t("employee_page.home.business_request") }}
                  </span>
                  <!-- <div class="subtitle text-right">
                    <v-icon color="green darken-2">mdi-dots-horizontal</v-icon>
                  </div> -->
              </v-card-text>
            </div>
            <v-sheet color="teal darken-4" height="4px"></v-sheet>
          </v-card>
        </router-link>
      </v-col>
      
      <v-col cols="12"  md="6" lg="4" xl="3">
        <router-link :to="{ name: 'InfoUpdate'}" style="text-decoration: none; color: inherit;">
          <v-card height="100%" class=" rounded-lg" style=" overflow:hidden !important">
            <div class="d-flex justify-start">
              <v-avatar size="128" color="cyan lighten-4" tile rounded="">
                <img src="/static/placeholder/personal_info.png" style="width:72px; height: 72px" alt="">
                <!-- <v-icon dark x-large>mdi-earth-plus</v-icon> -->
              </v-avatar>
              <v-card-text class="text-subtitle-1 cyan--text text--darken-4 text-truncate" >
                  <span style="text-transform: uppercase">
                    {{ 'Thông tin cá nhân' }}
                  </span>
                  <div class="text-body-2">
                    Cập nhật thông tin cá nhân
                  </div>
              </v-card-text>
            </div>
            <v-sheet color="cyan darken-4" height="4px"></v-sheet>
          </v-card>
        </router-link>
      </v-col>
      
      <v-col cols="12"  md="6" lg="4" xl="3">
        <a href="https://hocvientrungson.welearning.vn/login" style="text-decoration: none; color: inherit;" target="_blank">
          <v-card height="100%" class=" rounded-lg" style=" overflow:hidden !important">
            <div class="d-flex justify-start">
              <v-avatar size="128" color="lime lighten-4" tile rounded="">
                <img src="/static/placeholder/elearning.png" style="width:72px; height: 72px" alt="">
                <!-- <v-icon dark x-large>mdi-earth-plus</v-icon> -->
              </v-avatar>
              <v-card-text class="text-subtitle-1 lime--text text--darken-4 text-truncate" >
                  <span style="text-transform: uppercase">
                    {{ 'E-learning' }}
                  </span>
                  <!-- <div class="subtitle text-right">
                    <v-icon color="green darken-2">mdi-dots-horizontal</v-icon>
                  </div> -->
              </v-card-text>
            </div>
            <v-sheet color="lime darken-4" height="4px"></v-sheet>
          </v-card>
        </a>
      </v-col>

      <v-col cols="12"  md="6" lg="4" xl="3">
        <router-link :to="{ name: 'ItSupport'}" style="text-decoration: none; color: inherit;">
          <v-card height="100%" class=" rounded-lg" style=" overflow:hidden !important">
            <div class="d-flex justify-start">
              <v-avatar size="128" color="blue lighten-4" tile rounded="">
                <img src="/static/icons/support_icon.png" style="width:72px; height: 72px" alt="">
                <!-- <v-icon dark x-large>mdi-earth-plus</v-icon> -->
              </v-avatar>
              <v-card-text class="text-subtitle-1 blue--text text--darken-4 text-truncate" >
                  <span style="text-transform: uppercase">
                    {{ 'IT hỗ trợ' }}
                  </span>
                  <div class="text-body-2">
                    IT hỗ trợ 24h
                  </div>
              </v-card-text>
            </div>
            <v-sheet color="blue darken-4" height="4px"></v-sheet>
          </v-card>
        </router-link>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <media-section> </media-section>
            <!-- <v-card height="500">
              <v-carousel class="rounded" height="auto" 
                delimiter-icon="mdi-minus"
                hide-delimiter-background
                show-arrows-on-hover cycle  interval="2000">
                <v-carousel-item v-for="n in fNews" :key="n.code">
                  <v-card>
                    <v-img height="500" class="d-flex align-end" :src="n.avatar==''?`data:image/png;base64, ${n.avatarBlob}`:n.avatar" eager cover position="top center">
                      <template v-slot:placeholder><img style="object-position: center; object-fit: fill;" src="/static/document_bg4.jpg" alt=""></template>
                      <v-card tile dark style="background-color:rgba(0, 0, 0, 0.7);">
                        <v-card-title class="white--text" style="word-break: break-word; ">
                          <div style="width: calc(100% - 150px)">
                          {{n.title}}
                          </div>
                          <v-spacer></v-spacer>
                          <v-btn color="orange lighten-2" text dark :to="{name:'NewsDetail', params: { id: n.code }}">Xem thêm...</v-btn>
                        </v-card-title>
                        <v-card-subtitle>{{diffToHuman(n.createdAt)}}</v-card-subtitle>
                      </v-card>
                    </v-img>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </v-card> -->
          </v-col>
          <!-- <v-col cols="12" md="6">
            <v-card height="500" class="d-flex flex-column space-between">
              <v-card-title>
                Thông báo & tin tức
              </v-card-title>
              <v-list height="400" style="overflow-y: scroll">
                <v-list-item v-for="n in news" :key="n.code" :to="{name:'NewsDetail', params: { id: n.code }}">
                  <v-list-item-content >
                    <v-list-item-title class="font-weight-bold teal--text text--darken-4">
                    {{n.title}}
                    </v-list-item-title>
                    <v-list-item-subtitle 
                      class="text--primary mt-1">
                      <v-icon left>mdi-clock</v-icon>
                      {{diffToHuman(n.createdAt)}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle >{{n.subtitle}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="orange" :to="{name:'News'}">> Xem thêm...</v-btn>
              </v-card-actions>
            </v-card>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-xl">
              <v-card-title class="text-title">{{ $t("employee_page.home.timesheets_in_month") }}</v-card-title>
              <v-card-text>
                <v-calendar
                  ref="calendar" locale="vi"
                  v-model="value"
                  mode="column"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  type="month"
                  :events="workEvents"
                  event-text-color="black"
                  :event-overlap-threshold="30"
                  @change="getEvents"
                ></v-calendar>
              </v-card-text>
              <!-- <stacked-chart ref="chart" label='Giờ công làm việc trong tháng' :chartData="chartData" ></stacked-chart> -->
            </v-card>
          </v-col>
          
          <v-col cols="12" md="6">
            <v-card color="" class="rounded-xl" height="100%">
              <v-card-title>
                {{ $t("employee_page.home.enrollment_history") }}
                <v-spacer></v-spacer>
                <month-picker label="Tháng" width="100px" v-model="monthYear" :outlined="true" :dense="true"></month-picker>
              </v-card-title>
              <v-card-text>
                <v-data-table style="" fixed-header mobile-breakpoint="0" hide-default-footer :items-per-page="-1" height="400px"
                :headers="enrollHeader" dense
                :items="enrollHistory" >
                    <template v-slot:[`item.enrollDate`]="{item}">
                      <b class="">{{item.enrollDate}}</b>
                    </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="rounded-xl" height="100%">
              <v-card-title>
                {{ $t("employee_page.home.schedule") }}
              </v-card-title>
              <v-card-text>
                <v-calendar
                  :events="events"
                  locale="vi"
                  ref="calendar"
                  :event-overlap-threshold="30"
                ></v-calendar>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="popNewsDialog"  width="900" >
      <v-card dark>
        <v-carousel class="rounded" height="auto"
          delimiter-icon="mdi-minus"
          hide-delimiter-background
          show-arrows-on-hover cycle interval="1300">
          <v-carousel-item v-for="n in popNews" :key="n.code">
            <v-card>
              <v-img min-height="500px" style="max-height: calc(100vh - 300px);" width="900" class="d-flex align-end" :src="n.avatar==''?`data:image/png;base64, ${n.avatarBlob}`:n.avatar" cover position="top center">
                <template v-slot:placeholder><img style="width:900px" src="/static/document_bg4.jpg" alt=""></template>
                <v-card tile dark style="background-color:rgba(0, 0, 0, 0.4);">
                  <v-card-title class="white--text">
                    {{n.title}}
                    <v-spacer></v-spacer>
                    <v-btn dark :to="{name:'NewsDetail', params: { id: n.code }}">Chi tiết...</v-btn>
                  </v-card-title>
                  <v-card-subtitle>{{diffToHuman(n.createdAt)}}</v-card-subtitle>
                </v-card>
              </v-img>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
<script>
// @ is an alias to /src
import myBreadCrumb from '../components/BreadCrumb';
import http from '../components/services/http-common';
import Clock from '../components/Clock.vue';
import MediaSection from '../components/MediaSection.vue';
import moment from 'moment';
import StackedChart from '../components/Charts/StackedChart.vue';
export default {
  name: 'Home',
  components: {
    MediaSection,
    myBreadCrumb,
    "clock": Clock,
    StackedChart
  },
  computed:{
    popNewsDialog: {
      get: function () {
        return this.popNews.length>0
      },
      set: function (val){
        console.log(val);
        this.$emit("input", val)
      }
    },
    user () {
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      return user;
    },
    events() {
      let results = [];
      let startMonth = moment().startOf("month");
      let endMonth = moment().endOf("month");
      while(startMonth<= endMonth)
      {
        results.push({
          start: startMonth.format("YYYY-MM-DD"),
          name: "Chưa phân công",
          color: "blue-grey"
        })
        startMonth.add(1, 'days');
      }
      
      this.schedules.forEach(x=> {
        results[results.findIndex(el => el.start === x.start)] = {start: x.start, name: x.name, color: x.color};
      })
      return results;
    }
  },
  data(){
        return {
          token: localStorage.getItem("token"),
          payload: "",
          fNews:[],
          news:[],
          popNews:[],

          //label
          schedules:[],

          workEvents: [
          ],
          //charts
          chartData: {
              labels: [],
              datasets: [
                  {
                      label: "Giờ nghỉ",
                      data: [],
                      backgroundColor: "#c685ff",
                      borderColor: "#7917cf",
                      borderWidth: 2
                  },
                  {
                      label: "Giờ làm",
                      data: [],
                      backgroundColor: "#9be67e",
                      borderColor: "#34b304",
                      borderWidth: 2
                  },
                  {
                      label: "Giờ làm thêm",
                      data: [],
                      backgroundColor: "#fccf6d",
                      borderColor: "#e86602",
                      borderWidth: 2
                  },
              ],
          },
          
          // Enrollments
          monthYear: moment().format("YYYY/MM"),
          enrollHistory: [],
          enrollHeader: [
              {text: this.$t("employee_page.home.enrollment_history_table.enroll_date"), value: "enrollDate",width: 120},
              {text: this.$t("employee_page.home.enrollment_history_table.checkin"), value: "checkinTime"},
              {text: this.$t("employee_page.home.enrollment_history_table.checkout"), value: "checkoutTime"},
              {text: this.$t("employee_page.home.enrollment_history_table.location"), value: "department"},
              // {text: "Trễ", value: "late"},
              // {text: "Về sớm", value: "early"},
              // {text: "Số giờ", value: "actualTime"},
          ],
        }
  },
  methods: {
    diffToHuman(value){
        let date = moment(value, "YYYY-MM-DD").locale("vi");
        return moment(date).fromNow();
    },
    formatDate(value, format) {
      return moment(value).format(format)
    },
    checkTime(i) {
      if (i < 10) {i = "0" + i}  // add zero in front of numbers < 10
      return i;
    },
    fetchFeatureNews () {
      return http.get("contents/web/get-news", {
        params: {
          isFeature: true
        }
      }).then(res=> {
        console.log(res.data);
        this.fNews = res.data.filter(x=>x.isFeature && !x.hidden);
        this.news = res.data.filter(x=>!x.isFeature && !x.hidden);
        // this.popNews = res.data.results.filter(x=>
        //   moment() <= moment(x.createdAt, "YYYY-MM-DD").add(3, "days")
        // )
      })
      .then(() => {
        this.poppingNews();
      });
    },
    fetchPopUp () {
      return http.get("contents/get-news").then(res=> {
        // this.fNews = res.data.results.filter(x=>x.isFeature);
        // this.news = res.data.results.filter(x=>!x.isFeature);
        this.popNews = res.data
      })
      .then(() => {
        this.poppingNews();
      });
    },
    fetchWorkSchedule() {
      let url = `WorkSchedules/employee/${this.user.employeeCode}`
      return http.get(url).then(res => {
        this.schedules = res.data;
      })
    },
    fetchEnrollHistory(){
      return http.get('account/enrollment/').then(res => {
          this.enrollHistory = res.data.items;
      });
    },
    fetchTimesheetInMonth() {
      return http.get('account/timesheet-in-month').then(res => {
        res.data.data.forEach(ts => {
          this.workEvents.push({
            name: `Làm việc: ${!ts.isConfirmed ? 0 :ts.validActualTime}h`,
            start: this.formatDate(ts.workDate, 'YYYY-MM-DD'),
            end: this.formatDate(ts.workDate, 'YYYY-MM-DD'),
            color: 'teal lighten-4',
          })

          this.workEvents.push({
            name: `Làm thêm: ${ts.overtime}h`,
            start: this.formatDate(ts.workDate, 'YYYY-MM-DD'),
            end: this.formatDate(ts.workDate, 'YYYY-MM-DD'),
            color: 'red lighten-4'
          })

          this.workEvents.push({
            name: `Nghỉ phép: ${ts.leaveTime}h`,
            start: this.formatDate(ts.workDate, 'YYYY-MM-DD'),
            end: this.formatDate(ts.workDate, 'YYYY-MM-DD'),
            color: 'blue lighten-4',
          })
        });
        console.log(this.events)
      })
    },
    initChart(){
      //label
      var labels = [];
      let start = moment().startOf('month');
      let end = moment().endOf('month');
      while(start<=end)
      {
        labels.push(start.format("YYYY-MM-DD"));
        start.add(1, "days");
      }
      this.chartData.labels=labels;
      // this.$refs.chart.update();
      let url = `timesheets/employee/${this.user.employeeCode}`;
      return http.get(url).then(res => {
        let result = res.data;
        console.log(result);
        let work = result.map(x=> x.work);
        let overtime = result.map(x=> x.work);
        let workTime = result.map(x=> {return {date:x.date, leave:x.leave}});
        
        this.chartData.datasets[0].data=[];
        labels.forEach(x=>{
          let item = result.find(z=>z.date==x);
          this.chartData.datasets[0].data.push(!!item?item.leave:0);
          this.chartData.datasets[1].data.push(!!item?item.work:0);
          this.chartData.datasets[2].data.push(!!item?item.overtime:0);
        })
        // leave.forEach(x => {
        //     this.chartData.datasets[0].data.push(x);
        // });
        // this.chartData.datasets[1].data=[];
        // work.forEach(x => {
        //     this.chartData.datasets[1].data.push(x);
        // });
        // this.chartData.datasets[2].data=[];
        // overtime.forEach(x => {
        //     this.chartData.datasets[2].data.push(x);
        // });
        this.$refs.chart.update();
      })
    }
  },

  async mounted() {
    var tokens = this.token.split(".");
    // console.log(atob(tokens[0]));
    // console.log(atob(tokens[1]));
  },
  created()
  {
    this.fetchPopUp()
    this.fetchFeatureNews()
    this.fetchWorkSchedule()
    this.fetchEnrollHistory()
    this.initChart()
    this.fetchTimesheetInMonth()
  }
}
</script>




