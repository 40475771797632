<template>
    <div class="job-create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="600px" scrollable>
                <v-card>
                    <v-card-title> {{titleText}} chức danh </v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="saveData" v-model="valid" ref="form" id="form">
                        <v-row  class="mx-auto">
                            <v-col cols="12">
                                <label for="positionCode">Thuộc vị trí</label>
                                <v-autocomplete id="positionCode" v-model="form.positionCodes" solo flat outlined dense clearable
                                :items="positions" item-text="name" item-value="code" :search-input.sync="positionSearch" multiple>
                                <template  v-slot:[`prepend-item`]="">
                                    <v-list-item @click="selectAllPosition">
                                        <v-btn small color="success"><v-icon small left>mdi-check</v-icon>Chọn tất cả</v-btn>
                                    </v-list-item>
                                </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="name">Tên cấp công việc</label>
                                <v-text-field id="name" v-model="form.name" solo outlined flat dense>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="order">Ưu tiên hiển thị</label>
                                <v-text-field id="order" v-model="form.order" solo outlined flat dense>
                                </v-text-field>
                            </v-col>
                            <v-subheader>Quyền lợi</v-subheader>
                            <v-col cols="12">
                                <v-autocomplete v-model="form.leavePlans" :items="leavePlans" item-value="code" item-text="name" label="Loại phép" 
                                item-color="primary" multiple></v-autocomplete>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  color="primary" type="submit" dark small form="form">Lưu dữ liệu</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>

<script>
import methods from './create_methods.js'
export default {
    name: "Create",
    props: ["value"],
    computed:{
        titleText () {
            return this.value.item ? "Cập nhật" : "Tạp mới";
        }
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            },
            deep: true,
        },
        
    //   positionSearch (val) {
    //     // Items have already been loaded
    //     if (this.positions.length > 0) return
    //     this.fetchPositions()
    //       .catch(err => {
    //       })
    //       .finally()
    //   },
    },
    data () {
        return {
            ROOT_URL: "PositionStates",

            //FORMDATA
            valid: false,
            formData: { },
            positions: [],
            positionSearch:"",
            leavePlans:[],
            form: {
                positionCodes:"",
                name: "",
                order: 0,
                leavePlans:[],
            },

            defaultForm: {
                positionCodes:"",
                name: "",
                order: 0,
                leavePlans:[],
            },
            
        }
    },
    ...methods,
    created() {
        this.fetchPositions();
        this.fetchLeavePlans();
    }
    
}
</script>