<template>
    <div class="create-contract-type">
        <v-dialog v-model="dialog" max-width="600px" scrollable>
            <v-card>
                <v-card-title>
                    <span class="headline">Tạo loại hợp đồng</span>
                </v-card-title>
                <v-card-text>
                <v-form @submit.prevent="createData" ref="form" id="form">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                            :disabled="isUpdate"
                            :rules="rules.nameCodes"
                            v-model="form.code"
                            label="Mã"
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                            :rules="rules.nameRules"
                            v-model="form.name"
                            label="Tên"
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="form.isOfficial" label="Hợp đồng chính thức"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                            v-model="form.description"
                            filled
                            label="Mô tả"
                            auto-grow
                            placeholder="Mô tả "
                            ></v-textarea>
                        </v-col>
                        <v-subheader>Chế độ được hưởng</v-subheader>
                        <v-col cols="12">
                            <v-autocomplete multiple chips deletable-chips label="Loại phép" outlined dense v-model="form.leavePlans"
                            :items="leavePlans" item-text="name" item-value="code"></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"
                        text @click="dialog = false" >
                        Thoát
                    </v-btn>
                    <v-btn color="blue darken-1"
                        text type="submit" form="form">
                        Lưu
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import methods from './create_methods.js';
export default {
    name: "ContractTypeCreateDialog",
    components:{

    },
    data() {
        return {
            //*************** Validate **************
            rules: {
                nameRules:[ 
                    v => !!v || 'Tên bắt buộc nhập',
                    v => v.length > 3 || 'Phải nhiều hơn 3 kí tự',
                ],
                codeRules:[ 
                    v => v ? v.length > 3 : true,
                ],
            },
            
            //************* Form config */
            rootUrl: "contracttypes",
            activetab: 0,
            valid: false,
            item: undefined,
            isUpdate: false,
            dialog: false,
            IDdateMenu: false,
            HireMenu: false,
            formData: {
                parents: []
            },
            leavePlans:[],
            form: {
                code: "",
                name:"",
                description: "",
                leavePlans:[],
                isOfficial:false,
            },
            defaultForm: {
                code: "",
                name:"",
                description: "",
                leavePlans:[],
                isOfficial:false,
            },
        };
    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật loại hợp đồng" : "Tạo mới loại hợp đồng";
        }
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
    },
    ...methods,
    mounted() {
        this.fetchLeavePlans();
    }
}
</script>
