import http from '@/components/services/http-common';
// import * as moment from "moment/moment"

export const ROUTE = 'employeePayrolls';
// global api
class EmployeePayrollApi {
  gets(month) {
    return http.get(`${ROUTE}`, {
      params: {
        month: month
      }
    }).then(r => r.data)
  }

  apply(month) {
    return http.post(`${ROUTE}/init`, null, {
      params: {
        month: month
      }
    })
  }
}

export default new EmployeePayrollApi();