import http from '@/components/services/http-common';
import * as moment from "moment/moment"

export const ROUTE = 'organizationMaps';
// global api
class OrganizationMapIndexApi {
  gets() {
      return http.get(`${ROUTE}`).then(r => r.data)
  }
  
  getItem(id) {
    return http.get(`${ROUTE}/${id}`).then(r=> {
      let data = r.data.data
      return {
        name: data.name,
        validFrom: moment(data.validFrom, "YYYY-MM-DD").format("YYYY-MM-DD"),
        validTo: moment(data.validTo, "YYYY-MM-DD").format("YYYY-MM-DD"),
        isActive: data.isActive
      }
    })
  }

  post(form) {
      return http.post(`${ROUTE}`, form).then(r => r.data)
  }

  put(form, id) {
      return http.put(`${ROUTE}/${id}`, form).then(r => r.data)
  }

  deleteItem(id) {
    return http.delete(`${ROUTE}/${id}`)
  }
}

export default new OrganizationMapIndexApi();