<template>
  <v-fade-transition mode="out-in">
    <v-container class="mt-3">
        <router-view></router-view>
    </v-container>
  </v-fade-transition>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
import http from '@/components/services/http-common';
export default {
    components:{
        SideBar
    },
    data(){
        return {
            items: [
                { title: "Dashboard", subheader:true},
                { title: "Tổng quan", icon: "mdi-view-dashboard-outline", route:{ name: 'EmployeeOverview'}, auth: ["module:employment"]},
                { title: "Lịch làm việc", icon: "mdi-file-check-outline", route:{ name: 'Schedule'}, auth: [ "module:employment"]},
               
                // { title: "Chấm công", subheader:true},
                // { title: "Review giờ công", icon: "mdi-electron-framework", route:{ name: 'ReviewTimesheet'}, auth: [ "timesheet:review"]},
                
                // { title: "Nhân sự", subheader:true},
                { title: "Thông tin nhân sự", subheader:true},
                { title: "Nhân sự", icon: "mdi-folder-account-outline", group:"/admin/employment/HR", active: false, auth: ["module:employment"],  subs: [
                  { title: "Nguồn tuyển dụng", icon: "mdi-semantic-web", route:{ name: 'EmployeeResource'}, auth: ["employee:index", "module:employment", "employee:rsc"]},
                  { title: "Nhân viên", icon: "mdi-human-greeting", route:{ name: 'Employee'}, path:"admin/hr/employees", auth: ["employee:index", "module:employment"]},
                  { title: "Người dùng", icon: "mdi-account-box-multiple", route:{name:"User"}, auth: ["user:index", "module:employment"]},
                  { title: "Thông tin gia đình", icon: "mdi-transfer", route:{name:"HrEmployeeFamilyIndex"}, auth: ["employee:index", "module:employment"]},
                  { title: "Trạng thái chấm công", icon: "mdi-ruler", route:{name:"CurrentEmployee"}, auth: ["actEmp:index"]},
                  { title: "Thiết bị", icon: "", route:{name:"EmployeeIssue"}, auth: ["module:employment"]},
                  // { title: "Chốt nhân viên", icon: "mdi-human-queue", route:{ name: 'MonthlyEmployee'}, auth: ["employee:monthly", "module:employment"]},
                  // { title: "Lịch sử phân công", icon: "mdi-human-greeting", route:{name:"Assignment"}, path:"/hr/assignments", auth: ["employee:index", "assignment:index"]},
                ]},
                { title: "Chế độ nhân sự", icon: "mdi-folder-heart-outline", group:"/admin/employment/benefits", active: false, auth: ["module:employment"],  subs: [
                  { title: "Phân bổ phép", icon: "mdi-human-greeting", route:{name:"LeaveEnroll"}, auth: ["employee:index", "module:employment", "leaveEnrollment:index"]},
                  { title: "Hợp đồng", icon: "mdi-shield-account", route:{name:"Contract"}, auth: ["contract:index", "module:employment"]},
                  { title: "Bảo hiểm", icon: "mdi-shield-account", route:{name:"Insurance"}, auth: ["insurance:index", "module:employment"]},
                  { title: "Chế độ", icon: "mdi-ruler", route:{name:"BenefitManager"}, auth: ["benMan:index"]},
                  { title: "Hợp đồng v2", icon: "mdi-shield-account", route:{name:"Contractv2"}, auth: ["contract:index", "module:employment"]},
                ]},

                { title: "Xác nhận & đơn từ", subheader:true},
                { title: "Giờ công", icon: "mdi-calendar-clock", route:{ name: 'HrTimeSheet'}, auth: [ "timesheet:index"]},
                { title: "Chấm công", icon: "mdi-clock-digital", route:{ name: 'Attendance'}, auth: "module:salary"},
                { title: "Yêu cầu", icon: "mdi-note-multiple-outline", group:"/request", active: false, auth: ["module:employment"], subs: [
                  { title: "Yêu cầu tuyển dụng", icon: "mdi-transfer", route:{name:"RecruitmentRequest"}, auth: ["recruit:rq"]},
                  { title: "Yêu cầu điều chuyển", icon: "mdi-transfer", route:{name:"EmployeeRotationRequest"}, auth: ["rot:rq"]},
                  { title: "Yêu cầu thay đổi thông tin", icon: "mdi-transfer", route:{name:"HrEmployeeInfoUpdate"}, auth: ["info:rq"]},
                ]},
                { title: "Xác nhận đơn", icon: "mdi-note-multiple-outline", group:"/Confirm", active: false, auth: ["module:employment"], subs: [
                  { title: "Tăng ca tối đa", icon: "", route:{name:"ConfirmOvertimeBooking"}, auth: ["module:employment", "otmax:rq"]},
                  { title: "Làm thêm", route:{name:"ConfirmOvertime"}, auth: ["module:employment", "request:index"]},
                  { title: "Công tác", route:{name:"ConfirmBusiness"}, auth: ["module:employment", "request:index"]},
                  { title: "Nghỉ phép", route:{name:"ConfirmLeave"} , auth: ["module:employment", "request:index"]},
                  // { title: "Nghỉ việc", route:{name:"ConfirmQuit"} , auth: ["module:employment"]},
                  // { title: "Nghỉ dài hạn", icon: "", route:{name:"ConfirmLongLeaveRequest"}, auth: ["module:employment", "request:index"]},
                  { title: "Điều chuyển", icon: "mdi-transfer", route:{name:"ConfirmRotationRequest"}, auth: ["rot:ad"]},
                ]},
                
                

                { title: "Truyền thông", subheader:true},
                { title: "Quản lý khảo sát", icon: "mdi-vote-outline", group:"admin/employment/survey", active: false, auth: ["employee:index", "module:employment", "employee:rsc"], subs: [
                  { title: "Danh mục", icon: "mdi-forum", route:{name: "Survey"}, auth: ["module:employment"] },
                ]},
               
                { title: "Quản lý thông báo", icon: "mdi-bullhorn-outline", group:"admin/employment/content", active: false, auth: [ "cont:edit"], subs: [
                  { title: "Danh mục", icon: "mdi-book-open-page-variant", route:{name: "Category"}, auth: ["module:employment"] },
                  { title: "Bài viết", icon: "mdi-bank", route:{name: "Content"}, auth: ["module:employment"] },
                ]},
                { title: "Chức năng khác", subheader:true},
                { title: "Sinh mã QR", icon: "mdi-qrcode", route:{ name: 'qrLink'}, auth: [ "module:employment", "qr:module"]},
                { title: "Sơ đồ tổ chức", icon: "mdi-sitemap", route:{ name: 'OrganizationMap'}, auth: [ ]},
                // { title: "Phản hồi", icon: "mdi-message-alert", route:{ name: 'Feedback'}, auth: [ "module:employment"]},
                { title: "B/C videos", icon: "mdi-video-outline", route:{ name: 'videoSurvey'}, auth: [ "module:employment"]},
                { title: "B/C tổng hợp", icon: "mdi-chart-box-plus-outline", route:{ name: 'EmployeeReport'}, auth: ["rp:store" ]},
                
                { title: "Cài đặt", subheader:true},
                { title: "Danh mục hợp đồng", icon: "mdi-qrcode", route:{ name: 'ContractCategory'}, auth: [ "module:employment","contract:index"]},
                { title: "Phân quyền", icon: "mdi-ruler", route:{name:"Role"}, auth: ["role:index", "module:employment"]},
                { title: "Cài đặt ASM", icon: "mdi-ruler", route:{name:"AsmConfig"}, auth: ["role:index", "module:employment"]},
                
            ],
            dbMenus: []
        }
    },

    methods: {
      
      getMenu() {
        return http.get("menus/sections/EMPLOYEE").then(r => {
          this.dbMenus = r.data.data.map(x => {
            return {
              title: x.title,
              icon: x.icon,
              route: {name: x.routeName},
              auth: x==null ? '' :x.claims.split(','),
              subheader: x.type == 'SUBHEADER',
              group: x.type == 'GROUP' ? x.href : undefined,
              subs: x.subMenus ==null? undefined: x.subMenus.map(s => {
                return {
                  title: s.title,
                  icon: s.icon,
                  route: {name: s.routeName},
                  auth: x==null ? '' :s.claims.split(','),
                  subheader: s.type == 'SUBHEADER',
                }
              })
            }
          })

          // console.log(this.dbMenus)
        })
      }
    },
    
    beforeMount() {
      console.log("before mount")
    },
    beforeUpdate() {
      console.log("before update")
    },
    update() {
      console.log("update")
    },
    created(){
      console.log("created")
      this.getMenu().then(() => {
        this.$emit('updateSidebar', this.dbMenus);
      })
    }
}
</script>