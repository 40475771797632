<template>
    <v-dialog v-model="dialog" max-width="800px" persistent :fullscreen="fullscreen" scrollable eager>
        <v-card>
            <v-card-title>
                Thư viện hình ảnh
                <v-spacer></v-spacer>
                <v-icon small @click="fullscreen=!fullscreen" class="mx-1" color="blue"> mdi-fullscreen</v-icon>
                <v-icon small @click="dialog=false" class="mx-1"> mdi-close</v-icon>
                <v-row class="my-2">
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field label="Tên báo cáo" v-model="form.name" :rules='[v=>!!v||"Chọn dữ liệu"]' outlined readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-autocomplete item-text="name" item-value="code" :items="departments" v-model="form.departmentCode" outlined
                        label="Phòng(Chi nhánh)" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]' readonly></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-textarea label="Mô tả" v-model="form.description" :rules='[v=>!!v||"Chọn dữ liệu"]' outlined readonly hide-details></v-textarea>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn color="teal" small dark @click="saveData">Lưu</v-btn>
                    </v-col>
                </v-row>

                <!-- ***********  UPLOAD EXPANSE  ***********8-->
                
                <v-subheader class="pl-0" mt-><b>Hình ảnh</b>
                </v-subheader>
            </v-card-title>
            <v-card-text>
                <v-form v-model="isValid" ref="form" @submit.prevent="saveData" id="form" >
                <v-row>
                    <v-col cols="6" md="4">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card outlined class="text-center">
                                    <v-fade-transition>
                                        <v-overlay v-if="hover" absolute color="primary" >
                                            <v-file-input label="Tải lên" prepend-icon="mdi-upload" v-model="appendImages" multiple @change="addImage"></v-file-input>
                                        </v-overlay>
                                    </v-fade-transition>
                                    <v-avatar size="192">
                                        <v-icon large color="primary" v-if="!hover">mdi-plus</v-icon>
                                    </v-avatar>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                    <v-col cols="6" md="4" v-for="i in images" :key="i.label">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card outlined class="text-center">
                                    <v-fade-transition>
                                        <v-overlay v-if="hover" absolute color="primary" >
                                            <v-btn icon large @click="zoomDialog=true, zoomLink=i.source"><v-icon>mdi-magnify-plus</v-icon></v-btn>
                                            <v-btn icon color="red" large @click="removeImage(i.source)"><v-icon>mdi-close</v-icon></v-btn>
                                        </v-overlay>
                                    </v-fade-transition>
                                    <v-img :src="i.source" aspect-ratio="1.2"> </v-img>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-dialog v-model="zoomDialog" fullscreen scrollable>
            <v-card>
                <v-card-title class="teal darken-4 white--text">Hình ảnh
                    <v-spacer></v-spacer>
                    <v-btn icon @click="zoomDialog=false" dark><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="px-0">
                    <v-img :src="zoomLink" contain> </v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
// import Dropzone from "dropzone";
export default {
    props:["value", "item"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        value: {
            handler(val){
                if(val)
                {
                    if(this.isEdit)
                        this.fetch().then(()=>{
                            this.fetchData = false;
                        });
                }
                else{
                    this.close();
                }
            }
        },

        // FETCHING DATA
        "form.departmentCode": function(val){
            this.fetchDepartment();
        },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
    },
    data() {
        return {
            endpoint: process.env.VUE_APP_BASE_URL,
            zoomDialog:false,
            zoomLink:"",
            hover: false,
            ROOT_URL: "imageReports/",
            DEPARTMENT_FETCH: "departments/",

            isValid: true,
            fullscreen: false,
            departments: [],
            positions: [],
            scales: [],
            fetchData: true,
            upload: false,
            form: {
                name:"",
                description:"",
                departmentCode: null,
                attachmentUrl:""
            },
            defaultForm:{
                name:"",
                description:"",
                departmentCode: null,
                attachmentUrl:""
            },
            images: [
            ],
            appendImages:[
                
            ]
        }
    },
    methods: {
        addImage(){
            if(this.appendImages != null){
                let formData = new FormData();
                this.appendImages.forEach(file => {
                    formData.append("AppendFiles", file);
                });
                console.log(formData);
                return http.post(this.ROOT_URL+"add-image"+"/"+this.item.code, formData).then(res =>{
                    this.images=[];
                    this.fetch();
                    this.appendImages = null
                })
            }
        },
        
        removeImage(link){
            let formData = new FormData();
            formData.append("link", link);
            console.log(formData,"dddd");
            return http.post(this.ROOT_URL+"remove-image"+"/"+this.item.code, formData).then(res =>{
                this.images=[];
                this.fetch();
            })
        },

        fetchDepartment(val) {
            return http.get(this.DEPARTMENT_FETCH).then(res => {
                this.departments = res.data;
            });
        },
        
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                this.form = Object.assign({}, res.data.item);
                if(this.form.attachmentUrl=="") this.form.attachmentUrl=null;
                if(this.form.attachmentUrl){
                    var imageLinks = this.form.attachmentUrl.split(";");
                    let stt = 1;
                    imageLinks.forEach(element => {
                        this.images.push({label:stt, source:element});
                        stt++;
                    });
                }
            })
        },

        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    return http.post(this.ROOT_URL, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },

        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fullscreen = false;
            this.fetchData=true;
            this.images=[];
            this.zoomDialog=false;
            this.zoomLink="";
        }
    },
    created() {
        this.fetchDepartment();
    },
    mounted() {
        // let myDropzone = new Dropzone("#my-form", {
        //     autoProcessQueue: false,
        //     addRemoveLinks: true,
        //     init: function() {
        //     this.on("addedfile", function(file) {
        //         $(".dz-remove").html("<div>XOA</div>");
        //     });
        // }
            
        // });
        // myDropzone.on("addedfile", file => {
        // console.log(`File added: ${file.name}`);
        // });
    },
    
}
</script>